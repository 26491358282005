import {
  IndexText,
  RowDiv,
  StyledButton,
  TitleText,
  VideoContainer,
  WhiteCircle,
} from '@/views/components/Guide/styles';
import { Spacer } from '@/views/pages/md/customization/common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsFillPlayFill } from 'react-icons/bs';
import { VideoDisplay } from './VideoDisplay';
interface VideoComponentProps {
  index: number;
  duration: string;
  video: { title: string; duration: number; embedUrl: string };
}

const VideoComponent = ({ video, index, duration }: VideoComponentProps) => {
  const { t } = useTranslation();
  const [showVideoDisplay, setShowVideoDisplay] = useState(false);
  return (
    <>
      <VideoContainer>
        <>
          <IndexText>
            {index + 1 <= 9 && '0'}
            {index + 1}
          </IndexText>
          <TitleText>{video.title}</TitleText>
          <Spacer size={15} />
        </>

        <RowDiv isVerticalCenter>
          <StyledButton onClick={() => setShowVideoDisplay(true)}>
            <WhiteCircle>
              <BsFillPlayFill color='#4748ec' size={14} />
            </WhiteCircle>
            <Spacer size={9} />
            <>{t('mdguides.startVideo')}</>
          </StyledButton>
          <Spacer size={16} />
          <RowDiv isVerticalCenter style={{ fontSize: '1.4rem' }}>
            <AiOutlineClockCircle size={14} />
            <Spacer size={5} />
            {duration}
          </RowDiv>
        </RowDiv>
      </VideoContainer>
      {showVideoDisplay && (
        <VideoDisplay
          onCloseClick={() => setShowVideoDisplay(false)}
          details={{ title: video.title, path: video.embedUrl }}
        />
      )}
    </>
  );
};

export default VideoComponent;
