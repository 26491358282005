import { useEffect, useState } from 'react';

import { Spacer, SubsectionSpan } from '../common';
import SectionHeading from '../section-heading';

import DefaultImages from './default-images';
import AdditionalImages from './additional-images';
import Buttons from '../buttons';

import axios from 'axios';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../../../../config/config';
import { useAppDispatch, useAppSelector } from '../../../../../application/hooks';
import { withSilentAccessToken } from '../../../../../infrastructure/helper';
import { updateQrBgs } from '../../../../../application/actions/md/customization';
import { Theme } from '../../../../../shared/types/api';
import useDeferredLoader from '../../../../../infrastructure/hooks/useDeferredLoader';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import withNav from '@/infrastructure/hoc/withNav';
import ViewsContainer from '@/views/components/common/containers/ViewsContainer';
import { useTranslation } from 'react-i18next';
import { QRBGMode } from '@/views/components/qrcode-background/common/types';

type AdditionalImagesObj = {
  mode: QRBGMode;
  additionalVideoCallBgs?: string[];
  additionalMobileBgs?: string[];
  setAdditionalVideoCallBgs?: any;
  setAdditionalMobileBgs?: any;
};

const QrCodeBackground = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const account = useAppSelector(state => state.account);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const isLoading = useAppSelector(state => state.md.isLoading);

  const [defaultMobileBg, setDefaultMobileBg] = useState<string | File>('');
  const [defaultVideoCallBg, setDefaultVideoCallBg] = useState<string | File>('');

  const defaultMobileProps = { defaultMobileBg, setDefaultMobileBg };
  const defaultVideoCallProps = { defaultVideoCallBg, setDefaultVideoCallBg };
  const defaultImagesProps = { ...defaultMobileProps, ...defaultVideoCallProps };

  const [additionalMobileBgs, setAdditionalMobileBgs] = useState<Array<string>>([]);
  const [additionalVideoCallBgs, setAdditionalVideoCallBgs] = useState<Array<string>>([]);

  const { setIsUnsaved, withUnsavedSetter } = useUnsavedStatusSetter();

  const additionalVideoCallProps: AdditionalImagesObj = {
    mode: 'videocall',
    additionalVideoCallBgs,
    setAdditionalVideoCallBgs: withUnsavedSetter(setAdditionalVideoCallBgs),
  };
  const additionalMobileProps: AdditionalImagesObj = {
    mode: 'mobile',
    additionalMobileBgs,
    setAdditionalMobileBgs: withUnsavedSetter(setAdditionalMobileBgs),
  };

  const [isFetching, setIsFetching] = useState(false);

  useDeferredLoader(isFetching || isLoading, 'get-qr-bgs');

  useEffect(() => {
    setIsFetching(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.get(config.API_BASE_URL + 'account/qr-code/bg-imgs', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
      .then(res => {
        if (res.data?.isSuccess) {
          const {
            data: { mobile, videocall },
          } = res.data;

          setDefaultMobileBg(mobile.default);
          setAdditionalMobileBgs([...mobile.additional]);
          setDefaultVideoCallBg(videocall.default);
          setAdditionalVideoCallBgs([...videocall.additional]);
        } else toast.error(t('genericError'));
      })
      .catch(() => {
        toast.error(t('genericError'));
      })
      .finally(() => setIsFetching(false));
  }, [getAccessTokenSilently, t]);

  const handleSave = () => {
    let ids = { id: account.id, uniqueId: theme?.themeInternal.uniqueId };

    const toUpdateQrBgs = {
      defaultMobileBg,
      defaultVideoCallBg,
      additionalMobileBgs,
      additionalVideoCallBgs,
    };

    toast.loading(t('Loading'), { id: 'qr-code-bg-save' });
    dispatch(
      updateQrBgs(
        ids,
        toUpdateQrBgs,
        getAccessTokenSilently,
        () => {
          toast.success(t('changesSaved'), { id: 'qr-code-bg-save' });
          setIsUnsaved(false);
        },
        () => toast.error(t('genericError'), { id: 'qr-code-bg-save' }),
      ),
    );
  };

  return (
    <ViewsContainer>
      <SectionHeading
        headerText={'1. ' + t('qrBgTab.defaultBackgrounds')}
        tooltipText={t('md.profileDesign.boxFormTooltip')}
      />
      <DefaultImages {...defaultImagesProps} />
      <hr />
      <SectionHeading
        headerText={'2. ' + t('qrBgTab.additionalBgImgs')}
        subHeader={t('*optional')}
      />
      <SubsectionSpan light>{t('qrBgTab.additionalBgImgsDescription')}</SubsectionSpan>
      <AdditionalImages {...additionalMobileProps} />
      <hr />
      <AdditionalImages {...additionalVideoCallProps} />
      <Spacer size={15} />
      <Buttons onSave={handleSave} position='sticky' />
    </ViewsContainer>
  );
};

export default withNav(QrCodeBackground, { tTitle: 'qrCodePageHeader' }, {});
