import { routePaths } from '@/infrastructure/constants';
import { CardGiftcardOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';

export default function FreeNfcCardButton() {
  const history = useHistory();
  return (
    <IconButton onClick={() => history.push(routePaths.REFERRALS)}>
      <CardGiftcardOutlined />
    </IconButton>
  );
}
