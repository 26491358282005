import toast from 'react-hot-toast';
import i18n from '../../../config/i18n';
import { appendParamsToLink } from '@/shared/util';

export type ShareType = 'whatsapp' | 'linkedin' | 'mail' | 'webShare';

const doShareAction = (type: ShareType, profileLink: string) => {
  let shareLink = '';

  const linkedInUrl = appendParamsToLink(profileLink, { utm_medium: 'linkedinshare' });
  const whatsAppUrl = appendParamsToLink(profileLink, { utm_medium: 'whatsappshare' });
  const emailShare = appendParamsToLink(profileLink, { utm_medium: 'emailshare' });
  const webShare = appendParamsToLink(profileLink, { utm_medium: 'webshare' });

  switch (type) {
    case 'linkedin':
      shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${linkedInUrl}`;
      window.open(shareLink, '_blank');
      break;
    case 'whatsapp':
      shareLink = `https://api.whatsapp.com/send?text=${i18n.t('hereIsMyContact')}: ${whatsAppUrl}`;
      window.open(shareLink, '_blank');
      break;
    case 'mail':
      shareLink = `mailto:""?subject=${i18n.t('hereIsMyContact')}&body=${i18n.t(
        'myContact',
      )}: ${emailShare}`;
      window.open(shareLink, '_blank');
      break;
    case 'webShare':
      shareLink = `${webShare}`;
      if (window.navigator.share) {
        try {
          window.navigator.share({
            title: i18n.t('hereIsMyContact'),
            url: shareLink,
          });
        } catch (error) {
          toast.error(i18n.t('errorSharingData'));
        }
      } else {
        toast.error(i18n.t('errorSharingData'));
      }
      break;
    default:
      break;
  }
  return shareLink;
};
export default doShareAction;
