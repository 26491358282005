import { useAppSelector } from '@/application/hooks';
import { PageContainer } from '@/components';
import config from '@/config/config';
import { homepageLink } from '@/infrastructure/constants';
import { withSilentAccessToken } from '@/infrastructure/helper';
import withNav from '@/infrastructure/hoc/withNav';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { ANALYTICS_OPTIONS } from '@/shared/constants';
import { Account, AnalyticsSummary } from '@/shared/types/api';
import Admin from '@/views/components/generic/admin';
import { useAuth0 } from '@auth0/auth0-react';
import { ShareOutlined } from '@mui/icons-material';

import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CardContent,
  Divider,
  Typography,
  Button,
  Box,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import ShareDialog from './components/ShareDialog';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import ClicksCard from './components/ClicksCard';
import StatsCard from './components/StatsCard';
import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';

const DEFAULT_PERIOD =
  ANALYTICS_OPTIONS.filter(option => option?.default)?.[0]?.value || ANALYTICS_OPTIONS[0].value;

const Analytics = () => {
  const [period, setPeriod] = useState(DEFAULT_PERIOD);
  const [linksOpen, setLinksOpen] = useState(true);
  const [filesOpen, setFilesOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AnalyticsSummary>(null);
  const [error, setError] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const { firstName } = useAppSelector<Account>(state => state.account);
  const { getAccessTokenSilently } = useAuth0();

  const { t } = useAppTranslation();
  const { desktopView: isDesktop } = useDesktopView();

  useDeferredLoader(!data && loading, 'analytics-dataloader-id');

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `data/summary?period=${period}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [],
      () => setLoading(true),
    )
      .then(res => {
        if (res.data?.success) {
          setError(false);
          setData(res.data.data);
        }
      })
      .catch(_ => {
        setError(true);
        toast.error(t('error.general'));
      })
      .finally(() => setLoading(false));
  }, [getAccessTokenSilently, period, t]);

  const headerActions = useMemo(
    () =>
      createHeaderAction(
        <Button
          variant='outlined'
          color='primary'
          startIcon={<ShareOutlined />}
          onClick={() => setShowSharePopup(true)}
        >
          {t('analytics.share.share')}
        </Button>,
        { mobileOnly: true },
      ),
    [t],
  );
  useHeaderActions(headerActions);

  const oldFileClicksAmount = useMemo(() => {
    if (data?.raw) {
      const totalClicks = data.raw.fileClicksTotal;
      const activeFilesClicks = data.raw.fileClicksIndividual.reduce(
        (prev, cur) => prev + cur.clicks,
        0,
      );
      return totalClicks - activeFilesClicks;
    } else {
      return 0;
    }
  }, [data]);

  const oldLinkClicksAmount = useMemo(() => {
    if (data?.raw) {
      const totalClicks = data.raw.linkClicksTotal;
      const activeLinksClicks = data.raw.linkClicksIndividual.reduce(
        (prev, cur) => prev + cur.clicks,
        0,
      );
      return totalClicks - activeLinksClicks;
    } else {
      return 0;
    }
  }, [data]);

  const shareData = useMemo(() => {
    return {
      title: t('analytics.share.title'),
      text: t('analytics.share.text', {
        cards: Math.ceil(data?.savings?.cards),
        flyers: Math.ceil(data?.savings?.flyers),
        wood: Math.ceil(data?.savings?.wood),
        water: Math.ceil(data?.savings?.water),
        energy: Math.ceil(data?.savings?.energy),
        co2: Math.ceil(data?.savings?.co2),
      }),
      url: homepageLink,
    };
  }, [data, t]);

  if (error) {
    return null;
  }

  return (
    <PageContainer maxWidth='l'>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: '2rem' }}>
        {isDesktop && (
          <Button
            sx={{ ml: 1 }}
            size='large'
            onClick={() => setShowSharePopup(true)}
            startIcon={<ShareOutlined />}
          >
            {t('analytics.share.share')}
          </Button>
        )}
        <FormControl sx={{ minWidth: isDesktop ? '20%' : '100%', backgroundColor: 'white' }}>
          <InputLabel id='periodLabelId' size='small'>
            {t('analytics.options.title')}
          </InputLabel>
          <Select
            value={period}
            labelId={'periodLabelId'}
            label={t('analytics.options.title')}
            onChange={({ target: { value } }) => setPeriod(value as typeof DEFAULT_PERIOD)}
          >
            {ANALYTICS_OPTIONS.map((option, id) => (
              <MenuItem key={id} value={option.value}>
                {t(`analytics.options.${option.title}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Card sx={{ marginBottom: '1.5rem' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <Admin style={{ cursor: 'unset', width: '4rem', height: '4rem' }} />
          <Typography variant='h3' ml='1rem'>
            {t('analytics.networking.title', { firstName })}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}
        >
          {isDesktop ? (
            <>
              <StatsCard
                title={t('analytics.networking.profileShares')}
                body={data?.raw?.profileShares?.toString()}
                tooltip={t('analytics.tooltips.profileShares')}
              />
              <StatsCard
                title={t('analytics.networking.contacts')}
                body={data?.raw?.contactsCollected?.toString()}
                tooltip={t('analytics.tooltips.contacts')}
              />
              <StatsCard
                title={t('analytics.networking.vcard')}
                body={data?.raw?.contactsSaved?.toString()}
                tooltip={t('analytics.tooltips.vcard')}
              />
              <StatsCard
                title={t('analytics.networking.profileEdits')}
                body={data?.raw?.profileEdits?.toString()}
                tooltip={t('analytics.tooltips.profileEdits')}
              />
            </>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 1 }} mb={1}>
                <StatsCard
                  title={t('analytics.networking.profileShares')}
                  body={data?.raw?.profileShares?.toString()}
                  tooltip={t('analytics.tooltips.profileShares')}
                />
                <StatsCard
                  title={t('analytics.networking.contacts')}
                  body={data?.raw?.contactsCollected?.toString()}
                  tooltip={t('analytics.tooltips.contacts')}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <StatsCard
                  title={t('analytics.networking.vcard')}
                  body={data?.raw?.contactsSaved?.toString()}
                  tooltip={t('analytics.tooltips.vcard')}
                />
                <StatsCard
                  title={t('analytics.networking.profileEdits')}
                  body={data?.raw?.profileEdits?.toString()}
                  tooltip={t('analytics.tooltips.profileEdits')}
                />
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          gap: 1,
          alignItems: isDesktop ? 'flex-start' : 'normal',
        }}
      >
        <ClicksCard
          mode='links'
          open={linksOpen}
          onClick={() => setLinksOpen(!linksOpen)}
          entities={data?.raw?.linkClicksIndividual}
          totalClicks={data?.raw?.linkClicksTotal}
          oldClicks={oldLinkClicksAmount}
        />
        <ClicksCard
          mode='files'
          open={filesOpen}
          onClick={() => setFilesOpen(!filesOpen)}
          entities={data?.raw?.fileClicksIndividual}
          totalClicks={data?.raw?.fileClicksTotal}
          oldClicks={oldFileClicksAmount}
        />
      </Box>
      <Divider sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} />
      <Card>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h3'>{t('analytics.saves.title')}</Typography>
        </CardContent>
        <Divider />
        <CardContent
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', gap: 1 }}
        >
          <StatsCard
            title={t('analytics.saves.cards')}
            body={data?.savings?.cards?.toString()}
            tooltip={t('analytics.tooltips.savedCards')}
          />
          <StatsCard
            title={t('analytics.saves.flyers')}
            body={data?.savings?.flyers?.toString()}
            tooltip={t('analytics.tooltips.savedFlyers')}
          />
        </CardContent>
        <CardContent>
          <Typography variant='h3'>{t('analytics.saves.subtitle')}</Typography>
        </CardContent>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          {isDesktop ? (
            <>
              <StatsCard
                title={t('analytics.saves.wood')}
                body={`${Math.ceil(data?.savings?.wood)}kg`}
              />
              <StatsCard
                title={t('analytics.saves.water')}
                body={`${Math.ceil(data?.savings?.water)}l`}
              />
              <StatsCard
                title={t('analytics.saves.energy')}
                body={`${Math.ceil(data?.savings?.energy)}kwh`}
              />
              <StatsCard
                title={t('analytics.saves.co2')}
                body={`${Math.ceil(data?.savings?.co2)}kg`}
              />
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <StatsCard
                  title={t('analytics.saves.wood')}
                  body={`${Math.ceil(data?.savings?.wood)}kg`}
                />
                <StatsCard
                  title={t('analytics.saves.water')}
                  body={`${Math.ceil(data?.savings?.water)}l`}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <StatsCard
                  title={t('analytics.saves.energy')}
                  body={`${Math.ceil(data?.savings?.energy)}kwh`}
                />
                <StatsCard
                  title={t('analytics.saves.co2')}
                  body={`${Math.ceil(data?.savings?.co2)}kg`}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
      <ShareDialog
        shareData={shareData}
        open={showSharePopup}
        onClose={() => setShowSharePopup(false)}
      />
    </PageContainer>
  );
};

export default withNav(
  Analytics,
  {
    tTitle: 'Analytics',
    showBackButton: {
      linkTo: null,
    },
  },
  {},
);
