import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../text/Text';
import ActiveIcon from '../../../images/active-white.svg';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  name: string;
  value?: boolean;
  checked?: boolean;
  title: string | (() => JSX.Element);
  disabled?: Boolean;
  onChange: (name: string, value) => void;
  size?: number;
  forcedCheckState?: boolean;
  useOnlyCheck?: 'checked' | 'notChecked';
  style?: CSSProperties;
}

interface CheckedProps {
  isCheck: boolean;
}

export const Checkbox = (props: Props) => {
  const { theme } = useMuiTheme();

  const Wrapper = styled.div`
    display: inline-block;
    width: ${props.size || `20`}px;
    height: ${props.size || `20`}px;
    transition: all 150ms;
    ${(props: CheckedProps) => (props.isCheck ? checkedStyles : uncheckedStyles)};
  `;

  const checkedStyles = css`
    border: 2px solid ${theme.palette.primary.main};
    box-sizing: border-box;
    border-radius: 4px;
    background: transparent url(${ActiveIcon}) no-repeat 50% 50%;
    background-color: ${theme.palette.primary.main};
  `;

  const uncheckedStyles = css`
    background: #ffffff;
    border: 2px solid ${theme.palette.primary.main};
    box-sizing: border-box;
    border-radius: 4px;
  `;

  const CheckBoxItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    color: 2px solid ${theme.palette.primary.main};
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 1rem;
    ${props.disabled && `opacity: 0.3`};
    ${!props.disabled && `cursor: pointer`};
    ${props.style && { ...props.style }};
  `;
  return (
    <CheckBoxItem onClick={() => !props.disabled && props.onChange(props.name, props.value)}>
      <Wrapper
        name={props.name}
        value={props.value}
        isCheck={
          typeof props.useOnlyCheck === 'string'
            ? props.useOnlyCheck === 'checked'
            : props.forcedCheckState === undefined
            ? props.checked === props.value
            : props.forcedCheckState
        }
      ></Wrapper>
      {typeof props.title === 'string' ? (
        <Text
          text={props.title}
          size={props?.style?.fontSize || '14px'}
          style={{ marginLeft: '7px' }}
        />
      ) : (
        props.title()
      )}
    </CheckBoxItem>
  );
};
