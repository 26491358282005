import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { RequireOnlyOne } from '../../../shared/types/util';

interface Props {
  text?: string | JSX.Element;
  children?: ReactNode;
  size?: string;
  weight?: '400' | '600' | '700' | string;
  color?: string;
  align?: 'center' | 'left' | 'right';
  margin?: { top?: string; bottom?: string; all?: string };
  id?: string;
  style?: React.CSSProperties;
  fixSize?: boolean;
}

interface IProps {
  size?: Props['size'];
  weight?: Props['weight'];
  color?: Props['color'];
  align?: Props['align'];
  margin?: Props['margin'];
  fixSize?: Props['fixSize'];
}

export const Heading: React.FC<RequireOnlyOne<Props, 'text' | 'children'>> = ({
  text,
  children,
  style,
  ...rest
}) => {
  return children ? (
    <HeadingContent {...rest} style={style}>
      {children}
    </HeadingContent>
  ) : (
    <HeadingContent {...rest} style={style}>
      {text}
    </HeadingContent>
  );
};

const HeadingContent = styled.p`
  font-weight: ${(props: IProps) => props.weight ?? '600'};
  color: ${(props: IProps) => props.color ?? '#181a5a'};
  font-size: ${(props: IProps) => props.size ?? '24px'};
  line-height: 30px;
  ${(props: IProps) => (props.align ? 'text-align: center' : null)};
  margin-top: ${(props: IProps) => props.margin?.top ?? '5px'};
  margin-bottom: ${(props: IProps) => props.margin?.bottom ?? '2px'};
  ${(props: IProps) => (props.margin?.all ? `margin: ${props.margin?.all}` : null)};
  ${(props: IProps) =>
    !props.fixSize
      ? ` @media (max-width: 767px) {
    font-size: 20px;
  }`
      : ''}
`;
