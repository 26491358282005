import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ISO6391 from 'iso-639-1';
import { Box, Stack } from '@mui/material';
import LtDialog from '@/components/LtDialog';

export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language?.substring(0, 2);

  const handleLanguageChange = useCallback(
    async (lang: string) => {
      i18n.changeLanguage(lang);
      window.UC_UI?.updateLanguage?.(lang.substring(0, 2));
    },
    [i18n],
  );

  const Translation = useCallback(
    (props: { languages?: string[]; black?: boolean }) => {
      const langs = props.languages?.filter(ISO6391.validate) ?? ['en', 'de'];

      return (
        <TranslationWrapper black={props.black}>
          {langs.map((lang, idx) => (
            <>
              {idx !== 0 && <>&#124;</>}
              <TranslationButton
                onClick={() => handleLanguageChange(lang)}
                active={i18n.language === lang}
              >
                {lang}
              </TranslationButton>
            </>
          ))}
        </TranslationWrapper>
      );
    },
    [handleLanguageChange, i18n.language],
  );

  const TranslationSelectDialog = (props: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    languages?: string[];
  }) => {
    const langs = props.languages?.filter(ISO6391.validate) ?? ['en', 'de'];
    const [selectedLang, setSelectedLang] = useState(activeLanguage);

    return (
      <LtDialog
        open={props.show}
        onClose={() => props.setShow(false)}
        onCancel={() => props.setShow(false)}
        onConfirm={() => {
          handleLanguageChange(selectedLang);
          props.setShow(false);
        }}
        title={t('settings.changeLanguage')}
        size='sm'
        withActionDivider
      >
        <FormControl fullWidth>
          <InputLabel id='langLabelId'>{t('settings.selectLanguage')}</InputLabel>
          <Select
            value={selectedLang}
            labelId={'langLabelId'}
            label={t('settings.selectLanguage')}
            onChange={({ target: { value } }) => {
              setSelectedLang(value);
            }}
          >
            {langs.map((lang, idx) => (
              <MenuItem key={idx} value={lang}>
                {ISO6391.getNativeName(lang)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </LtDialog>
    );
  };

  const InlineTranslation = useCallback(
    (props: { languages?: string[] }) => {
      const langs = props.languages?.filter(ISO6391.validate) ?? ['en', 'de'];
      return (
        <Stack
          direction='row'
          divider={<Box>|</Box>}
          spacing={0.25}
          aria-label={t('header.langSelector.aria.label')}
          alignItems='center'
        >
          {langs.map((lang, idx) => (
            <Button
              key={lang}
              variant='text'
              onClick={() => handleLanguageChange(lang)}
              sx={theme => ({
                textTransform: 'uppercase',
                fontWeight: lang === i18n.language ? 'bold' : 'normal',
                padding: theme.spacing(0.5),
                minWidth: theme.spacing(3),
              })}
            >
              {lang}
            </Button>
          ))}
        </Stack>
      );
    },
    [handleLanguageChange, i18n.language, t],
  );

  return {
    InlineTranslation,
    Translation,
    TranslationSelectDialog,
    t,
    activeLanguage,
    i18n,
  };
};

const TranslationWrapper = styled.div`
  z-index: 1;
  margin: 0 auto;
  margin-top: 20px;
  color: ${p => (p.black ? '#000' : '#fff')};
  padding-bottom: 7rem;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
`;

const TranslationButton = styled.button`
  background: transparent;
  border: none;

  font-weight: ${props => props.active && '600'};

  &:focus-visible {
    outline: 2px solid;
  }
`;
