import styled from 'styled-components';

export const AdditionalImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ArrayImage = styled.img`
  object-fit: contain;
  width: 167px;
  height: 110px;
`;

export const ClearImageButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-style: solid;
  border-width: 1px;
  border-color: #c1cbdc;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  cursor: pointer;
`;

export const OneImageContainer = styled.div`
  width: 169px;
  height: 112px;
  position: relative;
  border-color: #c1cbdc;
  border-style: solid;
  border-width: 1px;
  margin-right: 0.8vw;
  margin-bottom: 0.8vw;
  border-radius: 5px;
`;

export const UploadBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2px dashed #c1cbdc;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 3px 5px;
  height: 5vw;
  width: 50%;
  cursor: pointer;

  /* img {
    width: 2vw;
    filter: invert(22%) sepia(87%) saturate(2678%) hue-rotate(236deg) brightness(94%) contrast(96%);
  } */

  span {
    color: #4748ec;
  }
`;

export const ImageSizeSpan = styled.span`
  color: #686868;
  font-weight: 400;
  font-size: 0.9vw;
  margin-top: 1vw;
`;
