import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Popup } from '../common';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { CSS_VARS } from '@/infrastructure/constants';

interface Props {
  onCancel: () => void;
  options?: string[];
  current?: string;
  onSelected: (string) => void;
}

const LogoChooser: React.FC<Props> = props => {
  const { onCancel, options, current, onSelected } = props;
  const { t } = useAppTranslation();
  const [selectedUrl, setSelectedUrl] = useState(current);

  return (
    <Popup onCloseClick={onCancel} headerText={t('chooseLogoPicture')}>
      <LogoOptions>
        {options.map((url: string) => (
          <Image
            key={url}
            src={url}
            alt='Logo option'
            onClick={() => setSelectedUrl(url)}
            selected={url === selectedUrl}
          />
        ))}
      </LogoOptions>
      <Button text={t('save')} className='btn' onClick={() => onSelected(selectedUrl)} sticky />
    </Popup>
  );
};

export default LogoChooser;

const LogoOptions = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  row-gap: 1.5rem;
  column-gap: 1rem;
  margin: 1rem 0;
  box-sizing: border-box;
`;

const Image = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  max-width: 100%;
  border: 6px solid transparent;
  transition: border 0.15s ease-in;

  ${props =>
    props.selected &&
    css`
      border: 6px solid ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
    `}
`;
