// csvbox boolean field
export const stringToBoolean = (input: string | boolean | undefined | null) => {
  if (typeof input === 'boolean') return input;
  switch (input?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case 'y':
    case '1':
    case 'on':
    case 'enabled':
      return true;

    case 'false':
    case 'no':
    case 'n':
    case '0':
    case 'off':
    case 'disabled':
    case null:
    case undefined:
      return false;

    default:
      return false;
  }
};
