import React from 'react';
import styled from 'styled-components';
import Back from '../back';
import PageHeading from '../page-heading';
import SubHeading from '../sub-heading';
import lemon from '@/views/images/background.png';
import lemonTapIcon from '@/views/images/lemontap-icon.png';

interface Props {
  pageHeading: string;
  headingCenter?: boolean;
  subHeading?: { stringText?: string; htmlText?: React.ReactElement };
  backPath?: string;
  loginOrRegister?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const PublicContainer = (props: Props): JSX.Element => {
  const { backPath, pageHeading, subHeading, loginOrRegister, children } = props;
  const renderBackButton = () => {
    if (backPath)
      return (
        <BackButtonHolder>
          <Back to={backPath} />
        </BackButtonHolder>
      );
  };

  return (
    <PublicWrapper>
      <PublicNestedWrapper loginOrRegister={loginOrRegister} style={props.style}>
        {renderBackButton()}
        <LogoHolder withBack={backPath}>
          <img src={lemonTapIcon} alt='' />
        </LogoHolder>

        <PageHeading
          text={pageHeading}
          style={{ ...(props.headingCenter && { textAlign: 'center' }) }}
        />
        {subHeading?.htmlText ? (
          subHeading.htmlText
        ) : subHeading?.stringText ? (
          <SubHeading text={subHeading.stringText} />
        ) : null}

        {children}
      </PublicNestedWrapper>
    </PublicWrapper>
  );
};

export default PublicContainer;

const PublicWrapper = styled.div`
  background: url(${lemon}) no-repeat;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const PublicNestedWrapper = styled.div`
  padding: 0 21px;
  width: 40%;
  height: fit-content;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;

  ${props =>
    props.loginOrRegister &&
    `
    height: auto !important;
  `}

  @media (max-width: 767px) {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    width: 88%;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
  }

  @media only screen and (min-width: 1023) and (max-width: 1200px) {
    width: 66%;
  }
`;

const BackButtonHolder = styled.div`
  padding-top: 30px;

  @media (max-width: 360px) {
    padding-top: 15px;
  }
`;

const LogoHolder = styled.div`
  padding-top: 70px;

  ${props =>
    props.withBack &&
    `
     padding-top: 20px;

     @media (max-width: 360px) {
        padding-top: 20px;
    }
  `}

  @media (max-width: 360px) {
    padding-top: 35px;
  }
`;
