import { useAppSelector } from '../../../application/hooks';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { getProfileImageUrl } from '@/shared/business-logic/features/profile/getter';
import { Box } from '@mui/material';

export const Admin = () => {
  const account = useAppSelector(state => state.account);
  const { profileDesign } = useProfileDesign();

  return (
    <Box width='4rem' height='4rem' sx={{ borderRadius: '50%' }}>
      <img
        width='100%'
        height='100%'
        src={getProfileImageUrl(account, profileDesign)}
        alt='admin'
      />
    </Box>
  );
};
