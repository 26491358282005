export const PhotoIcon = () => (
  <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='PhotoOutlined'>
      <path
        id='Vector'
        d='M8 42.6641L22.1144 28.5497C23.1558 27.5083 24.8442 27.5083 25.8856 28.5497L37.3333 39.9974M37.3333 39.9974L33.3333 43.9974M37.3333 39.9974L42.1144 35.2163C43.1557 34.175 44.8443 34.175 45.8856 35.2163L56 45.3307M13.3333 53.3307H50.6667C53.6123 53.3307 56 50.943 56 47.9974V15.9974C56 13.0519 53.6123 10.6641 50.6667 10.6641H13.3333C10.3878 10.6641 8 13.0519 8 15.9974V47.9974C8 50.943 10.3878 53.3307 13.3333 53.3307ZM49.3333 22.6641C49.3333 25.6096 46.9456 27.9974 44 27.9974C41.0544 27.9974 38.6667 25.6096 38.6667 22.6641C38.6667 19.7185 41.0544 17.3307 44 17.3307C46.9456 17.3307 49.3333 19.7185 49.3333 22.6641Z'
        stroke='#495474'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </g>
  </svg>
);
