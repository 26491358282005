import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type Props = {
  opened: boolean;
  onClose: () => void;
  options: {
    orderBy: string;
    sort: string;
    withoutNfc: boolean;
  };
  onFilterChange: (key: string, value: string | boolean) => void;
};

export const Filter = ({ opened, onClose, options, onFilterChange }: Props) => {
  const { t } = useTranslation();
  return (
    <Drawer open={opened} onClose={onClose} anchor='right'>
      <Box p={2.4} display='flex' flexDirection='column' gap={2}>
        <Button
          sx={{ width: 'max-content', textTransform: 'none' }}
          size='small'
          variant='text'
          color='primary'
          startIcon={<CloseIcon />}
          onClick={onClose}
        >
          {t('linkCode.close')}
        </Button>
        <FormControl>
          <FormLabel>{t('linkCode.sortBy')}</FormLabel>
          <RadioGroup
            value={options.orderBy}
            onChange={({ target: { value } }) => onFilterChange('orderBy', value)}
          >
            <FormControlLabel value='firstName' control={<Radio />} label={t('firstName')} />
            <FormControlLabel value='lastName' control={<Radio />} label={t('lastName')} />
            <FormControlLabel value='editedAt' control={<Radio />} label={t('linkCode.editDate')} />
            <FormControlLabel
              value='createdOn'
              control={<Radio />}
              label={t('linkCode.createDate')}
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>{t('linkCode.sortOrder')}</FormLabel>
          <RadioGroup
            value={options.sort}
            onChange={({ target: { value } }) => onFilterChange('sort', value)}
          >
            <FormControlLabel value='asc' control={<Radio />} label={t('linkCode.asc')} />
            <FormControlLabel value='desc' control={<Radio />} label={t('linkCode.desc')} />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>{t('linkCode.filter')}</FormLabel>
          <FormControlLabel
            sx={{ margin: 0, justifyContent: 'start' }}
            control={<Switch checked={options.withoutNfc} />}
            onChange={() => onFilterChange('withoutNfc', !options.withoutNfc)}
            label={t('linkCode.withoutNFC')}
            labelPlacement='start'
          />
        </FormControl>
      </Box>
    </Drawer>
  );
};
