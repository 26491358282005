import { TextFieldTypesType, InputRestrictionsType } from './constants.types';

export const TextFieldTypes: TextFieldTypesType = {
  NUMBER: 'number',
  TEXT: 'text',
  EMAIL: 'email',
  USER: 'user',
  PASSWORD: 'password',
  PHONE: 'phone',
  CODE: 'code',
  BIO: 'bio',
  BIOTEXT: 'bio-text',
  COMPANY: 'company',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  SPOTIFY: 'spotify',
  SNAPCHAT: 'snapchat',
  APPLEMUSIC: 'applemusic',
  TIKTOK: 'tiktok',
  SOUNDCLOUD: 'soundcloud',
  PAYPAL: 'paypal',
  YOUTUBE: 'youtube',
  WEBSITE: 'website',
  SEARCH: 'search',
};

export const InputRestrictions: InputRestrictionsType = {
  ONLY_ALPHABETS: 'alphabets',
  ONLY_NUMBERS: 'numbers',
  ALPHABETS_WITH_SPECIFIC_CHARS: 'alphabets with specific chars',
  ALPHA_NUMERIC: 'alphabets and numbers',
  ALPHA_NUMERIC_LOWERCASE: 'lowercase alphabets and numbers',
  ONLY_NUMBERS_AND_SPACES: 'numbers and spaces',
  ONLY_NUMBERS_AND_SPACES_AND_DASHES: 'numbers and spaces and dashes',
};
