import {
  isOnlyNumbersAndSpacesAndDashes,
  toOnlyNumbersAndSpacesAndDashes,
} from '../../../../infrastructure/helper';
import { Account } from '../../../../shared/types/api';
import { FormControl } from '@mui/material';
import { ACCOUNT_BIO_FIELDS } from '@/shared/constants';
import { isAddressField, isPhoneFaxField } from '../utils';
import AddressRenderer from './AddressRenderer';
import BioTextField from './BioTextField';
interface IProps {
  bioForm: Account;
  disableFields: boolean;
  checkDisabledField: (field: keyof Account, additionalConditions?: Array<boolean>) => boolean;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    transformFct?: (arg: string) => string,
  ) => void;
  focusOnMount?: string;
}

const EditBioForm: React.FC<IProps> = ({
  bioForm,
  disableFields,
  checkDisabledField,
  handleInputChange,
  focusOnMount,
}) => {
  const handlePhoneNrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isOnlyNumbersAndSpacesAndDashes(e.target.value)) {
      handleInputChange(e);
    } else {
      handleInputChange(e, toOnlyNumbersAndSpacesAndDashes);
      e.preventDefault();
    }
  };

  const fields = (Object.keys(bioForm) as Array<keyof Account>)
    .filter(key => ACCOUNT_BIO_FIELDS.includes(key))
    .sort((a, b) => ACCOUNT_BIO_FIELDS.indexOf(a) - ACCOUNT_BIO_FIELDS.indexOf(b));

  const [address1Fields, address2Fields, restFields] = [
    fields.filter(field => isAddressField(field) && field.includes('address1')),
    fields.filter(field => isAddressField(field) && field.includes('address2')),
    fields.filter(field => !isAddressField(field)),
  ];

  const isDisabled = (field: keyof Account) => checkDisabledField(field, [disableFields]);

  return (
    <FormControl sx={{ width: '100%' }}>
      {restFields.map(field => (
        <BioTextField
          field={field}
          value={bioForm[field]}
          disabled={isDisabled(field)}
          onChange={isPhoneFaxField(field) ? handlePhoneNrChange : handleInputChange}
          focused={field === focusOnMount}
        />
      ))}
      {address1Fields.length && (
        <AddressRenderer
          addressFields={address1Fields}
          bioForm={bioForm}
          onInputChange={handleInputChange}
        />
      )}
      {address2Fields.length && (
        <AddressRenderer
          addressFields={address2Fields}
          bioForm={bioForm}
          onInputChange={handleInputChange}
        />
      )}
    </FormControl>
  );
};

export default EditBioForm;
