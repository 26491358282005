import { Dispatch, SetStateAction, useState } from 'react';
import { SubsectionHeader } from '../../../common';
import { isFileValid } from '../../../../../../infrastructure/helper';
import { useTranslation } from 'react-i18next';
import cameraIcon from '../../../../../images/camera.svg';
import ImageCropper from '../../../../../components/image-cropper';
import BannerChooser from '../../../../../components/banner/banner-chooser';
import styled from 'styled-components';
import toast from 'react-hot-toast';

interface Props {
  bannerImgOptions: string[];
  bannerImageUrl?: string;
  setBannerImageUrl?: Dispatch<SetStateAction<string>>;
  setBannerImageFile?: Dispatch<SetStateAction<File>>;
}
const Banner = (props: Props) => {
  let fileInput;

  const { t } = useTranslation();
  const [bannerImage, setBannerImage] = useState('');
  const [showBannerChooser, setShowBannerChooser] = useState(false);

  const { bannerImgOptions, bannerImageUrl, setBannerImageUrl, setBannerImageFile } =
    props || undefined;

  const onImageClick = (fileType: string) => {
    if (fileType === 'banner' && bannerImgOptions && bannerImgOptions.length > 0)
      setShowBannerChooser(true);
    else fileInput.click();
  };

  const onImageSelection = async () => {
    const uploadedFile = fileInput.files[0];
    const extension = uploadedFile.name.split('.').pop();

    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        toast.error(errMsg);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);

      if (extension === 'gif') {
        setBannerImageFile(uploadedFile);
        reader.onload = () => setBannerImageUrl(reader.result as string);
      } else {
        reader.onload = () => setBannerImage(reader.result as string);
      }
    }
  };

  const onBannerUrlSelected = async (newUrl: string) => {
    setBannerImageUrl(newUrl);
    setShowBannerChooser(false);
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    var file = new File([blob], fileInput.files[0].name);
    setBannerImage('');
    setBannerImageUrl(croppedImageUrl);
    setBannerImageFile(file);
  };

  return (
    <>
      {bannerImage && (
        <ImageCropper
          image={bannerImage}
          onUpload={url => onUploadClick(url)}
          onClose={() => setBannerImage('')}
          crop={{ width: 100, x: 0, y: 0, aspect: 16 / 9 }}
        />
      )}

      {showBannerChooser && (
        <BannerChooser
          onCancel={() => setShowBannerChooser(false)}
          options={bannerImgOptions}
          onSelected={onBannerUrlSelected}
          current={bannerImageUrl}
        />
      )}

      <SubsectionHeader>{t('coverPicture')}</SubsectionHeader>
      <BannerWrapper>
        <BannerImage src={bannerImageUrl} alt='Banner' />
        <BannerSizeText>{t('imageSize')}</BannerSizeText>
        <EditBannerWrapper>
          <EditBannerImage src={cameraIcon} alt='Edit' onClick={() => onImageClick('banner')} />
        </EditBannerWrapper>
      </BannerWrapper>

      <input
        type='file'
        ref={input => {
          fileInput = input;
        }}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={() => onImageSelection()}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default Banner;

const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
`;

const BannerImage = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const BannerSizeText = styled.span`
  font-size: small;
  font-weight: 400;
`;

const EditBannerWrapper = styled.div`
  position: absolute;
  background: #e7e7e7;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 4%;
  bottom: 0;
  right: -7%;
`;

const EditBannerImage = styled.img`
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  object-fit: contain;
  cursor: pointer;
`;

// TODO: ADD MEDIA QUERIES
