import { Dispatch, SetStateAction } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { BiPencil } from 'react-icons/bi';
import styled from 'styled-components';
import { FiTrash } from 'react-icons/fi';
import { RowDiv } from '../customization/common';
import { Unit } from '@/shared/types/api/unit.type';
import { useHistory } from 'react-router-dom';
import { BulkButton, TableHeadWrapper, TableWrapper } from '../common/table';
import { RowEditIcon } from '../common';
import { CSS_VARS, routePaths } from '@/infrastructure/constants';

type Props = {
  columns: Array<string>;
  units: Array<Unit>;
  handleEdit: (unit: Unit) => void;
  setSelectedUnits: Dispatch<SetStateAction<Unit[]>>;
  selectedUnits: Array<Unit>;
  numUnits: number;
  deleteSelected: () => void;
};

const UnitsTable = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const navigateToUnitProfiles = (unit: Unit) => {
    history.push(routePaths.MD.PROFILES + '?col=unit&val=' + encodeURIComponent(unit.niceName));
  };

  return (
    <TableWrapper hasBulkActions className='lt-usetiful--md-table-container'>
      <TableHeadWrapper>
        {props.selectedUnits.length > 0 && (
          <RowDiv>
            <>
              <UnitsSelected>
                {t('mdLeads.xUnitsSelected', { count: props.selectedUnits.length })}
              </UnitsSelected>
              <BulkButton redColor onClick={props.deleteSelected}>
                <FiTrash color='#ef0000' size={14} />
                <SpanWithMargin>{t('deleteSelected')}</SpanWithMargin>
              </BulkButton>
            </>
          </RowDiv>
        )}
      </TableHeadWrapper>
      <TableContainer className='table-container' component={Paper}>
        <Table stickyHeader aria-label='customized table'>
          <TableHead>
            <TableRow>
              <TableCell width={'5'}></TableCell>
              <TableCell>{t('mdLeads.edit')}</TableCell>
              <TableCell>{t('mdLeads.view')}</TableCell>
              {props.columns.map((column: string, index) => (
                <TableCell key={column} width={'25%'}>
                  {column !== '' ? t(`mdLeads.${column}`) : null}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {props.units.map((unit, index) => (
            <TableRow key={index}>
              <TableCell key={`check${index}`}>
                <Checkbox
                  color='primary'
                  checked={props.selectedUnits.some(selUnit => unit.id === selUnit.id)}
                  onClick={() =>
                    props.selectedUnits.some(selUnit => unit.id === selUnit.id)
                      ? props.setSelectedUnits(prev =>
                          prev.filter(selUnit => selUnit.id !== unit.id),
                        )
                      : props.setSelectedUnits(prev => [...prev, unit])
                  }
                />
              </TableCell>

              <TableCell key={`edit${index}`}>
                <RowEditIcon
                  onClick={() => props.handleEdit(unit)}
                  color={getComputedStyle(document.documentElement, null).getPropertyValue(
                    CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
                  )}
                >
                  <BiPencil color={CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} size={20} />
                </RowEditIcon>
              </TableCell>
              <TableCell width={'15%'} key={`edit${index}`}>
                <BulkButton
                  onClick={() => navigateToUnitProfiles(unit)}
                  color={getComputedStyle(document.documentElement, null).getPropertyValue(
                    CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
                  )}
                >
                  <span>{t('mdLeads.membersView')}</span>
                </BulkButton>
              </TableCell>
              <TableCell key={unit.id} width={'10%'}>
                {unit.niceName}
              </TableCell>
              <TableCell key={`member${index}`} width={'10%'}>
                {unit.numAccounts}
              </TableCell>
              <TableCell key={`admin${index}`} width={'10%'}>
                {unit.numUnitAdmins}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default UnitsTable;

const SpanWithMargin = styled.div`
  margin-left: 9px;
`;

const UnitsSelected = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 3rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  color: #444444;
`;
