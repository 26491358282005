import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import config from '../../../../config/config';
import { withSilentAccessToken } from '../../../helper';
import { PERMISSIONS } from '../../../constants';
import { FileGenerationSingle } from '@/shared/types/global';
import { FileGeneration } from '@/shared/types/api/file-generation.type';

export const fetchFileGenerations = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
): Promise<FileGeneration[]> => {
  const response = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + 'theme/file-generations', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
  if (response.data?.isSuccess) {
    return response.data?.data;
  }
  throw new Error('isSuccess: false');
};

export const downloadGeneratedFile = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  id: number,
  fileUuid: string,
): Promise<FileGenerationSingle> => {
  const { data: result } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/file-generations/${id}`, {
        params: { fileUuid },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );

  if (result?.isSuccess) {
    return result.data;
  }

  throw new Error('isSuccess: false');
};

export const retryDownloadGeneratedFile = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  file: FileGeneration,
): Promise<FileGenerationSingle> => {
  const { data: result } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(config.API_BASE_URL + `theme/file-generations/${file.id}/retry`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );

  if (result?.isSuccess) {
    return result.message;
  }

  throw new Error('isSuccess: false');
};
