import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import lemonTapsLogo from '../../images/lemon-logo.png';

interface Props {
  delayedShow?: number;
}

export const Loader = (props: Props): React.ReactPortal | null => {
  const portal = useMemo(
    () =>
      ReactDOM.createPortal(
        <Wrapper>
          <Container className='loader-container'>
            <Image src={lemonTapsLogo} alt='' />
            <LoaderWrapper className='loader'></LoaderWrapper>
          </Container>
        </Wrapper>,
        document.getElementById('portal'),
      ),
    [],
  );
  const [portalLoader, setPortalLoader] = useState(props.delayedShow ? null : portal);

  useEffect(() => {
    if (props.delayedShow) {
      setTimeout(() => setPortalLoader(portal), props.delayedShow);
    }
  }, [portal, props.delayedShow]);

  return portalLoader;
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  z-index: 100;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
`;

const loaderAnimation = keyframes`
 0% {transform: rotate(0deg);}
100% {transform: rotate(360deg);}
`;

const LoaderWrapper = styled.div`
  border: 4px solid #6dc2ba;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s ${loaderAnimation} linear infinite;
  margin: auto;
  width: 80px;
  height: 80px;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
`;
