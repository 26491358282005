import { SettingsMenu } from '@/components';
import { Box, styled, Card, CardHeader } from '@mui/material';
import { useState, useRef } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';
import { fileToBase64 } from '@/util';
import { Cropper } from '@/views/components/Cropper';
import { PhotoIcon } from '@/icons/PhotoIcon';

const Background = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  opacity: '0.04',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
}));

const Wrapper = styled(Box)({
  height: '20rem',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '0.4rem',
});

const Image = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
});

export const BusinessCardImage = ({ onChange, value, onDelete }) => {
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [toCropImageUrl, setToCropImageUrl] = useState('');
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const imgPath = croppedImageUrl || value;

  const clickOnInput = () => {
    inputRef.current?.click();
  };

  const handleChange = async e => {
    const [file] = e.target.files;
    if (!file) return;

    const res = await fileToBase64(file);
    setToCropImageUrl(res);
  };

  const handleCrop = async (url: string) => {
    const originalFile = inputRef.current.files[0];
    if (!originalFile) return;

    const blob = await fetch(url).then(r => r.blob());
    const file = new File([blob], originalFile.name);

    setCroppedImageUrl(url);
    onChange(file);
    setToCropImageUrl('');
    inputRef.current.value = '';
  };

  const handleCropperClose = () => {
    setToCropImageUrl('');
    inputRef.current.value = '';
  };

  const menuItems = [
    {
      icon: <DeleteOutlineIcon />,
      label: t('delete'),
      onClick: () => {
        onDelete();
        setCroppedImageUrl('');
      },
    },
    {
      icon: <UploadFileIcon />,
      label: t('leadGen.uploadNew'),
      onClick: clickOnInput,
    },
  ];

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={t('leadGen.businessCardTitle')}
        subheader={t('leadGen.businessCardSubtitle')}
        titleTypographyProps={{ variant: 'h3', mb: 1 }}
        subheaderTypographyProps={{ variant: 'h5', color: 'primary' }}
        action={imgPath && <SettingsMenu icon={<MoreVertIcon />} menuItems={menuItems} />}
      />
      <Wrapper>
        {imgPath ? (
          <>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <Image src={imgPath} alt='' />
            </Box>
          </>
        ) : (
          <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{ cursor: 'pointer' }}
            onClick={clickOnInput}
          >
            <PhotoIcon />
          </Box>
        )}
        {toCropImageUrl && (
          <Cropper
            image={toCropImageUrl}
            onCropClick={handleCrop}
            onClose={handleCropperClose}
            // Adjust per needs
            cropOptions={{ width: 100, x: 0, y: 0, aspect: 16 / 9 }}
          />
        )}
        <input ref={inputRef} onChange={handleChange} style={{ display: 'none' }} type='file' />
        <Background />
      </Wrapper>
    </Card>
  );
};
