import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { RequireOnlyOne } from '../../../shared/types/util';

interface Props {
  text?: string;
  size?: string;
  color?: string;
  lineHeight?: string;
  fontWeight?: string;
  children?: ReactNode;
  style?: CSSProperties;
}

interface IProps {
  size?: string;
  color?: string;
  lineHeight?: string;
  fontWeight?: string;
}

export const SubText: React.FC<RequireOnlyOne<Props, 'text' | 'children'>> = ({
  text,
  children,
  ...rest
}) => {
  return children ? (
    <SubTextContent {...rest}>{children}</SubTextContent>
  ) : (
    <SubTextContent {...rest}>{text}</SubTextContent>
  );
};

const SubTextContent = styled.div`
  font-weight: ${(props: IProps) => props.fontWeight ?? '500'};
  color: ${(props: IProps) => props.color ?? '#8A8A8A'};
  font-size: ${(props: IProps) => props.size ?? '1.4rem'};
  line-height: ${(props: IProps) => props.lineHeight ?? '21px'};
  margin-top: 1rem;
  margin-bottom: 2px;
`;
