import React from 'react';
import styled from 'styled-components';

interface Props {
  image: string;
  title: string;
  linkLabel: string;
  link: string;
  action: () => void;
}

const SingleQuestion = (props: Props) => {
  return (
    <ParentContainer
      onClick={
        props.action === null ? () => window.open(props.link, '_blank') : () => props.action()
      }
    >
      <StyledImage>
        <img width={'100%'} src={props.image} alt={props.image} />
      </StyledImage>
      <TextContainer>
        <Title>{props.title}</Title>
        {props.action == null ? (
          <a href={props.link} target='_blank' rel='noreferrer'>
            <StyledLink>{props.linkLabel} &rarr;</StyledLink>
          </a>
        ) : (
          <StyledLink onClick={props.action}>{props.linkLabel} &rarr;</StyledLink>
        )}
      </TextContainer>
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  cursor: pointer;
  height: 270px;
  width: 410px;
  left: 327px;
  top: 803px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  margin-right: 50px;
  margin-bottom: 70px;
`;

const TextContainer = styled.div`
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLink = styled.div`
  color: #4748ec;
  align-self: flex-end;
  padding: 0.9vw;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledImage = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 25px;
  margin-bottom: 15px;
`;

export default SingleQuestion;
