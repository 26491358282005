import styled from 'styled-components';
import { Loader, Popup } from '../../components/common';
import { useTranslation } from 'react-i18next';
import { Link, LinkType } from '../../../shared/types/api';
import TooltipInfo from '../../components/generic/TooltipInfo';
import { MouseEventHandler } from 'react';

interface SingleLinkProps {
  linkType: LinkType;
  onClick: MouseEventHandler<HTMLDivElement>;
  genericWebsiteColor: string;
}

const SingleLink: React.FC<SingleLinkProps> = ({ linkType, onClick, genericWebsiteColor }) => {
  return (
    <TooltipInfo text={linkType.name} placement='bottom' onHover>
      <LinkWrapper
        onClick={onClick}
        style={{ background: linkType.backgroundCss || genericWebsiteColor || '#152570' }}
        grid
      >
        <img src={linkType.iconUrl} alt='link icon' />
      </LinkWrapper>
    </TooltipInfo>
  );
};

export interface ThemeLinkType extends LinkType {
  themeLinkTypes: Array<{
    order: number;
  }>;
}

interface Props {
  loading: boolean;
  onCloseClick: () => void;
  firstWebsite: boolean;
  newOrder: number;
  linkTypes: Array<ThemeLinkType>;
  onItemClick: (item: Link) => void;
  onLinkTypeClick: (boolean) => void;
  isCompanydataSettings?: boolean;
  genericWebsiteColor: string;
}

const AddNewLinkPopup = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  if (props.loading) {
    return (
      <Popup headerText={t('chooseLink')}>
        <Loader />
      </Popup>
    );
  } else if (props.firstWebsite) {
    const { linkTypes, onLinkTypeClick } = props;
    const orderedLinkTypes = linkTypes.sort((a, b) =>
      a.themeLinkTypes &&
      b.themeLinkTypes &&
      a.themeLinkTypes.length > 0 &&
      b.themeLinkTypes.length > 0
        ? a.themeLinkTypes[0].order - b.themeLinkTypes[0].order
        : 0,
    );
    return (
      <Popup headerText={t('chooseLink')} onCloseClick={props.onCloseClick}>
        <AddLinkWrapper>
          <LinkWrapper
            style={{
              background:
                orderedLinkTypes[0].backgroundCss || props.genericWebsiteColor || '#152570',
            }}
            onClick={() => onLinkTypeClick(orderedLinkTypes[0])}
            grid
          >
            <img src={orderedLinkTypes[0].iconUrl} alt='link icon' />
            <span> Websites </span>
          </LinkWrapper>
          <LinksGrid>
            {orderedLinkTypes.map((linkType, idx) =>
              idx > 0 ? (
                <SingleLink
                  key={linkType.id}
                  linkType={linkType}
                  onClick={() => onLinkTypeClick(linkType)}
                  genericWebsiteColor={props.genericWebsiteColor}
                />
              ) : null,
            )}
          </LinksGrid>
        </AddLinkWrapper>
      </Popup>
    );
  } else {
    const { linkTypes } = props;
    const orderedLinkTypes = linkTypes.sort((a, b) =>
      props.isCompanydataSettings
        ? a.order - b.order
        : a.themeLinkTypes[0].order - b.themeLinkTypes[0].order,
    );
    return (
      <>
        <Popup headerText={t('chooseLink')} onCloseClick={props.onCloseClick}>
          <AddLinkWrapper wide>
            <LinksGrid wide threeCols>
              {orderedLinkTypes.map(lt => {
                // @ts-ignore:next-line
                const linkType = props.isCompanydataSettings ? lt.linkType : lt;
                return (
                  <SingleLink
                    key={linkType.id}
                    linkType={linkType}
                    onClick={() =>
                      props.onItemClick({
                        id: 0,
                        name: '',
                        link: '',
                        order: props.newOrder,
                        canDisplayOnProfile: true,
                        linkType: linkType,
                        isShallow: true,
                      })
                    }
                    genericWebsiteColor={props.genericWebsiteColor}
                  />
                );
              })}
            </LinksGrid>
          </AddLinkWrapper>
        </Popup>
      </>
    );
  }
};

export default AddNewLinkPopup;

const AddLinkWrapper = styled.div`
  margin: auto;
  width: ${props => (props.wide ? '100%' : '80%')};
`;

const LinkWrapper = styled.div`
  ${props =>
    props.flex &&
    `
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 1.5rem;
    }
  `}

  ${props =>
    props.grid &&
    `
     display: grid;
    place-items: center;
    color: white;
  padding: 1.5rem;
  font-size: 1.5rem;
  border-radius: 10px;
  cursor: pointer;

    img {
      height: 25px;
    }
  `}
`;

const LinksGrid = styled.div`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: ${props =>
    props.threeCols
      ? 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
      : 'minmax(0, 1fr) minmax(0, 1fr)'};

  ${props =>
    props.wide &&
    `
    row-gap: 2rem !important;
  `}
`;
