import { EmailOutlined, FacebookOutlined, LinkedIn, Telegram, WhatsApp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export type ShareType = typeof SHARE_TYPES[keyof typeof SHARE_TYPES];

export const SHARE_TYPES = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  WHATSAPP: 'whatsapp',
  TELEGRAM: 'telegram',
  MAIL: 'mail',
} as const;

export type ShareProps = {
  shareData: ShareData;
  close: () => void;
  setSubScreen?: Dispatch<SetStateAction<boolean>>;
  subScreen: boolean;
  setDialogTitle?: Dispatch<SetStateAction<string>>;
};

export type ShareData = { [k in 'title' | 'text' | 'url']: string };

export const generateShareIcon = (type: ShareType) => {
  switch (type) {
    case 'facebook':
      return <FacebookOutlined />;
    case 'linkedin':
      return <LinkedIn />;
    case 'whatsapp':
      return <WhatsApp />;
    case 'telegram':
      return <Telegram />;
    case 'mail':
      return <EmailOutlined />;

    default:
      break;
  }
};

export const generateShareText = (text: string) =>
  text
    .replace(/\n/g, '<br>')
    .split('<br>')
    .map(line => (
      <Typography color={'secondary.main'} variant='body2'>
        {line}
      </Typography>
    ));

export const capitalize_first_char = (str: string) =>
  !!str && str.replace(/^./, str => str.toUpperCase());

export const generateShareLink = (type: ShareType, shareData: ShareData) => {
  switch (type) {
    case 'facebook':
      return `https://www.facebook.com/sharer.php?u=${shareData.url}&t=${shareData.text}&display=page`;
    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${shareData.url}`;

    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${shareData.text}%20${shareData.url}`;

    case 'telegram':
      return `https://telegram.me/share/url?url=${shareData.url}&text=${shareData.text}`;

    case 'mail':
      return `mailto:""?subject=${shareData.title}&body=${shareData.url} ${shareData.text}`;

    default:
      break;
  }
};
