import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../../application/hooks';
import { Employee } from '../../../../shared/types/api/employee.type';
import styled from 'styled-components';
import { CustomHeightHolder, Spacer } from '../customization/common';
import { Checkbox, TextField } from '../../../components/generic';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { Account, APIError, AuthRole, Theme } from '../../../../shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../config/config';
import toast from 'react-hot-toast';
import {
  AVAILABLE_PERMISSION_LEVELS,
  DEFAULT_COLOR,
  PERMISSIONS,
} from '../../../../infrastructure/constants';
import { useDispatch } from 'react-redux';
import { getEmployees } from '../../../../application/actions/md/profiles';
import { assignUnits, getUnits } from '../../../../infrastructure/apis/md/units';
import { Unit } from '../../../../shared/types/api/unit.type';
import { Loader } from '../../../components/common';
import useDeferredLoader from '../../../../infrastructure/hooks/useDeferredLoader';
import { ERROR_KEYS, FEATURE } from '../../../../shared/constants';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import { BtnB, BtnB2 } from '../common';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import TooltipInfo from '@/views/components/generic/TooltipInfo';
import { HeaderWithIconWrapper } from '@/views/components/qrcode-background/common/styles';
import { getMainUnitSync, getOtherUnitsSync, lexicalSortObject } from '@/shared/util';
import { getFullName } from '@/shared/business-logic/features/profile/getter';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ThemeProvider } from '@mui/system';
import { fetchUserData } from '@/application/actions/account';

interface Props {
  employee: Employee;
  onBackClick: () => void;
  onSaveSuccess?: Function;
  setShowUnit?: Dispatch<SetStateAction<boolean>>;
}

const getInitialRoles = (employee: Account): AuthRole[] =>
  employee.authRoles && employee.authRoles.length > 0
    ? employee.authRoles
    : [{ id: 'plain', name: 'plain', niceName: 'Employee' }];

const EmployeeTypeAssignment = (props: Props) => {
  const { isFeatureAllowed, isThemeAdmin, isUnitAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user: userSelf, logout } = useAuth0();
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const multiUnitsAllowed = !!theme?.themeInternal?.multiUnitsAllowed;
  const [roles, setRoles] = useState(getInitialRoles(props.employee));
  const [initialAuthEmail, setInitialAuthEmail] = useState<string>();
  const [updatedAuthEmail, setUpdatedAuthEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (error) toast.error(t('error.general'));
  }, [error, t]);
  const { getAccessTokenSilently } = useAuth0();
  const [units, setUnits] = useState<Unit[]>([]);
  const originalMainUnit = useMemo(() => getMainUnitSync(props.employee), [props.employee]);
  const [mainUnit, setMainUnit] = useState<Unit | null | undefined>(originalMainUnit);
  const [otherUnits, setOtherUnits] = useState<Unit[]>(getOtherUnitsSync(props.employee));
  const [errorNoUnitSelected, setErrorNoUnitSelected] = useState(false);
  const [errorNoSelectedRole, setErrorNoSelectedRole] = useState(false);
  const account = useAppSelector(state => state.account);
  const { profileDesign } = useProfileDesignForUnits(mainUnit?.id);

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const [loadingUnits, setLoadingUnits] = useState(false);
  useDeferredLoader(loadingUnits, 'loadingUnits');
  useEffect(() => {
    if (isFeatureAllowed(FEATURE.UNITS)) {
      if (!isThemeAdmin) {
        setUnits(account.units);
        return;
      }
      setLoadingUnits(true);
      getUnits(getAccessTokenSilently)
        .then(res => {
          setUnits(res.data.data.units);
        })
        .catch(err => {
          toast.error(t('mdLeads.errorReadUnits'), { id: 'loadingUnits' });
          setUnits([]);
        })
        .finally(() => setLoadingUnits(false));
    }
  }, [account.units, getAccessTokenSilently, t, isThemeAdmin, isFeatureAllowed]);

  useEffect(() => {}, [props.employee]);
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'business/employees/' + props.employee.id + '/authData', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES_AUTH_DATA],
    )
      .then(res => {
        setInitialAuthEmail(res.data.data.email);
        setUpdatedAuthEmail(res.data.data.email);
      })
      .catch(() => {
        setError(true);
        toast.error(t('mdLeads.loadingUnitsError'));
      });
  }, [getAccessTokenSilently, props.employee.id, t]);
  const [disabled, setDisabled] = useState(true);
  const [sendInvite, setSendInvite] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>();
  const getProfileImageUrl = () => {
    return props.employee?.profileImageUrl || profileDesign.defaultProfileImageUrl;
  };
  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };
  const saveDisabled = useMemo(() => {
    if (
      mainUnit?.id !== originalMainUnit?.id ||
      otherUnits
        .map(unit => unit?.id)
        .sort((a, b) => a - b)
        .join(',') !==
        props.employee.units
          ?.map(unit => unit?.id)
          ?.filter(uid => uid !== mainUnit?.id)
          .sort((a, b) => a - b)
          .join(',')
    )
      return false;
    return (
      error ||
      !initialAuthEmail ||
      (getInitialRoles(props.employee).every(ar => roles.some(r => r.name === ar.name)) &&
        initialAuthEmail === updatedAuthEmail)
    );
  }, [
    mainUnit?.id,
    originalMainUnit?.id,
    otherUnits,
    props.employee,
    error,
    initialAuthEmail,
    updatedAuthEmail,
    roles,
  ]);

  const onSaveClick = async () => {
    setErrorNoUnitSelected(false);
    setErrorNoSelectedRole(false);
    if (roles.filter(r => r.name === 'unit_admin').length > 0) {
      if (!mainUnit) {
        setErrorNoUnitSelected(true);
        return;
      }
    }
    if (roles.length === 0) {
      setErrorNoSelectedRole(true);
    }

    if (saveDisabled) return;

    const updatedRoles = roles.filter(r => r.name !== 'plain');

    const requestObj = {
      roles: updatedRoles,
      authEmail: {
        email: updatedAuthEmail,
        sendInvite: sendInvite,
      },
    };

    const toastId = 'emplTypeAss-toast';
    setIsLoading(true);

    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios
          .patch<{ isSuccess: boolean }>(
            config.API_BASE_URL + 'business/employees/' + props.employee.id + '/authData',
            requestObj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(async res => {
            if (res.data.isSuccess) {
              if (isFeatureAllowed(FEATURE.UNITS)) {
                assignUnits(
                  getAccessTokenSilently,
                  [props.employee.id],
                  mainUnit?.id,
                  multiUnitsAllowed ? otherUnits.map(unit => unit?.id) : [],
                )
                  .then(res => {
                    if (res.data.isSuccess) {
                      props?.onSaveSuccess();
                      if (props.employee.id === account.id)
                        dispatch(fetchUserData(getAccessTokenSilently, logout));
                      dispatch(
                        getEmployees(getAccessTokenSilently, '', '', t, {
                          withToast: true,
                          onSuccess: () => toast.success(t('changesSaved'), { id: toastId }),
                          onFail: () => toast.success(t('changesSaved'), { id: toastId }),
                        }),
                      );
                      setIsUnsaved(false);
                    }
                  })
                  .catch(err => toast.error(t('mdLeads.errorReadUnits', { id: toastId })))
                  .finally(() => setIsLoading(false));
              } else {
                props?.onSaveSuccess();
                dispatch(
                  getEmployees(getAccessTokenSilently, '', '', t, {
                    withToast: true,
                    onSuccess: () => toast.success(t('changesSaved'), { id: toastId }),
                    onFail: () => toast.success(t('changesSaved'), { id: toastId }),
                  }),
                );
                setIsLoading(false);
                setIsUnsaved(false);
              }
            }
          })
          .catch((err: APIError) => {
            setIsLoading(false);
            if (err?.response?.data?.error?.code === ERROR_KEYS.DISPOSABLE_EMAIL) {
              // disposable email
              toast.error(t('error.disposableEmail'));
            } else if (err?.response?.data?.error?.code === ERROR_KEYS.EXISTED_EMAILS) {
              // email already existed for another user
              toast.error(t('error.existedEmail'));
            } else if (err?.response?.data?.error?.code === ERROR_KEYS.INVALID_EMAIL) {
              // invalid email format
              toast.error(t('error.invalidEmail'));
            } else {
              return toast.error(t('error.general'));
            }
          }),
      [PERMISSIONS.WRITE.THEME_PROFILES_AUTH_DATA],
    );
  };

  useEffect(() => {
    if (!disabled) emailInputRef.current.focus();
  }, [disabled]);

  const emailHasChanged = useMemo(
    () => initialAuthEmail !== updatedAuthEmail,
    [initialAuthEmail, updatedAuthEmail],
  );
  const disableAdminForNoneThemeAdmin = (role: string) => role === 'theme_admin' && !isThemeAdmin; ///ss
  const disableIfNotThemeAdminAndUserAdmin = !isThemeAdmin && props.employee.isThemeAdmin;
  const onRoleChange = useCallback(
    (name: string) => {
      if (name === 'unit_admin') {
        setRoles(roles.filter(r => r.name !== 'theme_admin'));
      }
      const roleDef = AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).find(
        ar => ar.name === name,
      );
      const exclusiveRoles = AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).filter(
        ar => ar.exclusiveGroups.some(eg => roleDef.exclusiveGroups.some(rdeg => eg === rdeg)),
      );
      if (!roleDef) return;
      if (roles.find(r => r.name === name)) {
        let requiredToAdd: AuthRole[] = [];
        for (const reqGroup of roleDef.requiredGroups) {
          const toadd = AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS))
            .filter(ar => ar.name !== name)
            .find(ar => ar.requiredGroups.some(arreq => arreq === reqGroup));
          if (toadd) requiredToAdd.push(toadd);
        }
      } else {
        setRoles([...roles.filter(r => exclusiveRoles.every(er => er.name !== r.name)), roleDef]);
      }
      setIsUnsaved(true);
    },
    [isFeatureAllowed, roles, setIsUnsaved],
  );

  const canEditMainUnits =
    isThemeAdmin ||
    (isUnitAdmin && units.some(u => u && originalMainUnit && u.id === originalMainUnit.id));

  return (
    <>
      {isLoading && <Loader />}
      <HeaderContainer>
        <ProfilePicCropper>
          <ProfilePicImage src={getProfileImageUrl()} alt='Profile Pic' />
        </ProfilePicCropper>
        <EmailContainer>
          {getFullName(props.employee)}
          {props.employee.id === userSelf.sub ? ' (' + t('you') + ')' : ''}
        </EmailContainer>
        <Spacer size={60} />
      </HeaderContainer>
      <DetailsParentContainer>
        <DetailsContainer>
          <TextContainer>{t('role')}</TextContainer>
          <Spacer size={25} />
          <RowDiv>
            <ThemeProvider theme={muiTheme}>
              <StyledRadioGroup aria-labelledby='roles-group' name='roles-group' row>
                {AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).map(role => (
                  <StyledFormControlLabel
                    color={muiTheme.palette.primary.main}
                    value={role.name}
                    control={
                      <Radio
                        inputProps={{ 'aria-label': role.niceName }}
                        color='primary'
                        checked={!!roles.find(r => role.name === r.name)}
                      />
                    }
                    key={role.name}
                    label={role.niceName}
                    disabled={
                      (props.employee.isThemeAdmin || role.name === 'theme_admin') && !isThemeAdmin
                    }
                    name={role.name}
                    title={t(role.niceName)}
                    onChange={() => {
                      if (disableIfNotThemeAdminAndUserAdmin) return;
                      if (disableAdminForNoneThemeAdmin(role.name)) return;
                      onRoleChange(role.name);
                    }}
                  />
                ))}
              </StyledRadioGroup>
            </ThemeProvider>
          </RowDiv>

          {errorNoSelectedRole && <ErrorWrapper>{t('mdLeads.noRoleSelected')}</ErrorWrapper>}

          <Spacer size={25} />
          {units && units.length > 0 && (
            <>
              {/* main unit */}
              <HeaderWithIconWrapper>
                <TextContainer>
                  {multiUnitsAllowed ? t('mainUnit') : t('mdLeads.unit')}
                </TextContainer>
                {multiUnitsAllowed && (
                  <TooltipInfo text={t('mainUnitTooltip')} placement='right' arrow />
                )}
              </HeaderWithIconWrapper>
              <Spacer size={25} />
              <RowDiv>
                <ThemeProvider theme={muiTheme}>
                  <StyledRadioGroup aria-labelledby='roles-group' name='roles-group' row>
                    {canEditMainUnits ? (
                      <StyledFormControlLabel
                        key={-1}
                        disabled={props.employee.isThemeAdmin && !isThemeAdmin}
                        name={'none'}
                        label={t('mdLeads.noUnit')}
                        color={DEFAULT_COLOR}
                        control={
                          <Radio
                            inputProps={{ 'aria-label': t('mdLeads.noUnit') }}
                            checked={!mainUnit}
                          />
                        }
                        onChange={() => {
                          if (isUnitAdmin && props.employee.isThemeAdmin) {
                            return;
                          }
                          setMainUnit(null);
                          setOtherUnits([]);
                          setIsUnsaved(true);
                        }}
                      />
                    ) : (
                      <ThemeProvider theme={muiTheme}>
                        <StyledFormControlLabel
                          key={-1}
                          disabled
                          name={'none'}
                          label={t('mdLeads.noUnit')}
                          control={
                            <Radio
                              inputProps={{
                                'aria-label': originalMainUnit?.niceName || t('mdLeads.noUnit'),
                              }}
                              color='primary'
                              checked={true}
                            />
                          }
                          onChange={() => {}}
                        />
                      </ThemeProvider>
                    )}

                    {units?.sort(lexicalSortObject('niceName'))?.map((record, index) => (
                      <StyledFormControlLabel
                        value={record.niceName}
                        control={
                          <Radio
                            inputProps={{ 'aria-label': record.niceName }}
                            color='primary'
                            checked={record.id === mainUnit?.id}
                          />
                        }
                        key={index}
                        label={record.niceName}
                        disabled={
                          !canEditMainUnits || (isUnitAdmin && props.employee.isThemeAdmin === true)
                        }
                        name={record.niceName}
                        title={record.niceName}
                        onChange={() => {
                          if (!canEditMainUnits || (isUnitAdmin && props.employee.isThemeAdmin)) {
                            return;
                          }
                          if (multiUnitsAllowed) {
                            setOtherUnits(prev =>
                              [...prev.filter(u => u.id !== record.id), mainUnit].filter(Boolean),
                            );
                          }
                          setMainUnit(record);
                          setIsUnsaved(true);
                        }}
                      />
                    ))}
                  </StyledRadioGroup>
                </ThemeProvider>
              </RowDiv>
              <Spacer size={25} />
            </>
          )}

          <Spacer size={25} />
          {multiUnitsAllowed && units && units.length > 0 && (
            <>
              {/* other units */}
              <HeaderWithIconWrapper>
                <TextContainer>{t('otherUnits')}</TextContainer>
                <TooltipInfo text={t('otherUnitsTooltip')} placement='right' arrow />
              </HeaderWithIconWrapper>
              <Spacer size={25} />
              <RowDiv>
                {units?.sort(lexicalSortObject('niceName'))?.map((record, index) =>
                  record.id === mainUnit?.id ? null : (
                    <Checkbox
                      key={index}
                      disabled={!mainUnit || (props.employee.isThemeAdmin && !isThemeAdmin)}
                      name={record.niceName}
                      useOnlyCheck={
                        otherUnits.map(unit => unit?.id).includes(record?.id)
                          ? 'checked'
                          : 'notChecked'
                      }
                      title={record.niceName}
                      onChange={() => {
                        if (isUnitAdmin && props.employee.isThemeAdmin) {
                          return;
                        }
                        if (otherUnits.map(unit => unit?.id).includes(record?.id)) {
                          setOtherUnits(otherUnits.filter(x => x.id !== record.id));
                          setIsUnsaved(true);
                        } else {
                          setOtherUnits([...otherUnits, record]);
                          setIsUnsaved(true);
                        }
                      }}
                    />
                  ),
                )}
              </RowDiv>
              <Spacer size={25} />
            </>
          )}

          {errorNoUnitSelected && (
            <ErrorWrapper>
              {units.length === 0 ? t('mdLeads.firstCreateUnit') : t('mdLeads.noUnitSelected')}
            </ErrorWrapper>
          )}
          <Spacer size={45} />
          <TextContainer>{t('authEmail')}</TextContainer>
          <Spacer size={-5} />
          <RowDiv>
            <TextField
              name={'accountEmail'}
              value={updatedAuthEmail}
              angles='normal'
              type='text'
              border={borderProps}
              onChange={e => {
                // validate input
                for (let i = 0; i < e.target.value.length; i++) {
                  if (e.target.value.charCodeAt(i) > 127) {
                    toast.error(t('invalidCharEntered'));
                    return;
                  }
                }
                setUpdatedAuthEmail(e.target.value);
                setIsUnsaved(true);
              }}
              font={{ size: '18px', weight: '400' }}
              fullWidth
              padding={{ left: '0' }}
              placeholder={updatedAuthEmail}
              disabled={disabled}
              inputRef={emailInputRef}
              onBlur={() => !emailHasChanged && setDisabled(true)}
            />
            {disabled && !!updatedAuthEmail && isThemeAdmin && (
              <EditText onClick={() => setDisabled(false)} color={muiTheme.palette.primary.main}>
                {t('employeeType.edit')}
              </EditText>
            )}
          </RowDiv>
          <Spacer size={5} />
          {emailHasChanged && (
            <RowDiv isSpaceBetween>
              <SmallText>{t('employeeType.sendInviteText')}</SmallText>
              <Spacer size={5} />
              <Switch
                onChange={() => setSendInvite(!sendInvite)}
                checked={sendInvite === true}
                onColor={muiTheme.palette.primary.main}
                offColor={muiTheme.palette.grey[400]}
              />
            </RowDiv>
          )}
        </DetailsContainer>
      </DetailsParentContainer>
      <CustomHeightHolder height={emailHasChanged ? '58%' : '60%'} />
      <ButtonWrapper>
        <BtnB
          type='button'
          value={t<string>('save')}
          onClick={onSaveClick}
          disabled={saveDisabled}
          color={muiTheme.palette.primaryButton.main}
        />
        <BtnB2
          type='button'
          value={t<string>('cancel')}
          onClick={() => openDialogIfUnsaved(props.onBackClick)}
        />
      </ButtonWrapper>
    </>
  );
};

export default EmployeeTypeAssignment;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ProfilePicCropper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
`;

const ProfilePicImage = styled.img`
  width: 100%;
  height: auto;
`;

const EmailContainer = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: underline;
`;

const TextContainer = styled.div`
  font-size: 1em;
  font-weight: bold;
  margin-right: 0.6rem;
`;

const DetailsParentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
`;

const DetailsContainer = styled.div`
  width: 90%;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.isSpaceBetween ? 'space-between' : 'space-around')};
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const SmallText = styled.div`
  font-size: small;
  opacity: 0.6;
`;
const ErrorWrapper = styled.div`
  font-size: small;
  color: red;
`;

const EditText = styled.div`
  color: ${p => p.color};
  position: absolute;
  right: 15px;
  top: 35px;
  z-index: 10;
  cursor: pointer;
`;
const ButtonWrapper = styled.div`
  background-color: #f6f8ff;
  z-index: 10;
  position: sticky;
  right: 0;
  bottom: 0;
  width: 100%;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transform: scale(1.1);
`;

const StyledRadioGroup = styled(RadioGroup)`
  gap: 1.4rem;
  justify-content: center;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & > .MuiFormControlLabel-label {
    font-size: 1.4rem;
  }
  margin-left: 0;
  margin-right: 0;
`;
