import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  hasAccount: undefined,
  isUsername: undefined,
  isCode: undefined,
  code: undefined,
  addCodeResponse: {},
  addCodeError: {},
  addCodeLoading: false,
  authCallbackState: {},
};

const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.LANDING.HAS_ACCOUNT_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case dispatchTypes.LANDING.HAS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasAccount: action.hasAccount,
        isUsername: action.isUsername,
        isCode: action.isCode,
        code: action.code,
      };
    case dispatchTypes.LANDING.HAS_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        isCode: action.isCode,
        code: action.code,
        errorMsg: action.errorMsg,
      };
    case dispatchTypes.LANDING.ADD_CODE_INIT:
      return {
        ...state,
        addCodeLoading: true,
      };
    case dispatchTypes.LANDING.ADD_CODE_SUCCESS:
      return {
        ...state,
        addCodeLoading: false,
        addCodeResponse: action.apiResponse,
      };
    case dispatchTypes.LANDING.ADD_CODE_ERROR:
      return {
        ...state,
        addCodeLoading: false,
        addCodeError: action.error,
      };
    case dispatchTypes.LANDING.SET_AUTH_CALLBACK_STATE:
      return {
        ...state,
        authCallbackState: action.state,
      };
    case dispatchTypes.LANDING.RESET_AUTH_CALLBACK_STATE:
      return {
        ...state,
        authCallbackState: {},
      };
    default:
      return state;
  }
};

export default landingReducer;
