import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import frequentlyAskedImage from '@/views/images/guides/frequentlyAskedImage.png';
import writeToSupport from '@/views/images/guides/writeToSupport.png';

export const useQuestions = () => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  return useMemo(
    () => [
      {
        image: frequentlyAskedImage,
        title: t('mdguides.openQuestions.takeALook'),
        link: `https://support.lemontaps.com/${
          activeLanguage.startsWith('de') ? 'de' : 'en'
        }/support/home`,
        linkLabel: t('mdguides.openQuestions.takeALookLink'),
        action: null,
      },
      {
        image: writeToSupport,
        title: t('mdguides.openQuestions.writeToSupport'),
        link: null,
        linkLabel: t('mdguides.openQuestions.writeToSupportLink'),
        action: () => {
          if (i18n.language.startsWith('de')) {
            window.open('https://support.lemontaps.com/de/support/tickets/new', '_blank');
          } else {
            window.open('https://support.lemontaps.com/en/support/tickets/new', '_blank');
          }
        },
      },
    ],
    [activeLanguage, i18n.language, t],
  );
};
