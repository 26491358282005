import { dispatchTypes } from '../actions';

/**
 * a temporary state to hold the temporary frequently changing properties
 * these values might be useful for live sharing and previewing changes before saving them
 * @param state
 * @param action
 * @returns state.temp
 */

export type Temporary = {
  tempPrimaryButtonColor?: string;
  tempSidebarColor?: string;
  tempSidebarColorText?: string;
  tempSidebarColorActive?: string;
  tempSidebarColorActiveText?: string;
  tempChatColor?: string;
};

let initials = {
  tempPrimaryButtonColor: undefined,
  tempSidebarColor: undefined,
  tempSidebarColorText: undefined,
  tempSidebarColorActive: undefined,
  tempSidebarColorActiveText: undefined,
  tempChatColor: undefined,
};

const tempReducer = (state = initials, action) => {
  switch (action.type) {
    case dispatchTypes.TEMP.THEME.SETCOLOR:
      return {
        ...state,
        ...action.payload,
      };

    case dispatchTypes.TEMP.THEME.RESETCOLORS:
      return {
        ...state,
        tempPrimaryButtonColor: undefined,
        tempSidebarColor: undefined,
        tempSidebarColorText: undefined,
        tempSidebarColorActive: undefined,
        tempSidebarColorActiveText: undefined,
        tempChatColor: undefined,
      };
    default:
      return state;
  }
};

export default tempReducer;
