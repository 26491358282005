import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';
import { UnitSelector } from '@/views/pages/share-profile/UnitSelector';
import { useMemo, useState } from 'react';
import useTierInfo from './useTierInfo';
import { usePrivateFeatureFlag } from './useFeatureFlags';
import { useAppSelector } from '@/application/hooks';
import { FEATURE } from '@/shared/constants';
import { useEditRights } from './useEditRights';
import { Account } from '@/shared/types/api';
import { getMainUnitSync } from '@/shared/util';

export const useUnitSelectorForUidParam = (uid?: number) => {
  const account = useAppSelector<Account>(state => state.account);
  const { isFeatureAllowed } = useTierInfo();

  const flag_uidParam = usePrivateFeatureFlag('profileShare_uidParam');
  const { editRights } = useEditRights();
  const uidFeatureAllowed =
    flag_uidParam &&
    editRights.profileShare_uidParam &&
    isFeatureAllowed(FEATURE.UNITS) &&
    isFeatureAllowed(FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN);

  // initialize by prop or by current implicit behavior when uid param is not provided in profile link
  const [selectedUnitId, setSelectedUnitId] = useState(uid || getMainUnitSync(account)?.id || null);
  const uidForProfileLink = selectedUnitId === null ? null : selectedUnitId;

  const showUnitSelector = uidFeatureAllowed && account.units?.length > 0;
  const headerActions = useMemo(
    () =>
      showUnitSelector
        ? createHeaderAction(
            <UnitSelector
              units={account.units}
              selectedUnitId={selectedUnitId}
              onChange={setSelectedUnitId}
            />,
          )
        : null,
    [selectedUnitId, account, showUnitSelector],
  );
  useHeaderActions(headerActions);

  return {
    uidForProfileLink: uidFeatureAllowed ? uidForProfileLink : undefined,
  };
};
