import { Button } from '../../../components/common';
import styled from 'styled-components';

export function ContinueButton(props: {
  buttonTitle: string;
  nextFunction: Function;
  disabled?: boolean;
}) {
  return (
    <ButtonWrapper>
      <StyledButton
        text={props.buttonTitle}
        onClick={props.nextFunction}
        disabled={props.disabled}
      />
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid #868686;
  padding: 2rem 0;
  background-color: #ffffff;
`;
const StyledButton = styled(Button)`
  width: 325px;
  @media (max-width: 767px) {
    width: 270px;
  }
`;
