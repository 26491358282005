import React from 'react';

import QRBG from '../../components/qrcode-background';
import { routePaths } from '../../../infrastructure/constants';
import withNav from '../../../infrastructure/hoc/withNav';

const QRCodeBG = () => <QRBG />;

export default withNav(
  QRCodeBG,
  {
    tTitle: 'qrWallpaper',
    showBackButton: {
      linkTo: routePaths.SHARE_PROFILE,
    },
  },
  {},
);
