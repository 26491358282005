export const withKeyEventHandler = (cb: Function) => {
  return (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      cb(e);
    }
  };
};

const dateFieldKeys = ['createdOn', 'birthday', 'deletedAt'];

export const isDateField = (field: string) => {
  return dateFieldKeys.includes(field);
};
