import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { Box, List, styled } from '@mui/material';
import { setSidebarCollapsed, setSidebarSettingsCollapsed } from '@/application/actions/ui';
import { useProfileItems } from '@/infrastructure/hooks/Navigation/useProfileItems';
import { useMdItems } from '@/infrastructure/hooks/Navigation/useMdItems';
import { useTeamSettingsItems } from '@/infrastructure/hooks/Navigation/useTeamSettingsItems';
import { BackButton, CollapseIcon, Logo, SingleListSet } from './components';
import { NFCCardConnector } from './components/NFCCardConnector';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { AdminManagementButton } from './components/AdminManagementButton';
import { useTranslation } from 'react-i18next';
import { CSS_VARS } from '@/infrastructure/constants';

export const Sidebar = () => {
  const profileItems = useProfileItems();
  const mdItems = useMdItems();
  const teamSettingsItems = useTeamSettingsItems();
  const { t } = useTranslation();

  const { isStarter, isMinEssentials, isTrial, isAnyAdmin, isOnlySingle } = useTierInfo();

  const settingsCollapsed = useAppSelector(state => state.ui.sidebarSettingsCollapsed);
  const sidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  const dispatch = useAppDispatch();

  return (
    <Box
      component='nav'
      sx={{
        width: sidebarCollapsed
          ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
          : CSS_VARS.LT_SIDEBAR_WIDTH_VAR,
        minWidth: sidebarCollapsed
          ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
          : CSS_VARS.LT_SIDEBAR_WIDTH_VAR,
        maxWidth: sidebarCollapsed
          ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
          : CSS_VARS.LT_SIDEBAR_WIDTH_VAR,
        bgcolor: 'sidebar.main',
        color: 'sidebar.contrastText',
        height: '100vh',
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box height={CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR} sx={{ py: '1.6rem', px: '0.8rem' }}>
        {settingsCollapsed ? (
          <BackButton
            sidebarCollapsed={sidebarCollapsed}
            onClick={() => dispatch(setSidebarSettingsCollapsed(false))}
          />
        ) : (
          <Logo />
        )}
      </Box>
      <ListWrapper component='div'>
        {!settingsCollapsed && <SingleListSet items={profileItems} title={t('myProfile')} />}
        {!settingsCollapsed && isMinEssentials && isAnyAdmin && !isOnlySingle && (
          <SingleListSet items={mdItems} title={t('admin')} />
        )}
        {!settingsCollapsed && !sidebarCollapsed && isStarter && <AdminManagementButton />}
        {settingsCollapsed && !isStarter && (
          <SingleListSet items={teamSettingsItems} collapsable={false} />
        )}
      </ListWrapper>
      <Box
        sx={{
          marginTop: 'auto',
          marginBottom: '6rem',
          paddingTop: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {(isStarter || isTrial) && <NFCCardConnector sidebarCollapsed={sidebarCollapsed} />}
      </Box>
      <CollapseIcon
        onClick={() => dispatch(setSidebarCollapsed(!sidebarCollapsed))}
        sidebarCollapsed={sidebarCollapsed}
      />
    </Box>
  );
};

const ListWrapper = styled(List)({
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '0 0.8rem',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
});
