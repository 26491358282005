import React from 'react';
import styled from 'styled-components';

interface Props {
  pageHeading: string;
  pageHeadingSecond: string;
}
const OnboardingHeading = (props: Props): JSX.Element => {
  const { pageHeading, pageHeadingSecond } = props;
  return (
    <>
      <Heading>{pageHeading}</Heading>
      {pageHeadingSecond && <SubHeading>{pageHeadingSecond}</SubHeading>}
    </>
  );
};
export default OnboardingHeading;

const Heading = styled.p`
  color: #35366e;
  font-style: normal;
  max-width: 90%;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0;

  @media (max-width: 767px) {
    font-size: 18px;
    text-align: center;
  }
`;
const SubHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 5px;
  color: #979797;

  justify-content: center;

  @media (max-width: 767px) {
    font-size: 12px;
    text-align: center;
  }
`;
