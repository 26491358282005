import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { appendParamsToLink } from '@/shared/util';
import doShareAction, { ShareType } from '@/views/components/share/shareLink';
import { LinkedIn, MailOutline, Share, WhatsApp } from '@mui/icons-material';
import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';

const sharingItems: Array<{
  icon: React.ReactNode;
  shareType?: ShareType;
}> = [
  {
    icon: (
      <WhatsApp
        sx={{
          color: 'white',
          backgroundColor: '#45D354',
          fontSize: 40,
          borderRadius: 0.5,
          p: 0.5,
        }}
      />
    ),
    shareType: 'whatsapp',
  },
  {
    icon: (
      <LinkedIn
        sx={{
          color: '#0177B5',
          fontSize: 54,
          borderRadius: 0.5,
        }}
      />
    ),
    shareType: 'linkedin',
  },
  {
    icon: (
      <MailOutline
        sx={{
          color: '#fff',
          backgroundColor: 'text.primary',
          borderRadius: 0.5,
          fontSize: 40,
          p: 0.5,
        }}
      />
    ),
    shareType: 'mail',
  },
  {
    icon: (
      <Share
        sx={{
          color: '#fff',
          backgroundColor: 'secondary.main',
          borderRadius: 0.5,
          fontSize: 40,
          p: 0.5,
          ml: 0.7,
        }}
      />
    ),
    shareType: 'webShare',
  },
];

type Props = BoxProps & { profileUrl: string };

const ShareBar = (props: Props) => {
  const { t } = useAppTranslation();
  const { desktopView } = useDesktopView();
  return (
    <Box {...props}>
      <Typography variant={desktopView ? 'body2' : 'h3'}>
        {t(`shareProfile.profileLink.shareVia${desktopView ? '' : 'Media'}`)}
      </Typography>
      <Box display={'flex'} alignItems={'center'} mt={1} gap={1}>
        {sharingItems.map((item, index) => {
          if (item.shareType === 'webShare' && !navigator.share) return null;
          return (
            <Box
              key={index}
              sx={{ cursor: 'pointer' }}
              onClick={async () => {
                if (item.shareType === 'webShare') {
                  try {
                    await navigator.share({
                      url: appendParamsToLink(props.profileUrl, { utm_medium: 'emailshare' }),
                      title: t('hereIsMyContact'),
                      text: t('myContact'),
                    });
                  } catch (error) {
                    if (!error.name.startsWith('AbortError')) toast.error(t('errorSharingData'));
                  }
                } else {
                  doShareAction(item.shareType, props.profileUrl);
                }
              }}
            >
              {item.icon}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShareBar;
