import styled from 'styled-components';
import {
  FEATURE,
  THEME_BOX_STYLES,
  THEME_LINKS_FILES_POSITIONS,
  THEME_LINK_EDIT_MODES,
} from '../../../../../../../shared/constants';
import { Account, Link, LinkType, Theme } from '../../../../../../../shared/types/api';
import { Employee } from '../../../../../../../shared/types/api/employee.type';
import { getLinkCountString } from '../utils/helpers';
import { TFunction } from 'i18next';
import LinkBoxThemed from '../../../../../edit-profile/link-box-themed';
import DnD from './dnd';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import AutoComplete, { AutoCompleteValue } from '../../../../../../components/common/autoComplete';
import { withSilentAccessToken } from '../../../../../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../../../../config/config';
import { PERMISSIONS, routePaths } from '../../../../../../../infrastructure/constants';
import { useAppSelector } from '../../../../../../../application/hooks';
import { Spacer } from '../../../../customization/common';
import CompanyDetailsLinkBoxThemed from './CompanyDetailsLinkBoxThemed';
import { ThemeLinksFilesPosition } from '../../../../../../../shared/types/global';
import { StyledLink, SubsectionWrapper } from '../../styles';
import LtIconButton from '../../../../../../components/common/lt-icon-button';
import { CustomLinkType } from '../hooks/useBulk';
import { ReactComponent as AddIcon } from '../../../../../../images/add-icon.svg';
import useTierInfo from '../../../../../../../infrastructure/hooks/useTierInfo';
import TeaserBlur from '../../../../teaser/teaserBlur';
import { GoArrowUpRight } from 'react-icons/go';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
// TODO resolve any
interface Props {
  employee: Employee;
  links: Array<Link>;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
  account: Account;
  themeLinkTypes: Array<LinkType>;
  bulkProps: any;
  popupProps: any;
  t: TFunction;
  maxOrder: number;
  themeLinksPosition: ThemeLinksFilesPosition;
  showAddNewLinkPopup: () => void;
  onDeleteLinkClick?: (link: Link) => void;
  unitId?: number;
}

export interface BulkLink extends Link {
  existsInDB?: boolean;
  linkIds?: number[];
  countToRender?: string;
  extendToAll?: boolean;
  existsForAll?: boolean;
  // ADD REST OF PROPS
}

const LinksUi = (props: Props) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const { bulkProps, popupProps, unitId } = props;
  const { employee, links, themeLinkTypes, userLinksProps, t } = props;
  const { bulkEdit, bulkLinks, handleBulkDelete } = bulkProps;
  const { showCreateEditLinkPopup, setShowLinkTypePopup, showExtendLinkPopup, selectedLinkRef } =
    popupProps;
  const [linkTemplates, setLinkTemplates] = useState<Link[]>(props.links);
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);
  const { setBulkLinks } = props.bulkProps;
  const { getAccessTokenSilently } = useAuth0();
  const [companyLinksList, setCompanyLinksList] = useState<Link[]>([]);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const isDisabled = autoCompleteValues.length === 0;
  const noTemplates = isDisabled && linkTemplates.length === 0;
  const {
    editRights: { linkEditMode },
  } = useEditRights();
  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(`${config.API_BASE_URL}theme/link-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      ).then(res => {
        setLinkTemplates(res.data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureAllowed]);
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'theme/global-links', {
          params: { unitId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
    ).then(res => {
      setCompanyLinksList(res.data.data);
    });
  }, [getAccessTokenSilently, props.employee, unitId]);

  useEffect(() => {
    setAutoCompleteValues(
      linkTemplates
        ?.filter(link => !bulkLinks?.find(x => x.linkTemplateId === link.id))
        ?.filter(link => !links?.find(x => x.linkTemplateId === link.id))
        ?.filter(link => !companyLinksList?.find(x => x.linkTemplateId === link.id))
        .map((theme, index) => {
          return {
            id: theme.id,
            description: theme.name,
            index,
            otherData: theme,
          };
        }),
    );
  }, [linkTemplates, bulkLinks, links, companyLinksList]);

  const saveAutoValue = (item: AutoCompleteValue) => {
    if (item === null) return;
    if (bulkLinks) {
      setBulkLinks([
        ...bulkLinks,
        {
          id: item.otherData?.themeLinkType?.linkType?.id,
          name: item.otherData.name,
          link: item.otherData.link,
          order: linkTemplates.length * 10,
          canDisplayOnProfile: true,
          linkTemplateId: item.otherData.id,
          linkType: item.otherData.themeLinkType.linkType,
          existsForAll: true,
        },
      ]);
    } else {
      if (item != null) {
        const addedTemplateLink = {
          id: Math.floor(Math.random() * 1000),
          name: item.otherData.name,
          link: item.otherData.link,
          isShallow: true,
          canDisplayOnProfile: true,
          order: props.maxOrder + 10,
          linkTemplateId: item.otherData.id,
          linkType: item.otherData.themeLinkType.linkType,
          linkTypeId: item.otherData.themeLinkType.linkType.id,
        };

        userLinksProps.setUserLinks([...userLinksProps.userLinks, addedTemplateLink]);
      }
    }
  };

  const renderLinks = (links: Link[]) => {
    return links?.map(link => (
      <LinkBoxThemed
        key={link.id}
        employeeId={employee.id}
        link={link}
        onEditClick={item =>
          linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS
            ? setShowLinkTypePopup(themeLinkTypes.find(t => t.id === item.linkType.id))
            : showCreateEditLinkPopup(item)
        }
        onDeleteClick={props.onDeleteLinkClick}
        // double check, might cause issues for other cases
        defaultLinkId={userLinksProps.defaultLinkId}
        theme={theme}
        userIsAdmin
        blueBackground={typeof link.linkTemplateId === 'number'}
        userLinksProps={userLinksProps}
      />
    ));
  };

  const { setUserLinks } = userLinksProps || {};
  const dNdProps = { links, setUserLinks, renderLinks };

  const themeLinks = useMemo(
    () =>
      companyLinksList.map((link, index) => (
        <CompanyDetailsLinkBoxThemed
          key={index}
          // @ts-ignore:next-line
          link={link || []}
          hideOptions
          isThemeEntity
        />
      )),
    [companyLinksList],
  );

  return (
    <>
      {linkEditMode !== THEME_LINK_EDIT_MODES.OPTIONS && (
        <>
          <TeaserBlur condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} badge='Enterprise'>
            <AutoCompleteContainer>
              <AutoComplete
                options={autoCompleteValues}
                onSave={saveAutoValue}
                placeHolder={`${t(
                  noTemplates ? 'linkTab.noTemplates' : 'linkTab.searchStandardLinks',
                )}...`}
                disabled={isDisabled}
              />
            </AutoCompleteContainer>
            {noTemplates && isThemeAdmin && (
              <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.DATA_TEMPLATES}>
                {t('linkTab.createTemplate')}
                <GoArrowUpRight size={17} />
              </StyledLink>
            )}
          </TeaserBlur>
          <Spacer size={bulkEdit ? 25 : 9} />
        </>
      )}
      <OrManualHint>{t('linkTab.orAddIndividualLink')}</OrManualHint>
      <SubsectionWrapper>
        <LtIconButton
          text={linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS ? t('editLinks') : t('addLink')}
          icon={<AddIcon />}
          onClick={props.showAddNewLinkPopup}
          boxStyle={THEME_BOX_STYLES.ROUNDED}
          backgroundCss={muiTheme.palette.primaryButton.main}
        />
      </SubsectionWrapper>
      {props.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeLinks}
      {bulkEdit &&
        bulkLinks?.map((link: BulkLink, index: number) => (
          <>
            {(link.existsInDB || link.existsForAll) && (
              <CommonLinkSpan>{getLinkCountString(link, t)}</CommonLinkSpan>
            )}

            <LinkBoxThemed
              key={index}
              link={link}
              // OnEditClick should be conditional to handle case of options
              onEditClick={item =>
                linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS
                  ? setShowLinkTypePopup(themeLinkTypes.find(t => t.id === item.linkType.id))
                  : showCreateEditLinkPopup(item)
              }
              onDeleteClick={link => {
                // setSelectedLink(link);
                selectedLinkRef.current = link;
                handleBulkDelete();
              }}
              onExtendClick={() => showExtendLinkPopup(link)}
              theme={theme}
              userIsAdmin
              {...bulkProps}
            />
          </>
        ))}

      <DnD {...dNdProps} />
      {props.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeLinks}
    </>
  );
};

export default LinksUi;

const CommonLinkSpan = styled.span`
  font-size: 1rem;
`;
const AutoCompleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrManualHint = styled.div`
  text-align: center;
  color: #666666;
  font-size: small;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 5px;
`;
