import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isFileValid, isNotEmptyArray } from '../../../../infrastructure/helper';
import { SubsectionSpan } from './common';
import CloudUpload from '../../../images/cloud-upload.svg';
import ImageCropper from '../../../components/image-cropper';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import { CSS_VARS } from '@/infrastructure/constants';

interface Props {
  bannerImage: string | File;
  setBannerImage: (value: string | File) => void;
  profileImage: string | File;
  setProfileImage: (value: string | File) => void;
  logoHeaderImage: string | File;
  setLogoHeaderImage: (value: string | File) => void;
}
type ImageType = 'banner' | 'profile' | 'logo' | '';

const Images = (props: Props) => {
  let fileInput = useRef(null);
  const { t } = useTranslation();

  const {
    bannerImage,
    setBannerImage,
    profileImage,
    setProfileImage,
    logoHeaderImage,
    setLogoHeaderImage,
  } = props;

  const [imageMode, setImageMode] = useState<ImageType>('');
  const [showCropper, setShowCropper] = useState('');
  const [cropperProps, setCropperProps] = useState({});

  const [croppedBannerImageUrl, setCroppedBannerImageUrl] = useState(null);
  const [croppedProfileImageUrl, setCroppedProfileImageUrl] = useState(null);
  const [croppedLogoHeaderImageUrl, setCroppedLogoHeaderImageUrl] = useState(null);

  const bannerImageUrl = croppedBannerImageUrl || bannerImage;
  const profileImageUrl = croppedProfileImageUrl || profileImage;
  const logoHeaderImageUrl = croppedLogoHeaderImageUrl || logoHeaderImage;

  const onImageClick = (fileType: ImageType) => {
    setImageMode(fileType);
    fileInput.current.click();
  };

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);

        switch (imageMode) {
          case 'banner':
            setCropperProps({ crop: { width: 100, x: 0, y: 0, aspect: 16 / 9 } });
            reader.onload = () => {
              setShowCropper(reader.result as string);
            };
            break;
          case 'profile':
            setCropperProps({ circularCrop: true });
            reader.onload = () => {
              setShowCropper(reader.result as string);
            };
            break;
          case 'logo':
            setCropperProps({ noAspectRatio: true, crop: { width: 100, height: 100 } });
            reader.onload = () => {
              setShowCropper(reader.result as string);
            };
            break;
          default:
            console.warn('default case');
        }
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = fileInput.current.files[0].name.split('.').pop();
    setShowCropper('');

    let file;
    switch (imageMode) {
      case 'banner':
        file = new File([blob], `banner.${ext}`);
        setBannerImage(file);
        setCroppedBannerImageUrl(croppedImageUrl);
        break;
      case 'profile':
        file = new File([blob], `profile.${ext}`);
        setProfileImage(file);
        setCroppedProfileImageUrl(croppedImageUrl);
        break;
      case 'logo':
        file = new File([blob], `logoHeader.${ext}`);
        setLogoHeaderImage(file);
        setCroppedLogoHeaderImageUrl(croppedImageUrl);
        break;
      default:
        console.warn('default case, cropper');
    }
  };

  const handleX = (mode: ImageType) => {
    switch (mode) {
      case 'banner':
        setBannerImage(null);
        setCroppedBannerImageUrl(null);
        break;
      case 'profile':
        setProfileImage(null);
        setCroppedProfileImageUrl(null);
        break;
      case 'logo':
        setLogoHeaderImage(null);
        setCroppedLogoHeaderImageUrl(null);
        break;
      default:
        console.warn('Case not found');
    }
  };

  return (
    <>
      {showCropper && (
        <ImageCropper
          image={showCropper}
          onUpload={url => onUploadClick(url)}
          onClose={() => setShowCropper('')}
          {...cropperProps}
        />
      )}

      <ImageOptionsContainer>
        <ImageOptionContainer>
          <TooltipInfo
            text={t('md.profileDesign.coverImageTooltip')}
            placement='top'
            onHover={true}
          >
            <SubsectionSpan help marginBottom>
              {t('defaultCoverImage')}
            </SubsectionSpan>
          </TooltipInfo>
          {bannerImageUrl ? (
            <PreviewContainer>
              <img key={bannerImageUrl} src={bannerImageUrl} alt='banner' />
              <CloseX onClick={() => handleX('banner')}>&times;</CloseX>
            </PreviewContainer>
          ) : (
            <UploadBox onClick={() => onImageClick('banner')}>
              <img src={CloudUpload} alt='upload' />
              <SubsectionSpan>{t('uploadCoverImage')}</SubsectionSpan>
            </UploadBox>
          )}
        </ImageOptionContainer>
        <ImageOptionContainer>
          <TooltipInfo
            text={t('md.profileDesign.profileImageTooltip')}
            placement='top'
            onHover={true}
          >
            <SubsectionSpan help marginBottom>
              {t('defaultAvatar')}
            </SubsectionSpan>
          </TooltipInfo>
          {profileImageUrl ? (
            <PreviewContainer>
              <img key={profileImageUrl} src={profileImageUrl} alt='profile' />
              <CloseX onClick={() => handleX('profile')}>&times;</CloseX>
            </PreviewContainer>
          ) : (
            <UploadBox onClick={() => onImageClick('profile')}>
              <img src={CloudUpload} alt='upload' />
              <SubsectionSpan>{t('uploadAvatar')}</SubsectionSpan>
            </UploadBox>
          )}
        </ImageOptionContainer>
        <ImageOptionContainer>
          <TooltipInfo text={t('md.profileDesign.logoImageTooltip')} placement='top' onHover={true}>
            <SubsectionSpan help marginBottom>
              {t('logo')}
            </SubsectionSpan>
          </TooltipInfo>
          {logoHeaderImageUrl ? (
            <PreviewContainer>
              <img key={logoHeaderImageUrl} src={logoHeaderImageUrl} alt='logo' />
              <CloseX onClick={() => handleX('logo')}>&times;</CloseX>
            </PreviewContainer>
          ) : (
            <UploadBox onClick={() => onImageClick('logo')}>
              <img src={CloudUpload} alt='upload' />
              <SubsectionSpan>{t('uploadLogo')}</SubsectionSpan>
            </UploadBox>
          )}
        </ImageOptionContainer>
      </ImageOptionsContainer>

      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default Images;

export const ImageOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  padding: 20px 15px 0px;
`;

export const ImageOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  &:not(:last-child) {
    margin-right: 1.5vw;
    margin-bottom: 1.5vw;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2px dashed #c1cbdc;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 3px 5px;
  height: 5vw;
  cursor: pointer;

  img {
    width: 2vw;
    filter: invert(22%) sepia(87%) saturate(2678%) hue-rotate(236deg) brightness(94%) contrast(96%);
  }

  span {
    color: #4748ec;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  position: relative;
  border: 2px dashed ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 3px 5px;
  height: 5vw;

  img {
    object-fit: contain;
    width: 100%;
  }
`;

export const CloseX = styled.span`
  position: absolute;
  right: 0.25vw;
  cursor: pointer;
`;
