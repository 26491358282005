import styled from 'styled-components';
import { DESKTOP_BREAK_POINT } from '../../../shared/constants';
import { CSS_VARS } from '@/infrastructure/constants';

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const CardsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
`;

export const CardSpan = styled.span`
  color: #181a5a;
  font-size: 1.8rem;
  font-weight: 600;
`;

export const PrimaryButton = styled.button`
  width: 60%;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.2rem;
  background-color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  padding: 1.5rem 0;
  border: none;
  border-radius: 1rem;
`;

export const StyledVideo = styled.iframe`
  width: 95%;
  height: 25rem;
  @media (min-width: ${DESKTOP_BREAK_POINT}px) {
    height: 35rem;
  }
  margin-bottom: 3rem;
`;
