import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import { BiChevronDown, BiSearchAlt2 } from 'react-icons/bi';
import RenderUnits from './renderUnits';
import { Spacer } from '../../../customization/common';
import { Unit } from '@/shared/types/api/unit.type';
import { Employee } from '@/shared/types/api/employee.type';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { assignUnits } from '@/infrastructure/apis/md/units';
import { toast } from 'react-hot-toast';
import DeletePopup from '@/views/components/common/deletePopup';
import { BulkButton } from '../../../common/table';
import { CSS_VARS } from '@/infrastructure/constants';
import { fetchUserData } from '@/application/actions/account';
import { useDispatch } from 'react-redux';

interface Props {
  units: Array<Unit>;
  selectedIds: readonly string[];
  localEmployees: Array<Employee>;
  setLocalEmployees: Dispatch<SetStateAction<Employee[]>>;
  onSaveSuccess?: () => void;
}

const UnitAssign = (props: Props) => {
  const { user, logout } = useAuth0();
  const themeAdmin = user['https://lemontaps.com/claim/roles'].includes('theme_admin');
  const [filteredUnits, setFilteredUnits] = useState<Unit[]>(props.units);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const unassignSelected = async () => {
    toast.loading(t('mdLeads.removingFromUnit'), { id: 'removingunits' });
    const unitsResponse = await assignUnits(getAccessTokenSilently, props.selectedIds, null, []);
    if (props.selectedIds.includes(user.sub))
      dispatch(fetchUserData(getAccessTokenSilently, logout));
    if (unitsResponse.data.isSuccess === false) {
      toast.error(t('mdLeads.errorReadUnits', { id: 'removingunits' }));
      return;
    }
    toast.success(t('mdLeads.successRemovingFromUnit'), { id: 'removingunits' });
    props.setLocalEmployees(props.localEmployees.filter(x => !props.selectedIds.includes(x.id)));
    setShowPopup(false);
  };

  if (!themeAdmin && props.units.length === 1) {
    return (
      <>
        <BulkButton redColor onClick={() => setShowPopup(true)}>
          <div>{t('mdLeads.unassignFromUnit')}</div>
        </BulkButton>

        {showPopup && (
          <DeletePopup
            onCancel={() => setShowPopup(false)}
            onDeleteClick={unassignSelected}
            label={t('mdLeads.confirmRemoveUnit', { count: props.selectedIds.length })}
            deleteText={t('yes')}
          />
        )}
      </>
    );
  }

  return (
    <>
      <BulkButton
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(e.currentTarget);
        }}
        color={getComputedStyle(document.documentElement, null).getPropertyValue(
          CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
        )}
      >
        <span>{t('unitAssign')}</span>
        <BiChevronDown size={15} />
      </BulkButton>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <SearchContainer>
          <BiSearchAlt2 size={20} color='grey' style={{ position: 'absolute', left: 28 }} />
          <input
            name='search'
            type='text'
            placeholder={t('searchUnits')}
            style={{ paddingLeft: 45 }}
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);

              setFilteredUnits(
                props.units.filter(unit =>
                  unit.niceName.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
                ),
              );
            }}
          />
        </SearchContainer>
        <Spacer size={15} />
        <RenderUnits
          selectedIds={props.selectedIds}
          filteredUnits={filteredUnits}
          units={props.units}
          setLocalEmployees={props.setLocalEmployees}
          localEmployees={props.localEmployees}
          onSaveStart={() => setAnchorEl(null)}
          onSaveSuccess={props.onSaveSuccess}
        />
      </StyledMenu>
    </>
  );
};

export default UnitAssign;

const StyledMenu = styled(Menu)`
  ul {
    width: 25rem;
    padding: 15px;
    font-size: 1rem;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  position: 'relative';

  input[type='text'] {
    margin-bottom: 0;
    width: 100%;
  }

  .form-label {
    width: 0;
  }

  .form-input {
    width: 60%;
  }
`;
