import styled, { css } from 'styled-components';
import { THEME_BOX_STYLES } from '../../../shared/constants';
import { ReactComponent as FolderIconSvg } from '../../images/folder.svg';
import { ThemeBoxStyle } from '@/shared/types/global';
import { IconButton } from '@mui/material';

export const BoxWrapper = styled.div`
  ${props => props.blueBackground && 'background-color: #eff3fe'};
  border: 2px solid #f4f4f4;

  box-sizing: border-box;
  padding: 15px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #7a7a7a;
  position: relative;
  width: 100%;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${(props: { boxStyle: ThemeBoxStyle }) =>
    props.boxStyle === THEME_BOX_STYLES.ANGULAR
      ? css`
          border-radius: 0;
        `
      : css`
          border-radius: 30px;
        `}
`;

export const Name = styled.span`
  padding-left: 15px;
  width: ${(props: { fullwidth: boolean }) => (props.fullwidth ? '75%' : '60%')};
  text-overflow: clip;
  overflow-wrap: break-word;
  font-size: 1.4rem;
`;

export const ImagesBox = styled.div`
  position: absolute;
  right: 5px;
  margin: auto;

  & > img {
    padding-right: 12px;
    cursor: pointer;
    height: 21px;
  }
`;

export const FolderIcon = styled(FolderIconSvg)`
  fill: ${props => props.theme.fileBoxColor};
`;

export const StyledIconWrapper = styled(IconButton)`
  width: 4rem;
  height: 4rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
