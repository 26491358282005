import { useCallback, useState } from 'react';

import { routePaths } from '../../../../infrastructure/constants';
import withNav from '../../../../infrastructure/hoc/withNav';
import ViewsContainer from '../../../components/common/containers/ViewsContainer';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import StartTrialPopup from '../../../components/popups/start-trial';
import { Redirect } from 'react-router-dom';
import {
  CollectLeadsSection,
  FullFeatureComparisonSection,
  TiersSection,
  VideoSection,
} from './Sections';
import VideoSectionExpired from './Sections/VideoSectionExpired';
import UpgradeRequestPopup from '@/views/components/popups/upgradeRequest';

const UpgradeLeadgen = () => {
  const { isStarter, canStartTrial } = useTierInfo();

  const [startFreeTrialPopupShown, setStartFreeTrialPopupShown] = useState(false);
  const [upgradeRequestPopupShown, setUpgradeRequestPopupShown] = useState(false);

  const popupOpenHandler = useCallback(() => {
    setStartFreeTrialPopupShown(true);
  }, []);

  if (!isStarter) {
    return <Redirect exact to={routePaths.MD.ROOT} />;
  }

  return (
    <>
      {startFreeTrialPopupShown && (
        <StartTrialPopup onClosed={() => setStartFreeTrialPopupShown(false)} />
      )}
      {upgradeRequestPopupShown && (
        <UpgradeRequestPopup onClosed={() => setUpgradeRequestPopupShown(false)} />
      )}
      <ViewsContainer maxWidth='9999rem' style={{ padding: '5rem 0' }}>
        {!canStartTrial ? (
          <VideoSectionExpired onShowUpgradePopup={() => setUpgradeRequestPopupShown(true)} />
        ) : (
          <VideoSection isLeadgen={true} onShowUpgradePopup={popupOpenHandler} />
        )}
        <CollectLeadsSection />
        <TiersSection canStartTrial={canStartTrial} onShowUpgradePopup={popupOpenHandler} />
        <FullFeatureComparisonSection />
      </ViewsContainer>
    </>
  );
};

export default withNav(
  UpgradeLeadgen,
  {
    tTitle: () =>
      window.location.pathname === routePaths.UPGRADE_LEADGEN ||
      window.location.pathname === routePaths.CONTACTS.ROOT
        ? 'teaser.leadRecording'
        : 'Admin Management',
  },
  {},
);
