import { Box, TableCell, TableCellProps, useTheme } from '@mui/material';
import { LineClamp } from '@/views/components/common/LineClamp';
import { lineClampCount } from '../utils/helpers';

export type Props = {
  children: React.ReactNode;
  dim?: 's' | 'm' | 'l';
  tooltipText?: string;
  noPadding?: boolean;
} & TableCellProps;

export const CustomTableCell = ({ children, dim, sx, tooltipText, noPadding, ...rest }: Props) => {
  const theme = useTheme();
  const width = !dim || dim === 'm' ? 20 : dim === 'l' ? 30 : 15;
  return (
    <TableCell
      {...rest}
      sx={{
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: theme.spacing(width - 2),
        width: theme.spacing(width),
        maxWidth: theme.spacing(width + 2),
        p: 0,
        '&:first-child': {
          borderRight: 'none',
        },
        wordWrap: 'break-word',
        cursor: 'default',
        ...sx,
      }}
    >
      <Box width='100%' height='100%' py={!noPadding && '0.6rem'} px={!noPadding && '1.6rem'}>
        <LineClamp lineCount={lineClampCount} text={tooltipText}>
          {children}
        </LineClamp>
      </Box>
    </TableCell>
  );
};
