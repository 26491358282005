import { useCallback, useEffect, useRef, useState } from 'react';
import { FullScreenContainer } from './FullScreenContainer';
import { Box } from '@mui/material';
import { VideoToolsOverlay } from './VideoToolsOverlay';
import { styled } from '@mui/system';
import { useIsMounted } from '@/infrastructure/hooks/useIsMounted';
import {
  MAX_FILE_SIZE,
  PAGE_CONTAINER_SELECTOR,
  CameraAvailabilityEnum,
  getCameras,
  getStream,
  getTorchAvailability,
  toggleTorch,
  stopStream,
} from '../helpers';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CameraNotAvailable } from './CameraNotAvailable';
import { Loader } from '@/views/components/common';
import { routePaths } from '@/infrastructure/constants';

type Props = {
  onCapture: (imgDataUrl: File, dataUrl: string | ArrayBuffer, isSelectedFile?: boolean) => void;
  onHelpClick?: () => void;
  useFullScreenVideo?: boolean;
};

const AbsoluteContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 100,
  display: 'flex',
  overflow: 'hidden',
});

const Video = styled('video')({
  margin: 'auto',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const Image = styled('img')(props => ({
  margin: 'auto',
  ...(props.useFullScreenVideo
    ? { position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }
    : { width: '100%' }),
}));

export const CaptureScreen = ({ onCapture, onHelpClick, useFullScreenVideo }: Props) => {
  const history = useHistory();
  const { t } = useAppTranslation();

  const [cameraAvailability, setCameraAvailability] = useState(CameraAvailabilityEnum.LOADING);
  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>([]);
  const [currentStream, setCurrentStream] = useState<MediaStream | null>(null);
  const [isTorchOn, setIsTorchOn] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [captured, setCaptured] = useState(false);

  // note about videoRefElement
  // It has to be stored as state, since we need an additional re-render once the ref becomes available, so that we can show the camera stream
  // Otherwise (if stored as ref) the test in line 102 (`if (!currentStream || !videoRefElement)`) would return true and no stream would be shown on screen
  // This would happen _only_ on PWA standalone app added to the homescreen on an iPhone (and only in about 20% of the time)
  const [videoRefElement, setVideoRefElement] = useState<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cropCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const isTorchAvailable = getTorchAvailability(currentStream);

  const mounted = useIsMounted();

  useEffect(() => {
    if (!mounted) return;
    const fetchCameras = async () => {
      const cameras = await getCameras();
      setAvailableCameras(cameras);
    };
    fetchCameras();
  }, [mounted]);

  const startStreamIfPossible = useCallback(async (deviceId?: string | null) => {
    try {
      const stream = await getStream(deviceId);
      setCameraAvailability(CameraAvailabilityEnum.AVAILABLE);
      setCurrentStream(stream);
    } catch (error) {
      console.log('error', error);
      setCameraAvailability(CameraAvailabilityEnum.NOT_AVAILABLE);
    }
  }, []);

  useEffect(() => {
    if (!mounted) return;
    startStreamIfPossible(null);
  }, [mounted, startStreamIfPossible]);

  useEffect(() => {
    return () => {
      stopStream(currentStream);
    };
  }, [currentStream]);

  useEffect(() => {
    if (!currentStream || !videoRefElement) return;

    const video = videoRefElement;
    video.srcObject = currentStream;

    const adjustVideoSize = () => {
      const container = document.querySelector(PAGE_CONTAINER_SELECTOR);
      const containerWidth = container?.clientWidth || 0;
      const containerHeight = container?.clientHeight || 0;
      const videoRatio = video.videoWidth / video.videoHeight;

      if (useFullScreenVideo) {
        //fix height to container height if video height is smaller
        if (videoRatio > 1) {
          video.height = containerHeight;
          video.width = containerHeight * videoRatio;
          //fix width to container width if video width is smaller
        } else {
          video.width = containerWidth;
          video.height = containerWidth / videoRatio;
        }
      } else {
        video.width = containerWidth;
        if (video.clientHeight > containerHeight) {
          video.style.width = '100%';
          video.style.height = '100%';
          video.style.objectFit = 'cover';
        }
      }

      video.play();
    };

    video.addEventListener('loadeddata', adjustVideoSize);
    return () => {
      video.removeEventListener('loadeddata', adjustVideoSize);
    };
  }, [currentStream, videoRefElement, useFullScreenVideo]);

  const capture = () => {
    if (!currentStream) return;
    const video = videoRefElement;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.clientWidth;
    canvas.height = video.clientHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL('image/jpeg');
    setImgSrc(dataUrl);

    setCaptured(true);
  };

  const handleCaptureCancel = useCallback(() => {
    setCaptured(false);
    setImgSrc('');
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  }, []);

  const handleFileSelect = e => {
    const [file] = e.target.files;
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      toast.error(t('businessCardScanner.fileSelectSizeError'), { style: { zIndex: 1001 } });
      return;
    }
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        onCapture(file, reader.result, true);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCaptureAccept = async () => {
    if (!imgSrc) return;
    const img = imgRef.current;
    const cropCanvas = cropCanvasRef.current;
    const cropCanvasContext = cropCanvas.getContext('2d');
    const container = document.querySelector(PAGE_CONTAINER_SELECTOR);

    if (useFullScreenVideo) {
      const imgWidth = img.width;
      const imgHeight = img.height;

      const cropWidth = container.clientWidth;
      const cropHeight = cropWidth / 1.414;
      const cropX = (imgWidth - cropWidth) / 2;
      const cropY = (imgHeight - cropHeight) / 2;

      cropCanvas.width = cropWidth;
      cropCanvas.height = cropHeight;

      cropCanvasContext.drawImage(
        img,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
      );
    } else {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const cropWidth = imgWidth;
      const cropHeight = imgWidth / 1.414;
      const cropX = 0;
      const cropY = (imgHeight - cropHeight) / 2;
      cropCanvas.width = cropWidth;
      cropCanvas.height = cropHeight;
      cropCanvasContext.drawImage(
        imgRef.current,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
      );
    }

    const dataUrl = cropCanvas.toDataURL('image/jpeg');

    const res = await fetch(dataUrl);
    const blob = await res.blob();

    const file = new File([blob], `${uuidv4()}.jpg`, {
      type: blob.type,
    });

    onCapture(file, dataUrl);
  };

  const handleCameraChange = async () => {
    if (availableCameras.length <= 1) return;
    const currentStreamCameraId = currentStream?.getVideoTracks()[0].getSettings().deviceId;
    if (!currentStreamCameraId) return;

    const currentCameraIndex = availableCameras.findIndex(
      x => x.deviceId === currentStreamCameraId,
    );
    const nextIndex = (currentCameraIndex + 1) % availableCameras.length;
    const nextCamera = availableCameras[nextIndex];

    handleCaptureCancel();
    //Triggers efect to clean up the stream
    setCurrentStream(null);
    if (videoRefElement) videoRefElement.srcObject = null;

    setTimeout(() => {
      startStreamIfPossible(nextCamera.deviceId);
    }, 500);
  };

  const handleTorchToggle = () => {
    if (!currentStream || !isTorchAvailable) return;
    toggleTorch(currentStream);
    setIsTorchOn(!isTorchOn);
  };

  if (availableCameras.length === 0) return null;

  if (cameraAvailability === CameraAvailabilityEnum.LOADING) return <Loader />;

  if (cameraAvailability === CameraAvailabilityEnum.NOT_AVAILABLE)
    return <CameraNotAvailable onCloseClick={() => history.push(routePaths.CONTACTS.ROOT)} />;

  return (
    <FullScreenContainer>
      <Box width='100%' height='100%' bgcolor='black' position='relative'>
        <AbsoluteContainer sx={{ opacity: captured ? 0 : 1 }}>
          <Video ref={setVideoRefElement} playsInline muted />
        </AbsoluteContainer>

        <AbsoluteContainer sx={{ opacity: 0 }}>
          <canvas ref={canvasRef} />
          <canvas ref={cropCanvasRef} />
        </AbsoluteContainer>
        {imgSrc && (
          <AbsoluteContainer>
            <Image useFullScreenVideo={useFullScreenVideo} ref={imgRef} src={imgSrc} alt='' />
          </AbsoluteContainer>
        )}
      </Box>
      <VideoToolsOverlay
        onFileSelect={handleFileSelect}
        onCaptureClick={capture}
        onCloseClick={() => history.goBack()}
        onCaptureCancel={handleCaptureCancel}
        onCaptureAccept={handleCaptureAccept}
        onCameraFlipClick={handleCameraChange}
        onTorchClick={handleTorchToggle}
        onHelpClick={onHelpClick}
        cameraFlipVisible={availableCameras?.length > 1}
        isTorchAvailable={isTorchAvailable}
        isTorchOn={isTorchOn}
        captured={captured}
        infoText={t('businessCardScanner.infoText')}
      />
    </FullScreenContainer>
  );
};
