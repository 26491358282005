import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuth0TokenScopeString } from '@/util';

export const hasAccount = async (usernameOrCode: string) => {
  return await axios.get(config.API_BASE_URL + `account/check/${usernameOrCode}`);
};

export const saveCode = async (
  code: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    config.API_BASE_URL + 'account/addcode',
    { code },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};
