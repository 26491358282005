import { CSS_VARS } from '@/infrastructure/constants';
import { DESKTOP_BREAK_POINT } from '@/shared/constants';
import styled from 'styled-components';

export const Section = styled.div`
  padding: 6rem 2rem;
  max-width: 200rem;

  &:first-child {
    padding-top: 0;
  }
`;
export const BlueSection = styled(Section)`
  background: #f4f7fd;
`;

export const Center = styled.div`
  display: grid;
  place-items: center;
`;
export const Heading = styled.div`
  color: #181a5a;
  font-size: ${props => (props.$big ? '2rem' : props.$small ? '1.4rem' : '1.8rem')};
  font-weight: ${props => (!props.$small ? 600 : 500)};
`;
export const Subtext = styled.div`
  color: #747474;
  font-size: 1.4rem;
`;
export const GreenText = styled.div`
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  font-size: 1.4rem;
`;
export const GreenTextContainer = styled.div``;
export const CenterFlexRow = styled.div`
  display: flex;
  align-items: center;
`;
export const GreenButton = styled.button`
  outline: none;
  border: none;
  background: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  color: white;
  border-radius: 10px;
  font-size: 1.4rem;
  padding: 1.5rem 4rem;
`;

export const StyledVideo = styled.iframe`
  display: block;
  width: 40rem;
  max-width: 100%;
  margin: auto;
  height: 23rem;
  max-height: 100%;
  border-radius: 10px;
`;

export const FeatureComparisonButton = styled.a`
  border: none;
  outline: none;
  background: none;
  color: #181a5a;
  font-size: 1.6rem;
  font-weight: 500;
`;

export const MoreInfoButton = styled.a`
  border: none;
  outline: none;
  background: none;
  color: #747474;
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: underline;

  &:hover,
  &:active,
  &:visited {
    text-decoration: underline;
    color: #747474;
  }
`;

export const StyledIconWrapper = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  background: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledVideoContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 7fr) minmax(0, 8fr);
  grid-gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const StyledVideoDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 700px) {
    margin-top: 2rem;
  }
  > * {
    margin-bottom: 1rem;
  }
`;

export const StyledVideoDescriptionExpired = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 700px) {
    align-items: center;
    margin-top: 3rem;
  }
`;

export const StyledTiersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1.5rem;
  margin: 8rem auto 0;
  width: max-content;
  max-width: 100%;
  justify-content: center;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const StyledImg = styled.img`
  max-width: 25rem;
  display: block;
  margin-left: auto;
  @media (max-width: 700px) {
    maxwidth: 80%;
    margin: auto;
    margin-bottom: 3rem;
  }
`;

export const StyledBenefitsSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 5rem;
  grid-row-gap: 3rem;
  margin: 4rem 5rem;
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

// Tier styles
export const TierContainer = styled.div<{ highlight: boolean }>`
  background: white;
  border: 2px solid ${props => (props.highlight ? '#4546F1' : '#fff')};
  border-radius: 10px;
  border-top-left-radius: ${props => (props.highlight ? 0 : '10px')};
  border-top-right-radius: ${props => (props.highlight ? 0 : '10px')};
  box-shadow: rgb(0 0 0 / 25%) 4px 4px 16px;
  max-width: 35rem;
  overflow: visible;
  position: relative;
  padding: 2rem 1.5rem;
  margin-top: 0;
  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    margin-top: ${props => (props.highlight ? '3' : '0')}rem;
  }
  @media (max-width: 1024px) {
    max-width: initial;
  }
`;

export const TierHighlight = styled.div`
  position: absolute;
  left: -2px;
  right: -2px;
  top: -3rem;
  background: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  color: white;
  font-size: 1.2rem;
  height: 3rem;
  text-align: center;
  display: grid;
  place-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const TierTitle = styled.div`
  color: ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const TierIconText = styled.div`
  margin-left: 0.5rem;
  color: #707070;
  font-size: 1.2rem;
`;

export const TierLabel = styled.div`
  margin-bottom: 3rem;
  margin-top: 3rem;
  height: 9rem;
`;

export const TierLabelSublabel = styled.div`
  color: #181a5a;
  font-size: 1rem;
`;

export const TierActionButton = styled.button<{ isClickable: boolean; hasSubtext: boolean }>`
  background: ${props => (props.isClickable ? CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR : '#797979')};
  border: none;
  border-radius: 10px;
  color: white;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
  display: grid;
  font-size: 1.4rem;
  margin-bottom: ${props => (props.hasSubtext ? 0 : '4rem')};
  outline: none;
  padding: 2rem 0;
  place-items: center;
  width: 100%;
`;

export const TierActionSubtext = styled.div<{ isClickable: boolean }>`
  color: #707070;
  cursor: ${props => (props.isClickable ? 'pointer' : undefined)};
  font-size: 0.8rem;
  height: 1rem;
  margin: 0.2rem 0 3rem;
  text-align: center;
  text-decoration: ${props => (props.isClickable ? 'underline' : undefined)};
`;

export const TierDescriptionTitle = styled.div`
  color: #181a5a;
  font-size: 1.4rem;
  font-weight: 500;
`;

export const TierDescriptionBullet = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  margin: 1rem 0;
  color: #181a5a;
  font-size: 1.2rem;
  line-height: 1.2;
`;

export const TierShortDescrContainer = styled.div`
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: #707070;
  font-weight: 400;
  min-height: 7rem;
`;
