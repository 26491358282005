import { EmailTemplateAttachments } from '@/shared/constants';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  value: EmailTemplateAttachments[];
  options: EmailTemplateAttachments[];
  onChange: (value: EmailTemplateAttachments[]) => void;
};

export const AttachmentsSelector = ({ value, options, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={option => t(`emailTemplates.attachmentKeys.${option}`)}
      value={value}
      onChange={(_, value) => onChange(value as EmailTemplateAttachments[])}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          label={t('emailTemplates.attachments')}
          placeholder={t('emailTemplates.attachments')}
        />
      )}
    />
  );
};
