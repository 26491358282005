import {
  DEFAULT_BOX_STYLE,
  DEFAULT_THEME_CONTACT_EXCHANGE_FLOW,
  DEFAULT_THEME_LINKS_FILES_POSITION,
  FEATURE,
  THEME_CONFIG_KEYS,
} from '@/shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchThemeConfig } from '../apis/md/customization/jsonConfigs';
import { HierarchyLevel, ProfileDesignConfig } from '@/shared/types/api';
import { Unit } from '@/shared/types/api/unit.type';
import useTierInfo from './useTierInfo';
import { getUnits } from '../apis/md/units';
import { useAppSelector } from '@/application/hooks';

const defaultValues = {
  boxStyle: DEFAULT_BOX_STYLE,
  leadGenColor: '',
  fileBoxColor: '',
  genericWebsiteColor: '',
  defaultBannerImageUrl:
    'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/banner.jpg',
  defaultProfileImageUrl:
    'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/profile.png',
  logoHeaderUrl: '',
  privacyUrl: '',
  imprintUrl: '',
  showCopyright: false,
  legalName: '',
  leadGenFormShown: false,
  contactExchangeFlow: DEFAULT_THEME_CONTACT_EXCHANGE_FLOW,
  bannerImgOptions: [],
  logoImgOptions: [],
  themeLinksPosition: DEFAULT_THEME_LINKS_FILES_POSITION,
  themeFilesPosition: DEFAULT_THEME_LINKS_FILES_POSITION,
};

type DictValue = {
  value: ProfileDesignConfig;
  hierarchyLevel: HierarchyLevel;
};

type ProfileDesignDictionary = {
  theme?: DictValue;
  [key: string]: DictValue;
};

const ProfileDesignDictionaryContext = React.createContext<{
  profileDesignDictionary: ProfileDesignDictionary;
  refetch(): void;
  mutateProfileDesign(value: ProfileDesignConfig, key?: string): void;
  deleteProfileDesign(key?: string): void;
}>({
  profileDesignDictionary: {},
  refetch: () => {},
  mutateProfileDesign: () => {},
  deleteProfileDesign: () => {},
});

export const ProfileDesignForUnitsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const domainVerified = useAppSelector(state => state.login.domainVerified);

  const { getAccessTokenSilently } = useAuth0();
  const [profileDesignDictionary, setProfileDesignDictionary] =
    React.useState<ProfileDesignDictionary>({});

  const { isFeatureAllowed, isUnitAdmin, isThemeAdmin } = useTierInfo();

  const [units, setUnits] = useState<Unit[]>([]);
  const [unitsLoading, setUnitsLoading] = useState(true);

  const isUnitsAllowed = isFeatureAllowed(FEATURE.UNITS);
  const canFetchSettingsForUnits = isUnitsAllowed && (isUnitAdmin || isThemeAdmin);

  useEffect(() => {
    if (canFetchSettingsForUnits && domainVerified) {
      const fetchUnits = async () => {
        setUnitsLoading(true);
        try {
          const res = await getUnits(getAccessTokenSilently);
          if (res.data.isSuccess) {
            setUnits(res.data.data.units);
          } else {
            setUnits([]);
          }
        } catch (error) {
          setUnits([]);
        }
        setUnitsLoading(false);
      };
      fetchUnits();
    } else {
      setUnitsLoading(false);
    }
  }, [getAccessTokenSilently, canFetchSettingsForUnits, domainVerified]);

  const fetchData = useCallback(async () => {
    const fetcher = async (unitId: number | null) => {
      const { value, meta } = await fetchThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.PROFILE_DESIGN,
        unitId,
        fetchThemeLevel: !unitId,
      });
      return { value, unitId, hierarchyLevel: meta?.hierarchyLevel };
    };

    const unitsPromses = units.map(unit => fetcher(unit.id));
    try {
      const result = await Promise.all([...(isThemeAdmin ? [fetcher(null)] : []), ...unitsPromses]);
      const mapped = result.reduce(
        (acc, { value, unitId, hierarchyLevel }) => ({
          ...acc,
          [unitId ? `unitId-${unitId}` : 'theme']: { value, hierarchyLevel },
        }),
        {},
      );
      setProfileDesignDictionary(mapped);
    } catch {
      //do nothing
    }
  }, [getAccessTokenSilently, units, isThemeAdmin]);

  React.useEffect(() => {
    if (!unitsLoading) {
      fetchData();
    }
  }, [fetchData, unitsLoading]);

  const mutateProfileDesign = (value: ProfileDesignConfig, dictKey?: string) => {
    if (!dictKey || !profileDesignDictionary[dictKey]) return;

    // theme level update all unit configs that uses theme level config
    if (dictKey === 'theme') {
      const newDict = Object.entries(profileDesignDictionary).reduce((acc, [key, dictValue]) => {
        if (dictValue.hierarchyLevel !== HierarchyLevel.Unit) {
          return { ...acc, [key]: { ...dictValue, value } };
        }
        return { ...acc, [key]: dictValue };
      }, {});
      setProfileDesignDictionary(newDict);
      return;
    }

    setProfileDesignDictionary(prev => ({
      ...prev,
      [dictKey]: { value, hierarchyLevel: HierarchyLevel.Unit },
    }));
  };

  const deleteProfileDesign = (dictKey?: string) => {
    if (!dictKey || !profileDesignDictionary[dictKey] || dictKey === 'theme') return;
    setProfileDesignDictionary(prev => ({
      ...prev,
      [dictKey]: prev.theme,
    }));
  };

  return (
    <ProfileDesignDictionaryContext.Provider
      value={{
        profileDesignDictionary: profileDesignDictionary,
        refetch: fetchData,
        mutateProfileDesign,
        deleteProfileDesign,
      }}
    >
      {children}
    </ProfileDesignDictionaryContext.Provider>
  );
};

export const useProfileDesignForUnits = (unitId?: number | null) => {
  const context = React.useContext(ProfileDesignDictionaryContext);
  if (context === undefined) {
    throw new Error(
      'useProfileDesignForUnits must be used within a ProfileDesignDictionaryContext.Provider',
    );
  }

  const { profileDesignDictionary, refetch, mutateProfileDesign, deleteProfileDesign } = context;

  const key = unitId ? `unitId-${unitId}` : 'theme';

  return {
    profileDesign: profileDesignDictionary[key]?.value || defaultValues,
    dictionary: Object.entries(profileDesignDictionary).reduce(
      (acc, [key, dictValue]) => ({
        ...acc,
        [key]: dictValue.value,
      }),
      {},
    ) as { [key: string]: ProfileDesignConfig },
    refetch,
    mutateProfileDesignForUnit: (profileDesign: ProfileDesignConfig) =>
      mutateProfileDesign(profileDesign, key),
    deleteProfileDesignForUnit: () => (key === 'theme' ? void 0 : deleteProfileDesign(key)),
  };
};
