import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FeatureSet } from '@/shared/types/api';
import FeatureTeaser from '@/views/pages/md/teaser/featureTeaser';
import { useTranslation } from 'react-i18next';

type BaseProps = {
  feature?: keyof FeatureSet;
  text?: string;
  image?: string;
  loadingComponent?: React.ReactNode | null;
};

type ComponentProps = BaseProps & {
  children?: React.ReactNode;
};

const FeatureSetGuard = ({
  children,
  feature,
  text,
  image,
  loadingComponent = null,
}: ComponentProps) => {
  const { loading, isFeatureAllowed } = useTierInfo();

  const { t } = useTranslation();

  if (loading) return <>{loadingComponent}</>;

  if (!isFeatureAllowed(feature)) return <FeatureTeaser text={t(text)} imageSrc={image} />;

  return <>{children}</>;
};

export default FeatureSetGuard;

//HOC
export function withFeatureSetGuard<T>(Component: React.ComponentType<T>, args: BaseProps) {
  return (props: T) => (
    <FeatureSetGuard {...args}>
      <Component {...props} />
    </FeatureSetGuard>
  );
}
