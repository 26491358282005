import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 50% 16% 10% 1fr;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 2rem;
  width: fit-content;
  max-width: 32rem;
  height: auto;
  background: #eff3f7;
  border-radius: 8px;
  margin-right: 2rem;
  flex: none;
`;

export const Image = styled.img`
  max-width: 70%;
`;

export const PopopWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 20% 30%;
  row-gap: 3rem;
  align-items: center;
  justify-items: center;
`;
