import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AddFileComponent from './addFileComponent';
import { Account, ApiFile } from '../../../../shared/types/api';
import { RowDiv, Spacer } from './common';
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md';
import toast from 'react-hot-toast';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../config/config';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import {
  deleteFileTemplateById,
  editFileTemplateById,
} from '../../../../infrastructure/apis/edit-profile';
import AddNewFilePopup from '../../../components/popups/add-new-file-popup';
import { useAppSelector } from '../../../../application/hooks';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import FeatureTeaser from '../teaser/featureTeaser';
import { FEATURE } from '../../../../shared/constants';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import teaserImage from '../../../images/teaser/data-templates.png';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { LtDialog } from '@/components';
import { Typography } from '@mui/material';

const CustomDataFiles = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'data-files-feature-loader-id');

  if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
    return <FeatureContent />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.dataTemplates')} imageSrc={teaserImage} />;
  }
};

export default CustomDataFiles;

const FeatureContent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [filesList, setFilesList] = useState<ApiFile[]>();
  const [selectedFile, setSelectedFile] = useState<ApiFile>();
  const [editFileDialog, setEditFileDialog] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const account: Account = useAppSelector(state => state.account);

  useDeferredLoader(isLoading, 'data-templates-edit-delete-file-loader-id');

  useEffect(() => {
    const toastId = 'data-tab-fetching';
    const toastTimeoutId = setTimeout(
      () => toast.loading(t('Loading'), { id: toastId }),
      500,
      'delay-loading-toast',
    );
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `theme/file-templates`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_GLOBAL],
    )
      .then(res => {
        setFilesList(res.data.data);
        toast.dismiss(toastId);
      })
      .catch(() => toast.error(t('unexpectedError'), { id: toastId }))
      .finally(() => clearTimeout(toastTimeoutId));
  }, [getAccessTokenSilently, t]);

  const deleteFile = (file: ApiFile) => {
    setSelectedFile(file);
    setDeletePopupVisible(true);
  };

  const editFile = (file: ApiFile) => {
    setSelectedFile(file);
    setEditFileDialog(true);
  };

  const RenderFiles = useCallback(() => {
    return (
      <FilesContainer>
        {filesList?.map((file, index) => (
          <FileRecord key={index}>
            <TitleWrapper>{file.fileName}</TitleWrapper>
            <div>{decodeURIComponent(file.link.split('/').at(-1) || '')}</div>
            <IconContainer onClick={() => editFile(file)}>
              <MdOutlineModeEditOutline size={23} color='#666666' onClick={() => editFile(file)} />
            </IconContainer>
            <IconContainer onClick={() => deleteFile(file)}>
              <MdDelete size={23} color='#666666' />
            </IconContainer>
          </FileRecord>
        ))}
      </FilesContainer>
    );
  }, [filesList]);

  const handleDeleteFile = useCallback(() => {
    setIsLoading(true);
    deleteFileTemplateById(selectedFile.id, getAccessTokenSilently)
      .then(result => {
        if (!result) return;
        setFilesList(filesList.filter(file => file.id !== selectedFile.id));
        setDeletePopupVisible(false);
        toast.success(t('filesTab.successfullyDeleted'));
      })
      .catch(() => {
        toast.error(t('filesTab.writingDataError'));
      })
      .finally(() => setIsLoading(false));
  }, [filesList, getAccessTokenSilently, selectedFile?.id, t]);

  const handleEditFile = useCallback(
    async (
      fileName: string,
      oldFile: ApiFile,
      embed: boolean,
      newUploadedFile: { file: File; pageCount: number },
    ) => {
      setIsLoading(true);
      editFileTemplateById(
        selectedFile.id,
        fileName,
        embed,
        oldFile,
        newUploadedFile,
        getAccessTokenSilently,
        account.theme?.themeInternal.uniqueId,
      )
        .then(result => {
          if (!result) return;
          setFilesList(result.data.data);
          setEditFileDialog(false);
          toast.success(t('filesTab.successfullySaved'));
        })
        .catch(() => toast.error(t('filesTab.writingDataError')))
        .finally(() => setIsLoading(false));
    },
    [account.theme?.themeInternal.uniqueId, getAccessTokenSilently, selectedFile?.id, t],
  );

  return (
    <>
      <Header>
        {t('filesTab.header')}
        <Spacer size={9} />
        <TooltipInfo text={t('filesTab.headerTooltip')} placement='top' />
      </Header>
      <AddFileComponent filesList={filesList} setFilesList={setFilesList} />
      <Spacer size={25} />
      <RenderFiles />
      <LtDialog
        open={deletePopupVisible}
        onClose={() => setDeletePopupVisible(false)}
        onCancel={() => setDeletePopupVisible(false)}
        title={t('genericDelete')}
        size='sm'
        onDelete={handleDeleteFile}
        withActionDivider
        loading={isLoading}
      >
        <Typography>{t('deleteFile')}</Typography>
      </LtDialog>
      {editFileDialog && (
        <AddNewFilePopup
          onCloseClick={() => setEditFileDialog(false)}
          isEdit
          fileToEdit={selectedFile}
          handleEdit={handleEditFile}
        />
      )}
    </>
  );
};

const Header = styled(RowDiv)`
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;

const FilesContainer = styled.div`
  flex-wrap: wrap;
`;

const FileRecord = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) max-content max-content;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 27px 25px 15px 0px;
  border-bottom-color: #b2b8c3;
  border-bottom-width: 1px;
  border-style: solid;
`;

const TitleWrapper = styled.div`
  word-break: break-word;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;
