import React, { useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import { FileCopyOutlined } from '@mui/icons-material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { appendParamsToLink } from '@/shared/util';

type Props = {
  profileUrl: string;
};

const CopyLink = ({ profileUrl }: Props) => {
  const [copied, setCopied] = useState(false);
  const { t } = useAppTranslation();

  const profileLinkWithParams = appendParamsToLink(profileUrl, { lt_medium: 'url_share' });

  const handleClickCopy = () => {
    navigator.clipboard.writeText(profileLinkWithParams);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleMouseDownCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl variant='outlined'>
      <InputLabel htmlFor='copy-link-input'>{t('shareProfile.profileLink.label')}</InputLabel>
      <OutlinedInput
        id='copy-link-input'
        type={'text'}
        value={profileUrl}
        endAdornment={
          <InputAdornment position='end'>
            <Tooltip title={t('shareProfile.profileLink.copied')} placement='top' open={copied}>
              <IconButton
                aria-label='copy link'
                onClick={handleClickCopy}
                onMouseDown={handleMouseDownCopy}
                edge='end'
                disabled={copied}
              >
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        label={t('shareProfile.profileLink.label')}
      />
    </FormControl>
  );
};

export default CopyLink;
