import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../../../config/config';
import { PERMISSIONS, routePaths } from '../../../../../../infrastructure/constants';
import { showLoadingToaster, withSilentAccessToken } from '../../../../../../infrastructure/helper';
import { useAppSelector } from '@/application/hooks';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setSidebarSettingsCollapsed } from '@/application/actions/ui';
import { useState } from 'react';
import { ERROR_KEYS } from '@/shared/constants';
import { useTranslation } from 'react-i18next';

const useActions = (selectedUsernames: readonly string[]) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [actionLoading, setActionLoading] = useState(false);
  const { totalNumEmployees } = useAppSelector(state => state.md);
  const history = useHistory();
  const dispatch = useDispatch();

  const downloadQRCodes = async (type: 'online' | 'offline') => {
    setActionLoading(true);
    try {
      await withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `theme/zip-qr`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params:
              selectedUsernames.length < totalNumEmployees
                ? { usernames: selectedUsernames, content: type }
                : { content: type },
          }),
        [PERMISSIONS.READ.THEME_PROFILES],
      );
      dispatch(setSidebarSettingsCollapsed(true));
      history.push(routePaths.MD.CUSTOMISESETTINGS.EXPORT, { justStarted: true });
    } catch (err) {
      if (
        err?.response?.data?.error?.code === ERROR_KEYS.QR_ZIP_USERS_EXCEEDED &&
        err?.response?.data?.error?.data?.maxAllowed
      ) {
        console.log(err.response.data);
        toast.error(
          t('showQr.downloadQrBgsErrorMaxAllowed', {
            max: err?.response?.data?.error?.data?.maxAllowed,
          }),
        );
        setActionLoading(false);
        return;
      }
      toast.error(t('unexpectedError'));
    }
    setActionLoading(false);
  };

  const downloadQRBg = async (type: 'mobile' | 'videocall') => {
    setActionLoading(true);
    try {
      await withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `theme/zip-qr-backgrounds`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              usernames:
                selectedUsernames.length < totalNumEmployees ? selectedUsernames : undefined,
              size: type,
              content: 'online',
            },
          }),
        [PERMISSIONS.READ.THEME_PROFILES],
      );
      dispatch(setSidebarSettingsCollapsed(true));
      return history.push(routePaths.MD.CUSTOMISESETTINGS.EXPORT, { justStarted: true });
    } catch (err) {
      if (
        err?.response?.data?.error?.code === ERROR_KEYS.QR_ZIP_USERS_EXCEEDED &&
        err?.response?.data?.error?.data?.maxAllowed
      ) {
        toast.error(
          t('showQr.downloadQrBgsErrorMaxAllowed', {
            max: err?.response?.data?.error?.data?.maxAllowed,
          }),
        );
        setActionLoading(false);
        return;
      }
      toast.error(t('showQr.downloadQrBgsError'));
    }
    setActionLoading(false);
  };

  const downloadEmployees = (type: string) => {
    showLoadingToaster<void>(
      async () => {
        const response = await withSilentAccessToken(
          getAccessTokenSilently,
          token =>
            axios.get(config.API_BASE_URL + `business/export/${type}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'blob',
              params:
                selectedUsernames.length < totalNumEmployees
                  ? { usernames: selectedUsernames }
                  : {},
            }),
          [PERMISSIONS.READ.THEME_PROFILES],
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('id', 'qr-codes-download-img');
        link.setAttribute('download', `employees.${type}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      {
        loading: t('Loading') + '...',
        success: t('showQr.downloadSuccess'),
        error: err => {
          if (err?.response?.status === 403) {
            return t('featureNotAllowed');
          } else {
            return t('showQr.downloadError');
          }
        },
      },
    );
  };

  const downloadProfileLinks = () => {
    showLoadingToaster<void>(
      async () => {
        const response = await withSilentAccessToken(
          getAccessTokenSilently,
          token =>
            axios.get(config.API_BASE_URL + `theme/xlsx-links`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'blob',
              params:
                selectedUsernames.length < totalNumEmployees
                  ? { usernames: selectedUsernames.join(',') }
                  : {},
            }),
          [PERMISSIONS.READ.THEME_PROFILES],
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('id', 'qr-codes-download-img');
        link.setAttribute('download', `Employees-Profile-Links.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      {
        loading: t('Loading') + '...',
        success: t('showQr.downloadSuccess'),
        error: t('showQr.downloadError'),
      },
    );
  };

  const sendWalletEmails = (selectedIds: readonly string[]) => {
    showLoadingToaster<void>(
      async () => {
        await withSilentAccessToken(
          getAccessTokenSilently,
          token =>
            axios.post(
              config.API_BASE_URL + `business/bulkSendWalletEmails?qrType=both&wallet=both`,
              { ids: selectedIds },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ),
          [PERMISSIONS.READ.THEME_PROFILES],
        );
      },
      {
        success: t('bulkActions.distribute.wallet.successMsg'),
        error: t('bulkActions.distribute.wallet.errorMsg'),
      },
    );
  };

  return {
    downloadQRCodes,
    downloadQRBg,
    downloadEmployees,
    downloadProfileLinks,
    sendWalletEmails,
    actionLoading,
  };
};

export default useActions;
