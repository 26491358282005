import { Account } from '@/shared/types/api';
import { InputAdornment, TextField } from '@mui/material';
import { getTranslatedField, isPhoneFaxField, isRequiredField } from '../utils';
import TooltipInfo from '@/views/components/generic/TooltipInfo';
import { ACCOUNT_MULTILINE_FIELDS } from '@/shared/constants/entities';
import { ChangeEvent, useEffect, useRef } from 'react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CSS_VARS } from '@/infrastructure/constants';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';

const BioTextField = ({
  field,
  value,
  onChange,
  disabled = false,
  focused = false,
}: {
  field: keyof Account;
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  focused?: boolean;
}) => {
  const { t } = useAppTranslation();

  const { desktopView } = useDesktopView();
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    if (ref.current && focused) {
      ref.current.scrollIntoView();
    }
  }, [focused]);

  return (
    <TextField
      label={getTranslatedField(field)}
      name={field}
      value={value}
      required={isRequiredField(field)}
      onChange={onChange}
      disabled={disabled}
      ref={ref}
      InputProps={{
        startAdornment: isPhoneFaxField(field) ? (
          <InputAdornment position='start'>+</InputAdornment>
        ) : null,
        endAdornment: disabled ? (
          <TooltipInfo
            text={t('editDisabledTooltip')}
            icon={{ help: true, color: 'text.secondary' }}
          />
        ) : null,
      }}
      InputLabelProps={{ color: 'secondary', shrink: true }}
      key={field}
      multiline={ACCOUNT_MULTILINE_FIELDS.includes(field)}
      sx={{
        mt: 1,
        mb: 2,
        flexGrow: 1,
        flexBasis: field.toLowerCase().includes('addressline') ? '100%' : null,
        scrollMarginTop: `calc(${
          desktopView ? CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR : CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR
        } + 2rem)`,
      }}
    />
  );
};

export default BioTextField;
