import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { Loader } from '../../components/common';
import { getPdfPageCount } from '../../../infrastructure/helper';
import { uploadFile, clearApiResponse } from '../../../application/actions/edit-profile';
import { clearApiResponse as clearApiResponseMd } from '../../../application/actions/md/profiles';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { trackPrivateEvent } from '../../../infrastructure/apis/analytics';
import {
  PRIVATE_EVENTS,
  PRIVATE_META_TYPES,
  THEME_CONFIG_KEYS,
} from '../../../shared/constants/global';
import { Employee } from '../../../shared/types/api/employee.type';
import { Account } from '../../../shared/types/api';
import { UploadfileProps } from '../../pages/md/profiles/common/files';
import { ApiFile, BulkFile } from '../../../shared/types/api';
import { Dropzone, LtDialog } from '@/components';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import TooltipInfo from '../generic/TooltipInfo';
import { isEmbeddableUrl } from '@/components/LtVideo';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';

interface Props {
  onCloseClick: () => void;
  setBulkFiles?: Dispatch<SetStateAction<Array<BulkFile>>>;
  bulkFiles?: Array<BulkFile>;
  bulkEdit?: boolean;
  employee?: Employee;
  isEdit?: boolean;
  fileToEdit?: ApiFile;
  handleEdit?: (
    fileName: string,
    oldFile: ApiFile,
    embed: boolean,
    newUploadedFile: { file: File; pageCount: number },
  ) => void;
  isCompanydataSettings?: boolean;
  handleCompanySettingsUpdate?: (props: {
    fileType: 'newFile' | 'templateFile';
    templateFileDetails?;
    newFileDetails?: UploadfileProps;
  }) => void;
  userFiles?: Array<ApiFile>;
  setUserFiles?: Dispatch<SetStateAction<Array<ApiFile>>>;
}

const AddNewFilePopup = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const account: Account = useAppSelector(state => state.account);
  const apiResponse = useAppSelector(state =>
    props.employee ? state.md.apiResponse : state.editProfile.apiResponse,
  );
  const [isResponseUpdated, setResponseUpdate] = useState(false);
  const [fileName, setFileName] = useState(props.fileToEdit?.fileName || '');
  const [uploadedFile, setUploadedFile] = useState<File>(null);
  const [embed, setEmbed] = useState(props.fileToEdit?.embed || false);
  const [pageCountPending, setPageCountPending] = useState(false);
  const [pageCount, setPageCount] = useState<number>(null);
  const [hasFileUploaded, setFileUploaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fileToEdit, setFileToEdit] = useState(props.fileToEdit);

  const [mode, setMode] = useState<'videoLink' | 'file'>('file');
  const [embedUrl, setEmbedUrl] = useState('');

  const onFileSelection = async (files: File[]) => {
    const file = files[0];
    if (file) {
      setPageCountPending(true);
      getPdfPageCount(file).then(count => {
        setPageCount(count);
        setPageCountPending(false);
      });

      setUploadedFile(file);
      setFileUploaded(true);
    }
  };

  const onUploadClick = () => {
    if (mode === 'file' && !embed && fileName.trim().length < 1) {
      setErrorMsg(t('nameFileerror'));
      return;
    }

    if (props.isEdit) {
      props.handleEdit(fileName, fileToEdit, embed, {
        file: uploadedFile,
        pageCount: pageCount || 0,
      });
      return;
    }

    if (hasFileUploaded || mode === 'videoLink') {
      if (props.isCompanydataSettings) {
        props.handleCompanySettingsUpdate({
          fileType: 'newFile',
          newFileDetails: {
            uploadedFile,
            fileName: fileName,
            pageCount: pageCount || 0,
            embed: mode === 'videoLink' ? true : embed,
            link: mode === 'videoLink' ? embedUrl : undefined,
          },
        });
        props.onCloseClick();
        return;
      }

      if (props.bulkEdit) {
        const fileToUpload: BulkFile = {
          id: Math.floor(Math.random() * 1000),
          link: mode === 'videoLink' ? embedUrl : '',
          fileName,
          embed: mode === 'videoLink' ? true : embed,
          order: 0,
          pageCount,
          canDisplayOnProfile: true,
          uploadedFile,
          fileIds: [],
          existsInDB: false,
          existsForAll: false,
          extendToAll: false,
          existingIds: [],
          missingIds: [],
          existingUsernames: [],
          missingUsernames: [],
          count: 0,
          countToRender: '',
        };

        props.setBulkFiles([...props.bulkFiles, fileToUpload]);
      } else if (props.employee) {
        const fileToUpload: ApiFile = {
          id: Math.floor(Math.random() * 1000),
          link: mode === 'videoLink' ? embedUrl : '',
          fileName,
          embed: mode === 'videoLink' ? true : embed,
          order: 0,
          pageCount,
          canDisplayOnProfile: true,
          uploadedFile,
        };

        props.setUserFiles([...props.userFiles, fileToUpload]);
      } else {
        dispatch(
          uploadFile(
            account.username,
            uploadedFile,
            fileName,
            mode === 'videoLink' ? embedUrl : undefined,
            mode === 'videoLink' ? true : embed,
            pageCount,
            getAccessTokenSilently,
            () => toast.success(t('fileUploaded'), { id: 'fileupload' }),
            fileId => {
              trackPrivateEvent(
                getAccessTokenSilently,
                PRIVATE_EVENTS.PROFILE_EDIT,
                {
                  type: PRIVATE_META_TYPES.FILE_ADD,
                },
                fileId,
              );
            },
            progress => {
              toast(`${Math.round(progress * 100)}%`, {
                id: 'fileuploadprogress',
                duration: progress < 1 ? Infinity : 500,
              });
            },
          ),
        );
      }
      props.onCloseClick();
    }
  };

  const handleFileRemove = () => {
    setFileUploaded(false);
    setFileToEdit(null);
  };

  const checkForResponse = () => {
    if (props.isEdit) return;
    if (apiResponse && apiResponse.isSuccess === false && isResponseUpdated === false) {
      setErrorMsg(apiResponse.error.message);
      setResponseUpdate(true);
      if (props.employee) dispatch(clearApiResponseMd());
      else dispatch(clearApiResponse());
    }
  };

  const { theme: muiTheme } = useMuiTheme();

  const flag_videoEmbed = usePrivateFeatureFlag('videoEmbed');

  const embedUrlIsInvalid = embedUrl && !isEmbeddableUrl(embedUrl);

  // dirty: this is needed so that api interaction preparation works fine (removal of ids prior to sending to api)
  useEffect(() => {
    if (mode === 'file') {
      setUploadedFile(null);
      setFileUploaded(false);
    } else if (mode === 'videoLink') setUploadedFile(new File([], ''));
  }, [mode]);

  const { config: helpUrls } = usePrivateThemeConfig<Record<string, string>>(
    THEME_CONFIG_KEYS.HELP_RESOURCE_URLS,
  );
  const helpArticleUrl = helpUrls?.['video-embed'];

  return (
    <ThemeProvider theme={muiTheme}>
      {!props.isCompanydataSettings && checkForResponse()}
      {pageCountPending && <Loader delayedShow={500} />}
      <LtDialog
        open
        onClose={props.onCloseClick}
        onCancel={props.onCloseClick}
        title={t(props?.isEdit ? 'editFile' : 'addFile')}
        onConfirm={onUploadClick}
        withActionDivider
        disabled={
          (mode === 'file' && (!hasFileUploaded || (!embed && !fileName))) ||
          (mode === 'videoLink' && (!embedUrl || embedUrlIsInvalid))
        }
      >
        {!props.isEdit && flag_videoEmbed && (
          <>
            <Select
              value={mode}
              onChange={e => setMode(e.target.value as 'file' | 'videoLink')}
              sx={{ mb: 2, width: '100%' }}
            >
              <MenuItem value='file'>{t('addFileDialog.modeFile')}</MenuItem>
              <MenuItem value='videoLink'>{t('addFileDialog.modeVideoLink')}</MenuItem>
            </Select>
            {mode === 'videoLink' && (
              <Alert
                title={t('addFileDialog.embedVideoAlert.title')}
                variant='outlined'
                severity='info'
                sx={{ mb: 2 }}
              >
                {t('addFileDialog.embedVideoAlert.content')}
                {helpArticleUrl ? (
                  <>
                    {' '}
                    <Trans
                      i18nKey='addFileDialog.embedVideoAlert.helpReference'
                      components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={helpArticleUrl}
                          target='_blank'
                          rel='noreferrer'
                          style={{ color: 'currentcolor', textDecoration: 'underline' }}
                        />,
                      ]}
                    />
                  </>
                ) : null}
              </Alert>
            )}
          </>
        )}

        <TextField
          sx={{ mb: 2.4 }}
          label={t('addFileDialog.title')}
          fullWidth
          value={fileName}
          onChange={e => {
            if (errorMsg) setErrorMsg('');
            setFileName(e.target.value);
          }}
          required={mode === 'file' && !embed}
          error={!!errorMsg}
          helperText={errorMsg}
        />

        {mode === 'file' && (
          <>
            {!hasFileUploaded && !fileToEdit && (
              <Dropzone
                types={
                  flag_videoEmbed
                    ? ['mp4', 'mov', 'webm', 'pdf', 'jpg', 'png']
                    : ['pdf', 'jpg', 'png']
                }
                onChange={onFileSelection}
                maxSize={mimetype => {
                  if (!flag_videoEmbed) return 10;
                  if (mimetype.startsWith('video/')) return 500;
                  else return 10;
                }}
                fullWidth
              />
            )}

            {hasFileUploaded && (
              <UploadedFileWrapper onRemove={handleFileRemove}>
                <Typography variant='body1'>{uploadedFile?.name}</Typography>
              </UploadedFileWrapper>
            )}

            {props.isEdit && !hasFileUploaded && fileToEdit && (
              <UploadedFileWrapper onRemove={handleFileRemove}>
                <Typography variant='body1'>{fileToEdit?.fileName}</Typography>
              </UploadedFileWrapper>
            )}

            {flag_videoEmbed && hasFileUploaded && uploadedFile && fileIsVideo(uploadedFile) && (
              <Box mt={4} display='flex' alignItems='center'>
                <FormControlLabel
                  control={
                    <Checkbox checked={embed} onChange={(e, checked) => setEmbed(checked)} />
                  }
                  label={t('addFileDialog.embedVideo.label')}
                />
                <TooltipInfo
                  text={t('addFileDialog.embedVideo.tooltip')}
                  icon={{ color: '#111456' }}
                />
              </Box>
            )}
          </>
        )}

        {mode === 'videoLink' && (
          <TextField
            sx={{ mb: 2.4 }}
            label={t('addFileDialog.videoUrl')}
            fullWidth
            value={embedUrl}
            onChange={e => {
              setEmbedUrl(e.target.value);
            }}
            required
            error={embedUrlIsInvalid}
            helperText={embedUrlIsInvalid ? t('addFileDialog.error.invalidEmbedUrl') : undefined}
            placeholder={t('addFileDialog.embedVideo.placeholder')}
          />
        )}
      </LtDialog>
    </ThemeProvider>
  );
};

export default AddNewFilePopup;

const fileIsVideo = (file: File) => !!file.type?.startsWith('video/');

const UploadedFileWrapper = ({ onRemove, children }) => {
  return (
    <Paper
      sx={{
        py: 2,
        px: 4.5,
        borderStyle: 'dashed',
        mb: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        position: 'relative',
        width: 'max-content',
        minWidth: '30rem',
        maxWidth: '100%',
        m: 'auto',
        wordBreak: 'break-word',
      }}
      elevation={0}
      variant='outlined'
    >
      <IconButton
        onClick={onRemove}
        sx={(theme: Theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translate(50%, -50%)',
          bgcolor: theme.palette.background.paper,
        })}
      >
        <Close />
      </IconButton>
      <Box mb={1.6}>
        <InsertDriveFileOutlinedIcon />
      </Box>
      {children}
    </Paper>
  );
};
