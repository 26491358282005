import { routePaths } from '@/infrastructure/constants';
import { CardGiftcardOutlined } from '@mui/icons-material';
import { Box, ListItemButton, ListItemText, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const NFCCardConnector = ({ sidebarCollapsed }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Box px={0.8} mb={'1rem'}>
      <ListItemButton
        onClick={() => history.push(routePaths.REFERRALS)}
        sx={(theme: Theme) => ({
          bgcolor: theme.palette.primaryButton.main,
          height: '4rem',
          borderRadius: '0.4rem',
          mb: '0.4rem',
          '&:hover, &:focus-visible': {
            bgcolor: theme.palette.primaryButton.main,
          },
          ...(sidebarCollapsed ? { p: 0, alignItems: 'center', justifyContent: 'center' } : {}),
        })}
      >
        <CardGiftcardOutlined />
        {!sidebarCollapsed && (
          <>
            <ListItemText sx={{ ml: '1.6rem' }} disableTypography>
              {t('referrals.menuButton')}
            </ListItemText>
          </>
        )}
      </ListItemButton>
    </Box>
  );
};
