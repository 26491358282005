import { LeadAPI, StaticProfileConfig } from '@/shared/types/api';
import { ThemeBoxStyle } from '@/shared/types/global';
import { flatten } from '@/shared/util/array';
import Layout from '@/views/components/common/white-layout';
import styled from 'styled-components';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Row } from '@/views/components/generic';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import nl2br from 'react-nl2br';
import { Spacer } from '../../md/common';
import trustIcons from '@/views/images/trust-icons.svg';
import downIcon from '@/views/images/down.svg';
import { ArrowIcon, ArrowIconWrapper } from '../../edit-profile/contact-details';
import { THEME_BOX_STYLES } from '@/shared/constants';
import { invertHex, toOnlyNumbersAndSpacesAndDashes } from '@/infrastructure/helper';
import toast from 'react-hot-toast';
import { validateEmail } from '@/shared/util';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { withKeyEventHandler } from '@/utils/helpers';

// TODO: profile page optimizations

interface Props {
  config: StaticProfileConfig['leadGen'];
  values: Partial<Record<keyof LeadAPI, string>>;
  setValue(key: keyof LeadAPI, value: string): void;
  boxStyle: ThemeBoxStyle;
  onSubmit?: ContactFormProps['onSubmit'];
  boxLabel?: string;
  variant?: 'v1' | 'v2-card' | 'v2-popup'; // determines ui variant
}

export default function ContactFormBox({
  config,
  values,
  setValue,
  onSubmit,
  variant,
  boxLabel,
  boxStyle,
}: Props): JSX.Element {
  const optionalProps: Partial<ContactFormProps> =
    variant === 'v1'
      ? {}
      : variant === 'v2-card'
      ? { withMinimize: true, withTrust: true, withAutoExpand: true }
      : variant === 'v2-popup'
      ? { profileImageUrl: config.profileImageUrl, withTrust: true }
      : {};
  return (
    <Layout
      headerText={variant === 'v2-popup' ? undefined : boxLabel || config.labels.title}
      themeBoxStyle={boxStyle}
      flat={variant === 'v2-popup'}
      noPadding={variant === 'v2-popup'}
    >
      <ContactForm
        values={values}
        setValue={setValue}
        onSubmit={onSubmit}
        boxStyle={boxStyle}
        submitButtonColor={config.submitButtonColor}
        requiredFields={config.requiredFields}
        optionalFields={config.optionalFields}
        privacyPolicyUrl={config.privacyPolicyUrl}
        labels={config.labels}
        withAcknowledgement
        withValidations
        {...optionalProps}
      />
    </Layout>
  );
}

type ContactFormProps = {
  values: Partial<Record<keyof LeadAPI, string>>;
  setValue(key: keyof LeadAPI, value: string): void;
  onSubmit?(
    values: ContactFormProps['values'] & { sendContactToEmail?: boolean },
    onSuccessCb?: () => void,
  ): void; // if undefined, submit UI and logic is not rendered
  boxStyle: ThemeBoxStyle;
  submitButtonColor: string;
  expandOptionalInitially?: boolean; // whether optional fields are uncollapsed at start
  withAcknowledgement?: boolean; // whether to render the acknowledge logic and UI
  withValidations?: boolean; // whether to do validations before submitting
  withMinimize?: boolean; // whether to use outer collapse
  withTrust?: boolean; // whether to render trust logos
  withAutoExpand?: boolean; // when using "withMinimize" -> auto expand when starting to type
  profileImageUrl?: string; // if undefined, then no image is rendered
} & Pick<
  StaticProfileConfig['leadGen'],
  'requiredFields' | 'optionalFields' | 'privacyPolicyUrl' | 'labels'
>;
export function ContactForm({
  requiredFields,
  optionalFields,
  privacyPolicyUrl,
  labels,
  values,
  setValue,
  onSubmit,
  expandOptionalInitially,
  withAcknowledgement,
  withValidations,
  withMinimize,
  withTrust,
  withAutoExpand,
  boxStyle,
  submitButtonColor,
  profileImageUrl,
}: ContactFormProps): JSX.Element {
  const { t } = useTranslation();

  const [showOptionalFields, setShowOptionalFields] = useState(!!expandOptionalInitially);
  const [showOuterExtended, setShowOuterExtended] = useState(false); // only for v2-card

  const handleInputChange = useCallback<typeof setValue>(
    (key, value) => {
      if (key === 'workPhone' || key === 'mobilePhone') {
        setValue(key, toOnlyNumbersAndSpacesAndDashes(value));
      }

      setValue(key, value);
    },
    [setValue],
  );
  const inputFields = useMemo(
    () => (
      <>
        {requiredFields.map(conf => {
          const keys = flatten([conf]);
          return (
            <StyledRow key={conf[0]}>
              {keys.map(attribute => (
                <MappedInputField
                  key={attribute}
                  attribute={attribute}
                  placeholder={
                    labels.field[attribute] || t(attributeToTranslationKey[attribute] || attribute)
                  }
                  value={values[attribute] || ''}
                  onChange={handleInputChange}
                />
              ))}
            </StyledRow>
          );
        })}
        {(!withMinimize || showOuterExtended) && optionalFields?.length > 0 && (
          <StyledRow>
            <StyledShowOptional onClick={() => setShowOptionalFields(before => !before)}>
              {showOptionalFields ? <TriangleUp /> : <TriangleDown />}
              {showOptionalFields ? labels.showLess : labels.showMore}
            </StyledShowOptional>
          </StyledRow>
        )}
        {showOptionalFields && (!withMinimize || showOuterExtended) && (
          <>
            {optionalFields.map(conf => {
              const keys = flatten([conf]);
              return (
                <StyledRow key={keys[0]}>
                  {keys.map(attribute => (
                    <MappedInputField
                      key={attribute}
                      attribute={attribute}
                      placeholder={
                        labels.field[attribute] ||
                        t(attributeToTranslationKey[attribute] || attribute)
                      }
                      value={values[attribute] || ''}
                      onChange={handleInputChange}
                    />
                  ))}
                </StyledRow>
              );
            })}
          </>
        )}
      </>
    ),
    [
      handleInputChange,
      labels.field,
      labels.showLess,
      labels.showMore,
      optionalFields,
      requiredFields,
      showOptionalFields,
      showOuterExtended,
      t,
      values,
      withMinimize,
    ],
  );

  const [acknowledgement, setAcknowledgement] = useState(false);
  const gdprConsentText = useMemo(
    () => (
      <Trans
        i18nKey={labels.gdprConsent}
        components={[
          privacyPolicyUrl ? (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <StyledAnchor href={privacyPolicyUrl} target='_blank' rel='noreferrer' />
          ) : (
            <span style={{ display: 'none' }} />
          ),
        ]}
      />
    ),
    [labels.gdprConsent, privacyPolicyUrl],
  );
  const acknowledgementUI = useMemo(
    () =>
      withAcknowledgement ? (
        <StyledRow>
          <Row>
            <StyledFormLabel
              name='acknowledgement'
              control={
                <Checkbox
                  onClick={() => setAcknowledgement(value => !value)}
                  onKeyUp={withKeyEventHandler(() => setAcknowledgement(value => !value))}
                  checked={acknowledgement}
                  color='primary'
                  size='medium'
                  sx={{ transform: 'scale(1.2)' }}
                />
              }
              label={gdprConsentText}
            />
          </Row>
        </StyledRow>
      ) : null,
    [acknowledgement, gdprConsentText, withAcknowledgement],
  );

  const handleSubmit = useCallback<typeof onSubmit>(
    values => {
      if (!onSubmit) return;

      if (withValidations) {
        if (Object.values(values).every(val => !val)) {
          return toast.error(t('nothingEntered'));
        }

        // check required fields
        const missedFields = flatten(requiredFields).filter(key => !values[key]);

        if (missedFields.length > 0) {
          if (missedFields.length === 1) {
            return toast.error(t('error.requiredField', { field: t(missedFields[0]) }));
          } else {
            return toast.error(
              t('error.requiredFields', { fields: missedFields.map(f => t(f)).join(', ') }),
            );
          }
        }

        // validate fields
        if (values.email && !validateEmail(values.email))
          return toast.error(t('error.invalidField', { field: t('email') }));

        if (values.workEmail && !validateEmail(values.workEmail))
          return toast.error(t('error.invalidField', { field: t('workEmail') }));

        if (values.postCode && typeof Number(values.postCode) !== 'number')
          return toast.error(t('error.invalidField', { field: t('address.postCode') }));

        if (values.mobilePhone && typeof Number(values.mobilePhone) !== 'number')
          return toast.error(t('error.invalidField', { field: t('mobilePhone.text') }));

        if (values.workPhone && typeof Number(values.workPhone) !== 'number')
          return toast.error(t('error.invalidField', { field: t('workPhone.text') }));

        if (!acknowledgement) return toast.error(t('consentToData'));
      }

      onSubmit(
        {
          ...values,
          birthday: !values.birthday ? undefined : moment(values.birthday).format('YYYY-MM-DD'),
        },
        () => {
          setAcknowledgement(false);
          setShowOptionalFields(!!expandOptionalInitially);
          setShowOuterExtended(false);
        },
      );
    },
    [acknowledgement, expandOptionalInitially, onSubmit, requiredFields, t, withValidations],
  );
  const submitUI = useMemo(
    () =>
      onSubmit ? (
        <StyledRow>
          <StyleButton
            variant='contained'
            fullWidth={true}
            onClick={() => handleSubmit(values)}
            $buttonColor={submitButtonColor}
            $boxStyle={boxStyle}
          >
            {labels.submitButton}
          </StyleButton>
        </StyledRow>
      ) : null,
    [boxStyle, handleSubmit, labels.submitButton, onSubmit, submitButtonColor, values],
  );

  const profileImageUI = useMemo(
    () =>
      profileImageUrl ? (
        <ProfilePicCropper>
          <ProfilePicImage src={profileImageUrl} alt='Profile Pic' />
        </ProfilePicCropper>
      ) : null,
    [profileImageUrl],
  );

  // if desired: extend view after starting to type initially
  const startedTyping = useRef<boolean>(false);
  useEffect(() => {
    if (
      withMinimize &&
      withAutoExpand &&
      !startedTyping.current &&
      !showOuterExtended &&
      requiredFields.some(conf => {
        const keys = flatten([conf]);
        return keys.some(key => !!values[key]);
      })
    ) {
      startedTyping.current = true;
      setShowOuterExtended(true);
    }
  }, [requiredFields, showOuterExtended, values, withAutoExpand, withMinimize]);

  return (
    <>
      <Wrapper>
        {profileImageUI}
        {profileImageUI && <Spacer size={15} />}
        <Subheader>{nl2br(labels.subtext)}</Subheader>
      </Wrapper>
      {inputFields}
      {(!withMinimize || showOuterExtended) && (
        <>
          {acknowledgementUI && <Spacer size={4} />}
          {acknowledgementUI}
          {submitUI}
          {withTrust && (
            <Center>
              <TrustImage src={trustIcons} alt='trust, dsgvo' />
            </Center>
          )}
        </>
      )}
      {withMinimize && (
        <>
          <Spacer size={8} />
          <ArrowIconWrapper>
            <ArrowIcon
              src={downIcon}
              alt='toggle'
              expandView={showOuterExtended}
              onClick={() => setShowOuterExtended(prev => !prev)}
            />
          </ArrowIconWrapper>
        </>
      )}
    </>
  );
}

// TODO resolve 'string' to 'keyof Lead'
const MappedInputField = (props: {
  attribute: string;
  placeholder: string;
  value: string;
  onChange: (name: string, value: string) => void;
  prefixText?: string;
}): JSX.Element => {
  switch (props.attribute) {
    case 'birthday':
      return (
        <DatePicker
          label={props.placeholder}
          value={props.value || null}
          onChange={newVal => props.onChange(props.attribute, newVal)}
          inputFormat='DD.MM.YYYY'
          renderInput={params => (
            <StyledTextField
              {...params}
              border={{ bottom: true }}
              bgColor={{ normal: '#fff' }}
              font={{ size: '1.5rem' }}
            />
          )}
        />
      );
    default:
      return (
        <StyledTextField
          label={props.placeholder}
          name={props.attribute}
          value={props.value}
          onChange={e => props.onChange(props.attribute, e.target.value)}
          border={{ bottom: true }}
          bgColor={{ normal: '#fff' }}
          font={{ size: '1.5rem' }}
          prefixText={
            props.prefixText
              ? props.prefixText
              : props.attribute.toLowerCase().includes('phone')
              ? '+'
              : null
          }
          multiline={props.attribute === 'notes' ? true : false}
        />
      );
  }
};

const attributeToTranslationKey = {
  workPhone: 'workPhone.text',
  mobilePhone: 'mobilePhone.text',
};

const StyledAnchor = styled.a`
  &:focus-visible {
    outline: 2px solid;
  }
`;

const StyledRow = styled.div`
  margin: 1rem 0rem !important;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  > div {
    margin: 0 !important;
  }
`;

const StyledTextField = styled(TextField)`
  border: 0px;

  &:focus-within {
    border: unset !important;
  }
`;
const StyleButton = styled(Button)`
  height: 40px !important;
  margin: 20px !important;
  box-shadow: unset !important;
  background-color: ${(props: { $buttonColor: boolean }) =>
    props.$buttonColor || '#ededed'} !important;
  color: ${(props: { $buttonColor: string }) => invertHex(props.$buttonColor, true)};
  text-transform: unset !important;
  border-radius: ${(props: { $boxStyle: string }) =>
    props.$boxStyle === THEME_BOX_STYLES.ANGULAR ? '0' : '10px'} !important;
  font-size: 14px;
  font-weight: 600 !important;
`;

const StyledFormLabel = styled(FormControlLabel)`
  margin-left: 0px;
  span {
    font-size: 10px;
    line-height: 1.3;
    opacity: 0.9;
    text-align: justify;
  }

  a {
    color: darkblue;
  }
`;

const StyledShowOptional = styled.button`
  outline: none;
  background: none;
  border: none;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  color: #55595e;
  border-radius: 4px;
  line-height: 1.4;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: #00000007;
  }

  &:active {
    background-color: #00000015;
  }
  &:focus-visible {
    outline: 1px solid;
  }
`;

const TriangleUp = styled.span`
  font-size: 0.8em;
  margin-right: 0.3rem;
  &::before {
    content: '\u25B2';
  }
`;
const TriangleDown = styled.span`
  font-size: 0.8em;
  margin-right: 0.3rem;
  &::before {
    content: '\u25BC';
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const Subheader = styled.div`
  color: #55595e;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
`;
const ProfilePicCropper = styled.div`
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-top: -1.7rem;
`;
const ProfilePicImage = styled.img`
  width: 100%;
  height: auto;
`;
const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const TrustImage = styled.img`
  width: 80%;
`;
