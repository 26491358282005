import {
  DEFAULT_BOX_STYLE,
  DEFAULT_THEME_CONTACT_EXCHANGE_FLOW,
  DEFAULT_THEME_LINKS_FILES_POSITION,
  THEME_CONFIG_KEYS,
} from '@/shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchPublicThemeConfig, fetchThemeConfig } from '../apis/md/customization/jsonConfigs';
import { ProfileDesignConfig } from '@/shared/types/api';
import { RequireOnlyOne } from '@/shared/types/util';
import { useAppSelector } from '@/application/hooks';

export const initialValues = {
  boxStyle: DEFAULT_BOX_STYLE,
  leadGenColor: '',
  fileBoxColor: '',
  genericWebsiteColor: '',
  defaultBannerImageUrl:
    'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/banner.jpg',
  defaultProfileImageUrl:
    'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/profile.png',
  logoHeaderUrl: '',
  privacyUrl: '',
  imprintUrl: '',
  showCopyright: false,
  legalName: '',
  leadGenFormShown: false,
  contactExchangeFlow: DEFAULT_THEME_CONTACT_EXCHANGE_FLOW,
  bannerImgOptions: [],
  logoImgOptions: [],
  themeLinksPosition: DEFAULT_THEME_LINKS_FILES_POSITION,
  themeFilesPosition: DEFAULT_THEME_LINKS_FILES_POSITION,
};

const ProfileDesignContext = React.createContext<{
  profileDesign: ProfileDesignConfig;
  refetch(): void;
}>({
  profileDesign: { ...initialValues },
  refetch: () => {},
});

export const ProfileDesignProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const domainVerified = useAppSelector(state => state.login.domainVerified);
  const { getAccessTokenSilently } = useAuth0();
  const [profileDesign, setProfileDesign] = React.useState<ProfileDesignConfig>({
    ...initialValues,
  });

  const fetchData = useCallback(async () => {
    try {
      const { value: profileDesign } = await fetchThemeConfig(
        getAccessTokenSilently,
        THEME_CONFIG_KEYS.PROFILE_DESIGN,
      );
      setProfileDesign(profileDesign);
    } catch {
      //do nothing
    }
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    if (domainVerified) fetchData();
  }, [fetchData, domainVerified]);

  return (
    <ProfileDesignContext.Provider
      value={{
        profileDesign: profileDesign,
        refetch: fetchData,
      }}
    >
      {children}
    </ProfileDesignContext.Provider>
  );
};

export const useProfileDesign = () => {
  const context = React.useContext(ProfileDesignContext);
  if (context === undefined) {
    throw new Error('useProfileDesign must be used within a ');
  }
  return context;
};

const useExternalProfileDesign = (
  usernameOrAccountId: RequireOnlyOne<
    { username: string | undefined; accountId: string | undefined },
    'accountId' | 'username'
  >,
) => {
  const [profileDesign, setProfileDesign] = useState<ProfileDesignConfig>(null);

  const fetchProfileDesign = useCallback(
    () =>
      fetchPublicThemeConfig(THEME_CONFIG_KEYS.PROFILE_DESIGN, usernameOrAccountId)
        .then(setProfileDesign)
        .catch(() => setProfileDesign(initialValues)),
    [usernameOrAccountId],
  );

  useEffect(() => {
    if (usernameOrAccountId.username || usernameOrAccountId.accountId) {
      fetchProfileDesign();
    }
  }, [fetchProfileDesign, usernameOrAccountId.accountId, usernameOrAccountId.username]);

  return { profileDesign, refetch: fetchProfileDesign };
};
export const useExternalProfileDesignByUsername = (username?: string) => {
  const obj = useMemo(() => ({ username }), [username]);
  const returnObjs = useExternalProfileDesign(obj);
  return returnObjs;
};
