import { useAppSelector } from '@/application/hooks';
import { routePaths } from '@/infrastructure/constants';
import { isDarkHex } from '@/infrastructure/helper';
import { Theme } from '@/shared/types/api';
import styled from 'styled-components';
import LTIcon from '@/views/images/lemontap-icon.png';
import LTLogo from '@/views/images/lemontaps-logo.png';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

export const Logo = () => {
  const { themeInternal } = useAppSelector<Theme>(state => state.account.theme);
  const collapsed = useAppSelector(state => state.ui.sidebarCollapsed);
  const theme = useTheme();
  const sidebarColor = theme.palette.sidebar.main;

  return (
    <StyledLink to={routePaths.HOME} focusColor={theme.palette.sidebarActive.main}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='3.2rem'
        px={!collapsed && '4rem'}
      >
        {collapsed ? (
          themeInternal?.logoWideBrightUrl || themeInternal?.logoWideUrl ? null : (
            <LogoImage
              src={LTIcon}
              alt='Lemontaps'
              toggled={collapsed}
              invert={isDarkHex(sidebarColor)}
            />
          )
        ) : themeInternal?.logoWideUrl && themeInternal?.logoWideBrightUrl ? (
          <LogoImage
            src={
              isDarkHex(sidebarColor) ? themeInternal.logoWideBrightUrl : themeInternal.logoWideUrl
            }
            invert={isDarkHex(sidebarColor)}
            alt='custom-logo'
            crossorigin='anonymous'
          />
        ) : !themeInternal?.logoWideBrightUrl && themeInternal?.logoWideUrl ? (
          <LogoImage
            src={themeInternal.logoWideUrl}
            invert={themeInternal.logoWideInvert && isDarkHex(sidebarColor)}
            alt='custom-logo'
            crossorigin='anonymous'
          />
        ) : (
          <LogoImage
            src={LTLogo}
            alt='Lemontaps'
            toggled={collapsed}
            invert={isDarkHex(sidebarColor)}
          />
        )}
      </Box>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &:focus-visible {
    outline: 1px solid ${p => p.focusColor};
  }
`;

const LogoImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  ${props => props.invert && 'filter: brightness(0) invert(1)'};
`;
