import { useMemo } from 'react';
import { Employee } from '../../../../../../shared/types/api/employee.type';
import { ACCOUNT_STATUSES } from '../../../../../../shared/constants';
import useGenericSelection from '@/infrastructure/hooks/useGenericSelection';

const useSelection = (employees: Employee[], page: number, rowsPerPage: number) => {
  const curPageItems = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return employees.slice(start, end);
  }, [employees, page, rowsPerPage]);

  const {
    isSelected,
    selectAllItems,
    selectItem,
    selectedItems,
    unselectAllItems,
    isAllSelected,
    selectAllOnPage,
    unselectAllOnPage,
    isAnySelectedOnCurrentPage,
  } = useGenericSelection<Employee>(employees, curPageItems);

  const selectedIds = useMemo(() => selectedItems.map(e => e.id), [selectedItems]);
  const selectedUsernames = useMemo(() => selectedItems.map(e => e.username), [selectedItems]);
  const selectedStatus = useMemo(
    () => selectedItems.map(e => e.status !== ACCOUNT_STATUSES.ACTIVE),
    [selectedItems],
  );

  const numEmployees: number = employees?.length;
  const numSelected: number = selectedIds?.length;
  const numStatus: number = selectedStatus?.filter(Boolean).length;

  return {
    numSelected,
    numEmployees,
    numStatus,
    selectedEmployees: selectedItems,
    selectedIds,
    selectedUsernames,
    selectedStatus,
    isSelected,
    selectEmployee: selectItem,
    selectAllEmployees: selectAllItems,
    unselectAllEmployees: unselectAllItems,
    isAllSelected,
    selectAllOnPage,
    unselectAllOnPage,
    isAnySelectedOnCurrentPage,
  };
};

export default useSelection;
