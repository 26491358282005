import { Auth0ContextInterface } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../config/config';
import {
  AnalyticsData,
  PublicEvents,
  PrivateEvents,
  PublicMeta,
  PrivateMeta,
} from '../../shared/types/api';
import { LOCAL_STORAGE, PERMISSIONS } from '../constants';
import { checkSameSession, setSession, withSilentAccessToken } from '../helper';
import { getAuth0TokenScopeString } from '@/util';

export const trackPublicEvent = async (
  authUser: {
    username: string;
    isAuthenticated: boolean;
    isAuth0Loading: boolean;
  },
  type: PublicEvents[keyof PublicEvents],
  profileUsername: string,
  fileEntityId?: number,
  linkEntityId?: number,
  meta?: PublicMeta,
) => {
  const query = new URLSearchParams(window.location.search);
  const blocked =
    localStorage.getItem(LOCAL_STORAGE.ltNoTrack) === 'true' || query.get('ltNoTrack') === 'true';

  const data: AnalyticsData = {
    type,
    username: profileUsername,
    fileEntityId,
    linkEntityId,
    meta,
  };

  if (
    ((!authUser.isAuth0Loading && !authUser.isAuthenticated) ||
      (!authUser.isAuth0Loading &&
        authUser.isAuthenticated &&
        authUser.username &&
        authUser.username !== profileUsername)) &&
    !checkSameSession(type, linkEntityId, fileEntityId, meta) &&
    !blocked
  ) {
    setSession(data);
    return await axios.post(config.API_BASE_URL + 'data/event', data);
  }
};

export const trackPrivateEvent = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  type: PrivateEvents[keyof PrivateEvents],
  meta: PrivateMeta,
  fileEntityId?: number,
  linkEntityId?: number,
  md?: boolean,
) => {
  const query = new URLSearchParams(window.location.search);
  const blocked =
    localStorage.getItem(LOCAL_STORAGE.ltNoTrack) === 'true' || query.get('ltNoTrack') === 'true';
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const data: AnalyticsData = {
    type,
    fileEntityId,
    linkEntityId,
    meta,
  };

  if (
    getAccessTokenSilently &&
    !checkSameSession(type, linkEntityId, fileEntityId, meta) &&
    !blocked
  ) {
    setSession(data);
    return await axios.post(
      md
        ? config.API_BASE_URL + 'data/private-event-md'
        : config.API_BASE_URL + 'data/private-event',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

export const getAnalyticsDataTeaser = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  try {
    const res = await withSilentAccessToken(getAccessTokenSilently, token =>
      axios.get(config.API_BASE_URL + 'data/teaser', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    );
    if (res.data?.data) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
