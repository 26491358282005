import { routePaths } from '@/infrastructure/constants';
import useFeatureFlags from '@/infrastructure/hooks/useFeatureFlags';
import { Redirect } from 'react-router-dom';

type BaseProps = {
  privateFlags?: string[];
  publicFlags?: string[];
  loadingComponent?: React.ReactNode | null;
  redirectTo?: string;
};

type Props = BaseProps & {
  children?: React.ReactNode;
};

const FeatureFlagGuard = ({
  children,
  privateFlags = [],
  publicFlags = [],
  loadingComponent = null,
  redirectTo,
}: Props) => {
  const { publicFlags: _publicFlags, privateFlags: _privateFlags, isLoading } = useFeatureFlags();

  if (isLoading) return <>{loadingComponent}</>;

  const isPrivateEnabled = privateFlags.every(flag => _privateFlags[flag]);
  const isPublicEnabled = publicFlags.every(flag => _publicFlags[flag]);

  if (!isPrivateEnabled || !isPublicEnabled)
    return <Redirect to={redirectTo || routePaths.MD.ROOT} />;

  return <>{children}</>;
};

export default FeatureFlagGuard;

//HOC
export function withFeatureFlagGuard<T>(Component: React.ComponentType<T>, args: BaseProps) {
  return (props: T) => (
    <FeatureFlagGuard {...args}>
      <Component {...props} />
    </FeatureFlagGuard>
  );
}
