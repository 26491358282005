import EastIcon from '@mui/icons-material/East';
import { Avatar, Card, CardActionArea, CardContent, Typography } from '@mui/material';

type Props = {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
};

export const SimpleCard = ({ icon, onClick, text }: Props) => {
  return (
    <Card variant='elevation'>
      <CardActionArea sx={{ height: '100%' }} onClick={onClick}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Avatar variant='rounded' sx={{ borderRadius: '0.4rem', bgcolor: 'secondary.main' }}>
            {icon}
          </Avatar>
          <Typography mt={1} variant='h4'>
            {text}
          </Typography>
          <EastIcon fontSize='small' sx={{ ml: 'auto', mt: 'auto' }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
