import React from 'react';
import styled from 'styled-components';
import { DESKTOP_BREAK_POINT } from '../../../../shared/constants';
import { CSS_VARS } from '@/infrastructure/constants';

interface IProps {
  children: React.ReactNode;
  withTopMargin?: boolean;
  withBottomMargin?: boolean;
  width?: {
    s?: string;
    m?: string;
    l?: string;
  };
  transparentBg?: boolean;
  style?: React.CSSProperties;
  fillHeight?: boolean;
}

export const Container: React.FC<IProps> = ({
  children,
  withTopMargin,
  withBottomMargin = true,
  width,
  transparentBg,
  fillHeight,
  style,
  ...props
}) => {
  return (
    <Wrapper
      withTopMargin={withTopMargin}
      withBottomMargin={withBottomMargin}
      transparentBg={transparentBg}
      fillHeight={fillHeight}
    >
      <Content {...props} width={width} style={style}>
        {children}
      </Content>
    </Wrapper>
  );
};

interface WrapperProps {
  withTopMargin?: boolean;
  withBottomMargin?: boolean;
  transparentBg?: boolean;
  fillHeight?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: ${(props: WrapperProps) =>
    props.withBottomMargin
      ? `calc( 100vh - ${CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR} - ${CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR})`
      : '100vh'};

  @media (min-width: ${DESKTOP_BREAK_POINT}px) {
    min-height: ${(props: WrapperProps) =>
      props.withBottomMargin ? `calc( 100vh - ${CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR})` : '100vh'};
  }
  display: flex;
  background: ${(props: WrapperProps) => (props.transparentBg ? 'transparent' : '#fff')};
  justify-content: center;
  scroll-behavior: smooth;

  ${(props: WrapperProps) =>
    props.fillHeight
      ? `
    min-height: calc(100vh - ${CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR} - ${CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR});
    @media (min-width: ${DESKTOP_BREAK_POINT}px) {
      min-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
    };
  `
      : ''}

  ${(props: WrapperProps) =>
    props.withTopMargin ? `padding-top: ${CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR};` : ''}

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Content = styled.div`
  width: ${(props: { width: IProps['width'] }) => props.width?.l || '50%'};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    width: ${props => props.width?.m || '60%'};
  }
  @media (max-width: 767px) {
    width: ${props => props.width?.s || '90%'};
  }
`;
