import { getLeadLTAccount, getLeadOptionTypes } from '@/infrastructure/apis/leadGen';
import {
  Account,
  LeadAPI,
  LeadDataProps,
  LeadOptionTypeAPI,
  MDLead,
  ThemeAccount,
} from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BusinessCardImage } from './components/BusinessCardImage';
import { NotesField } from './components/NotesField';
import { LtDialog, MuiLoader, SettingsMenu } from '@/components';
import { Lt2ltProfileHeader } from './components/Lt2LtProfileHeader';
import { useExternalProfileDesignByUsername } from '@/infrastructure/hooks/useProfileDesign';
import { LTProfileInfo } from './components/LTProfileInfo';
import config from '@/config/config';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { ProfileImage } from './components/ProfileImage';
import { openLink } from '@/util';
import { LeadOptionsRenderer } from '../RenderLeadOptions';
import { FieldsRenderer } from './components/FieldsRenderer';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import moment from 'moment';
import { useAppSelector } from '@/application/hooks';
import { AccountsSelector } from './components/AccountsSelector';

type Props = {
  mobileSettingAnchorEl?: HTMLElement;
  onMobileSettingClose?: () => void;
  onDelete: (leadId: number) => Promise<void>;
  focusNotes?: boolean;
  lead?: LeadAPI | MDLead;
  loading?: boolean;
  isMd?: boolean;
  themeAccounts?: ThemeAccount[];
  onAccountSelect?: (accountId: string) => void;
  selectedAccountId?: string;
};

export const LeadForm = forwardRef((props: Props, ref) => {
  const {
    mobileSettingAnchorEl,
    onMobileSettingClose,
    onDelete,
    focusNotes,
    lead,
    loading,
    themeAccounts,
    onAccountSelect,
    selectedAccountId,
    isMd,
  } = props;
  const [leadOptionTypes, setLeadOptionTypes] = useState<Array<LeadOptionTypeAPI>>([]);

  const { withUnsavedSetter } = useUnsavedStatusSetter();
  const [lt2ltLoading, setLt2LtLoading] = useState(false);

  const [leadData, _setLeadData] = useState<Partial<LeadAPI>>({});
  const setLeadData = withUnsavedSetter(_setLeadData);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  const [lt2ltAccount, setlt2ltAccount] = useState<Account>(null);
  const [businessCardFile, setBusinessCardFile] = useState<File | null>(null);
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);

  const { profileDesign } = useExternalProfileDesignByUsername(lt2ltAccount?.username);

  const { config: allowedLeadKeys, loading: allowedKeysLoading } =
    usePrivateThemeConfig<string[]>('lead-details-keys');

  const account = useAppSelector<Account>(state => state.account);
  const accountId = account.id;

  const { getAccessTokenSilently } = useAuth0();

  const { t } = useTranslation();
  const history = useHistory();
  const { activeLanguage } = useAppTranslation();

  const isLt2Lt = Boolean(lt2ltAccount);

  useImperativeHandle(ref, () => ({
    getValues: () => {
      return {
        leadData,
        businessCardFile,
        profileImageFile,
      };
    },
    resetValues: () => {
      setBusinessCardFile(null);
      setProfileImageFile(null);
    },
  }));

  useEffect(() => {
    if (!lead) return;

    const fetchData = async () => {
      setLt2LtLoading(true);
      try {
        const { lt2lt, ...restFields } = lead;
        _setLeadData(restFields);
        if (lt2lt) {
          const leadAccount = await getLeadLTAccount(getAccessTokenSilently, lt2lt.username);
          setlt2ltAccount(leadAccount);
        }
      } catch (error) {
        history.goBack();
        toast.error(t('error.general'));
      }
      setLt2LtLoading(false);
    };
    fetchData();
  }, [lead, getAccessTokenSilently, t, history, setLt2LtLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLeadOptionTypes(getAccessTokenSilently, accountId);
        setLeadOptionTypes(data);
      } catch {
        //
      }
    };
    fetchData();
  }, [accountId, getAccessTokenSilently]);

  const handleBusinessCardDelete = () => {
    setLeadData({ ...leadData, businessCardUrl: '' });
    setBusinessCardFile(null);
  };

  const handleDownload = () => {
    let url = null;
    if (isLt2Lt && lt2ltAccount) {
      url = `${config.API_BASE_URL}account/download/contact?accountId=${lt2ltAccount.id}&lang=${activeLanguage}`;
    } else if (lead?.id) {
      url = `${config.API_BASE_URL}lead-gen/accounts/${lead.account?.id || account.id}/leads/${
        lead.id
      }/vcard`;
    }
    if (url) openLink(url);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    if (!lead) return;
    setDeleteLoading(true);
    await onDelete(lead.id);
    setDeletePopupOpened(false);
    setDeleteLoading(false);
  };

  const mobileSettingsMenuItems = [
    {
      icon: <DeleteOutlineIcon />,
      label: t('delete'),
      onClick: () => setDeletePopupOpened(true),
    },
    {
      icon: <DownloadIcon />,
      label: t('downloadContactFile'),
      onClick: handleDownload,
    },
  ];

  //If notes field is allowed, remove it from the list of allowed fields and render it separately

  const showLoader = loading || lt2ltLoading || (isLt2Lt && !profileDesign) || allowedKeysLoading;
  if (showLoader) {
    return <MuiLoader />;
  }

  //If notes field is allowed, remove it from the list of allowed fields and render it separately
  const hasNotesField = allowedLeadKeys.includes('notes');

  //filter with notes and createdOn fields
  const renderFields = allowedLeadKeys.filter(field => field !== 'notes' && field !== 'createdOn');

  return (
    <>
      <Box p={2}>
        {isLt2Lt && <Lt2ltProfileHeader profileDesign={profileDesign} account={lt2ltAccount} />}
        {!isLt2Lt && (
          <ProfileImage
            onChange={(file: File) => setProfileImageFile(file)}
            value={leadData.profileImageUrl}
          />
        )}
        {hasNotesField && (
          <NotesField
            focused={focusNotes}
            sx={{ mb: 2 }}
            value={leadData.notes || ''}
            onChange={value => setLeadData({ ...leadData, notes: value })}
          />
        )}

        <LeadOptionsRenderer
          leadOptionTypes={leadOptionTypes}
          leadOptions={leadData.leadOptions}
          onChange={value => setLeadData({ ...leadData, leadOptions: value })}
        />
        {isLt2Lt && <LTProfileInfo lt2ltAccount={lt2ltAccount} profileDesign={profileDesign} />}

        {!isLt2Lt && (
          <Box display='flex' flexDirection='column' gap={2} mb={2}>
            <FieldsRenderer
              fields={renderFields as Array<keyof LeadDataProps>}
              values={leadData}
              onChange={values => setLeadData(values)}
            />
            {!isMd && (
              <TextField
                value={moment(leadData.createdOn || new Date())
                  .format()
                  .substring(0, 16)}
                type='datetime-local'
                label={t('createdOn')}
                onChange={withUnsavedSetter(e =>
                  setLeadData({ ...leadData, createdOn: e.target.value }),
                )}
                fullWidth
              />
            )}
          </Box>
        )}
        {!isLt2Lt && (
          <BusinessCardImage
            onChange={(file: File) => setBusinessCardFile(file)}
            value={leadData.businessCardUrl}
            onDelete={handleBusinessCardDelete}
          />
        )}
        {isMd && (
          <>
            <TextField
              value={moment(leadData.createdOn || new Date())
                .format()
                .substring(0, 16)}
              type='datetime-local'
              label={t('mdLeads.metAt')}
              onChange={withUnsavedSetter(e =>
                setLeadData({ ...leadData, createdOn: e.target.value }),
              )}
              fullWidth
              sx={{ mb: 2 }}
            />
            {themeAccounts && themeAccounts.length > 0 && (
              <AccountsSelector
                lead={lead}
                themeAccounts={themeAccounts}
                selectedAccountId={selectedAccountId}
                onSelect={onAccountSelect}
              />
            )}
          </>
        )}
        {lead && (
          <Box
            display={{ xs: 'none', md: 'flex' }}
            gap={2}
            alignItems={'center'}
            justifyContent={'center'}
            mt={2}
          >
            <Button
              onClick={() => setDeletePopupOpened(true)}
              color='error'
              startIcon={<DeleteOutlineIcon />}
            >
              {t('delete')}
            </Button>
            <Button onClick={handleDownload} startIcon={<DownloadIcon />}>
              {t('downloadContactFile')}
            </Button>
          </Box>
        )}
        <Box height='9rem' />
      </Box>

      <LtDialog
        title={t('requestDelete')}
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={handleDelete}
        loading={deleteLoading}
      >
        {t('deleteLeadMsg')}
      </LtDialog>

      {lead && mobileSettingAnchorEl && (
        <SettingsMenu
          anchorEl={mobileSettingAnchorEl}
          onClose={onMobileSettingClose}
          menuItems={mobileSettingsMenuItems}
        />
      )}
    </>
  );
});
