import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import backIcon from '../../images/back-black.svg';
import closeIcon from '../../images/close.svg';
import { Button } from './button';
import { ErrorMessage } from './error-message';
import useCloseByEscape from '../../../infrastructure/hooks/useCloseByEscape';
import { Overlay } from './overlay';
import { useAppSelector } from '../../../application/hooks';
import { DESKTOP_BREAK_POINT } from '@/shared/constants';
import { CSS_VARS } from '@/infrastructure/constants';

type Props = {
  headerText?: string;
  onCloseClick?: () => void;
  btn?: {
    type?: 'button' | 'submit' | 'reset';
    text?: string;
    onClick?: () => void;
    noBg?: boolean;
    disabled?: boolean;
  };
  secondBtn?: {
    type?: 'button' | 'submit' | 'reset';
    text?: string;
    onClick?: () => void;
    noBg?: boolean;
    disabled?: boolean;
  };
  noBack?: boolean;
  onBackClick?: () => void;
  bodyText?: string;
  noClose?: boolean;
  isErrorPopup?: boolean;
  customContentWrapperStyles?: React.CSSProperties;
  withSideBar?: boolean;
};

export const Popup: React.FC<Props> = props => {
  useCloseByEscape(props.onCloseClick);

  const { withSideBar = true } = props;

  const isSidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  return ReactDOM.createPortal(
    <>
      <StyledContainer withSideBar={withSideBar} isSidebarCollapsed={isSidebarCollapsed}>
        <Wrapper>
          <Header>
            <div>
              {props.onBackClick && !props.noBack && (
                <Image src={backIcon} alt='Back' onClick={() => props.onBackClick()} />
              )}
            </div>
            <HeaderText>{props.headerText || ''}</HeaderText>
            {!props.noClose && props.onCloseClick && (
              <div
                style={
                  props.isErrorPopup && {
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }
                }
              >
                <Image src={closeIcon} alt='Close' onClick={props.onCloseClick} />
              </div>
            )}
          </Header>
          {props.isErrorPopup ? (
            <ErrorMessage message={props.bodyText} />
          ) : (
            <ContentWrapper>{props.bodyText}</ContentWrapper>
          )}
          <ContentWrapper style={props.customContentWrapperStyles}>{props.children}</ContentWrapper>
          {(props.btn || props.secondBtn) && (
            <ButtonWrapper>
              {props.btn && (
                <StyledButton
                  text={props.btn.text}
                  onClick={
                    props.btn.type === 'reset'
                      ? props.onCloseClick && props.onCloseClick
                      : props.btn.onClick
                  }
                  noBg={props.btn.noBg}
                  disabled={props.btn.disabled}
                />
              )}
              {props.secondBtn && (
                <StyledButton
                  text={props.secondBtn.text}
                  onClick={
                    props.secondBtn.type === 'reset'
                      ? props.onCloseClick && props.onCloseClick
                      : props.secondBtn.onClick
                  }
                  noBg={props.secondBtn.noBg}
                  disabled={props.secondBtn.disabled}
                />
              )}
            </ButtonWrapper>
          )}
        </Wrapper>
      </StyledContainer>
      <Overlay />
    </>,
    document.getElementById('portal'),
  );
};

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${props =>
    props.withSideBar
      ? props.isSidebarCollapsed
        ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
        : CSS_VARS.LT_SIDEBAR_WIDTH_VAR
      : '0'};
  z-index: 100;
  transform: translate3d(0, 0, 0);
  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    left: 0;
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 25px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 80;
  width: 35vw;
  max-height: 80vh;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    max-width: 95vw;
    width: 90%;
  }

  @media (min-width: ${DESKTOP_BREAK_POINT + 1}px) and (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 320px) {
    width: 80%;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 20px auto 20px;
  padding-bottom: 3rem;
`;

const Image = styled.img`
  height: 18px;
  cursor: pointer;
`;

const HeaderText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #55595e;
`;

const ContentWrapper = styled.div`
  overflow: auto;
  flex: 1;
  text-align: center;
`;

const StyledButton = styled(Button)`
  min-width: 150px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
`;
