import { routePaths } from '@/infrastructure/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import {
  AccountCircleOutlined,
  AutoGraphOutlined,
  ContactPhoneOutlined,
  CreditCardOutlined,
  DirectionsOutlined,
  SettingsOutlined,
  SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import { FEATURE } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { setSidebarSettingsCollapsed } from '@/application/actions/ui';
import { NavItem } from '@/infrastructure/hoc/types';
import { Account } from '@/shared/types/api';

export const useMdItems = (): NavItem[] => {
  const { isUnitAdmin, isFeatureAllowed, isStarter } = useTierInfo();
  const dispatch = useAppDispatch();
  const account = useAppSelector<Account>(state => state.account);

  const settingsRoute = window.location.pathname.startsWith('/md/settings')
    ? window.location.pathname
    : isUnitAdmin
    ? routePaths.MD.CUSTOMISESETTINGS.EDIT_RIGHTS
    : routePaths.MD.CUSTOMISESETTINGS.COMPANY_DATA;

  if (isStarter) return [];

  return [
    {
      to: routePaths.MD.GUIDE,
      icon: DirectionsOutlined,
      text: 'guides',
    },
    {
      to: routePaths.MD.PROFILES,
      icon: AccountCircleOutlined,
      text: 'profiles',
    },
    {
      to: routePaths.MD.UNITS,
      icon: SupervisedUserCircleOutlined,
      text: 'units',
      hide: isUnitAdmin,
      badge: !isFeatureAllowed(FEATURE.UNITS) ? 'Enterprise' : undefined,
    },
    {
      to: routePaths.MD.CONTACTS,
      icon: ContactPhoneOutlined,
      text: 'contacts',
      badge: !isFeatureAllowed(FEATURE.LEAD_GEN_MD) ? 'Enterprise' : undefined,
    },
    {
      to: routePaths.MD.ANALYTICS,
      icon: AutoGraphOutlined,
      text: 'mdAnalytics.sideBarLabel',
      hide: isUnitAdmin,
    },
    {
      to: routePaths.MD.NFCCARDS,
      icon: CreditCardOutlined,
      text: 'nfc.title',
      hide: isUnitAdmin && account?.theme?.name === 'wacker', // hard coded until we have solution for managing this
    },
    {
      to: settingsRoute,
      icon: SettingsOutlined,
      text: 'teamSettings',
      onClick: () => {
        dispatch(setSidebarSettingsCollapsed(true));
      },
    },
  ];
};
