import { EmailTemplatesEnum } from '@/shared/constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  value: EmailTemplatesEnum;
  options: EmailTemplatesEnum[];
  onChange: (value: EmailTemplatesEnum) => void;
};

export const TemplateSelector = ({ value, options, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel>{t('emailTemplates.selectTemplate')}</InputLabel>
      <Select
        value={value}
        label={t('emailTemplates.selectTemplate')}
        onChange={({ target: { value } }) => onChange(value as EmailTemplatesEnum)}
      >
        {options.map(key => (
          <MenuItem key={key} value={key}>
            {t(`emailTemplates.${key}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
