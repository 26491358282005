import { Account, UnitOverrideRightsKey, UnitOverrideSettings } from '@/shared/types/api';

import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback } from 'react';
import { fetchThemeConfig } from '../apis/md/customization/jsonConfigs';

export const useUnitOverrideSettings = (options: { unitId?: number; account?: Account }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [unitOverrideSettings, setUnitOverrideSettings] =
    React.useState<UnitOverrideSettings | null>(null);
  const unitId = options.unitId;
  const account = options.account;

  const isUnitOverrideAllowed = useCallback(
    (key: UnitOverrideRightsKey) => Boolean(unitOverrideSettings?.[key]),
    [unitOverrideSettings],
  );

  React.useEffect(() => {
    if (unitId || account) {
      const fetchData = async () => {
        try {
          const { value: result } = await fetchThemeConfig(getAccessTokenSilently, {
            key: THEME_CONFIG_KEYS.OVERRIDE_SETTINGS,
            unitId: unitId,
          });
          setUnitOverrideSettings(result);
        } catch {
          //do nothing
        }
      };
      fetchData();
    }
  }, [account, getAccessTokenSilently, unitId]);

  return {
    isUnitOverrideAllowed,
    unitOverrideSettings,
    fetched: unitOverrideSettings !== null,
  };
};
