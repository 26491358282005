import { Box } from '@mui/material';
import { VIDEOS } from '../../../../../infrastructure/constants';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import {
  GreenButton,
  Heading,
  MoreInfoButton,
  Section,
  StyledVideo,
  StyledVideoContainer,
  StyledVideoDescriptionExpired,
} from '../styled';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';

const VideoSectionExpired = ({ onShowUpgradePopup }: { onShowUpgradePopup: Function }) => {
  const { t, activeLanguage } = useAppTranslation();
  const { src, title } = activeLanguage.startsWith('de') ? VIDEOS.MD.DE : VIDEOS.MD.EN;
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  return (
    <Section>
      <StyledVideoContainer>
        <Box>
          <StyledVideo src={src} title={title} allowFullScreen></StyledVideo>
        </Box>
        <StyledVideoDescriptionExpired>
          <Heading $big>{t('teaser.expired')}</Heading>

          <GreenButton style={{ margin: '2rem 0 1rem' }} onClick={upgradeAction}>
            {flag_hidePrices ? t('contactSales') : t('upgradeNow')}
          </GreenButton>
          {upgradeRequestPopup}

          <MoreInfoButton
            target='_blank'
            href={
              activeLanguage.startsWith('de')
                ? 'https://lemontaps.de/pages/preise'
                : 'https://lemontaps.de/en/pages/prices'
            }
          >
            {t('teaser.moreInfo')}
            {' ->'}
          </MoreInfoButton>
        </StyledVideoDescriptionExpired>
      </StyledVideoContainer>
    </Section>
  );
};

export default VideoSectionExpired;
