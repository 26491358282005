// this popup will be shown when a user upgrades on mobile, and therefore is not able to access the md

import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LtDialog } from '@/components';
import { Box, List, ListItem, Typography } from '@mui/material';
import addLemontapsOnApp1 from '@/views/images/addLemontapsOnApp1.png';
import addLemontapsOnApp2 from '@/views/images/addLemontapsOnApp2.png';
import addLemontapsOnApp2DE from '@/views/images/addLemontapsOnApp2-de.jpg';
import { QRCodeCanvas } from 'qrcode.react';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';

interface Props {
  open?: boolean;
  onClose(): void;
  domain?: string;
}

export default function AddAppToHomePopup({ onClose, open, domain }: Props) {
  const { t, activeLanguage } = useAppTranslation();

  const isDe = activeLanguage.startsWith('de');
  const isDesktop = useIsDesktopMui();

  return (
    <LtDialog
      size='lg'
      open={open}
      title={t('homePage.addAppToPhone.popupTitle')}
      onClose={onClose}
      confirmAction={{
        onClick: onClose,
        text: t('close'),
      }}
    >
      <Typography variant='body2' component='div'>
        <List sx={{ pl: 2, listStyleType: 'decimal' }} component='ol'>
          <ListItem sx={{ display: 'list-item', px: 0.4, py: 1.2 }}>
            <Box component='p'>{t('homePage.addAppToPhone.step1')}</Box>
            {isDesktop && (
              <Box mt={1.6}>
                <QRCodeCanvas id='qr-code-canvas' value={`${domain}/login`} size={110} />
              </Box>
            )}
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.4 }}>
            <Box component='p'>{t('homePage.addAppToPhone.step2')}</Box>
            <Box width={{ xs: '100%', md: '30rem' }} mt={1}>
              <img width='100%' src={addLemontapsOnApp1} alt='' />
            </Box>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.4 }}>
            <Box component='p'>{t('homePage.addAppToPhone.step3')}</Box>
            <Box width={{ xs: '100%', md: '30rem' }} mt={1}>
              <img width='100%' src={isDe ? addLemontapsOnApp2DE : addLemontapsOnApp2} alt='' />
            </Box>
          </ListItem>
        </List>
      </Typography>
    </LtDialog>
  );
}
