import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { CSS_VARS } from '@/infrastructure/constants';

export type ButtonType = 'primary' | 'secondary' | 'submit';

interface Props {
  onClick?: () => void;
  sticky?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
  text: string;
  vMargin?: string;
  noBg?: boolean;
  type?: ButtonType;
  className?: string;
  styles?: CSSProperties;
}

export const Button = (props: Props): JSX.Element => {
  const { onClick, sticky, disabled, text, vMargin, noBg, type, className, noBorder, styles } =
    props;

  const handleClick = onClick ? () => onClick() : null;

  return (
    <>
      <ButtonContainer sticky={sticky}>
        <ButtonWrapper
          type='submit'
          disabled={disabled ? disabled : false}
          noBorder={noBorder ? noBorder : false}
          value={text}
          onClick={handleClick}
          vMargin={vMargin}
          noBg={noBg ? true : false}
          types={type}
          className={className}
          styles={styles}
        />
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  ${props =>
    props.sticky &&
    css`
      position: sticky;
      margin: 0 auto;
      bottom: 5px;
      width: 80%;
      z-index: 10;
    `}
`;

interface ButtonWrapperProps {
  types: ButtonType;
  vMargin: string;
  noBg: boolean;
}

const ButtonWrapper = styled.input`
  ${(props: ButtonWrapperProps) =>
    props.types === 'secondary'
      ? css`
          background: #ffffff;
          border: none;
          border-radius: 30px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          color: #666666;
        `
      : css`
          background: linear-gradient(
            90deg,
            ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} 0%,
            ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} 100%
          );
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
          border-radius: 30px;
          border: none;
          color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};

          &:hover {
            opacity: 0.8;
            color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
          }
        `};
  ${props => props.disabled && 'background: #bebebe; border-style: none;'};
  ${props =>
    props.vMargin
      ? css`
          margin-top: ${props.vMargin};
          margin-bottom: ${props.vMargin};
        `
      : ''}
  ${props =>
    props.noBg
      ? css`
          background: none;
          background: linear-gradient(90deg, #00ffe7, #00c27c);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} !important;
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
          text-decoration-color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} !important;
          border: none;
          box-shadow: none;
        `
      : ''}
  transition: all .2s;
  height: 52px;
  box-sizing: border-box;
  width: 100%;
  border-radius: ${props => (props.noBorder ? '10px' : '3rem')};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  ${props => props.styles && props.styles}
`;
