import { Dispatch, SetStateAction, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import toast from 'react-hot-toast';
import { SubsectionHeader } from '../../pages/md/common';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { checkCode } from '../../../infrastructure/apis/md/profiles';
import styled from 'styled-components';
import ScannerPopup from './popup';
import ConnectBtn from './connect-button';
import { BsChevronDown } from 'react-icons/bs';

export interface Props {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  codeExists: boolean;
  setCodeExists: Dispatch<SetStateAction<boolean>>;
}

const CodeBox = (props: Props) => {
  const { code, setCode, codeExists, setCodeExists } = props;
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [showCodeScannerPopup, setShowCodeScannerPopup] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [codeIsChecking, setCodeIsChecking] = useState(false);
  const [key, setKey] = useState(0);

  const handleCode = async (_code: string) => {
    setCode(_code);
    if (_code.length === 10) {
      const promise = new Promise(async (resolve, reject) => {
        setCodeIsChecking(true);
        const response = await checkCode(_code, getAccessTokenSilently);
        const result = response.data;
        if (result.isSuccess) {
          setCodeExists(true);
          setKey(key + 1);
          resolve(result);
          setShowCodeScannerPopup(false);
        } else {
          setCodeExists(false);
          reject(result);
        }
        setCodeIsChecking(false);
      });
      toast.promise(promise, {
        loading: t('codeRead.loadingMD'),
        success: t('codeRead.successMD'),
        error: t('codeRead.errorMD'),
      });
    } else {
      setCodeExists(false);
      setCodeIsChecking(false);
    }
  };

  const onResetCode = (): SetStateAction<string | boolean | number> => {
    setCode('');
    setCodeExists(false);
    setKey(key + 1);
    return;
  };

  const handleCloseClick = () => setShowCodeScannerPopup(false);

  const handleOnScanTrigger = (): SetStateAction<boolean> => {
    setShowCodeScannerPopup(true);
    return;
  };

  return (
    <>
      <CodeWrapper>
        <CodeHeaderWrapper>
          <SubsectionHeader>{t('codeRead.connectHeadlineMD')}</SubsectionHeader>
        </CodeHeaderWrapper>
        <CodeMessage>{t('codeRead.connectMessageMD')}</CodeMessage>
        <ConnectBtn
          codeExists={codeExists}
          onScanTrigger={handleOnScanTrigger}
          onResetCode={onResetCode}
          t={t}
        />
        {!codeExists && (
          <>
            <EnterCodeText
              onClick={() => (showCodeInput ? setShowCodeInput(false) : setShowCodeInput(true))}
            >
              {t('codeRead.enterCodeText')}
              <StyledChevron flip={showCodeInput.toString()} />
            </EnterCodeText>
            {showCodeInput && (
              <ReactCodeInput
                type='text'
                fields={10}
                autoFocus={false}
                value={code}
                key={key}
                onChange={e => handleCode(e)}
                filterChars={[
                  '!',
                  '@',
                  '#',
                  '$',
                  '%',
                  '^',
                  '&',
                  '*',
                  '(',
                  ')',
                  '[',
                  ']',
                  '{',
                  '}',
                  '/',
                  '|',
                  '+',
                  '-',
                  '=',
                  ';',
                  ':',
                  ',',
                  "'",
                  '"',
                  '`',
                  ' ',
                ]}
                name='dashboard-code-input'
                inputMode='verbatim'
              />
            )}
          </>
        )}
      </CodeWrapper>
      {showCodeScannerPopup && (
        <ScannerPopup
          onCloseClick={handleCloseClick}
          onHandleCode={handleCode}
          codeIsChecking={codeIsChecking}
          t={t}
        />
      )}
    </>
  );
};

export default CodeBox;

const CodeWrapper = styled.div`
  width: 100%;
  /* OVERWRITE CODE DEFAULT STYLES */

  .react-code-input {
    display: flex !important;
    flex-flow: row nowrap;
    justify-content: center;

    input {
      font-family: inherit !important;
      font-size: 20px !important;
      font-weight: 700;
      text-align: center;
      color: #4748ec !important;
      box-shadow: 0 0 0 0 !important;
      width: 8% !important;
      height: auto !important;
      padding: 0 !important;
      &:focus {
        border: 1px solid #4748ec !important;
        box-shadow: 0px 0px 4px 3px rgba(71, 72, 236, 0.25) !important;
      }
    }
  }
`;

const CodeHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EnterCodeText = styled.p`
  font-size: small;
  text-align: center;
  cursor: pointer;
`;

const StyledChevron = styled(BsChevronDown)`
  transform: rotate(${props => (props.flip === 'true' ? '180deg' : '0deg')})
    ${props => (props.flip === 'true' ? '' : 'translateY(50%)')};
  transition: transform 0.3s ease;
  position: relative;
  top: ${props => (props.flip === 'true' ? '4px' : '-4px')};
  left: 4px;
`;

const CodeMessage = styled.p`
  font-size: small;
  font-weight: 400;
  padding: 0px 5px;
  margin-bottom: 10px;
`;
