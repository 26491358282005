import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { Popup } from '../../components/common';
import { Heading, SubText } from '../../components/generic';
import { PopopWrapper } from './styled';

type Props = {
  onClose: () => void;
  success?: boolean;
};

const PopupMessage = (props: Props) => {
  const { t } = useAppTranslation();
  const anchor = useRef(null);
  return (
    <Popup onCloseClick={props.onClose}>
      <PopopWrapper>
        {props.success ? (
          <>
            <Heading size='2.3rem' fixSize>
              {t('referrals.redeem.popup.title')}
            </Heading>
            <SubText color='#000' size='1.5rem' fontWeight='400'>
              <Trans
                i18nKey='referrals.redeem.popup.success'
                components={[
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    ref={anchor}
                    href='mailto:contact@lemontaps.com'
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: '#000', fontWeight: '600' }}
                    onMouseOver={() => (anchor.current.style.color = '#000')}
                  />,
                ]}
              />
            </SubText>
          </>
        ) : (
          <>
            <SubText color='#000' size='1.5rem' fontWeight='400'>
              {t('referrals.redeem.popup.failed')}
            </SubText>
          </>
        )}
      </PopopWrapper>
    </Popup>
  );
};

export default PopupMessage;
