import { combineReducers } from 'redux';
import loginReducer from './login';
import userReducer from './user';
import editProfileReducer from './edit-profile';
import editBioReducer from './edit-bio';
import onboardingReducer from './onboarding';
import accountReducer from './account';
import mdReducer from './md/profiles';
import verificationReducer from './verification';
import landingReducer from './landing';
import leadGenReducer from './lead-gen';
import uiReducer from './ui';
import tempReducer from './temporary';

const appReducer = combineReducers({
  login: loginReducer,
  landing: landingReducer,
  account: accountReducer,
  user: userReducer,
  editProfile: editProfileReducer,
  editBio: editBioReducer,
  onboarding: onboardingReducer,
  md: mdReducer,
  verification: verificationReducer,
  leadGen: leadGenReducer,
  ui: uiReducer,
  temporary: tempReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
