import { dispatchTypes } from '../../index';
import { updateImage, uploadImage } from './images';
import { fetchThemeLinkTypes } from '../../../../infrastructure/apis/edit-profile';
import {
  UpdateEmployeeDataProps,
  AddEmployeeDataProps,
  toUpdateImagesType,
  _getEmployees,
  _sendEmail,
} from '../../../../infrastructure/apis/md/profiles';
import { _updateEmployee, _addEmployee } from '../../../../infrastructure/apis/md/profiles';
import { ACCOUNT_STATUSES, PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../../shared/constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import { reorderLinksAPI } from '../../../../infrastructure/apis/md/profiles/links';
import { reorderFilesAPI } from '../../../../infrastructure/apis/md/profiles/files';
import { Link, ApiFile } from '../../../../shared/types/api';
import { trackPrivateEvent } from '../../../../infrastructure/apis/analytics';
import { TFunction } from 'i18next';

export const getEmployees =
  (
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    searchTerm = '',
    selectedColumn = 'firstName',
    t: TFunction,
    params?: {
      onSuccess?: Function;
      onFail?: Function;
      withToast?: boolean;
      withErrorToast?: boolean;
    },
    unitId?,
    orderByParams?: { orderBy: string; sort: string },
  ) =>
  async dispatch => {
    const toastId = 'getEmployees-toast';
    const { onSuccess, onFail, withToast, withErrorToast } = params || {};
    let toastTimeoutId = 0;
    try {
      if (withToast) {
        // show toast only if loading takes longer than 500ms
        toastTimeoutId = setTimeout(
          () => toast.loading(t('Loading'), { id: toastId }),
          500,
          'delay-loading-toast',
        );
      }
      // TODO: implement pagination for list of employees and update page and pageSize based on pagination
      const page = 0;
      const pageSize = 99999;
      let response = await _getEmployees(
        getAccessTokenSilently,
        searchTerm,
        selectedColumn,
        unitId,
        page,
        pageSize,
        orderByParams,
      );

      const result = response.data;
      if (result.isSuccess) {
        const employees = result.data.accounts;
        dispatch({ type: dispatchTypes.BUSINESS.FETCHEMPLOYEES, employees });
        if (withToast) toast.dismiss(toastId);
        onSuccess?.();
      } else {
        // Will handle it later when I add onFail cb
        if (withToast || withErrorToast) toast.error(t('errorFetchingProfiles'), { id: toastId });
        onFail?.();
      }
    } catch (error) {
      // Will handle it later when I add onFail cb
      if (withToast || withErrorToast) toast.error(t('errorFetchingProfiles'), { id: toastId });
      onFail?.();
    }
    clearTimeout(toastTimeoutId);
  };

export const updateEmployee =
  (
    isAdmin: boolean,
    updatedAccount: UpdateEmployeeDataProps,
    toUpdateImages: toUpdateImagesType,
    toUpdateLinks: Link[],
    toUpdateFiles: ApiFile[],
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess: () => void,
    onFailed: () => void,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });
    const { id, username } = updatedAccount;

    const { profileImageFile, bannerImageFile, logoHeaderFile } = toUpdateImages;
    let profileImageUrl: string, bannerImageUrl: string, logoHeaderUrl: string;

    try {
      if (profileImageFile) {
        profileImageUrl = await doS3UploadForBusiness(
          `profile/${username}`,
          profileImageFile,
          id,
          getAccessTokenSilently,
          'profile',
        );
      }

      if (bannerImageFile) {
        bannerImageUrl = await doS3UploadForBusiness(
          `banner/${username}`,
          bannerImageFile,
          id,
          getAccessTokenSilently,
          'banner',
        );
      }

      if (toUpdateImages.bannerImageUrl && !bannerImageFile)
        bannerImageUrl = toUpdateImages.bannerImageUrl;

      if (logoHeaderFile) {
        logoHeaderUrl = await doS3UploadForBusiness(
          `logoHeader/${username}`,
          logoHeaderFile,
          id,
          getAccessTokenSilently,
          'logoHeader',
        );
      } else logoHeaderUrl = toUpdateImages.logoHeaderUrl;

      const { defaultLinkId } = updatedAccount;
      let defaultLink = defaultLinkId && toUpdateLinks.find(link => link.id === defaultLinkId);

      toUpdateLinks.forEach(l => (!l.isShallow ? l : delete l.id));
      const linksResponse = await reorderLinksAPI(id, toUpdateLinks, getAccessTokenSilently);
      const links = linksResponse.data.data;

      if (defaultLink) {
        defaultLink = links.find(
          l =>
            l.name === defaultLink.name &&
            l.link === defaultLink.link &&
            l.linkTypeId === defaultLink.linkTypeId,
        );
        updatedAccount.defaultLinkId = defaultLink.id;
      }

      if (toUpdateFiles.length) {
        // const progressToastFct = (starttime: number, filename: string) => (progress: number) =>
        //   Date.now() - starttime > 2000
        //     ? progress < 1
        //       ? toast(`"${filename}": ${Math.round(progress * 100)}%`, {
        //           id: 'fileupload',
        //         })
        //       : setTimeout(() => toast.dismiss('fileupload'), 500)
        //     : undefined;
        for (const file of toUpdateFiles) {
          if (file.uploadedFile instanceof File) {
            // const starttime = Date.now();
            const url = file.link
              ? file.link
              : await doS3UploadForBusiness(
                  `file/${username}`,
                  file.uploadedFile,
                  id,
                  getAccessTokenSilently,
                  undefined,
                  // progressToastFct(starttime, file.uploadedFile.name),
                );

            delete file.id;
            file.link = url;
          } else if (file.fileTemplateId) delete file.id;
        }
      }

      const filesResponse = await reorderFilesAPI(id, toUpdateFiles, getAccessTokenSilently);
      const files = filesResponse.data.data;

      updatedAccount = {
        ...updatedAccount,
        ...(profileImageUrl && { profileImageUrl }),
        bannerImageUrl,
        logoHeaderUrl,
        links,
        files,
      };
      const response = await _updateEmployee(updatedAccount, getAccessTokenSilently);
      const result = response.data;

      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELDS,
          id,
          fields: Object.keys(updatedAccount),
          values: Object.values(updatedAccount),
        });

        if (isAdmin) {
          dispatch({
            type: dispatchTypes.COMMON.UPDATEACCOUNT,
            account: updatedAccount,
          });
        }

        // tracking events
        profileImageFile &&
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_SINGLE,
            {
              type: PRIVATE_META_TYPES.PROFILE_PICTURE,
              editedAccountId: id,
            },
            null,
            null,
            true,
          );

        bannerImageFile &&
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_SINGLE,
            {
              type: PRIVATE_META_TYPES.BANNER,
              editedAccountId: id,
            },
            null,
            null,
            true,
          );

        trackPrivateEvent(
          getAccessTokenSilently,
          PRIVATE_EVENTS.PROFILE_EDIT_MD_SINGLE,
          {
            type: PRIVATE_META_TYPES.BIO,
          },
          null,
          null,
          true,
        );

        onSuccess();
      } else {
        onFailed();
      }
    } catch (error) {
      onFailed();
    }

    dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });
  };

export const addEmployee =
  (
    createdAccount: AddEmployeeDataProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess: () => void,
    onFailed: (message: string) => void,
  ) =>
  async (dispatch: any) => {
    dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });
    const { profileImageFile, bannerImageFile, bannerImageUrl, logoHeaderFile } = createdAccount;

    try {
      const response = await _addEmployee(
        { ...createdAccount, unit: createdAccount.unit?.id },
        getAccessTokenSilently,
      );
      const result = response.data;

      if (result.isSuccess) {
        // GET id, username OF CREATED ACCOUNT
        const {
          data: { id, username, accountToUnits },
        } = result;

        // ADD ACCOUNT TO REDUX
        const { sendInvite, email } = createdAccount;
        const status = sendInvite ? ACCOUNT_STATUSES.INVITED : ACCOUNT_STATUSES.UNINVITED;
        createdAccount = {
          ...createdAccount,
          id,
          username,
          status,
          authEmail: email,
          links: [],
          files: [],
        };
        dispatch({
          type: dispatchTypes.BUSINESS.ADD_EMPLOYEE,
          employee: { ...createdAccount, units: [createdAccount.unit], accountToUnits },
        });

        if (profileImageFile)
          await dispatch(
            uploadImage(
              false,
              'add',
              id,
              username,
              profileImageFile,
              'profile',
              getAccessTokenSilently,
              false,
            ),
          );

        if (bannerImageFile)
          await dispatch(
            uploadImage(
              false,
              'add',
              id,
              username,
              bannerImageFile,
              'banner',
              getAccessTokenSilently,
              false,
            ),
          );

        if (logoHeaderFile)
          await dispatch(
            uploadImage(
              false,
              'add',
              id,
              username,
              logoHeaderFile,
              'logoHeader',
              getAccessTokenSilently,
              false,
            ),
          );

        if (bannerImageUrl && !bannerImageFile)
          await dispatch(
            updateImage(false, 'add', id, bannerImageUrl, 'banner', getAccessTokenSilently, false),
          );

        onSuccess();
      } else {
        onFailed(result.message);
      }
    } catch (error) {
      onFailed(error?.response?.data?.error?.message || error.message);
    }

    dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });
  };

export const sendEmail =
  (id: string, email: string, getAccessTokenSilently, t: TFunction, onSuccess: () => void) =>
  async dispatch => {
    try {
      dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });

      let response = await _sendEmail(id, email, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.SENDEMAILSUCCESS,
          payload: { id, apiResponse: result },
        });

        dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });

        onSuccess();
      } else {
        dispatch({ type: dispatchTypes.BUSINESS.COMMON.SENDEMAILERROR, apiResponse: result });
        toast.error(t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.BUSINESS.COMMON.SENDEMAILERROR, apiResponse: error });
      toast.error(t('error.general'));
    }
  };

export const getThemeLinkTypes =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) => async dispatch => {
    try {
      let response = await fetchThemeLinkTypes(getAccessTokenSilently, true);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.BUSINESS.LINKS.SETLINKSSUCCESS,
          themeLinkTypes: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.BUSINESS.LINKS.SETLINKSERROR,
          apiResponse: result,
        });
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.BUSINESS.LINKS.SETLINKSERROR, apiResponse: error });
    }
  };

export const clearApiResponse = () => async dispatch => {
  dispatch({ type: dispatchTypes.BUSINESS.CLEARAPIRESPONSE });
};
