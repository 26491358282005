import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../application/hooks';

export default function useFreshworksIdentify() {
  const { isAuthenticated, user } = useAuth0();
  const account = useAppSelector(state => state.account);

  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      account &&
      Object.keys(account).length > 0 &&
      typeof window.FreshworksWidget === 'function'
    ) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: account.firstName + ' ' + account.lastName,
        email: user.email,
      });
    }
  }, [account, isAuthenticated, user]);
}
