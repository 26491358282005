import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

type Props = {
  header: string;
  subheader: string;
  img?: string;
  button?: string;
  linkClasses?: string;
  to?: string;
  external?: boolean;
  action?: Function;
  block?: boolean;
  customActionItem?: JSX.Element;
  disabled?: boolean;
};

const NavContainer = (props: Props) => {
  const history = useHistory();
  return (
    <Container $block={props.block}>
      <ContentContainer>
        <Header>{props.header}</Header>
        <Subheader>{props.subheader}</Subheader>
        {props.customActionItem ??
          (props.external ? (
            <AnchorButton
              href={props.to}
              target='_blank'
              $disabled={props.disabled}
              className={props.linkClasses}
              onClick={props.action}
            >
              {props.button} &rarr;
            </AnchorButton>
          ) : (
            <StyledButton
              onClick={props.to ? () => history.push(props.to) : props.action}
              $disabled={props.disabled}
              className={props.linkClasses}
            >
              {props.button} &rarr;
            </StyledButton>
          ))}
      </ContentContainer>
      <ImageContainer>{props.img && <img src={props.img} alt='' />}</ImageContainer>
    </Container>
  );
};

export default NavContainer;

const Container = styled.div`
  border-radius: 10px;
  padding: 3rem 2rem;
  background: #eff3fd;
  ${props =>
    !props.$block &&
    `
  width: 60rem;
  max-width: 90%;
  `}
  display: grid;
  grid-template-columns: 3fr 1fr;
  place-items: stretch;
  height: intrinsic;
`;
const ContentContainer = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;
const ImageContainer = styled.div`
  display: grid;
  place-items: center;
  img {
    width: 100%;
    height: auto;
    min-width: auto;
    max-width: 100%;
    object-fit: contain;
  }
`;
const Header = styled.div`
  font-size: 1.6rem;
  color: black;
  font-weight: 500;
`;
const Subheader = styled.div`
  font-size: 1.2rem;
  color: #666666;
  margin: 1rem 0;
`;
const AnchorButton = styled.a`
  outline: none;
  border: none;
  background: ${props => (props.$disabled ? '#797979' : '#4748ec')};
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 10px;
  margin: 0;
  margin-top: auto;

  &:hover,
  &:active,
  &:visited {
    outline: none;
    border: none;
    background: ${props => (props.$disabled ? '#797979' : '#4748ec')};
    color: white;
  }
`;
export const StyledButton = styled.button`
  outline: none;
  border: none;
  background: ${props => (props.$disabled ? '#797979' : '#4748ec')};
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 10px;
  margin: 0;
  margin-top: auto;

  &:hover,
  &:active,
  &:visited {
    outline: none;
    border: none;
    background: ${props => (props.$disabled ? '#797979' : '#4748ec')};
    color: white;
  }
`;
