import { dispatchTypes } from '../../index';
import {
  UpdateQrBgsProps,
  UpdateInternalDesignProps,
  _updateOrganisationName,
  _updateQrBgs,
  _updateInternalDesign,
  UpdateUnitSettingsProps,
  _updateUnitSettings,
  getImagesPath,
} from '../../../../infrastructure/apis/md/customization';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { isNotEmptyArray } from '../../../../infrastructure/helper';
import { TFunction } from 'i18next';

export const updateThemeInternal =
  (
    ids: { id: string; uniqueId: string },
    flags: { isNameChanged: boolean; isInternalDesignAllowed: boolean },
    niceName: string,
    internalDesign: UpdateInternalDesignProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess?: (message?: string) => void,
    onError?: (message?: string) => void,
  ) =>
  async dispatch => {
    const { isNameChanged, isInternalDesignAllowed } = flags;

    try {
      if (isNameChanged) {
        const response = await _updateOrganisationName(niceName, getAccessTokenSilently);
        const { isSuccess } = response.data;

        if (isSuccess) {
          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_FIELDS,
            payload: { niceName },
          });

          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_NAME_SUCCESS,
          });
        } else {
          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_NAME_ERROR,
          });

          onError?.('organisation.nameFailed');
        }
      }

      if (isInternalDesignAllowed) {
        const { id, uniqueId } = ids;
        const { logoWideUrl, logoWideBrightUrl } = internalDesign;

        internalDesign.logoWideUrl =
          logoWideUrl instanceof File
            ? await dispatch(updateThemeImage(id, uniqueId, logoWideUrl, getAccessTokenSilently))
            : logoWideUrl;

        internalDesign.logoWideBrightUrl =
          logoWideBrightUrl instanceof File
            ? await dispatch(
                updateThemeImage(id, uniqueId, logoWideBrightUrl, getAccessTokenSilently),
              )
            : logoWideBrightUrl;

        const response = await _updateInternalDesign(internalDesign, getAccessTokenSilently);
        const { isSuccess } = response.data;

        if (isSuccess) {
          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_FIELDS,
            payload: internalDesign,
          });

          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_DESIGN_SUCCESS,
          });
        } else {
          dispatch({
            type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_DESIGN_ERROR,
          });

          onError?.('organisation.internalDesignFailed');
        }
      }

      // both succeeded
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    }

    dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });
  };

const updateThemeImage =
  (
    accountId: string,
    themeId: string,
    file: File,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    try {
      const url = await doS3UploadForBusiness(
        `themes/${themeId}`,
        file,
        accountId,
        getAccessTokenSilently,
      );
      dispatch({ type: dispatchTypes.BUSINESS.THEME.UPLOAD_SUCCESS });
      if (url) return url;
    } catch (error) {
      dispatch({ type: dispatchTypes.BUSINESS.THEME.UPLOAD_ERROR });
    }
  };

export const updateQrBgs =
  (
    ids: { id: string; uniqueId: string },
    toUpdateQrBgs: UpdateQrBgsProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess: () => void,
    onFailed: () => void,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });

    const { id, uniqueId } = ids;
    const { defaultMobileBg, defaultVideoCallBg } = toUpdateQrBgs;
    const { additionalMobileBgs, additionalVideoCallBgs } = toUpdateQrBgs;

    try {
      let defaultMobileBgUrl: string | undefined;
      if (defaultMobileBg instanceof File) {
        defaultMobileBgUrl = await doS3UploadForBusiness(
          `themes/${uniqueId}`,
          defaultMobileBg,
          id,
          getAccessTokenSilently,
        );
      } else defaultMobileBgUrl = defaultMobileBg;

      let defaultVideoCallBgUrl: string | undefined;
      if (defaultVideoCallBg instanceof File) {
        defaultVideoCallBgUrl = await doS3UploadForBusiness(
          `themes/${uniqueId}`,
          defaultVideoCallBg,
          id,
          getAccessTokenSilently,
        );
      } else defaultVideoCallBgUrl = defaultVideoCallBg;

      let additionalMobileBgsPaths: string[] = [];
      if (isNotEmptyArray(additionalMobileBgs)) {
        additionalMobileBgsPaths = await getImagesPath(
          additionalMobileBgs,
          uniqueId,
          id,
          getAccessTokenSilently,
        );
      }

      let additionalVideoCallBgsPaths: string[] = [];
      if (isNotEmptyArray(additionalVideoCallBgs)) {
        additionalVideoCallBgsPaths = await getImagesPath(
          additionalVideoCallBgs,
          uniqueId,
          id,
          getAccessTokenSilently,
        );
      }

      const toUpdateQrBgs = {
        mobile: {
          default: defaultMobileBgUrl,
          additional: additionalMobileBgsPaths,
        },
        videocall: {
          default: defaultVideoCallBgUrl,
          additional: additionalVideoCallBgsPaths,
        },
      };

      let response = await _updateQrBgs(toUpdateQrBgs, getAccessTokenSilently);
      const { isSuccess } = response.data;

      if (isSuccess) onSuccess();
      else onFailed();
    } catch (error) {
      onFailed();
    }

    dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });
  };

export const updateUnitSettings =
  (
    unitSettings: UpdateUnitSettingsProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    t: TFunction,
    onSuccess?: (message?: string) => void,
    onError?: (message?: string) => void,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });

    try {
      const response = await _updateUnitSettings(unitSettings, getAccessTokenSilently);

      dispatch({
        type: dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_FIELDS,
        payload: response.data,
      });

      onSuccess?.();
    } catch (error) {
      if (error?.response?.data?.error?.code === 'stillMultiAssigned') {
        onError?.(t('stillMultiAssigned'));
      } else if (error?.message) {
        onError?.(error.message);
      } else {
        onError?.(t('error.general'));
      }
    }

    dispatch({ type: dispatchTypes.BUSINESS.HIDELOADER });
  };

export {};
