import { CSS_VARS } from '@/infrastructure/constants';
import { lightenHex } from '@/infrastructure/helper';
import React from 'react';
import styled from 'styled-components';

interface TableWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  hasPagination?: boolean;
  hasBulkActions?: boolean;
  scaleIcons?: boolean;
}

export const TableWrapper: React.FC<TableWrapperProps> = ({
  children,
  hasPagination = false,
  hasBulkActions = false,
  scaleIcons = true,
  ...rest
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const elem = divRef.current;
    const screenHeight = window.innerHeight;
    const elemFromTop = elem?.getBoundingClientRect().top;
    const elemHeight = screenHeight - elemFromTop;
    elem.style.height = `calc(${elemHeight}px - 1rem)`;
  }, []);

  return (
    <StyledWrapper
      {...rest}
      hasPagination={hasPagination}
      hasBulkActions={hasBulkActions}
      scaleIcons={scaleIcons}
      ref={divRef}
    >
      {children}
    </StyledWrapper>
  );
};

export const StyledWrapper = styled.div<{
  hasPagination: boolean;
  hasBulkActions: boolean;
  scaleIcons: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100% !important;
  min-height: 60vh;

  padding: 0 3rem;
  padding-top: ${props => (props.hasBulkActions ? '1rem' : '3rem')};
  padding-bottom: ${props => (props.hasPagination ? '1rem' : '3rem')};
  border-radius: 2rem;
  box-shadow: 0px 10px 40px 5px rgba(23, 3, 89, 0.1);

  .table-container {
    flex: 1;
    overflow: auto;
  }

  .nowrap {
    white-space: nowrap;
  }

  thead {
    white-space: nowrap;
  }

  /* MATERIAL-UI DEFAULT STYLES OVERWRITE */

  .MuiTableHead-root {
    height: 5rem;
  }

  .MuiTableRow-root {
    &:nth-of-type(even) {
      background-color: #f6f8ff;
    }
  }

  .MuiTableContainer-root {
    max-width: 100%;
    border-radius: 1rem !important;
    table-layout: fixed !important;
  }

  .MuiTableCell-root {
    border: none !important;
    font-family: inherit !important;
    font-size: 80% !important;
    padding: 0.8rem;
  }

  .MuiTableCell-head {
    background-color: #eff3fd !important;
    font-size: medium !important;
    font-weight: 600 !important;
  }

  .MuiTablePagination-root {
    margin-top: 1rem;
    width: 100% !important;
    display: grid;

    .MuiToolbar-root {
      min-height: auto;
      justify-self: end;
      align-items: center !important;

      p {
        font-size: small !important;
        font-weight: 500;
        margin: unset;
      }
    }

    .MuiSelect {
      &-select {
        font-size: small !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        line-height: 2rem !important;
      }

      &-icon {
        top: 0px !important;
        font-size: 2rem !important;
      }
    }
  }

  ${props =>
    props.scaleIcons &&
    `
    .MuiSvgIcon-root {
      transform: scale(1.4) !important;
    }
  `}
`;

export const TableHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.2rem;
  min-height: 3rem;
`;

export const MdConnectionsTableHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.2rem;
  min-height: 3rem;
`;

export const BulkButton = styled.button`
  border: none;
  border-radius: 0.5rem;
  background-color: ${(p: { redColor: boolean; color: string }) =>
    p.redColor ? '#fdcad4' : lightenHex(p.color, 10)};
  color: ${(p: { redColor: boolean }) =>
    p.redColor ? '#ef0000' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  font-size: 1rem;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  svg {
    &:first-child {
      margin-right: 0.4rem;
    }

    &:last-child {
      margin-left: 0.4rem;
    }
  }

  span {
    font-weight: 600;
  }
`;
