import { useTranslation } from 'react-i18next';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import nl2br from 'react-nl2br';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ArrowForward } from '@mui/icons-material';
import { Box, ThemeProvider } from '@mui/system';
import { flexCenterProps } from '@/util';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';

interface Props {
  text: string;
  imageSrc?: string;
  fullWidth?: boolean;
}

export default function FeatureTeaser(props: Props) {
  const { isTrial } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();

  const { t } = useTranslation();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  return (
    <>
      {upgradeRequestPopup}
      <ThemeProvider theme={muiTheme}>
        <Box mx={props.fullWidth ? 0 : 4} py={2}>
          <Alert
            sx={{
              '.MuiAlert-message': {
                flex: 1,
              },
            }}
            severity={'info'}
            variant='outlined'
            action={
              <Button
                variant='text'
                onClick={upgradeAction}
                endIcon={<ArrowForward />}
                size='small'
              >
                {isTrial
                  ? t('upgradeTeaser.enableForTrial')
                  : flag_hidePrices
                  ? t('contactSales')
                  : t('upgradeNow')}
              </Button>
            }
          >
            <AlertTitle>
              <Typography>{t('upgradeTeaser.upgradeYourPlan')}</Typography>
            </AlertTitle>

            <Typography variant='body2'>{nl2br(props.text)}</Typography>
          </Alert>
          {props.imageSrc && (
            <Box m={5} {...flexCenterProps}>
              <Box
                component='img'
                src={props.imageSrc}
                alt=''
                maxWidth='80%'
                sx={{ objectFit: 'contain' }}
              />
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
