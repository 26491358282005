import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { TapInfoType } from './Constant';
import { Link } from 'react-router-dom';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import { ChevIcon } from '../../../components/generic/LTCard';
import { FiChevronRight } from 'react-icons/fi';
import useWindowSize from '../../../../infrastructure/hooks/useWindowSize';

interface IProps {
  value: string;
  type?: 'primary' | 'secondary';
}

interface BoxProps {
  items: TapInfoType[];
}

export const Heading = ({ value, type = 'primary' }: IProps) => {
  return <StyledHeading type={type}>{value}</StyledHeading>;
};

export const SubHeading = ({ value, type = 'primary' }: IProps) => {
  return <StyledSubHeading type={type}>{value}</StyledSubHeading>;
};

export const TapBoxes = ({ items }: BoxProps) => {
  const { t } = useAppTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const boxEl = React.useRef(null);
  const [boxWidth, setBoxWidth] = React.useState(0);

  useEffect(() => {
    if (boxEl.current) {
      setBoxWidth(boxEl.current.clientWidth);
    }
  }, [boxWidth]);
  useWindowSize(useCallback(() => setBoxWidth(boxEl.current.clientWidth), []));
  return (
    <Wrapper className='lt-usetiful--how-to-tap-boxes'>
      <StyledIframeContainer>
        <iframe
          width='100%'
          src='https://www.youtube.com/embed/5GNDLiohc0E'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </StyledIframeContainer>
      {items.map(item => (
        <Box to={`/how-to-tap/${item.slug}`} key={item.id} ref={boxEl}>
          <ChevIcon to={'#'} $boxWidth={boxWidth} $wFactor={0.5}>
            <FiChevronRight />
          </ChevIcon>
          <Left>
            <Heading type='secondary' value={t(item.heading)} />
            <SubHeading type='secondary' value={t(item.subHeading)} />
          </Left>
          <Right>
            <Image src={item.image} />
          </Right>
        </Box>
      ))}
    </Wrapper>
  );
};

const StyledIframeContainer = styled.div`
  width: 100%;
  padding: 56.25% 0 0 0;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: ${props => (props.type === 'primary' ? '20px' : '14px')};
  color: #181a5a;
  margin-bottom: ${props => (props.type === 'primary' ? '1rem' : '0.5rem')};
`;

const StyledSubHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: rgb(151, 151, 151);
  margin-bottom: ${props => (props.type === 'primary' ? '1rem' : '0.5rem')};
`;

const Box = styled(Link)`
  position: relative;
  background: #eff3fd;
  border-radius: 15px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  justify-content: space-between;
  padding-top: 8px;

  @media (max-width: 768px) {
    padding: 0px 10px;
    padding-top: 8px;
  }
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  vertical-align: bottom;
  object-fit: contain;
  padding: 10px 0;
`;

const Left = styled.section`
  margin-top: 30px;
`;

const Wrapper = styled.div`
  margin-top: 25px;
`;

export const BackIconWrapper = styled.img`
  cursor: pointer;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Right = styled.section`
  vertical-align: bottom;
  margin-right: 20px;
`;
