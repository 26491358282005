import { Box, Card, CardActionArea, Typography } from '@mui/material';

type Props = {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
};

export const MobileWidget = ({ icon, onClick, text }: Props) => {
  return (
    <Card variant='elevation' sx={{ bgcolor: 'primary.main' }}>
      <CardActionArea sx={{ height: '100%' }} onClick={onClick}>
        <Box
          sx={{
            color: 'primary.contrastText',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 2,
            px: 0.4,
            pb: 0.8,
            svg: {
              width: '2rem',
              height: '2rem',
            },
            height: '100%',
          }}
        >
          {icon}
          <Typography mt={1} variant='h5' textAlign='center' sx={{ color: 'primary.contrastText' }}>
            {text}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};
