import { TFunction } from 'i18next';
import React, { ReactNode, useEffect, useRef } from 'react';

import addIcon from '../../../images/add-plus.svg';
import { Footer, FooterLink, SubText } from '../../generic';
import {
  BGImage,
  BGWrapper,
  ButtonWrapper,
  DownloadButton,
  ImgsSlider,
  SampleImg,
  SecondaryHeader,
  SectionWrapper,
  SliderContainer,
  UploadBox,
  UploadImage,
  UploadImageContainer,
  UploadText,
  Wrapper,
} from '../common/styles';
import { QRBGConfig } from '@/shared/types/global';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { withKeyEventHandler } from '@/utils/helpers';

interface Props {
  t: TFunction;
  QRCodeNavComponent: ReactNode;
  QRCodePositionComponent: ReactNode;
  states: {
    BGPreviewImgsVC: string[];
    QRBgImgVC: string;
    QRConfig: QRBGConfig;
    downloadReadyVC: boolean;
    windowWidth: number;
    allowOwnQrBgImage: boolean;
    sliderImagesNumber: number;
  };
  getQrCodeImg: (upload?: boolean, img?: string) => Promise<void>;
  handleOnImgClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUploadImage: (ref: React.RefObject<HTMLInputElement>) => void;
  handleUpload: (target: HTMLInputElement) => Promise<string>;
  handleDownloadImg: (qrBgImgDownload: string, analyticQuery?: string) => void;
  setQrCodeImgSize: (size: number, cb?: Function) => void;
}

function QRCodeBgVideocall(props: Props) {
  const {
    t,
    QRCodeNavComponent,
    QRCodePositionComponent,
    states,
    getQrCodeImg,
    handleOnImgClick,
    handleUploadImage,
    handleUpload,
    handleDownloadImg,
    setQrCodeImgSize,
  } = props;

  const {
    BGPreviewImgsVC,
    QRBgImgVC,
    QRConfig,
    downloadReadyVC,
    windowWidth,
    allowOwnQrBgImage,
    sliderImagesNumber,
  } = states;

  const { theme } = useMuiTheme();

  let imgUploadElVC = useRef(null);

  useEffect(() => setQrCodeImgSize(0), [setQrCodeImgSize]);

  useEffect(() => {
    let defaultBGImg = new Image();
    defaultBGImg.crossOrigin = 'Anonymous';
    defaultBGImg.src = QRBgImgVC;
    let factor = 0;

    defaultBGImg.onload = () => {
      factor = defaultBGImg.height;
      setQrCodeImgSize(factor * QRConfig?.videocall?.size, async (size: number) => {
        await getQrCodeImg(false, QRBgImgVC);
      });
    };
  }, [QRBgImgVC, setQrCodeImgSize, getQrCodeImg, QRConfig?.videocall?.size]);

  return (
    <>
      <SubText text={t('videocallTxt')} />
      <Wrapper>
        {QRCodeNavComponent}
        {QRCodePositionComponent}
        <SectionWrapper>
          <SecondaryHeader text={'3. ' + t('bgImageTitles.chooseImg')} />
          <SliderContainer>
            {!!allowOwnQrBgImage && (
              <UploadBox>
                <UploadImageContainer
                  tabIndex={0}
                  focusColor={theme.palette.primaryButton.main}
                  onClick={() => handleUploadImage(imgUploadElVC)}
                  onKeyUp={withKeyEventHandler(() => handleUploadImage(imgUploadElVC))}
                >
                  <UploadImage src={addIcon} alt='add-plus-icon' />
                </UploadImageContainer>
                <UploadText $smallScreen={windowWidth <= 443}>{t('imageUpload')}</UploadText>
              </UploadBox>
            )}
            <ImgsSlider
              centerMode
              showThumbs={false}
              centerSlidePercentage={sliderImagesNumber}
              emulateTouch
              onClickItem={handleOnImgClick}
              showIndicators={false}
              statusFormatter={() => {
                return false;
              }}
            >
              {BGPreviewImgsVC.map((img, idx) => (
                <SampleImg key={idx} src={img} alt={`sample img ${idx}`} />
              ))}
            </ImgsSlider>
          </SliderContainer>
        </SectionWrapper>
        <input
          type='file'
          onChange={e => handleUpload(e.target)}
          style={{ display: 'none' }}
          ref={imgUploadElVC}
          accept='image/*'
          onClick={e => {
            (e.target as HTMLInputElement).value = null;
          }}
        />
        <SectionWrapper>
          <SecondaryHeader text={'4. ' + t('bgImageTitles.downloadImg')} />
          <BGWrapper $videocall>
            <BGImage src={QRBgImgVC} alt='default bg' />
          </BGWrapper>
          <ButtonWrapper>
            <DownloadButton
              to='#'
              onClick={() => handleDownloadImg(QRBgImgVC, 'lt_source=qr-video-bg')}
              $downloadready={downloadReadyVC}
              primaryColor={theme.palette.primaryButton.main}
            >
              {t('download')}
            </DownloadButton>
          </ButtonWrapper>
        </SectionWrapper>
        <Footer>
          <FooterLink href='https://omr.com/de/videokonferenz-hintergrund-aendern/' target='_blank'>
            {t('howToUseBg')}
          </FooterLink>
        </Footer>
      </Wrapper>
    </>
  );
}

export default QRCodeBgVideocall;
