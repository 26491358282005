import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Alert } from '@mui/material';
import { ThemeProvider } from '@mui/system';

interface Props {
  ttext: string;
  tobj?: Record<string, string>;
}

export default function PrivateAlert({ ttext, tobj }: Props) {
  const { theme } = useMuiTheme();
  const { t } = useAppTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Alert variant='outlined' severity='info' sx={{ m: 1 }}>
        {t(ttext, { ...(tobj || {}) })}
      </Alert>
    </ThemeProvider>
  );
}
