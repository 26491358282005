import React, { Dispatch, SetStateAction } from 'react';
import SettingsHeader from '../../../settings/header';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TooltipInfo from '../../../../components/generic/TooltipInfo';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

export type PageType = 'account' | 'profile' | 'Card';

interface Props {
  currentTabPage: PageType;
  setCurrentTabPage: Dispatch<SetStateAction<PageType>>;
  onBackClick: () => void;
}

const RightBarTabPage = (props: Props) => {
  const { t } = useTranslation();
  const { theme: muiTheme } = useMuiTheme();
  const TabPageContents = () => {
    return (
      <RowHeader>
        <TooltipInfo
          text={t('md.profiles.sidebar.tabs.tooltipProfile')}
          onHover={true}
          placement='bottom'
        >
          <TabPageHeader
            isCurrent={props.currentTabPage === 'profile'}
            onClick={() => props.setCurrentTabPage('profile')}
            color={muiTheme.palette.text.secondary}
          >
            {t('profile')}
          </TabPageHeader>
        </TooltipInfo>
        <TooltipInfo
          text={t('md.profiles.sidebar.tabs.tooltipAccount')}
          onHover={true}
          placement='bottom'
        >
          <TabPageHeader
            isCurrent={props.currentTabPage === 'account'}
            onClick={() => props.setCurrentTabPage('account')}
            color={muiTheme.palette.text.secondary}
          >
            Account
          </TabPageHeader>
        </TooltipInfo>
        <TooltipInfo
          text={t('md.profiles.sidebar.tabs.tooltipCards')}
          onHover={true}
          placement='bottom'
        >
          <TabPageHeader
            isCurrent={props.currentTabPage === 'Card'}
            onClick={() => props.setCurrentTabPage('Card')}
            color={muiTheme.palette.text.secondary}
          >
            {t('cards.cards')}
          </TabPageHeader>
        </TooltipInfo>
      </RowHeader>
    );
  };

  return (
    <>
      <SettingsHeader
        headerText={t('editProfile')}
        onBackClick={props.onBackClick}
        contents={<TabPageContents />}
      />
    </>
  );
};

export default RightBarTabPage;

const RowHeader = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  @media (max-width: 500px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const TabPageHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${props => (props.isCurrent ? props.color : '#b2b8c3')};
  padding-left: 15px;
  padding-bottom: 10px;
  margin-right: 15px;
  padding-right: 15px;
  cursor: pointer;
  text-transform: capitalize;
  border-bottom: ${props =>
    props.isCurrent ? `2px solid ${props.color}` : '2px solid transparent'};
  margin-bottom: -2px;

  @media (max-width: 500px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
