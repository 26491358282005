import React from 'react';
import { Button } from '../../components/common';
import PublicContainer from '../../components/common/containers/public.container';
import { routePaths } from '../../../infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <PublicContainer pageHeading={t('pageNotFound')} subHeading={{ stringText: t('weareSorry') }}>
        <Link to={routePaths.HOME}>
          <Button text={t('backHome')} />
        </Link>
      </PublicContainer>
    </>
  );
};

export default PageNotFound;
