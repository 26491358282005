import { getConnectors } from '@/infrastructure/apis/md/crmIntegration';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const useConnectorsFetch = () => {
  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');
  const [connectors, setConnectors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!flag_crmIntegration) return;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await getConnectors(getAccessTokenSilently);
        setConnectors(data || []);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [flag_crmIntegration, getAccessTokenSilently]);

  return { connectors, isLoading };
};
