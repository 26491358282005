import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import config from '../../../config/config';
import { withSilentAccessToken } from '../../helper';
import { PERMISSIONS } from '../../constants';

export const getUnits = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/units`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_UNIT],
  );
};

export const deleteUnits = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  unitIds: Array<number>,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + `theme/units/delete`,
        {
          ids: unitIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.DELETE.THEME_UNIT],
  );
};

export const assignUnits = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  accountIds: readonly string[],
  mainUnitId: number,
  otherUnitIds: number[],
) => {
  return withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + `theme/units/assignAccounts`,
        {
          accounts: accountIds,
          mainUnitId,
          otherUnitIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
};

//TODO: check if we need to restore the unassign
export const unassignUnits = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  accountIds: readonly string[],
  unitIds: number[],
) => {
  return withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + `theme/units/unAssignAccounts`,
        {
          accounts: accountIds,
          unitIds: unitIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
};
