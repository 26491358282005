// just call once from App.tsx

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useAppSelector } from '../../application/hooks';
import i18n from '../../config/i18n';
import { updatePreferredLanguage } from '../apis/edit-profile';

// will backup preferred language to backend
export default function useLanguageSave() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const account = useAppSelector(state => state.account);
  const isDbLoading = useAppSelector(state => state.login.isLoading);

  useEffect(() => {
    if (isAuthenticated && account && Object.keys(account).length > 0 && !account.preferredLang) {
      // set language once upon account creation (or after introduction of this code snippet)
      updatePreferredLanguage(i18n.language, getAccessTokenSilently);
    } else if (
      isAuthenticated &&
      account &&
      Object.keys(account).length > 0 &&
      account.preferredLang !== i18n.language &&
      isDbLoading
    ) {
      // set language when logging in and having lang specified in be
      i18n.changeLanguage(account.preferredLang?.substring(0, 2));
    }
  }, [account, getAccessTokenSilently, isAuthenticated, isDbLoading]);

  // update lang in be when selection changed
  useEffect(() => {
    if (isAuthenticated) {
      const listener = (lang: string) =>
        ['en', 'de'].includes(lang) ? updatePreferredLanguage(lang, getAccessTokenSilently) : null; // dont care about response
      i18n.on('languageChanged', listener);
      return () => i18n.off('languageChanged', listener);
    }
  }, [isAuthenticated, getAccessTokenSilently]);
}
