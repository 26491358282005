import { dispatchTypes } from '../../../actions';
import { ACCOUNT_STATUSES } from '../../../../shared/constants';

let initialState = {
  isLoading: false,
  apiResponse: null,
  employees: [],
  // unchanged with filters
  totalNumEmployees: null,
  themeLinkTypes: [],
  commonLinks: [],
  commonFiles: [],
};

const mdReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.BUSINESS.SHOWLOADER:
      return { ...state, isLoading: true };
    case dispatchTypes.BUSINESS.HIDELOADER:
      return { ...state, isLoading: false };
    case dispatchTypes.BUSINESS.CLEARAPIRESPONSE:
      return { ...state, isLoading: false, apiResponse: null };

    // INIT
    case dispatchTypes.BUSINESS.FETCHEMPLOYEES:
      return {
        ...state,
        employees: action.employees,
        totalNumEmployees: state.totalNumEmployees ?? action.employees.length,
      };
    case dispatchTypes.BUSINESS.LINKS.SETLINKSSUCCESS:
      return { ...state, themeLinkTypes: action.themeLinkTypes };
    case dispatchTypes.BUSINESS.LINKS.SETLINKSERROR:
      return { ...state, apiResponse: action.apiResponse };

    // ADD
    case dispatchTypes.BUSINESS.ADD_EMPLOYEE:
      return {
        ...state,
        employees: [action.employee, ...state.employees],
        totalNumEmployees: state.totalNumEmployees++,
      };

    // REMOVE ALL LOADERS, REPLACE WITH GENERIC ONE
    case dispatchTypes.BUSINESS.LINKS.SHOWLOADER:
      return { ...state, isLoading: true };
    case dispatchTypes.BUSINESS.LINKS.HIDELOADER:
      return { ...state, isLoading: false };
    case dispatchTypes.BUSINESS.FILES.SHOWLOADER:
      return { ...state, isLoading: true };
    case dispatchTypes.BUSINESS.FILES.HIDELOADER:
      return { ...state, isLoading: false };

    // EMAIL
    case dispatchTypes.BUSINESS.COMMON.SENDEMAILERROR:
      return { ...state, isLoading: false, apiResponse: action.apiResponse };
    case dispatchTypes.BUSINESS.COMMON.SENDEMAILSUCCESS:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.payload.id) {
            e.status = ACCOUNT_STATUSES.INVITED;
          }

          return e;
        }),
        apiResponse: action.payload.apiResponse,
      };

    // IMAGES
    case dispatchTypes.EDITEMPLOYEE.UPDATEIMAGE:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.payload.id) {
            if (action.payload.type === 'profile') {
              e.profileImageUrl = action.payload.imgUrl;
            }
            if (action.payload.type === 'banner') {
              e.bannerImageUrl = action.payload.imgUrl;
            }
            if (action.payload.type === 'logoHeader') {
              e.logoHeaderUrl = action.payload.imgUrl;
            }
          }

          return e;
        }),
      };
    case dispatchTypes.ADDEMPLOYEE.UPLOADIMAGE:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.payload.id) {
            if (action.payload.type === 'profile') e.profileImageUrl = action.payload.imgUrl;
            if (action.payload.type === 'banner') e.bannerImageUrl = action.payload.imgUrl;
            if (action.payload.type === 'logoHeader') e.logoHeaderUrl = action.payload.imgUrl;
          }

          return e;
        }),
      };

    // FILES
    case dispatchTypes.BUSINESS.COMMON.ADDALLFILES:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.payload.employeeId) e.files = action.payload.files;
          return e;
        }),
      };
    case dispatchTypes.BUSINESS.COMMON.UPDATEFILE:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.payload.employeeId) e.files = action.payload.res.data;

          return e;
        }),
      };

    // ??
    case dispatchTypes.BUSINESS.COMMON.UPDATEFIELD:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.id) {
            e[action.field] = action.value;
          }

          return e;
        }),
      };
    case dispatchTypes.BUSINESS.COMMON.UPDATEFIELDS:
      return {
        ...state,
        employees: state.employees.map(e => {
          if (e.id === action.id) {
            for (let i = 0; i < action.fields.length; i++) {
              e[action.fields[i]] = action.values[i];
            }
          }
          return e;
        }),
      };

    // BULK
    case dispatchTypes.BUSINESS.BULK.UPDATE_SUCCESS:
      return { ...state, apiResponse: action.apiResponse };
    case dispatchTypes.BUSINESS.BULK.UPDATE_ERROR:
      return { ...state, apiResponse: action.apiResponse };

    case dispatchTypes.BUSINESS.THEME.UPDATE_SUCCESS:
      return { ...state, isLoading: false };
    case dispatchTypes.BUSINESS.THEME.UPDATE_ERROR:
      return { ...state, isLoading: false };
    case dispatchTypes.BUSINESS.BULK.SET_COMMON_LINKS:
      return { ...state, commonLinks: action.commonLinks };
    case dispatchTypes.BUSINESS.BULK.SET_COMMON_FILES:
      return { ...state, commonFiles: action.commonFiles };

    default:
      return state;
  }
};

export default mdReducer;
