import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from '../../../../../../../shared/types/api';
import { DropResult } from 'react-beautiful-dnd';
import { CustomLinkType } from './useBulk';

const useReorder = (
  links: Link[],
  setUserLinks?: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>,
  onDragEnd?: (links: Link[]) => void,
) => {
  const [orderedLinks, setOrderedLinks] = useState(links);

  useEffect(() => setOrderedLinks(links), [links]);

  const reorder = (links: Link[], startIndex: number, endIndex: number) => {
    const [removed] = links.splice(startIndex, 1);
    links.splice(endIndex, 0, removed);
    return links.map((link: Link, index: number) => ({
      ...link,
      order: index * 10,
    }));
  };

  const onDragEndLinks = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    const newOrder = reorder(links, source.index, destination.index);
    setOrderedLinks(newOrder);
    setUserLinks && setUserLinks(newOrder);
    onDragEnd && onDragEnd(newOrder);
  };

  return { orderedLinks, onDragEndLinks };
};

export default useReorder;
