import { debounce } from '@/utils/debounce';
import { TextField, TextFieldProps } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

type Props = TextFieldProps & {
  delay: number;
  value: string;
  onChange: (value: string) => void;
};

const DebouncedTextField = ({ delay, onChange, value, ...rest }: Props) => {
  const [localValue, setLocalValue] = useState('');

  const onChangeDebounced = useMemo(() => debounce(onChange, delay), [delay, onChange]);

  const handleChange = (newValue: string) => {
    setLocalValue(newValue);
    onChangeDebounced(newValue);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <TextField
      {...rest}
      value={localValue}
      onChange={({ target: { value } }) => handleChange(value)}
    />
  );
};

export default DebouncedTextField;
