import config from '../../../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../../../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuth0TokenScopeString } from '@/util';
import { isNotEmptyArray, withSilentAccessToken } from '@/infrastructure/helper';
import { ProfileDesignConfig } from '@/shared/types/api';
import { ProfileDesignConfigLocal } from '@/views/pages/md/customization/profile-design';
import { doS3UploadForBusiness } from '../../aws';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { writeThemeConfig } from './jsonConfigs';

export interface UpdateProfileDesignProps {
  defaultBannerImageUrl: string;
  defaultProfileImageUrl: string;
  logoHeaderUrl: string;
}

export interface UpdateEditRightsProps {
  detailsEditable: boolean;
  linksEditable: boolean;
  filesEditable: boolean;
}

export interface UpdateInternalDesignProps {
  logoWideUrl: string | File;
  logoWideBrightUrl: string | File;
  logoWideInvert: boolean;
  primaryColor: string;
  sidebarColor: string;
  sidebarColorText: string;
  sidebarColorActive: string;
  sidebarColorActiveText: string;
  chatColor: string;
}

export interface UpdateUnitSettingsProps {
  multiUnitsAllowed: boolean;
}

export interface UpdateQrBgsProps {
  defaultMobileBg: File | string | undefined;
  defaultVideoCallBg: File | string | undefined;
  additionalMobileBgs: String[] | [];
  additionalVideoCallBgs: String[] | [];
}

export const _updateProfileDesign = async (
  toUpdateDesign: ProfileDesignConfig,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    config.API_BASE_URL + 'theme/updateProfileDesign',
    toUpdateDesign,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response;
};

export const _updateOrganisationName = async (
  niceName: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const response = await axios.post(
    config.API_BASE_URL + 'business/organisation',
    { niceName },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response;
};

export const _updateInternalDesign = async (
  internalDesign: UpdateInternalDesignProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const response = await axios.post(config.API_BASE_URL + 'theme/internal-design', internalDesign, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _updateQrBgs = async (
  toUpdateQrBgs,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const response = await axios.post(config.API_BASE_URL + 'theme/update-qr-bgs', toUpdateQrBgs, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const updateEditRights = async (
  payload: { editRights: UpdateEditRightsProps; unitId: number | null },
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(config.API_BASE_URL + 'theme/updateEditRights', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
};

export const _updateUnitSettings = async (
  unitSettings: UpdateUnitSettingsProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_GLOBAL),
  });

  const response = await axios.put(config.API_BASE_URL + 'theme/units/settings', unitSettings, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const getImagesPath = (images, uniqueId, id, getAccessTokenSilently): Promise<string[]> => {
  const promises = images.map(content =>
    content instanceof File
      ? doS3UploadForBusiness(`themes/${uniqueId}`, content, id, getAccessTokenSilently)
      : Promise.resolve(content),
  );
  return Promise.all(promises);
};

export const updateProfileDesign = async (
  ids: { id: string; uniqueId: string },
  toUpdateDesign: ProfileDesignConfigLocal,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  unitId?: number | null,
) => {
  const { id, uniqueId } = ids;
  let {
    defaultBannerImageUrl,
    defaultProfileImageUrl,
    logoHeaderUrl,
    privacyUrl,
    imprintUrl,
    legalName,
    showCopyright,
    leadGenFormShown,
    bannerImgOptions,
    logoImgOptions,
  } = toUpdateDesign;

  const imgUploader = (file: File) =>
    doS3UploadForBusiness(`themes/${uniqueId}`, file, id, getAccessTokenSilently);

  let bannerImageUrl =
    defaultBannerImageUrl instanceof File && (await imgUploader(defaultBannerImageUrl));

  let profileImageUrl =
    defaultProfileImageUrl instanceof File && (await imgUploader(defaultProfileImageUrl));

  let logoHeaderImageUrl = logoHeaderUrl instanceof File && (await imgUploader(logoHeaderUrl));

  let successImageOptions = true;

  let bannerImgOptionsPaths: string[] = [];
  if (isNotEmptyArray(bannerImgOptions)) {
    bannerImgOptionsPaths = await getImagesPath(
      bannerImgOptions,
      uniqueId,
      id,
      getAccessTokenSilently,
    );
  }

  let logoImgOptionsPaths: string[] = [];
  if (isNotEmptyArray(logoImgOptions)) {
    logoImgOptionsPaths = await getImagesPath(logoImgOptions, uniqueId, id, getAccessTokenSilently);
  }

  if (
    (defaultBannerImageUrl instanceof File && !bannerImageUrl) ||
    (defaultProfileImageUrl instanceof File && !profileImageUrl) ||
    (logoHeaderUrl instanceof File && !logoHeaderImageUrl) ||
    !successImageOptions
  ) {
    throw new Error('Error uploading images');
  }

  if (bannerImageUrl) toUpdateDesign.defaultBannerImageUrl = bannerImageUrl;
  if (profileImageUrl) toUpdateDesign.defaultProfileImageUrl = profileImageUrl;
  if (logoHeaderImageUrl) toUpdateDesign.logoHeaderUrl = logoHeaderImageUrl;
  toUpdateDesign.bannerImgOptions = bannerImgOptionsPaths;
  toUpdateDesign.logoImgOptions = logoImgOptionsPaths;
  if (!defaultBannerImageUrl)
    toUpdateDesign.defaultBannerImageUrl =
      'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/banner.jpg';
  if (!defaultProfileImageUrl)
    toUpdateDesign.defaultProfileImageUrl =
      'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/profile.png';

  toUpdateDesign.privacyUrl = privacyUrl || null;
  toUpdateDesign.imprintUrl = imprintUrl || null;
  toUpdateDesign.legalName = legalName || null;
  toUpdateDesign.showCopyright = Boolean(showCopyright);
  toUpdateDesign.leadGenFormShown = Boolean(leadGenFormShown);

  await writeThemeConfig(getAccessTokenSilently, {
    key: THEME_CONFIG_KEYS.PROFILE_DESIGN,
    value: toUpdateDesign,
    unitId,
  });

  return toUpdateDesign as ProfileDesignConfig;
};
