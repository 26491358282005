import { IconButton, Typography, Theme } from '@mui/material';
import { Box } from '@mui/system';
import InsertDriveFileOutlineIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FileGenerationStatus } from '@/shared/types/global';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import moment from 'moment';

const getIconStyles = (status: FileGenerationStatus) => (theme: Theme) => {
  const defaultStyles = {
    mr: 1,
    p: 1,
    pointerEvents: 'none',
  };
  if (status === 'error') {
    return {
      ...defaultStyles,
      bgcolor: theme.palette.action.hover,
    };
  }
  return {
    ...defaultStyles,
    backgroundColor: theme.palette.action.disabledBackground,
    '&:hover': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  };
};

type Props = {
  title: string;
  status: FileGenerationStatus;
  createdOn: Date;
  action: React.ReactNode;
};

const FileRow = ({ title, status, createdOn, action }: Props) => {
  const { t } = useAppTranslation();

  const getStatus = () => {
    if (status === 'error')
      return (
        <Typography variant='body2' color='error.dark' ml={0.4}>
          {t('export.fileCard.status.failed')}
        </Typography>
      );
    if (status === 'pending')
      return (
        <Typography variant='body2' ml={0.4}>
          {t('export.fileCard.status.running')}
        </Typography>
      );
    if (status === 'success')
      return (
        <Typography variant='body2' color='success.main' ml={0.4}>
          {t('export.fileCard.status.finished')}
        </Typography>
      );
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignContent='center'
      gap={1.5}
      width={'100%'}
    >
      <Box display={'flex'}>
        <IconButton sx={getIconStyles(status)}>
          <InsertDriveFileOutlineIcon
            fontSize='medium'
            sx={theme => status === 'error' && { color: theme.palette.error.dark }}
          />
        </IconButton>
        <Box>
          <Typography variant='body1' {...(status === 'error' ? { color: 'error.dark' } : {})}>
            {title}
          </Typography>
          <Typography variant='body2' color='secondary' display={'inline-flex'}>
            {t('export.fileCard.date', { date: moment(createdOn).calendar() })}
            {' • '}
            {getStatus()}
          </Typography>
        </Box>
      </Box>
      {action}
    </Box>
  );
};

export default FileRow;
