import { Component, ErrorInfo, ReactNode } from 'react';
import DesignError from './views/components/common/ErrorDesign';
import { v4 as uuidv4 } from 'uuid';
interface ErrorBoundaryProps {
  children?: ReactNode;
}
interface ErrorBoundarystate {
  hasError: boolean;
  errorId: string;
  error: Error;
  errorInfo: ErrorInfo;
}

class GlobalErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundarystate> {
  state: ErrorBoundarystate = {
    hasError: false,
    errorId: '',
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      errorId: uuidv4(),
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <DesignError
            errorId={this.state.errorId}
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
        </>
      );
    }
    return this.props.children;
  }
}
export default GlobalErrorBoundary;
