import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../../components/common';
import { Spacer } from './common';
import { LinkFields } from './customDataLinks';
import styled from 'styled-components';
import { TextField } from '../../../components/generic';

interface Props {
  onBackClick: () => void;
  onCloseClick: () => SetStateAction<boolean>;
  link: LinkFields;
  setLink: Dispatch<SetStateAction<LinkFields>>;
  onSaveClick: (link: LinkFields, isEdit: boolean) => void;
}

const EditLinkComponent = (props: Props) => {
  const { t } = useTranslation();
  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };
  return (
    <Popup
      headerText={'Edit Link'}
      onBackClick={props.onBackClick}
      onCloseClick={props.onCloseClick}
    >
      <Container>
        <ImageContainer
          className='link-wrapper'
          style={{ background: props.link.themeLinkType.linkType.backgroundCss }}
        >
          <Image src={props.link.themeLinkType.linkType.iconUrl} alt='link icon' />
        </ImageContainer>

        <Spacer size={25} />
        <StyledText
          label={t('linkTab.name')}
          name={'linkTab'}
          value={props.link.name}
          angles='normal'
          type='text'
          border={borderProps}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.setLink({ ...props.link, name: e.target.value })
          }
          font={{ size: '18px', weight: '400' }}
          padding={{ left: '0' }}
          helperText={props.link.name === '' && t('fieldRequired')}
          error={props.link.name === ''}
          placeholder={t('linkTab.placeHolderLink')}
        />
        <Spacer size={25} />
        <StyledText
          label={
            props.link.themeLinkType.linkType.mode === 'username'
              ? t('username')
              : props.link.themeLinkType.linkType.mode === 'phone'
              ? t('linkTab.linkPhone')
              : t('linkTab.linkUrl')
          }
          name={'linkTab'}
          value={props.link.link}
          angles='normal'
          type='text'
          border={borderProps}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.setLink({ ...props.link, link: e.target.value })
          }
          font={{ size: '18px', weight: '400' }}
          padding={{ left: '0' }}
          prefixText={
            props.link.themeLinkType.linkType.mode === 'phone'
              ? '+'
              : props.link.themeLinkType.linkType.mode === 'username'
              ? '@'
              : undefined
          }
          placeholder={
            props.link.themeLinkType.linkType.mode === 'username'
              ? ''
              : props.link.themeLinkType.linkType.mode === 'phone'
              ? t('linkTab.placeHolderPhone')
              : t('linkTab.placeHolderUrl')
          }
          helperText={props.link.link === '' && t('fieldRequired')}
          error={props.link.link === ''}
        />
        <div>
          <Button
            text={t('save')}
            vMargin='3rem'
            onClick={() => props.onSaveClick(props.link, true)}
          />
        </div>
      </Container>
    </Popup>
  );
};

export default EditLinkComponent;

const StyledText = styled(TextField)`
  width: 200px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
const Image = styled.img`
  width: 25px;
  height: 25px;
`;
