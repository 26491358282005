export const REWARDS_KEYS = {
  VOUCHER10: 'voucher-10',
  VOUCHER30: 'voucher-30',
  VOUCHER40: 'voucher-40',
  VOUCHER50: 'voucher-50',
  LICENCE_ESSENTIALS: 'licence-essentials',
} as const;

export const REFERRAL_KEY_TYPES = {
  EMAIL: 'email',
  DIRECT: 'direct',
} as const;

export const CREDITS_REWARDS = [
  { key: REWARDS_KEYS.VOUCHER10, credit: 10 },
  { key: REWARDS_KEYS.LICENCE_ESSENTIALS, credit: 21 },
  { key: REWARDS_KEYS.VOUCHER30, credit: 30 },
  { key: REWARDS_KEYS.VOUCHER40, credit: 40 },
  { key: REWARDS_KEYS.VOUCHER50, credit: 50 },
] as const;

export const CREDIT_TRANSACTION_TYPE = {
  REFERRAL: 'referral',
  REFERRED_SIGNUP: 'referred-signup',
  LINKEDIN_TAG: 'linkedin-tag',
  REDEEM: 'redeem',
  DIRECT: 'direct',
} as const;

export const SIGNUP_CREDIT = {
  REFERRER: +2,
  RECIPIENT: +3,
};
