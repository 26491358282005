import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SubsectionSpan } from './common';
import { THEME_BOX_STYLES } from '../../../../shared/constants';

interface Props {
  boxForm: string;
  setBoxForm: (value: string) => void;
}

const BoxForm = (props: Props) => {
  const { t } = useTranslation();
  const { boxForm, setBoxForm } = props;

  return (
    <BoxFormContainer>
      <BoxFormOption
        onClick={() => setBoxForm(THEME_BOX_STYLES.ROUNDED)}
        active={boxForm === THEME_BOX_STYLES.ROUNDED}
        rounded
      >
        <SubsectionSpan>{t('rounded')}</SubsectionSpan>
      </BoxFormOption>
      <BoxFormOption
        onClick={() => setBoxForm(THEME_BOX_STYLES.ANGULAR)}
        active={boxForm === THEME_BOX_STYLES.ANGULAR}
      >
        <SubsectionSpan>{t('squared')}</SubsectionSpan>
      </BoxFormOption>
    </BoxFormContainer>
  );
};

export default BoxForm;

const BoxFormContainer = styled.div`
  display: flex;
  padding: 20px 15px 0px;
`;

const BoxFormOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8vw 2.5vw;
  border: 0.5px solid #444444;
  cursor: pointer;
  font-weight: normal;

  &:not(:last-child) {
    margin-right: 1.5vw;
  }

  ${(props: { rounded: boolean }) => props.rounded && `border-radius: 3rem;`}

  ${(props: { active: boolean }) => props.active && 'border-width: 2px; font-weight: 600'}
`;
