import styled from 'styled-components';

export const DivisionTitle = styled.div`
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 15px;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.isWrap && 'flex-wrap: wrap'};
  ${props => props.isVerticalCenter && 'align-items: center'};
  ${props => props.spaceBetween && 'justify-content: space-between'};
`;
