import { generateProfileLink, getProfileImageUrl } from '../business-logic/features/profile/getter';
import { EmailTemplatesEnum } from '../constants';
import {
  Account,
  EmailEditorFixedContent,
  EmailEditorFixedContentTypeEnum,
  ProfileDesignConfig,
} from '../types/api';
import { removeLeading } from './transforms';

type Props = {
  vcardUrl?: string;
  passwordResetUrl?: string;
  googleWalletUrlOnline?: string;
  googleWalletUrlOffline?: string;
};

const getTestHtmlStringParams = (
  account: Account,
  profileDesign: ProfileDesignConfig,
  props: Props,
): [RegExp, string | null | undefined][] => {
  const referralUrl = `${account.theme?.domain}/registration/signup`;
  const publicProfileUrl = generateProfileLink(account);
  // const vcardUrl = `${getParam('API_BASE_URL')}account/download/contact?accountId=${account.id}`;
  const leadUrl = `${account.theme?.domain}/contacts/123example/edit`;

  return [
    //contact-share-email-template
    [/\$NAME\$/g, `${account.firstName} ${account.lastName}`],
    [/\$POSITION\$/g, account.position || ''],
    [/\$COMPANY\$/g, account.company || ''],
    [/\$EMAIL\$/g, account.email || ''],
    [/\$PHONE_MOBILE\$/g, account.mobilePhone ? '+' + removeLeading(account.mobilePhone, '+') : ''],
    [/\$PHONE_WORK\$/g, account.workPhone ? '+' + removeLeading(account.workPhone, '+') : ''],
    [/\$ADDRESSEE\$/g, account.address1_addressee || ''],
    [/\$ADDRESS_LINE_1\$/g, account.address1_addressLine1 || ''],
    [/\$ADDRESS_LINE_2\$/g, account.address1_addressLine2 || ''],
    [/\$CITY\$/g, account.address1_city || ''],
    [/\$POSTCODE\$/g, account.address1_postCode || ''],
    [/\$COUNTRY\$/g, account.address1_country || ''],
    [/\$VCARD_LINK\$/g, props.vcardUrl],
    [/\$PROFILE_PIC_URL\$/g, getProfileImageUrl(account, profileDesign)],
    [/\$ACCOUNT_PUBLIC_URL\$/g, publicProfileUrl],

    //generic-status-email
    [/\$TEXT\$/g, 'Some Generic Text'],

    //contact-received-email-template
    [/\$RECEIVER_NAME\$/g, 'John Doe'],
    [/\$LEAD_NAME\$/g, account.firstName],
    [/\$LEAD_EMAIL\$/g, account.email],
    [/\$LEAD_URL\$/g, leadUrl],

    //welcome-email-template
    [/\$firstName\$/g, account.firstName],
    [/\$lastName\$/g, account.lastName],
    [/\$company\$/g, account.company],
    [/\$SET_PASSWORD_URL\$/g, props.passwordResetUrl || '#set-password-url'],
    [/\$LOGIN_URL\$/g, account.theme?.domain + '/login'],
    [
      /\$MAIN_ACCOUNT_EMAIL\$/g,
      account.theme?.themeInternal?.mainAccount?.email || '<main-account-email>',
    ],
    [/\$PASS_URL_ONLINE\$/g, props.googleWalletUrlOnline || '#'],
    [/\$PASS_URL_OFFLINE\$/g, props.googleWalletUrlOffline || '#'],

    //card-order-confirmation-email-template
    [/\$firstName\$/g, account.firstName],
    [/\$lastName\$/g, account.lastName],
    [/\$amountCards\$/g, '10'],

    //referral-email-template
    [/\$REFERRER_FIRSTNAME\$/g, account.firstName],
    [/\$REFERRER_LASTNAME\$/g, account.lastName],
    [/\$REFERRAL_URL\$/g, referralUrl],

    //referral-referrer-template
    [/\$REFERRER_FIRSTNAME\$/g, account.firstName],
    [/\$RECIPIENT_EMAIL\$/g, account.email],
    [/\$CREDIT\$/g, '100'],
    [/\$REFERRAL_URL\$/g, referralUrl],

    //referral-recipient-template
    [/\$RECIPIENT_FIRSTNAME\$/g, account.firstName],
    [/\$CREDIT\$/g, '100'],
    [/\$REFERRAL_URL\$/g, referralUrl],

    //wallet-pass-mail-template
    [/<%= account.firstName %>/g, account.firstName],
    [/&lt;%= account.firstName %&gt;/g, account.firstName],
    [/\$FIRST_NAME\$/g, account.firstName],
    [/\$LAST_NAME\$/g, account.lastName],
    [/<%= passUrl.online %>/g, '#'],
    [/&lt;%= passUrl.online %&gt;/g, '#'],
    [/\$PASS_URL_ONLINE\$/g, props.googleWalletUrlOnline || '#'],
    [/<%= passUrl.offline %>/g, '#'],
    [/&lt;%= passUrl.offline %&gt;/g, '#'],
    [/\$PASS_URL_OFFLINE\$/g, props.googleWalletUrlOffline || '#'],

    //onboarding-email-template
    [/\$FIRST_NAME\$/g, account.firstName],
    [/\$LAST_NAME\$/g, account.lastName],
    [/\$PASS_URL_ONLINE\$/g, props.googleWalletUrlOnline || '#'],
    [/\$PASS_URL_OFFLINE\$/g, props.googleWalletUrlOffline || '#'],
  ];
};

export const getTestHtmlString = (
  initialHtml: string,
  account: Account,
  profileDesign: ProfileDesignConfig,
  props: Props,
) =>
  getTestHtmlStringParams(account, profileDesign, props).reduce(
    (acc, [reg, value]) => (typeof value === 'string' ? acc.replace(reg, value) : acc),
    initialHtml,
  );

export const getFixedContent = (
  themeConfigContent: EmailEditorFixedContent[],
  emailTemplate: EmailTemplatesEnum,
  tier: string,
  hasFullAccess: boolean,
) => {
  const matcherFn = (x?: EmailEditorFixedContent) =>
    x &&
    x.templates.includes(emailTemplate) &&
    (!x.condition?.tiers || x.condition.tiers.includes(tier)) &&
    (!hasFullAccess || (hasFullAccess && !x.condition?.onlyNonFullAccess));

  const pre = themeConfigContent.find(
    x => matcherFn(x) && x.type === EmailEditorFixedContentTypeEnum.PreContent,
  );

  const post = themeConfigContent.find(
    x => matcherFn(x) && x.type === EmailEditorFixedContentTypeEnum.PostContent,
  );

  return { fixedPreContent: pre?.html || '', fixedPostContent: post?.html || '' };
};

export const withFixedPreContent = (html: string, content: string) => {
  return html.replace(/<body[^>]*>/, match => `${match}${content}`);
};

export const withFixedPostContent = (html: string, content: string) => {
  return html.replace(/<\/body>/, match => `${content}${match}`);
};
