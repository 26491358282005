import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../../../application/hooks';

import { onboardingNotify } from '../../../../util';
import { Loader } from '../../../components/common';

import WalletGenerator from '../../wallet/WalletGenerator';
import { ContinueButton } from './common';
import { Account } from '@/shared/types/api';
import { generateProfileLink } from '@/shared/business-logic/features/profile/getter';

interface Props {
  next: () => void;
  buttonTitle: string;
}

export const Screen2 = (props: Props) => {
  const { isLoading } = useAppSelector(state => state.onboarding);
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const handleSubmit = async () => {
    onboardingNotify('add-to-wallet-completed', getAccessTokenSilently);
    props.next();
  };

  const account: Account = useAppSelector(state => state.account);

  const profileUrl = generateProfileLink(account);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Wrapper>
        <WalletGenerator
          profileUrl={profileUrl}
          showOnly='online'
          detectDownload={() => {
            onboardingNotify('add-to-wallet-clicked', getAccessTokenSilently);
          }}
          description={t('onboarding.addToWallet.description')}
          noMobileHint
        />
      </Wrapper>
      <ContinueButton buttonTitle={props.buttonTitle} nextFunction={handleSubmit} />
    </>
  );
};
const Wrapper = styled.div`
  max-width: 100%;
  flex: 1;
`;
