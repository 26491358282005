import { FormControl, InputLabel, Select, MenuItem, FormControlProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISO6391 from 'iso-639-1';
import { DropdownWrapper, StyledLabel, StyledOption, StyledSelect } from '../../RenderLeadOptions';

type Props = {
  onLangSelect: (value: string) => void;
  value: string;
  showEmptyOption?: boolean;
} & FormControlProps;

const languages = ['en', 'de'];

export const LanguageSelector = ({
  onLangSelect,
  value,
  showEmptyOption = false,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const labelProps = showEmptyOption ? { shrink: true } : {};
  const selectProps = showEmptyOption ? { notched: true, displayEmpty: true } : {};
  return (
    <FormControl fullWidth {...rest}>
      <InputLabel {...labelProps}>{t('language')}</InputLabel>
      <Select
        {...selectProps}
        value={value}
        label={t('language')}
        onChange={({ target: { value } }) => onLangSelect(value)}
      >
        {showEmptyOption && <MenuItem value={''}>{'standard'}</MenuItem>}
        {ISO6391.getLanguages(languages).map(({ code, nativeName }) => (
          <MenuItem key={code} value={code}>
            {nativeName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

//used in md page where we have not migrated to MUI yet
export const LanguageSelectorLegacy = ({ onLangSelect, value }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div>{t('language')}</div>
      <DropdownWrapper>
        <StyledLabel></StyledLabel>
        <StyledSelect
          value={value}
          onChange={e => {
            onLangSelect(e.target.value);
          }}
        >
          <StyledOption value={''}>{t('select')} ...</StyledOption>
          {ISO6391.getLanguages(languages).map(({ code, nativeName }) => (
            <StyledOption key={code} value={code}>
              {nativeName}
            </StyledOption>
          ))}
        </StyledSelect>
      </DropdownWrapper>
    </>
  );
};
