import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Screen1, Screen2, Screen3, Screen3Card } from './screens';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import OnboardingContainer from '../../components/common/containers/onboarding.container';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../../application/hooks';
import { RowDiv } from '../md/guide/styles';
import { useHistory } from 'react-router-dom';
import { onboardingNotify } from '../../../util';
import { Account } from '../../../shared/types/api';
import { HEADINGS, getRedirectedRoute } from './screens/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';

const OnBoarding = () => {
  const { t } = useAppTranslation();

  // when this is rendered, the email has been verified
  const { getAccessTokenSilently, user } = useAuth0();
  const account = useAppSelector<Account>(state => state.account);
  const { isStarter, isTrial } = useTierInfo();
  const isCardOnboarding = account.origin === 'card';
  useEffect(() => {
    if (user.email_verified) {
      onboardingNotify('email-verified', getAccessTokenSilently);
    }
  }, [getAccessTokenSilently, user?.email_verified, t]);

  const SCREENS = useMemo(
    () =>
      Array.from(
        { length: (isCardOnboarding ? 4 : 3) - (!isStarter && !isTrial ? 1 : 0) },
        (_, i) => i + 1,
      ),
    [isCardOnboarding, isStarter, isTrial],
  );
  const [screenNumber, setScreenNumber] = useState(1);

  const buttonTitle = 'next';

  // to prevent the image width to "jump"
  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  const history = useHistory();
  const redirectHandler = () => {
    const redirectedRoute = getRedirectedRoute(account?.origin);
    history.push(redirectedRoute);
  };

  const nextScreen = () => {
    if (!isStarter && !isTrial && screenNumber === SCREENS.length) {
      redirectHandler();
    } else {
      setScreenNumber(screenNumber + 1);
    }
  };
  const backScreen = e => {
    e.persist();
    setScreenNumber(screenNumber - 1);
  };

  const renderProgressBar = useCallback(
    () =>
      SCREENS.map(screen => (
        <>
          {screen !== 1 && <Line />}
          <CircleMarker active={screenNumber === screen}>{screen}</CircleMarker>
        </>
      )),
    [SCREENS, screenNumber],
  );

  const headings = HEADINGS(isCardOnboarding);

  return (
    <ParentContainer>
      <OnboardingWrapper
        screenNumber={screenNumber}
        pageHeading={headings[screenNumber - 1].heading}
        pageHeadingSecond={headings[screenNumber - 1].secondHeading}
        back={backScreen}
        next={nextScreen}
        subHeading={headings[screenNumber - 1].subHeading}
        backPath={screenNumber !== 1}
        preHeader={<PageNumbersContainer>{renderProgressBar()}</PageNumbersContainer>}
        changeImage={screenNumber === 2}
        skip={
          screenNumber === SCREENS.length
            ? () => {
                redirectHandler();
                onboardingNotify('meta-page-skipped', getAccessTokenSilently);
              }
            : () => {
                nextScreen();
                if (screenNumber === 2)
                  onboardingNotify('add-to-wallet-skipped', getAccessTokenSilently);
              }
        }
        isPlain
      >
        {screenNumber === 1 && <Screen1 next={nextScreen} buttonTitle={t(buttonTitle)} />}
        {screenNumber === 2 && <Screen2 next={nextScreen} buttonTitle={t('onboarding.continue')} />}
        {isCardOnboarding ? (
          screenNumber === 3 ? (
            <>
              <Screen3Card
                next={() => {
                  nextScreen();
                  onboardingNotify('how-to-tap-completed', getAccessTokenSilently);
                }}
                buttonTitle={t(buttonTitle)}
              />
            </>
          ) : (
            screenNumber === 4 && <Screen3 />
          )
        ) : (
          screenNumber === 3 && <Screen3 />
        )}
      </OnboardingWrapper>
    </ParentContainer>
  );
};

export default OnBoarding;

const ParentContainer = styled.div`
  background-color: #fff;
  height: 100vh;
`;

const OnboardingWrapper = styled(OnboardingContainer)``;
const CircleMarker = styled.div`
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.active ? '#4748EC' : '#bbbbc0')};
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
  ${props => props.active && 'background-color :  #4748EC'};
  ${props => props.active && 'color :  #ffffff'};
`;

const Line = styled.div`
  background-color: #bbbbc0;
  min-height: 2px;
  width: 30px;
`;

const PageNumbersContainer = styled(RowDiv)`
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
