import { useEffect } from 'react';

export default function useCloseByEscape(closeFunction: Function) {
  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') closeFunction?.();
    };
    document.addEventListener('keyup', onKeyUp);
    return () => document.removeEventListener('keyup', onKeyUp);
  });
}
