import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BottomBarConfig } from '@/infrastructure/hoc/types';
import { useBottomBarItems } from '@/infrastructure/hooks/Navigation/useBottomBarItems';
import { CSS_VARS, routePaths } from '@/infrastructure/constants';
import { styled } from '@mui/material';

const bottomNavigationActionDefaultStyles = {
  svg: {
    width: '2.5rem',
    height: '2.5rem',
  },
  span: {
    marginTop: '0.7rem',
    fontWeight: 500,
    fontSize: '1.2rem',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const bottomNavigationActionStyles = {
  ...bottomNavigationActionDefaultStyles,
  color: 'secondary.main',
  '&.Mui-selected': {
    ...bottomNavigationActionDefaultStyles,
    color: 'primaryButton.main',
  },
};

const scannerIconWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '1rem',
  color: 'white',
  width: '75%',
  height: '75%',
  position: 'absolute',
  top: '-2rem',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  svg: {
    width: '3rem',
    height: '3rem',
  },
};

export const MobileBottomBar = ({
  activeScreen,
  noBorderRadiusMobile,
  noBoxShadowMobile,
  noHelpButton,
}: BottomBarConfig) => {
  const { t } = useTranslation();

  const items = useBottomBarItems();

  const history = useHistory();
  return (
    <NavBar component='nav'>
      <BottomNavigation
        sx={{
          height: '100%',
          borderRadius: noBorderRadiusMobile ? 'none' : '1.5rem 1.5rem 0 0',
          boxShadow: noBoxShadowMobile ? 'none' : '0px -4px 4px 0px rgba(0, 0, 0, 0.05)',
          paddingBottom: '1rem',
        }}
        showLabels
        value={activeScreen}
        onChange={(event, newValue) => history.push(newValue)}
      >
        {items.map((item, index) => {
          const isScanner = item.to === routePaths.CONTACTS.SCANNER;
          return (
            <BottomNavigationAction
              sx={{
                ...bottomNavigationActionStyles,
              }}
              key={index}
              label={t(item.text)}
              disableRipple
              icon={
                <>
                  <Box sx={isScanner && { opacity: 0 }}>
                    <item.icon />
                  </Box>
                  {isScanner && (
                    <Box sx={scannerIconWrapperStyles} bgcolor={'primary.main'}>
                      <item.icon />
                    </Box>
                  )}
                </>
              }
              value={item.to}
            />
          );
        })}
      </BottomNavigation>
    </NavBar>
  );
};

const NavBar = styled(Box)({
  position: 'fixed',
  bottom: 0,
  zIndex: 50,
  width: '100%',
  height: CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR,
});

export function BoxMobileBottomBarRelative() {
  return <Box sx={{ height: CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR }} />;
}
