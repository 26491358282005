import React from 'react';
import { SectionContainer, SectionHeader, Spacer } from './common';
import styled from 'styled-components';
import TooltipInfo from '../../../components/generic/TooltipInfo';

interface Props {
  headerText: string;
  tooltipText?: string;
  subHeader?: string;
}

const SectionHeading = (props: Props) => {
  const { headerText, tooltipText } = props;
  return (
    <SectionContainer>
      <SectionHeader>{headerText}</SectionHeader>
      {props.subHeader && <Spacer size={9} />}
      {props.subHeader && <SubHeader>{props.subHeader}</SubHeader>}
      {props.subHeader && <Spacer size={9} />}
      {tooltipText && <TooltipInfo text={tooltipText} placement='right' />}
    </SectionContainer>
  );
};

export default SectionHeading;

const SubHeader = styled('span')`
  font-size: small;
`;
