import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from './common';

interface Props {
  onSave: () => void;
  onBack?: () => void;
  position?: React.CSSProperties['position'];
  saveDisabled?: boolean;
  primaryColor?: string;
}

const Buttons = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper position={props.position}>
      <ButtonsContainer>
        <PrimaryButton
          onClick={props.onSave}
          disabled={props.saveDisabled}
          bgColor={props?.primaryColor}
        >
          {t('save')}
        </PrimaryButton>
        {props.onBack && <SecondaryButton onClick={props.onBack}>{t('cancel')}</SecondaryButton>}
      </ButtonsContainer>
    </Wrapper>
  );
};

export default Buttons;

const Wrapper = styled.div`
  z-index: 4;
  display: flex;
  justify-content: center;
  width: 100%;
  position: ${props => props.position};
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid #868686;
  padding: 2rem 0;
  background-color: #ffffff;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
`;
