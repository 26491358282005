import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  TextField,
  TypographyPropsVariantOverrides,
  useTheme,
  Link,
  Popper,
} from '@mui/material';
import { typographyVariants as typographyVariantInstance } from './config/theme/typography';
import { Variant } from '@mui/material/styles/createTypography';
import React, { useState } from 'react';
import { Modal } from './components';
import withNav from './infrastructure/hoc/withNav';
import { LoadingButton } from '@mui/lab';
const typographyVariants = Object.keys(typographyVariantInstance) as (Variant &
  TypographyPropsVariantOverrides)[];

const buttonSizes = ['small', 'medium', 'large'] as const;
const buttonVariants = ['contained', 'outlined', 'text'] as const;
const colorVariants = ['primary', 'secondary', 'error', 'info', 'success', 'warning'] as const;
type ColorType = typeof colorVariants[number];

const alertVariants = ['filled', 'outlined', 'standard'] as const;

const selectValues = colorVariants;

function ComponentsDemo() {
  const [isThemeModalOpened, setThemeModalOpened] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState('');
  const [selectedSelectColor, setSelectedSelectColor] = React.useState<ColorType>('primary');
  const [loading, setLoading] = React.useState(false);

  const theme = useTheme();
  const themeObject = {
    typography: theme.typography,
    palette: theme.palette,
    shape: theme.shape,
    components: theme.components,
  };

  const handleInputChange = ({ target: { value } }) => setTextFieldValue(value);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    console.log('event.currentTarget:', event.currentTarget);
  };

  return (
    <>
      <Box m={3} pb={15}>
        <Button variant='contained' color='primary' onClick={() => setThemeModalOpened(true)}>
          View Current Theme
        </Button>
        <Divider sx={{ my: 2 }} />
        <Typography variant='h3'>Typography</Typography>
        {typographyVariants.map(variant => (
          <Typography sx={{ display: 'block' }} variant={variant}>
            {variant}
          </Typography>
        ))}
        <Typography variant='h3'>Buttons</Typography>
        {buttonSizes.map(size =>
          buttonVariants.map(variant => (
            <Card sx={{ mb: 2 }} key={variant} color='primary'>
              <CardHeader title={`${variant}-${size}`} />
              <CardContent sx={{ display: 'flex', gap: 2 }}>
                {([...colorVariants, 'primaryButton'] as const).map(colorVariant => (
                  <Button
                    size={size}
                    key={`${variant}-${colorVariant}`}
                    sx={{ display: 'block' }}
                    variant={variant}
                    color={colorVariant}
                  >
                    {colorVariant}
                  </Button>
                ))}
              </CardContent>
            </Card>
          )),
        )}
        <Typography variant='h3'>Loading Buttons</Typography>
        <LoadingButton loading={loading} onClick={() => setLoading(true)}>
          click
        </LoadingButton>
        {buttonSizes.map(size =>
          buttonVariants.map(variant => (
            <Card sx={{ mb: 2 }} key={variant} color='primary'>
              <CardHeader title={`${variant}-${size}`} />
              <CardContent sx={{ display: 'flex', gap: 2 }}>
                {([...colorVariants, 'primaryButton'] as const).map(colorVariant => (
                  <LoadingButton
                    size={size}
                    key={`${variant}-${colorVariant}`}
                    variant={variant}
                    color={colorVariant}
                    loading={loading}
                    onClick={() => setLoading(true)}
                  >
                    {colorVariant} click
                  </LoadingButton>
                ))}
              </CardContent>
            </Card>
          )),
        )}

        <Typography variant='h3'>Divider</Typography>
        <Divider sx={{ m: 2 }} />
        <Typography variant='h3'>Alert</Typography>
        {alertVariants.map(variant => (
          <>
            <Typography variant='h4'>{variant}</Typography>
            <Box mb={2} display={'flex'} width={'100%'} gap={2}>
              <Alert variant={variant} severity='error'>
                This is an error alert — check it out!
              </Alert>
              <Alert variant={variant} severity='warning'>
                This is a warning alert — check it out!
              </Alert>
              <Alert variant={variant} severity='info'>
                This is an info alert — check it out!
              </Alert>
              <Alert variant={variant} severity='success'>
                This is a success alert — check it out!
              </Alert>
            </Box>
          </>
        ))}

        <Typography variant='h3'>Text Field</Typography>
        <Box display={'flex'} flexWrap={'wrap'} gap={2}>
          {colorVariants.map(colorVariant => (
            <Card>
              <CardHeader title={colorVariant} />
              <CardContent sx={{ display: 'flex', gap: 2 }}>
                {(['filled', 'outlined'] as const).map(variant => (
                  <TextField
                    label={variant}
                    color={colorVariant}
                    variant={variant}
                    value={textFieldValue}
                    onChange={handleInputChange}
                  />
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
        <Typography mt={2} variant='h3'>
          Select
        </Typography>
        <Box display={'flex'} flexWrap={'wrap'} gap={2}>
          {(['filled', 'outlined'] as const).map(variant => (
            <FormControl color={selectedSelectColor} variant={variant} sx={{ width: 300 }}>
              <InputLabel id='colorVariantLabelId'>Select Color Variant</InputLabel>
              <Select
                labelId='colorVariantLabelId'
                value={selectedSelectColor}
                label='Select Color Variant'
                onChange={({ target: { value } }) => setSelectedSelectColor(value as ColorType)}
              >
                {selectValues.map(value => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Box>
        <Link underline={'hover'} href='#'>
          Link
        </Link>
      </Box>

      <Modal open={isThemeModalOpened} onClose={() => setThemeModalOpened(false)}>
        <Paper>
          <pre style={{ padding: '2rem' }}>{JSON.stringify(themeObject, null, 2)}</pre>
        </Paper>
      </Modal>

      <button type='button' onClick={handleClick}>
        Toggle Popper
      </button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onResize={() => {}}
        onResizeCapture={() => {}}
        placement='right-start'
      >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>The content of the Popper.</Box>
      </Popper>
    </>
  );
}

export default withNav(ComponentsDemo, { tTitle: 'Demo' }, {});
