import { useState } from 'react';

const useBulk = () => {
  const [bulkEdit, setBulkEdit] = useState(false);

  const bulkEditOn = () => setBulkEdit(true);
  const bulkEditOff = () => setBulkEdit(false);

  return { bulkEdit, setBulkEdit, bulkEditOn, bulkEditOff };
};

export default useBulk;
