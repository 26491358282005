import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../application/hooks';
import { Account, FeatureSet } from '../../shared/types/api';
import { hasRole } from '../helper';
import { ROLES, TRIALSTATUS } from '@/shared/constants';

export default function useTierInfo() {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [idToken, setIdToken] = useState<IdToken>(null);
  useEffect(() => {
    if (isAuthenticated && !idToken) {
      getIdTokenClaims().then(setIdToken);
    }
  }, [idToken, getIdTokenClaims, isAuthenticated]);

  const account = useAppSelector<Account>(state => state.account);
  const theme = account?.theme;

  const now = useMemo(() => new Date().getTime(), []);

  const isFeatureAllowed = useCallback(
    (feature: keyof FeatureSet) => !!theme?.featureSet?.[feature],
    [theme?.featureSet],
  );

  const isMinTier = useCallback(
    (minTier: string) => {
      if (minTier === 'starter') return true;
      else if (minTier === 'essentials')
        return ['essentials', 'pro', 'enterprise'].includes(theme?.themeInternal?.tier);
      else if (minTier === 'pro') ['pro', 'enterprise'].includes(theme?.themeInternal?.tier);
      else if (minTier === 'enterprise') return theme?.themeInternal?.tier === 'enterprise';
      else return false;
    },
    [theme?.themeInternal?.tier],
  );

  const base = {
    loading: !theme?.themeInternal || !theme?.featureSet,
    isStarter: theme?.themeInternal?.tier === 'starter',
    isEssentials: theme?.themeInternal?.tier === 'essentials',
    isPro: theme?.themeInternal?.tier === 'pro',
    isEnterprise: theme?.themeInternal?.tier === 'enterprise',
    isMinEssentials: ['essentials', 'pro', 'enterprise'].includes(theme?.themeInternal?.tier),
    isMinPro: ['pro', 'enterprise'].includes(theme?.themeInternal?.tier),
    isTrial:
      theme?.themeInternal?.trialEndDate &&
      new Date(theme?.themeInternal?.trialEndDate).getTime() > now &&
      theme?.themeInternal?.trialStatus === TRIALSTATUS.CURRENT,
    isTrialFinished:
      theme?.themeInternal?.trialEndDate &&
      new Date(theme?.themeInternal?.trialEndDate).getTime() <= now &&
      theme?.themeInternal?.trialStatus === TRIALSTATUS.FINISHED,
    isFeatureAllowed,
    isThemeAdmin: hasRole(idToken, [ROLES.THEME_ADMIN]),
    isUnitAdmin: hasRole(idToken, [ROLES.UNIT_ADMIN]),
    isAnyAdmin: hasRole(idToken, [ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]),
    isOnlySingle: theme?.themeInternal?.onlySingle,
    isMainAccount: theme?.themeInternal?.mainAccountId === account.id,
    wasCreatedInTheme: typeof account.attachedThemeId === 'number',
    isMinTier,
    tier: theme?.themeInternal?.tier,
  };

  const derivedProps = {
    canStartTrial: !base.isTrialFinished && !base.wasCreatedInTheme,
  };
  return {
    ...base,
    ...derivedProps,
  };
}

export type TierInfo = ReturnType<typeof useTierInfo>;
