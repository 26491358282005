import { TextField, Typography } from '@mui/material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LtDialog } from '@/components';

const TestEmailSendPopup = ({ open, onClose, onConfirm, email, setEmail, loading }) => {
  const { t } = useAppTranslation();

  return (
    <LtDialog
      onClose={onClose}
      open={open}
      title={t('emailTemplates.testEmailSendPopupTitle')}
      onCancel={onClose}
      confirmAction={{
        ttext: 'send',
        onClick: onConfirm,
      }}
      loading={loading}
    >
      <TextField
        fullWidth
        name='email'
        value={email}
        onChange={e => setEmail(e.target.value)}
        label='Email'
      />
      <Typography mt={3}>{t('emailTemplates.testEmailSendPopupSubtitle')}</Typography>
    </LtDialog>
  );
};

export default TestEmailSendPopup;
