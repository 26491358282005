import withNav from '../../../../infrastructure/hoc/withNav';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import useDesktopView from '../../../../infrastructure/hooks/useDesktopView';
import ViewsContainer from '../../../components/common/containers/ViewsContainer';
import { PageHeader, HeaderTextContainer } from '../common';
import styled from 'styled-components';
import NavContainer from '../common/nav-container';
import customDomainImage from '../../../images/integrations/custom-domain.png';
import ssoImage from '../../../images/integrations/sso.png';
import activeDirectoryImage from '../../../images/integrations/active-directory.png';
import crmImage from '../../../images/integrations/crm.png';
import { useAppSelector } from '../../../../application/hooks';
import { useCallback, useMemo, useState } from 'react';
import { BsCheck, BsClock } from 'react-icons/bs';
import UpgradeRequestPopup from '../../../components/popups/upgradeRequest';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';

const Integrations = () => {
  const integrations = useAppSelector<Record<string, boolean>>(
    state => state.account?.theme?.themeInternal?.integrations,
  );
  const { desktopView } = useDesktopView();
  const { t } = useAppTranslation();
  const history = useHistory();
  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');

  const [showUpgradePopup, setShowUpgradePopup] = useState(false);

  const { isTrial } = useTierInfo();
  const upgradeRequest = useCallback(
    () => (isTrial ? toast.error(t('nfc.upgradeToContinue')) : setShowUpgradePopup(true)),
    [isTrial, t],
  );

  const connectedIndicator = useMemo(
    () => (
      <ConnectedWrapper>
        <BsCheck color='#000' size={24} />
        <span>{t('integrations.connected')}</span>
      </ConnectedWrapper>
    ),
    [t],
  );
  const inProgressIndicator = useMemo(
    () => (
      <ConnectedWrapper>
        <BsClock color='#000' size={20} />
        <span>{t('integrations.inProgress')}</span>
      </ConnectedWrapper>
    ),
    [t],
  );
  return (
    <>
      {showUpgradePopup && (
        <UpgradeRequestPopup tTitle='contactUs' onClosed={() => setShowUpgradePopup(false)} />
      )}
      <ViewsContainer>
        <HeaderTextContainer>
          {!desktopView && <PageHeader>{t('integrations.title')}</PageHeader>}
        </HeaderTextContainer>

        <IntegrationsContainer>
          <NavContainer
            block
            header={t('integrations.customDomain.title')}
            subheader={t('integrations.customDomain.description')}
            button={
              !integrations.customDomain && !integrations.customDomainInProgress
                ? t('integrations.connect')
                : undefined
            }
            customActionItem={
              integrations.customDomain
                ? connectedIndicator
                : integrations.customDomainInProgress
                ? inProgressIndicator
                : undefined
            }
            img={customDomainImage}
            action={upgradeRequest}
          />
          <NavContainer
            block
            header={t('integrations.sso.title')}
            subheader={t('integrations.sso.description')}
            button={
              !integrations.sso && !integrations.ssoInProgress
                ? t('integrations.connect')
                : undefined
            }
            customActionItem={
              integrations.sso
                ? connectedIndicator
                : integrations.ssoInProgress
                ? inProgressIndicator
                : undefined
            }
            img={ssoImage}
            action={upgradeRequest}
          />
          <NavContainer
            block
            header={t('integrations.activeDirectory.title')}
            subheader={t('integrations.activeDirectory.description')}
            button={
              !integrations.activeDirectory && !integrations.activeDirectoryInProgress
                ? t('integrations.connect')
                : undefined
            }
            customActionItem={
              integrations.activeDirectory
                ? connectedIndicator
                : integrations.activeDirectoryInProgress
                ? inProgressIndicator
                : undefined
            }
            img={activeDirectoryImage}
            action={upgradeRequest}
          />
          <NavContainer
            block
            header={t('integrations.crm.title')}
            subheader={t('integrations.crm.description')}
            button={t('integrations.configure')}
            img={crmImage}
            action={
              flag_crmIntegration
                ? () => history.push(routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION)
                : upgradeRequest
            }
          />
        </IntegrationsContainer>
      </ViewsContainer>
    </>
  );
};

export default withNav(
  Integrations,
  {
    tTitle: 'integrations.title',
  },
  {},
);

const IntegrationsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
`;

const ConnectedWrapper = styled.div`
  color: black;
  display: flex;
  align-items: center;
  margin-top: auto;
  span {
    margin-left: 1rem;
  }
`;
