import React from 'react';
import styled from 'styled-components';

export const Separator: React.FC<{ text?: string; width?: string }> = ({ text, width }) => {
  return (
    <Wrapper $width={width}>
      <div className='line'></div>
      {text && <span>{text}</span>}
      <div className='line'></div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.$width ?? '85%'};
  margin: 3rem auto;

  span {
    padding: 0 2rem;
    color: #b7b7b7;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .line {
    flex: 1;
    height: 1px;
    background-color: #b7b7b7;
  }
`;
