import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import { BiChevronDown } from 'react-icons/bi';
import Action, { ActionType } from './action';
import { BulkButton } from '@/views/pages/md/common/table';
import { CSS_VARS } from '@/infrastructure/constants';

interface Props {
  label: string;
  actions: ActionType[];
  icon?: React.ReactNode;
}

const BulkActions = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <BulkButton
        onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
        color={getComputedStyle(document.documentElement, null).getPropertyValue(
          CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
        )}
      >
        {props?.icon}
        <span>{props.label}</span>
        <BiChevronDown size={15} />
      </BulkButton>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {props.actions.map(action => (
          <>
            <Action
              key={action.actionText}
              {...action}
              onClick={() => {
                action.onClick();
                setAnchorEl(null);
              }}
            />
          </>
        ))}
      </StyledMenu>
    </>
  );
};

export default BulkActions;

const StyledMenu = styled(Menu)`
  ul {
    width: 25rem;
    padding: 0;
    padding-top: 4px;
  }
`;
