import { Account, StaticProfileConfig } from '@/shared/types/api';
import OverridesWrapper from './OverridesWrapper';
import TryForFreeBanner from './TryForFreeBanner';
import { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import ProfileDetails from '@/views/components/profile-details';
import { trackPublicEvent } from '@/infrastructure/apis/analytics';
import { PUBLIC_EVENTS } from '@/shared/constants';
import useFont from '../hooks/useFont';
import styled from 'styled-components';
import ContactDetailsBox from './ContactDetailsBox';
import { handleWebShare } from '@/views/components/profile-details/contactWebShare';
import CustomBioTextBox from './BioTextBox';
import UrlsBox from './UrlsBox';
import Policies from './Policies';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import ContactFormBox, { ContactForm } from './ContactFormBox';
import toast from 'react-hot-toast';
import { addLead } from '@/infrastructure/apis/leadGen';
import { Overlay } from '@/views/components/common/overlay';
import { Popup } from '@/views/components/common';
import { useAuth0 } from '@auth0/auth0-react';

// TODO: profile page optimizations
// take out lead gen logic from here?
// -> component should be UI-only

interface Props {
  profile: StaticProfileConfig;
  activeLanguage: string;
  authAccount?: Account | null;
  renderTryForFree?: boolean;
  noFooter?: boolean;
  noCookieSettings?: boolean;
  noLangSelector?: boolean;
  leadGenFormRef?: Ref<HTMLDivElement>;
  forcedLeadValues?: Parameters<Parameters<typeof ContactForm>[0]['onSubmit']>[0];
}

export default function StaticProfile({
  profile,
  activeLanguage,
  authAccount,
  renderTryForFree,
  noFooter,
  noCookieSettings,
  noLangSelector,
  leadGenFormRef,
  forcedLeadValues,
}: Props): JSX.Element {
  const [tffBannerHeight, setTffBannerHeight] = useState(0); // tff = try for free

  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const thArg = useMemo(
    () => ({
      username: authAccount?.username,
      isAuthenticated,
      isAuth0Loading,
    }),
    [authAccount?.username, isAuth0Loading, isAuthenticated],
  );

  const { t, Translation } = useAppTranslation();

  // make sure that font is fetched
  useFont(profile.config.font);

  // lead gen form logic
  const [leadGenFormValues, setLeadGenFormValues] = useState<
    Parameters<typeof ContactFormBox>[0]['values']
  >({});
  const setLeadGenFormValue = useCallback<Parameters<typeof ContactFormBox>[0]['setValue']>(
    (key, value) => setLeadGenFormValues(prev => ({ ...prev, [key]: value })),
    [],
  );
  const resetLeadGenFormValues = useCallback(() => setLeadGenFormValues({}), []);
  const onLeadGenFormSubmit = useCallback<Parameters<typeof ContactFormBox>[0]['onSubmit']>(
    (values, onSuccessCb) => {
      const toastId = 'createLeadToast';
      toast.loading(t('Loading'), { id: toastId });

      addLead(
        { ...values, preferredLang: activeLanguage, ...(forcedLeadValues || {}) },
        profile.meta.username,
        true,
      )
        .then(response => {
          const result = response.data;
          if (result.isSuccess) {
            toast.success(t('leadSuccess'), { id: toastId });
            resetLeadGenFormValues();
            onSuccessCb?.();
          } else {
            toast.error(t('leadError'), { id: toastId });
          }
        })
        .catch(() => {
          toast.error(t('leadError'), { id: toastId });
        });

      trackPublicEvent(thArg, PUBLIC_EVENTS.CONTACT_SUBMIT_CLICK, profile.meta.username);
    },
    [forcedLeadValues, profile.meta.username, resetLeadGenFormValues, t, thArg, activeLanguage],
  );
  const [exchangeState, setExchangeState] = useState<
    | { flow: 'bottomCardStatic' }
    | { flow: 'popup'; state: 'toPopup' | 'popupVisible' | 'popupDismissed' | 'submitted' }
    | null
  >(null);
  useEffect(() => {
    if (!exchangeState && profile.leadGen) {
      // init flow
      if (profile.leadGen.flow === 'bottomCardStatic') {
        setExchangeState({ flow: 'bottomCardStatic' });
      } else if (profile.leadGen.flow === 'popup') {
        setExchangeState({ flow: 'popup', state: 'toPopup' });
      }
    }
  }, [exchangeState, profile.leadGen]);
  // popup after 3s
  useEffect(() => {
    if (profile.leadGen && exchangeState?.flow === 'popup') {
      const toId = setTimeout(() => {
        setExchangeState(prev => ({ ...prev, state: 'popupVisible' }));
      }, 3000);
      return () => clearTimeout(toId);
    }
  }, [exchangeState?.flow, profile.leadGen]);

  return (
    <>
      <OverridesWrapper font={profile.config.font}>
        {renderTryForFree && (
          <>
            <TryForFreeBanner
              username={profile.meta.username}
              onHeightChange={setTffBannerHeight}
            />
            {/* to have the banner image shown _below_ the tff banner: */}
            <div style={{ height: tffBannerHeight }}></div>{' '}
          </>
        )}

        <ProfileDetails
          username={profile.meta.username}
          config={profile.header}
          boxStyle={profile.config.boxStyle}
          hideSaveContact={profile.leadGen?.flow === 'popup'}
          profilePicTrackHandler={() =>
            trackPublicEvent(thArg, PUBLIC_EVENTS.PROFILE_PIC_CLICK, profile.meta.username)
          }
          contactSaveTrackHandler={() =>
            trackPublicEvent(thArg, PUBLIC_EVENTS.CONTACT_SAVE_CLICK, profile.meta.username)
          }
        />

        <Wrapper>
          {profile.body.boxes.map(box => {
            switch (box.name) {
              case 'contactDetailsBox':
                return (
                  <ContactDetailsBox
                    config={box}
                    boxStyle={profile.config.boxStyle}
                    onSaveContact={
                      profile.leadGen?.flow === 'popup'
                        ? () => {
                            handleWebShare(profile.meta.username, activeLanguage);
                            trackPublicEvent(
                              thArg,
                              PUBLIC_EVENTS.CONTACT_SAVE_CLICK,
                              profile.meta.username,
                            );
                          }
                        : undefined
                    }
                  />
                );

              case 'customBioTextBox':
                return <CustomBioTextBox config={box} boxStyle={profile.config.boxStyle} />;

              case 'urlsBox':
                return (
                  <UrlsBox
                    config={box}
                    boxStyle={profile.config.boxStyle}
                    onTrackFileClick={(fileId: number, pageCount: number) => {
                      trackPublicEvent(
                        thArg,
                        PUBLIC_EVENTS.FILE_CLICK,
                        profile.meta.username,
                        fileId,
                        null,
                        pageCount ? { pageCount } : undefined,
                      );
                    }}
                    onTrackLinkClick={(linkId: number) => {
                      trackPublicEvent(
                        thArg,
                        PUBLIC_EVENTS.LINK_CLICK,
                        profile.meta.username,
                        null,
                        linkId,
                      );
                    }}
                  />
                );

              case 'contactFormBox':
                if (!profile.leadGen) return null;
                if (exchangeState?.flow === 'popup' && exchangeState?.state !== 'popupDismissed')
                  return null;
                return (
                  <>
                    {profile.leadGen.flow === 'bottomCardStatic' && (
                      <div ref={leadGenFormRef}></div>
                    )}
                    <ContactFormBox
                      config={profile.leadGen}
                      values={leadGenFormValues}
                      setValue={setLeadGenFormValue}
                      onSubmit={(values, cb) =>
                        onLeadGenFormSubmit(values, () => {
                          if (exchangeState?.flow === 'popup')
                            setExchangeState(prev => ({ ...prev, state: 'submitted' }));
                          cb();
                        })
                      }
                      boxStyle={profile.config.boxStyle}
                      boxLabel={box.label}
                      variant={
                        profile.leadGen.flow === 'bottomCardStatic'
                          ? 'v1'
                          : profile.leadGen.flow === 'popup'
                          ? 'v2-card'
                          : null
                      }
                    />
                  </>
                );

              default:
                return null;
            }
          })}

          {exchangeState?.flow === 'popup' && exchangeState.state === 'submitted' && (
            <ContactFormBox
              config={profile.leadGen}
              values={leadGenFormValues}
              setValue={setLeadGenFormValue}
              onSubmit={onLeadGenFormSubmit}
              boxStyle={profile.config.boxStyle}
              variant='v2-card'
            />
          )}
        </Wrapper>

        {!noFooter && (
          <Wrapper>
            <Policies
              imprint={profile.footer.imprint}
              privacyPolicy={profile.footer.privacyPolicy}
              copyright={profile.footer.copyright}
              showCookieSettingsButton={!noCookieSettings}
            />
            {!noLangSelector && profile.meta.supportedLangs.length > 1 && (
              <Translation black languages={profile.meta.supportedLangs} />
            )}
          </Wrapper>
        )}
      </OverridesWrapper>

      {exchangeState?.flow === 'popup' && exchangeState.state === 'popupVisible' && (
        <>
          <Overlay style={{ zIndex: 60 }} />
          <Popup
            withSideBar={false}
            onCloseClick={() => setExchangeState(prev => ({ ...prev, state: 'popupDismissed' }))}
            customContentWrapperStyles={{ marginTop: '-3rem' }}
          >
            <ContactFormBox
              config={profile.leadGen}
              values={leadGenFormValues}
              setValue={setLeadGenFormValue}
              onSubmit={(values, cb) =>
                onLeadGenFormSubmit(values, () => {
                  if (exchangeState?.flow === 'popup')
                    setExchangeState(prev => ({ ...prev, state: 'submitted' }));
                  cb();
                })
              }
              boxStyle={profile.config.boxStyle}
              variant='v2-popup'
            />
          </Popup>
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 33%;
  margin: 0 auto 7rem auto;
  padding-bottom: 5rem;
  @media (max-width: 1024px) and (min-height: 1366px) {
    width: 50%;
  }
  @media (min-width: 768px) and (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
`;
