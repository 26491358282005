import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../components/generic';
import { PrimaryButton, RowDiv, Spacer } from './common';
import { AiOutlineClose, AiOutlineCloudUpload, AiOutlineFile } from 'react-icons/ai';
import { Account, ApiFile } from '../../../../shared/types/api';
import { uploadTemplateFile } from '../../../../application/actions/edit-profile';
import { useAuth0 } from '@auth0/auth0-react';
import { getPdfPageCount, isFileValid } from '../../../../infrastructure/helper';

import toast from 'react-hot-toast';
import { useAppSelector } from '../../../../application/hooks';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';

interface Props {
  filesList: ApiFile[];
  setFilesList: Dispatch<SetStateAction<ApiFile[]>>;
}

const AddFileComponent = (props: Props) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState<number>(null);
  const [hasFileUploaded, setFileUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const account: Account = useAppSelector(state => state.account);

  useDeferredLoader(loading, 'add-file-template-loader-id');

  const handleSave = async () => {
    const myPromise = uploadTemplateFile(
      account.theme?.themeInternal.uniqueId,
      uploadedFile,
      fileName,
      pageCount || 1,
      getAccessTokenSilently,
    );
    setSaveDisabled(true);
    await toast.promise(myPromise, {
      loading: t('uploading'),
      success: result => {
        setSaveDisabled(false);
        if (!result) return;
        props.setFilesList([
          ...props.filesList,
          {
            id: result.file.id,
            link: result.file.link,
            fileName: result.file.fileName,
            embed: false,
            canDisplayOnProfile: result.file.canDisplayOnProfile,
            order: result.file.order,
            pageCount: result.file.pageCount,
          },
        ]);
        setIsValidating(false);
        setFileName('');
        setPageCount(null);
        setUploadedFile(null);
        setFileUploaded(false);
        return t('filesTab.successfullySaved');
      },
      error: () => {
        setSaveDisabled(false);
        return t('filesTab.writingDataError');
      },
    });
  };

  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };
  const fileInput = useRef<HTMLInputElement>();

  const clearFileSelection = () => {
    setUploadedFile(null);
    setFileUploaded(false);
    if (fileInput.current) {
      fileInput.current.value = null;
    }
  };

  const onFileSelection = async () => {
    const file = fileInput.current?.files[0] as File;
    if (file) {
      setIsLoading(true);
      getPdfPageCount(file).then(count => {
        setPageCount(count);
        setIsLoading(false);
      });
      const errMsg = isFileValid(file, 'file', t);
      if (errMsg) {
        return toast.error(errMsg);
      }

      setUploadedFile(file);
      setFileUploaded(true);
    }
  };

  return (
    <AddFileContainer>
      <StyledText
        label={t('filesTab.fileName')}
        name={'fileName'}
        value={fileName}
        angles='normal'
        type='text'
        border={borderProps}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value)}
        font={{ size: '18px', weight: '400' }}
        padding={{ left: '0' }}
        helperText={isValidating && fileName === '' && 'File name required'}
        error={isValidating && fileName === ''}
      />
      <Spacer size={25} />
      <FileSelectionContainer
        onClick={event => fileInput.current?.click()}
        alert={isValidating && !hasFileUploaded}
      >
        {!hasFileUploaded && <AiOutlineCloudUpload size={25} />}
        <Spacer size={9} />
        {hasFileUploaded ? (
          <AiOutlineFile size={20} color='#9767ff' />
        ) : (
          <div>
            {t('filesTab.uploadFile')}
            <StatusText alert>{isValidating && !hasFileUploaded && 'File required'}</StatusText>
          </div>
        )}
        <Spacer size={9} />
        {hasFileUploaded && <StatusText>{uploadedFile?.name}</StatusText>}
        {hasFileUploaded && (
          <AiOutlineClose
            onClick={event => {
              clearFileSelection();
              event.stopPropagation();
            }}
            size={15}
            color='#3e3e3f'
          />
        )}
        <input
          type='file'
          ref={input => {
            fileInput.current = input;
          }}
          onChange={onFileSelection}
          style={{ display: 'none' }}
        />
      </FileSelectionContainer>
      <Spacer size={25} />
      <SaveButton
        text={t('save')}
        onClick={handleSave}
        disabled={fileName === '' || !hasFileUploaded || saveDisabled}
      >
        {t('save')}
      </SaveButton>
      {/* {errorMsg && <ErrorMessage message={errorMsg} />} */}
    </AddFileContainer>
  );
};

export default AddFileComponent;

const AddFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledText = styled(TextField)`
  width: 30%;
  min-width: 200px;
`;

const FileSelectionContainer = styled(RowDiv)`
  border-style: dashed;
  border-width: 2px;
  border-color: ${props => (props.alert ? 'red' : '#5c5c5c')};
  border-radius: 10px;
  justify-content: center;
  width: 30%;
  min-width: 200px;
  height: 55px;
  padding: 12px 0px;
  color: ${props => (props.alert ? 'red' : '#4748ec')};
  cursor: pointer;
`;
const SaveButton = styled(PrimaryButton)`
  padding: 15px 0px;
  font-size: 1.2rem;
  width: 10%;
  min-width: 150px;
  @media (max-width: 550) {
    margin-top: 15px;
  }
  ${props => props.disabled && 'background: #dddddd'};
`;

const StatusText = styled.div`
  font-size: 12px;
`;
