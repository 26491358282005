import { useAppSelector } from '@/application/hooks';
import { Account, ContactPerson } from '@/shared/types/api';
import useTierInfo from './useTierInfo';

const getMainAccountAsContactPerson = (account: Account): ContactPerson => {
  const mainAccount = account?.theme?.themeInternal?.mainAccount;
  if (!mainAccount) return null;

  return {
    email: mainAccount.email,
    name: `${mainAccount.firstName} ${mainAccount.lastName}`,
  };
};

export const useContactPersons = () => {
  const account: Account = useAppSelector(state => state.account);
  const { isStarter } = useTierInfo();
  if (isStarter) return [];

  const contactPersons = account?.theme?.themeInternal?.contactPersons;

  return (contactPersons || [getMainAccountAsContactPerson(account)])?.filter(Boolean);
};
