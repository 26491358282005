import { BiDownload } from 'react-icons/bi';
import { ActionType as Action } from '../components/action';
import { TFunction } from 'i18next';

interface Handlers {
  downloadEmployees: (type: string) => void;
  downloadProfileLinks: () => void;
  downloadQRCodes: (type: 'online' | 'offline') => void;
  downloadQRBg: (type: 'mobile' | 'videocall') => void;
}

export const ActionsArr = (numSelected: number, handlers: Handlers, t: TFunction) => {
  const { downloadQRCodes, downloadQRBg, downloadEmployees, downloadProfileLinks } = handlers;

  // const exceededProfiles = () => toast.error(t('error.errorDownloadQr'));

  return [
    {
      icon: <BiDownload size={15} />,
      actionText: t('onlineQrCodes'),
      tooltipText: t('actionTooltips.onlineQrCodes'),
      onClick: () => downloadQRCodes('online'),
    },
    {
      icon: <BiDownload size={15} />,
      actionText: t('offlineQrCodes'),
      tooltipText: t('actionTooltips.offlineQrCodes'),
      onClick: () => downloadQRCodes('offline'),
    },
    {
      icon: <BiDownload size={15} />,
      actionText: t('employeesCsv'),
      tooltipText: t('actionTooltips.csv'),
      onClick: () => downloadEmployees('csv'),
    },
    {
      icon: <BiDownload size={15} />,
      actionText: t('employeesXlsx'),
      tooltipText: t('actionTooltips.xlsx'),
      onClick: () => downloadEmployees('xlsx'),
    },
    {
      icon: <BiDownload size={15} />,
      actionText: t('profileLinks'),
      tooltipText: t('actionTooltips.links'),
      onClick: () => downloadProfileLinks(),
    },
    {
      icon: <BiDownload size={15} />,
      actionText: t('mobileBg'),
      tooltipText: t('actionTooltips.mobileBg'),
      // onClick: () => (numSelected <= 10 ? downloadQRBg('mobile') : exceededProfiles()),
      onClick: () => downloadQRBg('mobile'),
    },
    {
      icon: <BiDownload size={15} />,
      actionText: t('videoBg'),
      tooltipText: t('actionTooltips.videoBg'),
      // onClick: () => (numSelected <= 10 ? downloadQRBg('videocall') : exceededProfiles()),
      onClick: () => downloadQRBg('videocall'),
    },
  ] as Action[];
};
