import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import config from '../../../config/config';
import { withSilentAccessToken } from '../../helper';
import { SyncType } from '@/shared/types/api';

export const getConsumer = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(`${config.API_BASE_URL}crm-integration/consumer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const updateConsumer = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: { syncType?: SyncType; useLeads?: boolean },
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.put(`${config.API_BASE_URL}crm-integration/consumer`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const deleteConsumer = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.delete(`${config.API_BASE_URL}crm-integration/consumer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const generateVaultUrl = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(`${config.API_BASE_URL}crm-integration/generateVaultUrl`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const getConnectors = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(`${config.API_BASE_URL}crm-integration/connectors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};
