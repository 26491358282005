import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../application/hooks';
import { sendEmail } from '../../../../../../application/actions/md/profiles';
import { ReactComponent as ResendIcon } from '../../../../../images/resend.svg';
import { ReactComponent as SendIcon } from '../../../../../images/send.svg';
import toast from 'react-hot-toast';
import { Employee } from '../../../../../../shared/types/api/employee.type';
import { ACCOUNT_STATUSES } from '../../../../../../shared/constants';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  id: string;
  status: Employee['status'];
  email: string;
}

const Status = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { id, status, email } = props;

  const { theme: muiTheme } = useMuiTheme();

  switch (status) {
    case ACCOUNT_STATUSES.ACTIVE:
      return <StatusContainer>{t('statusActive')}</StatusContainer>;
    case ACCOUNT_STATUSES.INVITED:
      return (
        <StatusContainer caret>
          {t('statusInvited')}
          <StatusContent
            style={{
              color: muiTheme.palette.primaryButton.main,
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
            }}
          >
            <ResendIcon />
            <button
              onClick={() => {
                dispatch(
                  sendEmail(id, email, getAccessTokenSilently, t, () =>
                    toast.success(t('invited')),
                  ),
                );
              }}
            >
              {t('resendInvite')}
            </button>
          </StatusContent>
          <Caret />
        </StatusContainer>
      );
    case ACCOUNT_STATUSES.UNINVITED:
      return (
        <StatusContainer caret>
          {t('statusUninvited')}
          <StatusContent
            style={{
              color: muiTheme.palette.primaryButton.main,
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
            }}
          >
            <SendIcon />
            <button
              onClick={() =>
                dispatch(
                  sendEmail(id, email, getAccessTokenSilently, t, () =>
                    toast.success(t('invited')),
                  ),
                )
              }
            >
              {t('sendInvite')}
            </button>
          </StatusContent>
          <Caret />
        </StatusContainer>
      );
    default:
      return <StatusContainer>-</StatusContainer>;
  }
};

export default Status;

const StatusContent = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  opacity: 0;
  visibility: hidden;
  justify-content: space-evenly;
  align-items: center;
  padding: 7px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eee;
  box-shadow: 0 0 3px #ddd;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -10%);
  white-space: nowrap;
  transition: ease-in-out 0.2s;

  img {
    padding-right: 5px;
  }

  button {
    font-size: 0.8em;
    font-weight: 500;
    cursor: pointer;
    border: none;
    background: transparent;
  }
`;

const StatusContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: default;
  padding: ${(props: { caret: boolean }) => (props.caret ? '0 15px 0 5px' : 'unset')};

  &:hover ${StatusContent} {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 30%);
  }
`;

const Caret = styled.div`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 1.1px;
    display: inline-block;
    background-color: black;
  }

  &::before {
    transform: translateX(-35%) rotate(45deg);
  }

  &::after {
    transform: translateX(35%) rotate(-45deg);
  }
`;
