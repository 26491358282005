import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Container, Text } from '../../components/generic';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';

import BackIcon from '../../images/back-icon.svg';
import { routePaths } from '../../../infrastructure/constants';
import crms from '../../components/lead-gen/crms';
import withNav from '../../../infrastructure/hoc/withNav';
import useDesktopView from '../../../infrastructure/hooks/useDesktopView';

const ImportLeads = () => {
  const history = useHistory();
  const { desktopView } = useDesktopView();

  const { t } = useAppTranslation();

  return (
    <>
      <StyledContainer>
        <Content>
          <div>
            <BackIconWrapper
              src={BackIcon}
              onClick={() => history.push(routePaths.CONTACTS.EXPORT)}
            />
          </div>

          {!desktopView && <Heading text={t('howToImport')} />}
          <CRMRows>
            {crms.map(crm => (
              <CRMRow bgColor='#dadafb' key={crm.id}>
                <img src={crm.icon} alt={crm.alt} />
                <BtnWrapper btnColor='#4748EC'>
                  <a href={crm.url} target='_blank' rel='noopener noreferrer'>
                    {t('learnMore')}
                  </a>
                </BtnWrapper>
              </CRMRow>
            ))}
          </CRMRows>
        </Content>
      </StyledContainer>
    </>
  );
};

export default withNav(
  ImportLeads,
  {
    tTitle: 'howToImport',
    showBackButton: {
      linkTo: routePaths.CONTACTS.EXPORT,
    },
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
  },
);

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  box-shadow: none;
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 17px;
  box-sizing: content-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 767px) {
    width: 50%;
  }

  @media (min-width: 768px) and (max-width: 1645px) {
    width: 70%;
  }
`;

const Heading = styled(Text)`
  font-weight: 600;
  color: #181a5a;
  font-size: 16px;
  margin-top: 5px;
`;

const BackIconWrapper = styled.img`
  cursor: pointer;
  margin-bottom: 2rem;
`;

const CRMRows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

interface Props {
  bgColor?: string;
  btnColor?: string;
}

const CRMRow = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f6f8ff;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  width: 60%;
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const BtnWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    background-color: #4748ec;
    border-radius: 10px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #ffffff;
    margin-right: 5px;
    padding: 1rem 2rem;
    @media (max-width: 425px) {
      font-size: 8px;
    }
  }
`;
