import { East, SendOutlined } from '@mui/icons-material';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { routePaths } from '@/infrastructure/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ShareProfileMobileCard = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Card sx={{ bgcolor: 'secondary.dark' }} variant='elevation'>
      <CardActionArea onClick={() => history.push(routePaths.SHARE_PROFILE)}>
        <Box display='flex' flexDirection='column' px={2} pt={2} pb={1}>
          <Box display='flex'>
            <SendOutlined sx={{ width: '3.7rem', height: '3.7rem', transform: 'rotate(-30deg)' }} />
            <Box ml={1} mt={0.5}>
              <Typography variant='h3'>{t('homePage.shareProfile.title')}</Typography>
              <Typography variant='body2'>{t('homePage.shareProfile.via')}</Typography>
            </Box>
          </Box>
          <East sx={{ ml: 'auto', width: '1.6rem', height: '1.6rem' }} />
        </Box>
      </CardActionArea>
    </Card>
  );
};
