import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import leadgenTeaser from '../../../../images/leadgen-teaser.png';
import { BlueSection, Center, Heading, StyledImg, Subtext } from '../styled';

const CollectLeadsSection = () => {
  const { t } = useTranslation();

  return (
    <BlueSection>
      <Center>
        <Heading>{t('teaser.collectLeadsWithLt')}</Heading>
      </Center>

      <Box my='4rem' mx='auto' maxWidth='100rem'>
        <Grid container columnSpacing='8rem' justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6}>
            <StyledImg
              style={{ maxWidth: '25rem', display: 'block', marginLeft: 'auto' }}
              src={leadgenTeaser}
              alt='screenshot'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={'2.5rem'}>
              <Heading $small style={{ marginBottom: '.5rem' }}>
                {t('teaser.makeContacts')}
              </Heading>
              <Subtext>{t('teaser.contacts')}</Subtext>
            </Box>
            <Box>
              <Heading $small style={{ marginBottom: '.5rem' }}>
                {t('teaser.manageContacts')}
              </Heading>
              <Subtext>{t('teaser.contactsLand')}</Subtext>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </BlueSection>
  );
};

export default CollectLeadsSection;
