import vCardJS from 'vcards-js';
import { Account } from '../types/api/account.type';
import { Locale } from '../types/global';

interface Config {
  account: Partial<Account>;
  lang: Locale;
  url: false | string;
  note: false | 'met-now' | { metAt?: Date; text?: string };
  profileImage:
    | false
    | {
        base64: string;
        type: string;
      };
  exclude?: Array<
    Exclude<keyof Account, 'profileImageUrl' | 'firstName' | 'lastName'> | 'address1' | 'address2'
  >;
}

// clear string of diacritics and replace umlaute (needed as currently the umlaute are not displayed correctly e.g. when imported to outlook)
const normalizeText = (text?: string | null): string =>
  text
    ?.replace(/\u00e4/g, 'ae')
    .replace(/\u00c4/g, 'Ae')
    .replace(/\u00f6/g, 'oe')
    .replace(/\u00d6/g, 'Oe')
    .replace(/\u00fc/g, 'ue')
    .replace(/\u00dc/g, 'Ue')
    .replace(/\u00df/g, 'ss')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') || // remove diacritics
  '';

export function createVcardFormattedString(config: Config): string {
  const { account, exclude } = config;

  const vCard = vCardJS();

  //set properties
  vCard.firstName = normalizeText(account?.firstName) || '';
  vCard.lastName = normalizeText(account?.lastName) || '';

  if (!exclude?.includes('company') && account.company) {
    vCard.organization = normalizeText(account.company);
  }
  if (!exclude?.includes('position') && account.position)
    vCard.title = normalizeText(account.position);
  if (!exclude?.includes('division') && account.division) {
    if (vCard.title) vCard.title += ' | ' + normalizeText(account.division);
    else vCard.title = normalizeText(account.division);
  }

  if (!exclude?.includes('workPhone') && account.workPhone)
    vCard.workPhone = `+${account.workPhone}`;
  if (!exclude?.includes('mobilePhone') && account.mobilePhone)
    vCard.cellPhone = `+${account.mobilePhone}`;
  if (!exclude?.includes('homePhone') && account.homePhone)
    vCard.homePhone = `+${account.homePhone}`;
  const otherPhones: string[] = [];
  if (!exclude?.includes('hotlinePhone') && account.hotlinePhone)
    otherPhones.push(`+${account.hotlinePhone}`);
  if (!exclude?.includes('assistantPhone') && account.assistantPhone)
    otherPhones.push(`+${account.assistantPhone}`);
  if (!exclude?.includes('workFax') && account.workFax) vCard.workFax = `+${account.workFax}`;
  vCard.otherPhone = otherPhones;

  const workEmails: string[] = [];
  if (!exclude?.includes('email') && account.email) workEmails.push(account.email);
  if (!exclude?.includes('workEmail') && account.workEmail) workEmails.push(account.workEmail);
  if (!exclude?.includes('workEmail2') && account.workEmail2) workEmails.push(account.workEmail2);
  if (!exclude?.includes('assistantEmail') && account.assistantEmail)
    workEmails.push(account.assistantEmail);
  vCard.workEmail = workEmails;

  if (!exclude?.includes('workUrl') && account.workUrl) {
    if (config.url) {
      vCard.workUrl = account.workUrl;
    } else {
      vCard.url = account.workUrl;
    }
  }

  if (config.url) {
    if (!exclude?.includes('workUrl') && account.workUrl) {
      vCard.url = config.url;
    } else {
      vCard.workUrl = config.url;
    }
  }

  if (config.profileImage) {
    vCard.photo.embedFromString(config.profileImage.base64, config.profileImage.type);
  }

  if (!exclude?.includes('address1')) {
    const type = 'workAddress';
    // vCard[type].label =
    //   normalizeText(account.address1_label) ||
    //   (config.lang.startsWith('de') ? 'Adresse' : 'Address'); // not interpreted correctly by Outlook
    vCard[type].street = normalizeText(
      [account.address1_addressLine1 || '', account.address1_addressLine2 || '']
        .map(s => s.trim())
        .filter(Boolean)
        .join(', '),
    );
    vCard[type].city = normalizeText(account.address1_city) || '';
    vCard[type].postalCode = account.address1_postCode || '';
    vCard[type].countryRegion = normalizeText(account.address1_country) || '';
  }

  // if (!exclude?.includes('address2') && account.address2) {
  //   const type = 'homeAddress';
  //   vCard[type].label = account.addressLabel2 || 'Work Address 2';
  //   vCard[type].street = account.address2;
  //   if (!exclude?.includes('city2')) vCard[type].city = account.city2;
  //   if (!exclude?.includes('postCode2')) vCard[type].postalCode = account.postCode2;
  //   if (!exclude?.includes('country2')) vCard[type].countryRegion = account.country2;
  // }

  if (config.note === 'met-now') {
    const now = new Date();
    if (config.lang.startsWith('de')) {
      // const dateNow = new Intl.DateTimeFormat('de-DE').format(Date.now()); -> not supported by node v12
      const dateNow = `${now.getDate().toString().padStart(2, '0')}.${(now.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${now.getFullYear()}`;
      vCard.note =
        'Du hast ' +
        `${normalizeText(account.firstName)} ${normalizeText(account.lastName)}`.trim() +
        ' am ' +
        dateNow +
        ' getroffen.';
    } else {
      const dateNow = `${(now.getMonth() + 1).toString().padStart(2, '0')}/${now
        .getDate()
        .toString()
        .padStart(2, '0')}/${now.getFullYear()}`;
      vCard.note =
        'You met ' +
        `${normalizeText(account.firstName)} ${normalizeText(account.lastName)}`.trim() +
        ' on ' +
        dateNow +
        '.';
    }
  } else if (config.note && config.note?.text) {
    vCard.note = normalizeText(config.note.text);
  } else if (config.note && config.note?.metAt) {
    const datetime = config.note.metAt;
    if (config.lang.startsWith('de')) {
      const date = `${datetime.getDate().toString().padStart(2, '0')}.${(datetime.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${datetime.getFullYear()}`;
      vCard.note =
        'Du hast ' +
        `${normalizeText(account.firstName)} ${normalizeText(account.lastName)}`.trim() +
        ' am ' +
        date +
        ' getroffen.';
    } else {
      const date = `${(datetime.getMonth() + 1).toString().padStart(2, '0')}/${datetime
        .getDate()
        .toString()
        .padStart(2, '0')}/${datetime.getFullYear()}`;
      vCard.note =
        'You met ' +
        `${normalizeText(account.firstName)} ${normalizeText(account.lastName)}`.trim() +
        ' on ' +
        date +
        '.';
    }
  }

  vCard.version = '3.0';

  return vCard.getFormattedString();
}
