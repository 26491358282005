import CustomDataAddresses from './customDataAddresses';
import CustomDataFiles from './customDataFiles';
import CustomDataLinks from './customDataLinks';
import { Spacer } from './common';
import { FEATURE, THEME_LINK_EDIT_MODES } from '@/shared/constants';
import FeatureTeaser from '../teaser/featureTeaser';
import teaserImage from '../../../images/teaser/data-templates.png';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useTranslation } from 'react-i18next';
import ViewsContainer from '@/views/components/common/containers/ViewsContainer';
import withNav from '@/infrastructure/hoc/withNav';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';

const DataTemplates = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed } = useTierInfo();
  const {
    editRights: { linkEditMode },
  } = useEditRights();

  return (
    <ViewsContainer>
      {isFeatureAllowed(FEATURE.DATA_TEMPLATES) ? (
        <>
          <CustomDataAddresses />
          <Spacer size={48} />
          {linkEditMode !== THEME_LINK_EDIT_MODES.OPTIONS && (
            <>
              <CustomDataLinks />
              <Spacer size={48} />
            </>
          )}
          <CustomDataFiles />
          <Spacer size={96} />
        </>
      ) : (
        <FeatureTeaser text={t('upgradeTeaser.feature.dataTemplates')} imageSrc={teaserImage} />
      )}
    </ViewsContainer>
  );
};

export default withNav(
  DataTemplates,
  {
    tTitle: 'standardData',
  },
  {},
);
