import { useState } from 'react';
import { MdEuro } from 'react-icons/md';
import { Button, Heading } from '../../components/generic';
import { mainColor } from './constants';
import PopupMessage from './PopupMessage';
import { Image, Wrapper } from './styled';

type Props = {
  imageSrc: string;
  price: number;
  title: string;
  subTitle?: string;
  buttonText: string;
  balance: number;
};

const CreditsCard = (props: Props) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>();
  const [showFailedPopup, setShowFailedPopup] = useState<boolean>();

  const redeemCheckHandler = () => {
    if (props.balance < props.price) {
      setShowFailedPopup(true);
    } else {
      setShowSuccessPopup(true);
    }
  };

  return (
    <>
      <Wrapper>
        <Image src={props.imageSrc}></Image>
        <Heading
          weight='400'
          size='3rem'
          margin={{ top: '2rem', bottom: '2rem' }}
          color={mainColor}
          style={{ lineHeight: '2rem', display: 'flex', alignItems: 'center' }}
          fixSize
        >
          {props.price} <MdEuro />
        </Heading>
        <Heading weight='400' size='1.4rem' fixSize style={{ lineHeight: '2rem' }}>
          {props.title}
          {props?.subTitle && (
            <Heading
              weight='400'
              size='.9rem'
              margin={{ top: '0', bottom: '0' }}
              fixSize
              style={{ lineHeight: '2rem' }}
            >
              {props.subTitle}
            </Heading>
          )}
        </Heading>
        <Button
          text={props.buttonText}
          size='1.4rem'
          onClick={redeemCheckHandler}
          width='100%'
          txtColor={'#fff'}
          bgColor={mainColor}
          padding={{ all: '1rem' }}
          radius='5px'
          style={{ alignSelf: 'center', marginTop: '0' }}
        />
      </Wrapper>
      {showSuccessPopup && <PopupMessage success onClose={() => setShowSuccessPopup(false)} />}
      {showFailedPopup && <PopupMessage onClose={() => setShowFailedPopup(false)} />}
    </>
  );
};

export default CreditsCard;
