import { DebouncedTextField, PageContainer } from '@/components';
import { Box, IconButton, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SingleContactCard } from './components/SingleContactCard';
import { useEffect, useRef, useState } from 'react';
import { _getEmployees, linkCodeToAccount } from '@/infrastructure/apis/md/profiles';
import { useAuth0 } from '@auth0/auth0-react';
import { Employee } from '@/shared/types/api/employee.type';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Filter } from './components/Filter';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useTranslation } from 'react-i18next';
import { ColumnSelector } from './components/ColumnSelector';
import { useAppSelector } from '@/application/hooks';
import { updateActionPreferences } from '@/application/actions/account';
import { useDispatch } from 'react-redux';

const searchBarWrapperStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr max-content',
  alignItems: 'center',
  bgcolor: 'primary.light',
  position: 'sticky',
  mt: -3,
  pt: 3,
  pb: 3,
  mx: -2,
  px: 2,
  top: 0,
  zIndex: 2,
};

const PER_PAGE = 20;

export const ProfileList = () => {
  const [employees, setEmployees] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [filterOpened, setFilterOpened] = useState(false);
  const account = useAppSelector(state => state.account);
  const filterPreferences = account?.actionPreferences?.linkCode_Filters;
  const dontShowConfirmationDialog = account?.actionPreferences.linkCode_dontShowConfirmationDialog;
  const [options, setOptions] = useState({
    selectedColumn: 'lastName',
    searchTerm: '',
    orderBy: 'lastName',
    sort: 'asc',
    page: 0,
    withoutNfc: false,
    ...(filterPreferences ? filterPreferences : {}),
  });

  const noEmployeesFound = employees.length === 0 && !loading;

  const handleFilterChange = opts => {
    const newOptions = { ...options, ...opts };
    setOptions({ ...newOptions, page: 0 });
    const { page: _page, searchTerm: _searchTerm, ...filterOptions } = newOptions;
    dispatch(
      updateActionPreferences(getAccessTokenSilently, {
        linkCode_Filters: filterOptions,
      }),
    );
  };

  const { t } = useTranslation();

  const fakeDivRef = useRef(null);

  const { code: codeToConnect } = useParams<{ code: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleConnect = async (id: string, dontShowAgain?: boolean) => {
    if (dontShowAgain) {
      dispatch(
        updateActionPreferences(getAccessTokenSilently, {
          linkCode_dontShowConfirmationDialog: true,
        }),
      );
      localStorage.setItem('ltDontShowConfirmationDialog', 'true');
    }
    setIsSaving(true);
    try {
      await linkCodeToAccount(getAccessTokenSilently, id, codeToConnect);
      history.push({ pathname: '/md/linkcode/success' });
    } catch (error) {
      history.push({ pathname: '/md/linkcode/error', state: { codeToConnect } });
    }
    setIsSaving(false);
  };

  useDeferredLoader(loading, 'fetch-profiles');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let { data } = await _getEmployees(
        getAccessTokenSilently,
        options.searchTerm,
        options.selectedColumn,
        null,
        options.page,
        PER_PAGE,
        { orderBy: options.orderBy, sort: options.sort },
        options.withoutNfc,
      );
      const accounts = data?.data?.accounts || [];
      setEmployees(current => (options.page === 0 ? accounts : [...current, ...accounts]));
      setTotal(data?.data?.total || 0);
      setLoading(false);
    };
    fetchData();
  }, [getAccessTokenSilently, options]);

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          if (employees.length < total && !loading) {
            setOptions(prev => ({ ...prev, page: prev.page + 1 }));
          }
        }
      });
      const fakeDiv = fakeDivRef.current;
      if (fakeDiv) {
        observer.observe(fakeDiv);
        return () => {
          observer.unobserve(fakeDiv);
        };
      }
    }
  }, [employees, total, loading]);

  if (!codeToConnect) {
    return <Redirect to='/' />;
  }
  //

  return (
    <>
      <PageContainer bgcolor='primary.light' title={t('linkCode.selectProfile')} maxWidth='s'>
        <Box sx={searchBarWrapperStyles}>
          <DebouncedTextField
            size='small'
            sx={{ mr: 1 }}
            label={t('linkCode.searchProfile')}
            variant='outlined'
            value={options.searchTerm}
            delay={1000}
            onChange={value => setOptions({ ...options, searchTerm: value, page: 0 })}
          />
          <ColumnSelector
            value={options.selectedColumn}
            onChange={value => handleFilterChange({ selectedColumn: value, searchTerm: '' })}
          />

          <IconButton onClick={() => setFilterOpened(true)}>
            <FilterListIcon />
          </IconButton>
        </Box>
        <Box display='flex' flexDirection='column' gap={1.5}>
          {noEmployeesFound && (
            <Typography variant='h3' m={'auto'}>
              {t('linkCode.noProfilesFound')}
            </Typography>
          )}
          {employees?.map((employee: Employee) => (
            <SingleContactCard
              isSaving={isSaving}
              employee={employee}
              onClick={handleConnect}
              showConfirmationDialog={!dontShowConfirmationDialog}
            />
          ))}
        </Box>
        <div ref={fakeDivRef}></div>
      </PageContainer>
      <Filter
        opened={filterOpened}
        onClose={() => setFilterOpened(false)}
        options={options}
        onFilterChange={(key: keyof typeof options, value: string | boolean) =>
          handleFilterChange({ [key]: value })
        }
      />
    </>
  );
};
