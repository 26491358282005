import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import { updateEmployee } from '../../../../application/actions/md/profiles';
import InputsForm from './common/inputs-form';
import ProfilePicture from './common/images/profile-picture';
import Banner from './common/images/banner';
import LogoHeader from './common/images/logo-header';
import { Loader } from '../../../components/common';
import toast from 'react-hot-toast';
import { SettingsContainer, HeaderWrapper, HeaderName, InputsFormWrapper } from '../common';
import { Employee } from '../../../../shared/types/api/employee.type';
import RightBarTabPage, { PageType } from './common/rightBarTabPage';
import EmployeeTypeAssignment from './employeeTypeAssignment';

import EmployeeCards from './EmployeeCards';
import { Account } from '../../../../shared/types/api';
import { Overlay } from '../../../components/common/overlay';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { getMainUnitSync } from '@/shared/util';
import { Box } from '@mui/system';
import { Button, Divider, ScopedCssBaseline, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import {
  GoogleFormPrefillConfig,
  getPrefilledGoogleFormLink,
} from '@/shared/business-logic/lib/google-forms';
import { openLink } from '@/util';
import { getFullName } from '@/shared/business-logic/features/profile/getter';

interface Props {
  employee: Employee;
  onBackClick: () => void;
}

const EditEmployee = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { employee, onBackClick } = props;
  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const account = useAppSelector<Account>(state => state.account);
  const { id: adminId, theme } = account;
  const employeeMainUnitId = getMainUnitSync(employee)?.id;
  const isLoading = useAppSelector(state => state.md.isLoading);

  const [namePrefix, setNamePrefix] = useState(employee.namePrefix);
  const [firstName, setFirstName] = useState(employee.firstName);
  const [lastName, setLastName] = useState(employee.lastName);
  const nameProps = { firstName, setFirstName, lastName, setLastName, namePrefix, setNamePrefix };

  const { profileDesign } = useProfileDesignForUnits(employeeMainUnitId);

  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(
    employee.profileImageUrl || profileDesign.defaultProfileImageUrl,
  );
  const profileImageProps = {
    profileImageUrl,
    setProfileImageUrl,
    setProfileImageFile: withUnsavedSetter(setProfileImageFile),
  };

  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(
    employee.bannerImageUrl || profileDesign.defaultBannerImageUrl,
  );
  const bannerImageProps = {
    defaultBannerImageUrl: profileDesign.defaultBannerImageUrl,
    bannerImgOptions: profileDesign.bannerImgOptions,
    bannerImageUrl,
    setBannerImageUrl,
    setBannerImageFile: withUnsavedSetter(setBannerImageFile),
  };

  const [logoHeaderFile, setLogoHeaderFile] = useState(null);
  const [logoHeaderUrl, setLogoHeaderUrl] = useState(
    profileDesign.logoHeaderUrl || employee.logoHeaderUrl,
  );
  const logoHeaderProps = {
    logoHeaderUrl,
    setLogoHeaderUrl,
    setLogoHeaderFile: withUnsavedSetter(setLogoHeaderFile),
  };

  const [defaultLinkId, setDefaultLinkId] = useState(employee.defaultLinkId);
  const [userLinks, setUserLinks] = useState([...employee.links]);
  const userLinksProps = {
    userLinks,
    setUserLinks: withUnsavedSetter(setUserLinks),
    defaultLinkId,
    setDefaultLinkId: withUnsavedSetter(setDefaultLinkId),
  };

  const [userFiles, setUserFiles] = useState([...employee.files]);
  const userFilesProps = { userFiles, setUserFiles: withUnsavedSetter(setUserFiles) };

  const [currentTabPage, setCurrentTabPage] = useState<PageType>('profile');

  const onSuccess = () => {
    toast.success(t('changesSaved'));
    setIsUnsaved(false);
    onBackClick();
  };

  const onFailed = () => toast.error(t('error.general'));

  const handleSave = async inputs => {
    const toUpdateLinks = userLinks;
    const toUpdateFiles = userFiles;

    const toUpdateImages = {
      profileImageFile,
      bannerImageFile,
      bannerImageUrl,
      logoHeaderFile,
      logoHeaderUrl,
    };

    const { id, username } = employee;
    const updatedAccount = { id, username, defaultLinkId, ...inputs };
    const isAdmin = id === adminId;

    dispatch(
      updateEmployee(
        isAdmin,
        updatedAccount,
        toUpdateImages,
        toUpdateLinks,
        toUpdateFiles,
        getAccessTokenSilently,
        () => onSuccess(),
        () => onFailed(),
      ),
    );
  };

  const { config: cardOrderFormConfig, loading: isCardOrderFormConfigLoading } =
    usePrivateThemeConfig<GoogleFormPrefillConfig | Record<string, never>>(
      THEME_CONFIG_KEYS.ADMIN_CARD_ORDER_GOOGLE_FORM,
    );
  const cardOderFormLink = useMemo(() => {
    if (
      !isCardOrderFormConfigLoading &&
      account &&
      cardOrderFormConfig &&
      'baseUrl' in cardOrderFormConfig &&
      'attributeMap' in cardOrderFormConfig
    ) {
      return getPrefilledGoogleFormLink(
        cardOrderFormConfig as GoogleFormPrefillConfig,
        employee,
        account.theme,
      );
    }
    return '';
  }, [account, cardOrderFormConfig, employee, isCardOrderFormConfigLoading]);

  const inputsFormProps = {
    ...nameProps,
    employee,
    userLinksProps,
    userFilesProps,
    onBackClick,
    handleSave,
  };

  return (
    <>
      {isLoading && <Loader />}
      <Overlay />
      <SettingsContainer>
        <RightBarTabPage
          currentTabPage={currentTabPage}
          setCurrentTabPage={setCurrentTabPage}
          onBackClick={() => openDialogIfUnsaved(onBackClick)}
        />
        {currentTabPage === 'profile' && (
          <InputsFormWrapper>
            <HeaderWrapper>
              <ProfilePicture {...profileImageProps} />
              <HeaderName> {getFullName({ namePrefix, firstName, lastName })}</HeaderName>
            </HeaderWrapper>
            <hr />

            <Banner {...bannerImageProps} />

            <hr />
            <LogoHeader notEditable={!!profileDesign.logoHeaderUrl} {...logoHeaderProps} />
            <hr />
            <InputsForm {...inputsFormProps} employeeMainUnitId={employeeMainUnitId} />
          </InputsFormWrapper>
        )}
        {currentTabPage === 'account' && (
          <EmployeeTypeAssignment
            employee={employee}
            onBackClick={onBackClick}
            onSaveSuccess={() => {
              onBackClick();
            }}
          />
        )}
        {currentTabPage === 'Card' && (
          <>
            {(isCardOrderFormConfigLoading || cardOderFormLink) && (
              <ScopedCssBaseline>
                <Box display='flex' alignItems='center' justifyContent='center' my={4}>
                  <Button
                    variant='text'
                    endIcon={<ArrowForwardIcon />}
                    onClick={
                      cardOderFormLink ? () => openLink(cardOderFormLink, '_blank') : () => {}
                    }
                  >
                    <Typography
                      color='text.primary'
                      sx={{ textTransform: 'capitalize', fontSize: '1.6rem' }}
                    >
                      {t('toPaperCardOrderForm')}
                    </Typography>
                  </Button>
                </Box>
                <Divider />
              </ScopedCssBaseline>
            )}
            <EmployeeCards
              employee={employee}
              onBackClick={onBackClick}
              themeDomain={theme?.domain}
            />
          </>
        )}
      </SettingsContainer>
    </>
  );
};

export default EditEmployee;
