import { useAuth0 } from '@auth0/auth0-react';
import { FEATURE } from '../../../../shared/constants';
import { CSS_VARS, LOCAL_STORAGE, routePaths } from '../../../../infrastructure/constants';
import { useAppSelector } from '../../../../application/hooks';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import {
  Account,
  ApideckConnectionState,
  ApideckConsumer,
  LeadSortOption,
  MDLead,
  SyncType,
} from '../../../../shared/types/api';
import toast from 'react-hot-toast';
import withNav from '../../../../infrastructure/hoc/withNav';
import { Loader } from '../../../components/common';
import { useTranslation } from 'react-i18next';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import teaserImage from '../../../images/teaser/leadgen-md.png';
import { ExportButton as ExportButtonComp } from './components/ExportButton';
import FeatureTeaser from '../teaser/featureTeaser';
import styled from 'styled-components';
import { useCallback, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Paper,
  TablePagination,
  Theme,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import {
  deleteMdLeads,
  exportLeadsToCSV,
  exportLeadsToCrm,
  getMdLeads,
} from '@/infrastructure/apis/leadGen';
import { useState, useEffect } from 'react';
import { AddOutlined, Close, DeleteOutline, InfoOutlined, TuneOutlined } from '@mui/icons-material';
import useGenericSelection from '@/infrastructure/hooks/useGenericSelection';
import { SearchField } from '../../lead-gen/MyLeads/components/SearchField';
import {
  initMdColumnsDef,
  resetMdCols,
  saveContactsTableColumns,
} from '../../lead-gen/MyLeads/utils/columns';
import { useDispatch } from 'react-redux';
import { updateActionPreferences } from '@/application/actions/account';
import { Table } from '../../lead-gen/MyLeads/components/Table';
import ColSortMenu from '../profiles/colSortMenu';
import { SelectedCountCounter } from '../../lead-gen/MyLeads/components/SelectedCountCounter';
import { ColumnDefinition } from '../profiles/table/utils/constants';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { MDLeadFormDrawer } from './components/MDLeadFormDrawer';
import { LtDialog } from '@/components';
import { useHistory } from 'react-router-dom';
import { useConnectorsFetch } from '../../CrmIntegration/hooks/useConnectorsFetch';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { getConsumer } from '@/infrastructure/apis/md/crmIntegration';
import useConfirm from '@/infrastructure/hooks/useConfirm';
import { NoLeads } from '../../lead-gen/MyLeads/components/NoLeads';

const ROWS_PER_PAGE = [25, 50, 100];
const DEFAULT_PER_PAGE = 25;

const MdContacts = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  if (loading) {
    return <Loader />;
  }

  if (isFeatureAllowed(FEATURE.LEAD_GEN_MD)) {
    return <FeaturePage />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.leadgenMd')} imageSrc={teaserImage} />;
  }
};

export default withNav(
  MdContacts,
  {
    tTitle: 'contacts',
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
  },
);

const FeaturePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useAppTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [leads, setLeads] = useState<MDLead[]>([]);

  const [leadIds, setLeadIds] = useState<{ id: number }[]>([]);

  const [total, setTotal] = useState(0);
  const [leadsLoading, setLeadsLoading] = useState(true);
  const [selectAllVisible, setSelectAllVisibility] = useState(false);
  const [selectedLeadForEdit, setSelectedLeadForEdit] = useState<MDLead>(null);
  const [addEditDrawerOpened, setAddEditDrawerOpened] = useState(false);
  const [focusNotes, setFocusNotes] = useState(false);
  const [bulkDeletePopupOpened, setBulkDeletePopupOpened] = useState(false);
  const [isBulkDeleteLoading, setIsBulkDeleteLoading] = useState(false);

  const [searchWord, setSearchWord] = useState('');

  const account: Account = useAppSelector(state => state.account);
  const optionsPreferences = account?.actionPreferences?.mdLeads_options;

  const [options, setOptions] = useState<LeadSortOption>({
    orderBy: 'createdOn',
    sort: 'DESC',
    ...(optionsPreferences || {}),
  });

  const sortOptions = useMemo(() => {
    return {
      orderBy: options.orderBy,
      sort: options.sort,
    };
  }, [options]);
  const [apideckConsumer, setApideckConsumer] = useState<ApideckConsumer>(null);

  const { config: allowedKeys, loading: allowedKeysLoading } =
    usePrivateThemeConfig<string[]>('lead-details-keys');

  const loading = allowedKeysLoading || leadsLoading;

  useEffect(() => {
    if (!allowedKeys) return;
    setColumns(initMdColumnsDef(allowedKeys));
  }, [allowedKeys]);

  const [columns, setColumns] = useState([]);

  const visibleColumns = columns.filter(x => !x.hidden);

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const history = useHistory();
  const mountedRef = useRef(false);

  const dispatch = useDispatch();

  const fetchLeads = useCallback(async () => {
    try {
      const result = await getMdLeads(getAccessTokenSilently, {
        pageSize: rowsPerPage,
        page,
        searchWord,
        ...options,
      });
      setLeads(result.leads);
      setLeadIds(result.leadIds);
      setTotal(result.total);
    } catch (error) {
      toast.error(t('error.general'));
    }
  }, [getAccessTokenSilently, page, t, rowsPerPage, searchWord, options]);

  useEffect(() => {
    const fetch = async () => {
      setLeadsLoading(true);
      await fetchLeads();
      mountedRef.current = true;
      setLeadsLoading(false);
    };
    fetch();
  }, [fetchLeads]);

  const { connectors: crmConnectors } = useConnectorsFetch();

  const callableConnections = crmConnectors.filter(connector =>
    apideckConsumer?.apideckConnections?.some(
      c => c.seviceId === connector.id && c.enabled && c.state === ApideckConnectionState.CALLABLE,
    ),
  );
  const hasCallableApideckConnection = !!callableConnections.length;
  const connectionsToList = hasCallableApideckConnection ? callableConnections : crmConnectors;

  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');

  useEffect(() => {
    if (!flag_crmIntegration) return;
    const fetchConsumer = async () => {
      try {
        const { data } = await getConsumer(getAccessTokenSilently);
        setApideckConsumer(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchConsumer();
  }, [flag_crmIntegration, getAccessTokenSilently]);

  const {
    selectAllItems,
    selectItem,
    selectedCount,
    isAllSelected,
    selectedItems,
    unselectAllItems,
    selectAllOnPage,
    unselectAllOnPage,
    isAnySelectedOnCurrentPage,
  } = useGenericSelection(leadIds, leads);

  const handleCheckboxClick = (lead: MDLead) => {
    selectItem(lead);
    setSelectAllVisibility(false);
  };

  const handleHeaderCheckboxClick = () => {
    if (isAnySelectedOnCurrentPage) {
      unselectAllOnPage();
      setSelectAllVisibility(false);
      return;
    }
    selectAllOnPage();
    if (selectedCount < total) {
      setSelectAllVisibility(true);
    }
  };

  const handleSelectAll = () => {
    selectAllItems();
    setSelectAllVisibility(false);
  };

  const handleSortChange = (newOptions: LeadSortOption) => {
    if (newOptions.orderBy === sortOptions.orderBy && newOptions.sort === sortOptions.sort) return;
    setPage(0);
    setOptions({ ...options, ...newOptions });
    dispatch(
      updateActionPreferences(getAccessTokenSilently, {
        mdLeads_options: { ...options, ...newOptions },
      }),
    );
  };

  const handleSearch = (value: string) => {
    setLeadsLoading(true);
    setPage(0);
    setSearchWord(value);
  };

  const handleColumnChange = (newCols: ColumnDefinition[]) => {
    setColumns(newCols);
    saveContactsTableColumns(newCols, LOCAL_STORAGE.ltMdContactsTableColumns);
  };

  const handleDrawerClose = () => {
    setAddEditDrawerOpened(false);
    setFocusNotes(false);
    setSelectedLeadForEdit(null);
  };

  const addUpdateCB = () => {
    setIsUnsaved(false);
    fetchLeads();
    if (hasCallableApideckConnection && apideckConsumer.syncType === SyncType.AUTOMATIC) {
      setTimeout(() => {
        fetchLeads();
      }, 3000);
    }
  };

  const handleEditClick = (leadId: number, focusNotes?: boolean) => {
    const lead = leads.find(x => x.id === leadId);
    setSelectedLeadForEdit(lead);
    setAddEditDrawerOpened(true);
    setFocusNotes(Boolean(focusNotes));
  };

  const bulkdDeleteLeads = async (ids: number[]) => {
    try {
      await deleteMdLeads(getAccessTokenSilently, ids);
      await fetchLeads();
      setAddEditDrawerOpened(false);
      toast.success(t('mdLeads.deleteSuccess'));
    } catch (error) {
      toast.error(t('mdLeads.deleteError'));
    }
  };

  const handleDeleteLead = (leadId: number) => bulkdDeleteLeads([leadId]);

  const handleBulkDelete = async () => {
    setIsBulkDeleteLoading(true);
    await bulkdDeleteLeads(selectedItems.map(x => x.id));
    setIsBulkDeleteLoading(false);
    setBulkDeletePopupOpened(false);
  };

  const addContactBtn = (
    <Button startIcon={<AddOutlined />} onClick={() => setAddEditDrawerOpened(true)}>
      {t('addContact')}
    </Button>
  );

  const handleCsvExport = useCallback(() => {
    if (selectedCount < 1) {
      toast.error(t('mdLeads.crmExport.emptySelection'));
      return;
    }
    const promise = exportLeadsToCSV(getAccessTokenSilently, {
      leadIds: (selectedItems || []).map(l => l.id),
    });
    toast.promise(promise, {
      loading: t('downloading'),
      success: res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('id', 'company-contacts');
        link.setAttribute('download', 'company-contacts.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return t('successfullyDownloaded');
      },
      error: t('error.general'),
    });
  }, [selectedCount, selectedItems, getAccessTokenSilently, t]);

  const handleCrmExport = useCallback(
    async (serviceId: string, serviceName: string) => {
      if (!hasCallableApideckConnection) {
        history.push(routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION);
        return;
      }

      const leads = selectedItems;
      if (!leads || leads.length === 0) {
        toast.error(t('mdLeads.crmExport.emptySelection'));
        return;
      }

      try {
        await exportLeadsToCrm(getAccessTokenSilently, {
          leadIds: leads.map(l => l.id),
          serviceId,
        });
        fetchLeads();
        setTimeout(() => {
          fetchLeads();
        }, 3000);

        toast.success(t('mdLeads.crmExport.startSuccess'));
      } catch (error) {
        toast.error(t('mdLeads.crmExport.startFail'));
        console.log(error);
      }
    },
    [fetchLeads, selectedItems, getAccessTokenSilently, hasCallableApideckConnection, history, t],
  );

  const { withConfirm: withCrmExportConfirm, ConfirmDialog: CrmExportConfirmDialog } = useConfirm(
    (serviceId: string, serviceName: string) => ({
      title: t('mdLeads.crmExport.confirmDialog.title'),
      titleIcon: <InfoOutlined />,
      children: isAllSelected
        ? t('mdLeads.crmExport.confirmDialog.textAll', { name: serviceName })
        : t('mdLeads.crmExport.confirmDialog.text', {
            count: selectedCount,
            name: serviceName,
          }),
    }),
    () => selectedCount > 0 && hasCallableApideckConnection,
  );

  const ExportButton = useCallback(
    () => (
      <ExportButtonComp
        onCsvExportClick={handleCsvExport}
        onCrmExportClick={withCrmExportConfirm(handleCrmExport)}
        crmList={connectionsToList}
      />
    ),
    [connectionsToList, handleCrmExport, handleCsvExport, withCrmExportConfirm],
  );

  const filtersApplied = !!searchWord;

  if (loading && !filtersApplied && !mountedRef.current) return null;

  const noLeadsAreAdded = !loading && total === 0 && !filtersApplied;

  return (
    <>
      {noLeadsAreAdded ? (
        <NoLeads actions={[addContactBtn]} />
      ) : (
        <Box p={2} sx={{ height: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})` }}>
          <Paper
            variant='outlined'
            sx={{
              display: loading && !filtersApplied && !mountedRef.current ? 'none' : 'flex',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <StyledToolbar sx={{ py: '1.6rem' }}>
              <SearchField sx={{ flexGrow: 1 }} onSearch={handleSearch} />
              <Box sx={{ flexGrow: 1 }} />
              <ExportButton />
              {addContactBtn}
            </StyledToolbar>
            <StyledToolbar
              sx={(theme: Theme) => ({
                bgcolor: alpha(theme.palette.primary.main, 0.04),
              })}
            >
              {selectedCount > 0 && (
                <>
                  <SelectedCountCounter
                    total={total}
                    selectAllVisible={selectAllVisible}
                    onSelectAll={handleSelectAll}
                    selectedCount={selectedCount}
                  />
                  <Button
                    variant='text'
                    color='primary'
                    startIcon={<Close />}
                    onClick={unselectAllItems}
                  >
                    {t('deselectAll')}
                  </Button>

                  <Button
                    color='error'
                    variant='outlined'
                    startIcon={<DeleteOutline />}
                    onClick={() => setBulkDeletePopupOpened(true)}
                  >
                    {t('delete')}
                  </Button>
                  <ExportButton />
                </>
              )}
              <Box flexGrow={1} />
              <ColSortMenu
                toggleElement={
                  <Button startIcon={<TuneOutlined />} variant='text'>
                    {t('customizeTable')}
                  </Button>
                }
                columnDefs={columns}
                setColumnDefs={handleColumnChange}
                reset={() => handleColumnChange(resetMdCols(allowedKeys))}
              />
            </StyledToolbar>
            {Boolean(leads.length) ? (
              <>
                <Table
                  leads={leads}
                  onCheckboxClick={handleCheckboxClick}
                  onHeaderCheckboxClick={handleHeaderCheckboxClick}
                  selectedItems={selectedItems}
                  isAllSelected={isAllSelected}
                  isAnySelectedOnCurrentPage={isAnySelectedOnCurrentPage}
                  onDelete={handleDeleteLead}
                  onLeadEditClick={handleEditClick}
                  columns={visibleColumns}
                  sortOptions={sortOptions}
                  onSortOptionsChange={handleSortChange}
                  crmConnectors={crmConnectors}
                />
                <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE}
                  component='div'
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={t('rowsPerPage')}
                  onPageChange={(_, page) => setPage(page)}
                  onRowsPerPageChange={({ target: { value } }) => {
                    setRowsPerPage(+value);
                    setPage(0);
                  }}
                />
              </>
            ) : (
              <Typography variant='h2' textAlign='center' py={5}>
                {t('leads.noLeadsSearchResult')}
              </Typography>
            )}
          </Paper>
        </Box>
      )}
      <MDLeadFormDrawer
        opened={addEditDrawerOpened}
        onClose={handleDrawerClose}
        lead={selectedLeadForEdit}
        account={account}
        onSave={addUpdateCB}
        focusNotes={focusNotes}
        onDelete={handleDeleteLead}
      />

      <LtDialog
        title={t('requestDelete')}
        open={bulkDeletePopupOpened}
        onClose={() => setBulkDeletePopupOpened(false)}
        onCancel={() => setBulkDeletePopupOpened(false)}
        onDelete={handleBulkDelete}
        loading={isBulkDeleteLoading}
      >
        {t('deleteLeadsMsg', { count: selectedCount })}
      </LtDialog>
      {CrmExportConfirmDialog}
    </>
  );
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  '&.MuiToolbar-root': {
    gap: '1.6rem',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.light,
    },
  },
}));
