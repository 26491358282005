import TooltipInfo from '@/views/components/generic/TooltipInfo';
import { Card, CardContent, Divider, Typography } from '@mui/material';

const StatsCard = ({ title, body, tooltip }: { [k in 'title' | 'body' | 'tooltip']?: string }) => (
  <Card sx={{ borderRadius: '1rem', flexBasis: '100%' }}>
    <CardContent
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          wordBreak: 'break-word',
          hyphens: 'auto',
        }}
      >
        {title || null}
      </Typography>
      {tooltip ? (
        <TooltipInfo text={tooltip} icon={{ help: true, color: '#111456', size: 24 }} />
      ) : null}
    </CardContent>
    <Divider />
    <CardContent>
      <Typography variant='h1'>{body || null}</Typography>
    </CardContent>
  </Card>
);

export default StatsCard;
