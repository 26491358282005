import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuth0TokenScopeString } from '@/util';

export const resendVerificationEmail = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.get(config.API_BASE_URL + 'account/resend-verification-email', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
