// Global constants: used in the fe and the api

export * from './analytics.constants';
export * from './theme.constants';
export * from './referrals.constants';
export * from './error.constants';

export const THEME_LEADS_DEFAULT_PAGE_SIZE = 20;

export const THEME_LEADS_PAGE_SIZE = 20;

export const DEFAULT_AUTH_CONNECTION = 'Username-Password-Authentication';

export const DESKTOP_BREAK_POINT = 768;
export const SIDEBAR_COLLAPSE_BREAKPOINT = 1124;

export const COMPANY_SIZES = ['1-19', '20-49', '50-99', '100-249', '250-499', '500-999', '1000+'];

export const USAGE_GOALS = ['personal', 'company'];

export const POTENTIAL_USER_SIZES = COMPANY_SIZES;

export const TRIAL_MAX_USERS = 99;

export const INVITE_EMAILS_MAX_NUM = 5;

export const TRIAL_LENGTH_DAYS = 14;
