import { useRef } from 'react';
import { SubsectionSpan } from '../common';
import { ColorDot, Option, OptionContainer } from '../button-colors';

export const ColorPicker = ({ title, value, onChange }) => {
  const inputRef = useRef(null);
  return (
    <OptionContainer>
      <Option onClick={() => inputRef?.current?.click()}>
        <SubsectionSpan>{value}</SubsectionSpan>
        <ColorDot bg={value} />
        <input type='color' onChange={e => onChange(e.target.value)} value={value} ref={inputRef} />
      </Option>
      <SubsectionSpan style={{ marginTop: '.5rem' }}>{title}</SubsectionSpan>
    </OptionContainer>
  );
};
