import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Badge } from './Badge';

interface Props {
  condition: boolean;
  badge?: string;
}

export default function TeaserBlur(props: PropsWithChildren<Props>) {
  if (props.condition) {
    return (
      <div style={{ position: 'relative' }}>
        {props.badge && <StyledBadge text={props.badge} />}
        <Blur>{props.children}</Blur>
      </div>
    );
  } else {
    return <>{props.children}</>;
  }
}

const Blur = styled.div`
  position: relative;

  &::before {
    content: '';
    z-index: 5;
    display: block;
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 6px 30px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 6;
  transform: scale(1.2) rotate(-3deg);
`;
