import { LtDialog } from '@/components';
import { TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  onSubmit: (code: string) => Promise<void>;
  open: boolean;
  loading: boolean;
};

export const EnterCodePopup = ({ onClose, onSubmit, open, loading }: Props) => {
  const [value, setValue] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (open) setValue('');
  }, [open]);

  return (
    <LtDialog
      open={open}
      title={t('cards.enterCodePopup.title')}
      onCancel={onClose}
      onClose={onClose}
      confirmAction={{
        ttext: 'cards.connect',
        onClick: () => onSubmit(value),
      }}
      loading={loading}
      size='sm'
    >
      <TextField
        fullWidth
        label='Code'
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />
      <Typography variant='body2' lineHeight={1.5} mt={1}>
        {t('cards.enterCodePopup.description')}
      </Typography>
    </LtDialog>
  );
};
