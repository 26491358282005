import { Account } from '@/shared/types/api';
import tapDemoPic from '@/views/images/tap-demo.png';
import { TFunction } from 'react-i18next';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Helmet } from 'react-helmet';

// TODO: profile page optimizations

interface Props {
  bio: Partial<Record<keyof Account, string>>;
}

export default function MetaTags({ bio }: Props) {
  const { t } = useAppTranslation();
  return (
    <Helmet>
      <meta
        name='robots'
        content={process.env.REACT_APP_PRODUCTION === 'true' ? 'noindex' : 'noindex,nofollow'}
      />

      {/** Primary meta tags */}
      <title>{`${bio?.firstName} ${bio?.lastName}`}</title>
      <meta name='title' content={`${bio?.firstName} ${bio?.lastName}`} />
      <meta name='description' content={getMetaDescr(bio, t)} />

      {/** Open Graph/Facebook */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={window.location.href} />
      <meta property='og:title' content={`${bio?.firstName} ${bio?.lastName}`} />
      <meta property='og:description' content={getMetaDescr(bio, t)} />
      <meta property='og:image' content={tapDemoPic} />

      {/** Twitter */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={window.location.href} />
      <meta property='twitter:title' content={`${bio?.firstName} ${bio?.lastName}`} />
      <meta property='twitter:description' content={getMetaDescr(bio, t)} />
      <meta property='twitter:image' content={tapDemoPic} />
    </Helmet>
  );
}

const getMetaDescr = (bio: Props['bio'], t: TFunction) => {
  let start: string;
  if (bio?.position && bio?.company) {
    start = `${bio.position}, ${bio.company}. `;
  } else if (bio?.position) {
    start = bio.position + '. ';
  } else if (bio?.company) {
    start = bio.company + '. ';
  }

  return `${start || ''}${t('metaTagProfileDescription')}`;
};
