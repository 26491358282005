import { JsonToMjml } from 'easy-email-core';
import mjml from 'mjml-browser';
import { emailTemplateMergeTags } from './constants';
import { EmailTemplatesEnum } from '@/shared/constants';
import { TFunction } from 'react-i18next';

export const keyToLabel = (key: EmailTemplatesEnum) =>
  key
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const getHtml = values => {
  const html = mjml(
    JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      // dataSource: mergeTags,
    }),
    {
      beautify: true,
      validationLevel: 'soft',
    },
  ).html;

  return html;
};

export const getMergeTags = (emailTemplate: EmailTemplatesEnum) => {
  const result = {};
  emailTemplateMergeTags[emailTemplate]?.forEach(tag => {
    result[tag] = '';
  });
  return result;
};

export type FontType = {
  name: string;
  href: string;
};

export const getUniqueFonts = (elems: FontType[]) =>
  elems.filter((elem, index, self) => {
    return index === self.findIndex(t => t.href === elem.href && t.name === elem.name);
  });

export const isEqual = <T>(arr1: T[], arr2: T[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const elem1 = arr1[i];
    const elem2 = arr2[i];

    for (let prop in elem1) {
      if (elem1[prop] !== elem2[prop]) {
        return false;
      }
    }
  }

  return true;
};

export const applyTooltips = (t: TFunction) => {
  requestAnimationFrame(() => {
    const tabItems = document.querySelectorAll('.easy-email-editor-tabItem');
    tabItems[0]?.setAttribute('title', t('emailTemplates.editView'));
    tabItems[1]?.setAttribute('title', t('emailTemplates.desktopPreview'));
    tabItems[2]?.setAttribute('title', t('emailTemplates.mobilePreview'));
    const middleContainer = document.querySelector('.arco-layout .arco-layout') as HTMLElement;
    if (middleContainer) {
      middleContainer.style.overflowX = 'auto';
    }
  });
};

export const hideArcoNavbar = () => {
  requestAnimationFrame(() => {
    const elems = document.querySelectorAll('.arco-tabs-header-nav') as NodeListOf<HTMLElement>;
    if (elems)
      elems.forEach(elem => {
        elem.style.display = 'none';
      });
  });
};

export const containsSetPasswordUrlPlaceholder = (html: string) => {
  const regex = /\$SET_PASSWORD_URL\$/;
  return regex.test(html);
};
