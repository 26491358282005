import styled from 'styled-components';

export const DivisionTitle = styled.div`
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 15px;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.isWrap && 'flex-wrap: wrap'};
  ${props => props.isVerticalCenter && 'align-items: center'};
  ${props => props.spaceBetween && 'justify-content: space-between'};
`;

export const Container = styled.div`
  max-width: 109rem;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const Line = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  ${props => !props.noLine && 'border-bottom-width: 1px'};
  border-bottom-color: '#B9B9B9';
  cursor: pointer;
  font-size: 14px;
`;
export const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

export const VideoContainer = styled.div`
  width: 35rem;
  background-color: #e0e0ff;
  border-radius: 15px;
  margin-bottom: 2rem;
  position: relative;
  padding: 20px;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IndexText = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
`;
export const TitleText = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
`;
export const StyledButton = styled.div`
  color: white;
  background: #4748ec;
  align-self: flex-start;
  padding: 1rem;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border: none;
  border-radius: 1rem;
  &:hover {
    color: white;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const WhiteCircle = styled.div`
  background-color: white;
  border-radius: 10px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 1500;

  width: 90vw;
  max-width: 100rem;
  height: calc(90vw / (1920 / 1080));
  max-height: calc(100rem / (1920 / 1080));

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ContentWrapper = styled.div`
  overflow: auto;
  flex: 1;
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 10;
  cursor: pointer;
`;
