import { useTranslation } from 'react-i18next';
import { SubsectionSpan } from './common';
import StaticPreviewDE from '../../../images/static-preview-de.png';
import StaticPreviewEN from '../../../images/static-preview-en.png';
import { styled } from '@mui/material';

interface Props {
  image?: Record<string, string>;
}

const SmartphonePreview = ({ image }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledWrapper>
      <SubsectionSpan marginBottom>{t('profilePreview')}</SubsectionSpan>
      <Smartphone>
        <ImageContainer>
          {i18n.language === 'de' ? (
            <img src={image?.de ?? StaticPreviewDE} alt='static-preview' />
          ) : (
            <img src={image?.en ?? StaticPreviewEN} alt='static-preview' />
          )}
        </ImageContainer>
      </Smartphone>
    </StyledWrapper>
  );
};

export default SmartphonePreview;

const StyledWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
});

const Smartphone = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '26rem',
  height: '55rem',
  maxHeight: 'fit-content',
  backgroundColor: 'white',
  border: '2px solid #8a8a8a',
  borderRadius: '4rem',
});

const ImageContainer = styled('div')({
  width: '90%',
  height: '90%',
  overflow: 'auto',

  img: {
    objectFit: 'contain',
    width: '100%',
  },
});
