import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../../application/hooks';

import { Loader } from '../../../components/common';

import ProfilePicture from '../../md/profiles/common/images/profile-picture';
import { InputRestrictions, Row, TextField } from '../../../components/generic';
import { Account } from '../../../../shared/types/api';
import { useTranslation } from 'react-i18next';

import { isEmptyObject, toOnlyNumbersAndSpacesAndDashes } from '../../../../infrastructure/helper';
import { Spacer } from '../../md/customization/common';
import { useDispatch } from 'react-redux';
import { operations } from '../../../../application/actions/onboarding';
import { useAuth0 } from '@auth0/auth0-react';
import { uploadImage } from '../../../../application/actions/edit-profile';
import useBlockedFields from '../../../../infrastructure/hooks/useBlockedFields';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { DESKTOP_BREAK_POINT } from '@/shared/constants';
import { checkAccountFieldsLength } from '@/shared/util';
import { getAccountLengthError } from '../../md/profiles/import-error-handler';
import { Toaster, toast } from 'react-hot-toast';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { ContinueButton } from './common';

interface Props {
  next: () => void;
  buttonTitle: string;
}

export const Screen1 = (props: Props) => {
  const { isMinEssentials } = useTierInfo();
  const { getAccessTokenSilently } = useAuth0();
  const { disableFields, checkDisabledField } = useBlockedFields();

  const { isLoading } = useAppSelector(state => state.onboarding);
  const account: Account = useAppSelector(state => state.account);
  const [firstName, setFirstName] = useState<string>(account.firstName);
  const [lastName, setLastName] = useState<string>(account.lastName);
  const [company, setCompany] = useState<string>(account.company);
  const [workUrl, setWorkUrl] = useState<string>(account.workUrl);

  const [email, setEmail] = useState<string>(account.email);
  const [mobilePhone, setMobilePhone] = useState<string>(account.mobilePhone);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [position, setPosition] = useState<string>(account.position);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (profileImageFile) {
      dispatch(uploadImage(account.username, profileImageFile, 'profile', getAccessTokenSilently));
    }
  }, [account.username, dispatch, getAccessTokenSilently, profileImageFile]);

  const {
    profileDesign: { defaultProfileImageUrl },
  } = useProfileDesign();

  const [profileImageUrl, setProfileImageUrl] = useState(
    { ...account, status: 'active' }.profileImageUrl || defaultProfileImageUrl,
  );
  const profileImageProps = { profileImageUrl, setProfileImageUrl, setProfileImageFile };
  const handleSubmit = async () => {
    if (!firstName || !lastName || (isMinEssentials && !company)) {
      return;
    }
    const accountObject = {
      firstName: firstName,
      lastName: lastName,
      position: position,
      company: company,
      email: email,
      mobilePhone: mobilePhone,
      workUrl: workUrl,
    };

    // check for account fields length rule
    const lengthError = checkAccountFieldsLength(accountObject);
    if (!isEmptyObject(lengthError)) {
      const errorText = getAccountLengthError(lengthError);
      toast.error(errorText);
      return;
    }

    await dispatch(operations(accountObject, getAccessTokenSilently));
    props.next();
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Toaster />
      <Spacer size={20} />
      <ProfilePicture {...profileImageProps} />
      <Spacer size={4} />
      <Wrapper>
        <Container onSubmit={e => e.preventDefault()}>
          <>{isLoading && <Loader />}</>
          <Field>
            <StyledRow>
              <TextField
                name='firstname'
                type='text'
                placeholder={t('firstName')}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                maxLength={70}
                isRequired
                inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
                padding={{ left: '0' }}
                disabled={checkDisabledField('firstName', [disableFields])}
              />
              <TextField
                name='lastname'
                type='text'
                placeholder={t('lastName')}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                maxLength={70}
                isRequired
                inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
                padding={{ left: '0' }}
                disabled={checkDisabledField('lastName', [disableFields])}
              />
            </StyledRow>
          </Field>
          <Spacer size={15} />
          <Field>
            <TextField
              name='position'
              type='text'
              placeholder={t('position')}
              value={position}
              onChange={e => setPosition(e.target.value)}
              maxLength={200}
              padding={{ left: '0' }}
              disabled={checkDisabledField('position', [disableFields])}
            />
          </Field>
          <Field>
            <TextField
              name='company'
              type='text'
              placeholder={t('company')}
              value={company}
              multiline={true}
              onChange={e => setCompany(e.target.value)}
              maxLength={70}
              isRequired={isMinEssentials}
              padding={{ left: '0' }}
              disabled={checkDisabledField('company', [disableFields])}
            />
          </Field>
          <FieldContainer>
            <Field>
              <TextField
                name='email'
                type='text'
                placeholder={t('email')}
                value={email}
                onChange={e => setEmail(e.target.value)}
                padding={{ left: '0' }}
                disabled={checkDisabledField('email', [disableFields])}
              />
            </Field>
            <Field>
              <TextField
                name='mobilePhone'
                type='text'
                placeholder={t('mobilePhone.text')}
                value={mobilePhone}
                prefixText='+'
                onChange={e => setMobilePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value))}
                padding={{ left: '0' }}
                disabled={checkDisabledField('mobilePhone', [disableFields])}
              />
            </Field>
            <Field>
              <TextField
                name='workUrl'
                type='text'
                placeholder={t('website')}
                value={workUrl}
                onChange={e => {
                  setWorkUrl(e.target.value);
                }}
                padding={{ left: '0' }}
                disabled={checkDisabledField('workUrl', [disableFields])}
              />
            </Field>
          </FieldContainer>
        </Container>
        <ContinueButton
          buttonTitle={t('onboarding.continue')}
          nextFunction={handleSubmit}
          disabled={!firstName || !lastName || (isMinEssentials && !company)}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-top: 25px;
  @media (max-width: 1300px) {
    width: 50vw;
  }

  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    width: 90vw;
  }
`;

const FieldContainer = styled.div`
  width: 405px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  margin-top: 25px;
  @media (max-width: 1300px) {
    width: 60vw;
  }
  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    width: 100%;
  }
`;
const Field = styled.div`
  width: 405px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  margin-bottom: -18px;
  @media (max-width: 1300px) {
    width: 60vw;
  }
  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  width: 70vw;
  flex: 1;

  @media (max-width: 1300px) {
    width: 50vw;
  }

  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    width: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin: 0.5rem 0rem !important;
`;
