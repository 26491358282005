import { useEffect } from 'react';
import toast from 'react-hot-toast';
import i18n from '../../config/i18n';

const useDeferredLoader = (controlState: boolean, id: string) => {
  useEffect(() => {
    let timeoutId = null;
    if (controlState) {
      timeoutId = setTimeout(() => toast.loading(i18n.t('Loading'), { id }), 500, id);
      return () => {
        clearTimeout(timeoutId);
        toast.dismiss(id);
      };
    } else {
      toast.dismiss(id);
    }
    return () => {
      clearTimeout(timeoutId);
      toast.dismiss(id);
    };
  }, [controlState, id]);
};

export default useDeferredLoader;
