import React from 'react';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';

import { Container } from '../../../components/generic';
import { Heading, SubHeading, BodyWrapper } from './Common';
import { tapInfo } from './Constant';
import { routePaths } from '../../../../infrastructure/constants';

import QRCode from '../../../images/qr-codeIcon.svg';
import withNav from '../../../../infrastructure/hoc/withNav';
import useDesktopView from '../../../../infrastructure/hooks/useDesktopView';
import { Spacer } from '../../md/customization/common';

const HowToTapDetails = () => {
  const { t } = useAppTranslation();

  const { desktopView } = useDesktopView();

  const { slug } = useParams<{ slug: string }>();

  const [tapDetail] = tapInfo.filter(obj => {
    return obj.slug === slug;
  });

  return (
    <Container fillHeight>
      <BodyWrapper>
        {desktopView && <Spacer size={24} />}
        {!desktopView && <Heading value={t(tapDetail?.details?.heading)} />}
        <SubHeading value={t(tapDetail?.details?.subHeading)} />
        <Description>{t(tapDetail?.details?.description)}</Description>
        <Image src={tapDetail?.details?.GifImage} alt='' />
        <Heading type='secondary' value={t('important')} />
        <Description>{t(tapDetail?.details?.importantDescription)}</Description>
        {tapDetail?.details?.importantInfoImage && (
          <>
            <Image src={tapDetail?.details?.importantInfoImage} alt='' />
            <Description>{t(tapDetail?.details?.importantDescription2)}</Description>
          </>
        )}
        {tapDetail?.details?.backUpDescriptionHeading && (
          <>
            <Icon src={QRCode} alt='' />
            <Heading type='secondary' value={t(tapDetail?.details?.backUpDescriptionHeading)} />
            <Description>{t(tapDetail?.details?.backUpDescription)}</Description>
          </>
        )}
      </BodyWrapper>
    </Container>
  );
};

export default withNav<{}>(
  HowToTapDetails,
  {
    tTitle: () => {
      const slug = window.location.pathname.split('/').at(-1);
      const [tapDetail] = tapInfo.filter(obj => {
        return obj.slug === slug;
      });
      return tapDetail?.details?.heading;
    },
    showBackButton: {
      linkTo: routePaths.HOW_TO_TAP.MAIN,
    },
  },
  {},
);

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  margin-bottom: 25px;

  color: #4b4b4b;
`;

const Image = styled.img`
  margin: 20px 40px;
  height: 250px;
  width: 250px;
  align-self: center;
`;

const Icon = styled.img`
  width: 25px;
  height: 30px;
  margin: 10px 0px;
`;
