import withNav from '../../../infrastructure/hoc/withNav';
import { routePaths } from '../../../infrastructure/constants';
import useDesktopView from '../../../infrastructure/hooks/useDesktopView';
import { PageContainer } from '@/components';
import { Box, Divider, styled } from '@mui/material';
import ShareLinkCard from './ShareLinkCard';
import WalletGenerator from '../wallet/WalletGenerator';
import NFCCard from './NFCCard';
import QRBackgroundsCard from './QRBackgroundsCard';
import ShareBar from './ShareBar';
import ShareMenu from './ShareMenu';
import { Account } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { useUnitSelectorForUidParam } from '@/infrastructure/hooks/useUnitSelectorForUidParam';
import { generateProfileLink } from '@/shared/business-logic/features/profile/getter';

const ShareProfile = () => {
  const { desktopView } = useDesktopView();

  const account: Account = useAppSelector(state => state.account);

  const { uidForProfileLink } = useUnitSelectorForUidParam();

  const profileUrl = generateProfileLink(account, { unit: uidForProfileLink });

  return (
    <PageContainer maxWidth={'m'}>
      <StyledCardsContainer>
        <WalletGenerator
          useMUIDesign
          profileUrl={profileUrl}
          uidForProfileLink={uidForProfileLink}
        />
        <ShareLinkCard profileUrl={profileUrl} />
      </StyledCardsContainer>
      {desktopView ? (
        <StyledCardsContainer mt={1.5}>
          <QRBackgroundsCard />
          <NFCCard />
        </StyledCardsContainer>
      ) : (
        <>
          <ShareMenu />
          <Divider />
          <ShareBar
            profileUrl={profileUrl}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            mt={2}
          />
        </>
      )}
    </PageContainer>
  );
};

export default withNav(
  ShareProfile,
  {
    tTitle: 'shareProfile.title',
    showBackButton: {
      linkTo: routePaths.HOME,
    },
  },
  {},
);

const StyledCardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  [theme.breakpoints.down('900')]: {
    flexDirection: 'column',
  },
}));
