import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, DivisionTitle, Line, RowDiv } from './styles';
import { IoPlayCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { Spacer } from '@/views/pages/md/customization/common';
import { VideoDisplay } from './VideoDisplay';

const FurtherExplainersContainer = ({ furtherExplainersGuides }) => {
  const { t } = useTranslation();
  const [currentVideoDetails, setCurrentVideoDetails] = useState<{ title: string; path: string }>();
  const [explainersShown, setExplainersShown] = useState(false);
  const [showVideoDisplay, setShowVideoDisplay] = useState(false);

  if (!furtherExplainersGuides || furtherExplainersGuides.length === 0) {
    return null;
  }

  return (
    <>
      <DivisionTitle
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => setExplainersShown(!explainersShown)}
      >
        {t('mdguides.videoGuides.helpFullexplanation')} <StyledCaretUp rotate={!explainersShown} />
      </DivisionTitle>
      {explainersShown && (
        <Container>
          {furtherExplainersGuides.map((video, index) => {
            return (
              <Line noLine={index === furtherExplainersGuides.length - 1}>
                <RowDiv isVerticalCenter spaceBetween>
                  <RowDiv
                    isVerticalCenter
                    onClick={() => {
                      setCurrentVideoDetails({
                        title: video.title,
                        path: video.embedUrl,
                      });
                      setShowVideoDisplay(true);
                    }}
                  >
                    <IoPlayCircleOutline size={24} />
                    <Spacer size={9} />
                    <span style={{ textDecoration: 'underline' }}>{video.title}</span>
                  </RowDiv>
                  <AlignedRowDiv isVerticalCenter style={{ fontSize: '1.4rem' }}>
                    <AiOutlineClockCircle size={14} />
                    {t('minute', { count: video.duration })}
                  </AlignedRowDiv>
                </RowDiv>
              </Line>
            );
          })}
        </Container>
      )}
      {showVideoDisplay && (
        <VideoDisplay
          onCloseClick={() => setShowVideoDisplay(false)}
          details={currentVideoDetails}
        />
      )}
    </>
  );
};

export default FurtherExplainersContainer;

const CaretUp = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='14'
    height='8'
    viewBox='0 0 14 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M1.13694 8L0 6.88219L7 0L14 6.88219L12.8631 8L7 2.23562L1.13694 8Z'
      fill='black'
    />
  </svg>
);
const StyledCaretUp = styled(CaretUp)`
  margin-left: 1rem;
  ${props => (props.rotate ? 'transform: scale(0.8) rotate(180deg);' : 'transform: scale(0.8);')}
`;

const AlignedRowDiv = styled(RowDiv)`
  min-width: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
