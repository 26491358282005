import styled from 'styled-components';
import { Button, Popup } from '../../../../../components/common';
import { useTranslation } from 'react-i18next';

interface Props {
  numSelected?: number;
  onSaveClick: () => void;
  onCloseClick: () => void;
}

const ExtendPopup = (props: Props) => {
  const { t } = useTranslation();
  const { onCloseClick, onSaveClick, numSelected } = props;

  return (
    <Popup headerText={t('extendTheFile')} onCloseClick={onCloseClick}>
      <PopupText>{t('extendFileConsentText', { numSelected })}</PopupText>
      <ButtonsContainer>
        <Button onClick={onCloseClick} noBg text={t('cancel')} />
        <Button onClick={onSaveClick} text={t('extendPopupConsentButton')} />
      </ButtonsContainer>
    </Popup>
  );
};

export default ExtendPopup;

const PopupText = styled.p`
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  /* color: #000000; */
`;

const ButtonsContainer = styled.div`
  div {
    display: inline-block;
    width: 47%;
  }
`;
