import { useTranslation } from 'react-i18next';
import { RequireOnlyOne } from '../../../../shared/types/util';
import styled from 'styled-components';

export const Badge = (
  props: RequireOnlyOne<{ text: string; tText: string; className?: string }, 'text' | 'tText'>,
) => {
  const { t } = useTranslation();
  return (
    <BadgeContainer className={props.className}>
      <BadgeSpan>{props.text || t(props.tText)}</BadgeSpan>
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #eff3fd;
  border-radius: 0.2rem;
  padding: 0.2rem 0.4rem;
  margin-left: 1rem;
`;

const BadgeSpan = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  color: #4748ec;
  letter-spacing: 0.7px;
`;
