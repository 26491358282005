import { useAppSelector } from '@/application/hooks';
import { trackPublicEvent } from '@/infrastructure/apis/analytics';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { PUBLIC_EVENTS } from '@/shared/constants';
import { Account } from '@/shared/types/api';
import ContactDetails from '@/views/pages/edit-profile/contact-details';
import { renderFiles, renderLinks } from '@/views/pages/show-profile';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardContent, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const LTProfileInfo = ({ lt2ltAccount, profileDesign }) => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const { t } = useTranslation();

  const account: Account = useAppSelector(state => state.account);

  const {
    editRights: { linkEditMode },
  } = useEditRights();

  const linkTH = useCallback(
    (linkId: number) => {
      if (lt2ltAccount) {
        trackPublicEvent(
          { username: account?.username, isAuthenticated, isAuth0Loading },
          PUBLIC_EVENTS.LINK_CLICK,
          lt2ltAccount.username,
          null,
          linkId,
        );
      }
    },
    [account, isAuth0Loading, isAuthenticated, lt2ltAccount],
  );

  const fileTH = useCallback(
    (fileId: number, pageCount: number) => {
      trackPublicEvent(
        { username: account?.username, isAuthenticated, isAuth0Loading },
        PUBLIC_EVENTS.FILE_CLICK,
        lt2ltAccount.username,
        fileId,
        null,
        pageCount ? { pageCount } : undefined,
      );
    },
    [account, isAuth0Loading, isAuthenticated, lt2ltAccount],
  );

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant='h4'>{t('leadGen.infoFromProfile')}</Typography>
        <ContactDetails genericHeader account={lt2ltAccount} boxStyle={profileDesign?.boxStyle} />
        {renderLinks(lt2ltAccount, linkTH, t, linkEditMode, profileDesign, true)}
        {renderFiles(lt2ltAccount, fileTH, t, profileDesign, true)}
      </CardContent>
    </Card>
  );
};
