import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import config from '../../../../config/config';
import { withSilentAccessToken } from '../../../helper';
import { PERMISSIONS } from '../../../constants';
import { EmailTemplateAttachments } from '@/shared/constants';

export const updateTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: {
    html: string;
    content: object;
    template: string;
    locale: string;
    subject: string;
    attachments: EmailTemplateAttachments[];
  },
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.put(config.API_BASE_URL + 'theme/email-template', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
};

export const getTemplate = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  queryParams: { template: string; locale: string },
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/email-template`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams,
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
};

export const sendTestEmail = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: {
    html: string;
    email: string;
    subject: string;
    attachments: EmailTemplateAttachments[];
  },
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(config.API_BASE_URL + `theme/email-template/send-test-email`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
};
