import { Box, Avatar, Typography, Divider, styled } from '@mui/material';

export const Lt2ltProfileHeader = ({ account, profileDesign }) => {
  const profileImage = account?.profileImageUrl || profileDesign?.defaultProfileImageUrl;

  const name = [account?.title || '', account?.firstName || '', account?.lastName || '']
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <Box display='flex' alignItems='center' gap={4} mb={3}>
        <Avatar sx={{ width: '8rem', height: '8rem' }} alt={name} src={profileImage} />

        <Box>
          <StyledTypography lineHeight={2} variant='h2'>
            {name}
          </StyledTypography>
          <StyledTypography lineHeight={1.6}>{account?.position}</StyledTypography>
          <StyledTypography lineHeight={1.6}>{account?.role}</StyledTypography>
          <StyledTypography lineHeight={1.6} sx={{ fontStyle: 'italic' }}>
            {account?.company}
          </StyledTypography>
        </Box>
      </Box>
      <Divider sx={{ width: '80%', mb: 3, mx: 'auto' }} />
    </>
  );
};

const StyledTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
});
