import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { fetchThemeConfig } from '../apis/md/customization/jsonConfigs';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { useAppTranslation } from './useAppTranslation';

// better types here!
export default function usePrivateThemeConfig<T extends Record<string, any>>(
  key: string | null,
  language?: string | null,
) {
  const { getAccessTokenSilently } = useAuth0();
  const { activeLanguage } = useAppTranslation();

  const lang = typeof language === 'string' ? language : activeLanguage;

  const [config, setConfig] = useState<T | null>(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (key && Object.values(THEME_CONFIG_KEYS).includes(key as THEME_CONFIG_KEYS)) {
      fetchThemeConfig(getAccessTokenSilently, {
        key: key as THEME_CONFIG_KEYS,
        language: lang,
      })
        .then(({ value }) => setConfig(value))
        .catch(() => setError(true));
    } else if (key) {
      setError(true);
    }
  }, [lang, getAccessTokenSilently, key]);

  return { config, error, loading: key && !config && !error };
}
