import { LeadAPI } from './lead.type';
import { ThemeInternal } from './theme.type';

export enum SyncType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum CrmExportEventStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ApideckConnectionState {
  AVAILABLE = 'available',
  ADDED = 'added',
  INVALID = 'invalid',
  AUTHORIZED = 'authorized',
  CALLABLE = 'callable',
}

export type CrmExportEvent = {
  id: number;
  leadId: number;
  lead?: LeadAPI;
  apideckConnectionId: number;
  apideckConnection?: ApideckConnection;
  createdOn: Date;
  status: CrmExportEventStatus;
  error?: Record<string, any>;
};

export type ApideckConnection = {
  id: number | string;
  apideckConsumerId: number;
  apideckConsumer?: ApideckConsumer;
  seviceId: string;
  enabled: boolean;
  crmExportEvents?: CrmExportEvent[];
  state: ApideckConnectionState;
};

export type ApideckConsumer = {
  id: number;
  themeInternalId: number;
  themInternal?: ThemeInternal;
  syncType: SyncType;
  useLeads: boolean;
  apideckConnections?: ApideckConnection[];
};

export type CrmConnectorType = {
  id: string;
  name: string;
  websiteUrl: string;
  iconUrl: string;
};
