import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { DESKTOP_BREAK_POINT } from '../../../shared/constants';

type Props = {
  children: ReactNode;
  direction?: 'column' | 'row';
  sameFlow?: boolean; // same flex direction for small screens
  style?: CSSProperties;
};

type IProps = {
  direction?: Props['direction'];
  sameFlow?: Props['sameFlow'];
};

const FlexWrapper = (props: Props) => {
  return (
    <Wrapper sameFlow={props?.sameFlow} direction={props?.direction} style={props?.style}>
      {props.children}
    </Wrapper>
  );
};

export default FlexWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props: IProps) => props?.direction ?? 'row'};
  margin-bottom: 3rem;
  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    ${(props: IProps) => !props.sameFlow && 'flex-direction: column'};
  }
`;
