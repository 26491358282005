import i18nMain from '@/config/i18n';
import { Account } from '@/shared/types/api';

export const getTranslatedField = (field: keyof Account) => {
  let _field = field.toLowerCase();
  if (_field.includes('label')) {
    return i18nMain.t('address.label');
  } else if (_field.includes('addressee')) {
    return i18nMain.t('address.addressee');
  } else if (_field.includes('addressline1')) {
    return i18nMain.t('address.addressLine1');
  } else if (_field.includes('addressline2')) {
    return i18nMain.t('address.addressLine2');
  } else if (_field.includes('postcode')) {
    return i18nMain.t('address.postCode');
  } else if (_field.includes('city')) {
    return i18nMain.t('address.city');
  } else if (_field.includes('country')) {
    return i18nMain.t('address.country');
  } else if (_field.includes('country')) {
    return i18nMain.t('address.country');
  } else if (_field.includes('phone') || _field.includes('fax') || _field.includes('assistant')) {
    return i18nMain.t(`${field}.text`);
  } else {
    return i18nMain.t(field);
  }
};

export const getHelperText = (field: keyof Account) =>
  field === 'academicDegree'
    ? i18nMain.t('academicDegreeHelper')
    : field === 'academicDegree2'
    ? i18nMain.t('academicDegree2Helper')
    : field === 'namePrefix'
    ? i18nMain.t('namePrefixHelper')
    : null;

export const isAddressField = (field: keyof Account) => {
  return field.toLowerCase().includes('address');
};

export const isAddress1Field = (field: keyof Account) => {
  return field.toLowerCase().includes('address1');
};

export const isPhoneFaxField = (field: string) => {
  let _field = field.toLowerCase();
  return _field.includes('phone') || _field.includes('fax');
};

export const isRequiredField = (field: keyof Account) =>
  field === 'firstName' || field === 'lastName';
