import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BiSearchAlt2 } from 'react-icons/bi';

interface Props {
  setParentSearchCondition: Dispatch<SetStateAction<string>>;
}
const Searchbar = (props: Props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    const timer = setTimeout(() => {
      props.setParentSearchCondition(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [initialRender, props, searchTerm]);

  return (
    <SearchContainer>
      <BiSearchAlt2 size={20} color='grey' style={{ position: 'absolute', left: 20 }} />
      <input
        name='search'
        type='text'
        placeholder={t('searchUnits')}
        style={{ paddingLeft: 60 }}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
    </SearchContainer>
  );
};

export default Searchbar;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 586px;
  position: relative;

  input[type='text'] {
    margin-bottom: 0;
    width: 100%;
  }

  .form-label {
    width: 0;
  }

  .form-input {
    width: 60%;
  }
`;
