export const shadows = [
  'none',
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
  `0px 1px 3px 0px rgba(17, 19, 86, 0.12), 0px 1px 1px 0px rgba(17, 19, 86, 0.08), 0px 2px 1px -1px rgba(17, 19, 86, 0.1)`,
] as const;
