import { useEffect, useRef, useState } from 'react';
import { MdEuro } from 'react-icons/md';
import { APIError } from '../../../shared/types/api';
import { Heading, SubText, LTCard, Section } from '../../components/generic';
import CreditsCard from './RedeemCard';
import FlexWrapper from '../../components/generic/FlexWrapper';
import CollectCard from './CollectCard';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { CREDITS_REWARDS_CLIENT } from './constants';
import { CREDITS_REWARDS, ERROR_KEYS } from '../../../shared/constants';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../../config/config';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import useDeferredLoader from '../../../infrastructure/hooks/useDeferredLoader';
import withNav from '../../../infrastructure/hoc/withNav';
import { routePaths } from '../../../infrastructure/constants';
import useDesktopView from '../../../infrastructure/hooks/useDesktopView';
import useTierInfo from '../../../infrastructure/hooks/useTierInfo';
import { useHistory } from 'react-router-dom';
import { WhitespaceContainer } from '../md/common';

const Referral = () => {
  const { t, activeLanguage } = useAppTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { desktopView } = useDesktopView();

  const [balance, setBalance] = useState<number>(0);
  const [referralEmail, setReferralEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  // const [referralLink, setReferralLink] = useState<string>();

  useDeferredLoader(loading, 'referrals-loading-toast');

  const { isTrial, isStarter } = useTierInfo();

  const history = useHistory();

  const referralUrl = useRef(null);

  useEffect(() => {
    if (!isStarter && !isTrial) {
      history.push(routePaths.HOME);
    }
  }, [history, isStarter, isTrial]);

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `account/balance/credit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [],
      () => setLoading(true),
    )
      .then((res: { data: { isSuccess: boolean; data?: { balance: number } } }) => {
        if (res.data?.isSuccess) {
          let result = res.data;
          setBalance(result.data.balance);
        }
      })
      .catch(err => {
        toast.error(t('error.general'));
      })
      .finally(() => setLoading(false));
  }, [getAccessTokenSilently, t]);

  const generateReferralLink = () => {
    if (!referralUrl.current) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `account/referral/key`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [],
        () => setLoading(true),
      )
        .then((res: { data: { isSuccess: boolean; data?: { referralUrl: string } } }) => {
          if (res.data?.isSuccess) {
            let result = res.data;
            referralUrl.current = result.data.referralUrl;
            // copy link to clipboard
            navigator.clipboard.writeText(result.data.referralUrl);
            toast.success(t('referrals.toast.linkSuccess'));
          }
        })
        .catch(err => {
          toast.error(t('error.general'));
        })
        .finally(() => setLoading(false));
    } else {
      navigator.clipboard.writeText(referralUrl.current);
      toast.success(t('referrals.toast.linkSuccess'));
    }
  };

  const handleSendEmail = (onSuccess?: () => void) => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.post(
          config.API_BASE_URL + `account/referral/key`,
          { email: referralEmail },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [],
      () => setLoading(true),
    )
      .then((res: { data: { isSuccess: boolean } }) => {
        if (res.data?.isSuccess) {
          onSuccess?.();
          toast.success(t('referrals.toast.emailSuccess'));
        }
      })
      .catch((err: APIError) => {
        const errData: { notValidEmail?: boolean } = err.response.data?.error?.data;
        if (errData.notValidEmail) {
          toast.error(t('referrals.toast.emailError'));
        } else if (err?.response?.data?.error?.code === ERROR_KEYS.DISPOSABLE_EMAIL) {
          // disposable email
          toast.error(t('error.disposableEmail'));
        } else {
          toast.error(t('error.general'));
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <WhitespaceContainer style={{ background: 'white' }}>
      <>
        <Section backgroundColor='transparent' style={{ padding: '2rem 1rem' }}>
          {!desktopView && <Heading text={t('referrals.heading')} size='2.2rem' />}
          <SubText size='1.5rem' color='#000' style={{ marginBottom: '2rem', marginTop: '2rem' }}>
            {t('referrals.subtext')}
          </SubText>
          <LTCard
            fullWidth={{
              reverse: true,
              leftContent: (
                <div>
                  <span
                    style={{
                      fontSize: '5rem',
                      color: '#1ABC9C',
                      fontWeight: '600',
                    }}
                  >
                    {balance}
                  </span>{' '}
                  <MdEuro size={20} />
                </div>
              ),
            }}
            title={{
              text: t('referrals.balance.title'),
            }}
            tooltip={{
              text: t('referrals.balance.tooltip'),
            }}
            style={{
              borderColor: '#DADAE5',
              borderWidth: '1px',
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
            transparent
          />
        </Section>
        <Section backgroundColor='transparent' style={{ padding: '2rem 1rem' }}>
          <Heading
            text={t('referrals.redeem.title')}
            size='2rem'
            margin={{ bottom: '2rem' }}
            fixSize
          />
          <FlexWrapper sameFlow style={{ overflowX: 'auto', paddingBottom: '2rem' }}>
            {CREDITS_REWARDS.map(card => (
              <CreditsCard
                imageSrc={CREDITS_REWARDS_CLIENT[card.key].image}
                price={card.credit}
                title={
                  CREDITS_REWARDS_CLIENT[card.key].text[
                    activeLanguage.startsWith('en') ? 'en' : 'de'
                  ].title
                }
                subTitle={
                  CREDITS_REWARDS_CLIENT[card.key].text[
                    activeLanguage.startsWith('en') ? 'en' : 'de'
                  ]?.subtitle
                }
                buttonText={t('referrals.redeem.button')}
                balance={balance}
              />
            ))}
          </FlexWrapper>
        </Section>
        <Section backgroundColor='transparent' style={{ padding: '2rem 1rem' }}>
          <Heading
            text={t('referrals.collect.title')}
            size='2rem'
            margin={{ bottom: '2rem' }}
            fixSize
          />
          <FlexWrapper>
            <CollectCard
              emailCard
              generateReferralLink={generateReferralLink}
              email={{ referralEmail, setReferralEmail, handleSendEmail }}
            />
            <CollectCard />
          </FlexWrapper>
        </Section>
      </>
    </WhitespaceContainer>
  );
};

export default withNav(
  Referral,
  {
    tTitle: 'referrals.heading',
    showBackButton: {
      linkTo: routePaths.HOME,
    },
  },
  {},
);
