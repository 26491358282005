import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { HierarchyLevel } from '@/shared/types/api';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
type Props = {
  onDelete: () => void;
  hierarchyLevel: HierarchyLevel;
};

export const DeleteSettingsSection = ({ onDelete, hierarchyLevel }: Props) => {
  const { t } = useTranslation();

  const flag_showGlobalReset = usePrivateFeatureFlag('themeConfigGlobalReset');
  if (!flag_showGlobalReset && hierarchyLevel !== HierarchyLevel.Unit) {
    return null;
  }

  return (
    <Box display='flex'>
      <Button
        onClick={onDelete}
        startIcon={<DeleteOutlineIcon />}
        variant='outlined'
        color='error'
        sx={{ ml: 'auto' }}
      >
        {t(hierarchyLevel === HierarchyLevel.Unit ? 'deleteSettings' : 'resetSettings')}
      </Button>
    </Box>
  );
};
