import { dispatchTypes } from './index';
import {
  saveLinkAPI,
  updateImageUrl,
  deleteLinkById,
  saveFile,
  deleteFileById,
  updateLinkDisplayStatus,
  updateLinkDefaultStatus,
  updateFileDisplayStatus,
  fetchThemeLinkTypes,
  saveLinkThemedAPI,
  saveCustomLinkThemedAPI,
  reorderLinksAPI,
  reorderFilesAPI,
  saveFileTemplate,
} from '../../infrastructure/apis/edit-profile';
import { doS3Upload, doS3UploadTemplateFile } from '../../infrastructure/apis/aws';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Account } from '../../shared/types/api';
import { SavelinkItemProps } from '../../infrastructure/apis/md/profiles/links';
import { ApiFile } from '../../shared/types/api';
import toast from 'react-hot-toast';
import i18n from '../../config/i18n';

export const clearApiResponse = () => async dispatch => {
  dispatch({ type: dispatchTypes.EDITPROFILE.CLEARAPIRESPONSE });
};

export const uploadImage =
  (
    username: string,
    file: Blob | null,
    type: 'profile' | 'banner' | 'logoHeader',
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    callback?: () => void,
  ) =>
  async dispatch => {
    try {
      dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });

      let url: string;
      if (file) {
        url = await doS3Upload(`${type}/${username}`, file, getAccessTokenSilently, type);
      } else {
        url = '';
      }

      if (url || !file) {
        const uploadResponse = await updateImageUrl(url, type, getAccessTokenSilently);
        if (uploadResponse.data.isSuccess) {
          dispatch({
            type: dispatchTypes.EDITPROFILE.HIDELOADER,
          });

          if (type === 'profile') {
            dispatch({
              type: dispatchTypes.COMMON.UPDATEFIELD,
              field: 'profileImageUrl',
              value: url,
            });
          } else if (type === 'banner') {
            dispatch({
              type: dispatchTypes.COMMON.UPDATEFIELD,
              field: 'bannerImageUrl',
              value: url,
            });
          } else if (type === 'logoHeader') {
            dispatch({
              type: dispatchTypes.COMMON.UPDATEFIELD,
              field: 'logoHeaderUrl',
              value: url,
            });
          }

          callback && callback();
        }
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.UPLOADFILEERROR, apiResponse: error });
      toast.error(i18n.t('error.uploadingImage'));
    }
  };

export const updateImage =
  (
    username: string,
    imgUrl: string,
    type: 'profile' | 'banner' | 'logoHeader',
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    callback?: () => void,
  ) =>
  async dispatch => {
    try {
      dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });

      const uploadResponse = await updateImageUrl(imgUrl, type, getAccessTokenSilently);
      if (uploadResponse.data.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.HIDELOADER,
        });

        if (type === 'profile') {
          dispatch({
            type: dispatchTypes.COMMON.UPDATEFIELD,
            field: 'profileImageUrl',
            value: imgUrl,
          });
        } else if (type === 'banner') {
          dispatch({
            type: dispatchTypes.COMMON.UPDATEFIELD,
            field: 'bannerImageUrl',
            value: imgUrl,
          });
        } else if (type === 'logoHeader') {
          dispatch({
            type: dispatchTypes.COMMON.UPDATEFIELD,
            field: 'logoHeaderUrl',
            value: imgUrl,
          });
        }
        callback && callback();
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.UPDATEIMGURLERROR, apiResponse: error });
      toast.error(i18n.t('error.uploadingImage'));
    }
  };

export const saveLink =
  (
    linkItem: SavelinkItemProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    callback: (linkId: number) => void,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await saveLinkAPI(linkItem, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        let links = result.data.sort((a, b) => b.id - a.id);
        let addedLink = links[0];
        callback && callback(addedLink.id);
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKSUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.general'), { id: linkItem.name });
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.SAVELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.general'), { id: linkItem.name });
    }
  };

export const reorderLinks =
  (links: Array<File>, getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await reorderLinksAPI(links, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.REORDERLINKSSUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.REORDERLINKSERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.REORDERLINKSERROR, apiResponse: error });
      toast.error(i18n.t('error.general'));
    }
  };

export const reorderFiles =
  (files: Array<File>, getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await reorderFilesAPI(files, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.REORDERFILESSUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'files',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.REORDERFILESERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.REORDERFILESERROR, apiResponse: error });
      toast.error(i18n.t('error.general'));
    }
  };

export const saveCustomLinkThemed =
  (
    linkTypeId: number,
    name: string,
    link: string,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await saveCustomLinkThemedAPI(linkTypeId, name, link, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKSUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.SAVELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.general'));
    }
  };

export const saveLinksThemed =
  (
    themeLinkOptionIds: Array<number>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let responses = await Promise.all(
        themeLinkOptionIds.map(id => saveLinkThemedAPI(id, getAccessTokenSilently)),
      );

      if (responses.every(res => res.data.isSuccess)) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKSUCCESS,
          apiResponse: null,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: responses[responses.length - 1].data.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKERROR,
          apiResponse: responses,
        });
        toast.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.SAVELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.general'));
    }
  };

export const updateLinkDisplay =
  (
    linkId: number,
    canDisplayOnProfile: boolean,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await updateLinkDisplayStatus(
        linkId,
        canDisplayOnProfile,
        getAccessTokenSilently,
      );

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.HIDELOADER,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.general'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.SAVELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.general'));
    }
  };

export const updateLinkDefault =
  (
    linkId: number,
    account: Account,
    isDefault: boolean,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await updateLinkDefaultStatus(linkId, isDefault, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.HIDELOADER,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEACCOUNT,
          account: {
            ...account,
            defaultLinkId: isDefault ? linkId : null,
          },
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.defaultLink'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.SAVELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.defaultLink'));
    }
  };

export const deleteLink =
  (linkId: number, getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await deleteLinkById(linkId, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.DELETELINKSUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({ type: dispatchTypes.EDITPROFILE.DELETELINKERROR, apiResponse: result });
        toast.error(i18n.t('error.deleteLink'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.DELETELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.deleteLink'));
    }
  };
export const deleteLinksThemed =
  (
    linkIds: Array<number>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let responses = await Promise.all(
        linkIds.map(id => deleteLinkById(id, getAccessTokenSilently)),
      );

      if (responses.every(res => res.data.isSuccess)) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.DELETELINKSUCCESS,
          apiResponse: null,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: responses[responses.length - 1].data.data,
        });
      } else {
        dispatch({ type: dispatchTypes.EDITPROFILE.DELETELINKERROR, apiResponse: responses });
        toast.error(i18n.t('error.deleteLink'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.DELETELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.deleteLink'));
    }
  };
export const deleteCustomLinkThemed =
  (linkId: number, getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await deleteLinkById(linkId, getAccessTokenSilently, true);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.DELETELINKSUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'links',
          value: result.data,
        });
      } else {
        dispatch({ type: dispatchTypes.EDITPROFILE.DELETELINKERROR, apiResponse: result });
        toast.error(i18n.t('error.deleteLink'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.DELETELINKERROR, apiResponse: error });
      toast.error(i18n.t('error.deleteLink'));
    }
  };

export const uploadFile =
  (
    username: string,
    file: File,
    fileName: string,
    fileLink: string | undefined,
    embed: boolean,
    pageCount: number | null,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess: () => void,
    callback?: (fileId: number) => void,
    progressCb?: (progress: number) => void,
  ) =>
  async dispatch => {
    try {
      dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });

      const url = fileLink
        ? fileLink
        : await doS3Upload(`file/${username}`, file, getAccessTokenSilently, undefined, progressCb);

      if (url) {
        const uploadResponse = await saveFile(
          fileName,
          url,
          embed,
          pageCount,
          getAccessTokenSilently,
        );
        let result = uploadResponse.data;
        if (result.isSuccess) {
          let files = result.data.sort((a, b) => b.id - a.id);
          let addedFile = files[0];
          dispatch({
            type: dispatchTypes.EDITPROFILE.UPLOADFILESUCCESS,
            apiResponse: result,
          });
          callback && callback(addedFile.id);

          dispatch({
            type: dispatchTypes.COMMON.UPDATEFIELD,
            field: 'files',
            value: result.data,
          });
          if (onSuccess) onSuccess();
        } else {
          dispatch({
            type: dispatchTypes.EDITPROFILE.UPLOADFILEERROR,
            apiResponse: result,
          });
          toast.error(i18n.t('error.uploadFile'));
        }
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.UPLOADFILEERROR,
        });
        toast.error(i18n.t('error.uploadFile'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.UPLOADFILEERROR, apiResponse: error });
      toast.error(i18n.t('error.uploadFile'));
    }
  };

export const deleteFile =
  (fileId: number, getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await deleteFileById(fileId, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.DELETEFILESUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'files',
          value: result.data,
        });
      } else {
        dispatch({ type: dispatchTypes.EDITPROFILE.DELETEFILEERROR, apiResponse: result });
        toast.error(i18n.t('error.deleteFile'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.DELETEFILEERROR, apiResponse: error });
      toast.error(i18n.t('error.deleteFile'));
    }
  };

export const uploadTemplateFile = async (
  themeUniqId: string,
  file: File,
  fileName: string,
  pageCount: number | null,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
): Promise<{ file: ApiFile; pageCount: number | null }> => {
  const url = await doS3UploadTemplateFile(`themes/${themeUniqId}`, file, getAccessTokenSilently);
  if (url) {
    const uploadResponse = await saveFileTemplate(fileName, url, pageCount, getAccessTokenSilently);
    let result = uploadResponse.data;
    if (result.isSuccess) {
      let files = result.data.sort((a, b) => b.id - a.id);
      return { file: files[0], pageCount };
    }
  }
};

export const updateFileDisplay =
  (
    fileId,
    canDisplayOnProfile,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SHOWLOADER });
    try {
      let response = await updateFileDisplayStatus(
        fileId,
        canDisplayOnProfile,
        getAccessTokenSilently,
      );

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.HIDELOADER,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEFIELD,
          field: 'files',
          value: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.UPLOADFILEERROR,
          apiResponse: result,
        });
        toast.error(i18n.t('error.uploadFile'));
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.UPLOADFILEERROR, apiResponse: error });
      toast.error(i18n.t('error.uploadFile'));
    }
  };

export const getThemeLinkTypes =
  (getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']) => async dispatch => {
    dispatch({ type: dispatchTypes.EDITPROFILE.SETLINKTYPESLOADING });
    try {
      let response = await fetchThemeLinkTypes(getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.HIDELOADER,
        });

        dispatch({
          type: dispatchTypes.EDITPROFILE.SETTHEMELINKTYPES,
          themeLinkTypes: result.data,
        });
      } else {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SETTHEMELINKTYPESERROR,
          apiResponse: result,
        });
      }
    } catch (error) {
      dispatch({ type: dispatchTypes.EDITPROFILE.SETTHEMELINKTYPESERROR, apiResponse: error });
    }
  };
