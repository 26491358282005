import { Divider, ListItemIcon, Typography } from '@mui/material';
import Switch from 'react-switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { DropResult, DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { ColumnDefinition } from './table/utils/constants';
import RestartIcon from '@mui/icons-material/RestartAlt';
import { ReactComponent as DragIndicatorIcon } from '../../../images/dragIndicatorIcon.svg';
import styled from 'styled-components';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  columnDefs: ColumnDefinition[];
  setColumnDefs: (newDef: ColumnDefinition[]) => any;
  reset: Function;
  toggleElement: React.ReactNode;
}

// TODO: make universal
export default function ColSortMenu(props: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(props.columnDefs, source.index, destination.index);
    props.setColumnDefs(newItems);
  };

  const handleToggleItem = (idx: number) => {
    props.setColumnDefs(
      props.columnDefs.map((col, i) => (i === idx ? { ...col, hidden: !col.hidden } : col)),
    );
  };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <>
      <div onClick={handleOpen}>{props.toggleElement}</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ maxWidth: '40rem' }}>
          <MenuItem
            onClick={() => {
              props.reset();
              handleClose();
            }}
          >
            <ListItemIcon>
              <RestartIcon />
            </ListItemIcon>
            {t('resetToDefault')}
          </MenuItem>
          <Divider sx={{ my: 0.5 }}></Divider>
          <Droppable droppableId='droppable-list'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {props.columnDefs.map((col, idx) => (
                  <Draggable draggableId={col.key} index={idx} key={col.key}>
                    {(provided, snapshot) => (
                      <MenuItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => handleToggleItem(idx)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <ItemToggle>
                          <StyledDragIndicatorIcon />
                          <Typography
                            variant='inherit'
                            sx={{
                              marginLeft: '.5rem',
                              marginRight: 'auto',
                            }}
                            noWrap
                          >
                            {t(col.tkey)}
                          </Typography>
                          <Switch
                            onChange={() => {}}
                            checked={!col.hidden}
                            onColor={muiTheme.palette.primary.main}
                            offColor={muiTheme.palette.grey[400]}
                          />
                        </ItemToggle>
                      </MenuItem>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </Menu>
      </DragDropContext>
    </>
  );
}

const reorder = <T extends any>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ItemToggle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const StyledDragIndicatorIcon = styled(DragIndicatorIcon)`
  flex-grow: 0;
  flex-shrink: 0;
`;
