import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import config from '../../../../config/config';
import { withSilentAccessToken } from '../../../helper';
import { PERMISSIONS } from '../../../constants';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { HierarchyLevel, NFCCard } from '@/shared/types/api';
import { APIResponse } from '@/shared/types/global/api';

export const fetchNfcCardConfig = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken<{ data: APIResponse<NFCCard[]> }>(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/nfc-cards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  );
};

type ThemeConfigQueryParams = {
  key: THEME_CONFIG_KEYS;
  language?: string;
  unitId?: number;
  fetchThemeLevel?: boolean;
};

type ThemeConfigResponse<T = any> = {
  meta: {
    hierarchyLevel: HierarchyLevel;
    key: THEME_CONFIG_KEYS;
  };
  value: T;
};

export const fetchThemeConfig = async <T = any>(
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: ThemeConfigQueryParams | THEME_CONFIG_KEYS,
): Promise<ThemeConfigResponse<T>> => {
  const params = typeof payload === 'string' ? { key: payload } : payload;
  const response = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(`${config.API_BASE_URL}theme/config`, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
  if (response.data?.isSuccess) {
    return response.data?.data;
  }
  throw new Error('isSuccess: false');
};

export const fetchPublicThemeConfig = async (
  key: THEME_CONFIG_KEYS,
  payload?: { username?: string; accountId?: string },
) => {
  const response = await axios.get(`${config.API_BASE_URL}theme/public-config`, {
    params: { key: key || '', ...payload },
  });
  if (response.data?.isSuccess) {
    return response.data?.data;
  }
  throw new Error('isSuccess: false');
};

export const writeThemeConfig = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: { key: THEME_CONFIG_KEYS; value: any; language?: string; unitId?: number },
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(`${config.API_BASE_URL}theme/config`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const deleteThemeConfig = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: {
    key: THEME_CONFIG_KEYS;
    language?: string;
    unitId?: number;
    withDeleteUnitConfig?: boolean;
  },
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(`${config.API_BASE_URL}theme/delete-config`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};
