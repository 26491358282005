import { Heading } from '../../components/generic';
import { QRBtn, QRNavigator } from '@/views/components/qrcode-background/common/styles';
import appleWalletImageDE from '../../images/add-to-apple-wallet-de.png';
import appleWalletImageEN from '../../images/add-to-apple-wallet-en.png';
import googleWalletImageEN from '../../images/add-to-google-wallet-en.png';
import googleWalletImageDE from '../../images/add-to-google-wallet-de.png';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import fileDownload from 'js-file-download';
import { toast } from 'react-hot-toast';
import QRCode from 'qrcode.react';
import { routePaths } from '@/infrastructure/constants';
import { Spacer } from '../md/customization/common';
import { getWalletPass } from '@/infrastructure/apis/account';
import { useMemo, useRef, useState } from 'react';
import useDeviceInfo from '@/infrastructure/hooks/useDeviceInfo';
import TooltipInfo from '@/views/components/generic/TooltipInfo';
import { defaultPalette } from '@/config/theme/palette';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import {
  Box,
  CardContent,
  MenuItem,
  Select,
  Typography,
  IconButton,
  CardMedia,
  Button,
  Divider,
} from '@mui/material';
import { LtDialog } from '@/components';
import { CardRenderer } from '@/components/CardRenderer';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { FileDownloadOutlined, InfoOutlined } from '@mui/icons-material';
import { useAppSelector } from '@/application/hooks';
import { Account } from '@/shared/types/api';
import { createVCard } from '@/infrastructure/helper';

type WalletQRType = 'online' | 'offline';

const QR_TYPES = ['online', 'offline'] as Array<WalletQRType>;

type Props = {
  showOnly?: 'online' | 'offline';
  detectDownload?: () => void;
  preQrHint?: string;
  description?: string;
  noMobileHint?: boolean;
  useMUIDesign?: boolean;
  profileUrl: string;
  uidForProfileLink?: number;
};

const WalletGenerator = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { theme } = useMuiTheme();

  const account = useAppSelector<Account>(state => state.account);

  const { t, activeLanguage } = useAppTranslation();

  const qrRef = useRef(null);

  const isDE = activeLanguage === 'de';

  const [qrType, setQrType] = useState<WalletQRType>(props.showOnly || 'online');
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const { isDesktop, isIOS, isAndroid, isChrome, isSafari } = useDeviceInfo();

  const { desktopView } = useDesktopView();

  const company = isIOS ? 'Apple' : isAndroid ? 'Google' : 'Apple/Google';
  const device = isIOS ? 'iPhone' : isAndroid ? 'Android' : 'iPhone/Android';

  const clickHandler = async (wallet: 'google' | 'apple') => {
    try {
      toast.loading(t('Loading'), { id: 'aw-toastid' });
      const response = await getWalletPass(
        getAccessTokenSilently,
        qrType,
        wallet,
        props.uidForProfileLink,
      );
      if (wallet === 'apple') {
        fileDownload(response.data, `pass.pkpass`, 'application/vnd.apple.pkpass');
      } else if (wallet === 'google') {
        window.open(response.data, '_blank');
      }
      toast.dismiss('aw-toastid');
      props?.detectDownload && props.detectDownload();
    } catch {
      toast.error(t('error.general'), { id: 'aw-toastid' });
    }
  };

  const handleDownloadQRImage = () => {
    const canvas = qrRef.current?.children[0] as HTMLCanvasElement;
    if (!canvas) return;
    var link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = canvas.toDataURL();
    link.click();
    link.remove();
  };

  // info video config goes here
  const videoConfig = useMemo(
    () => ({
      title: '',
      text: '',
      videoUrl: isDE ? '' : '',
    }),
    [isDE],
  );

  const profileVcard = useMemo(
    () => createVCard(account, props.profileUrl, activeLanguage),
    [account, activeLanguage, props.profileUrl],
  );

  const cardActions = useMemo(
    () => [
      <Select
        value={qrType}
        onChange={({ target: { value } }) => setQrType(value as WalletQRType)}
        size={desktopView ? 'small' : 'medium'}
        sx={{ mr: 1, flex: desktopView ? undefined : '1' }}
      >
        {QR_TYPES.map(option => (
          <MenuItem key={option} value={option}>
            {t(`shareProfile.wallet.${option}`).concat(!desktopView ? ' QR-Code' : '')}
          </MenuItem>
        ))}
      </Select>,
      videoConfig?.videoUrl?.length ? (
        <IconButton>
          <InfoOutlined
            sx={{ color: 'text.primary', cursor: 'pointer' }}
            onClick={() => setShowInfoDialog(true)}
          />
        </IconButton>
      ) : (
        <TooltipInfo
          text={t('shareProfile.wallet.titleTooltip')}
          icon={{ color: 'text.primary' }}
        />
      ),
    ],
    [desktopView, qrType, t, videoConfig?.videoUrl?.length],
  );

  return props.useMUIDesign ? (
    <>
      <CardRenderer
        title={desktopView ? t('shareProfile.wallet.title') : undefined}
        sx={{ flexBasis: '100%' }}
        headingActions={desktopView ? cardActions : undefined}
        variant={desktopView ? 'elevation' : 'outlined'}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: 3,
          }}
        >
          {!desktopView && (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              {cardActions}
            </Box>
          )}
          <Box display={'flex'} flexDirection={'column'}>
            <Box ref={qrRef} display={'flex'} justifyContent={'center'}>
              <QRCode
                value={qrType === 'online' ? props.profileUrl : profileVcard}
                size={164}
                includeMargin
                style={{ margin: '0rem auto' }}
              />
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant='text'
                startIcon={<FileDownloadOutlined />}
                onClick={handleDownloadQRImage}
              >
                {t('download')}
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box display={'flex'} justifyContent={'center'} width='100%'>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              maxWidth={'80%'}
              gap={1}
              flexWrap={desktopView ? 'nowrap' : 'wrap'}
            >
              {!isAndroid && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: desktopView ? 'auto' : 1,
                    gap: 0.5,
                  }}
                >
                  <img
                    src={isDE ? appleWalletImageDE : appleWalletImageEN}
                    alt='add-to-apple-wallet'
                    onClick={() => clickHandler('apple')}
                    style={{
                      width: '80%',
                      maxWidth: '24rem',
                      minWidth: '15rem',
                      alignSelf: 'center',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography variant='caption' sx={{ textTransform: 'capitalize' }}>
                    {qrType} QR-Code
                  </Typography>
                </Box>
              )}
              {!isIOS && (
                <Box
                  sx={{
                    ml: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <img
                    src={isDE ? googleWalletImageDE : googleWalletImageEN}
                    alt='add-to-google-wallet'
                    onClick={() => clickHandler('google')}
                    style={{
                      width: '80%',
                      maxWidth: '28rem',
                      minWidth: '15.5rem',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography variant='caption' sx={{ textTransform: 'capitalize' }}>
                    {qrType} QR-Code
                  </Typography>
                </Box>
              )}
              {desktopView && (
                <TooltipInfo
                  text={t('shareProfile.wallet.buttonsTooltip', { company })}
                  icon={{ color: 'text.primary' }}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </CardRenderer>
      <LtDialog
        open={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
        title={t('shareProfile.wallet.dialog.title')}
        size='sm'
        withActionDivider
      >
        <Box>
          <Box display={'flex'}>
            <InfoOutlined sx={{ color: 'text.primary', mr: 2 }} />
            <Typography variant='body1'>{t('shareProfile.wallet.dialog.description')}</Typography>
          </Box>
          <Box mt={2}>
            <CardMedia
              component='iframe'
              title={videoConfig?.title || 'Add to wallet'}
              image={videoConfig.videoUrl}
              allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerPolicy='strict-origin-when-cross-origin'
              allowFullScreen
              sx={{ height: '30rem' }}
            />
          </Box>
        </Box>
      </LtDialog>
    </>
  ) : (
    <>
      <Spacer size={32} />
      <p>{props.description || t('wallet.description', { company })}</p>
      <Spacer size={32} />

      {!props.showOnly && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
            <Heading text={'1. ' + t('wallet.step1')} style={{ marginRight: '1rem' }} />
            <TooltipInfo
              text={t('shareProfile.wallet.titleTooltip')}
              placement={window.innerWidth >= 600 ? 'right-start' : 'bottom'}
              arrow
              icon={{ color: defaultPalette.primary.main }}
            />
          </div>
          <QRNavigator style={{ marginBottom: '3rem' }}>
            <QRBtn
              onClick={() => setQrType('online')}
              className={qrType === 'online' ? 'active' : null}
              primaryColor={theme.palette.primaryButton.main}
            >
              {t('shareProfile.wallet.online')}
            </QRBtn>
            <QRBtn
              onClick={() => setQrType('offline')}
              className={qrType === 'offline' ? 'active' : null}
              primaryColor={theme.palette.primaryButton.main}
            >
              {t('shareProfile.wallet.offline')}
            </QRBtn>
          </QRNavigator>

          <Heading text={'2. ' + t('wallet.step2')} style={{ marginTop: '2rem' }} />
        </>
      )}

      {isIOS && !(isChrome || isSafari) && (
        <>
          <Spacer size={8} />
          <p>{t('wallet.hint.browsers')}</p>
        </>
      )}

      <WalletImageRow>
        {!isAndroid && (
          <StyledImgWrapper>
            <StyledButton
              onClick={() => clickHandler('apple')}
              focusColor={theme.palette.primaryButton.main}
            >
              <StyledImg
                src={isDE ? appleWalletImageDE : appleWalletImageEN}
                alt='add-to-apple-wallet-image'
              />
            </StyledButton>
          </StyledImgWrapper>
        )}
        {!isIOS && (
          <StyledImgWrapper>
            <StyledButton
              onClick={() => clickHandler('google')}
              focusColor={theme.palette.primaryButton.main}
            >
              <StyledImg
                src={isDE ? googleWalletImageDE : googleWalletImageEN}
                alt='add-to-google-wallet-image'
              />
            </StyledButton>
          </StyledImgWrapper>
        )}
      </WalletImageRow>

      {isDesktop && !props.noMobileHint && (
        <>
          <Heading size='1.8rem' text={t('wallet.hint.title', { device })} />
          <Spacer size={16} />

          {props.preQrHint ? <p>{props.preQrHint}</p> : <p>{t('wallet.hint.p1', { device })}:</p>}
          <QRCode
            value={window.location.origin + routePaths.SHARE_PROFILE}
            size={132}
            style={{ margin: '0rem auto 2rem' }}
          />
          <p> {t('wallet.hint.p2', { device, company })}</p>
        </>
      )}
      <Spacer size={16} />
    </>
  );
};

export default WalletGenerator;

const StyledButton = styled.button`
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: grid;
  place-items: center;
  &:focus-visible {
    outline: 1px solid ${p => p.focusColor};
    outline-offset: 2px;
  }
`;

const StyledImgWrapper = styled.div`
  width: 25rem;
  display: grid;
  place-items: center;
`;

const StyledImg = styled.img`
  width: 100%;
  max-width: 100%;
`;

const WalletImageRow = styled.div`
  margin: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  max-width: 100%;
`;
