import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SubsectionSpan } from './common';
import TooltipInfo from '../../../components/generic/TooltipInfo';

interface Props {
  saveColor: string;
  setSaveColor: (value: string) => void;
  filesColor: string;
  setFilesColor: (value: string) => void;
  genericWebsiteColor: string;
  setGenericWebsiteColor: (value: string) => void;
}

const ButtonColors = (props: Props) => {
  const { t } = useTranslation();

  const {
    saveColor,
    setSaveColor,
    filesColor,
    setFilesColor,
    genericWebsiteColor,
    setGenericWebsiteColor,
  } = props;

  type ColorPickerType = 'saveColorPicker' | 'filesPicker' | 'genericWebsitePicker' | '';
  const [colorMode, setColorMode] = useState<ColorPickerType>('');

  const handleColorChange = e => {
    switch (colorMode) {
      case 'saveColorPicker':
        setSaveColor(e.target.value);
        break;
      case 'filesPicker':
        setFilesColor(e.target.value);
        break;
      case 'genericWebsitePicker':
        setGenericWebsiteColor(e.target.value);
        break;
      default:
        console.warn('color mode not set');
    }
  };

  const handleColorClick = (colorPicker: ColorPickerType) => {
    document.getElementById(colorPicker).click();
    setColorMode(colorPicker);
  };

  return (
    <>
      <OptionsContainer>
        <OptionContainer>
          <TooltipInfo
            text={t('md.profileDesign.buttonColorSaveContactTooltip')}
            placement='top'
            onHover={true}
          >
            <SubsectionSpan help marginBottom>
              {t('strSaveContact')}
            </SubsectionSpan>
          </TooltipInfo>

          <Option onClick={() => handleColorClick('saveColorPicker')}>
            <SubsectionSpan>{saveColor}</SubsectionSpan>
            <ColorDot bg={saveColor} />
            <input
              id='saveColorPicker'
              type='color'
              onChange={e => handleColorChange(e)}
              value={saveColor}
            />
          </Option>
        </OptionContainer>
        <OptionContainer>
          <TooltipInfo
            text={t('md.profileDesign.buttonColorFilesTooltip')}
            placement='top'
            onHover={true}
          >
            <SubsectionSpan help marginBottom>
              {t('files')}:
            </SubsectionSpan>
          </TooltipInfo>
          <Option onClick={() => handleColorClick('filesPicker')}>
            <SubsectionSpan>{filesColor}</SubsectionSpan>
            <ColorDot bg={filesColor} />
            <input
              id='filesPicker'
              type='color'
              onChange={e => handleColorChange(e)}
              value={filesColor}
            />
          </Option>
        </OptionContainer>
        <OptionContainer>
          <TooltipInfo
            text={t('md.profileDesign.buttonColorGenericWebsitePicker')}
            placement='top'
            onHover={true}
          >
            <SubsectionSpan help marginBottom>
              {t('genericWebsite')}
            </SubsectionSpan>
          </TooltipInfo>

          <Option onClick={() => handleColorClick('genericWebsitePicker')}>
            <SubsectionSpan>{genericWebsiteColor}</SubsectionSpan>
            <ColorDot bg={genericWebsiteColor} />
            <input
              id='genericWebsitePicker'
              type='color'
              onChange={e => handleColorChange(e)}
              value={genericWebsiteColor}
            />
          </Option>
        </OptionContainer>
      </OptionsContainer>
    </>
  );
};

export default ButtonColors;

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 15px 0px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  margin-bottom: 2rem;

  &:not(:last-child) {
    margin-right: 2.5rem;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  padding: 0.5rem;
  padding-left: 1.5rem;
  border-radius: 3rem;
  border: 2px solid #444444;
  box-sizing: content-box;
  cursor: pointer;

  input[type='color'] {
    visibility: hidden;
    position: absolute;
  }
`;

export const ColorDot = styled.div.attrs(props => ({
  bg: props.bg,
}))`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${props => props.bg || 'transparent'};
  border: 1px solid #999;
`;
