import i18n from '@/config/i18n';
import { routePaths } from '@/infrastructure/constants';
import { Account } from '@/shared/types/api';

export const HEADINGS = (condition: boolean) => [
  { heading: i18n.t('onboarding.welcome') },
  { heading: i18n.t('onboarding.addToWallet.title') },
  ...(condition
    ? [
        {
          heading: i18n.t('onboarding.howShareProfile'),
        },
        {
          heading: i18n.t('onboarding.tellUs'),
          secondHeading: i18n.t('onboarding.forPersonalize'),
          subHeading: i18n.t('lemonTapsPhones'),
        },
      ]
    : [
        {
          heading: i18n.t('onboarding.tellUs'),
          secondHeading: i18n.t('onboarding.forPersonalize'),
          subHeading: i18n.t('lemonTapsPhones'),
        },
      ]),
];

export const getRedirectedRoute = (origin: Account['origin']) => {
  if (origin === 'signup2theme' || origin === 'signup2unit') {
    return routePaths.MD.GUIDE;
  } else {
    return routePaths.HOME;
  }
};
