import { FormControl, InputLabel, Select, MenuItem, FormControlProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  gdprOptions: string[];
  onGdprSelect: (value: string) => void;
  value: string;
} & FormControlProps;

export const GDPRSelector = ({ gdprOptions, onGdprSelect, value, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth {...rest}>
      <InputLabel>{t('gdpr')}</InputLabel>
      <Select
        value={value}
        label={t('gdpr')}
        onChange={({ target: { value } }) => onGdprSelect(value)}
      >
        {gdprOptions.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
