import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { Loader } from '../../components/common';
import { resendVerificationEmail } from '../../../application/actions/verification';
import PublicContainer from '../../components/common/containers/public.container';

import { useAuth0 } from '@auth0/auth0-react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { toast } from 'react-hot-toast';
import { isEmptyObject, withSilentAccessToken } from '../../../infrastructure/helper';
import { BiEdit, BiLogOut } from 'react-icons/bi';
import axios from 'axios';
import config from '../../../config/config';

interface Props {
  onLogoutClick: () => void;
}
const VerifyEmail = (props: Props): JSX.Element => {
  const { getAccessTokenSilently, user, logout } = useAuth0();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { apiResponse, isLoading: resendEmailLoading } = useAppSelector(
    state => state.verification,
  );

  const [unlinkCodesLoading, setUnlinkCodesLoading] = useState<boolean>();

  useEffect(() => {
    if (apiResponse?.isSuccess) toast.success(t('verification.sendSuccess'));
    else if (!isEmptyObject(apiResponse) && !apiResponse?.isSuccess)
      toast.error(t('verification.sendError'));
  }, [apiResponse, t]);

  const reSendMail = () => {
    dispatch(resendVerificationEmail(getAccessTokenSilently));
  };

  const changeEmail = async () => {
    setUnlinkCodesLoading(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + 'account/unlink-all-codes',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
      .then(res => {
        let resData = res.data;
        if (resData.isSuccess && resData.data?.codes.length > 0) {
          logout({
            returnTo: `${window.location.origin}/login?signup=true&code=${resData.data?.codes[0]}`,
          });
        } else {
          logout({
            returnTo: `${window.location.origin}/login?signup=true`,
          });
        }
      })
      .catch(err => {
        toast.error('error change email');
      })
      .finally(() => setUnlinkCodesLoading(false));
  };

  return (
    <>
      {(resendEmailLoading || unlinkCodesLoading) === true ? <Loader /> : null}

      <PublicContainer
        pageHeading={t('verification.verifyEmail')}
        subHeading={{
          htmlText: (
            <Trans
              i18nKey='verification.subHeader'
              values={{
                email: user.email,
              }}
              components={[<strong />]}
            />
          ),
        }}
      >
        <VerifyEmailBlock>
          <ActionsList>
            <a href='#!' onClick={reSendMail}>
              <AiOutlineReload />
              <span>{t('verification.resendEmail')}</span>
            </a>
            <a href='#!' onClick={changeEmail}>
              <BiEdit />
              <span>{t('verification.changeEmail')}</span>
            </a>
            <a href='#!' onClick={props.onLogoutClick}>
              <BiLogOut />
              <span>{t('verification.logout')}</span>
            </a>
          </ActionsList>
        </VerifyEmailBlock>
      </PublicContainer>
    </>
  );
};

export default VerifyEmail;

const VerifyEmailBlock = styled.div`
  margin-top: 20px;
`;

const ActionsList = styled.div`
  a {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #fff;

    span {
      margin-left: 5px;
    }

    & > svg {
      font-size: 1.8rem;
    }
  }

  &:hover,
  &:visited {
    color: #fff;
  }
`;
