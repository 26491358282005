import { Box, Button, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
  onClick: () => void;
  overrideTexts?: {
    title?: string;
    description?: string;
    buttonText?: string;
  };
} & ComponentProps<typeof Box>;

export const SetupSettings = ({ onClick, overrideTexts, ...boxProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Box {...boxProps}>
      <Typography variant='h2' mb={2}>
        {overrideTexts?.title || t('setupSettings.title')}
      </Typography>
      <Typography variant='body1' mb={2}>
        {overrideTexts?.description || t('setupSettings.description')}
      </Typography>

      <Button onClick={onClick}>
        {overrideTexts?.buttonText || t('setupSettings.buttonText')}
      </Button>
    </Box>
  );
};
