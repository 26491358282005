import styled from 'styled-components';
import { Props as CodeProps } from '../../../../../components/qrcode-scanner/codebox';
import { CSS_VARS } from '@/infrastructure/constants';
import { Link } from 'react-router-dom';

export const SettingsContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  background: #fff;
  z-index: 10;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  box-sizing: border-box;
  animation: animationDropLeft 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    width: 70%;
  }

  @keyframes animationDropLeft {
    0% {
      max-width: 0%;
    }
    100% {
      max-width: 40%;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const HeaderName = styled.h5`
  color: #252525;
  font-weight: 600;
  padding-left: 10px;
`;

export const SubsectionHeader = styled.h6`
  font-size: small;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: ${props => (props.bottomPadding ? props.bottomPadding + 'px' : '15px')};
  padding-left: 5px;
  display: flex;
  align-items: center;
`;

export const SubsectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

//popup components
export const PopupWrapper = styled.div`
  padding: 10px 0 30px 0;
  text-align: center;
`;

export const Title = styled.h5`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const SecondaryText = styled.div`
  font-size: 1.2rem;
  line-height: 21px;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const SecondaryButton = styled.a`
  color: #4748ec;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
`;

export const ScannerVideo = styled.video`
  width: ${p => p.videoWidth ?? '90%'};

  @media (max-width: 425px) {
    height: 50vh;
    object-fit: cover;
  }
`;
export const SmallText = styled.div`
  font-size: small;
  color: grey;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

// button components
export const ButtonWrapper = styled.div`
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${(props: CodeProps) =>
    props.codeExists &&
    `& svg {
      color: #535353;
      font-size: 2rem;
      margin-right: 0.7em;
      cursor: pointer;
    }`}
`;

export const Button = styled.div`
  display: flex;
  background: ${(props: CodeProps) =>
    props.codeExists ? '#fff' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  color: ${(props: CodeProps) =>
    props.codeExists ? '#535353' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  height: 50px;
  box-sizing: border-box;
  border: ${(props: CodeProps) => (props.codeExists ? 'solid 1px #DFE0E7' : 'none')};
  border-radius: 10px;
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  cursor: ${(props: CodeProps) => (props.codeExists ? 'auto' : 'pointer')};
  & svg {
    color: ${(props: CodeProps) =>
      props.codeExists ? CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR : '#fff'};
    font-size: 2rem;
    margin-right: 0.7em;
    cursor: auto;
  }
`;

export const StyledLink = styled(Link)`
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: normal;
  margin-top: 1rem;
  &:hover,
  &:active {
    color: #666666;
  }
`;
