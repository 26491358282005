import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  apiResponse: {},
  isAuthenticated: false,
  domainMismatch: null,
  domainVerified: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.LOGIN.CHECKLOGIN:
      return { ...state, isLoading: true };
    case dispatchTypes.LOGIN.LOGINSUCESS:
      return { ...state, isLoading: false, apiResponse: action.apiResponse, isAuthenticated: true };
    case dispatchTypes.LOGIN.LOGINERROR:
      return {
        ...state,
        isLoading: false,
        apiResponse: action.apiResponse,
        isAuthenticated: false,
      };
    case dispatchTypes.COMMON.ONBOARDINGSILENTLOGIN:
      return { ...state, isAuthenticated: true };
    case dispatchTypes.LOGIN.DOMAIN_MISMATCH:
      return {
        ...state,
        domainMismatch: {
          domain: action.domain,
        },
      };
    case dispatchTypes.LOGIN.DOMAIN_VERIFIED:
      return {
        ...state,
        domainVerified: true,
      };

    default:
      return state;
  }
};

export default loginReducer;
