import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../application/hooks';
import { routePaths, VIDEOS } from '../../../infrastructure/constants';
import withNav from '../../../infrastructure/hoc/withNav';
import useDesktopView from '../../../infrastructure/hooks/useDesktopView';
import { Container, Heading, LTCard } from '../../components/generic';
import { CardsContainer, CardSpan, HeadingWrapper, StyledVideo } from '../homepage/common';

const Leads = () => {
  const { t, i18n } = useTranslation();
  const { username } = useAppSelector(state => state.account);
  const { src, title } = i18n.language.startsWith('de') ? VIDEOS.LEADS.DE : VIDEOS.LEADS.EN;

  const { desktopView } = useDesktopView();

  return (
    <Container fillHeight>
      <HeadingWrapper>
        {!desktopView && <Heading text={t('homePage.leads.title')} />}
      </HeadingWrapper>
      <CardsContainer>
        <StyledVideo src={src} title={title} frameBorder='0' allowFullScreen></StyledVideo>
        <LTCard
          fullWidth={{
            content: <CardSpan>{t('homePage.leads.manage')}</CardSpan>,
          }}
          link={routePaths.CONTACTS.ROOT}
        />
        <LTCard
          fullWidth={{
            content: <CardSpan>{t('homePage.leads.previewForm')}</CardSpan>,
          }}
          link={`${username}#leadform`}
        />
      </CardsContainer>
    </Container>
  );
};

export default withNav(
  Leads,
  {
    tTitle: 'homePage.leads.title',
    showBackButton: {
      linkTo: routePaths.HOME,
    },
  },
  {},
);
