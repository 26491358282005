import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as SaveIcon } from '../../images/save-icon.svg';
import logoImage from '../../images/logo.svg';

import LTPlaceholder from '../../images/lemontap-icon.png';
import { invertHex, isFileValid, isNotEmptyArray } from '../../../infrastructure/helper';
import Banner from '../banner';
import { useAppDispatch } from '../../../application/hooks';
import { updateImage, uploadImage } from '@/application/actions/edit-profile';
import { themeCss } from '../../../util';
import { useAuth0 } from '@auth0/auth0-react';
import ImageModal from './image-modal';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { handleWebShare } from './contactWebShare';
import { ProfileDesignConfig, StaticProfileConfig } from '../../../shared/types/api';
import { trackPrivateEvent } from '../../../infrastructure/apis/analytics';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../shared/constants/global';
import { DEFAULT_BOX_STYLE, THEME_BOX_STYLES } from '../../../shared/constants';
import { Popup } from '../common';
import { MdOutlineClose } from 'react-icons/md';
import LogoChooser from '../popups/LogoChooser';
import ImageCropper from '../image-cropper';
import { CSS_VARS } from '@/infrastructure/constants';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { ThemeBoxStyle } from '@/shared/types/global';
import { withKeyEventHandler } from '@/utils/helpers';
import EditImageIcon from './EditImageIcon';

// TODO: profile page optimizations

const nl2br = require('react-nl2br');

type Props = {
  username: string;
  config: StaticProfileConfig['header'];
  logoImgOptions?: string[];
  bannerImgOptions?: string[];
  boxStyle: ThemeBoxStyle;
  canEdit?: boolean;
  canEditLogo?: boolean;
  picInMiddle?: boolean;
  hideSaveContact?: boolean;
  profilePicTrackHandler?: () => void;
  contactSaveTrackHandler?: () => void;
};

interface IProps {
  $backgroundColor: string;
  $boxStyle: ProfileDesignConfig['boxStyle'];
}

const ProfileDetails: React.FC<Props> = ({
  username,
  config,
  logoImgOptions,
  bannerImgOptions,
  boxStyle,
  canEdit,
  canEditLogo,
  picInMiddle,
  hideSaveContact,
  profilePicTrackHandler,
  contactSaveTrackHandler,
}) => {
  const {
    logoImageUrl: logoHeaderUrl,
    profileImageUrl,
    bannerImageUrl,
    contactSaveButton: contactSaveButtonDef,
    bioContent,
  } = config;

  const { getAccessTokenSilently } = useAuth0();
  const { editRights } = useEditRights();

  const DownloadElRef = useRef(null) as React.MutableRefObject<HTMLAnchorElement>;
  const dispatch = useAppDispatch();
  const { t, activeLanguage } = useAppTranslation();
  const [image, setImage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [uploadImageType, setUploadImageType] = useState<'profile' | 'logoHeader' | null>(null);
  const [headerLogo, setHeaderLogo] = useState(LTPlaceholder);
  const [showLogoChooser, setShowLogoChooser] = useState(false);

  const headerLogoInitial = logoHeaderUrl || logoImage;

  let fileInput = useRef(null);
  let logoInput = useRef(null);

  const showEditProfilePic = canEdit && editRights.profileImageEditable;

  const onImageClick = (): void => {
    if (canEdit && editRights?.profileImageEditable) {
      setUploadImageType('profile');
      fileInput.current?.click();
    } else if (profileImageUrl) {
      setProfilePic(profileImageUrl);
    }
  };

  const onLogoClick = (): void => {
    if (canEdit) {
      if (logoImgOptions && logoImgOptions.length > 0) {
        setShowLogoChooser(true);
      } else {
        setUploadImageType('logoHeader');
        logoInput.current.click();
      }
    } else if (logoHeaderUrl) {
      setHeaderLogo(logoHeaderUrl);
    }
  };

  const onLogoRemoveClick = useCallback((): void => {
    if (canEdit) {
      setUploadImageType('logoHeader');
      dispatch(uploadImage(username, null, 'logoHeader', getAccessTokenSilently));
    }
  }, [canEdit, dispatch, username, getAccessTokenSilently]);

  const onLogoChange = async event => {
    // Update the state
    let img;
    if (isNotEmptyArray(event.target?.files)) {
      img = event.target.files[0];

      // validate uploaded logo
      const errMsg = isFileValid(img, 'imageWithSvg', t);
      if (errMsg) {
        setErrorMsg(errMsg);
        return;
      }

      const logoUrl = URL.createObjectURL(img);

      let blob = await fetch(logoUrl).then(r => r.blob());
      let file = new File([blob], logoInput.current.files[0].name);
      dispatch(uploadImage(username, file, 'logoHeader', getAccessTokenSilently));
      await toDataUrl(logoHeaderUrl, headerLogoInitial.endsWith('svg')).then(imgUrl =>
        setHeaderLogo(imgUrl.toString()),
      );
      setUploadImageType(null);
    }
  };

  const onImageSelection = async (): Promise<void> => {
    let uploadedFile;
    if (isNotEmptyArray(fileInput.current?.files)) {
      uploadedFile = fileInput.current.files[0];
      const extension = uploadedFile.name.split('.').pop();
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        setErrorMsg(errMsg);
        return;
      }

      if (extension === 'gif') {
        dispatch(
          uploadImage(username, uploadedFile, uploadImageType, getAccessTokenSilently, () => {
            trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
              type: PRIVATE_META_TYPES.PROFILE_PICTURE,
            });
          }),
        );
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => {
          setImage(reader.result as string);
        };
      }
    }
  };

  const onUploadClick = async croppedImageUrl => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    var file = new File([blob], fileInput.current.files[0].name);
    setImage('');
    if (uploadImageType === 'profile') {
      dispatch(
        uploadImage(username, file, uploadImageType, getAccessTokenSilently, () => {
          trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
            type: PRIVATE_META_TYPES.PROFILE_PICTURE,
          });
        }),
      );
    } else {
      dispatch(uploadImage(username, file, uploadImageType, getAccessTokenSilently));
    }
    setUploadImageType(null);
  };

  const toDataUrl = async (url, isSvg) => {
    const MAP = {
      jpg: 'jpg',
      jpeg: 'jpg',
      png: 'png',
    };
    return await fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise(callback => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              callback(
                reader.result
                  .toString()
                  .replace(
                    /^data:.+;base64,/,
                    `data:image${
                      isSvg
                        ? '/svg+xml'
                        : Object.keys(MAP).some(ext => url.endsWith(`.${ext}`))
                        ? MAP[url.split('.').at(-1)]
                        : ''
                    };base64,`,
                  ),
              );
            };
          }),
      );
  };

  const handleLogoUrlChoose = (newUrl: string) => {
    setShowLogoChooser(false);
    dispatch(
      updateImage(username, newUrl, 'logoHeader', getAccessTokenSilently, () => {
        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
          type: PRIVATE_META_TYPES.BANNER,
        });
      }),
    );
  };

  useEffect(() => {
    if (!headerLogoInitial.toLowerCase().endsWith('.svg')) {
      setHeaderLogo(headerLogoInitial);
    } else {
      toDataUrl(headerLogoInitial, headerLogoInitial.endsWith('svg'))
        .then(imgUrl => setHeaderLogo(imgUrl.toString()))
        .catch(error => console.error(error));
    }
  }, [headerLogoInitial]);

  return (
    <>
      {image && (
        <ImageCropper
          image={image}
          onUpload={url => onUploadClick(url)}
          onClose={() => setImage('')}
          circularCrop={uploadImageType === 'profile'}
        />
      )}
      {errorMsg && <Popup bodyText={errorMsg} onCloseClick={() => setErrorMsg('')} isErrorPopup />}
      {profilePic && <ImageModal profilePic={profilePic} setProfilePic={setProfilePic} />}
      <ProfileWrapper>
        <Banner
          username={username}
          canEdit={canEdit && editRights.bannerImageEditable}
          bannerImageUrl={bannerImageUrl}
          bannerImgOptions={canEdit ? bannerImgOptions : undefined}
        />
        <ProfileDetailBlock theme={themeCss(boxStyle || DEFAULT_BOX_STYLE)}>
          <ProfilePicBlock middle={picInMiddle} onClick={() => onImageClick()}>
            <img src={profileImageUrl} alt='Profile Pic' onClick={profilePicTrackHandler} />
            {showEditProfilePic && <EditImageIcon style={{ bottom: 0, right: '1rem' }} />}
          </ProfilePicBlock>

          <LogoContainer
            tabIndex={canEditLogo && 0}
            clickable={canEditLogo}
            showBorder={canEdit}
            boxStyle={boxStyle}
            onClick={canEditLogo ? onLogoClick : undefined}
            onKeyUp={canEditLogo && withKeyEventHandler(() => onLogoClick())}
          >
            {canEditLogo && logoHeaderUrl && (
              <XIconStyled
                onClick={event => {
                  event.stopPropagation();
                  onLogoRemoveClick();
                }}
              />
            )}
            {canEditLogo || logoHeaderUrl ? (
              <LogoImage
                src={headerLogo}
                crossorigin='anonymous'
                className='lt-usetiful--logo-image-select'
                alt='logo-image'
              />
            ) : null}
          </LogoContainer>

          <ProfileDetailsSection>
            {bioContent.map(def => (
              <div
                style={{
                  color: def.color,
                  fontSize:
                    def.size === 'big' ? '1.8rem' : def.size === 'small' ? '1rem' : '1.4rem',
                  fontWeight: def.fontWeight,
                  fontStyle: def.italic ? 'italic' : undefined,
                  lineHeight:
                    def.lineHeight === 'big' ? '1.5' : def.lineHeight === 'small' ? '1' : '1.25',
                }}
              >
                {nl2br(def.text)}
              </div>
            ))}
          </ProfileDetailsSection>

          {contactSaveButtonDef && !hideSaveContact && (
            <ContactSave>
              <ContactSaveButton
                $boxStyle={boxStyle}
                $backgroundColor={contactSaveButtonDef.backgroundCss}
                onClick={() => {
                  handleWebShare(username, activeLanguage);
                  contactSaveTrackHandler();
                }}
              >
                <SaveIcon />
                {contactSaveButtonDef.text}
                <a
                  style={{ display: 'none' }}
                  href='/'
                  rel='noopener noreferrer'
                  ref={el => (DownloadElRef.current = el)}
                >
                  Link
                </a>
              </ContactSaveButton>
            </ContactSave>
          )}

          <input
            type='file'
            ref={fileInput}
            onClick={event => {
              (event.target as HTMLInputElement).value = null;
            }}
            onChange={onImageSelection}
            style={{ display: 'none' }}
            accept='image/*'
          />
          <input
            type='file'
            ref={logoInput}
            onChange={onLogoChange}
            style={{ display: 'none' }}
            accept='image/*'
          />
        </ProfileDetailBlock>
        {showLogoChooser && logoImgOptions && (
          <LogoChooser
            onCancel={() => setShowLogoChooser(false)}
            options={logoImgOptions}
            onSelected={handleLogoUrlChoose}
            current={logoHeaderUrl}
          />
        )}
      </ProfileWrapper>
    </>
  );
};

export default ProfileDetails;

const ProfileWrapper = styled.div`
  position: relative;
`;

const ProfilePicBlock = styled.div`
  position: absolute;
  z-index: 1;
  top: -52.5px;
  left: 30px;
  display: grid;
  place-items: center;

  ${props =>
    props.middle &&
    `
    left: 50%;
    transform: translateX(-50%);
  `}

  & > img {
    width: 105px;
    height: 105px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    background-color: white;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 95px;
      height: 95px;
    }
  }
`;

const ProfileDetailBlock = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: -10rem;
  padding-top: 70px;
  padding-bottom: 30px;
  margin: 0 auto;
  margin-bottom: -10rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.04);
  width: 33%;
  border-radius: ${props => (props.theme === 'theme-angular' ? '0px' : '10px')};
  @media (max-width: 1024px) and (min-height: 1366px) {
    width: 50%;
    top: -8rem;
    margin-bottom: -8rem;
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    width: 50%;
    top: -8rem;
    margin-bottom: -8rem;
  }

  @media (max-width: 767px) {
    width: 90%;
    top: -6rem;
    margin-bottom: -6rem;
  }

  @media (max-width: 425px) {
    padding-top: 55px;
  }

  @media (max-width: 333px) {
    width: 90%;
    top: -4rem;
    margin-bottom: -4rem;
  }
`;

const ProfileDetailsSection = styled.div`
  position: relative;
  display: block;
  margin: 0 30px;
  padding: 5px 10px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 0;
    border-left: 1px dashed #9f9f9f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }

  & > * {
    line-height: 1;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.6rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 3rem;
  height: 50px;
  width: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${props =>
    props.showBorder
      ? css`
          border-radius: ${props.boxStyle === THEME_BOX_STYLES.ANGULAR ? '0' : '3px'};
          border: 2px dashed ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
        `
      : ''}
  padding: 3px 5px;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};

  @media (max-width: 425px) {
    top: 7.5px;
    right: 7.5px;
    height: 40px;
    padding: 1.5px 2.5px;
  }
  &:focus-visible {
    outline: 2px solid;
  }
`;

const XIconStyled = styled(MdOutlineClose)`
  fill: ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
`;

const ContactSave = styled.div`
  display: flex;
  margin: 1.5rem 3rem 0;
`;

const ContactSaveButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ededed;
  border-radius: ${(props: IProps) =>
    props.$boxStyle === THEME_BOX_STYLES.ANGULAR ? '0' : '10px'};
  padding: 1rem;
  background: ${(props: IProps) => props.$backgroundColor || '#ededed'} !important;
  color: ${(props: IProps) => invertHex(props.$backgroundColor, true)};
  white-space: nowrap;
  &:hover,
  &:focus,
  &:visited {
    color: ${(props: IProps) => invertHex(props.$backgroundColor, true)};
  }
  &:focus-visible {
    outline: 2px solid ${(props: IProps) => props.$backgroundColor || '#ededed'};
    outline-offset: 2px;
  }

  svg {
    fill: ${(props: IProps) => invertHex(props.$backgroundColor, true)};
    margin-right: 1rem;
  }
`;
