import { Account, ProfileDesignConfig } from '@/shared/types/api';
import { Edit } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';
import { CardRenderer } from '@/components/CardRenderer';

type Props = {
  account: Account;
  profileDesign: ProfileDesignConfig;
};

export const BusinessCardCard = ({ account, profileDesign }: Props) => {
  const { t } = useTranslation();
  const avatar = account.profileImageUrl || profileDesign.defaultProfileImageUrl;
  const fullName = [account.firstName, account.lastName].filter(Boolean).join(' ');

  const history = useHistory();
  const isDesktop = useIsDesktopMui();

  const handleShowPreview = () => {
    if (isDesktop) window.open(`/${account.username}?forceProfile=true`, '_blank');
    else history.push(`/${account.username}?forceProfile=true`);
  };

  const actions = [
    <Button color='primary' variant='outlined' onClick={handleShowPreview}>
      {t('preview')}
    </Button>,
    <Button startIcon={<Edit />} onClick={() => history.push(routePaths.EDITPROFILE)}>
      {t('editProfile')}
    </Button>,
  ];
  return (
    <CardRenderer title={t('homePage.digitalBusinessCard')} actions={actions}>
      <Box display='flex' alignItems='center'>
        <Avatar
          sx={{
            width: isDesktop ? '11rem' : '7rem',
            height: isDesktop ? '11rem' : '7rem',
            mr: '2.4rem',
          }}
          src={avatar}
        />
        <Box>
          <Typography lineHeight={1.5} variant={isDesktop ? 'h2' : 'h3'}>
            {fullName}
          </Typography>
          <Typography
            lineHeight={1.5}
            color='text.secondary'
            variant={isDesktop ? 'body1' : 'body2'}
          >
            {account.position}
          </Typography>
          <Typography
            lineHeight={1.5}
            color='text.secondary'
            variant={isDesktop ? 'body1' : 'body2'}
          >
            {account.company}
          </Typography>
        </Box>
      </Box>
    </CardRenderer>
  );
};
