import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { routePaths } from './infrastructure/constants';
import { hasRole } from './infrastructure/helper';
import { Loader } from './views/components/common';
import VerifyEmail from './views/pages/activation/verify-email';
import config from './config/config';

import { useAppSelector } from './application/hooks';
import useQuery from './infrastructure/hooks/useQuery';
import { Feature, Tier } from './shared/constants';
import useTierInfo from './infrastructure/hooks/useTierInfo';
import { replaceAll } from './shared/util';
import { useMuiTheme } from './config/theme/useMuiTheme';
import { RouteWithMuiTheme } from './RouteWithMuiTheme';

interface Props {
  exact?: boolean;
  path: string;
  role?: string[];
  features?: Feature[];
  noAuthRedirectTo?: string;
  minTier?: Tier;
  tierInsufficientRedirect?: string;
  authRedirectTo?: string;
  component: RouteProps['component'];
  useLegacyTheme?: boolean;
}

export default function PrivateRouter(props: Props) {
  const {
    exact,
    path,
    role,
    features,
    minTier,
    noAuthRedirectTo,
    tierInsufficientRedirect,
    authRedirectTo,
    component,
    useLegacyTheme,
  } = props;

  const [idToken, setIdToken] = useState<IdToken>(null);
  const {
    isAuthenticated,
    getIdTokenClaims,
    isLoading: isAuth0Loading,
    user,
    logout,
    loginWithRedirect,
  } = useAuth0();

  const isDbLoading = useAppSelector(state => state.login.isLoading);
  const account = useAppSelector(state => state.account);
  const { isFeatureAllowed, isMinTier } = useTierInfo();
  const { pathname } = useLocation();
  const [domainLoading, setDomainLoading] = useState(false);
  const addCode = useAppSelector(state => state.landing.authCallbackState?.ltLoginCode);

  const domainMismatch = useAppSelector(state => state.login.domainMismatch);

  // if on main domain, but account lives on custom domain -> init redirect, otherwise logout
  useEffect(() => {
    if (domainMismatch && window.location.origin === config.REACT_APP_URL) {
      setDomainLoading(true);

      const redirectPath = addCode ? `${addCode}&addCodeFlow=true` : replaceAll(pathname, '/', ',');
      window.location.href =
        domainMismatch.domain + '/home?lt-event=login&redirect=' + redirectPath;
      // loginWithRedirect({
      //   redirectUri: domainMismatch.domain + '/home?lt-event=login&redirect=' + redirectPath,
      //   appState: { login: true },
      // });
    } else if (domainMismatch) {
      logout();
    }
  }, [addCode, domainMismatch, loginWithRedirect, logout, pathname]);

  const query = useQuery();
  // if user wanted to add a code via the main domain when the account lives on a custom domain
  // -> do one more login call to let auth0 set the auth cookie
  // this is executed after the redirect to the custom domain
  useEffect(() => {
    if (query.get('addCodeFlow') && query.get('redirect')) {
      setDomainLoading(true);
      loginWithRedirect({
        redirectUri:
          window.location.origin + '/home?lt-event=login&redirect=' + query.get('redirect'),
        appState: { login: true },
      });
    }
  }, [loginWithRedirect, query]);

  const onClickLogout = () => {
    logout();
  };

  useEffect(() => {
    if (isAuthenticated && !idToken) {
      getIdTokenClaims().then(setIdToken);
    }
  }, [isAuthenticated, idToken, getIdTokenClaims]);

  const roleCondition = !role || hasRole(idToken, role);
  const featuresCondition = !features || features.every(feature => isFeatureAllowed(feature));
  const tierCondition = !minTier || isMinTier(minTier);

  const { isFetching: isMuiThemeFetching } = useMuiTheme();

  useEffect(() => {
    if (useLegacyTheme) {
      document.body.classList.add('legacy-theme');
    } else {
      document.body.classList.remove('legacy-theme');
    }
  }, [useLegacyTheme]);

  const isLoading = isAuth0Loading || isDbLoading || domainLoading || isMuiThemeFetching;

  if (!isLoading && query.get('redirect')) {
    return <Redirect exact to={{ pathname: replaceAll(query.get('redirect'), ',', '/') }} />;
  } else if (!isAuthenticated && !isLoading) {
    return <Redirect exact={exact} to={{ pathname: noAuthRedirectTo || routePaths.LOGIN }} />;
  } else if (!idToken || isLoading) {
    return <Loader />;
  } else if (isAuthenticated && user && !user.email_verified) {
    return <VerifyEmail onLogoutClick={onClickLogout} />;
  } else if (minTier && !tierCondition) {
    return <Redirect exact={exact} to={tierInsufficientRedirect || routePaths.UPGRADE_TEAMS} />;
  } else if ((role || features) && (!roleCondition || !featuresCondition)) {
    return <Redirect exact={exact} to={authRedirectTo || routePaths.HOME} />;
  } else if (pathname !== routePaths.ONBOARDING && !account.firstName && !account.lastName) {
    return <Redirect exact to={routePaths.ONBOARDING} />;
  } else {
    return useLegacyTheme ? (
      <Route exact={exact} path={path} component={component} />
    ) : (
      <RouteWithMuiTheme exact={exact} path={path} component={component} />
    );
  }
}
