import { routePaths } from '@/infrastructure/constants';
import { AccountCircleOutlined, ContactsOutlined, HomeOutlined, Menu } from '@mui/icons-material';
import { NavItem } from '@/infrastructure/hoc/types';
import { usePrivateFeatureFlag } from '../useFeatureFlags';
import { ReactComponent as ScannerIcon } from '@/views/images/nav/scanner.svg';

export const useBottomBarItems = (): NavItem[] => {
  const flag_businessCardScanner = usePrivateFeatureFlag('businessCardScanner');

  return [
    {
      to: routePaths.HOME,
      icon: HomeOutlined,
      text: 'home',
    },
    {
      to: routePaths.EDITPROFILE,
      icon: AccountCircleOutlined,
      text: 'profile',
    },
    {
      to: routePaths.CONTACTS.SCANNER,
      icon: ScannerIcon as React.ElementType,
      text: 'scanner',
      hide: !flag_businessCardScanner,
    },
    {
      to: routePaths.CONTACTS.ROOT,
      icon: ContactsOutlined,
      text: 'contacts',
    },

    {
      to: routePaths.SETTINGS.MORE,
      icon: Menu,
      text: 'settings.more',
    },
  ];
};
