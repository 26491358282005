import styled from 'styled-components';
import { Link } from '../../../../../../../shared/types/api';
import DnD from './dnd';
import { useEffect, useState } from 'react';
import AutoComplete, { AutoCompleteValue } from '../../../../../../components/common/autoComplete';
import { withSilentAccessToken } from '../../../../../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../../../../config/config';
import { PERMISSIONS, routePaths } from '../../../../../../../infrastructure/constants';
import i18n from '../../../../../../../config/i18n';
import EditCompanyDetailsLink from './editCompanyDetailsLink';
import CompanyDetailsLinkBoxThemed from './CompanyDetailsLinkBoxThemed';
import {
  FEATURE,
  THEME_BOX_STYLES,
  THEME_LINK_EDIT_MODES,
} from '../../../../../../../shared/constants';
import LtIconButton from '../../../../../../components/common/lt-icon-button';
import { StyledLink, SubsectionWrapper } from '../../styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../../../../../images/add-icon.svg';
import useTierInfo from '../../../../../../../infrastructure/hooks/useTierInfo';
import TeaserBlur from '../../../../teaser/teaserBlur';
import { GoArrowUpRight } from 'react-icons/go';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
// TODO resolve any
interface Props {
  links;
  onSaveCompanydataSettings: (link: Partial<Link>) => void;
  onDeleteLink: (link: Link) => void;
  onEditLink: (link: Link) => void;
  onDragEnd: (links) => void;
  showAddNewLinkPopup: () => void;
}

const LinksCompanyDetails = (props: Props) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();

  const [showEditLink, setShowEditLink] = useState(false);
  const [LinkToEdit, SetLinkToEdit] = useState<Link>();
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const {
    editRights: { linkEditMode },
  } = useEditRights();
  const isDisabled = autoCompleteValues.length === 0;
  if (document.getElementById('autocomplete-component')) {
    document.getElementById('autocomplete-component').style.borderStyle = 'none';
    document.getElementById('autocomplete-component').style.boxShadow = 'none';
    document.getElementById('autocomplete-component').style.borderColor = 'white';
    document.getElementById('autocomplete-component').style.fontSize = '20px';
    document.getElementById('autocomplete-component').style.height = '50px';
  }
  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(`${config.API_BASE_URL}theme/link-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      ).then(res => {
        setAutoCompleteValues(
          res.data.data
            ?.map((linkTemplate, index) => {
              return {
                id: linkTemplate.id,
                description: linkTemplate.name,
                index,
                otherData: linkTemplate,
              };
            })
            .filter(({ id }) => props.links.every(link => link.linkTemplateId !== id)),
        );
      });
    }
  }, [getAccessTokenSilently, isFeatureAllowed, props.links]);

  const saveAutoValue = (item: AutoCompleteValue) => {
    if (item) {
      props.onSaveCompanydataSettings({
        link: item.otherData.link,
        name: item.otherData.name,
        linkType: item.otherData.themeLinkType.linkType,
        linkTypeId: item.otherData.themeLinkType.linkTypeId,
        linkTemplateId: item.id,
      });
    }
    // props.onSaveCompanydataSettings({ ...item.otherData, linkTemplateId: item.id });
  };

  const renderLinks = (links: Link[]) => {
    return links?.map(link => (
      <CompanyDetailsLinkBoxThemed
        key={link.id}
        link={link}
        onEditClick={item => {
          SetLinkToEdit(item);
          setShowEditLink(true);
        }}
        onDeleteClick={() => props.onDeleteLink(link)}
        blueBackground={typeof link.linkTemplateId === 'number'}
      />
    ));
  };

  return (
    <>
      {showEditLink && (
        <EditCompanyDetailsLink
          onCloseClick={() => setShowEditLink(false)}
          onDeleteClick={() => props.onDeleteLink(LinkToEdit)}
          item={LinkToEdit}
          mode={
            LinkToEdit.linkType?.mode ||
            // @ts-ignore:next-line
            LinkToEdit?.themeLinkType?.mode ||
            // @ts-ignore:next-line
            LinkToEdit?.mode
          }
          onEditLink={props.onEditLink}
        />
      )}
      {linkEditMode !== THEME_LINK_EDIT_MODES.OPTIONS && (
        <TeaserBlur condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} badge='Enterprise'>
          <AutoCompleteContainer>
            <AutoComplete
              options={autoCompleteValues}
              onSave={saveAutoValue}
              placeHolder={`${i18n.t(
                isDisabled ? 'linkTab.noTemplates' : 'linkTab.searchStandardLinks',
              )}...`}
              disabled={isDisabled}
            />
          </AutoCompleteContainer>
          {isDisabled && isThemeAdmin && (
            <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.DATA_TEMPLATES}>
              {t('linkTab.createTemplate')}
              <GoArrowUpRight size={17} />
            </StyledLink>
          )}
        </TeaserBlur>
      )}
      <OrManualHint>{t('linkTab.orAddIndividualLink')}</OrManualHint>
      <SubsectionWrapper>
        <LtIconButton
          text={linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS ? t('editLinks') : t('addLink')}
          icon={<AddIcon />}
          onClick={props.showAddNewLinkPopup}
          boxStyle={THEME_BOX_STYLES.ROUNDED}
          backgroundCss={muiTheme.palette.primaryButton.main}
        />
      </SubsectionWrapper>
      <DnD links={props.links} renderLinks={renderLinks} onDragEnd={props.onDragEnd} />
    </>
  );
};

export default LinksCompanyDetails;

const AutoCompleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrManualHint = styled.div`
  text-align: center;
  color: #666666;
  font-size: small;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 5px;
`;
