import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../application/hooks';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import SettingsHeader from '../../settings/header';
import { Checkbox, TextField } from '../../../components/generic';
import { AddressFields } from './customDataAddresses';
import { Spacer } from './common';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../config/config';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import { updateDefaultTemplate } from '../../../../application/actions/edit-bio';
import { FiInfo } from 'react-icons/fi';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import { Overlay } from '../../../components/common/overlay';
import { BtnB, BtnB2 } from '../common';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { createPortal } from 'react-dom';

interface Props {
  onBackClick: () => void;
  onSaveClick: (address: AddressFields, isEdit?: boolean) => void;
  editAddress?: AddressFields;
}

const AddAddress = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [newAddress, setNewAddress] = useState<AddressFields>(
    props.editAddress || {
      label: '',
      addressee: '',
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      city: '',
      country: '',
      isDefault: false,
    },
  );

  const [error, setError] = useState<'none-entered' | 'label-missing' | false>(false);
  const { getAccessTokenSilently } = useAuth0();

  useDeferredLoader(isLoading, 'save-address-template-loader-id');

  const onSuccess = () => {
    toast.success(t('addressesTab.saveSuccess'));
    props.onBackClick();
  };

  const onFailed = (msg: string) => toast.error(msg);

  const onSaveClick = () => {
    if (
      (!newAddress.addressee || newAddress.addressee.trim() === '') &&
      (!newAddress.addressLine1 || newAddress.addressLine1.trim() === '') &&
      (!newAddress.addressLine2 || newAddress.addressLine2.trim() === '') &&
      (!newAddress.postCode || newAddress.postCode?.trim() === '') &&
      (!newAddress.city || newAddress.city?.trim() === '') &&
      (!newAddress.country || newAddress.country?.trim() === '')
    ) {
      setError('none-entered');
      return;
    }
    if (!newAddress.label || newAddress.label.trim() === '') {
      setError('label-missing');
      return;
    }
    setIsLoading(true);
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        props.editAddress != null
          ? axios.patch(
              config.API_BASE_URL + `theme/address-templates/${props.editAddress.id}`,
              {
                addressee: newAddress.addressee || undefined,
                addressLine1: newAddress.addressLine1 || undefined,
                addressLine2: newAddress.addressLine2 || undefined,
                city: newAddress.city || undefined,
                label: newAddress.label || undefined,
                country: newAddress.country || undefined,
                postCode: newAddress.postCode || undefined,
                isDefault: newAddress.isDefault || undefined,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
          : axios.post(
              config.API_BASE_URL + `theme/address-templates`,
              {
                addressee: newAddress.addressee,
                addressLine1: newAddress.addressLine1,
                addressLine2: newAddress.addressLine2,
                city: newAddress.city,
                label: newAddress.label,
                country: newAddress.country,
                postCode: newAddress.postCode,
                isDefault: newAddress.isDefault,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ),
      [PERMISSIONS.WRITE.THEME_GLOBAL],
    )
      .then(res => {
        setIsLoading(false);
        if (newAddress.isDefault) {
          dispatch(updateDefaultTemplate(res.data.data.id, t));
        }
        props.onSaveClick(res.data.data, props.editAddress != null);
        onSuccess();
      })
      .catch(err => {
        onFailed(t('leadError'));
        setIsLoading(false);
      });
  };
  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    transformFct?: (arg: string) => string,
  ) => {
    const { name } = e.target;
    let { value } = e.target;
    if (transformFct) {
      value = transformFct(value);
    }
    const _newAddress = { ...newAddress };
    _newAddress[name] = value;
    setNewAddress(_newAddress);
  };

  const RenderCheckboxText = () => (
    <HorizontalDiv>
      <CheckboxText>{t('addressesTab.makeAddressDefault')}</CheckboxText>
    </HorizontalDiv>
  );
  return createPortal(
    <>
      <Overlay />
      <AddContainer>
        <SettingsHeader
          headerText={t('addressesTab.addNewAddress')}
          onBackClick={props.onBackClick}
        />
        <InputContainers>
          {error && (
            <ErrorMessage>
              <FiInfo size={12} color='red' />
              <Spacer size={9} />
              {error === 'none-entered'
                ? t('addressesTab.errorWriteAddress')
                : error === 'label-missing'
                ? t('addressesTab.errorWriteLabel')
                : 'Error'}
            </ErrorMessage>
          )}
          <TextField
            label={t('address.label') + '*'}
            name={'label'}
            value={newAddress?.label}
            angles='normal'
            type='text'
            border={borderProps}
            onChange={e => {
              if (e.target.value !== '') setError(false);
              handleInputChange(e);
            }}
            font={{ size: '18px', weight: '400' }}
            fullWidth
            padding={{ left: '0' }}
          />

          <TextField
            label={t('address.addressee')}
            name={'addressee'}
            value={newAddress?.addressee}
            angles='normal'
            type='text'
            border={borderProps}
            onChange={e => {
              if (e.target.value !== '') setError(false);
              handleInputChange(e);
            }}
            font={{ size: '18px', weight: '400' }}
            fullWidth
            padding={{ left: '0' }}
          />
          <TextField
            label={t('address.addressLine1')}
            name={'addressLine1'}
            value={newAddress?.addressLine1}
            angles='normal'
            type='text'
            border={borderProps}
            onChange={e => {
              if (e.target.value !== '') setError(false);
              handleInputChange(e);
            }}
            font={{ size: '18px', weight: '400' }}
            fullWidth
            padding={{ left: '0' }}
          />
          <TextField
            label={t('address.addressLine2')}
            name={'addressLine2'}
            value={newAddress?.addressLine2}
            angles='normal'
            type='text'
            border={borderProps}
            onChange={e => {
              if (e.target.value !== '') setError(false);
              handleInputChange(e);
            }}
            font={{ size: '18px', weight: '400' }}
            fullWidth
            padding={{ left: '0' }}
          />
          <HorizontalDiv>
            <LineTextField
              label={t('address.postCode')}
              name={'postCode'}
              value={newAddress?.postCode}
              angles='normal'
              type='text'
              border={borderProps}
              onChange={e => {
                if (e.target.value !== '') setError(false);
                handleInputChange(e);
              }}
              font={{ size: '18px', weight: '400' }}
              fullWidth
              padding={{ left: '0' }}
            />
            <Spacer size={9} />
            <LineTextField
              label={t('address.city')}
              name={'city'}
              value={newAddress?.city}
              angles='normal'
              type='text'
              border={borderProps}
              onChange={e => {
                if (e.target.value !== '') setError(false);
                handleInputChange(e);
              }}
              font={{ size: '18px', weight: '400' }}
              fullWidth
              padding={{ left: '0' }}
            />
          </HorizontalDiv>
          <TextField
            label={t('address.country')}
            name={'country'}
            value={newAddress?.country}
            angles='normal'
            type='text'
            border={borderProps}
            onChange={e => {
              if (e.target.value !== '') setError(false);
              handleInputChange(e);
            }}
            font={{ size: '18px', weight: '400' }}
            fullWidth
            padding={{ left: '0' }}
          />
          <CheckboxContainer>
            <Checkbox
              key={'isDefault'}
              name={'isDefault'}
              value={newAddress?.isDefault}
              title={() => <RenderCheckboxText />}
              checked={true}
              onChange={() => setNewAddress({ ...newAddress, isDefault: !newAddress.isDefault })}
            />
            <TooltipInfo
              text={t('addressesTab.addressDefaulTooltip')}
              placement='top'
              icon={{ size: 14 }}
            />
          </CheckboxContainer>
          <Spacer size={30} />
        </InputContainers>
        <ButtonWrapper>
          <BtnB type='button' value={t<string>('save')} onClick={onSaveClick} />
          <BtnB2 type='button' value={t<string>('cancel')} onClick={props.onBackClick} />
        </ButtonWrapper>
      </AddContainer>
    </>,
    document.getElementById('portal'),
  );
};

export default AddAddress;

const AddContainer = styled.div`
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  background: #fff;
  z-index: 10;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  box-sizing: border-box;
  animation: animationDropLeft 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
`;

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const LineTextField = styled(TextField)`
  display: flex;
  flex: 1;
`;

const InputContainers = styled.div`
  min-height: 100vh;
  padding: 25px;
`;

const CheckboxText = styled.div`
  font-size: 14px;
  font-weight: normal;
  margin-left: 9px;
`;

const ButtonWrapper = styled.div`
  background-color: #f6f8ff;
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transform: scale(1.1);
`;

const CheckboxContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
