import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { addCode } from '../../../application/actions/landing';
import { Button, Popup, Loader } from '../../components/common';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Text } from '../../components/generic';

interface Props {
  code: string;
  onCloseClick: () => void;
}

const AddCodePopup = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { addCodeLoading, addCodeResponse } = useAppSelector(state => state.landing);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onAddCodeClick = () => {
    dispatch(addCode(props.code, getAccessTokenSilently));
  };

  return (
    <>
      {addCodeLoading && <Loader />}
      {!addCodeLoading && !addCodeResponse.isSuccess && (
        <Popup onCloseClick={props.onCloseClick} withSideBar={false}>
          <AddCodeWrapper>
            <TextBlock>
              <Text
                text={
                  <>
                    {t('newCardDetection')}
                    <br />
                    {t('connectProfile')}
                  </>
                }
                variant='strong'
              />
            </TextBlock>
            <ButtonBlock>
              <Button noBg text={t('cancel')} onClick={() => props.onCloseClick()} />
              <Button text={t('connectCard')} onClick={() => onAddCodeClick()} />
            </ButtonBlock>
          </AddCodeWrapper>
        </Popup>
      )}
      {!addCodeLoading && addCodeResponse.isSuccess && (
        <Popup onCloseClick={props.onCloseClick}>
          <AddCodeWrapper>
            <TextBlock>
              <Text
                text={
                  <>
                    <CongratsIcon role='img' aria-label='congrats-emoji'>
                      🎉
                    </CongratsIcon>{' '}
                    <br />
                    <br />
                    {t('addCodeSuccess')}
                  </>
                }
                variant='strong'
              />
            </TextBlock>
          </AddCodeWrapper>
        </Popup>
      )}
    </>
  );
};

export default AddCodePopup;

const AddCodeWrapper = styled.div``;

const TextBlock = styled.div`
  padding: 10px 0 30px 0;
`;

const CongratsIcon = styled.span`
  font-size: 4rem;
`;

const ButtonBlock = styled.div`
  div {
    display: inline-block;
    width: 47%;
    text-align: center;
  }
`;
