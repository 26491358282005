import { RiLinkedinFill } from 'react-icons/ri';
import { BiCopy } from 'react-icons/bi';
import styled from 'styled-components';
import { mainColor, secondaryColor } from './constants';
import { Button, Heading, SubText, TextField } from '../../components/generic';
import FlexWrapper from '../../components/generic/FlexWrapper';
import TooltipInfo from '../../components/generic/TooltipInfo';
import { DESKTOP_BREAK_POINT } from '../../../shared/constants';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { Trans } from 'react-i18next';
import { Dispatch, SetStateAction, useRef } from 'react';

type Props = {
  emailCard?: boolean;
  generateReferralLink?: () => void;
  email?: {
    referralEmail?: string;
    setReferralEmail?: Dispatch<SetStateAction<string>>;
    handleSendEmail?: (onSuccess?: () => void) => void;
  };
};

const CollectCard = (props: Props) => {
  const { t } = useAppTranslation();
  const anchor = useRef(null);
  return (
    <Wrapper multiRows={props?.emailCard}>
      {props?.emailCard ? (
        <>
          <div style={{ marginBottom: '2rem' }}>
            <FlexWrapper sameFlow direction='column'>
              <TooltipInfo
                text={t('referrals.collect.email.tooltip')}
                style={{ alignSelf: 'end', marginTop: '1rem', marginRight: '-1.5rem' }}
                arrow
              />
              <Heading margin={{ all: '0' }} size='2.3rem' fixSize>
                {t('referrals.collect.email.title')}
              </Heading>
            </FlexWrapper>
            <SubText color='#000' fontWeight={'400'}>
              <Trans
                i18nKey='referrals.collect.email.subtext'
                components={[<span style={{ fontWeight: '600' }} />]}
              />
            </SubText>
          </div>
          <InputWrapper>
            <TextField
              name='email'
              value={props?.email?.referralEmail}
              onChange={e => props?.email?.setReferralEmail(e.target.value)}
              placeholder={t('referrals.collect.email.placeholder')}
              bgColor={{ normal: '#fff', hover: '#fff' }}
              border={{ normal: '1px solid #DADAE5', focus: '1px solid #DADAE5' }}
              margin={{ all: '0' }}
              font={{ size: '1.4rem' }}
            />{' '}
            {/* Handle send invitaion email */}
            <Button
              text={t('referrals.collect.email.button')}
              onClick={() =>
                props?.email?.handleSendEmail(() => props?.email?.setReferralEmail(''))
              }
              size='1.4rem'
              width='fit-content'
              txtColor={'#fff'}
              bgColor={mainColor}
              padding={{ all: '1rem' }}
              radius='4px'
              margin={{ left: '1rem', right: '1rem', top: '0' }}
              style={{ height: '100%', justifySelf: 'right' }}
            />
          </InputWrapper>
          <Button
            text={t('referrals.collect.email.copyButton')}
            size='1.4rem'
            onClick={props.generateReferralLink}
            icon={{ element: <BiCopy />, left: true }}
            width='fit-content'
            txtColor={secondaryColor}
            bgColor={'transparent'}
            padding={{ all: '1rem' }}
            radius='4px'
            borderColor={secondaryColor}
          />
        </>
      ) : (
        <>
          <div></div>
          <div>
            <FlexWrapper sameFlow style={{ alignItems: 'center' }}>
              <RiLinkedinFill
                size={30}
                color={'#2967BC'}
                style={{
                  border: '3px solid #2967BC',
                  borderRadius: '3px',
                  marginRight: '1rem',
                  minWidth: '30px',
                }}
              />
              <Heading margin={{ all: '0' }} weight='500' size='2.5rem' fixSize>
                {t('referrals.collect.linkedin.title')}
              </Heading>
            </FlexWrapper>
            <SubText color='#000' fontWeight={'400'}>
              <Trans
                i18nKey='referrals.collect.linkedin.subtext'
                components={[
                  <span style={{ fontWeight: '600' }} />,
                  <br />,
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    ref={anchor}
                    href='mailto:contact@lemontaps.com'
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: '#000', fontWeight: '600' }}
                    onMouseOver={() => (anchor.current.style.color = '#000')}
                  />,
                ]}
              />
            </SubText>
          </div>
          <div></div>
        </>
      )}
    </Wrapper>
  );
};

export default CollectCard;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${props => (props.multiRows ? '50% 25% 25%' : '1fr 50% 1fr')};
  justify-content: start;
  align-items: center;
  width: 50%;
  min-height: 248px;
  padding: 2rem;
  margin-right: 1rem;
  background: #ffffff;
  border: 1px solid rgba(24, 26, 90, 0.16);
  border-radius: 8px;
  @media (max-width: ${DESKTOP_BREAK_POINT}px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 65% 35%;
  align-items: center;
`;
