import { Center, FeatureComparisonButton, Section } from '../styled';

import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';

const FullFeatureComparisonSection = () => {
  const { t, activeLanguage } = useAppTranslation();

  return (
    <Section>
      <Center>
        <FeatureComparisonButton
          target='_blank'
          href={
            activeLanguage.startsWith('de')
              ? 'https://lemontaps.de/pages/preise'
              : 'https://lemontaps.de/en/pages/prices'
          }
        >
          {t('teaser.comparison')}
        </FeatureComparisonButton>
      </Center>
    </Section>
  );
};

export default FullFeatureComparisonSection;
