import { ACCOUNT_FIELDS_MAX_LENGTH } from '../constants';
import { Account } from '../types/api';
import { Unit } from '../types/api/unit.type';
import { AccountFieldsErr, AccountImportData } from '../types/global';
import { nonNullable } from '../types/util';

export const validateEmail = (value: string): boolean => {
  // SOURCE: https://community.auth0.com/t/email-validation-rules-please-make-them-public-knowledge/29532
  // SAME regExp THAT AUTH0 USES
  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const result = regExp.exec(value.toLowerCase());
  return !!result && result[0] !== null;
};

/*
  Auth0 fields rules:
  __email__ The maximum length is 64 characters for the user/local part and 256 characters for the domain part.
  https://auth0.com/docs/manage-users/user-accounts/create-users
  first
  __names__ The maximum length is 150 chars for each first & last name property.
   https://community.auth0.com/t/firstname-lastname-middlename-chracter-length/66157/5
*/

export const checkAccountFieldsLength = (acc: AccountImportData): AccountFieldsErr => {
  const err: AccountFieldsErr = {};
  const email_user = acc.email?.split('@').shift();
  const email_domain = acc.email?.split('@').pop();

  if (
    !!email_user &&
    !!email_domain &&
    (email_user.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAILUSER ||
      email_domain.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAILDOMAIN)
  )
    err.email = true;

  if (!!acc.workEmail && acc.workEmail.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAIL)
    err.workEmail = true;

  if (!!acc.workEmail2 && acc.workEmail2.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAIL)
    err.workEmail2 = true;

  if (!!acc.id && acc.id.length > ACCOUNT_FIELDS_MAX_LENGTH.ID) err.id = true;

  if (!!acc.firstName && acc.firstName.length > ACCOUNT_FIELDS_MAX_LENGTH.FIRSTNAME)
    err.firstName = true;

  if (!!acc.lastName && acc.lastName.length > ACCOUNT_FIELDS_MAX_LENGTH.LASTNAME)
    err.lastName = true;

  if (!!acc.username && acc.username.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.username = true;

  if (!!acc.position && acc.position.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.position = true;

  if (!!acc.role && acc.role.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.role = true;

  if (!!acc.division && acc.division.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.division = true;

  if (!!acc.company && acc.company.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.company = true;

  if (!!acc.mobilePhone && acc.mobilePhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.mobilePhone = true;

  if (!!acc.workPhone && acc.workPhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.workPhone = true;

  if (!!acc.homePhone && acc.homePhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.homePhone = true;

  if (!!acc.hotlinePhone && acc.hotlinePhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.hotlinePhone = true;

  if (!!acc.assistantPhone && acc.assistantPhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.assistantPhone = true;

  if (!!acc.workFax && acc.workFax.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) err.workFax = true;

  if (!!acc.address1_addressee && acc.address1_addressee.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_addressee = true;
  if (
    !!acc.address1_addressLine1 &&
    acc.address1_addressLine1.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address1_addressLine1 = true;
  if (
    !!acc.address1_addressLine2 &&
    acc.address1_addressLine2.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address1_addressLine2 = true;
  if (!!acc.address1_postCode && acc.address1_postCode.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.address1_postCode = true;
  if (!!acc.address1_city && acc.address1_city.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_city = true;
  if (!!acc.address1_country && acc.address1_country.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_country = true;
  if (!!acc.address1_label && acc.address1_label.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_label = true;

  if (!!acc.address2_addressee && acc.address2_addressee.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_addressee = true;
  if (
    !!acc.address2_addressLine1 &&
    acc.address2_addressLine1.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address2_addressLine1 = true;
  if (
    !!acc.address2_addressLine2 &&
    acc.address2_addressLine2.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address2_addressLine2 = true;
  if (!!acc.address2_postCode && acc.address2_postCode.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.address2_postCode = true;
  if (!!acc.address2_city && acc.address2_city.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_city = true;
  if (!!acc.address2_country && acc.address2_country.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_country = true;
  if (!!acc.address2_label && acc.address2_label.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_label = true;

  if (
    !!acc.addressTemplate1Id &&
    (acc.addressTemplate1Id > ACCOUNT_FIELDS_MAX_LENGTH.INTEGER ||
      acc.addressTemplate1Id < -ACCOUNT_FIELDS_MAX_LENGTH.INTEGER)
  )
    err.addressTemplate1Id = true;

  if (
    !!acc.addressTemplate2Id &&
    (acc.addressTemplate2Id > ACCOUNT_FIELDS_MAX_LENGTH.INTEGER ||
      acc.addressTemplate2Id < -ACCOUNT_FIELDS_MAX_LENGTH.INTEGER)
  )
    err.addressTemplate2Id = true;

  if (!!acc.workUrl && acc.workUrl.length > ACCOUNT_FIELDS_MAX_LENGTH.URL) err.workUrl = true;

  Object.keys(err).length > 0 && (err.user = acc.email);

  return err;
};

export const isInFuture = (date?: Date | string | null): boolean =>
  !!(date && new Date(date).getTime() > new Date().getTime());

export const getContactFormTranslationKeyByField = (field: string): string => {
  if (field.toLowerCase().includes('phone')) return `${field}.text`;

  if (field === 'gdprLegalBasis') return `gdpr`;

  return field;
};

/**
 * alternative to String.prototype.replaceAll native helper which not supported in old browsers
 * @param str  the string to replace inside
 * @param find the string to be replaced
 * @param replace the string to be replaced with
 * @returns replaced string
 */
export const replaceAll = (str: string, find: string, replace: string) =>
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

/**
 * escape string from regular expression special charachters
 * @param string
 * @returns escaped string
 */
const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export const getMainUnitSync = (account: Account): null | Unit =>
  account.accountToUnits?.find(atu => atu.isMain)?.unit || null;
export const getOtherUnitsSync = (account: Account): Array<Unit> =>
  account.accountToUnits
    ?.filter(atu => !atu.isMain)
    ?.map(atu => atu.unit)
    .filter(nonNullable) || [];

export const appendParamsToLink = (link: string, params: Record<string, string | number>) => {
  const url = new URL(link);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value.toString());
  });
  return url.toString();
};
