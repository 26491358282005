import React from 'react';
import styled, { css } from 'styled-components';

import folderIcon from '../../images/folder.svg';
import { invertHex } from '../../../infrastructure/helper';
import { ApiFile } from '../../../shared/types/api';
import { THEME_BOX_STYLES } from '../../../shared/constants';
import { ThemeBoxStyle } from '@/shared/types/global';

interface Props {
  item: ApiFile;
  fileBoxColor: string;
  boxStyle: ThemeBoxStyle;
  trackHandler: () => void;
}
interface IProps {
  $fileBoxColor: string;
  $boxStyle: string;
  $txtColor: string;
}

const FileBox: React.FC<Props> = ({ item, boxStyle, fileBoxColor, trackHandler }) => {
  const txtColor = invertHex(fileBoxColor, true);
  return (
    <Link href={item.link} target='_blank' rel='noopener noreferrer' onClick={trackHandler}>
      <FileWrapper $fileBoxColor={fileBoxColor} $boxStyle={boxStyle} $txtColor={txtColor}>
        <Image src={folderIcon} alt='folder-icon' $txtColor={txtColor} />
        <Name>{item.fileName}</Name>
      </FileWrapper>
    </Link>
  );
};

export default FileBox;

const Link = styled.a``;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  background: ${(props: IProps) => props.$fileBoxColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  height: fit-content;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;

  ${(props: IProps) =>
    props.$boxStyle === THEME_BOX_STYLES.ANGULAR
      ? css`
          border-radius: 0;
        `
      : css`
          border-radius: 30px;
        `}

  & > span {
    color: ${(props: IProps) => props.$txtColor};
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
`;

const Image = styled.img`
  position: absolute;
  left: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  filter: brightness(0)
    ${(props: IProps) => props.$txtColor.toLowerCase() === '#ffffff' && 'invert(1)'};

  @media (max-width: 280px) {
    height: 20px;
  }
`;

const Name = styled.span`
  text-overflow: clip;
  overflow-wrap: anywhere;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
