import LtVideo from '@/components/LtVideo';
import { invertHex } from '@/infrastructure/helper';
import { THEME_BOX_STYLES } from '@/shared/constants';
import { UrlsBox as UrlsBoxType } from '@/shared/types/api';
import { ThemeBoxStyle } from '@/shared/types/global';
import Layout from '@/views/components/common/white-layout';
import styled, { css } from 'styled-components';

// TODO: profile page optimizations

interface Props {
  config: UrlsBoxType;
  boxStyle: ThemeBoxStyle;
  onTrackLinkClick: (linkId: number) => void;
  onTrackFileClick: (fileId: number, pageCount: number) => void;
}

export default function UrlsBox({ config, boxStyle, onTrackFileClick, onTrackLinkClick }: Props) {
  return (
    <Layout headerText={config.label} themeBoxStyle={boxStyle}>
      {config.items.map((item, idx) => {
        switch (item.renderAs) {
          case 'link':
            return (
              <FileWrapper
                $fileBoxColor={item.backgroundCss}
                $boxStyle={boxStyle}
                $txtColor={invertHex(item.backgroundCss, true)}
                href={item.url}
                target='_blank'
                rel='nooperner noreferrer'
                onClick={
                  config.type === 'files'
                    ? () => onTrackFileClick(item.meta.itemId, item.meta.pageCount)
                    : () => onTrackLinkClick(item.meta.itemId)
                }
              >
                <Image
                  src={item.iconUrl}
                  alt='icon'
                  $txtColor={invertHex(item.backgroundCss, true)}
                />
                <Name>{item.text}</Name>
              </FileWrapper>
            );
          case 'video':
            return (
              <LtVideo
                label={item.text}
                embedUrl={item.src}
                sx={{
                  marginBottom: '20px',
                  width: '100%',
                }}
                labelSx={{
                  // adjusted to be aligned with other url box labels
                  color: '#000',
                  fontWeight: 600,
                  fontSize: '1.4rem',
                  letterSpacing: '0.3px',
                }}
              />
            );
          default:
            return null;
        }
      })}
    </Layout>
  );
}

interface IProps {
  $fileBoxColor: string;
  $boxStyle: string;
  $txtColor: string;
}
const FileWrapper = styled.a`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  background: ${(props: IProps) => props.$fileBoxColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  height: fit-content;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;

  ${(props: IProps) =>
    props.$boxStyle === THEME_BOX_STYLES.ANGULAR
      ? css`
          border-radius: 0;
        `
      : css`
          border-radius: 30px;
        `}

  & > span {
    color: ${(props: IProps) => props.$txtColor};
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  &:focus-visible {
    outline: 2px solid
      ${props => (props.$fileBoxColor?.startsWith('linear') ? '#000000' : props.$fileBoxColor)};
    outline-offset: 2px;
  }
`;

const Image = styled.img`
  position: absolute;
  left: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  filter: brightness(0)
    ${(props: IProps) => props.$txtColor.toLowerCase() === '#ffffff' && 'invert(1)'};

  @media (max-width: 280px) {
    height: 20px;
  }
`;

const Name = styled.span`
  text-overflow: clip;
  overflow-wrap: anywhere;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
