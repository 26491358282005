import { Box } from '@mui/material';
import { UpgradeAlert } from './UpgradeAlert';
import { useTranslation } from 'react-i18next';

export const RightPanelTeaser = () => {
  const { t } = useTranslation();
  return (
    <Box p={2} width='100%' height='100%'>
      <UpgradeAlert description={t('emailTemplates.upgradeAlertBody')} />
    </Box>
  );
};
