import { useLayoutEffect, useState } from 'react';

/**
 * @param cb function: should be wrapped with useCallback hook
 */
export default function useWindowSize(cb: Function = null) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      cb?.();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [cb]);
  return size;
}
