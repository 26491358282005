import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import {
  cardsShopLinkDe,
  cardsShopLinkEn,
  rebuyCardsDe,
  rebuyCardsEn,
  routePaths,
} from '@/infrastructure/constants';
import moment from 'moment';
import withNav from '@/infrastructure/hoc/withNav';
import { connectCode, deleteAccountCode, getAccountCodes } from '@/infrastructure/apis/account';
import { LtDialog, PageContainer } from '@/components';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Add, Close, DeleteOutline, ShoppingCartOutlined } from '@mui/icons-material';
import { ScanCardPopup } from './components/ScanCardPopup';
import { EnterCodePopup } from './components/EnterCodePopup';
import { NoCards } from './components/NoCards';
import { useDispatch } from 'react-redux';
import { updateActionPreferences } from '@/application/actions/account';
import { useAppSelector } from '@/application/hooks';
import { Account, ActionPreferences } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { openLink } from '@/util';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';

type CardType = { date: string | null; code: string };

const SingleCard = ({
  card,
  isLast,
  onDelete,
}: {
  card: CardType;
  isLast: boolean;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        bgcolor='white'
        borderBottom={!isLast && '1px solid'}
        borderColor='divider'
        px='1.6rem'
        py='1.2rem'
        display='flex'
        alignItems='center'
      >
        <Box flex='1'>
          <Typography variant='body2'>{t('cards.card', { code: card.code })}</Typography>
          <Typography variant='body2' color={theme => theme.palette.text.secondary}>
            {card.date ? moment(card.date).format('LLL') : t('cards.nodate')}
          </Typography>
        </Box>
        <IconButton onClick={onDelete}>
          <DeleteOutline />
        </IconButton>
      </Box>
    </>
  );
};

const MyCards = () => {
  const { t, activeLanguage } = useAppTranslation();
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [cards, setCards] = useState<CardType[]>([]);
  const [selectedCodeForDelete, setSelectedCodeForDelete] = useState('');
  const [codeIsChecking, setCodeIsChecking] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const [scanCardPopupVisible, setScanCardPopupVisible] = useState(false);
  const [enterCodePopupVisible, setEnterCodePopupVisible] = useState(false);
  const dispatch = useDispatch();
  const account = useAppSelector<Account>(state => state.account);

  const actionPreferences = account.actionPreferences as ActionPreferences;

  const { isStarter } = useTierInfo();
  const { editRights } = useEditRights();
  const shouldRenderOrderButton = isStarter || editRights.rebuyCards;
  const orderButtonLabel = isStarter ? t('cards.orderCard') : t('cards.reorderCard');

  const handleCardOrderClick = useCallback(() => {
    if (isStarter)
      openLink(activeLanguage.startsWith('de') ? cardsShopLinkDe : cardsShopLinkEn, '_blank');
    else openLink(activeLanguage.startsWith('de') ? rebuyCardsDe : rebuyCardsEn, '_blank');
  }, [activeLanguage, isStarter]);

  const updateAccountPreferences = useCallback(
    (value: boolean) => {
      dispatch(
        updateActionPreferences(getAccessTokenSilently, {
          myCards_reconnectCardAlertVisible: value,
        }),
      );
    },
    [dispatch, getAccessTokenSilently],
  );

  const fetchCards = useCallback(async () => {
    setIsLoadingCards(true);

    try {
      const res = await getAccountCodes(getAccessTokenSilently);
      setCards(res.data);
    } catch (error) {
      toast.error(t('error.general'));
    }
    setIsLoadingCards(false);
  }, [getAccessTokenSilently, t]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  const handleSubmit = useCallback(
    async (_code: string) => {
      setCodeIsChecking(true);
      if (cards.find(card => card.code === _code)) {
        toast.error(t('cards.cardAlreadyConnected'));
        setCodeIsChecking(false);
        setScanCardPopupVisible(false);
        return;
      }
      try {
        const { data } = await connectCode(getAccessTokenSilently, _code);
        setEnterCodePopupVisible(false);
        if (cards.length === 0) updateAccountPreferences(true);
        setCards([{ code: _code, date: data?.date || new Date() }, ...cards]);

        toast.success(t('cards.successUpload'));
      } catch (error) {
        toast.error(t('cards.errorUpload'));
      }
      setScanCardPopupVisible(false);
      setCodeIsChecking(false);
    },
    [getAccessTokenSilently, t, cards, updateAccountPreferences],
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteCode = async () => {
    setIsDeleting(true);
    try {
      await deleteAccountCode(getAccessTokenSilently, selectedCodeForDelete);
      setCards(cards.filter(card => card.code !== selectedCodeForDelete));
      setSelectedCodeForDelete('');
      toast.success(t('cards.successDelete'), { id: 'delete-code-toast' });
    } catch (error) {
      toast.error(t('cards.errorDelete'), { id: 'delete-code-toast' });
    }
    setIsDeleting(false);
  };

  const isDesktop = useIsDesktopMui();

  const addCardButton = useMemo(
    () => (
      <Button
        startIcon={<Add />}
        onClick={() => setScanCardPopupVisible(true)}
        fullWidth={!isDesktop}
      >
        {t('cards.connectCard')}
      </Button>
    ),
    [isDesktop, t],
  );

  const orderCardButton = useMemo(
    () =>
      shouldRenderOrderButton ? (
        <Button
          variant='outlined'
          startIcon={<ShoppingCartOutlined />}
          color='primary'
          fullWidth={!isDesktop}
          onClick={handleCardOrderClick}
        >
          {orderButtonLabel}
        </Button>
      ) : null,
    [handleCardOrderClick, isDesktop, orderButtonLabel, shouldRenderOrderButton],
  );

  const headerActions = useMemo(
    () =>
      shouldRenderOrderButton ? createHeaderAction(orderCardButton, { mobileOnly: true }) : [],
    [orderCardButton, shouldRenderOrderButton],
  );
  useHeaderActions(headerActions);

  const actions = [orderCardButton, addCardButton];

  return (
    <PageContainer maxWidth='m'>
      {isLoadingCards ? (
        <Box display='flex'>
          <CircularProgress sx={{ m: 'auto' }} />
        </Box>
      ) : cards.length === 0 ? (
        <NoCards actions={actions} />
      ) : (
        <>
          {actionPreferences?.myCards_reconnectCardAlertVisible && (
            <Alert
              severity='info'
              variant='outlined'
              action={
                <IconButton size='small' onClick={() => updateAccountPreferences(false)}>
                  <Close fontSize='small' />
                </IconButton>
              }
            >
              <AlertTitle>{t('cards.alert.title')}</AlertTitle>
              {t('cards.alert.subtitle')}
            </Alert>
          )}
          {isDesktop && (
            <Box
              display='flex'
              justifyContent='flex-end'
              gap='1.3rem'
              py='1.6rem'
              borderBottom='1px solid'
              borderColor='divider'
            >
              {actions}
            </Box>
          )}

          {!isLoadingCards && (
            <Paper
              variant='outlined'
              sx={{ maxWidth: '76rem', m: 'auto', mt: '1.6rem', marginBottom: '13rem' }}
            >
              {cards.map((card, index) => (
                <SingleCard
                  key={card.code}
                  card={card}
                  isLast={index === cards.length - 1}
                  onDelete={() => setSelectedCodeForDelete(card.code)}
                />
              ))}
            </Paper>
          )}
          {!isDesktop && (
            <Button
              startIcon={<Add />}
              size='large'
              sx={{ position: 'fixed', bottom: '13rem', left: '2rem', right: '2rem' }}
              onClick={() => setScanCardPopupVisible(true)}
            >
              {t('cards.connectCard')}
            </Button>
          )}
        </>
      )}
      <LtDialog
        size='sm'
        title={t('cards.deletePopup.title')}
        onClose={() => setSelectedCodeForDelete('')}
        onCancel={() => setSelectedCodeForDelete('')}
        onDelete={() => handleDeleteCode()}
        open={Boolean(selectedCodeForDelete)}
        loading={isDeleting}
      >
        {t('cards.deletePopup.description')}
      </LtDialog>
      <ScanCardPopup
        onScanFinish={handleSubmit}
        onClose={() => setScanCardPopupVisible(false)}
        onEnterCodeClick={() => {
          setScanCardPopupVisible(false);
          setEnterCodePopupVisible(true);
        }}
        loading={codeIsChecking}
        open={scanCardPopupVisible}
      />
      <EnterCodePopup
        onSubmit={handleSubmit}
        onClose={() => setEnterCodePopupVisible(false)}
        open={enterCodePopupVisible}
        loading={codeIsChecking}
      />
    </PageContainer>
  );
};

export default withNav(
  MyCards,
  {
    tTitle: 'myCards',
    showBackButton: {
      linkTo: null,
    },
  },
  {
    activeScreen: routePaths.NFC_CARDS,
  },
);
