import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import TooltipInfo from '../../../../../components/generic/TooltipInfo';

export interface ActionType {
  icon: JSX.Element;
  actionText: string;
  tooltipText: string;
  onClick: () => void;
}

const Action = (action: ActionType) => {
  const { icon, actionText, tooltipText, onClick } = action;

  return (
    <StyledMenuItem onClick={onClick}>
      <IconTextContainer>
        {icon}
        <MenuItemSpan>{actionText}</MenuItemSpan>
      </IconTextContainer>
      <TooltipContainer>
        <TooltipInfo onHover={true} text={tooltipText} placement='right' />
      </TooltipContainer>
    </StyledMenuItem>
  );
};

export default Action;

const StyledMenuItem = styled(MenuItem)`
  justify-content: space-between;
`;

const IconTextContainer = styled.div`
  display: inherit;
`;

const TooltipContainer = styled.div``;

const MenuItemSpan = styled.span`
  padding-left: 1rem;
`;
