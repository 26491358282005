import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isFileValid, isNotEmptyArray } from '../../../../infrastructure/helper';
import { Spacer, SubsectionSpan } from './common';
import CloudUpload from '../../../images/cloud-upload.svg';
import { IoIosClose } from 'react-icons/io';
import { Image } from './profile-design';

interface Props {
  images: Image[];
  onChange: (arr: Image[]) => void;
}

const AdditionalLogoUpload = ({ images, onChange }: Props) => {
  let fileInput = useRef(null);
  const { t } = useTranslation();

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let file = fileInput.current.files[0];
      if (file) {
        const errMsg = isFileValid(file, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }
        const [filename, ext] = file.name.split('.');
        const newFileName = `${filename}-${Date.now()}.${ext}`;
        const newFile = new File([file], newFileName, { type: file.type });
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onChange([
            ...images,
            { fileName: newFileName, url: reader.result as string, file: newFile },
          ]);
        };
      }
    }
  };

  return (
    <>
      <Spacer size={15} />
      <> {t('md.profileDesign.additionalLogosDescription')}</>
      <ImageOptionsContainer>
        <ImageOptionContainer>
          <UploadBox onClick={() => fileInput.current.click()}>
            <img src={CloudUpload} alt='upload' />
            <SubsectionSpan>
              {images && images.length > 0
                ? t('md.profileDesign.additionalLogosUploadFurtherButton')
                : t('md.profileDesign.additionalLogosUploadButton')}
            </SubsectionSpan>
          </UploadBox>
        </ImageOptionContainer>
      </ImageOptionsContainer>
      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
      <ImagesContainer>
        {!images?.length && <Hint>{t('md.profileDesign.additionalLogosCurrentSettings')}</Hint>}
        {images?.map((file, index) => (
          <OneImageContainer key={index}>
            <ArrayImage src={file.url} alt='logo' />
            <ClearImageButton
              onClick={() => {
                onChange(images.filter(image => image.fileName !== file.fileName));
              }}
            >
              <IoIosClose size={16} color='#4748EC' />
            </ClearImageButton>
          </OneImageContainer>
        ))}
      </ImagesContainer>
      <Spacer size={15} />
    </>
  );
};

export default AdditionalLogoUpload;

const ImageOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px 0px;
  margin-bottom: 30px;
`;

const ImageOptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 1.5vw;
    margin-bottom: 1.5vw;
  }
`;

const UploadBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2px dashed #c1cbdc;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 3px 5px;
  height: 5vw;
  cursor: pointer;

  img {
    width: 2vw;
    filter: invert(22%) sepia(87%) saturate(2678%) hue-rotate(236deg) brightness(94%) contrast(96%);
  }

  span {
    color: #4748ec;
  }
`;

const ArrayImage = styled.img`
  object-fit: contain;
  width: 167px;
  height: 110px;
`;
const ImagesContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 20px;
`;

const ClearImageButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-style: solid;
  border-width: 1px;
  border-color: #c1cbdc;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  cursor: pointer;
`;

const OneImageContainer = styled.div`
  width: 169px;
  height: 112px;
  position: relative;
  border-color: #c1cbdc;
  border-style: solid;
  border-width: 1px;
  margin-right: 14px;
  border-radius: 5px;
`;

const Hint = styled.div``;
