import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Employee } from '@/shared/types/api/employee.type';
import { useTranslation } from 'react-i18next';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { getProfileImage, getPhone, handleProfileClick } from '../utils/helpers';
import { FiSend, FiTrash } from 'react-icons/fi';
import { BiDownload, BiPencil, BiSend } from 'react-icons/bi';
import Status from './status';
import usePagination from '../hooks/usePagination';
import { TFunction } from 'i18next';
import { useImperativeHandle } from 'react';
import LinksCell from './linksCell';
import { TableProps } from '..';
import FilesCell from './filesCell';
import {
  ColumnDefinition,
  COLUMNS_DEFAULT,
  initColumnsDef,
  NON_SORTABLE_COLUMNS,
  saveColumnsDef,
} from '../utils/constants';
import { FiMinusSquare } from 'react-icons/fi';
import ColSortMenu from '../../colSortMenu';
import { Account } from '@/shared/types/api';
import { Unit } from '@/shared/types/api/unit.type';
import BulkActions from './bulkActions';
import { ActionsArr } from '../utils/actions';
import useActions from '../hooks/useActions';
import UnitAssign from './unitAssign';
import { FEATURE } from '@/shared/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { BulkButton, TableHeadWrapper, TableWrapper } from '@/views/pages/md/common/table';
import { LineClamp } from '@/views/components/common/LineClamp';
import { IconButton, TableSortLabel } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import moment from 'moment';
import { Loader } from '@/views/components/common';
import { HiOutlineMail } from 'react-icons/hi';
import { CSS_VARS } from '@/infrastructure/constants';
import { getMainUnitSync, getOtherUnitsSync } from '@/shared/util';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import cogIcon from '@/views/images/settings.svg';
import { bulkSendEmail } from '@/application/actions/md/profiles/bulk-update';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  numSelected: number;
  numEmployees: number;
  numStatus: number;
  selectedIds: readonly string[];
  selectedUsernames: readonly string[];
  isSelected: (emp: Employee) => boolean;
  selectEmployee: (employee: Employee) => void;
  selectAllEmployees: () => void;
  unselectAllEmployees: () => void;
  employees: Array<Employee>;
  handleEdit: (employee: Employee) => void;
  bulkEditOn: () => void;
  bulkDelete: () => void;
  SelectedObjects: TableProps['SelectedObjects'];
  units?: Array<Unit>;
  refreshEmployees: () => void;
  orderBy?: string | null;
  sort?: 'asc' | 'desc' | null;
  onColumnClick?: (column: ColumnDefinition['key']) => void;
  selectAllOnPage: () => void;
  unselectAllOnPage: () => void;
  isAnySelectedOnCurrentPage: boolean;
  isAllSelected: boolean;
  paginationProps: ReturnType<typeof usePagination>;
}

export interface TableUiHandle {
  setPage: (newPage: number) => void;
}
const TableUi = React.forwardRef<TableUiHandle, Props>((props, forwardedRef) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const isLoading = useAppSelector(state => state.md.isLoading);
  const { page, rowsPerPage, onPageChange } = props.paginationProps;
  const [selectAllVisible, setSelectAllVisibility] = useState(false);

  const theme = useAppSelector(state => state.account?.theme);

  const [columns, setColumns] = useState(
    initColumnsDef(isFeatureAllowed(FEATURE.UNITS), theme?.themeInternal?.multiUnitsAllowed),
  );
  const visibleColumns = useMemo(() => columns.filter(col => !col.hidden), [columns]);
  const [localEmployees, setLocalEmployees] = useState<Employee[]>([]);

  // useSelection props
  const {
    numSelected,
    numEmployees,
    numStatus,
    selectedIds,
    selectedUsernames,
    isSelected,
    selectEmployee,
    selectAllEmployees,
    unselectAllEmployees,
    isAllSelected,
    isAnySelectedOnCurrentPage,
    selectAllOnPage,
    unselectAllOnPage,
  } = props;
  const account = useAppSelector<Account>(state => state.account);
  // employees, useEdit props, useBulk props
  const { employees, handleEdit, bulkEditOn, bulkDelete, onColumnClick, orderBy, sort } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectAllVisibility(false);
  }, [employees, page]);

  const notActiveSelectedIds = useMemo(
    () =>
      employees?.reduce<string[]>((ids, employee) => {
        if (selectedIds?.includes(employee.id) && employee.status !== 'active')
          ids.push(employee.id);
        return ids;
      }, []),
    [employees, selectedIds],
  );

  useEffect(() => setLocalEmployees(employees), [employees]);

  const handleCheckboxClick = (employee: Employee) => {
    selectEmployee(employee);
  };

  const { actionLoading, sendWalletEmails, ...exportDownloadHandlers } =
    useActions(selectedUsernames);

  const showBlockingLoader = isLoading || actionLoading;

  const actions = ActionsArr(numSelected, exportDownloadHandlers, t);
  const distributeActions = useMemo(
    () => [
      {
        icon: <FiSend size={15} />,
        actionText: 'Wallet',
        tooltipText: t('bulkActions.distribute.wallet.tooltip'),
        onClick: () => sendWalletEmails(selectedIds),
      },
    ],
    [selectedIds, sendWalletEmails, t],
  );

  // to allow to reset the page when a search happens
  useImperativeHandle(forwardedRef, () => ({
    setPage: (newPage: number) => onPageChange(null, newPage),
  }));

  const handleBulkInvite = (
    selectedIds: readonly string[],
    t: TFunction,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) => {
    dispatch(bulkSendEmail(selectedIds, t, getAccessTokenSilently));
  };

  const handleHeaderCheckboxClick = () => {
    if (isAnySelectedOnCurrentPage) {
      unselectAllOnPage();
      setSelectAllVisibility(false);
      return;
    }
    selectAllOnPage();
    if (numSelected < numEmployees) {
      setSelectAllVisibility(true);
    }
  };

  const handleDeselectAll = () => {
    unselectAllEmployees();
    setSelectAllVisibility(false);
  };

  const RenderRow = ({ employee }: { employee: Employee }) => {
    const { id, username } = employee;
    const mainUnit = getMainUnitSync(employee);
    const { profileDesign } = useProfileDesignForUnits(mainUnit?.id || null);

    const isItemSelected = isSelected(employee);
    const renderCell = (colDef: ColumnDefinition) => {
      switch (colDef.key) {
        case 'status':
          return (
            <TableCell key={colDef.key} align='center' style={{ whiteSpace: 'nowrap' }}>
              {/* TODO: here the WRONG email field is being passed. It should be from auth0, but this is just the account.email field */}
              {employee.authRoles
                ?.filter(x => x.name === 'theme_admin')
                .map(role => <Chip key={role.name}>{t('employeeType.themeAdmin')}</Chip>) || null}
              {employee?.isUnitAdmin && <Chip>{t('employeeType.unitAdmin')}</Chip>}
              <Status {...employee} email={employee.email} />
            </TableCell>
          );
        case 'createdOn':
        case 'editedAt':
          return (
            <TableCell key={colDef.key} align='center' className='nowrap'>
              {moment(employee[colDef.key]).format('L LT')}
            </TableCell>
          );
        case 'mainUnit':
          return (
            <TableCell key={colDef.key} align='center' className='nowrap'>
              <LineClamp text={getMainUnitSync(employee)?.niceName} lineCount={2} />
            </TableCell>
          );
        case 'otherUnits':
          return (
            <TableCell key={colDef.key} align='center' className='nowrap'>
              <LineClamp
                text={getOtherUnitsSync(employee)
                  .map(u => u?.niceName)
                  .join(', ')}
                lineCount={2}
              />
            </TableCell>
          );
        case 'unit':
          return (
            <TableCell key={colDef.key} align='center' className='nowrap'>
              <LineClamp text={getMainUnitSync(employee)?.niceName} lineCount={2} />
            </TableCell>
          );
        case 'mobilePhone':
        case 'workPhone':
        case 'homePhone':
        case 'hotlinePhone':
        case 'assistantPhone':
        case 'workFax':
          return (
            <TableCell key={colDef.key} align='center' className='nowrap'>
              <LineClamp text={getPhone(employee[colDef.key])} lineCount={2} />
            </TableCell>
          );
        case 'language':
          return (
            <TableCell key={colDef.key} align='center'>
              {(employee?.preferredLang &&
                (employee?.preferredLang?.startsWith('de') ? t('german') : t('english'))) ||
                '-'}
            </TableCell>
          );
        case 'links':
          return (
            <TableCell key={colDef.key} align='center'>
              <LinksCell
                selectedLinks={props.SelectedObjects.selectedLinksProps}
                links={employee?.links || []}
              />
            </TableCell>
          );
        case 'files':
          return (
            <TableCell key={colDef.key} align='center'>
              <FilesCell
                selectedFiles={props.SelectedObjects.selectedFilesProps}
                files={employee?.files || []}
              />
            </TableCell>
          );
        default:
          return (
            <TableCell key={colDef.key} align='center'>
              <LineClamp text={employee[colDef.key] || ''} lineCount={2} />
            </TableCell>
          );
      }
    };
    return (
      <TableRow key={id}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            checked={isItemSelected}
            onClick={() => handleCheckboxClick(employee)}
          />
        </TableCell>
        <TableCell align='center'>
          <IconButton
            onClick={() => handleEdit(employee)}
            sx={{
              backgroundColor: muiTheme.palette.primaryButton.main,
              ':hover': { backgroundColor: muiTheme.palette.primaryButton.main },
            }}
          >
            <BiPencil color={muiTheme.palette.primaryButton.contrastText} size={16} />
          </IconButton>
        </TableCell>
        <TableCell align='center'>
          <RowProfileImage>
            <img
              onClick={() => handleProfileClick(username)}
              src={getProfileImage(employee, profileDesign)}
              alt='profile-img'
            />
          </RowProfileImage>
        </TableCell>
        {visibleColumns.map(colDef => renderCell(colDef))}
      </TableRow>
    );
  };

  const employeeList = localEmployees?.map((employee: Employee) => (
    <RenderRow key={employee.id} employee={employee} />
  ));

  return (
    <>
      {showBlockingLoader && <Loader />}

      <TableWrapper hasPagination hasBulkActions className='lt-usetiful--md-table-container'>
        <TableHeadWrapper>
          {numSelected > 0 && (
            <BulkIndicatorSpan>
              {numSelected === numEmployees
                ? t('xAllProfilesSelected')
                : t('xProfilesSelected', { count: numSelected })}
            </BulkIndicatorSpan>
          )}
          <BulkWrapper>
            {numSelected > 0 && (
              <>
                <BulkButton
                  onClick={handleDeselectAll}
                  color={getComputedStyle(document.documentElement, null).getPropertyValue(
                    CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
                  )}
                  style={{ marginRight: '2rem' }}
                >
                  <FiMinusSquare size={15} />
                  <span>{t('deselectAll')}</span>
                </BulkButton>
                <BulkButton
                  onClick={
                    numSelected > 1
                      ? bulkEditOn
                      : () =>
                          handleEdit(localEmployees?.find(e => selectedIds.some(id => id === e.id)))
                  }
                  color={getComputedStyle(document.documentElement, null).getPropertyValue(
                    CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
                  )}
                >
                  <BiPencil size={15} />
                  <span>{t('editSelected', { count: numSelected })}</span>
                </BulkButton>

                {notActiveSelectedIds.length > 0 && (
                  <BulkButton
                    onClick={() =>
                      handleBulkInvite(notActiveSelectedIds, t, getAccessTokenSilently)
                    }
                    color={getComputedStyle(document.documentElement, null).getPropertyValue(
                      CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
                    )}
                  >
                    <BiSend size={15} />
                    <span>{t('inviteSelected', { count: numStatus })}</span>
                  </BulkButton>
                )}

                <BulkActions
                  label='Export / Download'
                  actions={actions}
                  icon={<BiDownload size={15} />}
                />

                <BulkActions
                  label={t('bulkActions.distribute.title')}
                  actions={distributeActions}
                  icon={<HiOutlineMail size={15} />}
                />
                {isFeatureAllowed(FEATURE.UNITS) && props.units && props.units.length > 0 && (
                  <UnitAssign
                    units={!isThemeAdmin ? account.units : props.units}
                    selectedIds={selectedIds}
                    localEmployees={localEmployees}
                    setLocalEmployees={setLocalEmployees}
                    onSaveSuccess={() => {
                      props.refreshEmployees();
                    }}
                  />
                )}

                <BulkButton onClick={bulkDelete} redColor>
                  <FiTrash color='#ef0000' size={14} />
                  {/* TODO:: check if this text is being rendered */}
                  <SpanWithMargin>{t('deleteSelected', { count: numSelected })}</SpanWithMargin>
                </BulkButton>
              </>
            )}
          </BulkWrapper>
          <ColSortWrapper>
            <ColSortMenu
              toggleElement={
                <CogButton>
                  <img src={cogIcon} alt='edit columns' />
                  <span>{t('editColumns')}</span>
                </CogButton>
              }
              columnDefs={columns}
              setColumnDefs={newDef => {
                setColumns(newDef);
                saveColumnsDef(
                  newDef,
                  isFeatureAllowed(FEATURE.UNITS),
                  theme.themeInternal.multiUnitsAllowed,
                );
              }}
              reset={() => {
                const newDef = [
                  ...COLUMNS_DEFAULT(
                    isFeatureAllowed(FEATURE.UNITS),
                    theme.themeInternal.multiUnitsAllowed,
                  ),
                ];
                setColumns(newDef);
                saveColumnsDef(
                  newDef,
                  isFeatureAllowed(FEATURE.UNITS),
                  theme.themeInternal.multiUnitsAllowed,
                );
              }}
            />
          </ColSortWrapper>
        </TableHeadWrapper>
        {selectAllVisible && (
          <StyledSelectAll
            onClick={() => {
              selectAllEmployees();
              setSelectAllVisibility(false);
            }}
          >
            {t('selectAllTexts.profile', { count: numEmployees })}
          </StyledSelectAll>
        )}

        <TableContainer className='table-container' component={Paper}>
          <Table stickyHeader aria-label='customized table'>
            <TableHead className='lt-usetiful--md-table-head'>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='primary'
                    indeterminate={isAnySelectedOnCurrentPage}
                    checked={numEmployees > 0 && isAllSelected}
                    onChange={handleHeaderCheckboxClick}
                    key={'checkbox'}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '20rem', // percentage also works
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align='center'
                >
                  {t('editMD')}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '20rem', // percentage also works
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align='center'
                ></TableCell>
                {visibleColumns.map(colDef => (
                  <TableCell
                    sx={{
                      maxWidth: '20rem', // percentage also works
                      minWidth: '13rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    align='center'
                    key={colDef.key}
                  >
                    {NON_SORTABLE_COLUMNS.includes(colDef.key) ? (
                      t(colDef.tkey)
                    ) : (
                      <TableSortLabel
                        onClick={() => onColumnClick(colDef.key)}
                        active={orderBy === colDef.key}
                        direction={sort || 'asc'}
                        IconComponent={
                          sort === 'desc' ? StyledArrowUpwardIcon : StyledArrowDownwardIcon
                        }
                      >
                        {t(colDef.tkey)}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='lt-usetiful--md-table-body'>
              {employeeList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          labelRowsPerPage={t('rowsPerPage')}
          component='div'
          count={numEmployees}
          {...props.paginationProps}
          sx={{
            '.MuiIconButton-root:not(.Mui-disabled)': {
              color: '#2C2C2C', // should this color been added to our design palette (?)
            },
          }}
          SelectProps={{
            MenuProps: {
              sx: {
                '.MuiTablePagination-menuItem.MuiMenuItem-root': {
                  fontSize: '1.4rem',
                },
              },
            },
          }}
        />
      </TableWrapper>
    </>
  );
});

export default TableUi;

const BulkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.2rem;
  margin-bottom: -0.8rem;

  > * {
    margin-bottom: 0.8rem;
  }
`;

const BulkIndicatorSpan = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
`;

const SpanWithMargin = styled.div`
  margin-left: 0.9rem;
`;

const RowProfileImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #efefef;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`;

const Chip = styled.div`
  color: black;
  background: #e0e0ff;
  border-radius: 3px;
  font-size: 0.9rem;
  margin: 0 auto 0.5rem;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  /* cursor: pointer; */
`;

const ColSortWrapper = styled.div`
  margin-left: auto;
  white-space: nowrap;
`;
export const Line = styled.div`
  background-color: #bbbbc0;
  min-height: 2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
  font-size: 1.2rem;
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
  font-size: 1.2rem;
`;

const CogButton = styled.button`
  border: none;
  border-radius: 0.5rem;
  background: none;
  color: grey;
  font-size: 1rem;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  border: 1px solid grey;

  img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: auto;
  }

  span {
    font-weight: 600;
  }
`;

const StyledSelectAll = styled.div`
  align-self: self-start;
  margin-top: -1rem;
  margin-bottom: 0.3rem;
  font-style: italic;
  font-size: 0.8em;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
