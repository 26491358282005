import config from '../../../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../../../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuth0TokenScopeString } from '@/util';

export const saveFile = async (
  employeeId: string,
  fileName: string,
  url: string,
  pageCount: number | null,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  fileTemplateId?: number,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}business/file/save`,
    {
      employeeId,
      fileEntity: {
        fileName,
        link: url,
        pageCount,
        fileTemplateId,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const updateFileDisplayStatus = async (
  employeeId: string,
  fileId: number,
  canDisplayOnProfile: boolean,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}business/file/update/display`,
    {
      employeeId,
      fileId,
      canDisplayOnProfile,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const reorderFilesAPI = async (id, files, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}business/files`,
    {
      id,
      files,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response;
};
