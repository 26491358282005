import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../application/hooks';
import showEyeIcon from '../../images/eye-show.svg';
import hiddenEyeIcon from '../../images/eye-hidden.svg';
import editIcon from '../../images/edit.svg';
import trashIcon from '../../images/trash.svg';
import extendIcon from '../../images/extend.svg';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';

import { updateLinkDisplay, updateLinkDefault } from '../../../application/actions/edit-profile';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { BoxWrapper, Name, ImagesBox, StyledIconWrapper } from './sharedUIComponets';

import { Account, Link, Theme } from '../../../shared/types/api';
import { CustomLinkType } from '../md/profiles/common/links/hooks/useBulk';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { FlashOffOutlined, FlashOnOutlined } from '@mui/icons-material';

interface Props {
  link: Link;
  employeeId: string;
  defaultLinkId: number;
  onEditClick: (link: Link) => void;
  onExtendClick?: (link: Link) => void;
  hideOptions?: boolean;
  isThemeEntity?: boolean;
  account?: Account;
  theme?: Theme;
  bulkEdit?: boolean;
  onDeleteClick?: (link: Link) => void;
  userIsAdmin?: boolean;
  blueBackground?: boolean;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
}

const LinkBoxThemed = (props: Props): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const { profileDesign } = useProfileDesign();

  const isTemplated = useMemo(
    () => typeof props.link.linkTemplateId === 'number',
    [props.link?.linkTemplateId],
  );

  const dispatch = useAppDispatch();
  const [tooltipText, setTooltipText] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useAppTranslation();
  let { account, link, employeeId, hideOptions, defaultLinkId, userLinksProps } = props;
  const { userLinks, setUserLinks } = userLinksProps || {};

  const isDefault = link.id === defaultLinkId;

  const toggleCanDisplay = (canDisplayOnProfile: boolean) => {
    const index = userLinks.findIndex(l => l.id === link.id);
    const updatedLink = userLinks?.find(l => l.id === link.id);
    if (index !== -1) userLinks[index] = { ...updatedLink, canDisplayOnProfile };
    setUserLinks([...userLinks]);
  };

  const onToggleDisplayClick = () => {
    if (employeeId) toggleCanDisplay(!link.canDisplayOnProfile);
    else dispatch(updateLinkDisplay(link.id, !link.canDisplayOnProfile, getAccessTokenSilently));
    setTooltipText('');
  };

  const onToggleDefaultClick = () => {
    if (employeeId)
      !isDefault ? userLinksProps.setDefaultLinkId(link.id) : userLinksProps.setDefaultLinkId(null);
    else dispatch(updateLinkDefault(link.id, account, !isDefault, getAccessTokenSilently));
    setTooltipText('');
  };

  const getShowHideImageUrl = (): string => {
    return link.canDisplayOnProfile ? showEyeIcon : hiddenEyeIcon;
  };

  const handleShowTooltip = (action: 'display' | 'default' | 'extend' | 'explainTemplate') => {
    setTooltipText('');

    if (action === 'display' && link.canDisplayOnProfile) setTooltipText(t('hideMessage'));

    if (action === 'default' && !isDefault) setTooltipText(t('redirectMessage'));

    if (action === 'extend') setTooltipText(t('linkExtendMessage'));

    if (action === 'explainTemplate') setTooltipText(t('explainTemplatedLink'));

    setShowTooltip(true);
  };

  const showExtendIcon = () =>
    props.bulkEdit && link['existsInDB'] && !(link['existsForAll'] || link['extendToAll']);

  const displayName = link.name;

  return (
    <>
      <StyledBoxWrapper
        boxStyle={profileDesign.boxStyle}
        bulkEdit={props.bulkEdit}
        blueBackground={props.blueBackground}
      >
        <LinkIcon>
          <img
            // @ts-ignore:next-line
            src={link.linkType?.iconColorUrl || link?.link?.linkType?.iconColorUrl}
            alt='link icon'
          />
        </LinkIcon>
        <StyledName>{displayName}</StyledName>
        {!hideOptions && (
          <Tooltip
            open={Boolean(showTooltip && tooltipText)}
            title={<TooltipText>{tooltipText}</TooltipText>}
            placement='bottom'
            enterDelay={300}
          >
            <StyledImagesBoxes>
              {
                <div style={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {!props.bulkEdit && (
                    <>
                      <StyledIconWrapper
                        onClick={() => onToggleDisplayClick()}
                        onMouseEnter={() => handleShowTooltip('display')}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <img src={getShowHideImageUrl()} alt='' />
                      </StyledIconWrapper>
                      <StyledIconWrapper
                        onClick={() => onToggleDefaultClick()}
                        onMouseEnter={() => handleShowTooltip('default')}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        {isDefault ? (
                          <FlashOnOutlined fontSize='large' />
                        ) : (
                          <FlashOffOutlined fontSize='large' />
                        )}
                      </StyledIconWrapper>
                    </>
                  )}
                  {isTemplated ? (
                    <StyledIconWrapper
                      onClick={() => props.onDeleteClick(link)}
                      onMouseEnter={() => handleShowTooltip('explainTemplate')}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <img src={trashIcon} alt='' />
                    </StyledIconWrapper>
                  ) : (
                    <StyledIconWrapper onClick={() => props.onEditClick(link)}>
                      <img src={editIcon} alt='' />
                    </StyledIconWrapper>
                  )}
                  {showExtendIcon() && (
                    <StyledIconWrapper
                      onClick={() => props.onExtendClick(link)}
                      onMouseEnter={() => handleShowTooltip('extend')}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <img style={{ transform: 'scale(0.85)' }} src={extendIcon} alt='' />
                    </StyledIconWrapper>
                  )}
                </div>
              }
            </StyledImagesBoxes>
          </Tooltip>
        )}
        {props.isThemeEntity && (
          <Tooltip
            title={
              <TooltipText>
                {props.userIsAdmin ? t('explainThemeLinkToAdmin') : t('explainThemeLink')}
              </TooltipText>
            }
            enterDelay={300}
          >
            <StyledImagesBoxes>
              <NoEditIconWrapper>
                <img src={editIcon} alt='' />
              </NoEditIconWrapper>
            </StyledImagesBoxes>
          </Tooltip>
        )}
      </StyledBoxWrapper>
    </>
  );
};

export default LinkBoxThemed;

const StyledBoxWrapper = styled(BoxWrapper)`
  @media (max-width: 360px) {
    font-size: 1.4rem;
  }
  ${props => props.blueBackground && 'background-color: #eff3fe'};
  ${props => props.bulkEdit && `margin-top: unset !important;`}
`;

const StyledName = styled(Name)`
  font-size: 1.4rem;
  @media (max-width: 1024px) {
    width: 49%;
  }

  @media (max-width: 320px) {
    width: 40%;
  }

  @media (max-width: 280px) {
    width: 30%;
  }
`;

const LinkIcon = styled.div`
  width: 10%;
  max-width: 3rem;
  display: grid;
  place-items: center;

  & > img {
    width: 100%;
  }
`;

const StyledImagesBoxes = styled(ImagesBox)`
  & > img {
    @media (max-width: 1024px) {
      padding-right: 10px;
    }
  }
`;

export const NoEditIconWrapper = styled.div`
  position: relative;
  padding-right: 12px;
  color: #989897;
  @media (max-width: 1024px) {
    padding-right: 10px;
  }
  img {
    height: 21px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 45%;
    left: 4%;
    transform: rotate(45deg);
    display: block;
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background-color: currentColor;
  }
`;

const TooltipText = styled.div`
  font-size: 1.4rem;
`;
