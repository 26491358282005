import React, { useMemo } from 'react';
import { Text, Checkbox, TextField } from '../../components/generic';
import styled from 'styled-components';
import { LeadOptionValue } from '../../../shared/types/global';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LEAD_OPTION_TYPES } from '@/shared/constants';
import { LeadOptionAPI, LeadOptionTypeAPI } from '@/shared/types/api';
import {
  TextField as MuiTextfield,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
interface LeadOptionsPropsLegacy {
  leadOptionTypes: Array<LeadOptionTypeAPI>;
  leadOptions: Array<LeadOptionAPI>;
  handleChange: (typeId: number, value: LeadOptionValue) => void;
  activeLanguage: string;
}

export const LeadOptionsLegacy = (props: LeadOptionsPropsLegacy) => {
  return (
    <>
      {props.leadOptionTypes?.map(optionType => {
        let leadOptionDef = props.leadOptions?.find(option => {
          return option.typeId === optionType.id;
        });
        if (!leadOptionDef) {
          leadOptionDef = {
            id: null,
            typeId: optionType.id,
            value: null,
          };
        }

        const Heading =
          props.activeLanguage.startsWith('de') && optionType.translatedName?.de
            ? optionType.translatedName.de
            : optionType.name;
        const meta =
          props.activeLanguage.startsWith('de') && optionType.translatedMeta?.de
            ? optionType.translatedMeta.de
            : optionType.meta;

        switch (optionType.type) {
          case LEAD_OPTION_TYPES.CHECKBOX:
            return (
              <Item key={optionType.id}>
                <Label text={Heading} />
                <RenderCheckBoxLegacy
                  name={optionType.id}
                  meta={meta}
                  value={leadOptionDef.value}
                  handleChange={props.handleChange}
                />
              </Item>
            );
          case LEAD_OPTION_TYPES.TEXT:
            return (
              <Item key={optionType.id}>
                <Label text={Heading} />
                <RenderTextLegacy
                  name={optionType.id}
                  placeholder={meta}
                  value={leadOptionDef.value}
                  handleChange={props.handleChange}
                />
              </Item>
            );
          case LEAD_OPTION_TYPES.SELECT:
            return (
              <Item key={optionType.id}>
                <Label text={Heading} />
                <RenderDropDownLegacy
                  name={optionType.id}
                  meta={meta}
                  selected={leadOptionDef.value}
                  handleChange={props.handleChange}
                />
              </Item>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

const RenderCheckBoxLegacy = props => {
  const { name, handleChange, value, meta } = props;
  const { options } = meta;
  const content = useMemo(
    () => (
      <CheckBoxContainer>
        {options.map(option => {
          return (
            <Checkbox
              key={option.id}
              name={name}
              value={option.id}
              title={option.title}
              checked={value?.find(val => val === option.id)}
              onChange={() => {
                if (!value || value.length === 0) {
                  handleChange(name, [option.id]);
                } else if (value.some(val => val === option.id)) {
                  handleChange(
                    name,
                    value.filter(val => val !== option.id),
                  );
                } else {
                  handleChange(name, [...value, option.id]);
                }
              }}
            />
          );
        })}
      </CheckBoxContainer>
    ),
    [handleChange, name, options, value],
  );
  return content;
};

const RenderTextLegacy = props => {
  const { placeholder, value, handleChange, name } = props;
  const content = useMemo(
    () => (
      // <NoteBox
      //   name={name}
      //   placeholder={placeholder}
      //   value={value ?? ''}
      //   onChange={e => handleChange(name, e.target.value)}
      // />
      <TextField
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={e => handleChange(name, e.target.value)}
        multiline
      />
    ),
    [handleChange, name, placeholder, value],
  );
  return content;
};

const RenderDropDownLegacy = props => {
  const { name, handleChange, selected, meta } = props;
  const { options } = meta;
  const content = useMemo(
    () => (
      <DropdownWrapper>
        <StyledLabel htmlFor='services'>{props.formLabel}</StyledLabel>
        <StyledSelect
          id='services'
          name='services'
          onChange={e => handleChange(name, parseInt(e.target.value))}
          defaultValue={selected}
        >
          <StyledOption value=''>Select ...</StyledOption>
          {options.map((option, index) => {
            return (
              <StyledOption value={option.id} key={index}>
                {option.title}
              </StyledOption>
            );
          })}
        </StyledSelect>
      </DropdownWrapper>
    ),
    [handleChange, name, options, props.formLabel, selected],
  );
  return content;
};

// const NoteBox = styled.textarea`
//   background: #ffffff;
//   box-shadow: 0px 10px 40px 5px rgba(23, 3, 89, 0.05);
//   border-radius: 15px;
//   border: none;
//   margin-bottom: 1.8rem;
//   box-sizing: border-box;
//   font-size: none;
//   color: #222;
//   transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
//   padding-left: 5px;
//   height: 80px;
//   line-height: 1;
// `;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 10px 40px 5px rgba(23, 3, 89, 0.05);
  border-radius: 15px;
  padding: 18px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 10px 40px 5px rgba(23, 3, 89, 0.05);
  border-radius: 15px;
  padding: 6px 18px;
`;

export const StyledSelect = styled.select`
  max-width: 100%;
  background: white;
  height: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
  border: 1px solid grey;
  cursor: pointer;

  &:focus {
    box-shadow: 0px 10px 40px 5px rgba(23, 3, 89, 0.025);
    outline: 2px solid #02e0b1;
  }
`;

export const StyledOption = styled.option`
  color: ${props => (props.selected ? '#02e0b1' : 'black')};
`;

export const StyledLabel = styled.label`
  margin-bottom: 1rem;
`;

export const Item = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  ${props =>
    props.starred
      ? `&::after {
    content: '*';
    display: block;
    position: absolute;
    right: 1rem;
    top: calc(0.5 * (100% - 0.8rem));
    transform: translateY(-50%);
    color: #666666;
  }`
      : ''}
`;

export const Label = styled(Text)`
  color: #181a5a;
  margin-bottom: 5px;
`;

interface LeadOptionsProps {
  leadOptionTypes: Array<LeadOptionTypeAPI>;
  leadOptions: Array<LeadOptionAPI>;
  onChange: (value: Array<LeadOptionAPI>) => void;
}

export const RenderText = ({ onChange, value, meta }) => {
  return (
    <MuiTextfield
      fullWidth
      placeholder={meta}
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
      multiline
      rows={4}
    />
  );
};

export const RenderDropDown = ({ onChange, value, meta }) => {
  const { options } = meta;

  return (
    <FormControl fullWidth>
      <Select value={value} onChange={({ target: { value } }) => onChange(value)}>
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const RenderCheckbox = ({ onChange, value, meta }) => {
  const { options } = meta;

  const handleChange = option => {
    const currentValues = value || [];
    if (currentValues.includes(option.id)) {
      onChange(currentValues.filter(val => val !== option.id));
    } else {
      onChange([...currentValues, option.id]);
    }
  };

  return (
    <FormControl variant='outlined' fullWidth>
      <FormGroup aria-label='position'>
        {options.map(option => (
          <FormControlLabel
            label={option.title}
            control={
              <MuiCheckbox
                checked={(value || []).includes(option.id)}
                onChange={() => handleChange(option)}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const LeadOptionsRenderer = ({
  leadOptionTypes,
  onChange,
  leadOptions,
}: LeadOptionsProps) => {
  const { activeLanguage } = useAppTranslation();
  const isDE = activeLanguage.startsWith('de');

  const handleChange = (value, optionTypeId) => {
    const currentLeadOptions = leadOptions || [];
    const currentLeadOption = currentLeadOptions.find(item => item.typeId === optionTypeId);

    if (currentLeadOption) {
      onChange(
        currentLeadOptions.map(item =>
          item.typeId === optionTypeId ? { typeId: optionTypeId, value: value, id: item.id } : item,
        ),
      );
      return;
    } else {
      onChange([...currentLeadOptions, { typeId: optionTypeId, value: value, id: null }]);
      return;
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={2} mb={2}>
      {leadOptionTypes?.map(optionType => {
        const value =
          leadOptions?.find(option => {
            return option.typeId === optionType.id;
          })?.value || null;

        const label =
          isDE && optionType.translatedName?.de ? optionType.translatedName.de : optionType.name;
        const meta =
          isDE && optionType.translatedMeta?.de ? optionType.translatedMeta.de : optionType.meta;

        let Component = null;
        switch (optionType.type) {
          case LEAD_OPTION_TYPES.TEXT:
            Component = RenderText;
            break;
          case LEAD_OPTION_TYPES.SELECT:
            Component = RenderDropDown;
            break;
          case LEAD_OPTION_TYPES.CHECKBOX:
            Component = RenderCheckbox;
            break;
        }

        return Component ? (
          <Card>
            <CardHeader titleTypographyProps={{ variant: 'h4' }} title={label} />
            <CardContent>
              <Component
                meta={meta}
                value={value}
                onChange={value => handleChange(value, optionType.id)}
              />
            </CardContent>
          </Card>
        ) : null;
      })}
    </Box>
  );
};
