import i18n, { InitOptions } from 'i18next';
import { en } from './EN-Translation';
import { de } from './DE-Translation';
import LanguageDetector from 'i18next-browser-languagedetector';
import ISO6391 from 'iso-639-1';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  de: de,
  en: en,
  'en-US': en,
  'de-DE': de,
};

export const I18N_LOCAL_STORAGE_KEY = {
  MAIN: 'i18nextLng',
  PROFILE: 'i18nextLngProfile',
};
export const QUERY_KEY_LANG = 'lang';

const DetectingOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: I18N_LOCAL_STORAGE_KEY.MAIN,
};
const i18nMain = i18n.createInstance();
i18nMain
  .use(LanguageDetector)
  // .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    nonExplicitSupportedLngs: true,
    fallbackLng: {
      de: ['de-AT', 'de-LU', 'de-CH', 'de-LI'],
      default: ['en'],
    },
    supportedLngs: ['en', 'de', 'en-US', 'de-DE'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: DetectingOptions,
  });

export default i18nMain;

export const getNewI18nInstance = (options?: InitOptions) => {
  const instance = i18n.createInstance();
  instance.use(LanguageDetector).init({
    resources: {
      de: de,
      en: en,
    },
    fallbackLng: 'en',
    supportedLngs: ISO6391.getAllCodes(),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: DetectingOptions,
    ...(options || {}),
  });
  return instance;
};
