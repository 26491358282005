import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { CSS_VARS, DEFAULT_COLOR } from '@/infrastructure/constants';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useMemo, useState } from 'react';
import { defaultPalette } from './palette';
import { defaultShape } from './shape';
import { defaultBreakpoints } from './breakpoints';

import { defaultTypography } from './typography';
import { Theme as MuiTheme, Shadows, createTheme } from '@mui/material';
import { defaultComponents } from './components';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { MuiPalette, MuiShape, MuiTypography, Theme } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { shadows } from './shadows';
import { isDarkHex } from '@/infrastructure/helper';
import { Temporary } from '@/application/reducers/temporary';

export const oldTheme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  palette: {
    primary: {
      main: '#181a5a',
    },
    info: {
      main: DEFAULT_COLOR,
      contrastText: '#fff',
    },
  },
});

const createMuiTheme = (typography: MuiTypography, palette: MuiPalette, shape: MuiShape) =>
  createTheme({
    spacing: (value: number) => `${value}rem`,
    typography: {
      htmlFontSize: 10,
      ...typography,
    },
    palette: {
      mode: 'light',
      contrastThreshold: 2,
      ...palette,
    },
    shape: {
      ...shape,
    },
    components: {
      ...defaultComponents,
      MuiTypography: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          },
        },
      },
    },
    breakpoints: defaultBreakpoints,
    shadows: [...shadows] as Shadows,
  } as const);

interface InternalDesignProps {
  theme: MuiTheme;
  isFetching: boolean;
  oldTheme: typeof oldTheme;
}

const InternalDesignContext = React.createContext<InternalDesignProps>({
  theme: null,
  isFetching: false,
  oldTheme: oldTheme,
});

export const InternalDesignProvider: React.FC = ({ children }) => {
  const [fetchedTheme, setFetchedTheme] = useState({ typography: {}, palette: {}, shape: {} });
  const [isFetching, setIsFetching] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const domainVerified = useAppSelector(state => state.login.domainVerified);
  const accountTheme = useAppSelector<Theme>(state => state.account?.theme);

  const temporary = useAppSelector<Temporary>(state => state.temporary);

  const primaryButtonColor =
    temporary?.tempPrimaryButtonColor || accountTheme?.themeInternal?.primaryColor;
  const sidebarColor = temporary?.tempSidebarColor || accountTheme?.themeInternal?.sidebarColor;
  const sidebarColorText =
    temporary?.tempSidebarColorText || accountTheme?.themeInternal?.sidebarColorText;
  const sidebarColorActive =
    temporary?.tempSidebarColorActive || accountTheme?.themeInternal?.sidebarColorActive;
  const sidebarColorActiveText =
    temporary?.tempSidebarColorActiveText || accountTheme?.themeInternal?.sidebarColorActiveText;

  const theme = useMemo(() => {
    const typography = {
      ...defaultTypography,
      ...fetchedTheme.typography,
    };

    const initialPalette = {
      ...defaultPalette,
      ...fetchedTheme.palette,
    };

    const palette = {
      ...initialPalette,
      primaryButton: primaryButtonColor
        ? {
            main: primaryButtonColor,
            contrastText: isDarkHex(primaryButtonColor) ? '#fff' : '#000',
          }
        : initialPalette.primaryButton,
      sidebar: {
        main: sidebarColor || initialPalette.sidebar.main,
        contrastText: sidebarColorText || initialPalette.sidebar.contrastText,
      },
      sidebarActive: {
        main: sidebarColorActive || initialPalette.sidebarActive.main,
        contrastText: sidebarColorActiveText || initialPalette.sidebarActive.contrastText,
      },
    };

    const shape = {
      ...defaultShape,
      ...fetchedTheme.shape,
    };

    return createMuiTheme(typography, palette, shape);
  }, [
    fetchedTheme.palette,
    fetchedTheme.shape,
    fetchedTheme.typography,
    primaryButtonColor,
    sidebarColor,
    sidebarColorText,
    sidebarColorActive,
    sidebarColorActiveText,
  ]);

  useEffect(() => {
    if (!domainVerified) return;
    const fetchTheme = async () => {
      setIsFetching(true);
      try {
        const { value: data } = await fetchThemeConfig(
          getAccessTokenSilently,
          THEME_CONFIG_KEYS.INTERNAL_DESIGN,
        );
        setFetchedTheme(data);
      } catch {
        //empty block do nothing if failed, use default theme
      }
      setIsFetching(false);
    };
    fetchTheme();
  }, [getAccessTokenSilently, domainVerified]);

  // set css vars
  useEffect(() => {
    document.documentElement.style.setProperty(
      CSS_VARS.LT_DEFAULT_COLOR_NAME,
      theme.palette.primary.main,
    );
    document.documentElement.style.setProperty(
      CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
      theme.palette.primaryButton.main,
    );
    document.documentElement.style.setProperty(
      CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_NAME,
      theme.palette.primaryButton.contrastText,
    );
  }, [theme]);

  return (
    <InternalDesignContext.Provider value={{ theme, isFetching, oldTheme }}>
      {children}
    </InternalDesignContext.Provider>
  );
};

export const useMuiTheme = () => {
  const context = React.useContext(InternalDesignContext);
  if (context === undefined) {
    throw new Error('useMuiTheme must be used within a InternalDesignProvider');
  }
  return context;
};
