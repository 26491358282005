import styled from 'styled-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { Popup, Button, ErrorMessage } from '../../components/common';
import { TextField } from '../../components/generic';
import { saveLink, clearApiResponse } from '../../../application/actions/edit-profile';
import { clearApiResponse as clearEmployeeApiResponse } from '../../../application/actions/md/profiles';
import deletIcon from '../../images/trash.svg';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { removePrefix } from '../../../util';
import { trackPrivateEvent } from '../../../infrastructure/apis/analytics';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../shared/constants/global';
import { LINK_TYPE_MODES } from '../../../shared/constants';
import { Link } from '../../../shared/types/api';
import { CustomLinkType } from '../../pages/md/profiles/common/links/hooks/useBulk';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  item: Link;
  mode: string;
  employeeId?: string;
  bulkEdit?: boolean;
  handleBulkEdit?: (item: any, isCreated: boolean) => void;
  onCloseClick: () => void;
  onBackClick: () => void;
  handleBulkDelete?: () => void;
  onDeleteClick: () => void;
  isCompanydataSettings?: boolean;
  onSaveCompanydataSettings?: (linkItem: Link) => void;
  userLinks?: Array<Link>;
  setUserLinks?: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
  genericWebsiteColor: string;
}

const CreateEditLinkPopup = (props: Props): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { theme: muiTheme } = useMuiTheme();

  const apiResponse = useAppSelector(state =>
    props.employeeId ? state.md.apiResponse : state.editProfile.apiResponse,
  );
  const [isResponseUpdated, setResponseUpdate] = useState(false);
  const [linkName, setLinkName] = useState(props.item.name);
  const [linkText, setLinkText] = useState<string>(
    props.mode === LINK_TYPE_MODES.LINK
      ? removePrefix(props.item.link, 'https://')
      : props.item.link,
  );
  const [errorMsg, setErrorMsg] = useState('');

  const isUsername = props.mode === LINK_TYPE_MODES.USERNAME;
  const isPhone = props.mode === LINK_TYPE_MODES.PHONE;
  const isCreate = props.item.id === 0;
  const backgroundCss =
    props.item?.linkType.backgroundCss || props.genericWebsiteColor || '#152570';
  const iconUrl = props.item?.linkType.iconUrl;

  const onTextBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'linkName') {
      if (errorMsg === t('nameLinkerror') && linkName.length > 1) setErrorMsg('');
    } else if (e.target.name === 'linkText') {
      if (
        (errorMsg === t('enterLinkerror') || errorMsg === t('enterUsernameerror')) &&
        linkText.length > 1
      )
        setErrorMsg('');
    }
  };

  const onSaveClick = () => {
    if (linkName.length <= 1) {
      setErrorMsg(t('nameLinkerror'));
    } else if (linkText.length <= 1) {
      setErrorMsg(t('enterMessage', { subject: props.mode }));
    } else {
      setErrorMsg('');

      const linkItem = {
        ...props.item,
        name: linkName,
        link: linkText,
        order: props.item.order,
        linkTypeId: props.item.linkType.id,
      };

      if (props.isCompanydataSettings) {
        // @ts-ignore:next-line
        props.onSaveCompanydataSettings({ ...props.item, name: linkName, link: linkText });
      } else if (props.bulkEdit) {
        // item.id defaults to 0 which will always yield isCreate to true
        if (isCreate) linkItem.id = Math.floor(Math.random() * 1000);
        const editedLinks = { ...linkItem, existsForAll: true };
        props.handleBulkEdit(editedLinks, isCreate);
      } else if (props.employeeId) {
        if (isCreate) {
          linkItem.id = Math.floor(Math.random() * 1000);
          props.setUserLinks([...props.userLinks, linkItem]);
        } else {
          let index = props.userLinks?.findIndex((link: Link) => link.id === linkItem.id);
          if (index !== -1) props.userLinks[index] = linkItem;
        }
      } else {
        dispatch(
          saveLink(linkItem, getAccessTokenSilently, linkId => {
            trackPrivateEvent(
              getAccessTokenSilently,
              PRIVATE_EVENTS.PROFILE_EDIT,
              {
                type: PRIVATE_META_TYPES.LINK_ADD,
              },
              null,
              linkId,
            );
          }),
        );
      }
      props.onCloseClick();
    }
  };

  const checkForResponse = () => {
    if (apiResponse && apiResponse.isSuccess === false && isResponseUpdated === false) {
      setErrorMsg(apiResponse.error.message);
      setResponseUpdate(true);
      if (props.employeeId) dispatch(clearEmployeeApiResponse());
      else dispatch(clearApiResponse());
    }
  };

  const getHeaderText = (): string => {
    return isCreate ? t('createLink') : t('editLink');
  };

  return (
    <>
      {checkForResponse()}
      <Popup
        headerText={getHeaderText()}
        onBackClick={props.onBackClick}
        onCloseClick={props.onCloseClick}
        noBack={!isCreate}
      >
        <CreateLinkWrapper>
          <LinkWrapper style={{ background: backgroundCss }}>
            <img src={iconUrl} alt='link icon' />
          </LinkWrapper>
          <div>
            {errorMsg && <ErrorMessage message={errorMsg} />}
            <TextField
              name='linkName'
              placeholder={t('nameLink')}
              value={linkName}
              onChange={e => setLinkName(e.target.value)}
              onBlur={e => onTextBlur(e)}
              maxLength={30}
              variant='outlined'
              fullWidth={true}
              angles='curved'
              bgColor={{ normal: '#fff', hover: '#fff' }}
              padding={{ left: 'auto' }}
              border={{ normal: '1px solid #ddd', focus: '1px solid #ddd' }}
              disabled={!!props.item.linkTemplateId}
            />
            <TextField
              name='linkText'
              value={linkText}
              placeholder={
                isUsername ? t('enterUsername') : isPhone ? t('enterPhone') : t('enterLink')
              }
              onChange={e => setLinkText(removePrefix(e.target.value, 'https://'))}
              onBlur={e => onTextBlur(e)}
              maxLength={isUsername || isPhone ? 30 : null}
              prefixText={isUsername ? '@' : isPhone ? '+' : null}
              variant='outlined'
              fullWidth={true}
              angles='curved'
              bgColor={{ normal: '#fff', hover: '#fff' }}
              border={{ normal: '1px solid #ddd', focus: '1px solid #ddd' }}
              disabled={!!props.item.linkTemplateId}
            />
          </div>
          <div>
            {!props.item.linkTemplateId && (
              <Button
                text={t('save')}
                vMargin='3rem'
                onClick={() => onSaveClick()}
                styles={{
                  backgroundColor: muiTheme.palette.primaryButton.main,
                  color: muiTheme.palette.primaryButton.contrastText,
                }}
              />
            )}
            {!isCreate && (
              <DeleteLink
                href='#!'
                onClick={() => (props.bulkEdit ? props.handleBulkDelete() : props.onDeleteClick())}
              >
                <img src={deletIcon} alt='Delete' />
                {t('deleteLink')}
              </DeleteLink>
            )}
          </div>
        </CreateLinkWrapper>
      </Popup>
    </>
  );
};

export default CreateEditLinkPopup;

const CreateLinkWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 29px;
  row-gap: 3vh;
  width: 70%;
  text-align: center;
  margin: auto;

  img {
    height: 62px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  border-radius: 10px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 1.5rem;
  padding: 1.5rem;
  cursor: pointer;
  max-width: 6rem;
  margin: 0 auto 1.5rem;

  img {
    height: 25px;
  }
`;

const DeleteLink = styled.a`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 19px;
  text-align: justify;
  text-decoration-line: underline;
  color: #55595e;

  img {
    width: 20px;
    height: 13px;
    padding-right: 7px;
    text-align: justify;
  }
`;
