import { Box } from '@mui/material';

export const BottomActionContainer = ({ children, ...rest }) => {
  return (
    <Box
      px={1.8}
      pt={2.4}
      pb={6}
      mt='auto'
      bgcolor={'white'}
      zIndex={1001}
      sx={{ position: 'sticky', bottom: 0, boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.05)' }}
      {...rest}
    >
      {children}
    </Box>
  );
};
