import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Container, Text } from '../../generic';
import { lightenHex } from '../../../../infrastructure/helper';
import { CSS_VARS } from '@/infrastructure/constants';

interface IProps {
  $downloadready?: boolean;
  $videocall?: boolean;
  $smallScreen?: boolean;
}

export const StyledContainer = styled(Container)`
  margin-bottom: 16px;
`;

export const ScreenNavigator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #eff3fd;
  border: 0.5px solid #8a8a8a;
  box-sizing: border-box;
  border-radius: 10px;
`;
export const ScreenBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8a8a;
  width: 50%;
  height: 100%;
  padding: 1rem;
  cursor: pointer;
  &.active {
    background: ${props => lightenHex(props.color, 30)};
    color: #000;
    font-weight: 500;
  }
  &.videocall {
    border-left: 0.5px solid #8a8a8a;
    border-radius: 0 10px 10px 0;
  }
  &.mobile {
    border-right: 0.5px solid #8a8a8a;
    border-radius: 10px 0 0 10px;
  }
  &:focus-visible {
    outline: 1px solid ${p => p.color};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const QRNavigator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
export const QRBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #444;
  width: 30%;
  height: 100%;
  padding: 1rem;
  border: 1px solid #444444;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  background: transparent;
  &.active {
    border-width: 2px;
    border-color: ${p => p.primaryColor};
    color: ${p => p.primaryColor};
    font-weight: 600;
  }
  &:focus-visible {
    outline: 1px solid ${p => p.primaryColor};
  }
`;

export const BGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props: IProps) => (props.$videocall ? '100%' : '50%')};
  align-self: center;
`;

export const BGImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
`;

export const HeaderWithIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  & svg {
    font-size: 2.3rem;
    color: #b7b7b7;
    &:hover {
      cursor: pointer;
    }
  }
  & Button {
    height: 25px;
    min-width: unset;
    &:hover {
      background-color: unset;
    }
  }
`;

export const SecondaryHeader = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2rem;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: content-box;
`;

export const ImgsSlider = styled(Carousel)`
  .slide:hover {
    cursor: pointer;
  }

  .slide img {
    max-height: 32vh;
  }

  .control-arrow {
    background: rgba(0, 0, 0, 0.2) !important;
    &:hover {
      background: rgba(0, 0, 0, 0.4) !important;
    }
  }
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  border: 2px solid #4f4f4f;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 2%;
`;

export const UploadImageContainer = styled.div`
  display: flex;
  height: 93%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:focus-visible {
    outline: 1px solid ${p => p.focusColor};
  }
`;

export const UploadImage = styled.img``;

export const UploadText = styled.p`
  background-color: rgba(94, 94, 94, 0.8);
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 11px;
    line-height: 12px;
  }
  @media (min-width: 376px) and (max-width: 425px) {
    font-size: 12px;
    line-height: 13px;
  }
  ${(props: IProps) => (props.$smallScreen ? 'display: none' : 'display: auto')}
`;

export const SampleImg = styled.img`
  max-width: 95%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

export const DownloadButton = styled(Link)`
  background-color: ${p => p.primaryColor};
  border-radius: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  padding: 1.5rem 6rem;
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  &:hover,
  &:visited,
  &:active {
    color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  }
  &:focus-visible {
    outline: 1px solid ${p => p.primaryColor};
    outline-offset: 2px;
  }
  ${(props: IProps) =>
    !props.$downloadready
      ? `
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
  `
      : null}
`;
