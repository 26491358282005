import { RowDiv, Spacer } from '../../../customization/common';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Unit } from '@/shared/types/api/unit.type';
import { Employee } from '@/shared/types/api/employee.type';
import { assignUnits } from '@/infrastructure/apis/md/units';
import { CSS_VARS, DEFAULT_COLOR } from '@/infrastructure/constants';
import { Checkbox, Separator, Text } from '@/views/components/generic/index';
import { BulkButton } from '../../../common/table';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { Account, ThemeInternal } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { getMainUnitSync, lexicalSortObject } from '@/shared/util';
import { fetchUserData } from '@/application/actions/account';
import { useDispatch } from 'react-redux';

interface Props {
  selectedIds: readonly string[];
  filteredUnits?: Unit[];
  units?: Unit[];
  setLocalEmployees?: Dispatch<SetStateAction<Employee[]>>;
  localEmployees?: Employee[];
  onSaveStart?: () => void;
  onSaveSuccess?: () => void;
}

const RenderUnits = (props: Props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently, logout } = useAuth0();
  const { isThemeAdmin, isUnitAdmin } = useTierInfo();
  const selectedEmployees = props.localEmployees?.filter(e => props.selectedIds.includes(e.id));

  const [mainUnit, setMainUnit] = useState<Unit>(null);
  const [otherUnits, setOtherUnits] = useState<Unit[]>([]);

  const { multiUnitsAllowed } = useAppSelector<ThemeInternal>(
    state => state.account.theme.themeInternal,
  );
  const account = useAppSelector<Account>(state => state.account);

  const dispatch = useDispatch();
  const onAssignClick = async () => {
    toast.loading(t('saving'), { id: 'assigningUnit' });
    props.onSaveStart?.();
    assignUnits(
      getAccessTokenSilently,
      props.selectedIds,
      mainUnit?.id,
      multiUnitsAllowed ? otherUnits.map(unit => unit.id) : [],
    )
      .then(res => {
        if (res.data.isSuccess) {
          toast.success(t('saved'), { id: 'assigningUnit' });
          const employeesCopy = [];
          if (props.selectedIds.includes(account.id))
            dispatch(fetchUserData(getAccessTokenSilently, logout));
          props.localEmployees.forEach(employee => {
            const emp = { ...employee };

            if (props.selectedIds.includes(emp.id)) {
              emp.units = res.data.data;
            }
            employeesCopy.push(emp);
          });

          props.setLocalEmployees && props.setLocalEmployees(employeesCopy);
          props.onSaveSuccess?.();
        }
      })
      .catch(e => {
        toast.error(t('mdLeads.AssignUnitError'), { id: 'assigningUnit' });
      });
  };

  const canEditMainUnits =
    isThemeAdmin ||
    (isUnitAdmin &&
      selectedEmployees
        .map(e => getMainUnitSync(e)?.id)
        .every(mainUnitId => props.units.some(u => u.id === mainUnitId)));

  return (
    <>
      <div>
        {props.filteredUnits && props.filteredUnits.length > 0 && (
          <>
            <>
              <Text text={t('mdLeads.assignUnitWarning')} size={'1.2rem'} />
              <Separator width='100%' />
              <TextContainer>{multiUnitsAllowed ? t('mainUnit') : t('mdLeads.unit')}</TextContainer>
              <Spacer size={10} />
              <RadioGroup aria-labelledby='roles-group' name='roles-group' row>
                {canEditMainUnits && (
                  <FormControlLabel
                    key={-1}
                    disabled={!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)}
                    name={'none'}
                    label={t('mdLeads.noUnit')}
                    color={DEFAULT_COLOR}
                    control={
                      <Radio
                        inputProps={{ 'aria-label': t('mdLeads.noUnit') }}
                        color='info'
                        checked={!mainUnit}
                      />
                    }
                    onChange={() => {
                      if (!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)) {
                        return;
                      }
                      setMainUnit(null);
                      setOtherUnits([]);
                    }}
                  />
                )}
                {props.filteredUnits?.sort(lexicalSortObject('niceName'))?.map((record, index) => (
                  <FormControlLabel
                    key={index}
                    color={DEFAULT_COLOR}
                    value={record.niceName}
                    control={
                      <Radio
                        inputProps={{ 'aria-label': record.niceName }}
                        color='info'
                        checked={record.id === mainUnit?.id}
                      />
                    }
                    label={record.niceName}
                    disabled={!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)}
                    name={record.niceName}
                    title={record.niceName}
                    onChange={() => {
                      if (!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)) {
                        return;
                      }
                      if (multiUnitsAllowed) {
                        setOtherUnits(prev =>
                          [...prev.filter(u => u.id !== record.id), mainUnit].filter(Boolean),
                        );
                      }
                      setMainUnit(record);
                    }}
                  />
                ))}
              </RadioGroup>
            </>
          </>
        )}

        {multiUnitsAllowed && (
          <>
            <Separator width='100%' />
            <TextContainer>{t('otherUnits')}</TextContainer>
            <Spacer size={10} />
            <RowDivStyled>
              {props.filteredUnits?.sort(lexicalSortObject('niceName'))?.map((record, index) =>
                record.id === mainUnit?.id ? null : (
                  <Checkbox
                    size={14}
                    style={{ fontSize: '1rem', marginRight: '1rem' }}
                    key={index}
                    disabled={
                      (canEditMainUnits && !mainUnit) ||
                      (!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin))
                    }
                    name={record.niceName}
                    useOnlyCheck={
                      otherUnits.map(unit => unit?.id).includes(record?.id)
                        ? 'checked'
                        : 'notChecked'
                    }
                    title={record.niceName}
                    onChange={() => {
                      if (!isThemeAdmin && selectedEmployees.some(e => e.isThemeAdmin)) {
                        return;
                      }
                      if (otherUnits.map(unit => unit?.id).includes(record?.id)) {
                        setOtherUnits(otherUnits.filter(x => x.id !== record.id));
                      } else {
                        setOtherUnits([...otherUnits, record]);
                        setOtherUnits([...otherUnits, record]);
                      }
                    }}
                  />
                ),
              )}
            </RowDivStyled>
          </>
        )}
      </div>
      <Spacer size={15} />
      <ButtonContainer>
        <BulkButton
          onClick={onAssignClick}
          color={getComputedStyle(document.documentElement, null).getPropertyValue(
            CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME,
          )}
          size={25}
        >
          <span>{t('save')}</span>
        </BulkButton>
      </ButtonContainer>
    </>
  );
};

export default RenderUnits;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  font-size: 1em;
  font-weight: bold;
`;

const RowDivStyled = styled(RowDiv)`
  flex-wrap: wrap;
  justify-content: start;
`;
