import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SHARE_TYPES,
  ShareProps,
  ShareType,
  capitalize_first_char,
  generateShareIcon,
  generateShareLink,
  generateShareText,
} from '../utils';
import { Box } from '@mui/system';
import { Card, Divider, Typography } from '@mui/material';
import { CheckCircleOutline, ContentCopy } from '@mui/icons-material';

const ShareList = (props: ShareProps) => {
  const [currentType, setCurrentType] = useState<ShareType>();
  const { subScreen, setSubScreen, setDialogTitle, shareData } = props;
  const { t } = useTranslation();

  const [textCopied, setTextCopied] = useState(false);

  const copyAndShare = (type: ShareType, withPost: boolean = false) => {
    navigator.clipboard.writeText(shareData.text).then(() => {
      if (withPost) window.open(generateShareLink(type, shareData), '_blank');
      return;
    });
  };

  const onItemClick = (type: ShareType) => {
    const isOnlyCopy = type === 'facebook' || type === 'linkedin';
    setCurrentType(type);

    if (isOnlyCopy) {
      setDialogTitle(t('shareTo.copyAndShare', { via: capitalize_first_char(type) }));
      setSubScreen(true);
      return;
    } else {
      copyAndShare(type, true);
    }
  };

  if (subScreen)
    return (
      <Box>
        <Card sx={{ borderRadius: 1 }}>
          <Box sx={{ p: 1.5 }}>{generateShareText(shareData.text)}</Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!textCopied) {
                copyAndShare(currentType);
                setTextCopied(true);
                setTimeout(() => setTextCopied(false), 4000);
              }
            }}
          >
            {textCopied ? <CheckCircleOutline /> : <ContentCopy />}
            <Typography variant='h3' sx={{ p: 1.5 }}>
              {textCopied ? t('shareTo.textCopied') : t('copyText')}
            </Typography>
          </Box>
        </Card>
      </Box>
    );

  return (
    <Box>
      {Object.values(SHARE_TYPES).map((type: ShareType) => (
        <>
          <Typography
            variant='body1'
            onClick={() => onItemClick(type)}
            sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1, pb: 1, cursor: 'pointer' }}
          >
            {generateShareIcon(type)}

            {t(`shareTo.share${capitalize_first_char(type)}`)}
          </Typography>
          <Divider />
        </>
      ))}
    </Box>
  );
};

export default ShareList;
