// defines the udt categories
export const UDT_CATEGORIES = [
  'profile--links',
  'profile--files',
  'profile--system-labels',
  'profile--position',
  'profile--role',
  'profile--division',
  'profile--bio',
  'profile--addresses',
  'profile--firstName',
  'profile--lastName',
  'profile--company',
  'profile--homepage',
  'profile--other-contact-info',
  'profile--urls',
] as const;

// text used to depict deletion
export const UDT_DELETE_TOKEN = '$DELETE$';
