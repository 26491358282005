import React from 'react';
import { Link } from '../../../../../../shared/types/api';
import styled from 'styled-components';
import { TableProps } from '..';
import { invertHex } from '../../../../../../infrastructure/helper';
import TooltipInfo from '../../../../../components/generic/TooltipInfo';
import { getFullLinkUrl } from '@/shared/business-logic/features/profile/getter';

const VISIBLE_LINKS_COUNT = 8;

interface Props {
  links: Link[];
  selectedLinks: TableProps['SelectedObjects']['selectedLinksProps'];
}

const LinksCell = ({ links, selectedLinks }: Props) => {
  if (!Array.isArray(links)) return null;

  const addFilter = (link: Link) => {
    if (selectedLinks.selectedLink?.id === link.id) {
      selectedLinks.setSelectedLink(null);
    } else {
      selectedLinks.setSelectedLink(link);
    }
  };

  const visibleLinks = links.slice(0, VISIBLE_LINKS_COUNT);

  return (
    <Container>
      {visibleLinks?.map(link => (
        <TooltipInfo
          text={
            <a
              href={getFullLinkUrl(link.link, link.linkType)}
              target='_blank'
              rel='noopener noreferrer'
            >
              {getFullLinkUrl(link.link, link.linkType)}
            </a>
          }
          placement='bottom'
          onHover={true}
          key={link.id}
        >
          <LinkContainer
            bg={link?.linkType?.backgroundCss}
            color={invertHex(link?.linkType?.backgroundCss, true)}
            disabled={selectedLinks?.selectedLink && selectedLinks?.selectedLink?.id !== link.id}
            onClick={() => addFilter(link)}
          >
            <img
              src={link.linkType.iconUrl}
              alt='link-icon'
              style={{ width: '100%', height: '100%' }}
            />
          </LinkContainer>
        </TooltipInfo>
      ))}
    </Container>
  );
};

export default LinksCell;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const LinkContainer = styled.div`
  padding: 0.4rem;
  background: ${props => (props.disabled ? 'rgb(0, 0, 0)' : props.bg || 'rgb(0, 0, 0)')};
  color: ${props => props.color || 'rgb(48, 48, 48)'};
  ${props => props.disabled && 'opacity:0.3'};
  margin: 1px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
