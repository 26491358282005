import { BreakpointsOptions } from '@mui/material';
export const defaultBreakpoints: BreakpointsOptions = {
  values: {
    xl: 1536,
    lg: 1200,
    md: 769,
    sm: 600,
    xs: 0,
  },
};
