import NewIphone from '../../../images/new-iphone.png';
import OldIphone from '../../../images/old-iphone.png';
import AndroidNew from '../../../images/android-new.png';
import ScanQr from '../../../images/scan-qr.png';
import TapAndroidImportant from '../../../images/TapAndroidImportant.svg';

import NewIphoneGif from '../../../images/new-iphone.gif';
import TapOlderIphoneGif from '../../../images/TapOlderIphones.gif';
import TapAndroidsGif from '../../../images/TapAndroids.gif';
import QRCodeScanGif from '../../../images/QRCodeScanGif.gif';

export const tapInfo = [
  {
    id: 1,
    heading: 'TapNewIphoneHeading',
    slug: 'tap-new-iphone',
    subHeading: 'TapNewIphoneSubHeading',
    image: NewIphone,
    details: {
      heading: 'TapNewIphoneDetailHeading',
      subHeading: 'TapNewIphoneDetailSubHeading',
      description: 'TapNewIphoneDetailDescription',
      importantDescription: 'TapNewIphoneDetailImportantDescription',
      backUpDescriptionHeading: 'BackupDescriptionHeading',
      backUpDescription: 'TapNewIphoneDetailBackupDescription',
      GifImage: NewIphoneGif,
    },
  },
  {
    id: 2,
    heading: 'TapOlderIphoneHeading',
    slug: 'tap-old-iphone',
    subHeading: 'TapOlderIphoneSubHeading',
    image: OldIphone,
    details: {
      heading: 'TapOlderIphoneDetailHeading',
      subHeading: 'TapOlderIphoneDetailSubHeading',
      description: 'TapOlderIphoneDetailDescription',
      importantDescription: 'TapOlderIphoneDetailImportantDescription',
      backUpDescriptionHeading: 'BackupDescriptionHeading',
      backUpDescription: 'TapOlderIphoneDetailBackupDescription',
      GifImage: TapOlderIphoneGif,
    },
  },
  {
    id: 3,
    heading: 'TapAndroidHeading',
    slug: 'tap-android',
    subHeading: 'TapAndroidSubHeading',
    image: AndroidNew,
    details: {
      heading: 'TapAndroidDetailHeading',
      subHeading: 'TapAndroidDetailSubHeading',
      description: 'TapAndroidDetailDescription',
      importantDescription: 'TapAndroidDetailImportantDescription',
      importantDescription2: 'TapAndroidDetailImportantDescription2',
      backUpDescriptionHeading: 'BackupDescriptionHeading',
      backUpDescription: 'TapAndroidDetailBackupDescription',
      GifImage: TapAndroidsGif,
      importantInfoImage: TapAndroidImportant,
    },
  },
  {
    id: 4,
    heading: 'TapScanHeading',
    slug: 'scan-qr-code',
    subHeading: 'TapScanSubHeading',
    image: ScanQr,
    details: {
      heading: 'TapScanDetailHeading',
      subHeading: 'TapScanDetailSubHeading',
      description: 'TapScanDetailDescription',
      importantDescription: 'TapScanDetailImportantDescription',
      GifImage: QRCodeScanGif,
    },
  },
];

export type TapInfoType = {
  id: number;
  slug: string;
  heading: string;
  subHeading: string;
  image: string;
};
