import { IconButton, Fade, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react';

export type MenuItemType = {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
};

type Props = {
  icon?: React.ReactNode;
  menuItems: MenuItemType[];
  anchorEl?: HTMLElement;
  onClose?: () => void;
};

const SettingsMenu = ({ icon, menuItems, anchorEl, onClose }: Props) => {
  const [_anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };
  const anchorElement = anchorEl || _anchorEl;
  const open = Boolean(anchorElement);

  return (
    <>
      {icon && <IconButton onClick={handleClick}>{icon}</IconButton>}
      <Menu
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            sx={{ width: '30rem' }}
            key={index}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            <ListItemIcon sx={{ color: 'secondary.main' }}>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SettingsMenu;
