import React from 'react';
import styled from 'styled-components';

export const ErrorMessage = (props: { message: string }): JSX.Element => {
  const { message } = props;
  return <Wrapper>{message}</Wrapper>;
};

const Wrapper = styled.span`
  margin-bottom: 30px;
  display: inline-block;
  background: #ff0000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  padding: 8px 20px;
  height: fit-content;
  overflow-y: auto;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  box-sizing: border-box;
  width: 100%;
  top: unset !important;
  position: relative !important;
  white-space: pre-line;

  @media (max-width: 350px) {
    margin-bottom: 0;
  }
`;
