import { LEAD_MULTILINE_FIELDS, THEME_CONFIG_KEYS } from '@/shared/constants';
import { LeadDataProps } from '@/shared/types/api';
import { getContactFormTranslationKeyByField, removeLeading } from '@/shared/util';
import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GDPRSelector } from './GDPRSelector';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { LanguageSelector } from './LanguageSelector';
import { isPhoneFaxField } from '@/views/pages/edit-bio/utils';

type Props = {
  fields: Array<keyof LeadDataProps>;
  values: Partial<LeadDataProps>;
  onChange: (values: Partial<LeadDataProps>) => void;
  fullWidth?: boolean;
};

export const FieldsRenderer = ({ fields, values, onChange, fullWidth }: Props) => {
  const { t } = useTranslation();
  const { config: gdprOptions } = usePrivateThemeConfig<string[]>(
    THEME_CONFIG_KEYS.GDPR_LEGAL_BASIS_OPTIONS,
  );
  return (
    <>
      {fields.map((field: keyof LeadDataProps) => {
        const value = values[field];
        return field === 'gdprLegalBasis' ? (
          <GDPRSelector
            gdprOptions={gdprOptions || []}
            onGdprSelect={value => onChange({ ...values, gdprLegalBasis: value })}
            value={values.gdprLegalBasis || ''}
          />
        ) : field === 'preferredLang' ? (
          <LanguageSelector
            onLangSelect={(value: string) => onChange({ ...values, preferredLang: value })}
            value={values.preferredLang || ''}
          />
        ) : (
          <TextField
            fullWidth={fullWidth}
            key={field}
            label={t(getContactFormTranslationKeyByField(field))}
            value={
              isPhoneFaxField(field) && typeof value === 'string'
                ? removeLeading(value, '+')
                : value || ''
            }
            onChange={e => onChange({ ...values, [field]: e.target.value })}
            multiline={LEAD_MULTILINE_FIELDS.includes(field)}
            InputProps={{
              startAdornment: isPhoneFaxField(field) ? (
                <InputAdornment position='start'>+</InputAdornment>
              ) : null,
            }}
          />
        );
      })}
    </>
  );
};
