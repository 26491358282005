import config from '../../../config/config';
import { openLink } from '@/util';

/****************************************/
/** Webshare has been disabled for now **/
/**   due to confused user reception   **/
/****************************************/

// let windowNavigator: any;
// windowNavigator = window.navigator;

export const handleWebShare = (username: string, lang: string) => {
  // const { saveContactClicked, setSaveContactClicked } = SCState;
  const vcardUrl = `${config.API_BASE_URL}account/download/contact?usernameOrCode=${username}&lang=${lang}`;
  // const fullName = `${account.firstName ?? account.username}_${account.lastName}`;

  openLink(vcardUrl);

  // if (saveContactClicked) {
  //   downloadVcard(DownloadElRef, vcardUrl);
  // } else {
  //   if (!!windowNavigator.share && browserName === 'Safari' && browserVersion >= 15) {
  //     fetch(vcardUrl)
  //       .then(response => response.blob())
  //       .then(res => {
  //         const file = new File([res], `${fullName + t('contactShareFile')}.vcf`, {
  //           type: 'text/x-vcard',
  //         });
  //         if (windowNavigator.canShare && windowNavigator.canShare({ files: [file] })) {
  //           setSaveContactClicked(true);
  //           windowNavigator
  //             .share({
  //               files: [file],
  //               title: `${t('contactShareTitle') + fullName}`,
  //               text: `${t('contactShareText') + fullName}.`,
  //             })
  //             .then(() => {
  //               return;
  //             })
  //             .catch(error => {
  //               if (error.name.startsWith('AbortError')) {
  //                 window.location.reload();
  //               } else if (!error.name.startsWith('NotAllowedError')) {
  //                 downloadVcard(DownloadElRef, vcardUrl);
  //               }
  //             });
  //         } else {
  //           downloadVcard(DownloadElRef, vcardUrl);
  //         }
  //       })
  //       .catch(err => {
  //         downloadVcard(DownloadElRef, vcardUrl);
  //       });
  //   } else {
  //     downloadVcard(DownloadElRef, vcardUrl);
  //   }
  // }
};
