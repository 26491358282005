import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import {} from '../../../../infrastructure/apis/edit-profile';
import FeatureTeaser from '../teaser/featureTeaser';
import { FEATURE, THEME_CONFIG_KEYS } from '../../../../shared/constants';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import teaserImage from '../../../images/teaser/contact-form.jpg';
import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import {
  HeaderTextContainer,
  InteractionsContainer,
  PaddedContainer,
  PreviewContainer,
  SectionHeader,
  Spacer,
} from './common';
import { PageSubheader } from '../common';
import Buttons from './buttons';
import { writeThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import Sorter from './ContactFieldsSorter';
import withNav from '@/infrastructure/hoc/withNav';
import imgPreviewDe from '@/views/images/contact-form-preview-de.png';
import imgPreviewEn from '@/views/images/contact-form-preview-en.png';
import { CSS_VARS } from '@/infrastructure/constants';

const ContactFormFields = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'md-settings-contact-form');

  if (isFeatureAllowed(FEATURE.LEAD_GEN_FORM_CONFIG)) {
    return <FeatureContent />;
  } else {
    return (
      <FeatureTeaser text={t('upgradeTeaser.feature.leadGenContactForm')} imageSrc={teaserImage} />
    );
  }
};

export default withNav(ContactFormFields, { tTitle: 'contactFormPageHeader' }, {});

const getFormattedArray = (array: string[]) => {
  const index = array.indexOf('firstAndLastName');
  if (index !== -1) {
    return [...array.slice(0, index), ['firstName', 'lastName'], ...array.slice(index + 1)];
  }
  return array;
};

const fetchLeadKeys = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: fieldKeysResponse } = await fetchThemeConfig<string[]>(
    getAccessTokenSilently,
    THEME_CONFIG_KEYS.LEAD_DETAILS_KEYS,
  );

  if (fieldKeysResponse.includes('firstName') && fieldKeysResponse.includes('lastName')) {
    return [...fieldKeysResponse, 'firstAndLastName'];
  }
  return fieldKeysResponse;
};

const fieldMapper = (field: string | string[]) =>
  Array.isArray(field) && field.includes('firstName') && field.includes('lastName')
    ? 'firstAndLastName'
    : field;

const fetchContactForm = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: contactForm } = await fetchThemeConfig<{
    required: Array<string | Array<string>>;
    optional: Array<string | Array<string>>;
  }>(getAccessTokenSilently, THEME_CONFIG_KEYS.PROFILE_PAGE_CONTACT_FORM);

  return {
    required: contactForm.required.map(fieldMapper).flat(),
    optional: contactForm.optional.map(fieldMapper).flat(),
  };
};

const FeatureContent = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [contactFormFields, setContactFormFields] = useState({
    required: [],
    optional: [],
    notShown: [],
  });
  const [loading, setLoading] = useState(false);

  useDeferredLoader(isDataLoading, 'data-templates-contact-form-loader-id');

  const { getAccessTokenSilently } = useAuth0();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const [fieldKeysResponse, contactFormResponse] = await Promise.all([
          fetchLeadKeys(getAccessTokenSilently),
          fetchContactForm(getAccessTokenSilently),
        ]);

        const notShownFields = fieldKeysResponse.filter(
          x =>
            !contactFormResponse.required.includes(x) && !contactFormResponse.optional.includes(x),
        );

        setContactFormFields({
          required: contactFormResponse.required,
          optional: contactFormResponse.optional,
          notShown: notShownFields,
        });
      } catch (error) {
        toast.error(t('error.general'));
      }
      setIsDataLoading(false);
    };

    fetchData();
  }, [getAccessTokenSilently, t]);

  const handleSave = async () => {
    if (contactFormFields.required.length === 0) {
      toast.error(t('contactFormEdit.atLeastOneRequired'));
      return;
    }
    setLoading(true);
    const payload = {
      required: getFormattedArray(contactFormFields.required),
      optional: getFormattedArray(contactFormFields.optional),
    };
    try {
      toast.loading(t('Loading'), { id: 'contact-form-saving' });
      await writeThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.PROFILE_PAGE_CONTACT_FORM,
        value: payload,
      });
      toast.success(t('changesSaved'), { id: 'contact-form-saving' });
    } catch (error) {
      toast.error(t('error.general'), { id: 'contact-form-saving' });
    }
    setLoading(false);
  };

  return (
    <>
      <PageContainer>
        <PreviewContainer>
          <PreviewImage
            src={i18n.language?.startsWith('de') ? imgPreviewDe : imgPreviewEn}
            alt='static-preview'
          />
        </PreviewContainer>
        <InteractionsContainer style={{ transform: 'none' }}>
          <PaddedContainer>
            <HeaderTextContainer>
              <SectionHeader>
                {t('contactFormEdit.title')}
                <Spacer size={9} />
              </SectionHeader>
              <PageSubheader>{t('contactFormEdit.description')}</PageSubheader>
            </HeaderTextContainer>
            {contactFormFields && (
              <Sorter
                fields={contactFormFields}
                title={t('contactFormEdit.sortRequiredFields')}
                onSortEnd={fields => setContactFormFields(fields)}
              />
            )}
          </PaddedContainer>
          <Buttons position='sticky' saveDisabled={loading} onSave={handleSave} />
        </InteractionsContainer>
      </PageContainer>
    </>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  min-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  max-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
`;

const PreviewImage = styled.img`
  width: 80%;
  min-width: 30rem;
  max-width: 45rem;
  padding: 3rem;
`;
