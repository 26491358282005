import { dispatchTypes } from './index';

export const setSidebarCollapsed = (collapsed: boolean) => async dispatch => {
  dispatch({
    type: dispatchTypes.UI.SET_SIDEBAR_COLLAPSED,
    sidebarCollapsed: collapsed,
  });
};

export const setSidebarMyProfileCollapsed = (collapsed: boolean) => async dispatch => {
  dispatch({
    type: dispatchTypes.UI.SET_SIDEBAR_MY_PROFILE_COLLAPSED,
    collapsed,
  });
};

export const setSidebarAdminManagementCollapsed = (collapsed: boolean) => async dispatch => {
  dispatch({
    type: dispatchTypes.UI.SET_SIDEBAR_ADMIN_MANAGEMENT_COLLAPSED,
    collapsed,
  });
};

export const setSidebarSettingsCollapsed = (collapsed: boolean) => async dispatch => {
  dispatch({
    type: dispatchTypes.UI.SET_SIDEBAR_SETTINGS_COLLAPSED,
    collapsed,
  });
};
