import { Box, IconButton } from '@mui/material';
import XIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { MouseEventHandler } from 'react';

type XButtonProps = {
  onClick: MouseEventHandler;
  color?: string;
};

export const XButton = ({ onClick, color = 'white' }: XButtonProps) => {
  return (
    <FixedBox sx={{ top: '2rem', right: '2rem' }}>
      <IconButton size='large' onClick={onClick}>
        <XIcon sx={{ color, width: '3rem', height: '3rem' }} />
      </IconButton>
    </FixedBox>
  );
};

export const FixedBox = styled(Box)({
  position: 'absolute',
  zIndex: 101,
});
