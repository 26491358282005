import salesForceIcon from '../../images/salesforce.svg';
import hubSpotIcon from '../../images/hubspot.svg';
import sapIcon from '../../images/sap.svg';
import pipeDriveIcon from '../../images/pipedrive.svg';
import microsoftIcon from '../../images/microsoft365.svg';

const crms = [
  {
    id: 1,
    icon: salesForceIcon,
    alt: 'SalesForce',
    url: 'https://help.salesforce.com/s/articleView?id=sf.import_with_data_import_wizard.htm&type=5',
  },
  {
    id: 2,
    icon: hubSpotIcon,
    alt: 'HubSpot',
    url: 'https://knowledge.hubspot.com/de/crm-setup/import-objects?_ga=2.21941435.1884510151.1616683804-1738100527.1616683804',
  },
  {
    id: 3,
    icon: sapIcon,
    alt: 'SAP',
    url: 'https://help.sap.com/viewer/0204678aad934e5da0ecf4d40ba38ca9/1909.YMKT/de-DE/7dd16e5458548c4ce10000000a4450e5.html',
  },
  {
    id: 4,
    icon: pipeDriveIcon,
    alt: 'PipeDrive',
    url: 'https://support.pipedrive.com/de/article/importing-data-into-pipedrive-with-spreadsheets',
  },
  {
    id: 5,
    icon: microsoftIcon,
    alt: 'Microsoft Dynamics 365',
    url: 'https://docs.microsoft.com/de-de/dynamics365/marketing/import-data',
  },
];

export default crms;
