import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  options: { value: string; tName: string }[];
  onChange: (value: string) => void;
};

export const LanguageSelector = ({ value, options, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel>{t('emailTemplates.selectLanguage')}</InputLabel>
      <Select
        value={value}
        label={t('emailTemplates.selectLanguage')}
        onChange={({ target: { value } }) => onChange(value)}
      >
        {options.map(locale => (
          <MenuItem key={locale.value} value={locale.value}>
            {t(`emailTemplates.${locale.tName}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
