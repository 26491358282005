import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import config from '../../config/config';
import { Account } from '../../shared/types/api';
import { withSilentAccessToken } from '../helper';
import useDeferredLoader from './useDeferredLoader';
import { useTranslation } from 'react-i18next';

const useBlockedFields = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [blockedFields, setBlockedFields] = useState<Array<keyof Account> | null>(null);
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [blockedFieldsLoading, setBlockedFieldsLoading] = useState<boolean>(true);

  const checkDisabledField = useCallback(
    (field: keyof Account, additionalConditions?: Array<boolean>) => {
      const conditions = additionalConditions?.reduce((prev, curr) => prev || curr);
      return conditions || blockedFields?.includes(field);
    },
    [blockedFields],
  );

  useDeferredLoader(blockedFieldsLoading, 'blocked-fields-toast');

  useEffect(() => {
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.get(config.API_BASE_URL + 'theme/blocked-bio-fields', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
      .then(res => {
        const result = res.data;
        if (result.isSuccess) {
          setBlockedFields(result.data);
          setDisableFields(false);
        }
      })
      .catch(error => {
        toast.error(t('error.general'), { id: 'blocked-fields-toast' });
        setDisableFields(true);
      })
      .finally(() => setBlockedFieldsLoading(false));
  }, [getAccessTokenSilently, t]);

  return { blockedFields, disableFields, checkDisabledField };
};

export default useBlockedFields;
