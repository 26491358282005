import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useUnitOverrideSettings } from '@/infrastructure/hooks/useUnitOverrideSettings';

import { UnitSelector, useUnitsForSettings } from '@/views/components/units/UnitSelector';
import { Box, Divider, Theme, Typography, styled } from '@mui/material';
import { FeatureSet, HierarchyLevel, UnitOverrideRightsKey } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import {
  PaddedContainer,
  PreviewContainer,
  InteractionsContainer,
  WhitespaceContainer,
} from '../../common';
import SmartphonePreview from '../../smarphone-preview';
import { SetupSettings } from '../SetupSettings';
import { SettingsContext } from './context';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useUnsavedStatus } from '@/utils/unsavedStatus';
import FeatureTeaser from '../../../teaser/featureTeaser';
import LtDialog from '@/components/LtDialog';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { FEATURE } from '@/shared/constants';

type Props = {
  unitSelectorDisabled?: boolean;
  settingKey: UnitOverrideRightsKey;
  children: React.ReactNode;
  requiredFeature: keyof FeatureSet;
  teaserContent: {
    text: string;
  };
  forceTeaser?: boolean;
  slot?: React.ReactNode;
  useMUIDesign?: boolean;
  overrideTexts?: {
    editNotAllowed?: string;
    setupSettings?: {
      title?: string;
      description?: string;
      buttonText?: string;
    };
    deleteDialog?: {
      unit?: {
        settingDeleteConfirmationTitle?: string;
        settingDeleteConfirmation?: string;
      };
      theme?: {
        settingResetConfirmationTitle?: string;
        settingResetConfirmation?: string;
      };
    };
  };
};

const actionsWrapperStyles = ({ palette }: Theme) => ({
  bgcolor: palette.background.paper,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: 1.5,
  pb: 1.5,
});

export const SettingsWrapper = ({
  settingKey,
  children,
  slot,
  useMUIDesign,
  overrideTexts,
  requiredFeature,
  teaserContent,
  forceTeaser,
  unitSelectorDisabled,
}: Props) => {
  const { isThemeAdmin, loading: tierInfoLoading, isUnitAdmin, isFeatureAllowed } = useTierInfo();

  const { t } = useTranslation();

  const { selectedUnit, setSelectedUnit, units } = useUnitsForSettings();
  const selectedUnitId = selectedUnit?.id || null;
  const [settingsHierarchyLevel, setSettingsHierarchyLevel] = useState<HierarchyLevel | null>(null);
  const [setupClicked, setSetupClicked] = useState(false);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { openDialogIfUnsaved } = useUnsavedStatus();

  const { isUnitOverrideAllowed, fetched: overrideSettingsFetched } = useUnitOverrideSettings({
    unitId: selectedUnitId,
  });

  const showLoader =
    tierInfoLoading || (!isThemeAdmin && (!selectedUnitId || !overrideSettingsFetched));

  useDeferredLoader(settingsLoading, 'settings-loader-id');
  useDeferredLoader(showLoader, 'settings-loader-id');

  const showTeaser =
    forceTeaser || (isThemeAdmin && Boolean(selectedUnit) && !isFeatureAllowed(requiredFeature));

  const canEdit =
    isThemeAdmin ||
    (isFeatureAllowed(requiredFeature) && !isThemeAdmin && isUnitOverrideAllowed(settingKey));

  const showSetupUnitsSettings =
    canEdit &&
    settingsHierarchyLevel !== null &&
    settingsHierarchyLevel !== HierarchyLevel.Unit &&
    selectedUnitId &&
    !setupClicked;

  const showPageContent =
    canEdit && !showLoader && !showSetupUnitsSettings && settingsHierarchyLevel !== null;

  const flag_showGlobalReset = usePrivateFeatureFlag('themeConfigGlobalReset');

  const deleteButtonIsVisible =
    (isUnitAdmin && settingsHierarchyLevel === HierarchyLevel.Unit) ||
    (isThemeAdmin && settingsHierarchyLevel === HierarchyLevel.Unit) ||
    (isThemeAdmin &&
      settingsHierarchyLevel === HierarchyLevel.Theme &&
      !selectedUnit &&
      flag_showGlobalReset);

  useEffect(() => {
    setSetupClicked(false);
    setSettingsHierarchyLevel(null);
  }, [selectedUnitId, settingKey]);

  const deleteCallbackFnRef = useRef(null);

  const deleteClickHandler = confirmCallback => {
    setDeletePopupShow(true);
    deleteCallbackFnRef.current = confirmCallback;
  };

  const deleteDialogConfirmHandler = async () => {
    setDeleteLoading(true);
    deleteCallbackFnRef.current && (await deleteCallbackFnRef.current());
    deleteCallbackFnRef.current = null;
    setDeletePopupShow(false);
    setupClicked && setSetupClicked(false);
    setDeleteLoading(false);
  };

  const deleteDialogCloseHandler = async () => {
    deleteCallbackFnRef.current = null;
    setDeletePopupShow(false);
  };

  const content = useMemo(
    () => (
      <>
        {showSetupUnitsSettings && (
          <SetupSettings
            onClick={() => setSetupClicked(true)}
            overrideTexts={overrideTexts?.setupSettings}
            pt={2}
          />
        )}
        <Box sx={{ display: showPageContent ? 'block' : 'none' }}>
          <SettingsContext.Provider
            value={{
              selectedUnit,
              deleteButtonIsVisible,
              settingsHierarchyLevel,
              setSettingsHierarchyLevel,
              deleteClickHandler,
              setSettingsLoading,
              canEdit,
            }}
          >
            {children}
          </SettingsContext.Provider>
        </Box>
      </>
    ),
    [
      canEdit,
      children,
      deleteButtonIsVisible,
      overrideTexts?.setupSettings,
      selectedUnit,
      settingsHierarchyLevel,
      showPageContent,
      showSetupUnitsSettings,
    ],
  );

  return (
    <>
      {useMUIDesign ? (
        <Box width='100%' pb={2}>
          {isFeatureAllowed(FEATURE.UNITS) && units?.length ? (
            <Box sx={actionsWrapperStyles}>
              {slot ? slot : null}
              <Box display={'flex'} width='35%'>
                <>
                  <UnitSelector
                    selectedUnit={selectedUnit}
                    onChange={value => openDialogIfUnsaved(() => setSelectedUnit(value))}
                    units={units}
                    requiredFeature={requiredFeature}
                    disabled={unitSelectorDisabled}
                  />
                  <Divider />
                </>
              </Box>
            </Box>
          ) : null}
          <Divider />
          <Box>
            {showLoader ? null : showTeaser && isThemeAdmin ? (
              <>
                <FeatureTeaser text={teaserContent.text} fullWidth />
                {!selectedUnit && (
                  <Box sx={{ opacity: 0.35, pointerEvents: 'none !important' }}>{content}</Box>
                )}
              </>
            ) : !canEdit ? (
              <Typography variant='body2' pt={4} pb={4}>
                {overrideTexts?.editNotAllowed ?? t('editNotAllowed')}
              </Typography>
            ) : (
              <>{content}</>
            )}
          </Box>
        </Box>
      ) : (
        <WhitespaceContainer scroll>
          <StyledPreviewContainer>
            <Box px={'2rem'} py={'1rem'} sx={{ width: '100%' }}>
              {isFeatureAllowed(FEATURE.UNITS) && units?.length ? (
                <UnitSelector
                  selectedUnit={selectedUnit}
                  onChange={value => openDialogIfUnsaved(() => setSelectedUnit(value))}
                  units={units}
                  requiredFeature={requiredFeature}
                  disabled={unitSelectorDisabled}
                />
              ) : null}
            </Box>
            <StyledPreviewWrapper>
              <SmartphonePreview />
            </StyledPreviewWrapper>
          </StyledPreviewContainer>
          <InteractionsContainer>
            {slot && slot}
            {showLoader ? null : showTeaser ? (
              <FeatureTeaser text={teaserContent.text} />
            ) : !canEdit ? (
              <PaddedContainer>
                {overrideTexts?.editNotAllowed ?? t('editNotAllowed')}
              </PaddedContainer>
            ) : (
              <>
                {showSetupUnitsSettings && (
                  <PaddedContainer>
                    <SetupSettings
                      onClick={() => setSetupClicked(true)}
                      overrideTexts={overrideTexts?.setupSettings}
                    />
                  </PaddedContainer>
                )}
                <Box sx={{ display: showPageContent ? 'block' : 'none' }}>
                  <SettingsContext.Provider
                    value={{
                      selectedUnit,
                      deleteButtonIsVisible,
                      settingsHierarchyLevel,
                      setSettingsHierarchyLevel,
                      deleteClickHandler,
                      setSettingsLoading,
                      canEdit,
                    }}
                  >
                    {children}
                  </SettingsContext.Provider>
                </Box>
              </>
            )}
          </InteractionsContainer>
        </WhitespaceContainer>
      )}

      <LtDialog
        title={
          settingsHierarchyLevel === HierarchyLevel.Unit
            ? overrideTexts?.deleteDialog?.unit?.settingDeleteConfirmationTitle ??
              t('settingDeleteConfirmationTitle')
            : overrideTexts?.deleteDialog?.theme?.settingResetConfirmationTitle ??
              t('settingResetConfirmationTitle')
        }
        open={deletePopupShow}
        loading={deleteLoading}
        onClose={deleteDialogCloseHandler}
        onCancel={deleteDialogCloseHandler}
        onDelete={() => deleteDialogConfirmHandler()}
      >
        {settingsHierarchyLevel === HierarchyLevel.Unit
          ? overrideTexts?.deleteDialog?.unit?.settingDeleteConfirmation ??
            t('settingDeleteConfirmation')
          : overrideTexts?.deleteDialog?.theme?.settingResetConfirmation ??
            t('settingResetConfirmation')}
      </LtDialog>
    </>
  );
};

const StyledPreviewContainer = styled(PreviewContainer)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  justifyContent: 'flex-start',
});

const StyledPreviewWrapper = styled(Box)({
  flex: 1,
  display: 'flex',
  width: '100%',
  overflow: 'auto',
});
