import React from 'react';
import styled from 'styled-components';

type Props = {
  value?: string | number;
  setValue: Function;
  options: { id: number; title: string; value: number }[];
  formLabel?: string;
  styles?: {
    size?: string;
    width?: string;
    align?: string;
    center?: boolean;
  };
};

export const DropDown: React.FC<Props> = ({ value, setValue, options, formLabel, styles }) => {
  const identifier = formLabel
    ? formLabel.toLowerCase()
    : Math.floor(Math.random() * 1000).toString();

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  return (
    <DropdownWrapper width={styles?.width} center={styles?.center}>
      {formLabel && <StyledLabel htmlFor={identifier}>{formLabel}</StyledLabel>}
      <StyledSelect
        id={formLabel && identifier}
        name={identifier}
        onChange={e => handleChange(e)}
        styles={styles}
        value={value}
      >
        {options.map(option => {
          return (
            <StyledOption value={option.value} key={option.id}>
              {option.title}
            </StyledOption>
          );
        })}
      </StyledSelect>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: ${props => props?.width ?? 'auto'};
  ${props => props?.center && 'margin: 0 auto'};
  position: relative;
  &::after {
    content: '>';
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 1.5rem;
    top: 0.5rem;
    position: absolute;
    pointer-events: none;
  }
`;

const StyledLabel = styled.label``;

export const StyledSelect = styled.select`
  align-self: ${props => props?.styles?.align ?? 'center'};
  font-size: ${props => props?.styles?.size ?? '13px'};
  background: white;
  height: 3.25rem;
  line-height: 1;
  margin: 0;
  padding: 0 0.5rem;
  border-radius: 25px;
  border: 1px solid grey;
  text-align: center;
  cursor: pointer;
  appearance: none;
  cursor: pointer;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    padding-right: 1.5rem;
  }
`;

export const StyledOption = styled.option`
  color: ${props => (props.selected ? '#02e0b1' : 'black')};
`;
