import { ArrowBack } from '@mui/icons-material';
import { ListItemButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const BackButton = ({ onClick, sidebarCollapsed }) => {
  const { t } = useTranslation();
  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        height: '4.8rem',
        ':hover': { bgcolor: 'initial' },
        ...(sidebarCollapsed
          ? {
              p: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {}),
      }}
    >
      <ArrowBack />
      {!sidebarCollapsed && (
        <Typography variant='h3' sx={{ color: 'inherit', ml: '1.6rem' }}>
          {t('teamSettings')}
        </Typography>
      )}
    </ListItemButton>
  );
};
