import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import backButton from '../../images/back.png';
import { ReactComponent as BackIcon } from '../../images/back-icon.svg';
import { RequireOnlyOne } from '../../../shared/types/util';

interface IProps {
  isExternal?: boolean;
  to?: string;
  isButton?: true;
  onClick?: () => void;
}
type Props = RequireOnlyOne<IProps, 'to' | 'onClick'>;

const Back = (props: Props): JSX.Element => {
  const { isExternal, to, isButton, onClick } = props;

  if (isExternal === true) {
    return (
      <a href={to}>
        <img src={backButton} alt='' />
      </a>
    );
  }

  if (isButton === true) {
    return (
      <Button onClick={onClick}>
        <BackIcon />
      </Button>
    );
  }

  return (
    <Link to={to}>
      <img src={backButton} alt='' />
    </Link>
  );
};
export default Back;
