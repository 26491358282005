import { LtDialog } from '@/components';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const NoteAddEditPopup = ({ opened, onClose, onSave, saving, initialValue }) => {
  const [note, setNote] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setNote(initialValue);
  }, [initialValue]);

  return (
    <LtDialog
      title={t('leads.addEditNote')}
      open={opened}
      onClose={onClose}
      onCancel={onClose}
      onSave={() => onSave(note)}
      loading={saving}
    >
      <TextField
        label={t('note')}
        fullWidth
        multiline
        rows={4}
        value={note}
        onChange={e => setNote(e.target.value)}
      />
    </LtDialog>
  );
};
