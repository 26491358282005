import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/common';
import { routePaths } from '../../../infrastructure/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, useLocation } from 'react-router-dom';
import useQuery from '../../../infrastructure/hooks/useQuery';

const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation<{ codeToConnect?: string }>();

  const [signupWithCode, setSignupWithCode] = useState<boolean>();
  const [signupWithoutCode, setSignupWithoutCode] = useState<boolean>();

  const query = useQuery();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !isAuthenticated) {
        if (query.get('signup')) {
          if (query.get('code')) {
            setSignupWithCode(true);
          } else {
            setSignupWithoutCode(true);
          }
        } else {
          const redirectUri = window.location.origin + routePaths.HOME + '?lt-event=login';
          await loginWithRedirect({
            redirectUri,
            appState: {
              login: true,
              ltLoginCode: location?.state?.codeToConnect || undefined,
            },
          });
        }
      }
    })();
  }, [isAuthenticated, isLoading, location?.state?.codeToConnect, loginWithRedirect, query]);

  // redirect to signup and send along the CodeToConnect with a new email account
  if (signupWithCode) {
    return (
      <Redirect
        to={{
          pathname: routePaths.REGISTRATION.SIGNUP,
          state: { codeToConnect: query.get('code') },
        }}
      />
    );
  }

  // redirect to signup
  if (signupWithoutCode) {
    return <Redirect exact to={routePaths.REGISTRATION.SIGNUP} />;
  }

  if (isAuthenticated) {
    return <Redirect exact to={routePaths.HOME} />;
  } else {
    return <Loader />;
  }
};

export default Login;
