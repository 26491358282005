import { MuiPalette } from '@/shared/types/api';
import { alpha } from '@mui/system';

export const defaultPalette: MuiPalette = {
  text: {
    primary: '#111356',
    secondary: '#495474',
    disabled: 'rgba(17, 19, 86, 0.38)',
  },
  sidebar: {
    main: '#111356',
    contrastText: '#FBFBFF',
  },
  sidebarActive: {
    main: '#FBFBFF',
    contrastText: '#111356',
  },
  primary: {
    main: '#111356',
    dark: '#080A3A',
    light: '#FBFBFF',
    contrastText: '#FBFBFF',
  },
  secondary: {
    main: '#495474',
    dark: '#E4EAFF',
    light: '#FBFBFF',
    contrastText: '#FFF',
  },
  error: {
    main: '#F44336',
    dark: '#D32F2F',
    light: '#E57373',
    contrastText: '#FFF',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrastText: '#FFF',
  },
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    contrastText: '#FFF',
  },
  success: {
    main: '#008561',
    dark: '#167960',
    light: '#08E5AC',
    contrastText: '#FFF',
  },
  primaryButton: {
    main: '#008484',
    dark: '#007272',
    light: '#019494',
    contrastText: '#FFF',
  },
  action: {
    active: '#111356',
    hover: '#f6f6f8',
    hoverOpacity: 0.04,
    selected: 'rgba(17, 19, 86, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(17, 19, 86, 0.26)',
    disabledOpacity: 0.26,
    disabledBackground: 'rgba(17, 19, 86, 0.12)',
    focus: 'rgba(17, 19, 86, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  background: {
    paper: '#FFFFFF',
    box: '#F6F8FE',
    video: '#E4E8EB',
    default: '#FBFBFF',
  },
  // divider: '#E4EAFF',
  divider: alpha('#111356', 0.08),
};

export const customLightPalette = {
  outlineBorder: 'rgba(17, 19, 86, 0.23)',
  standardInputLine: 'rgba(17, 19, 86, 0.42)',
  backdropOverlay: 'rgba(17, 19, 86, 0.50)',
  ratingActive: '#FFB400',
  snackbarBackground: '#111356',
  anchor: '#4450E3',
};
