import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SubsectionSpan, ToggleRow, TogglesContainer } from './common';
import Switch from 'react-switch';
import TooltipInfo from '../../../components/generic/TooltipInfo';

interface Props {
  leadGenFormShown: boolean;
  setLeadGenFormShown: (value: boolean) => void;
  leadGenFormAsPopup: boolean;
  setLeadGenFormAsPopup: (value: boolean) => void;
}

const ContactsForm = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TogglesContainer>
        <ToggleRow>
          <TooltipInfo text={t('md.profileDesign.showForm')} placement='top' onHover={true}>
            <SubsectionSpan style={{ cursor: 'help' }}>{t('showContactsForm')}</SubsectionSpan>
          </TooltipInfo>
          <Switch
            onChange={() => props.setLeadGenFormShown(!props.leadGenFormShown)}
            checked={props.leadGenFormShown}
            onColor={'#02E0B1'}
            offColor='#C1CBDC'
          />
        </ToggleRow>
        <ToggleRow>
          <TooltipInfo text={t('md.profileDesign.showFormAsPopup')} placement='top' onHover={true}>
            <SubsectionSpan style={{ cursor: 'help' }}>
              {t('showContactsFormAsPopup')}
            </SubsectionSpan>
          </TooltipInfo>
          <Switch
            onChange={() => props.setLeadGenFormAsPopup(!props.leadGenFormAsPopup)}
            checked={props.leadGenFormAsPopup}
            onColor={'#02E0B1'}
            disabled={!props.leadGenFormShown}
            offColor='#C1CBDC'
          />
        </ToggleRow>
      </TogglesContainer>
    </Container>
  );
};

export default ContactsForm;

const Container = styled.div`
  margin-top: 20px;
`;
