import ThemeProvider from '@mui/system/ThemeProvider';
import { GlobalStyles } from '@mui/styled-engine';
import { Route, RouteProps } from 'react-router-dom';
import { useMuiTheme } from './config/theme/useMuiTheme';
import { Loader } from './views/components/common';

const inputGlobalStyles = (
  <GlobalStyles
    styles={theme => `
  body {
    background-color: ${theme.palette.secondary.light};
  }
`}
  />
);

interface Props {
  exact?: boolean;
  path: string;
  component: RouteProps['component'];
}

export const RouteWithMuiTheme = ({ path, exact, component }: Props) => {
  const { theme: muiTheme, isFetching } = useMuiTheme();

  if (isFetching) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={muiTheme}>
      {inputGlobalStyles}
      <Route exact={exact} path={path} component={component} />
    </ThemeProvider>
  );
};
