import React, { SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spacer } from './common';
import { Popup } from '../../../components/common';
import AddLinkComponent from './addLinkComponent';
import { MdDelete, MdOutlineModeEditOutline } from 'react-icons/md';
import EditLinkComponent from './editLinkComponent';
import { getFullLink, withSilentAccessToken } from '../../../../infrastructure/helper';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../config/config';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import teaserImage from '../../../images/teaser/data-templates.png';
import FeatureTeaser from '../teaser/featureTeaser';
import { FEATURE } from '../../../../shared/constants';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import { LinkType } from '@/shared/types/api';
import { Separator } from '@/views/components/generic';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';

export interface LinkFields {
  id?: number;
  name: string;
  link: string;
  type: number;
  iconUrl: string;
  color: string;
  isDefault: boolean;
  mode: string;
  themeLinkType?: { id?: number; linkType: LinkType };
}

const CustomDataLinks = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'data-templates-feature-loading-id');

  if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
    return <FeatureContent />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.dataTemplates')} imageSrc={teaserImage} />;
  }
};

export default CustomDataLinks;

const FeatureContent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [savedLinks, setSavedLinks] = useState<LinkFields[]>([]);
  const [selectedLink, setSelectedLink] = useState<LinkFields>();
  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useDeferredLoader(isLoading, 'data-templates-feature-content-loading-id');

  const { profileDesign } = useProfileDesign();

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(`${config.API_BASE_URL}theme/link-templates`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_GLOBAL],
    ).then(res => {
      setSavedLinks(res.data.data);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editLink = (link: LinkFields) => {
    setSelectedLink(link);
    setShowEditPopup(true);
  };

  const handleDeleteLink = () => {
    setDeleteAlertVisible(false);
    const promise = withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.delete(`${config.API_BASE_URL}theme/link-templates/${selectedLink.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.WRITE.THEME_GLOBAL],
    );

    toast.promise(promise, {
      loading: t('savingTemplate'),
      success: res => {
        setSavedLinks(res.data.data);
        return t('linkTab.successDelete');
      },
      error: t('linkTab.errorDelete'),
    });
  };
  const deleteLink = (link: LinkFields) => {
    setSelectedLink(link);
    setDeleteAlertVisible(true);
  };

  const RenderLinks = () => {
    return (
      <FilesContainer>
        {savedLinks?.map((link, index) => (
          <FileRecord key={index}>
            <LinkIcon
              className='link-wrapper'
              color={link.themeLinkType.linkType.backgroundCss || profileDesign.genericWebsiteColor}
            >
              <Image src={link.themeLinkType.linkType.iconUrl} alt={link.name} />
            </LinkIcon>
            <TitleWrapper>{link.name}</TitleWrapper>
            <AnchorWrapper
              href={getFullLink(link.link, link.themeLinkType.linkType)}
              target='_blank'
              rel='noreferrer'
            >
              {t('openLinkInNewTab')}
            </AnchorWrapper>
            <IconContainer onClick={() => editLink(link)}>
              <MdOutlineModeEditOutline size={23} color='#666666' />
            </IconContainer>
            <IconContainer onClick={() => deleteLink(link)}>
              <MdDelete size={23} color='#666666' />
            </IconContainer>
          </FileRecord>
        ))}
      </FilesContainer>
    );
  };

  const onSaveClick = (link: LinkFields, isEdit?: boolean, callBack?: () => void) => {
    const promise = withSilentAccessToken(
      getAccessTokenSilently,
      isEdit
        ? token =>
            axios.patch(
              // @ts-ignore:next-line
              config.API_BASE_URL + `theme/link-templates/${link.id}`,
              {
                linkTemplateEntity: {
                  name: link.name,
                  // @ts-ignore:next-line
                  link: link.link,
                  linkTypeId: link?.themeLinkType.id,
                  isStandard: false,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
        : token =>
            axios.post(
              config.API_BASE_URL + `theme/link-templates`,
              {
                linkTemplateEntity: {
                  name: link.name,
                  link: link.link,
                  linkTypeId: link.type,
                  isStandard: false,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ),
      [PERMISSIONS.WRITE.THEME_GLOBAL],
    );

    if (isEdit) {
      setIsLoading(true);
      promise
        .then(res => {
          toast.success(t('linkTab.successEdit'));
          setSavedLinks(res.data.data);
          setShowEditPopup(false);
          callBack && callBack();
        })
        .catch(() => toast.error(t('errorEdit')))
        .finally(() => setIsLoading(false));
    } else {
      setIsAddDisabled(true);
      toast.promise(promise, {
        loading: t('Loading'),
        success: res => {
          setSavedLinks(res.data.data);
          setShowEditPopup(false);
          callBack && callBack();
          setIsAddDisabled(false);

          return t('linkTab.successAdd');
        },
        error: () => {
          setIsAddDisabled(true);
          return t('linkTab.errorAdd');
        },
      });
    }
  };

  return (
    <>
      <Separator width='100%' />
      <StyledContainer>
        <Header>
          <HorizontalLine>
            {t('linkTab.header')}
            <Spacer size={9} />
            <TooltipInfo text={t('linkTab.headerTooltip')} placement='top' />
          </HorizontalLine>
        </Header>
        <Spacer size={9} />
        <AddLinkComponent
          onBackClick={() => {}}
          onSaveClick={onSaveClick}
          isAddDisabled={isAddDisabled}
        />
        <Spacer size={25} />
        <RenderLinks />
        {deleteAlertVisible && (
          <Popup
            headerText={t('linkTab.deleteLink')}
            onCloseClick={(): SetStateAction<boolean> => {
              setDeleteAlertVisible(false);
              return;
            }}
            btn={{ type: 'reset', noBg: true, text: t('cancel') }}
            secondBtn={{
              type: 'submit',
              text: t('linkTab.yesDelete'),
              onClick: handleDeleteLink,
            }}
            bodyText={t('linkTab.confirmDelete')}
          />
        )}
        {showEditPopup && (
          <EditLinkComponent
            onBackClick={() => setShowEditPopup(false)}
            onCloseClick={() => {
              setShowEditPopup(false);
              return null;
            }}
            link={selectedLink}
            setLink={setSelectedLink}
            onSaveClick={onSaveClick}
          />
        )}
      </StyledContainer>
      <Separator width='100%' />
    </>
  );
};
const StyledContainer = styled.div`
  padding: 3rem 0;
`;

const Header = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 97%;
  margin-bottom: 16px;
`;
const HorizontalLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilesContainer = styled.div`
  flex-wrap: wrap;
`;
const FileRecord = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) minmax(0, 1fr) max-content max-content;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 27px 25px 15px 0px;
  border-bottom-color: #b2b8c3;
  border-bottom-width: 1px;
  border-style: solid;
`;

const TitleWrapper = styled.div`
  word-break: break-word;
`;

const AnchorWrapper = styled.a`
  color: inherit;
  display: block;
  margin: auto;
  place-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const LinkIcon = styled.div`
  background: ${props => props.color};
  width: 100px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 25px;
  height: 25px;
`;
