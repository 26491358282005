import React, { createContext, useEffect } from 'react';

export type HeaderAction = {
  element: React.ReactNode;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
};

type HeaderActionsProps = {
  actions: HeaderAction[];
};

export const HeaderActionsContext = createContext<HeaderActionsProps>({
  actions: null,
});

export const HeaderActionsSetterContext = createContext<{
  setActions: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}>({
  setActions: () => {},
});

export const HeaderActionsProvider = ({ children }) => {
  const [actions, setActions] = React.useState<HeaderAction[]>(null);

  return (
    <HeaderActionsContext.Provider value={{ actions }}>
      <HeaderActionsSetterContext.Provider value={{ setActions }}>
        {children}
      </HeaderActionsSetterContext.Provider>
    </HeaderActionsContext.Provider>
  );
};

export const useConsumeHeaderActions = () => {
  const context = React.useContext(HeaderActionsContext);

  if (!context) {
    throw new Error('useHeaderActions must be used within a SettingsContext');
  }
  return context;
};

export const useHeaderActions = (actions?: HeaderAction[]) => {
  const context = React.useContext(HeaderActionsSetterContext);
  if (!context) {
    throw new Error('useSetHeaderActions must be used within a SettingsContext');
  }

  const { setActions } = context;

  useEffect(() => {
    if (actions) {
      setActions(actions);
      return () => {
        setActions(null);
      };
    }
  }, [setActions, actions]);
  return context;
};

export const createHeaderAction = (
  element: React.ReactNode,
  options: Omit<HeaderAction, 'element'> = {},
): [HeaderAction] => [
  {
    element,
    ...options,
  },
];

export const createHeaderActions = (
  ...actions: ([React.ReactNode, Omit<HeaderAction, 'element'>] | [React.ReactNode])[]
): HeaderAction[] => actions.map(conf => createHeaderAction(conf[0], conf[1] || {})[0]);
