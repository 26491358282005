import styled from 'styled-components';
import React from 'react';
import sectionBG from '../../../images/analytics/section-bg.jpg';
import { CSS_VARS } from '@/infrastructure/constants';

interface Props {
  label: string;
  total: number;
}

const SummaryCardWithBackground = (props: Props) => (
  <CardContainer>
    <BGImage src={sectionBG} />
    <Label>{props.label}</Label>
    <Total>{props.total}</Total>
  </CardContainer>
);

export default SummaryCardWithBackground;

const CardContainer = styled.div`
  border-radius: 10px;
  padding: 50px;
  width: 40%;
  position: relative;
  padding-bottom: 35px;
  margin-right: 15px;
  max-width: 400px;
  min-width: 250px;
  margin: 10px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px #0000001a;
`;
const BGImage = styled.img`
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Label = styled.div`
  color: ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
  font-weight: 600;
`;
const Total = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 50px;
`;
