export const ANALYTICS_EVENTS = {
  PUBLIC: {
    PROFILE_VIEW: 'profile-view',
    LINK_CLICK: 'link-click',
    FILE_CLICK: 'file-click',
    PROFILE_PIC_CLICK: 'profile-pic-click',
    CONTACT_SAVE_CLICK: 'contact-save-click',
    CONTACT_SUBMIT_CLICK: 'contact-submit-click',
    CONTACT_LT2LT_SAVED: 'contact-lt2lt-saved',
  },
  PRIVATE: {
    PROFILE_EDIT: 'profile-edit',
    PROFILE_EDIT_MD_SINGLE: 'profile-edit-md-single',
    PROFILE_EDIT_MD_BULK: 'profile-edit-md-bulk',
  },
} as const;

export const META_DATA_TYPES = {
  PRIVATE: {
    BIO: 'bio',
    LINK_ADD: 'link-add',
    FILE_ADD: 'file-add',
    LINK_DELETE: 'link-delete',
    FILE_DELETE: 'file-delete',
    BANNER: 'banner',
    PROFILE_PICTURE: 'profile-picture',
  },
} as const;

export const ANALYTICS_OPTIONS = [
  { title: 'pastWeek', value: 7, default: false },
  { title: 'pastMonth', value: 30, default: false },
  { title: 'past90Days', value: 90, default: false },
  { title: 'pastYear', value: 365, default: false },
  { title: 'allTime', value: 36500, default: true },
] as const;

export const PUBLIC_EVENTS = ANALYTICS_EVENTS.PUBLIC;
export const PRIVATE_EVENTS = ANALYTICS_EVENTS.PRIVATE;
export const PRIVATE_META_TYPES = META_DATA_TYPES.PRIVATE;
