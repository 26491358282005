import React, { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LinkBoxThemed from './link-box-themed';
import { ReactComponent as DragIndicatorIcon } from '../../images/dragIndicatorIcon.svg';
import Layout from '../../components/common/white-layout';
import { useAppSelector } from '../../../application/hooks';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { Account, Link } from '../../../shared/types/api';
import { DropResult } from 'react-beautiful-dnd';
import {
  DEFAULT_BOX_STYLE,
  THEME_LINKS_FILES_POSITIONS,
  THEME_LINK_EDIT_MODES,
} from '../../../shared/constants';
import { ReactComponent as AddIcon } from '../../images/add-icon.svg';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';

interface Props {
  onDragEndLinks: (result: DropResult) => void;
  onAddNewLink: () => void;
  setShowLinkTypePopup: (boolean) => void;
  showCreateEditLinkPopup: (linkWithType) => void;
  showDeletePopup: (linkWithType) => void;
  getItemStyle: (isDragging: boolean, draggableStyle: any) => React.CSSProperties;
}

const LinksLayout = React.memo((props: Props) => {
  const { t } = useAppTranslation();

  const account: Account = useAppSelector(state => state.account);
  const themeLinkTypes: Array<Link> = useAppSelector(state => state.editProfile.linkTypes);
  const links: Array<Link> = useAppSelector(state => {
    return state.account.links
      ? state.account.links.sort((a: Link, b: Link) => a.order - b.order)
      : [];
  });

  const { profileDesign } = useProfileDesign();
  const { editRights } = useEditRights();

  const themeLinks = useMemo(
    () =>
      account.theme?.links?.map((link, index) => {
        return (
          <LinkBoxThemed
            key={index}
            employeeId={null}
            link={link}
            onEditClick={item => {}}
            theme={account.theme}
            defaultLinkId={account.defaultLinkId}
            hideOptions
            isThemeEntity
          />
        );
      }),
    [account.defaultLinkId, account.theme],
  );

  if (editRights.linkEditMode === THEME_LINK_EDIT_MODES.DISALLOW) {
    return (
      <Layout headerText={t('myLinks')} themeBoxStyle={profileDesign.boxStyle || DEFAULT_BOX_STYLE}>
        <div>
          {profileDesign.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeLinks}
          {links.map((item: Link, index) => (
            <LinkBoxThemed
              key={index}
              employeeId={null}
              link={item}
              onEditClick={item =>
                props.showCreateEditLinkPopup({
                  ...item,
                  backgroundCss: item.linkType.backgroundCss,
                  iconUrl: item.linkType.iconUrl,
                  iconColorUrl: item.linkType.iconColorUrl,
                  mode: item.linkType.mode,
                  modeMeta: item.linkType.modeMeta,
                })
              }
              theme={account.theme}
              hideOptions
              defaultLinkId={account.defaultLinkId}
            />
          ))}
          {profileDesign.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeLinks}
        </div>
      </Layout>
    );
  } else {
    const sortedLinks: Array<Link> =
      editRights.linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS
        ? links.sort((a, b) => {
            // move order logic to server
            if (a.linkType?.id === b.linkType?.id) {
              if (a.isCustomLink && b.isCustomLink) return a.order - b.order;
              else if (a.isCustomLink && !b.isCustomLink) return 1;
              else if (!a.isCustomLink && b.isCustomLink) return -1;
              else return a.order - b.order;
            } else {
              let a_order = a.themeLinkOption?.themeLinkType?.order;
              let b_order = b.themeLinkOption?.themeLinkType?.order;
              if (!a_order) {
                a_order =
                  themeLinkTypes?.find(val => val.linkType?.id === a.linkType?.id)?.order || 0;
              }
              if (!b_order) {
                b_order =
                  themeLinkTypes?.find(val => val.linkType?.id === b.linkType?.id)?.order || 0;
              }
              return a_order - b_order;
            }
          })
        : links;
    return (
      <Layout
        headerText={t('myLinks')}
        themeBoxStyle={profileDesign.boxStyle}
        actionButton={{
          text: !account.theme
            ? undefined
            : editRights.linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS
            ? t('editLinks')
            : t('add'),
          icon: <AddIcon />,
          customBtnClasses: 'lt-usetiful--link-add',
          onClick: props.onAddNewLink,
        }}
      >
        {profileDesign.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeLinks}
        <DragDropContext onDragEnd={props.onDragEndLinks}>
          <Droppable droppableId='links'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedLinks.map((item, index) => (
                  <Draggable
                    key={item.id.toString()}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={props.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <div
                          className='darg-icon'
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          {...provided.dragHandleProps}
                          tabIndex={undefined}
                        >
                          <DragIndicatorIcon />
                        </div>
                        <LinkBoxThemed
                          key={index}
                          employeeId={null}
                          link={item}
                          onEditClick={item => {
                            if (editRights.linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS) {
                              props.setShowLinkTypePopup(
                                themeLinkTypes.find(t => t.id === item.linkType.id),
                              );
                            } else {
                              props.showCreateEditLinkPopup({
                                ...item,
                                backgroundCss: item.linkType.backgroundCss,
                                iconUrl: item.linkType.iconUrl,
                                iconColorUrl: item.linkType.iconColorUrl,
                                mode: item.linkType.mode,
                                modeMeta: item.linkType.modeMeta,
                              });
                            }
                          }}
                          onDeleteClick={item => {
                            props.showDeletePopup({
                              ...item,
                              backgroundCss: item.linkType.backgroundCss,
                              iconUrl: item.linkType.iconUrl,
                              iconColorUrl: item.linkType.iconColorUrl,
                              mode: item.linkType.mode,
                              modeMeta: item.linkType.modeMeta,
                            });
                          }}
                          theme={account.theme}
                          defaultLinkId={account.defaultLinkId}
                          blueBackground={typeof item.linkTemplateId === 'number'}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {profileDesign.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeLinks}
      </Layout>
    );
  }
});

export default LinksLayout;
