import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Account } from '../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';

export const updateAccount = async (
  account: Account,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const payload = { ...account };
  const response = await axios.post(config.API_BASE_URL + 'account/update', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
