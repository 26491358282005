// compatible with the auth0 User type
type User = {
  sub?: string;
};

// returns the idp type based solely on the user id
// --> The user id might not be the only identity type that account has!
export const getAccountIdentityProviderByIdSync = (
  arg: string | User | null | undefined,
): null | string => {
  let accountId = arg;
  if (typeof arg !== 'string' && arg && 'sub' in arg) accountId = arg.sub;
  if (typeof accountId !== 'string') return null;
  const provider = accountId.split('|')[0];
  if (!provider) return null;
  return provider;
};

export const canChangeEmailAddress = (user: User) =>
  getAccountIdentityProviderByIdSync(user) === 'auth0';

export const canChangePassword = (user: User) => canChangeEmailAddress(user);

// returns a friendly name based on idp
export const getIdpFriendlyName = (
  provider: string | null | undefined,
): null | 'Email & Password' | 'LinkedIn' | 'Google' | 'Microsoft' | 'Single Sign-On (SSO)' => {
  if (typeof provider !== 'string') return null;

  switch (provider) {
    case 'auth0':
      return 'Email & Password';
    case 'linkedin':
      return 'LinkedIn';
    case 'google-oauth2':
      return 'Google';
    case 'windowslive':
      return 'Microsoft';
    default:
      return 'Single Sign-On (SSO)';
  }
};
