import LtDialog, { LtDialogProps } from '@/components/LtDialog';
import { useCallback, useMemo, useState } from 'react';

type PartialDialogProps = Pick<LtDialogProps, 'title' | 'titleIcon' | 'children'>;
export default function useConfirm<T>(
  dialogProps: PartialDialogProps | ((...args: T[]) => PartialDialogProps),
  conditionFct?: (...args: T[]) => boolean,
) {
  const [inConfirmState, setInConfirmState] = useState<null | [Function, T[]]>(null);
  const [loading, setLoading] = useState(false);

  const withConfirm = useCallback(
    (fct: Function) =>
      (...args: T[]) => {
        if (conditionFct && !conditionFct(...args)) fct(...args);
        else setInConfirmState(() => [() => fct(...args), args]);
      },
    [conditionFct],
  );

  const onConfirm = useCallback(async () => {
    setLoading(true);
    try {
      await inConfirmState?.[0]?.();
    } catch {}
    setLoading(false);
    setInConfirmState(null);
  }, [inConfirmState]);

  const onCancel = useCallback(() => setInConfirmState(null), []);

  const ConfirmDialog = useMemo(
    () => (
      <LtDialog
        open={!!inConfirmState}
        loading={loading}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onClose={onCancel}
        {...(typeof dialogProps === 'function'
          ? dialogProps(...(inConfirmState?.[1] || []))
          : dialogProps)}
      />
    ),
    [dialogProps, inConfirmState, loading, onCancel, onConfirm],
  );

  return {
    inCofirmState: !!inConfirmState,
    confirmLoading: loading,
    withConfirm,
    onConfirm,
    onCancel,
    ConfirmDialog,
  };
}
