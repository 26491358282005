import { useTranslation } from 'react-i18next';
import useTierInfo from '../../../infrastructure/hooks/useTierInfo';
import { Loader, Popup } from '../common';
import styled from 'styled-components';
import { TextField } from '../generic';
import { useCallback, useState } from 'react';
import { Account } from '../../../shared/types/api';
import { useAppSelector } from '../../../application/hooks';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../config/config';
import { useHistory, useLocation } from 'react-router-dom';
import { routePaths } from '../../../infrastructure/constants';
import { POTENTIAL_USER_SIZES } from '../../../shared/constants';
import { toast } from 'react-hot-toast';

interface Props {
  onClosed(): void;
}

export default function StartTrialPopup(props: Props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { isStarter } = useTierInfo();
  const account = useAppSelector<Account>(state => state.account);
  const [showLoader, setShowLoader] = useState(false);

  const [company, setCompany] = useState(account.company);
  const [potentialUsers, setPotentialUsers] = useState<string>(
    account?.accountMeta?.potentialUsers?.[0]?.key,
  );

  const optionBgColor = '#D5F8EF';

  const optionBorderColor = '#e0e2da';

  const upgradeDisabled = !company || !company.trim().length || !potentialUsers;

  const doUpgrade = useCallback(() => {
    if (upgradeDisabled) return;

    setShowLoader(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + 'account/upgrade',
        {
          tier: 'pro',
          trial: true,
          pathname,
          params: {
            niceName: company?.trim(),
            potentialUsers: potentialUsers,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
      .then(() => {
        history.push(routePaths.HOME + '?lt-upgraded-now=true');
        window.location.reload();
      })
      .catch(e => {
        console.error(e);
        setShowLoader(false);
        toast.error(t('unexpectedError'));
      });
  }, [company, getAccessTokenSilently, history, pathname, potentialUsers, t, upgradeDisabled]);

  if (!isStarter) return null;

  return (
    <>
      {showLoader && <Loader />}
      <Popup
        headerText={t('upgradePopup.title')}
        onCloseClick={props.onClosed}
        btn={{
          text: t('upgradePopup.submit'),
          onClick: () => doUpgrade(),
          disabled: upgradeDisabled,
        }}
      >
        <QuestionContainer>
          <Question>{t('upgradePopup.q1')}</Question>
          <TextField
            label={t('company')}
            name='company'
            value={company}
            multiline={true}
            angles='normal'
            onChange={e => setCompany(e.target.value)}
            font={{ size: '12px', weight: '400' }}
            fullWidth
          />
        </QuestionContainer>
        <QuestionContainer>
          <Question>{t('upgradePopup.q2')}</Question>
          <ButtonContainer>
            {POTENTIAL_USER_SIZES.map((size, index) => {
              return (
                <BoxFormOption
                  style={{
                    backgroundColor: potentialUsers === size ? optionBgColor : '',
                    color: potentialUsers === size ? '' : 'white',
                    border: `1px solid ${optionBorderColor}`,
                  }}
                  onClick={() =>
                    size === potentialUsers ? setPotentialUsers('') : setPotentialUsers(size)
                  }
                  key={index}
                >
                  <SubsectionSpan>{t(size)}</SubsectionSpan>
                </BoxFormOption>
              );
            })}
          </ButtonContainer>
        </QuestionContainer>
      </Popup>
    </>
  );
}

const QuestionContainer = styled.div`
  margin-bottom: 2rem;
`;

const Question = styled.div``;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const BoxFormOption = styled.div`
  padding: 0.3rem 1rem;
  transition: ease-in all 0.15s;
  cursor: pointer;
  border-radius: 30px;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const SubsectionSpan = styled.span`
  color: #444444;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
`;
