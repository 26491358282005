import { useState } from 'react';
import { useAppDispatch } from '../../../application/hooks';
import Tooltip from '@mui/material/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';

import { updateFileDisplay } from '../../../application/actions/edit-profile';
import { updateFileDisplayMd } from '../../../application/actions/md/profiles/files';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { BoxWrapper, Name, ImagesBox, FolderIcon, StyledIconWrapper } from './sharedUIComponets';

import showEyeIcon from '../../images/eye-show.svg';
import hiddenEyeIcon from '../../images/eye-hidden.svg';
import trashIcon from '../../images/trash.svg';
import { ApiFile, Theme } from '../../../shared/types/api';
import editIcon from '../../images/edit.svg';
import extendIcon from '../../images/extend.svg';

import { NoEditIconWrapper } from './link-box-themed';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import LtVideo from '@/components/LtVideo';

interface Props {
  item: ApiFile;
  employeeId?: string;
  bulkEdit?: boolean;
  onDeleteClick: () => void;
  onExtendClick?: (file: ApiFile) => void;
  hideOptions?: boolean;
  theme?: Theme;
  blueBackground?: boolean;
  isThemeEntity?: boolean;
  userIsAdmin?: boolean;
}

const FileBox = (props: Props): JSX.Element => {
  const { t } = useAppTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { profileDesign } = useProfileDesign();

  const dispatch = useAppDispatch();
  const [tooltipText, setTooltipText] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const { item, theme, employeeId, bulkEdit, hideOptions } = props;

  const onToggleDisplayClick = () => {
    if (employeeId)
      dispatch(
        updateFileDisplayMd(employeeId, item.id, !item.canDisplayOnProfile, getAccessTokenSilently),
      );
    else dispatch(updateFileDisplay(item.id, !item.canDisplayOnProfile, getAccessTokenSilently));
  };

  const handleShowTooltip = (action: 'display' | 'extend') => {
    setTooltipText('');

    if (action === 'display' && item.canDisplayOnProfile) setTooltipText(t('fileHidden'));
    if (action === 'extend') setTooltipText(t('fileExtendMessage'));

    setShowTooltip(true);
  };

  const getShowHideImageUrl = (): string => {
    return item.canDisplayOnProfile ? showEyeIcon : hiddenEyeIcon;
  };

  const showExtendIcon = () =>
    bulkEdit && item['existsInDB'] && !(item['existsForAll'] || item['extendToAll']);

  return (
    <BoxWrapper blueBackground={props.blueBackground} boxStyle={profileDesign.boxStyle}>
      {item.embed ? (
        <LtVideo
          label={item.fileName}
          embedUrl={item.link}
          sx={{ width: '70%', '& *': { color: '#7a7a7a !important' } }}
        />
      ) : (
        <>
          <FolderIcon theme={theme} />
          <Name fullwidth>{item.fileName}</Name>
        </>
      )}
      {!hideOptions && (
        <Tooltip
          open={showTooltip}
          title={<div style={{ fontSize: '1.4rem' }}>{tooltipText}</div>}
          placement='bottom'
          enterDelay={300}
        >
          <ImagesBox>
            {!bulkEdit && (
              <StyledIconWrapper
                onClick={() => onToggleDisplayClick()}
                onMouseEnter={() => handleShowTooltip('display')}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <img src={getShowHideImageUrl()} alt='' />
              </StyledIconWrapper>
            )}
            <StyledIconWrapper onClick={() => props.onDeleteClick()}>
              <img src={trashIcon} alt='' />
            </StyledIconWrapper>
            {showExtendIcon() && (
              <StyledIconWrapper
                onClick={() => props.onExtendClick(item)}
                onMouseEnter={() => handleShowTooltip('extend')}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <img style={{ transform: 'scale(0.85)' }} src={extendIcon} alt='' />
              </StyledIconWrapper>
            )}
          </ImagesBox>
        </Tooltip>
      )}
      {props.isThemeEntity && (
        <Tooltip
          title={
            <div style={{ fontSize: '1.4rem' }}>
              {props.userIsAdmin ? t('explainThemeFileToAdmin') : t('explainThemeFile')}
            </div>
          }
          enterDelay={300}
        >
          <ImagesBox>
            <NoEditIconWrapper>
              <img src={editIcon} alt='' />
            </NoEditIconWrapper>
          </ImagesBox>
        </Tooltip>
      )}
    </BoxWrapper>
  );
};

export default FileBox;
