import { useState, useEffect, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import { getThemeLinkTypes } from '../../../../application/actions/md/profiles';
import Search from './search';
import Table from './table';
import AddEmployee from './add-employee';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { WhitespaceContainer } from '../common';
import { TableUiHandle } from './table/components/table';
import { ApiFile, Link, Theme } from '../../../../shared/types/api';
import useCsvbox, { licenseAlertType } from '../../../../infrastructure/hooks/useCsvbox';
import { toast } from 'react-hot-toast';
import { Popup, Button } from '../../../components/common';
import { Spacer } from '../customization/common';
import { addEmployee as AddEmployeeAction } from '../../../../application/actions/md/profiles';
import nl2br from 'react-nl2br';
import useQuery from '../../../../infrastructure/hooks/useQuery';
import withNav from '../../../../infrastructure/hoc/withNav';
import { ColumnDefinition } from './table/utils/constants';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { Box, Button as MUIButton, ThemeProvider } from '@mui/material';
import { AddOutlined, KeyboardArrowDown } from '@mui/icons-material';
import LtMultiActionButton from '@/components/LtMultiActionButton';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

const MdProfiles = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { theme: muiTheme } = useMuiTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [addEmployee, setAddEmployee] = useState(false);
  const { employees } = useAppSelector(state => state.md);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const [parentSearchCondition, setParentSearchCondition] = useState('');
  const [parentSelectedColumn, setParentSelectedColumn] = useState<string>();
  const [selectedLink, setSelectedLink] = useState<Link>();
  const [selectedFile, setSelectedFile] = useState<ApiFile>();
  const [showModalLicense, setShowModalLicense] = useState(false);
  const [LicenseModalContents, setLicenseModalContent] = useState<licenseAlertType>(
    'noOrderCardWithExceedLicense',
  );
  const [orderCard, setOrderCard] = useState(0);
  const [isOneUserUpload, setIsOneUserUpload] = useState(false);
  const [importedData, setImportedData] = useState();
  const [employeeFromSideBar, setEmployeeFromSideBar] = useState();
  useEffect(() => {
    dispatch(getThemeLinkTypes(getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('firstName');
  const [orderBy, setOrderBy] = useState(null);
  const [sort, setSort] = useState(null);

  const searchRef = useRef<{ refreshEmployees: () => void }>();
  const refreshEmployees = () => searchRef.current?.refreshEmployees();

  const { setIsUnsaved } = useUnsavedStatusSetter();

  const handleOrderBy = (orderByColumn: ColumnDefinition['key']) => {
    if (orderByColumn === orderBy) {
      setSort(sort === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(orderByColumn);
      setSort('asc');
    }
  };

  const handleAdd = () => {
    setAddEmployee(!addEmployee);
  };
  useEffect(() => {
    if (selectedLink) {
      setParentSelectedColumn('links');
      setParentSearchCondition(`name:${selectedLink.name},url:${selectedLink.link}`);
    } else {
      setParentSearchCondition('');
    }
  }, [selectedLink]);

  useEffect(() => {
    if (selectedFile) {
      setParentSelectedColumn('files');
      setParentSearchCondition(`name:${selectedFile.fileName}`);
    } else {
      setParentSearchCondition('');
    }
  }, [selectedFile]);

  const query = useQuery();
  useEffect(() => {
    const col = query.get('col');
    const val = query.get('val');
    if (col && val) {
      setParentSelectedColumn(col);
      setParentSearchCondition(val);
    }
  }, [query]);

  const clearItemSelection = () => {
    setSelectedLink(null);
    setSelectedFile(null);
  };

  const tableRef = useRef<TableUiHandle>(null);
  const onSearchStart = useCallback(() => {
    tableRef?.current?.setPage(0);
  }, []);

  const {
    openImportModal,
    openUpdateModal,
    openDeleteModal,
    insertIntoDatabase,
    importError,
    setImportError,
  } = useCsvbox({
    importerLicence:
      theme?.themeInternal?.csvImporterLicence || process.env.REACT_APP_CSVBOX_LICENCE_IMPORT,
    updaterLicence:
      theme?.themeInternal?.csvUpdaterLicence || process.env.REACT_APP_CSVBOX_LICENCE_UPDATE,
    deleterLicence:
      theme?.themeInternal?.csvDeleterLicence || process.env.REACT_APP_CSVBOX_LICENCE_DELETE,
  });
  const startImport = useCallback(() => {
    setIsOneUserUpload(false);
    openImportModal(
      theme?.themeInternal.amountLicences || 0,
      employees.length,
      (data, type, orderCard) => {
        setLicenseModalContent(type);
        setOrderCard(orderCard);
        setShowModalLicense(true);
        setImportedData(data);
      },
    );
  }, [employees.length, openImportModal, theme?.themeInternal.amountLicences]);

  const onSuccess = () => {
    toast.success(t('createdSuccessMD'));
    setIsUnsaved(false);
    setAddEmployee(false);
  };

  const onFailed = msg => toast.error(msg);
  const getLicenseText = () => {
    if (LicenseModalContents === 'noOrderCardWithExceedLicense') {
      return {
        line0: t('csvImport.toast.exceedtotalLicensing0'),
        line1: nl2br(t('csvImport.toast.exceedtotalLicensing1')),
        line2:
          t('csvImport.toast.exceedtotalLicensing2') +
          ' ' +
          (theme?.themeInternal.amountLicences || 0),
      };
    }
    if (LicenseModalContents === 'orderCardWithExceedLicense') {
      return {
        line0: t('csvImport.toast.exceedtotalLicensing0'),
        line1: nl2br(
          t('csvImport.toast.exceedtotalLicensing3').replace('@amount', orderCard.toString()),
        ),
        line2:
          t('csvImport.toast.exceedtotalLicensing2') +
          ' ' +
          (theme?.themeInternal.amountLicences || 0),
      };
    }
    if (LicenseModalContents === 'orderCardWithNoneExceedLicense') {
      return {
        line0: t('csvImport.toast.otherCards'),
        line1: t('csvImport.toast.selectedCards').replace('@amount', orderCard.toString()),
        line2: t('csvImport.toast.cardsMessage'),
      };
    }
  };
  return (
    <>
      {importError && (
        <Popup
          headerText={t('csvImport.errors.header')}
          bodyText={importError}
          onCloseClick={() => setImportError(undefined)}
          isErrorPopup
        />
      )}
      {addEmployee && (
        <AddEmployee
          onBackClick={() => setAddEmployee(false)}
          setAddEmployee={setEmployeeFromSideBar}
          showLicensesWarning={() => setShowModalLicense(true)}
        />
      )}

      <WhitespaceContainer scroll>
        <ThemeProvider theme={muiTheme}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={4}>
            <Search
              ref={searchRef}
              onSearchStart={onSearchStart}
              parentSearchCondition={parentSearchCondition}
              setParentSearchCondition={setParentSearchCondition}
              parentSelectedColumn={parentSelectedColumn}
              onTextChange={clearItemSelection}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              orderBy={orderBy}
              sort={sort}
            />
            <Box display='flex' alignItems='center' ml={1}>
              <LtMultiActionButton
                label={t('csvImport.label')}
                options={[
                  { text: t('csvImport.add'), onClick: startImport },
                  { text: t('csvImport.update'), onClick: openUpdateModal },
                  { text: t('csvImport.delete'), onClick: openDeleteModal },
                ]}
                size='medium'
                variant='outlined'
                color='primary'
                sx={{ mr: 1 }}
                endIcon={<KeyboardArrowDown />}
                MenuProps={{ style: { fontSize: '1.4rem' } }}
              />
              <MUIButton
                startIcon={<AddOutlined />}
                size='medium'
                onClick={() => {
                  setIsOneUserUpload(true);
                  handleAdd();
                }}
              >
                {t('addProfile')}
              </MUIButton>
            </Box>
          </Box>
        </ThemeProvider>

        <Table
          tableRef={tableRef}
          employees={employees}
          SelectedObjects={{
            selectedLinksProps: { selectedLink, setSelectedLink },
            selectedFilesProps: { selectedFile, setSelectedFile },
          }}
          refreshEmployees={refreshEmployees}
          orderBy={orderBy}
          sort={sort}
          onColumnClick={handleOrderBy}
        />
      </WhitespaceContainer>
      {showModalLicense && (
        <Popup
          onCloseClick={() => setShowModalLicense(false)}
          noClose
          headerText={getLicenseText().line0}
        >
          <div>{getLicenseText().line1}</div>

          <Spacer size={32} />
          <div style={{ fontSize: '0.8em' }}>{getLicenseText().line2}</div>
          <RowDiv>
            <Button
              text={t('cancel')}
              noBg
              onClick={() => {
                setShowModalLicense(false);
                toast.dismiss('csvboxRequestLoading');
              }}
            />
            <StyledButton
              text={t('csvImport.toast.continue')}
              onClick={() => {
                setShowModalLicense(false);
                if (isOneUserUpload) {
                  dispatch(
                    AddEmployeeAction(
                      employeeFromSideBar,
                      getAccessTokenSilently,
                      () => onSuccess(),
                      (msg: string) => onFailed(t(msg)),
                    ),
                  );
                } else {
                  insertIntoDatabase(importedData);
                }
              }}
            />
          </RowDiv>
        </Popup>
      )}
    </>
  );
};

export default withNav(
  MdProfiles,
  {
    tTitle: 'profileOverview',
  },
  {},
);

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  min-width: 150px;
`;
