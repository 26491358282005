import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../../../config/config';
import { routePaths } from '../../../infrastructure/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@/views/components/common';

export default function Home() {
  const history = useHistory();
  const { isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(routePaths.LOGIN);
    }
    if (!isLoading && !isAuthenticated) {
      // only redirect to shop from our main domain
      if (window.location.origin === config.REACT_APP_URL) {
        const locationLink: string = config.SHOP_URL;
        window.location.href = locationLink;
      } else {
        history.push(routePaths.LOGIN);
      }
    }
  }, [history, isAuthenticated, isLoading]);

  if (isLoading) return <Loader />;
  else return null;
}
