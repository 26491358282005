import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhitespaceContainer, PageHeader, PageSubheader } from '@/views/pages/md/common';
import { HeaderTextContainer, Spacer } from '@/views/pages/md/customization/common';
import withNav from '@/infrastructure/hoc/withNav';

import { useAuth0 } from '@auth0/auth0-react';
import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { toast } from 'react-hot-toast';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import MainVideosContainer from '@/views/components/Guide/MainVideosContainer';
import FurtherExplainersContainer from '@/views/components/Guide/FurtherExplainersContainer';
import QuestionsContainer from '@/views/components/Guide/QuestionsContainer';
import { DivisionTitle } from '@/views/components/Guide/styles';
import { useQuestions } from './useQuestions';
import { THEME_CONFIG_KEYS } from '@/shared/constants';

const MdGuide = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { desktopView } = useDesktopView();

  const [loading, setLoading] = useState(false);
  const [guides, setGuides] = useState({ main: [], furtherExplainers: [] });

  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const langPrefix = activeLanguage.startsWith('de') ? 'de' : 'en';

  const mainVideoGuides = guides?.main?.[langPrefix] || [];
  const furtherExplainersGuides = guides?.furtherExplainers?.[langPrefix] || [];
  const frequentlyAskedQuestions = useQuestions();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { value: result } = await fetchThemeConfig(
          getAccessTokenSilently,
          THEME_CONFIG_KEYS.MD_GUIDES,
        );

        if (result) {
          setGuides(result);
        } else {
          toast.error(t('error.general'));
        }
      } catch {
        toast.error(t('error.general'));
      }
      setLoading(false);
    };
    fetchData();
  }, [getAccessTokenSilently, t]);

  return (
    <>
      <WhitespaceContainer>
        <Spacer size={20} />
        <HeaderTextContainer>
          {!desktopView && <PageHeader>{t('mdguides.headerPage')}</PageHeader>}
          <PageSubheader>{t('mdguides.subHeader')}</PageSubheader>
        </HeaderTextContainer>

        <MainVideosContainer loading={loading} videos={mainVideoGuides} />
        <FurtherExplainersContainer furtherExplainersGuides={furtherExplainersGuides} />

        <DivisionTitle>{t('mdguides.openQuestions.header')}</DivisionTitle>
        <QuestionsContainer questions={frequentlyAskedQuestions} />
      </WhitespaceContainer>
    </>
  );
};

export default withNav(
  MdGuide,
  {
    tTitle: 'mdguides.headerPage',
  },
  {},
);
