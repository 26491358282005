import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Spacer } from './common';
import { AddressFields } from './customDataAddresses';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { getAddressFromEntity } from '@/shared/util';
interface Props {
  address: AddressFields;
  onDeleteClick: (adress: AddressFields) => void;
  onEditClick: (address: AddressFields) => void;
}

const AddressRecord = (props: Props) => {
  const { t } = useTranslation();
  const addressDetails = getAddressFromEntity(props.address, ', ');
  const isDefault = !!props.address.isDefault;
  return (
    <Container>
      {isDefault && <DefaultText>{t('addressesTab.default')}</DefaultText>}
      <Spacer size={9} />
      <NameText>{props.address.label}</NameText>
      <AddressDetails>{addressDetails}</AddressDetails>
      <ActionButtons>
        <IconContainer onClick={() => props.onEditClick(props.address)}>
          <MdOutlineModeEditOutline size={28} color='#5C5C5C' />
        </IconContainer>
        <Spacer size={5} />
        <IconContainer onClick={() => props.onDeleteClick(props.address)}>
          <MdOutlineDeleteOutline size={28} color='#5C5C5C' />
        </IconContainer>
      </ActionButtons>
    </Container>
  );
};

export default AddressRecord;

const Container = styled.div`
  border-radius: 15px;
  background-color: #eff3fd;
  padding: 25px;
  margin-top: 25px;
  max-width: 500px;
  position: relative;
`;

const DefaultText = styled.div`
  color: #5e5fee;
`;

const NameText = styled.div`
  font-size: 2rem;
  font-weight: 600;
`;

const AddressDetails = styled.div`
  color: #666666;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;
