import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { locales } from './constants';

import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { EmailTemplatesEnum } from '@/shared/constants';
import { LtDialog } from '@/components';

const DuplicationPopup = ({ open, onClose, onConfirm, allowedEmailTemplates }) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    template: EmailTemplatesEnum | '';
    locale: string;
  }>({
    template: '',
    locale: '',
  });

  const handleConfirm = () => {
    onConfirm(selectedOptions);
  };

  const handleClose = () => {
    setSelectedOptions({
      template: '',
      locale: '',
    });
    onClose();
  };

  const { t } = useAppTranslation();

  return (
    <LtDialog
      onClose={handleClose}
      open={open}
      title={t('emailTemplates.duplicateTitle')}
      onCancel={handleClose}
      onConfirm={handleConfirm}
      disabled={!selectedOptions.template || !selectedOptions.locale}
    >
      <FormControl fullWidth margin='normal'>
        <InputLabel>{t('emailTemplates.selectTemplate')}</InputLabel>
        <Select
          value={selectedOptions.template}
          label={t('emailTemplates.selectTemplate')}
          onChange={({ target: { value } }) =>
            setSelectedOptions(prev => ({
              ...prev,
              template: value as EmailTemplatesEnum,
            }))
          }
        >
          {allowedEmailTemplates.map(key => (
            <MenuItem key={key} value={key}>
              {t(`emailTemplates.${key}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin='normal'>
        <InputLabel>{t('emailTemplates.selectLanguage')}</InputLabel>
        <Select
          value={selectedOptions.locale}
          label={t('emailTemplates.selectLanguage')}
          onChange={({ target: { value } }) =>
            setSelectedOptions(prev => ({ ...prev, locale: value }))
          }
        >
          {locales.map(locale => (
            <MenuItem key={locale.value} value={locale.value}>
              {t(`emailTemplates.${locale.tName}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </LtDialog>
  );
};

export default DuplicationPopup;
