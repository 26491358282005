import { CardRenderer } from '@/components/CardRenderer';
import { Box, Typography } from '@mui/material';
import nfcCardImage from '../../images/nfc-card.png';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useAppSelector } from '@/application/hooks';
import { ThemeInternal } from '@/shared/types/api';

const NFCCard = () => {
  const { t } = useAppTranslation();
  const { hideNfcCardUI } = useAppSelector<ThemeInternal>(
    state => state.account?.theme?.themeInternal,
  );
  const linkTo = routePaths.NFC_CARDS;

  if (hideNfcCardUI) return null;

  return (
    <CardRenderer sx={{ flexBasis: '100%' }}>
      <Link to={linkTo}>
        <Box display={'flex'} width={'100%'} height={'100%'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box width={'60%'} display={'flex'} justifyContent={'center'}>
              <img src={nfcCardImage} alt='nfc-card' style={{ width: '100%', height: '100%' }} />
            </Box>
            <Box>
              <Typography variant='h3' mb={1}>
                {t('shareProfile.nfcCards.title')}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {t('shareProfile.nfcCards.description')}
              </Typography>
            </Box>
            <ArrowForwardOutlined sx={{ color: 'text.primary' }} />
          </Box>
        </Box>
      </Link>
    </CardRenderer>
  );
};

export default NFCCard;
