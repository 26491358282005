import { HelpSidebarConfig } from '@/shared/types/api';
import { VideoDisplay } from '@/views/components/Guide/VideoDisplay';
import {
  Box,
  Button,
  Drawer,
  Grid,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HelpSidebarKey } from '@/shared/constants';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import CloseIcon from '@mui/icons-material/Close';
import AnnaProfile from '@/views/images/anna.png';
import { ReactComponent as LemontapsLogo } from '@/views/images/lemontaps-logo-text.svg';
import MarkdownRenderer from './MarkdownRenderer';

/********************
 *  Main Component  *
 ********************/

export const HelpButton = ({ configKey }: { configKey: HelpSidebarKey }) => {
  const [toggled, setToggled] = useState(false);
  const { t } = useTranslation();

  const [videoOpenIdx, setVideoOpenIdx] = useState<null | number>(null);

  const toggleDrawer = (open?: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (open === undefined) setToggled(prev => !prev);
    else setToggled(open);
  };

  const { config: helpSidebarConfig, loading: helpSidebarConfigLoading } =
    usePrivateThemeConfig<HelpSidebarConfig>(configKey);

  if (!helpSidebarConfigLoading && (!helpSidebarConfig || !helpSidebarConfig.content)) return null;

  return (
    <>
      <Button variant='text' startIcon={<HelpOutlineIcon />} onClick={toggleDrawer()}>
        {t('help')}
      </Button>

      <Drawer
        anchor='right'
        variant='temporary'
        open={toggled}
        onClose={toggleDrawer(false)}
        disableEscapeKeyDown={videoOpenIdx !== null}
        PaperProps={{ sx: { bgcolor: '#F6F8FE' } }}
      >
        <SidebarContent
          config={helpSidebarConfig}
          onClose={toggleDrawer(false)}
          videoOpenIdx={videoOpenIdx}
          setVideoOpenIdx={setVideoOpenIdx}
        />
      </Drawer>
    </>
  );
};

/********************
 *  Sidebar Content *
 ********************/

const SidebarContent = ({
  config,
  onClose,
  videoOpenIdx,
  setVideoOpenIdx,
}: {
  config: HelpSidebarConfig | null;
  onClose: () => void;
  videoOpenIdx: number | null;
  setVideoOpenIdx: (idx: number | null) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={headerStyles}>
        <Button variant='text' size='small' startIcon={<CloseIcon />} onClick={onClose}>
          {t('close')}
        </Button>
      </Box>
      <Box sx={boxSyles}>
        <MarkdownRenderer content={config?.content || ''} />
        <Box mt={6}>
          {config?.videos?.map((x, idx) => (
            <SingleVideo
              title={x.title}
              url={x.url}
              key={x.url.concat(x.title || '')}
              open={idx === videoOpenIdx}
              onToggle={() => (idx === videoOpenIdx ? setVideoOpenIdx(null) : setVideoOpenIdx(idx))}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

const boxSyles: SxProps = (theme: Theme) => ({
  maxWidth: '45rem',
  p: 3,
});

const headerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  p: 3,
  pb: 2,
  position: 'sticky',
  top: 0,
  bgcolor: 'inherit',
};

/********************
 *  Video Container *
 ********************/

export const SingleVideo = ({
  title,
  url,
  open,
  onToggle,
}: {
  title: string;
  url: string;
  open: boolean;
  onToggle: () => void;
}) => {
  const isXlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  return (
    <Box sx={videoContainerStyles}>
      <Grid container columnSpacing={0.5} sx={overlayStyles} onClick={onToggle}>
        <Box className='lt-play-icon' sx={playIconWrapper}>
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {PlayIcon}
          </Box>
        </Box>

        <Grid item xs={7} display='flex' flexDirection='column' justifyContent='flex-end'>
          <Box display='flex' flexDirection='column' pl={1} pb={2}>
            <LemontapsLogo style={{ maxWidth: '8rem' }} />
            <Typography variant={isXlUp ? 'h3' : 'h4'}>{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={5} display='flex' alignItems='end' justifyContent='center'>
          <img src={AnnaProfile} alt='Anna from Lemontaps' style={{ maxWidth: '100%' }} />
        </Grid>
      </Grid>
      {open && <VideoDisplay onCloseClick={onToggle} details={{ title: title, path: url }} />}
    </Box>
  );
};

const PlayIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='19' height='21' viewBox='0 0 19 21' fill='none'>
    <path d='M18.8711 10.5054L0.508804 20.0972L0.508804 0.913546L18.8711 10.5054Z' fill='white' />
  </svg>
);

const overlayStyles: SxProps = {
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
  '> .lt-play-icon': {
    transition: '.1s ease-in-out',
  },
  '&:hover > .lt-play-icon': {
    opacity: '0.8',
  },
};

const videoContainerStyles: SxProps = (theme: Theme) => ({
  position: 'relative',
  width: '100%',
  paddingBottom: '56.25%',
  mb: '2rem',
  borderRadius: '3px',
  border: `1px solid ${theme.palette.secondary.dark}`,
  overflow: 'hidden',
  bgcolor: 'common.white',
});

const playIconWrapper: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  paddingBottom: '10%',
  bgcolor: 'primaryButton.main',
  borderRadius: '2px',
  svg: {
    width: '50%',
    height: '50%',
  },
};
