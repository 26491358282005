import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuth0TokenScopeString } from '@/util';
import { OnboardingDataProps1, OnboardingDataProps2 } from '@/shared/types/api';

export const onboardingOperations = async (
  onboardingData: OnboardingDataProps1 | OnboardingDataProps2,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    config.API_BASE_URL + 'onboarding',
    { onboardingData },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};
