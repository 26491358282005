import { CrmConnectorType, LeadAPI, LeadSortOption } from '@/shared/types/api';
import {
  Avatar,
  CardHeader,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  ArrowDownward,
  ArrowUpward,
  DeleteOutline,
  DownloadOutlined,
  EditOutlined,
  MoreVert,
  NoteAddOutlined,
} from '@mui/icons-material';
import { CustomMenuList } from './CustomMenuList';
import { useState } from 'react';
import config from '@/config/config';
import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { openLink } from '@/util';
import { LtDialog } from '@/components';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';
import moment from 'moment';
import { getAvatar, getLeadDisplayName, lineClampCount } from '../utils/helpers';
import { CustomTableCell } from './CustomTableCell';
import { LineClamp } from '@/views/components/common/LineClamp';
import { isDateField, withKeyEventHandler } from '@/utils/helpers';
import { getFullName } from '@/shared/business-logic/features/profile/getter';
import { isPhoneFaxField } from '@/views/pages/edit-bio/utils';
import { getPhone } from '@/views/pages/md/profiles/table/utils/helpers';
import { ExportEventRenderer } from './ExportEventRenderer';

const EnhancedTableRow = ({
  lead,
  onSelect,
  isSelected,
  onDelete,
  onLeadEditClick,
  columns,
  crmConnectors,
}: {
  lead: LeadAPI;
  onSelect: () => void;
  isSelected: boolean;
  onDelete?: (leadId: number) => Promise<void>;
  onLeadEditClick: (leadId: number, focusNotes?: boolean) => void;
  columns: ColumnDefinition[];
  crmConnectors?: CrmConnectorType[];
}) => {
  const { id: accountId } = useAppSelector(state => state.account);
  const { activeLanguage, t } = useAppTranslation();

  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDownload = () => {
    openLink(
      `${config.API_BASE_URL}lead-gen/accounts/${lead.account?.id || accountId}/leads/${
        lead.id
      }/vcard?lang=${activeLanguage}`,
    );
  };

  const handleDelete = async () => {
    setDeleting(true);
    await onDelete(lead.id);
    setDeletePopupOpened(false);
    setDeleting(false);
  };

  const rowActions = [
    { icon: <EditOutlined />, label: t('edit'), onClick: () => onLeadEditClick(lead.id) },
    {
      icon: <NoteAddOutlined />,
      label: t('leads.addEditNote'),
      onClick: () => onLeadEditClick(lead.id, true),
    },
    { icon: <DeleteOutline />, label: t('delete'), onClick: () => setDeletePopupOpened(true) },
    { icon: <DownloadOutlined />, label: t('download'), onClick: handleDownload },
  ];

  const avatar = getAvatar(lead);
  const name = getLeadDisplayName(lead);

  return (
    <>
      <TableRow
        hover
        role='checkbox'
        aria-checked={isSelected}
        tabIndex={-1}
        key={lead.id}
        selected={isSelected}
      >
        <TableCell padding='checkbox'>
          <Checkbox color='primary' onClick={() => onSelect()} checked={isSelected} />
        </TableCell>

        <CustomTableCell noPadding dim='l'>
          <CardHeader
            sx={{ p: 0, px: 1, py: 0.5, '.MuiCardHeader-action': { alignSelf: 'auto' } }}
            avatar={
              <IconButton sx={{ p: 0 }} onClick={() => onLeadEditClick(lead.id)}>
                <Avatar
                  sx={theme => ({
                    cursor: 'pointer',
                    width: theme.spacing(3),
                    height: theme.spacing(3),
                  })}
                  alt={name}
                  src={avatar}
                />
              </IconButton>
            }
            title={
              <Box
                tabIndex={0}
                sx={{
                  ':hover': { textDecoration: 'underline' },
                  ':focus-visible': { textDecoration: 'underline' },
                  cursor: 'pointer',
                }}
                onClick={() => onLeadEditClick(lead.id)}
              >
                <LineClamp lineCount={lineClampCount} text={name} styles={{ fontWeight: 500 }} />
              </Box>
            }
            action={
              <CustomMenuList
                items={rowActions}
                toggleElement={
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                }
              />
            }
          />
        </CustomTableCell>
        {columns.map(({ key }) => {
          if (key === 'metBy') {
            const metByAcc = lead?.account;
            const fullName = metByAcc ? getFullName(metByAcc) : t('mdLeads.accountDeleted');
            return (
              <CustomTableCell noPadding dim='l'>
                <CardHeader
                  sx={{ p: 0, px: 1, py: 0.5, '.MuiCardHeader-action': { alignSelf: 'auto' } }}
                  avatar={
                    <Avatar
                      sx={theme => ({
                        cursor: 'pointer',
                        width: theme.spacing(3),
                        height: theme.spacing(3),
                      })}
                      src={metByAcc?.profileImageUrl}
                      alt={fullName}
                    />
                  }
                  title={
                    <LineClamp
                      lineCount={lineClampCount}
                      text={fullName}
                      styles={{ fontWeight: 500 }}
                    />
                  }
                />
              </CustomTableCell>
            );
          }

          const isDate = isDateField(key);
          const isPhone = isPhoneFaxField(key);
          const text = isDate
            ? moment(lead[key]).format('DD-MM-YYYY')
            : isPhone
            ? getPhone(lead.lt2lt?.[key] || lead[key])
            : lead.lt2lt?.[key] || lead[key];

          return (
            <CustomTableCell key={key} tooltipText={text}>
              {text}
            </CustomTableCell>
          );
        })}
        {Boolean(crmConnectors?.length) && lead.crmExportEvents && (
          <CustomTableCell>
            <Box display='flex' gap={1} alignItems='center' justifyContent='center'>
              {lead.crmExportEvents?.map(event => (
                <ExportEventRenderer crmConnectors={crmConnectors} event={event} />
              ))}
            </Box>
          </CustomTableCell>
        )}
      </TableRow>
      <LtDialog
        title={t('requestDelete')}
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={handleDelete}
        loading={deleting}
      >
        {t('deleteLeadMsg')}
      </LtDialog>
    </>
  );
};

type TableHeadCellProps = {
  column: ColumnDefinition;
  sortOptions: LeadSortOption;
  onSortOptionsChange: (sortOptions: LeadSortOption) => void;
};

const TableHeadCell = ({ column, sortOptions, onSortOptionsChange }: TableHeadCellProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const { sort, orderBy: sortColumn } = sortOptions || {};

  const isSortedByAsc = sortColumn === column.key && sort === 'ASC';
  const isSortedByDesc = sortColumn === column.key && sort === 'DESC';
  const isCreatedOnColumn = column.key === 'createdOn';
  const isDate = isDateField(column.key);

  const actions = [
    (isCreatedOnColumn || !isSortedByDesc) && {
      icon: <ArrowUpward />,
      label: t(isDate ? 'leads.dateDesc' : 'leads.textDesc'),
      onClick: () => onSortOptionsChange({ orderBy: column.key as keyof LeadAPI, sort: 'DESC' }),
    },
    (isCreatedOnColumn || !isSortedByAsc) && {
      icon: <ArrowDownward />,
      label: t(isDate ? 'leads.dateAsc' : 'leads.textAsc'),
      onClick: () => onSortOptionsChange({ orderBy: column.key as keyof LeadAPI, sort: 'ASC' }),
    },
    !isCreatedOnColumn &&
      (isSortedByAsc || isSortedByDesc) && {
        label: t('leads.reset'),
        onClick: () => onSortOptionsChange({ orderBy: 'createdOn', sort: 'DESC' }),
        color: 'secondary',
      },
  ].filter(Boolean);
  return (
    <>
      <StyledTableCellHeader
        tabIndex={0}
        hover
        key={column.key}
        onClick={e => setAnchorEl(e.currentTarget)}
        onKeyUp={withKeyEventHandler(e => setAnchorEl(e.currentTarget))}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <LineClamp lineCount={lineClampCount} text={t(column.tkey)} />
          {isSortedByAsc && <ArrowDownward />}
          {isSortedByDesc && <ArrowUpward />}
          {!isSortedByAsc && !isSortedByDesc && <SwapVertIcon color='secondary' />}
        </Box>
      </StyledTableCellHeader>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((item, index) => (
          <MenuItem
            sx={{ width: '20rem' }}
            key={index}
            onClick={() => {
              item.onClick();
              setAnchorEl(null);
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>
              <Typography color={item.color}>{item.label}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

type Props = {
  leads: LeadAPI[];
  onCheckboxClick: (lead: LeadAPI) => void;
  onHeaderCheckboxClick: () => void;
  selectedItems: { id: number }[];
  isAllSelected: boolean;
  isAnySelectedOnCurrentPage: boolean;
  onDelete?: (leadId: number) => Promise<void>;
  onLeadEditClick: (leadId: number) => void;
  columns: ColumnDefinition[];
  sortOptions: LeadSortOption;
  onSortOptionsChange: (sortOptions: LeadSortOption) => void;
  crmConnectors?: CrmConnectorType[];
};

export const Table = ({
  leads,
  onCheckboxClick,
  onHeaderCheckboxClick,
  isAllSelected,
  isAnySelectedOnCurrentPage,
  selectedItems,
  onDelete,
  onLeadEditClick,
  columns,
  sortOptions,
  onSortOptionsChange,
  crmConnectors,
}: Props) => {
  const shouldRenderExportColumn = Boolean(crmConnectors?.length);
  return (
    <TableContainer
      sx={(theme: Theme) => ({
        flex: '1',
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      <MuiTable stickyHeader sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow sx={{ bgcolor: 'white' }}>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                indeterminate={isAnySelectedOnCurrentPage}
                checked={isAllSelected}
                onChange={onHeaderCheckboxClick}
              />
            </TableCell>
            <TableHeadCell
              column={{ key: 'lastName', tkey: 'fullName' }}
              sortOptions={sortOptions}
              onSortOptionsChange={onSortOptionsChange}
            />
            {columns.map(column => (
              <TableHeadCell
                column={column}
                sortOptions={sortOptions}
                onSortOptionsChange={onSortOptionsChange}
              />
            ))}
            {shouldRenderExportColumn && (
              <StyledTableCellHeader tooltipText='Exports'>Exports</StyledTableCellHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((lead, index) => (
            <EnhancedTableRow
              key={lead.id}
              lead={lead}
              onSelect={() => onCheckboxClick(lead)}
              isSelected={selectedItems.some(({ id }) => id === lead.id)}
              onDelete={onDelete}
              onLeadEditClick={onLeadEditClick}
              columns={columns}
              crmConnectors={crmConnectors}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

const StyledTableCellHeader = styled(CustomTableCell)(({ theme }: { theme: Theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:focus-visible': {
    backgroundColor: theme.palette.action.hover,
  },
}));
