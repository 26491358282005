import styled from 'styled-components';
import { useAppSelector } from '../../../application/hooks';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { CSSProperties } from 'react';

const Admin = ({ style }: { style?: CSSProperties }) => {
  const account = useAppSelector(state => state.account);
  const { profileImageUrl } = account;

  const { profileDesign } = useProfileDesign();

  return (
    <AdminContainer>
      <AdminPicCropper style={style}>
        <img src={profileImageUrl || profileDesign.defaultProfileImageUrl} alt='admin' />
      </AdminPicCropper>
    </AdminContainer>
  );
};

export default Admin;

const AdminContainer = styled.div`
  display: inherit;
`;

const AdminPicCropper = styled.div`
  position: relative;
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;

  img {
    display: block;
    position: absolute;
    height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: ${p => p?.style?.cursor || 'poniter'};
  }
`;
