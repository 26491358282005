import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubsectionHeader } from '../../common';
import styled from 'styled-components';
import AutoComplete, { AutoCompleteValue } from '../../../../components/common/autoComplete';
import { Spacer } from '../../customization/common';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { invertHex, withSilentAccessToken } from '../../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../../config/config';
import { CSS_VARS, PERMISSIONS, routePaths } from '../../../../../infrastructure/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { AddressTemplate } from '../../../../../shared/types/api/address-template';
import { Account } from '../../../../../shared/types/api';
import { useAppSelector } from '../../../../../application/hooks';
import toast from 'react-hot-toast';
import useTierInfo from '../../../../../infrastructure/hooks/useTierInfo';
import { FEATURE } from '../../../../../shared/constants';
import TeaserBlur from '../../teaser/teaserBlur';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { StyledLink } from './styles';
import { GoArrowUpRight } from 'react-icons/go';
import { getAddressFromEntity } from '@/shared/util';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  setTemplateAddresses: Dispatch<SetStateAction<AddressTemplate[]>>;
  templateAddresses: AddressTemplate[];
  individualAddressesCount: number;
  setShowIndividualAddress: Dispatch<SetStateAction<boolean>>;
  employeeAddresses: Array<number | undefined>;
  isAddingEmployee: boolean;
  isBulkEdit: boolean;
}

const LocationSelector = (props: Props) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [initialTemplates, setInitialTemplates] = useState<AddressTemplate[]>([]);
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);
  const isDisabled = props.individualAddressesCount + props.templateAddresses.length > 1;
  const isAutocompleteDisabled = isDisabled || autoCompleteValues?.length === 0;
  const noTemplates = isAutocompleteDisabled && initialTemplates.length === 0;
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const account: Account = useAppSelector(state => state.account);
  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `theme/address-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      )
        .then(res => {
          setInitialTemplates(res.data.data);
          if (props.isAddingEmployee) {
            if (
              account.theme?.themeInternal?.defaultAddressTemplateId &&
              res.data?.data?.length > 0
            ) {
              const defaults = res.data?.data?.filter(
                address => address.id === account.theme?.themeInternal?.defaultAddressTemplateId,
              );
              defaults.length > 0 && props.setTemplateAddresses([defaults[0]]);
            }
          }
        })
        .catch(err => {
          toast.error(t('addressesTab.errorReadAddress'));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, t, isFeatureAllowed]);

  useEffect(() => {
    const array: AddressTemplate[] = [];
    initialTemplates.forEach(address => {
      if (!props.templateAddresses.find(_address => _address.id === address.id)) {
        array.push(address);
      }
    });

    const duplicates = array.filter((addressOut, idxOut) =>
      array.some((addressIn, idxIn) => idxOut !== idxIn && addressOut?.label === addressIn?.label),
    );

    setAutoCompleteValues(
      array.map((address, index) => ({
        id: address.id,
        description: duplicates.some(dup => dup?.label === address?.label)
          ? `${address?.label} (${getAddressFromEntity(address, ', ')})`
          : address?.label,
        index,
        otherData: { ...address },
      })),
    );
  }, [initialTemplates, props.templateAddresses]);

  useEffect(() => {
    const _savedAddresses = [];
    props.employeeAddresses.forEach(address => {
      if (address != null) {
        _savedAddresses.push(address);
      }
    });
    props.setTemplateAddresses(_savedAddresses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAutoValue = (selectedValue: AutoCompleteValue) => {
    if (isDisabled) return;

    if (selectedValue) {
      if (props.templateAddresses.find(address => address.id === selectedValue.id)) return;
      props.setTemplateAddresses([...props.templateAddresses, selectedValue.otherData]);
      setIsUnsaved(true);
    }
  };

  const deleteAddress = (item: AddressTemplate) => {
    props.setTemplateAddresses(props.templateAddresses.filter(address => address.id !== item.id));
    setIsUnsaved(true);
  };

  const AddressItem = (innerProps: { address: AddressTemplate }) => (
    <RowDiv>
      <AddressContainer>
        <NameText>{innerProps.address?.label}</NameText>
        <AddressDetails>
          {[
            innerProps.address?.addressee,
            innerProps.address?.addressLine1,
            innerProps.address?.addressLine2,
            innerProps.address?.postCode,
            innerProps.address?.city,
            innerProps.address?.country,
          ]
            .reduce((prev, cur) => (cur ? [...prev, cur] : [...prev]), [])
            .join(', ')}
        </AddressDetails>
      </AddressContainer>
      <Spacer size={15} />
      <IconContainer
        isDisabled={
          props.isAddingEmployee &&
          innerProps.address.id === account?.theme?.themeInternal?.defaultAddressTemplateId
        }
      >
        <MdOutlineDeleteOutline
          size={28}
          color='#5C5C5C'
          onClick={() =>
            !(
              props.isAddingEmployee &&
              innerProps.address.id === account?.theme?.themeInternal?.defaultAddressTemplateId
            ) && deleteAddress(innerProps.address)
          }
        />
      </IconContainer>
    </RowDiv>
  );

  return (
    <>
      <hr />
      <TeaserBlur condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} badge='Enterprise'>
        <SubsectionHeader>{t('locationSelector.header')}</SubsectionHeader>
        <SubHeader>{t('locationSelector.max2Label')}</SubHeader>
        <Spacer size={10} />
        <AutoCompleteContainer>
          <AutoComplete
            options={autoCompleteValues}
            onSave={saveAutoValue}
            placeHolder={t(
              noTemplates ? 'locationSelector.noTemplates' : 'locationSelector.searchAddress',
            )}
            disabled={isAutocompleteDisabled}
          />
        </AutoCompleteContainer>
        {noTemplates && isThemeAdmin && (
          <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.DATA_TEMPLATES}>
            {t('locationSelector.createTemplate')}
            <GoArrowUpRight size={17} />
          </StyledLink>
        )}
      </TeaserBlur>
      {
        <SwitchIndividualAddressContainer>
          <SwitchAddressTitle>{t('locationSelector.orCreateIndividual')}</SwitchAddressTitle>
          <ButtonWrapper
            onClick={() => !isDisabled && props.setShowIndividualAddress(true)}
            disabled={isDisabled}
          >
            <Button disabled={isDisabled} color={muiTheme.palette.primaryButton.main}>
              <AiOutlinePlusCircle size={17} color='#FFFFFF' />
              {t('addressesTab.addAddresses')}
            </Button>
          </ButtonWrapper>
        </SwitchIndividualAddressContainer>
      }

      {props.templateAddresses.map((address, index) => (
        <AddressItem address={address} key={index} />
      ))}
    </>
  );
};

export default LocationSelector;

const AutoCompleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubHeader = styled.div`
  color: #666666;
  font-size: small;
  font-weight: normal;
  margin-top: -15px;
  padding-left: 3px;
`;

// const IconContainer = styled.div`
//   margin-right: 40px;
//   width: 40px;
//   text-align: center;
// `;

const SwitchIndividualAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${props =>
    props.disabled &&
    `& svg {
      color: #535353;
      font-size: 2rem;
      margin-right: 0.7em;
      cursor: pointer;
    }`}
`;

const Button = styled.div`
  display: flex;
  background-color: ${props => (props.disabled ? '#dbdbdb' : props.color)};
  color: ${props => (props.disabled ? '#535353' : invertHex(props.color, true))};
  height: 50px;
  box-sizing: border-box;
  border: ${props => (props.disabled ? 'solid 1px #DFE0E7' : 'none')};
  border-radius: 10px;
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  & svg {
    color: ${props => (props.disabled ? CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR : '#fff')};
    font-size: 2rem;
    margin-right: 0.2em;
    cursor: auto;
  }
`;

const SwitchAddressTitle = styled.div`
  color: #666666;
  font-size: small;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const AddressContainer = styled.div`
  border-radius: 15px;
  background-color: #eff3fd;
  padding: 25px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const NameText = styled.div`
  font-weight: 600;
`;

const AddressDetails = styled.div`
  color: #666666;
  font-size: 1.3rem;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div`
  cursor: ${props => (props.isDisabled ? 'normal' : 'pointer')};
  ${props => props.isDisabled && 'opacity: 0.2'};
`;
