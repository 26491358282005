import { useState } from 'react';

const usePagination = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const onPageChange = (_, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  return { page, rowsPerPage, onPageChange, onRowsPerPageChange };
};

export default usePagination;
