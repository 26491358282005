import { Dispatch, SetStateAction } from 'react';
import { isFileValid } from '../../../../../../infrastructure/helper';

import styled, { css } from 'styled-components';
import { SubsectionHeader } from '../../common/styles';
import { THEME_BOX_STYLES } from '../../../../../../shared/constants';

import { IoCloseSharp } from 'react-icons/io5';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import TooltipInfo from '../../../../../components/generic/TooltipInfo';
import { CSS_VARS } from '@/infrastructure/constants';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  logoHeaderUrl?: string;
  notEditable?: boolean;
  setLogoHeaderUrl?: Dispatch<SetStateAction<string>>;
  setLogoHeaderFile?: Dispatch<SetStateAction<File>>;
}

const LogoHeader = (props: Props) => {
  let fileInput;

  const { t } = useTranslation();
  const { profileDesign } = useProfileDesign();

  const { logoHeaderUrl, setLogoHeaderUrl, setLogoHeaderFile } = props || undefined;

  const onImageSelection = async () => {
    const uploadedFile = fileInput.files[0];
    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        toast.error(errMsg);
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => setLogoHeaderUrl(reader.result as string);
      setLogoHeaderFile(uploadedFile);
    }
  };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <>
      <SubsectionHeader>
        {t('editHeaderLogo')}{' '}
        <TooltipInfo text={t('editLogoTooltip')} placement='right' icon={{ size: 15 }} arrow />
      </SubsectionHeader>

      <LogoContainer
        clickable={!props.notEditable}
        showBorder
        borderColor={muiTheme.palette.primary.main}
        boxStyle={profileDesign.boxStyle}
        onClick={props.notEditable ? undefined : () => fileInput.click()}
      >
        {logoHeaderUrl ? (
          <>
            <LogoImage src={logoHeaderUrl} alt='Logo' />
            {!props.notEditable && (
              <CloseIcon
                size={24}
                color={CSS_VARS.LT_DEFAULT_COLOR_VAR}
                onClick={e => {
                  e.stopPropagation();
                  setLogoHeaderUrl(null);
                  setLogoHeaderFile(null);
                }}
              />
            )}
          </>
        ) : props.notEditable ? null : (
          <PlusLogoContainer>
            <AiOutlinePlusCircle size={17} color='#919191' />
            <div>Logo</div>
          </PlusLogoContainer>
        )}
      </LogoContainer>

      <input
        type='file'
        ref={input => {
          fileInput = input;
        }}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default LogoHeader;

const LogoImage = styled.img`
  height: 80px;
  border-radius: 10px;
  margin-top: 10px;
  max-width: 100%;
  object-fit: contain;
`;

const LogoContainer = styled.div`
  height: 100px;
  position: relative;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${props =>
    props.showBorder
      ? css`
          border-radius: ${props.boxStyle === THEME_BOX_STYLES.ANGULAR ? '0' : '3px'};
          border: 2px dashed ${p => p.borderColor};
        `
      : ''}
  padding: 3px 5px;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};

  @media (max-width: 425px) {
    top: 7.5px;
    right: 7.5px;
    height: 40px;
    padding: 1.5px 2.5px;
  }
`;

const PlusLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  font-weight: 500;
`;

const CloseIcon = styled(IoCloseSharp)`
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  z-index: 10;
`;
