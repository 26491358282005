import { useLayoutEffect, useState } from 'react';
import { DESKTOP_BREAK_POINT, SIDEBAR_COLLAPSE_BREAKPOINT } from '../../shared/constants';

export default function useDesktopView(onSidebarCollapse?: (collapsed: boolean) => void) {
  const [desktopView, setDesktopView] = useState<boolean>(window.innerWidth > DESKTOP_BREAK_POINT);
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < SIDEBAR_COLLAPSE_BREAKPOINT,
  );

  // update window-width
  useLayoutEffect(() => {
    function resizeListener() {
      if (desktopView && window.innerWidth <= DESKTOP_BREAK_POINT) {
        setDesktopView(false);
      } else if (!desktopView && window.innerWidth > DESKTOP_BREAK_POINT) {
        setDesktopView(true);
      }
      if (sidebarCollapsed && window.innerWidth > SIDEBAR_COLLAPSE_BREAKPOINT) {
        setSidebarCollapsed(false);
        onSidebarCollapse?.(false);
      } else if (!sidebarCollapsed && window.innerWidth <= SIDEBAR_COLLAPSE_BREAKPOINT) {
        setSidebarCollapsed(true);
        onSidebarCollapse?.(true);
      }
    }
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [desktopView, onSidebarCollapse, sidebarCollapsed]);

  return { desktopView, sidebarCollapsed };
}
