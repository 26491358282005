import { Box } from '@mui/material';
import { BsCheck2 } from 'react-icons/bs';
import { CSS_VARS, VIDEOS } from '../../../../../infrastructure/constants';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { Spacer } from '../../customization/common';
import {
  CenterFlexRow,
  GreenButton,
  GreenText,
  GreenTextContainer,
  Heading,
  Section,
  StyledVideo,
  StyledVideoContainer,
  StyledVideoDescription,
  Subtext,
} from '../styled';
import { TRIAL_LENGTH_DAYS } from '@/shared/constants';

const VideoSection = ({
  onShowUpgradePopup,
  isLeadgen = false,
}: {
  onShowUpgradePopup: Function;
  isLeadgen: boolean;
}) => {
  const { t, activeLanguage } = useAppTranslation();
  const { src, title } = activeLanguage.startsWith('de') ? VIDEOS.MD.DE : VIDEOS.MD.EN;
  return (
    <Section>
      <StyledVideoContainer>
        <Box>
          <StyledVideo src={src} title={title} allowFullScreen></StyledVideo>
        </Box>
        <StyledVideoDescription>
          <Heading $big>{t('teaser.teamsEssential')}</Heading>
          <Subtext style={{ margin: '2rem 0 1rem' }}>
            {t(isLeadgen ? 'teaser.leadgenEfficientSolution' : 'teaser.efficientSolution', {
              days: TRIAL_LENGTH_DAYS,
            })}
          </Subtext>

          <GreenTextContainer style={{ margin: '2rem 0 1rem' }}>
            <CenterFlexRow>
              <BsCheck2 size={25} color={CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} />
              <Spacer size={10} />
              <GreenText> {t('teaser.noCredit')} </GreenText>
            </CenterFlexRow>

            <CenterFlexRow>
              <BsCheck2 size={25} color={CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} />
              <Spacer size={10} />
              <GreenText>{t('teaser.noInstallation')}</GreenText>
            </CenterFlexRow>
          </GreenTextContainer>

          <GreenButton style={{ margin: '2rem 0 1rem' }} onClick={onShowUpgradePopup}>
            {t('teaser.testItXDaysForFree', { days: TRIAL_LENGTH_DAYS })}
          </GreenButton>
        </StyledVideoDescription>
      </StyledVideoContainer>
    </Section>
  );
};

export default VideoSection;
