import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import SettingsHeader from '../../settings/header';
import Banner from './common/images/banner';
import LogoHeader from './common/images/logo-header';
import InputsForm from './common/inputs-form';
import { InputsFormWrapper, SettingsContainer } from '../common';
import {
  bulkUpdateCombined,
  getCommonFiles,
  getCommonLinks,
} from '../../../../application/actions/md/profiles/bulk-update';
import { clearApiResponse, getEmployees } from '../../../../application/actions/md/profiles';
import { Link, ApiFile, BulkFile } from '../../../../shared/types/api';
import { Loader } from '../../../components/common';
import toast from 'react-hot-toast';
import { Overlay } from '../../../components/common/overlay';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { initialValues as defaultProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
interface Props {
  employeeIds: readonly string[];
  usernames: readonly string[];
  onBackClick: () => void;
  unitIds: (number | null)[];
  differentUnits?: boolean;
}

const BulkEdit = (props: Props) => {
  const { employeeIds, usernames, onBackClick, unitIds } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const apiResponse = useAppSelector(state => state.md.apiResponse);
  const commonLinks = useAppSelector(state => state.md.commonLinks);
  const commonFiles = useAppSelector(state => state.md.commonFiles);
  const isLoading = useAppSelector(state => state.md.isLoading);

  const numSelected = employeeIds?.length;

  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(null);

  const { dictionary: profileDesignDict } = useProfileDesignForUnits(null);

  const defaultBannerImageUrl = useMemo(() => {
    const allUrls = unitIds
      .map(
        unitId => profileDesignDict[unitId ? `unitId-${unitId}` : 'theme']?.defaultBannerImageUrl,
      )
      .filter(Boolean);
    if (new Set(allUrls).size !== 1)
      return (
        profileDesignDict['theme']?.defaultBannerImageUrl ||
        defaultProfileDesign.defaultBannerImageUrl
      );
    else return allUrls[0];
  }, [profileDesignDict, unitIds]);
  const bannerImgOptions = useMemo(() => {
    const allOptions = unitIds
      .map(unitId => profileDesignDict[unitId ? `unitId-${unitId}` : 'theme']?.bannerImgOptions)
      .filter(Boolean);
    if (allOptions.some(o => o === null || o.length === 0)) return [];
    else return Array.from(new Set(allOptions.reduce((prev, cur) => [...prev, ...cur], [])));
  }, [profileDesignDict, unitIds]);
  const bulkBannerProps = {
    defaultBannerImageUrl,
    bannerImgOptions,
    bannerImageUrl,
    setBannerImageUrl,
    setBannerImageFile,
  };

  const [logoHeaderFile, setLogoHeaderFile] = useState(null);
  const [logoHeaderUrl, setLogoHeaderUrl] = useState(null);

  const globalLogoHeaderConfig = useMemo(() => {
    const allUrls = unitIds.map(
      unitId => profileDesignDict[unitId ? `unitId-${unitId}` : 'theme']?.logoHeaderUrl,
    );
    if (new Set(allUrls).size === 1 && !!allUrls[0]) return { url: allUrls[0], notEditable: true };
    else return { url: null, notEditable: allUrls.every(u => !!u) };
  }, [profileDesignDict, unitIds]);
  const logoHeaderProps = {
    logoHeaderUrl: globalLogoHeaderConfig.url || logoHeaderUrl,
    notEditable: globalLogoHeaderConfig.notEditable,
    setLogoHeaderUrl,
    setLogoHeaderFile,
  };

  const bulkEdit = true;
  const [bulkLinks, setBulkLinks] = useState<Array<Link>>([...commonLinks]);
  const [bulkDeletedLinks, setBulkDeletedLinks] = useState<Array<Link>>([]);
  const bulkLinkProps = {
    numSelected,
    bulkEdit,
    bulkLinks,
    setBulkLinks,
    bulkDeletedLinks,
    setBulkDeletedLinks,
  };

  const [bulkFiles, setBulkFiles] = useState<Array<BulkFile>>([...commonFiles]);
  const [bulkDeletedFiles, setBulkDeletedFiles] = useState<Array<BulkFile>>([]);
  const [bulkTemplateFiles, setBulkTemplateFiles] = useState<Array<ApiFile>>([]);
  const bulkFileProps = {
    numSelected,
    bulkEdit,
    bulkFiles,
    setBulkFiles,
    bulkDeletedFiles,
    setBulkDeletedFiles,
    bulkTemplateFiles,
    setBulkTemplateFiles,
  };

  useEffect(() => {
    setBulkLinks([...commonLinks]);
  }, [commonLinks]);

  useEffect(() => {
    setBulkFiles([...commonFiles]);
  }, [commonFiles]);

  useEffect(() => {
    dispatch(getCommonLinks(employeeIds, getAccessTokenSilently));
    dispatch(getCommonFiles(employeeIds, usernames, getAccessTokenSilently));
  }, [employeeIds, usernames, dispatch, getAccessTokenSilently]);

  const handleSave = inputs => {
    let ids = employeeIds;
    let toUpdateBanner = { bannerImageFile, bannerImageUrl };
    let toUpdateLogo = { logoHeaderFile };
    let toUpdateInfo = inputs;
    let toUpdateLinks = bulkLinks;
    let toDeleteLinks = bulkDeletedLinks;
    let toUpdateFiles = bulkFiles;
    let toDeleteFiles = bulkDeletedFiles;
    let toUpdateTemplateFiles = bulkTemplateFiles;

    dispatch(
      bulkUpdateCombined(
        ids,
        usernames,
        toUpdateBanner,
        toUpdateLogo,
        toUpdateInfo,
        toUpdateLinks,
        toDeleteLinks,
        toUpdateFiles,
        toDeleteFiles,
        toUpdateTemplateFiles,
        getAccessTokenSilently,
        true,
      ),
    );
  };

  const checkForResponse = () => {
    if (apiResponse && apiResponse.isSuccess) {
      dispatch(
        getEmployees(getAccessTokenSilently, '', '', t, {
          withToast: true,
          onSuccess: () => toast.success(t('bulkUpdated')),
          onFail: () => toast.success(t('bulkUpdated')),
        }),
      );
      onBackClick();
      dispatch(clearApiResponse());
    }

    if (apiResponse && !apiResponse.isSuccess) {
      dispatch(clearApiResponse());
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {checkForResponse()}
      <Overlay />
      <SettingsContainer>
        <SettingsHeader headerText={t('editProfiles')} onBackClick={() => onBackClick()} />
        <InputsFormWrapper>
          <ProfilesSelected>{t('xProfilesSelected', { count: numSelected })}</ProfilesSelected>
          <Warning>{t('contactInfoBulkHint')}</Warning>

          <hr />
          <Banner {...bulkBannerProps} />
          <hr />
          <LogoHeader {...logoHeaderProps} />
          <hr />
          <InputsForm
            onBackClick={onBackClick}
            handleSave={handleSave}
            bulkLinkProps={bulkLinkProps}
            bulkFileProps={bulkFileProps}
            bulkEdit
          />
        </InputsFormWrapper>
      </SettingsContainer>
    </>
  );
};

export default BulkEdit;

const ProfilesSelected = styled.h3`
  font-size: larger;
  font-weight: 600;
  line-height: 3rem;
  padding-top: 2.5rem;
  padding-left: 0.5rem;
  color: #444444;
`;

const Warning = styled.div`
  margin-top: 2rem;
  font-size: 1.3rem;
  text-align: center;
  color: #ffc107;
  font-weight: bold;
`;
