import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetAuthCallbackState } from '../../../application/actions/landing';
import { useAppSelector } from '../../../application/hooks';
import { routePaths } from '../../../infrastructure/constants';
import AddCodePopup from '../popups/add-code-popup';
import SuccessCodePopup from '../popups/success-code-popup';

interface AddCodeProps {
  suppress?: boolean;
}

export const AddCode = (props: AddCodeProps): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();

  const account = useAppSelector(state => state.account);

  // Add code after login or while logged in
  const addCode = useAppSelector(state => state.landing.authCallbackState?.ltLoginCode);

  // Added code through signup
  const addedCodeAfterSignup = useAppSelector(state =>
    state.landing.authCallbackState?.signup
      ? state.landing.authCallbackState.ltSignupCode
      : undefined,
  );
  const [showAddedCodePopup, setShowAddedCodePopup] = useState(false);
  const [showAddCodePopup, setShowAddCodePopup] = useState(addCode ? true : false);

  useEffect(() => {
    if (!addCode) setShowAddCodePopup(false);
  }, [addCode]);
  useEffect(() => {
    if (addCode && !showAddCodePopup) setShowAddCodePopup(true);
  }, [addCode, showAddCodePopup]);
  useEffect(() => {
    if (account && Object.keys(account).length > 0 && addedCodeAfterSignup && !showAddedCodePopup)
      setShowAddedCodePopup(true);
  }, [account, addedCodeAfterSignup, showAddedCodePopup]);

  const addCodePopupOnClose = () => {
    setShowAddCodePopup(false);
    dispatch(resetAuthCallbackState());
  };

  if (
    location.pathname === routePaths.LANDING.LOGIN_REGISTER ||
    location.pathname.includes('md/linkcode')
  )
    return null;

  return (
    <>
      {showAddedCodePopup && (
        <SuccessCodePopup
          onCloseClick={() => setShowAddedCodePopup(false)}
          code={addedCodeAfterSignup}
        />
      )}
      {!props.suppress && showAddCodePopup && (
        <AddCodePopup onCloseClick={addCodePopupOnClose} code={addCode} />
      )}
    </>
  );
};
