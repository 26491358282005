import { Account, LinkType, ProfileDesignConfig } from '../../../types/api';
import { base64UrlEncode, removeDiacritics } from '../../../util';

export function getProfileImageUrl(account: Account, profileDesign: ProfileDesignConfig) {
  return (
    account.profileImageUrl ||
    profileDesign.defaultProfileImageUrl ||
    'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/profile.png'
  );
}

export function getBannerImageUrl(account: Account, profileDesign: ProfileDesignConfig) {
  return (
    account.bannerImageUrl ||
    profileDesign.defaultBannerImageUrl ||
    'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/banner.jpg'
  );
}

// TODO: Consolidate hierarchy logic and align to profile and banner image
export function getLogoHeaderUrl(account: Account, profileDesign: ProfileDesignConfig) {
  return profileDesign.logoHeaderUrl || account.logoHeaderUrl || null;
}

// compatible with leads
export function getFullName(
  entity:
    | Partial<
        Pick<
          Account,
          'namePrefix' | 'academicDegree' | 'academicDegree2' | 'firstName' | 'lastName'
        >
      >
    | undefined,
  options: {
    replaceDiacritics?: boolean;
    removeNonHttpHeaderCompliantChars?: boolean;
  } = {},
) {
  if (!entity) return '';

  const parts = [
    entity.academicDegree,
    entity.academicDegree2,
    entity.namePrefix,
    entity.firstName,
    entity.lastName,
  ]
    .filter(Boolean)
    .map(s => (s ? s.trim() : s));

  let str = parts.join(' ').replace(/\n/g, ' ').trim();

  if (options.replaceDiacritics) str = removeDiacritics(str);
  if (options.removeNonHttpHeaderCompliantChars) str = str.replace(/([^a-z0-9 ]+)/gi, '');

  return str;
}

export function getFullLinkUrl(baseLink: string, linkType: LinkType | undefined) {
  let url = baseLink;
  switch (linkType?.mode) {
    case 'username':
    case 'phone':
      url = linkType.modeMeta + baseLink;
      break;
    default:
      if (baseLink?.startsWith('https')) url = baseLink;
      else url = 'https://' + baseLink;
      break;
  }
  return url;
}

export function generateProfileLink(
  account: Account,
  options: {
    lt?: { lt_medium?: string };
    unit?: number | { id: number }; // id
  } = {},
): string {
  const domain = account.theme?.domain?.trim() || 'https://lemontaps.com';
  const profileLink = new URL(`${domain}/${account.username}`);

  if (options.lt) {
    Object.entries(options.lt).forEach(([key, value]) =>
      profileLink.searchParams.append(key, value),
    );
  }
  if (options.unit) {
    const unitId = typeof options.unit === 'number' ? options.unit : options.unit.id;
    profileLink.searchParams.append('uid', base64UrlEncode(unitId.toString()));
  }

  return profileLink.toString();
}
