import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CSS_VARS } from '@/infrastructure/constants';
export interface StickyBannerProps {
  tLabel: string;
  bannerLink?: string;
  bannerOnClick?: Function;
  useAnchorTag?: boolean;
  icon?: JSX.Element;
  bannerHide?: boolean;
  // use fixed position instead of sticky (fixes a bug on the show-profile page)
  cssFixed?: boolean;
}

const StickyBanner = (props: StickyBannerProps): JSX.Element => {
  const { useAnchorTag, bannerHide, bannerLink, bannerOnClick, ...domProps } = props;
  const { tLabel, icon } = domProps;
  const { t } = useTranslation();
  const Outer = useCallback(
    ({ children }) =>
      bannerLink ? (
        useAnchorTag ? (
          <a href={bannerLink} {...domProps}>
            {children}
          </a>
        ) : (
          <Link to={bannerLink} {...domProps}>
            {children}
          </Link>
        )
      ) : bannerOnClick ? (
        <div {...domProps} onClick={() => bannerOnClick()}>
          {children}
        </div>
      ) : (
        <div {...domProps}>{children}</div>
      ),
    [domProps, bannerOnClick, bannerLink, useAnchorTag],
  );

  if (bannerHide) return null;

  return (
    <>
      <Outer>
        <Wrapper fixed={props.cssFixed}>
          {icon}
          <span>{t(tLabel)}</span>
        </Wrapper>
      </Outer>
    </>
  );
};

export default StickyBanner;

const Wrapper = styled.div`
  position: ${props => (props.fixed ? 'fixed' : 'sticky')};
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0;
  ${(p: { disabled?: boolean }) =>
    p.disabled
      ? `background: linear-gradient(90deg, #ccc 21.2%, #ddd 112.27%);
      color: 'white';`
      : `background: linear-gradient(90deg, ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} 21.2%, ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} 112.27%);
      color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};`}
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem 0;
  height: 5rem;
  margin: auto 0;
  font-size: 1.6rem;

  & > svg {
    font-size: 2rem;
  }

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;
