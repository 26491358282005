import { Box, CircularProgress } from '@mui/material';

const MuiLoader = () => {
  return (
    <Box width='100%' display='flex'>
      <CircularProgress size='6rem' sx={{ m: 'auto' }} />
    </Box>
  );
};

export default MuiLoader;
