import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfirmationDialog } from './confirmationDialog';

const UnsavedStatusContext = React.createContext<{
  isUnsaved: boolean;
  openDialogIfUnsaved: (onConfirm: () => void) => void;
}>({
  isUnsaved: false,
  openDialogIfUnsaved: () => {},
});

const UnsavedStatusSetterContext = React.createContext<{
  setIsUnsaved: React.Dispatch<React.SetStateAction<boolean>>;
  withUnsavedSetter: Function;
}>({
  setIsUnsaved: () => {},
  withUnsavedSetter: () => {},
});

export const UnsavedStatusContextProvider: React.FC = ({ children }) => {
  const [isUnsaved, setIsUnsaved] = React.useState(false);
  const { pathname, search } = useLocation();
  const { openDialog } = useConfirmationDialog();

  const withUnsavedSetter =
    callback =>
    (...args) => {
      setIsUnsaved(true);
      return callback(...args);
    };

  const openDialogIfUnsaved = useCallback(
    (onConfirm: () => void) => {
      if (isUnsaved) {
        openDialog(() => {
          onConfirm();
          setIsUnsaved(false);
        });
      } else {
        onConfirm();
      }
    },
    [openDialog, isUnsaved],
  );

  useEffect(() => {
    setIsUnsaved(false);
  }, [pathname, search]);

  return (
    <UnsavedStatusContext.Provider
      value={{
        isUnsaved,
        openDialogIfUnsaved,
      }}
    >
      <UnsavedStatusSetterContext.Provider value={{ setIsUnsaved, withUnsavedSetter }}>
        {children}
      </UnsavedStatusSetterContext.Provider>
    </UnsavedStatusContext.Provider>
  );
};

export const useUnsavedStatus = () => {
  const context = React.useContext(UnsavedStatusContext);
  if (context === undefined) {
    throw new Error('useUnsavedStatus must be used within a UnsavedStatusContext.Provider');
  }
  return context;
};

export const useUnsavedStatusSetter = () => {
  const context = React.useContext(UnsavedStatusSetterContext);
  if (context === undefined) {
    throw new Error(
      'useUnsavedStatusSetter must be used within a UnsavedStatusSetterContext.Provider',
    );
  }
  return context;
};
