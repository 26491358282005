import { CSS_VARS } from '@/infrastructure/constants';
import styled from 'styled-components';

interface Props {
  text: string;
  onClick: () => void;
}

const SecondaryButton = (props: Props) => {
  const { text, onClick } = props;
  return (
    <StyledDiv>
      <StyledAnchor href='#!' onClick={onClick}>
        {text}
      </StyledAnchor>
    </StyledDiv>
  );
};

export default SecondaryButton;

// Just needed for the styles
const StyledDiv = styled.div``;

const StyledAnchor = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
`;
