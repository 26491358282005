import styled from 'styled-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Popup, ErrorMessage } from '../../components/common';
import { TextField } from '../../components/generic';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  deleteLinksThemed,
  saveLinksThemed,
  saveCustomLinkThemed,
  deleteCustomLinkThemed,
} from '../../../application/actions/edit-profile';
import { removePrefix } from '../../../util';
import { Link } from '../../../shared/types/api';
import { LINK_TYPE_MODES } from '../../../shared/constants';
import { LinkTypeProp } from '../../pages/edit-profile';
import { CustomLinkType } from '../../pages/md/profiles/common/links/hooks/useBulk';

interface Props {
  linkType: LinkTypeProp;
  formerIds: Array<number>;
  formerCustomLink: Link;
  employeeId?: string;
  bulkEdit?: boolean;
  handleBulkCustomLinks?: (IProps: {
    formerCustomLink?: Link;
    linkTypeId?: number;
    customLink?: string;
  }) => void;
  handleBulkOptionLinks?: any;
  onCloseClick: () => void;
  onBackClick: () => void;
  userLinks?: Array<Link>;
  setUserLinks?: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
}

export default function EditLinkTypePopup(props: Props): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { linkType, formerIds, formerCustomLink, employeeId, bulkEdit } = props;
  const isLoading = useAppSelector(state =>
    employeeId ? state.md.isLoading : state.editProfile.isLoading,
  );

  const [errorMsg, setErrorMsg] = useState('');
  const [customLinkSelected, setCustomLinkSelected] = useState(formerCustomLink ? true : false);
  const [customLink, setCustomLink] = useState(
    formerCustomLink?.link
      ? linkType.mode === LINK_TYPE_MODES.LINK
        ? removePrefix(formerCustomLink.link, 'https://')
        : formerCustomLink.link
      : '',
  );
  const [selectedIds, setSelectedIds] = useState(new Set(formerIds));

  const onCustomLinkBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'customLink') {
      if (errorMsg === t('correctLink') && customLink.length > 1) setErrorMsg('');
    }
  };

  const handleOptionLinks = (newIds: number[], deletedIds: number[]) => {
    const filteredLinks = props.userLinks.filter(l => !deletedIds.includes(l.themeLinkOption?.id));

    let newLinks = [];
    for (const newId of newIds) {
      const themeLinkOption = linkType.options.find(lt => lt.id === newId);

      const newLink = {
        ...themeLinkOption,
        id: Math.floor(Math.random() * 1000),
        linkType,
        linkTypeId: linkType.id,
        themeLinkOption,
        themeLinkOptionId: themeLinkOption.id,
        canDisplayOnProfile: true,
        isShallow: true,
      };

      newLinks.push(newLink);
    }
    props.setUserLinks([...filteredLinks, ...newLinks]);
  };

  const addEditCustomLinks = () => {
    let index = props.userLinks?.findIndex(l => l.isCustomLink && l.linkTypeId === linkType.id);
    let updatedLink = props.userLinks?.find(l => l.isCustomLink && l.linkTypeId === linkType.id);
    if (index !== -1) props.userLinks[index] = { ...updatedLink, link: customLink };
    else {
      const newLink = {
        id: Math.floor(Math.random() * 1000),
        name: `My ${linkType.name}`,
        link: customLink,
        isCustomLink: true,
        linkType,
        linkTypeId: linkType.id,
        canDisplayOnProfile: true,
        isShallow: true,
      };

      props.setUserLinks(prevUserLinks => [...prevUserLinks, newLink]);
    }
  };

  const deleteCustomLinks = () => {
    props.setUserLinks(prevUserLinks =>
      prevUserLinks.filter(link => link.id !== formerCustomLink.id),
    );
  };

  const onSaveClick = () => {
    const { options, allowCustom, id: linkTypeId, name: linkTypeName } = linkType;

    if (options.length === 0 && allowCustom) {
      if (customLink.length <= 0) {
        if (formerCustomLink) {
          if (bulkEdit) props.handleBulkCustomLinks({ formerCustomLink });
          else if (employeeId) {
            deleteCustomLinks();
          } else dispatch(deleteCustomLinkThemed(formerCustomLink.id, getAccessTokenSilently));
        }
        props.onCloseClick();
      } else {
        if (bulkEdit) props.handleBulkCustomLinks({ linkTypeId, customLink });
        else if (employeeId) addEditCustomLinks();
        else
          dispatch(
            saveCustomLinkThemed(
              linkTypeId,
              `my ${linkTypeName}`,
              customLink,
              getAccessTokenSilently,
            ),
          );
        props.onCloseClick();
      }
    } else if (options.length > 0 && !allowCustom) {
      // get all new ids
      const newIds = options
        .filter(option => selectedIds.has(option.id) && !formerIds.includes(option.id))
        .map(o => o.id);
      // get all deleted ids
      const deletedIds = options
        .filter(option => !selectedIds.has(option.id) && formerIds.includes(option.id))
        .map(o => o.id);

      if (bulkEdit) props.handleBulkOptionLinks({ newIds, deletedIds });
      else if (employeeId) handleOptionLinks(newIds, deletedIds);
      else {
        if (newIds.length) dispatch(saveLinksThemed(newIds, getAccessTokenSilently));
        if (deletedIds.length) dispatch(deleteLinksThemed(deletedIds, getAccessTokenSilently));
      }
      props.onCloseClick();
    } else if (options.length > 0 && allowCustom) {
      if (customLinkSelected && customLink.length <= 1) {
        setErrorMsg(t('correctLink'));
        return;
      }
      // get all new ids
      const newIds = options
        .filter(option => selectedIds.has(option.id) && !formerIds.includes(option.id))
        .map(o => o.id);
      // get all deleted ids
      const deletedIds = options
        .filter(option => !selectedIds.has(option.id) && formerIds.includes(option.id))
        .map(o => o.id);

      const optionsChanged = newIds.length > 0 || deletedIds.length > 0;
      if (optionsChanged) {
        if (bulkEdit) props.handleBulkOptionLinks({ newIds, deletedIds });
        else if (employeeId) handleOptionLinks(newIds, deletedIds);
        else {
          if (newIds.length) dispatch(saveLinksThemed(newIds, getAccessTokenSilently));
          if (deletedIds.length) dispatch(deleteLinksThemed(deletedIds, getAccessTokenSilently));
        }
      }
      // handle custom link: delete
      if (formerCustomLink && !customLinkSelected) {
        if (bulkEdit) props.handleBulkCustomLinks({ formerCustomLink });
        else if (employeeId) deleteCustomLinks();
        else dispatch(deleteCustomLinkThemed(formerCustomLink.id, getAccessTokenSilently));
        // handle custom link: add
      } else if (customLinkSelected) {
        if (bulkEdit) props.handleBulkCustomLinks({ linkTypeId, customLink });
        else if (employeeId) addEditCustomLinks();
        else
          dispatch(
            saveCustomLinkThemed(
              linkTypeId,
              `my ${linkTypeName}`,
              customLink,
              getAccessTokenSilently,
            ),
          );
      }
      props.onCloseClick();
    } else {
      setErrorMsg(t('serverError'));
      return;
    }
  };

  const renderOptions = linkType => {
    const { options, allowCustom } = linkType;
    if (options.length === 0 && allowCustom) {
      return (
        <TextFieldWrapper>
          <TextField
            name='customLink'
            placeholder={t('enterLink')}
            value={customLink}
            onChange={e => {
              return setCustomLink(removePrefix(e.target.value, 'https://'));
            }}
            onBlur={onCustomLinkBlur}
            maxLength={2000}
            variant='outlined'
            fullWidth={true}
            angles='curved'
            bgColor={{ normal: '#fff', hover: '#fff' }}
            padding={{ left: 'auto' }}
            border={{ normal: '1px solid #ddd', focus: '1px solid #ddd' }}
          />
        </TextFieldWrapper>
      );
    } else {
      const output = options.map(option => (
        <div key={option.id} className='lt-theme-link-option'>
          <LinkOptionLabel>
            <LinkOptionCheckbox
              type='checkbox'
              checked={selectedIds.has(option.id)}
              onChange={() =>
                setSelectedIds(prev => {
                  if (!prev.has(option.id)) {
                    return new Set([...Array.from(prev), option.id]);
                  } else {
                    const out = new Set(Array.from(prev));
                    out.delete(option.id);
                    return out;
                  }
                })
              }
            />
            <LinkOptionCheckmark />
            {option.name}
          </LinkOptionLabel>
        </div>
      ));

      if (allowCustom) {
        output.push(
          <div key='custom-link' className='custom-input-area'>
            <LinkOption>
              <LinkOptionLabel>
                <LinkOptionCheckbox
                  type='checkbox'
                  checked={customLinkSelected}
                  onChange={() => setCustomLinkSelected(prev => !prev)}
                />
                <LinkOptionCheckmark />
                my {linkType.name}
              </LinkOptionLabel>
            </LinkOption>
            {customLinkSelected && (
              <TextFieldWrapper>
                <TextField
                  name='customLink'
                  placeholder={t('enterLink')}
                  value={customLink}
                  onChange={e => setCustomLink(removePrefix(e.target.value, 'https://'))}
                  onBlur={onCustomLinkBlur}
                  maxLength={2000}
                  variant='outlined'
                  fullWidth={true}
                  angles='curved'
                  bgColor={{ normal: '#fff', hover: '#fff' }}
                  padding={{ left: 'auto' }}
                  border={{ normal: '1px solid #ddd', focus: '1px solid #ddd' }}
                />
              </TextFieldWrapper>
            )}
          </div>,
        );
      }
      return output;
    }
  };

  return (
    <Popup
      headerText={t('chooseLinks')}
      onCloseClick={props.onCloseClick}
      onBackClick={props.onBackClick}
    >
      <div>
        <LinkWrapper style={{ background: linkType.backgroundCss }}>
          <img src={linkType.iconUrl} alt='link icon' />
        </LinkWrapper>

        {renderOptions(linkType)}

        {errorMsg && <ErrorMessage message={errorMsg} />}
        <Button text={t('save')} onClick={onSaveClick} disabled={isLoading} />
      </div>
    </Popup>
  );
}

const TextFieldWrapper = styled.div`
  margin: 2rem 0 0;
`;

const LinkWrapper = styled.div`
  border-radius: 10px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 1.5rem;
  padding: 1.5rem;
  cursor: pointer;
  max-width: 5rem;
  margin: 0 auto 1.5rem;

  img {
    height: 25px;
  }
`;

const LinkOption = styled.div``;

const LinkOptionLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  font-size: 1.5rem;
  color: #55595e;
`;

const LinkOptionCheckmark = styled.span`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 3px;
  border: 2px solid #828282;

  transition: all 0.1s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0.3rem;
    height: 0.9rem;
    border: solid transparent;
    border-color: white;
    border-width: 0 3px 3px 0;
  }
`;

const LinkOptionCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;

  &:checked ~ ${LinkOptionCheckmark} {
    background-color: #01c886;
    border: 2px solid #01cb8c;

    &::after {
      display: block;
    }
  }
`;
