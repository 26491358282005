import { fetchNfcCardConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import withNav from '../../../../infrastructure/hoc/withNav';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import useDesktopView from '../../../../infrastructure/hooks/useDesktopView';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import cardsImage from '../../../images/nfc-card/lt-cards.png';
import { PageHeader, HeaderTextContainer } from '../common';
import styled from 'styled-components';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { LtDialog, PageContainer } from '@/components';
import { NFCCard } from '@/shared/types/api';
import LtActionCard from '@/components/LtActionCard';

const MdNFCCards = () => {
  const [cardsInfo, setCardsInfo] = useState<NFCCard[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  const { desktopView } = useDesktopView();
  const { isThemeAdmin, isUnitAdmin, isTrial } = useTierInfo();
  const { t, activeLanguage } = useAppTranslation();

  const isGerman = activeLanguage.startsWith('de');

  const [popupContent, setPopupContent] = useState<NFCCard>(null);
  const [selectedLinkByCountry, setSelectedLinkByCountry] = useState(null);

  const noBoxText = isThemeAdmin
    ? t('nfc.noBoxThemeAdmin')
    : isUnitAdmin
    ? t('nfc.noBoxUnitAdmin')
    : '';

  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  useDeferredLoader(loadingConfig, 'config-loader-toast');

  useEffect(() => {
    (async () => {
      try {
        const { data: { data = [] } = {} } = await fetchNfcCardConfig(getAccessTokenSilently);
        setCardsInfo(data);
        console.log({ data });
      } catch (error) {
        toast.error(t('error.general'));
      }
      setLoadingConfig(false);
    })();
  }, [getAccessTokenSilently, t]);

  const handleMenuItemClick = (card: NFCCard) => {
    if (card.linksByCountry) {
      setPopupContent(card);
    } else if (card.link) {
      window.open(isGerman ? card.link.de : card.link.en, '_blank');
    }
  };

  const onPopupClose = () => {
    setPopupContent(null);
    setSelectedLinkByCountry(null);
  };

  const onPopupNextClick = () => {
    setPopupContent(null);
    if (selectedLinkByCountry) window.open(selectedLinkByCountry, '_blank');
    setSelectedLinkByCountry(null);
  };

  if (loadingConfig) return null;

  return (
    <>
      {popupContent && (
        <LtDialog
          open
          onClose={onPopupClose}
          title={t('nfc.order.byCountryPopup.title')}
          confirmAction={{ text: t('next'), onClick: onPopupNextClick }}
          disabled={!selectedLinkByCountry}
        >
          <Box display='flex'>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={selectedLinkByCountry}
              onChange={e => setSelectedLinkByCountry(e.target.value)}
            >
              {popupContent.linksByCountry?.map(conf => (
                <FormControlLabel
                  value={isGerman ? conf.de : conf.en}
                  control={<Radio />}
                  label={isGerman ? conf.label.de : conf.label.en}
                />
              ))}
            </RadioGroup>
          </Box>
          <Typography variant='body1' mt={3}>
            {t('nfc.order.byCountryPopup.other')}
          </Typography>
        </LtDialog>
      )}
      <PageContainer maxWidth='l'>
        {!desktopView && (
          <HeaderTextContainer>
            <PageHeader>{t('nfc.title')}</PageHeader>
          </HeaderTextContainer>
        )}
        {cardsInfo?.length ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LtActionCard
                image={{ src: cardsImage, alt: t('nfc.order.imageAlt') }}
                title={t('nfc.order.title')}
                body={t('nfc.order.body')}
                multipleActions={{
                  text: t('nfc.order.actionLabel'),
                  actions: cardsInfo.map(card => ({
                    text: t(`nfc.order.subaction.${card.key}.label`),
                    tooltipText: t(`nfc.order.subaction.${card.key}.tooltip`),
                    onClick: () => handleMenuItemClick(card),
                    disabled: card.disableDuringTrial && isTrial,
                  })),
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Banner>{noBoxText}</Banner>
        )}
      </PageContainer>
    </>
  );
};

const Banner = styled.div`
  position: relative;
  padding: 2rem 3rem;
  margin-top: 5rem;
  text-align: center;
`;

export default withNav(
  MdNFCCards,
  {
    tTitle: 'nfc.title',
  },
  {},
);
