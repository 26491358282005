import { ModalProps, Modal as MuiModal, Box } from '@mui/material';

type Props = ModalProps & { children: React.ReactNode };

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '100%',
  overflow: 'auto',
  width: '76rem',
};

export default function Modal(props: Props) {
  const { children, ...modalProps } = props;
  return (
    <MuiModal {...modalProps}>
      <Box sx={style}>{children}</Box>
    </MuiModal>
  );
}
