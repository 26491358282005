import CopyLink from '../../components/generic/CopyLink';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { CardRenderer } from '@/components/CardRenderer';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import ShareBar from './ShareBar';

type Props = {
  profileUrl: string;
};

const ShareLinkCard = ({ profileUrl }: Props) => {
  const { t } = useTranslation();
  const { desktopView } = useDesktopView();

  return (
    <CardRenderer
      title={desktopView ? t('shareProfile.profileLink.title') : undefined}
      sx={{ flexBasis: '100%', justifyContent: 'center' }}
      variant={desktopView ? 'elevation' : 'outlined'}
    >
      <Box display={'flex'} width={'100%'} height={'100%'}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          width={'100%'}
          rowGap={3}
          pt={desktopView ? 5 : 2}
          pb={desktopView ? 5 : 2}
        >
          <CopyLink profileUrl={profileUrl} />
          {desktopView && <ShareBar profileUrl={profileUrl} />}
          <Typography variant={'body2'} color='text.secondary'>
            {t('shareProfile.profileLink.tip')}
          </Typography>
        </Box>
      </Box>
    </CardRenderer>
  );
};

export default ShareLinkCard;
