import { Box, Typography } from '@mui/material';
import { FullScreenContainer } from './FullScreenContainer';
import { useTranslation } from 'react-i18next';
import useDeviceInfo from '@/infrastructure/hooks/useDeviceInfo';
import { XButton } from './common';
import { MouseEventHandler } from 'react';

export const CameraNotAvailable = ({ onCloseClick }: { onCloseClick: MouseEventHandler }) => {
  const { t } = useTranslation();
  const { isChrome, isFirefox, isSafari, isEdge, isOpera, isMobile } = useDeviceInfo();

  const getMessageKeyForPermissionDenied = () => {
    const baseText = `businessCardScanner.permissionDenied.${isMobile ? 'mobile' : 'desktop'}.`;

    if (isChrome) return `${baseText}chrome`;
    if (isFirefox) return `${baseText}firefox`;
    if (isSafari) return `${baseText}safari`;
    if (isEdge) return `${baseText}edge`;
    if (isOpera) return `${baseText}opera`;

    return '';
  };

  const message = getMessageKeyForPermissionDenied();

  return (
    <FullScreenContainer>
      <Box
        pb={2.1}
        px={1.6}
        display='flex'
        flexDirection='column'
        height='100%'
        width='80%'
        margin='0 auto'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
      >
        <XButton onClick={onCloseClick} color='primary.dark' />
        <Typography variant='h2' lineHeight={2}>
          {t('businessCardScanner.cameraNotAvailable')}
        </Typography>
        {message && (
          <>
            <Typography variant='h4' mt={3} lineHeight={2}>
              {t('businessCardScanner.permissionDenied.preText')}
            </Typography>
            <Typography variant='body1' mt={1} lineHeight={2}>
              {t(message)}
            </Typography>
          </>
        )}
      </Box>
    </FullScreenContainer>
  );
};
