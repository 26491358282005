import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const PageHeading = (props: { text: string; style?: CSSProperties }): JSX.Element => {
  const { text } = props;
  return (
    <Wrapper style={props.style}>
      <Heading>{text}</Heading>
    </Wrapper>
  );
};
export default PageHeading;

const Wrapper = styled.div`
  margin-bottom: 5px;
`;

const Heading = styled.h4`
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 375px) {
    font-size: 23px;
  }

  @media (max-width: 360px) {
    font-size: 20px;
  }
`;
