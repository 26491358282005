import React, { useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import styled from 'styled-components';

export type AutoCompleteValue = {
  id: number;
  description: string;
  index: number;
  order?: number;
  otherData: any;
};

interface Props {
  options: Array<AutoCompleteValue>;
  onSave: (newValue: AutoCompleteValue) => void;
  placeHolder?: string;
  disabled?: boolean;
  autoCompleteText?: string;
  style?: React.CSSProperties;
  value?: AutoCompleteValue;
}

const filter = createFilterOptions();

const AutoComplete = (props: Props) => {
  const [value, setValue] = useState<AutoCompleteValue | null>(props.value);

  const sortedOptions = useMemo(
    () =>
      props.options?.sort((a, b) =>
        typeof a.order === 'number' && typeof b.order === 'number' ? a.order - b.order : 0,
      ) || [],
    [props.options],
  );

  return (
    <React.Fragment>
      <Autocomplete
        // @ts-ignore:next-line
        value={value}
        disabled={props.disabled}
        // @ts-ignore:next-line
        onChange={(event, newValue: AutoCompleteValue) => {
          setValue(newValue);
          props.onSave(newValue);
        }}
        // @ts-ignore:next-line
        filterOptions={(options: Array<AutoCompleteValue>, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        id='autocomplete-component'
        options={sortedOptions}
        getOptionLabel={(option: AutoCompleteValue) => {
          return option.description;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <AutoCompleteItem {...props} isPair={option.index % 2 === 0}>
            {option?.description}
          </AutoCompleteItem>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={params => {
          // @ts-ignore:next-line
          return (
            <StyledTextField className='textarea' label={props.placeHolder || ''} {...params} />
          );
        }}
        style={{ display: 'flex', flex: 1 }}
      />
    </React.Fragment>
  );
};

export default AutoComplete;

const AutoCompleteItem = styled('li')`
  ${props =>
    props.isPair &&
    `background-color:#F6F8FE;
`};
  font-size: 16px;
  padding: 10px;
`;

const StyledTextField = styled(TextField)`
  input {
    margin-bottom: 0;
    border: none;
    box-shadow: none;
    font-size: 20px;
  }
`;
