import { useAppSelector } from '@/application/hooks';
import { routePaths } from '@/infrastructure/constants';
import { DropdownAction } from '@/infrastructure/hoc/types';
import { getFullName } from '@/shared/business-logic/features/profile/getter';
import { Account } from '@/shared/types/api';

import { useAuth0 } from '@auth0/auth0-react';
import { PeopleOutlineOutlined, AccountCircleOutlined, HelpOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useAppTranslation } from '../useAppTranslation';
import { getProfileImage } from '@/views/pages/md/profiles/table/utils/helpers';
import { useProfileDesign } from '../useProfileDesign';
import useTierInfo from '../useTierInfo';

export const useProfileActions = (): DropdownAction[] => {
  const { isAuthenticated, logout } = useAuth0();
  const account: Account = useAppSelector(state => state.account);
  const { activeLanguage } = useAppTranslation();
  const { profileDesign } = useProfileDesign();
  const { isAnyAdmin } = useTierInfo();

  if (!isAuthenticated || !account) return [];

  return [
    {
      icon: () => (
        <Box
          component='img'
          src={getProfileImage(account, profileDesign)}
          alt='profile image'
          sx={theme => ({
            borderRadius: '50%',
            width: theme.spacing(4),
            height: theme.spacing(4),
            mr: theme.spacing(1),
          })}
        />
      ),
      customActionUI: (
        <Box>
          <Typography variant='body1' sx={{ userSelect: 'text' }}>
            {getFullName(account)}
          </Typography>
          <Typography color='secondary' variant='body2' sx={{ userSelect: 'text' }}>
            {account?.authEmail}
          </Typography>
        </Box>
      ),
      withSeparator: true,
    },

    {
      icon: AccountCircleOutlined,
      label: 'header.settings',
      link: routePaths.SETTINGS.MAIN,
    },
    {
      icon: PeopleOutlineOutlined,
      label: 'header.teamManagement',
      link: routePaths.MD.PROFILES,
      hide: !isAnyAdmin,
      withSeparator: true,
    },

    {
      icon: HelpOutline,
      label: 'header.helpCenter',
      onClick: () =>
        window.open(
          `https://support.lemontaps.com/${
            activeLanguage.startsWith('de') ? 'de' : 'en'
          }/support/home`,
          '_blank',
        ),
      withSeparator: true,
    },

    {
      label: 'logout',
      onClick: () => logout(),
    },
  ];
};
