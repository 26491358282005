import { AddOutlined, East } from '@mui/icons-material';
import { Avatar, Box, Button, CardHeader, CircularProgress } from '@mui/material';

import { LeadAPI } from '@/shared/types/api';
import moment from 'moment';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { NoLeads } from '../../lead-gen/MyLeads/components/NoLeads';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { ReactComponent as ScannerIcon } from '@/views/images/nav/scanner.svg';
import { useHistory } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { getAvatar, getLeadDisplayName } from '../../lead-gen/MyLeads/utils/helpers';
import { withKeyEventHandler } from '@/utils/helpers';
import { CardRenderer } from '@/components/CardRenderer';

const SingleLead = ({ lead }: { lead: LeadAPI }) => {
  const history = useHistory();
  return (
    <CardHeader
      avatar={<Avatar src={getAvatar(lead)} />}
      title={getLeadDisplayName(lead)}
      subheader={lead?.lt2lt?.company || lead.company}
      action={<Box>{moment(lead.createdOn).format('L')}</Box>}
      sx={theme => ({
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.palette.divider}`,
        '.MuiCardHeader-action': { alignSelf: 'center' },
        '&:focus-visible': {
          outline: `1px solid ${theme.palette.primaryButton.main}`,
        },
      })}
      onClick={() => history.push(`/contacts/${lead.id}/edit`)}
      onKeyUp={withKeyEventHandler(() => history.push(`/contacts/${lead.id}/edit`))}
      tabIndex={0}
    />
  );
};

type Props = {
  leads?: LeadAPI[];
  isLoading: boolean;
};

export const LeadsCard = ({ isLoading, leads }: Props) => {
  const { isStarter } = useTierInfo();
  const history = useHistory();
  const flag_businessCardScanner = usePrivateFeatureFlag('businessCardScanner');

  const { t } = useTranslation();

  const scanCardBtn = flag_businessCardScanner ? (
    <Button
      sx={{ svg: { width: '2rem', height: '2rem' } }}
      variant='outlined'
      color='primary'
      startIcon={<ScannerIcon />}
      onClick={() =>
        history.push(isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.CONTACTS.SCANNER)
      }
    >
      {t('scanCard')}
    </Button>
  ) : null;

  const addContactBtn = (
    <Button
      startIcon={<AddOutlined />}
      onClick={() => history.push(isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.CONTACTS.ADD)}
    >
      {t('addContact')}
    </Button>
  );

  return (
    <CardRenderer
      title={t('homePage.leads.recentContacts')}
      actions={[
        <Button
          endIcon={<East />}
          variant='text'
          sx={{ ml: 'auto' }}
          onClick={() =>
            history.push(isStarter ? routePaths.UPGRADE_LEADGEN : routePaths.CONTACTS.ROOT)
          }
        >
          {t('homePage.viewAll')}
        </Button>,
      ]}
    >
      {(isStarter || (!isLoading && leads.length === 0)) && (
        <NoLeads size='sm' actions={[scanCardBtn, addContactBtn]} />
      )}

      {isLoading && (
        <Box display='flex' width='100%' height='100%'>
          <CircularProgress sx={{ m: 'auto' }} />
        </Box>
      )}

      <Box>
        {leads?.map(lead => (
          <SingleLead lead={lead} />
        ))}
      </Box>
    </CardRenderer>
  );
};
