import styled from 'styled-components';
import React, { useState, useEffect, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import withNav from '../../../infrastructure/hoc/withNav';
import { routePaths } from '../../../infrastructure/constants';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import {
  clearApiResponse,
  getThemeLinkTypes,
  reorderLinks,
  reorderFiles,
} from '../../../application/actions/edit-profile';
import ProfileDetails from '../../components/profile-details';
import { Loader } from '../../components/common';
import CreateEditLinkPopup from '../../components/popups/create-edit-link-popup';
import EditLinkTypePopup from '../../components/popups/edit-link-type-popup';
import AddNewFilePopup from '../../components/popups/add-new-file-popup';
import AddNewLinkPopup from '../../components/popups/add-new-link-popup';
import DeletePopup, { DeleteModeType } from '../../components/popups/delete-item-popup';
import ContactDetails from './contact-details';
import LinksLayout from './links-layout';
import FilesLayout from './files-layout';
import { Account, Link, LinkType, StaticProfileCustomTextLine } from '../../../shared/types/api';
import { THEME_FILE_EDIT_MODES, THEME_LINK_EDIT_MODES } from '../../../shared/constants';
import BioText from './bio-text';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as OpenInNewTabIcon } from '../../../views/images/open-in-new-tab.svg';
import { Toaster } from 'react-hot-toast';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { Button, IconButton } from '@mui/material';
import {
  HeaderAction,
  createHeaderActions,
  useHeaderActions,
} from '@/components/Header/useHeaderActions';
import { IosShare } from '@mui/icons-material';

// TODO: profile page optimizations
// integrate with components used in show-profile

export interface LinkTypeProp extends LinkType {
  // TODO: Replace with api type from lemontaps-lib
  options: any; //TODO any must be replaced
  allowCustom: boolean;
}

export const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  // background: isDragging ? 'rgba(245,245,245, 0.75)' : 'none',

  ...draggableStyle,
});

const EditProfile = (props, ref) => {
  const { t } = useAppTranslation();
  const history = useHistory();

  const { getAccessTokenSilently } = useAuth0();

  const { profileDesign } = useProfileDesign();
  const { editRights } = useEditRights();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.editProfile.isLoading);
  const isLinkTypesLoading = useAppSelector(state => state.editProfile.isLinkTypesLoading);
  const themeLinkTypes = useAppSelector(state => state.editProfile.linkTypes);
  const account = useAppSelector<Account>(state => state.account);
  const links = useAppSelector(state => {
    return state.account.links ? state.account.links.sort((a, b) => a.order - b.order) : [];
  });
  const files = useAppSelector(state => {
    return state.account.files ? state.account.files.sort((a, b) => a.order - b.order) : [];
  });
  const apiResponse = useAppSelector(state => state.editProfile.apiResponse);

  const [isResponseUpdated, setResponseUpdate] = useState(false);
  const [showAddNewLink, setAddNewLink] = useState(false);
  const [showLinkTypePopup, setShowLinkTypePopup] = useState<LinkTypeProp | false>(false);
  const [showCreatePopup, setCreatePopup] = useState(false);
  const [selectedLink, setSelectedLink] = useState<Link>();
  const [showAddNewFile, setAddNewFile] = useState(false);
  const [showDeletePopup, setDeletePopup] = useState(false);
  const [deleteMode, setDeleteMode] = useState<DeleteModeType>('');
  // TODO: replace any
  const reorder = <T extends Link | File>(
    list: Array<T>,
    startIndex: number,
    endIndex: number,
  ): Array<any> => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((elements, idx) => ({
      ...elements,
      order: idx * 10,
    }));
  };
  const onDragEndLinks = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const newItems = reorder(links, source.index, destination.index);

    dispatch(reorderLinks(newItems, getAccessTokenSilently));
  };

  const onDragEndFiles = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const newItems = reorder(files, source.index, destination.index);

    dispatch(reorderFiles(newItems, getAccessTokenSilently));
  };

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (account.theme?.id) {
      dispatch(getThemeLinkTypes(getAccessTokenSilently));
    }
  }, [account, dispatch, getAccessTokenSilently]);

  const handleShowPreview = (newTab?: boolean) => () => {
    if (newTab) window.open(`/${account.username}?forceProfile=true`, '_blank');
    else history.push(`/${account.username}?forceProfile=true`);
  };

  const headerActions = useMemo<HeaderAction[]>(
    () =>
      createHeaderActions(
        [
          <IconButton onClick={() => history.push(routePaths.SHARE_PROFILE)}>
            <IosShare />
          </IconButton>,
        ],
        [
          <Button
            variant='outlined'
            color='primary'
            onClick={() => history.push(`/${account.username}?forceProfile=true`)}
          >
            {t('preview')}
          </Button>,
          { mobileOnly: true },
        ],
      ),
    [account.username, history, t],
  );
  useHeaderActions(headerActions);

  const showCreateEditLinkPopup = item => {
    setResponseUpdate(false);
    setSelectedLink(item);
    setAddNewLink(false);
    setCreatePopup(true);
  };

  const onCreateLinkBackClick = () => {
    setCreatePopup(false);
    setAddNewLink(true);
  };

  const onAddNewLink = () => {
    setAddNewLink(true);
  };

  const onAddNewFileClick = () => {
    setResponseUpdate(false);
    setAddNewFile(true);
  };

  const onDeleteLinkClick = () => {
    setCreatePopup(false);
    setDeleteMode('link');
    setDeletePopup(true);
  };

  const onDeleteFileClick = item => {
    setSelectedLink(item);
    setDeleteMode('file');
    setResponseUpdate(false);
    setDeletePopup(true);
  };

  const checkForResponse = () => {
    if (apiResponse && apiResponse.isSuccess === true && isResponseUpdated === false) {
      setCreatePopup(false);
      setResponseUpdate(true);
      setDeletePopup(false);
      setAddNewFile(false);
      dispatch(clearApiResponse());
    }
  };

  const navigateToEditDetails = () => {
    history.push(routePaths.EDITBIO);
  };

  const maxLinkOrder = useMemo(
    () => links?.reduce((prev, cur) => (cur.order > prev ? cur.order : prev), -1) || 0,
    [links],
  );

  const contactDetailsBox = (
    <ContactDetails
      account={account}
      boxStyle={profileDesign.boxStyle}
      editConfig={{
        addNewText: t('edit'),
        onAddNewClick: navigateToEditDetails,
      }}
    />
  );
  const bioTextBox = <BioText boxStyle={profileDesign.boxStyle} bioText={account.bioText} />;
  const linksBox =
    !account.theme ||
    editRights.linkEditMode !== THEME_LINK_EDIT_MODES.DISALLOW ||
    links.length > 0 ? (
      <LinksLayout
        onAddNewLink={onAddNewLink}
        onDragEndLinks={onDragEndLinks}
        setShowLinkTypePopup={setShowLinkTypePopup}
        showCreateEditLinkPopup={showCreateEditLinkPopup}
        getItemStyle={getItemStyle}
        showDeletePopup={link => {
          setSelectedLink(link);
          onDeleteLinkClick();
        }}
      />
    ) : null;
  const filesBox =
    !account.theme ||
    editRights.fileEditMode !== THEME_FILE_EDIT_MODES.DISALLOW ||
    files.length > 0 ? (
      <FilesLayout
        onDragEndFiles={onDragEndFiles}
        onDeleteFileClick={onDeleteFileClick}
        onAddNewFileClick={onAddNewFileClick}
        getItemStyle={getItemStyle}
      />
    ) : null;

  let mainContentBoxes = [contactDetailsBox, bioTextBox, linksBox, filesBox];
  if (account?.theme?.name === 'capgemini-invent')
    mainContentBoxes = [filesBox, contactDetailsBox, bioTextBox, linksBox];

  return (
    <>
      {checkForResponse()}
      <Toaster containerClassName='toaster' />
      <script></script>
      <RelativeDiv>
        <StyledButton onClick={handleShowPreview(true)}>
          <OpenInNewTabIcon />
          {t('preview')}
        </StyledButton>
        {showAddNewLink && (
          <AddNewLinkPopup
            newOrder={maxLinkOrder + 10}
            onCloseClick={() => setAddNewLink(false)}
            onItemClick={item => showCreateEditLinkPopup(item)}
            onLinkTypeClick={linkType => {
              setShowLinkTypePopup(linkType);
              setAddNewLink(false);
            }}
            loading={isLinkTypesLoading}
            linkTypes={themeLinkTypes}
            firstWebsite={editRights.linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
        )}
        {showCreatePopup && (
          <CreateEditLinkPopup
            onCloseClick={() => setCreatePopup(false)}
            onBackClick={() => onCreateLinkBackClick()}
            onDeleteClick={() => onDeleteLinkClick()}
            item={selectedLink}
            mode={selectedLink.linkType?.mode}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
        )}
        {showLinkTypePopup && (
          <EditLinkTypePopup
            onCloseClick={() => setShowLinkTypePopup(false)}
            onBackClick={() => {
              setShowLinkTypePopup(false);
              setAddNewLink(true);
            }}
            linkType={showLinkTypePopup}
            formerIds={account.links
              .filter(l => !!l.themeLinkOption)
              .map(l => l.themeLinkOption.id)}
            formerCustomLink={account.links.find(
              l => l.isCustomLink && l.linkType && l.linkType.id === showLinkTypePopup.id,
            )}
          />
        )}
        {showDeletePopup && selectedLink && (
          <DeletePopup
            onCloseClick={() => setDeletePopup(false)}
            item={selectedLink}
            mode={deleteMode}
          />
        )}
        {showAddNewFile && <AddNewFilePopup onCloseClick={() => setAddNewFile(false)} />}
        {isLoading && <Loader />}
        {/* <Header showHowToTap={showHowToTap} /> */}
        <ProfileDetails
          username={account.username}
          bannerImgOptions={profileDesign.bannerImgOptions}
          logoImgOptions={profileDesign.logoImgOptions}
          config={{
            bannerImageUrl: account.bannerImageUrl || profileDesign.defaultBannerImageUrl,
            logoImageUrl: profileDesign.logoHeaderUrl || account.logoHeaderUrl,
            profileImageUrl: account.profileImageUrl || profileDesign.defaultProfileImageUrl,
            bioContent: [
              {
                text: account.academicDegree,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.academicDegree2,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: [account.namePrefix, account.firstName, account.lastName]
                  .filter(Boolean)
                  .join(' '),
                color: '#55595E',
                size: 'big',
                fontWeight: 700,
                italic: false,
                lineHeight: 'small',
              },
              {
                text: account.position,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.role,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.division,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.company,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: true,
                lineHeight: 'normal',
              },
            ].filter((e): e is StaticProfileCustomTextLine => !!e.text),
          }}
          boxStyle={profileDesign.boxStyle}
          canEdit
          canEditLogo={!profileDesign.logoHeaderUrl}
        />
        <EditProfileWrapper>{mainContentBoxes}</EditProfileWrapper>
      </RelativeDiv>
    </>
  );
};

export default withNav(
  EditProfile,
  {
    tTitle: 'editProfile',
    showShareButton: true,
  },
  {
    activeScreen: routePaths.EDITPROFILE,
  },
);

const RelativeDiv = styled.div`
  position: relative;
`;

const EditProfileWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 33%;
  margin: 0 auto;
  padding-bottom: 5rem;

  @media (max-width: 1024px) and (min-height: 1366px) {
    width: 50%;
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;

const StyledButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  box-shadow: none;
  font-size: 15px;
  padding: 5px 10px;
  border: 1px solid #191a1c;
  border-radius: 4px;
  line-height: 1.4;
  color: #191a1c;
  font-family: inherit, Arial;
  font-weight: 500;
  background-color: white;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: #e3e4e4;
  }

  &:focus {
    background-color: #e3e4e4;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 767px) {
    font-size: 11px;
  }

  svg {
    margin-right: 5px;
    margin-bottom: -1px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
