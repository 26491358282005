import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React, { CSSProperties, useState } from 'react';
import { ImInfo } from 'react-icons/im';
import HelpOIcon from '@mui/icons-material/HelpOutlineOutlined';
import styled from 'styled-components';
import { IconButton } from '@mui/material';

type Props = {
  text: TooltipProps['title'];
  onHover?: boolean;
  children?: JSX.Element;
  icon?: { size?: number; color?: string; help?: boolean };
  placement?: TooltipProps['placement'];
  arrow?: boolean;
  style?: CSSProperties;
  onHoverCursor?: string;
};

const TooltipInfo = (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {props?.onHover ? (
        <>
          <Tooltip
            title={<Content>{props.text}</Content>}
            placement={props?.placement}
            arrow={props?.arrow}
            enterDelay={100}
            PopperProps={{
              sx: props?.style,
            }}
          >
            <span style={{ cursor: props.onHoverCursor }}>
              {props.children ? (
                props.children
              ) : props?.icon?.help ? (
                <IconButton>
                  <HelpOIcon
                    sx={{
                      color: props?.icon?.color ?? '#DADADA',
                      fontSize: props?.icon?.size ?? 17,
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton>
                  <ImInfo size={props?.icon?.size ?? 17} color={props?.icon?.color ?? '#DADADA'} />
                </IconButton>
              )}
            </span>
          </Tooltip>
        </>
      ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                sx: props?.style,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<Content>{props.text}</Content>}
              placement={props?.placement}
              arrow={props?.arrow}
            >
              {props.children ? (
                props.children
              ) : (
                <IconButton onClick={handleTooltipOpen} onBlur={handleTooltipClose}>
                  {props?.icon?.help ? (
                    <HelpOIcon
                      sx={{
                        color: props?.icon?.color ?? '#DADADA',
                        fontSize: props?.icon?.size ?? 17,
                      }}
                    />
                  ) : (
                    <ImInfo
                      size={props?.icon?.size ?? 17}
                      color={props?.icon?.color ?? '#DADADA'}
                    />
                  )}
                </IconButton>
              )}
            </Tooltip>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TooltipInfo;

const Content = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;
  white-space: pre-wrap;
`;
