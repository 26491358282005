import React from 'react';
import styled from 'styled-components';
import { Popup, Button } from '../../components/common';
import { useTranslation } from 'react-i18next';
import { Spacer } from '../../pages/md/customization/common';
import { FiTrash } from 'react-icons/fi';
import { CSS_VARS } from '@/infrastructure/constants';

interface Props {
  label: string;
  onDeleteClick: () => void;
  onCancel: () => void;
  deleteIcon?: () => JSX.Element;
  deleteText?: string;
}

const DeletePopup = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Popup onCloseClick={props.onCancel}>
        <ModalContainer className='delete-item-wrapper'>
          {props.deleteIcon || <FiTrash size={60} color='#01ce91' />}
          <Spacer size={10} />
          {props.label}
          <Spacer size={65} />
          <ButtonBlock>
            <CancelButton onClick={props.onCancel}>{t('cancel')}</CancelButton>
            <Button text={props.deleteText || t('deleteIt')} onClick={props.onDeleteClick} />
          </ButtonBlock>
        </ModalContainer>
      </Popup>
    </>
  );
};

export default DeletePopup;

const ModalContainer = styled.div`
  padding: 10px 0 20px 0;
  text-align: center;
`;

const CancelButton = styled.div`
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  cursor: pointer;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    flex: 1;
  }
`;
