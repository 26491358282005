import { useMemo, useState } from 'react';

import { routePaths } from '../../../infrastructure/constants';

import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import withNav from '../../../infrastructure/hoc/withNav';

import { useAppSelector } from '@/application/hooks';
import { Account } from '../../../shared/types/api';

import { PageContainer } from '@/components';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';
import AddAppToHomePopup from '../homepage/components/AddAppToHomePopup';
import { HelpDropdown } from '@/components/Header/components/HelpDropdown';
import { createHeaderAction, useHeaderActions } from '@/components/Header/useHeaderActions';
import Menu from './Menu';

const MoreSettings = () => {
  const { logout } = useAuth0();
  const account = useAppSelector<Account>(state => state.account);
  const { t } = useAppTranslation();

  const [addToHomeScreenVisible, setAddToHomeScreenVisible] = useState(false);

  const [helpOpen, setHelpOpen] = useState(false);
  const headerActions = useMemo(
    () =>
      createHeaderAction(<HelpDropdown open={helpOpen} onClose={() => setHelpOpen(false)} />, {
        mobileOnly: true,
      }),
    [helpOpen],
  );
  useHeaderActions(headerActions);

  return (
    <>
      <PageContainer maxWidth='s'>
        <Menu
          mode='more'
          clickHandlers={{
            help: () => setHelpOpen(true),
            addToHomeScreen: () => setAddToHomeScreenVisible(true),
          }}
        />
        <Box display='flex' justifyContent='center' mt={5}>
          <Button
            variant='text'
            size='large'
            sx={theme => ({ color: theme.palette.error.main })}
            onClick={() => logout()}
          >
            {t('moreSettings.signOut')}
          </Button>
        </Box>
      </PageContainer>

      <AddAppToHomePopup
        domain={account?.theme?.domain}
        open={addToHomeScreenVisible}
        onClose={() => setAddToHomeScreenVisible(false)}
      />
    </>
  );
};

export default withNav(
  MoreSettings,
  {
    tTitle: 'moreSettings.title',
  },
  {
    activeScreen: routePaths.SETTINGS.MORE,
  },
);
