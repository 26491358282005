import React, { useEffect } from 'react';
import { Loader } from '../../components/common';
import { routePaths } from '../../../infrastructure/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';

const Logout = () => {
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  if (!isAuthenticated) {
    return <Redirect exact to={routePaths.LOGIN} />;
  } else {
    return <Loader />;
  }
};

export default Logout;
