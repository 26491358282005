import { useAppSelector } from '../../../../application/hooks';
import { useCallback } from 'react';
import { updateActionPreferences } from '../../../../application/actions/account';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import { ActionPreferences } from '../../../../shared/types/api';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { Alert, AlertTitle, Link, Typography } from '@mui/material';
import { TRIAL_LENGTH_DAYS } from '@/shared/constants';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';

export const FreeTrialBanner: React.FC = () => {
  const { isTrial, isTrialFinished, isMinEssentials, isStarter, isMainAccount } = useTierInfo();
  const actionPreferences = useAppSelector<ActionPreferences>(
    state => state.account?.actionPreferences,
  );

  const trialEndDate = useAppSelector<string>(
    state => state.account?.theme?.themeInternal?.trialEndDate,
  );
  const { t, activeLanguage } = useAppTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const onDismiss = useCallback(() => {
    dispatch(
      updateActionPreferences(getAccessTokenSilently, {
        dismissedFreeTrialBannerAt: new Date().getTime(),
      }),
    );
  }, [dispatch, getAccessTokenSilently]);

  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');

  if (!isMainAccount) {
    return null;
  } else if (!actionPreferences?.dismissedFreeTrialBannerAt && isTrial && isMinEssentials) {
    const daysRemaining = moment(trialEndDate).diff(moment(), 'days');
    const isTrialAboutToEnd = daysRemaining <= 7;
    return (
      <>
        {upgradeRequestPopup}
        <Alert severity={isTrialAboutToEnd ? 'warning' : 'success'}>
          <AlertTitle>
            <Trans
              i18nKey='freeTrialBanner.ongoing.line1'
              count={daysRemaining}
              values={{ days: TRIAL_LENGTH_DAYS }}
            ></Trans>
          </AlertTitle>
          <Typography
            variant='body2'
            onClick={upgradeAction}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              display: 'inline',
            }}
          >
            {flag_hidePrices ? t('contactSales') : t('upgradeNow')}
          </Typography>
          {t('freeTrialBanner.ongoing.line2')}

          <Typography variant='body2'>
            <Trans i18nKey='freeTrialBanner.ongoing.line3'>
              <Link
                style={{
                  textDecoration: 'underline',
                  color: 'inherit',
                }}
                href={
                  activeLanguage.startsWith('de')
                    ? 'https://support.lemontaps.com/de/support/solutions/articles/150000052975-was-passiert-nach-dem-ablauf-meiner-kostenlosen-testphase-'
                    : 'https://support.lemontaps.com/en/support/solutions/articles/150000052975-what-happens-after-my-30-days-trial-period-'
                }
                target='_blank'
                rel='noreferrer'
              ></Link>
            </Trans>
          </Typography>
        </Alert>
      </>
    );
  } else if (!actionPreferences?.dismissedFreeTrialBannerAt && isTrialFinished && isStarter) {
    return (
      <>
        {upgradeRequestPopup}
        <Alert severity='warning' onClose={onDismiss}>
          <AlertTitle>
            <Trans
              i18nKey='freeTrialBanner.finished.line1'
              values={{ days: TRIAL_LENGTH_DAYS }}
            ></Trans>
          </AlertTitle>
          <Typography variant='body2'>
            <Typography
              variant='body2'
              onClick={upgradeAction}
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                display: 'inline',
              }}
            >
              {flag_hidePrices ? t('contactSales') : t('upgradeNow')}
            </Typography>
            {t('freeTrialBanner.finished.line2')}
          </Typography>
        </Alert>
      </>
    );
  } else {
    return null;
  }
};
