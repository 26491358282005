import { FeatureFlags } from '@/shared/types/global';
import { createContext, useContext, useEffect, useState } from 'react';
import { fetchPublicThemeConfig, fetchThemeConfig } from '../apis/md/customization/jsonConfigs';
import { useAuth0 } from '@auth0/auth0-react';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { useAppSelector } from '@/application/hooks';

export default function useFeatureFlags() {
  const { isLoading: isAuth0Loading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const domainVerified = useAppSelector(state => state.login.domainVerified);

  const [publicFlags, setPublicFlags] = useState<FlagState>({ flags: {}, loaded: false });
  const [privateFlags, setPrivateFlags] = useState<FlagState>({ flags: {}, loaded: false });

  useEffect(() => {
    if (domainVerified) {
      fetchPublicThemeConfig(THEME_CONFIG_KEYS.FEATURE_FLAGS_PUBLIC)
        .then(res =>
          setPublicFlags({
            flags: res || {},
            loaded: true,
          }),
        )
        .catch(() => setPublicFlags(prev => ({ ...prev, loaded: true })));
    }
  }, [domainVerified]);

  useEffect(() => {
    if (!isAuth0Loading && domainVerified && isAuthenticated) {
      fetchThemeConfig(getAccessTokenSilently, THEME_CONFIG_KEYS.FEATURE_FLAGS_PRIVATE)
        .then(res =>
          setPrivateFlags({
            flags: res.value || {},
            loaded: true,
          }),
        )
        .catch(() => setPrivateFlags(prev => ({ ...prev, loaded: true })));
    } else if (!isAuth0Loading && !isAuthenticated) {
      setPrivateFlags({ flags: {}, loaded: true });
    }
  }, [getAccessTokenSilently, isAuth0Loading, domainVerified, isAuthenticated]);

  return {
    publicFlags: publicFlags.flags,
    privateFlags: privateFlags.flags,
    isLoading: !publicFlags.loaded || !privateFlags.loaded,
  };
}

type FlagState = { flags: FeatureFlags; loaded: boolean };

export const FeatureFlagContext = createContext<{
  publicFlags: FeatureFlags;
  privateFlags: FeatureFlags;
}>({
  publicFlags: {},
  privateFlags: {},
});

export const usePublicFeatureFlag = (key: string) => {
  const context = useContext(FeatureFlagContext);
  return context.publicFlags[key];
};
export const usePrivateFeatureFlag = (key: string) => {
  const context = useContext(FeatureFlagContext);
  return context.privateFlags[key];
};
