import withNav from '@/infrastructure/hoc/withNav';

import InfoCard from './components/InfoCard';
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import FileCard from './components/FileCard';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { fetchFileGenerations } from '@/infrastructure/apis/md/customization/fileGen';
import PageContainer from '@/components/PageContainer';
import { useLocation } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/system';
import LtLoadingButton from '@/components/LtLoadingButton';
import { FileGeneration } from '@/shared/types/api/file-generation.type';
import { Trans } from 'react-i18next';

const Export = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useAppTranslation();

  const location = useLocation<{ justStarted?: boolean }>();

  const [files, setFiles] = useState<Array<FileGeneration>>(null);
  const [showToastLoader, setShowToastLoader] = useState(false);
  const [showRefreshButtonLoading, setShowRefreshButtonLoading] = useState(false);
  const [showInfoCard, setShowInfoCard] = useState(!!location.state?.justStarted);

  useEffect(() => {
    if (location.state?.justStarted) {
      window.history.replaceState({}, document.title);
    }
  });

  useDeferredLoader(showToastLoader, 'file-gens-fetch-toast');

  const fetchData = useCallback(
    async (withLoader = true) => {
      let out: FileGeneration[] | null = null;
      if (withLoader) setShowToastLoader(true);
      try {
        const res = await fetchFileGenerations(getAccessTokenSilently);
        setFiles(res);
        out = res;
      } catch {
        toast.error(t('error.general'), { id: 'file-gens-fetch-toast' });
      }
      if (withLoader) setShowToastLoader(false);
      return out;
    },
    [getAccessTokenSilently, t],
  );
  const fetchDataSilently = useCallback(() => fetchData(false), [fetchData]);

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    const asyncFetches = async () => {
      let files: FileGeneration[] | null = null;

      files = await fetchData();

      if (files?.some(file => file.status === 'pending')) {
        const offsets = [3000, 10000, 30000];
        offsets.forEach(offset => {
          timeouts.push(
            setTimeout(() => {
              if (files?.some(file => file.status === 'pending'))
                fetchDataSilently().then(res => (files = res));
            }, offset),
          );
        });
      }
    };
    asyncFetches();

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [fetchData, fetchDataSilently]);

  const refresh = async () => {
    setShowRefreshButtonLoading(true);
    await fetchDataSilently();
    setShowRefreshButtonLoading(false);
  };

  const fileCards = files?.length ? (
    files.map(file => (
      <FileCard refetch={fetchDataSilently} file={file} key={`${file.id}_${file.createdOn}`} />
    ))
  ) : files ? (
    <InfoCard title={t('export.emptyCard.title')} body={t('export.emptyCard.body')} />
  ) : null;

  return (
    <PageContainer>
      {showInfoCard && (
        <InfoCard
          title={t('export.infoCard.title')}
          body={
            <Trans
              i18nKey='export.infoCard.body'
              components={[<br />, <span style={{ fontWeight: 600 }} />]}
            />
          }
          // body={t('export.infoCard.body')}
          onClose={() => setShowInfoCard(false)}
        />
      )}
      <Box display='flex' width='100%'>
        <LtLoadingButton
          loading={showRefreshButtonLoading}
          minimumAnimationTime={1000}
          startIcon={<RefreshIcon />}
          onClick={refresh}
          sx={{ mb: 2, ml: 'auto' }}
          color='primaryButton'
        >
          {t('export.refresh')}
        </LtLoadingButton>
      </Box>
      {fileCards}
    </PageContainer>
  );
};

export default withNav(
  Export,
  {
    tTitle: 'export.title',
  },
  {},
);
