import { Box, Chip, IconButton, Typography } from '@mui/material';
import XIcon from '@mui/icons-material/Close';
import { useFrameDimensions } from '../helpers';
import CheckIcon from '@mui/icons-material/Check';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { useTranslation } from 'react-i18next';
import { FullScreenContainer } from './FullScreenContainer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FixedBox, XButton } from './common';

const hidden = { opacity: 0, pointerEvents: 'none' };

const Frame = () => {
  const { width, height } = useFrameDimensions();
  return (
    <>
      <FixedBox
        sx={{
          border: '4px solid white',
          borderRadius: '2rem',
          left: '2.4rem',
          top: '50%',
          transform: 'translateY(-50%)',
          height: height,
          width: width,
        }}
      ></FixedBox>
    </>
  );
};

const CaptureButton = ({ onClick }) => {
  return (
    <Box
      sx={{
        borderRadius: '50%',
        width: '7.2rem',
        height: '7.2rem',
        border: '4px solid white',
        overflow: 'hidden',
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'white',
          opacity: '30%',
          '&:active': { opacity: '50%' },
        }}
      ></Box>
    </Box>
  );
};

const FileSelectButton = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <label>
      <Box width='4rem' height='4rem' border='3px solid white' m='auto'></Box>
      <Typography mt={0.8} variant='body2' color='white'>
        {t('businessCardScanner.upload')}
      </Typography>
      <input type='file' accept='image/*' onChange={onChange} style={{ display: 'none' }} />
    </label>
  );
};

const CameraFlipButton = ({ onClick, sx = {} }) => {
  return (
    <Box>
      <IconButton size='large' onClick={onClick} sx={sx}>
        <CameraswitchIcon sx={{ color: 'white', width: '3rem', height: '3rem' }} />
      </IconButton>
    </Box>
  );
};

const TorchButton = ({ onClick, isOn }) => {
  const Icon = isOn ? FlashOnIcon : FlashOffIcon;
  return (
    <FixedBox sx={{ top: '8rem', right: '2rem' }}>
      <IconButton size='large' onClick={onClick}>
        <Icon sx={{ color: 'white', width: '2.8rem', height: '2.8rem' }} />
      </IconButton>
    </FixedBox>
  );
};

const HelpButton = ({ onClick }) => {
  return (
    <FixedBox sx={{ top: '3rem', left: '3rem' }}>
      <Chip
        label='BETA'
        color='primaryButton'
        variant='filled'
        onClick={onClick}
        icon={<HelpOutlineIcon />}
      />
    </FixedBox>
  );
};

const CancelAcceptButton = ({ onAccept, onCancel }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem', height: 'max-content' }}>
      <IconButton size='large' onClick={onAccept} sx={{ border: '3px solid white' }}>
        <CheckIcon sx={{ color: 'white', width: '3rem', height: '3rem' }} />
      </IconButton>
      <IconButton size='large' onClick={onCancel} sx={{ border: '3px solid white' }}>
        <XIcon sx={{ color: 'white', width: '3rem', height: '3rem' }} />
      </IconButton>
    </Box>
  );
};

const FrameTooltipText = ({ text }) => {
  return (
    <Box
      sx={{
        boxShadow:
          '0px 1px 18px 0px rgba(17, 19, 86, 0.12), 0px 6px 10px 0px rgba(17, 19, 86, 0.14), 0px 3px 5px -1px rgba(17, 19, 86, 0.20)',
        bgcolor: 'white',
        px: '1.6rem',
        py: '1rem',
        borderRadius: '0.4rem',
        textAlign: 'center',
      }}
    >
      <Typography variant='body1'>{text}</Typography>
    </Box>
  );
};

const BottomBarContainer = ({ children }) => {
  const { height } = useFrameDimensions();
  const bottomBarHeight = (window.innerHeight - height) / 2;

  return (
    <FixedBox
      sx={{
        mx: 3,
        bottom: '0',
        left: '0',
        right: '0',
        height: `${bottomBarHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        position='absolute'
        bottom='0'
        width='100%'
        pb={4}
        pt={2}
        minHeight='100%'
        display='flex'
        flexDirection='column'
        alignItems='stretch'
        justifyContent='space-between'
      >
        {children}
      </Box>
    </FixedBox>
  );
};

export const VideoToolsOverlay = ({
  onFileSelect,
  onCaptureClick,
  onCloseClick,
  captured,
  onCaptureCancel,
  onCaptureAccept,
  onCameraFlipClick,
  onHelpClick,
  cameraFlipVisible,
  onTorchClick,
  isTorchAvailable,
  isTorchOn,
  infoText,
}) => {
  return (
    <FullScreenContainer nobg>
      <Box width='100%' height='100%' position='relative'>
        <XButton onClick={onCloseClick} />
        <Frame />
        {onHelpClick && <HelpButton onClick={onHelpClick} />}
        {isTorchAvailable && <TorchButton isOn={isTorchOn} onClick={onTorchClick} />}
        <BottomBarContainer>
          <FrameTooltipText text={infoText} />
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mt={1}
            minHeight='8rem'
          >
            <Box display='flex' justifyContent='flex-start' alignItems='center' width='20%'>
              <FileSelectButton onChange={onFileSelect} />
            </Box>

            {captured ? (
              <CancelAcceptButton onAccept={onCaptureAccept} onCancel={onCaptureCancel} />
            ) : (
              <CaptureButton onClick={onCaptureClick} />
            )}

            <Box display='flex' justifyContent='flex-end' alignItems='center' width='20%'>
              <CameraFlipButton sx={!cameraFlipVisible && hidden} onClick={onCameraFlipClick} />
            </Box>
          </Box>
        </BottomBarContainer>
      </Box>
    </FullScreenContainer>
  );
};
