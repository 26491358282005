import { TFunction } from 'i18next';
import { Account, Link } from '../../../../../../../shared/types/api';
import { BulkLink } from '../components/links';

export const sortLinks = (links: Array<Link>, account: Account) => {
  return links?.sort((a, b) => {
    // move order logic to server
    if (a.linkType.id === b.linkType.id) {
      if (a.isCustomLink && b.isCustomLink) return a.order - b.order;
      else if (a.isCustomLink && !b.isCustomLink) return 1;
      else if (!a.isCustomLink && b.isCustomLink) return -1;
      else return a.order - b.order;
    } else {
      let a_order = a?.order;
      let b_order = b?.order;
      // if (!a_order) {
      //   a_order =
      //     account.theme.themeLinkTypes?.find(val => val.linkType.id === a.linkType.id)?.order || 0;
      // }
      // if (!b_order) {
      //   b_order =
      //     account.theme.themeLinkTypes?.find(val => val.linkType.id === b.linkType.id)?.order || 0;
      // }
      return a_order - b_order;
    }
  });
};

export const isLinkExtendable = (link: BulkLink) => {
  return !(link['existsForAll'] || link['extendToAll']);
};

export const getLinkCountString = (link: BulkLink, t: TFunction) => {
  return isLinkExtendable(link)
    ? t('xProfilesHaveLink', { cnt: link['countToRender'] })
    : t('allProfilesHaveLink');
};
