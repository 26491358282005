import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  validateResponse: {},
  apiResponse: {},
  addPhotoAPIResponse: {},
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.ONBOARDING.SHOWLOADER:
      return { ...state, isLoading: true };
    case dispatchTypes.ONBOARDING.ONBOARDINGOPERATIONS.SUCCESS:
    case dispatchTypes.ONBOARDING.ONBOARDINGOPERATIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        apiResponse: action.apiResponse,
      };
    case dispatchTypes.ONBOARDING.ONBOARDINGOPERATIONS.VALIDATESUCCESS:
    case dispatchTypes.ONBOARDING.ONBOARDINGOPERATIONS.VALIDATEERROR:
      return {
        ...state,
        isLoading: false,
        validateResponse: action.apiResponse,
      };
    case dispatchTypes.ONBOARDING.ADDPHOTO.SUCCESS:
    case dispatchTypes.ONBOARDING.ADDPHOTO.ERROR:
      return {
        ...state,
        isLoading: false,
        addPhotoAPIResponse: action.apiResponse,
      };
    default:
      return state;
  }
};

export default onboardingReducer;
