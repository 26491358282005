import styled from 'styled-components';
import React from 'react';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import { CSS_VARS } from '@/infrastructure/constants';

interface Props {
  label: string;
  total: number;
  tooltipText?: string;
  unit?: string;
  size?: string;
  center?: boolean;
}

const SummaryCard = (props: Props) => {
  const CardContainer = styled.div`
    border-radius: 10px;
    background-color: #e8eaf1;
    width: ${props.size || '30%'};
    position: relative;
    padding-bottom: 35px;
    max-width: 400px;
    min-width: 230px;
    margin-right: 35px;
    margin: 10px;
    ${!props.center && `text-align: left;`};
    padding: 25px;
    ${props.center && `flex-direction: column;`};
  `;
  return props.unit ? (
    <CardContainer>
      <Total>
        {props.total}
        <Unit>{props.unit}</Unit>
      </Total>
      <Label>{props.label}</Label>
    </CardContainer>
  ) : (
    <CardContainer>
      <Label>{props.label}</Label>
      <Total>{props.total}</Total>
      {props.tooltipText && (
        <TooltipPosition>
          <TooltipInfo
            text={props.tooltipText}
            placement='top'
            icon={{ color: CSS_VARS.LT_DEFAULT_COLOR_VAR }}
          />
        </TooltipPosition>
      )}
    </CardContainer>
  );
};

export default SummaryCard;

const Label = styled.div`
  color: #191b5a;
  font-weight: 600;
`;
const Total = styled.div`
  color: ${CSS_VARS.LT_DEFAULT_COLOR_VAR};
  font-weight: 600;
  font-size: 24px;
`;

const TooltipPosition = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  .MuiTooltip-tooltip {
    width: max-content;
  }
`;

const Unit = styled.span`
  font-size: 1.5rem;
`;
