import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  error: null,
  apiResponse: {},
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL:
      return { ...state, isLoading: true, error: null };
    case dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL_SUCCESS:
      return { ...state, isLoading: false, apiResponse: action.apiResponse };
    case dispatchTypes.VERIFICATION.SEND_VERIFICATION_EMAIL_ERROR:
      return { ...state, isLoading: false, error: true, apiResponse: action.apiResponse };
    default:
      return state;
  }
};

export default verificationReducer;
