import { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { IoIosClose } from 'react-icons/io';
import CloudUpload from '../../../../images/cloud-upload.svg';
import { isFileValid, isNotEmptyArray } from '../../../../../infrastructure/helper';

import { Spacer, SubsectionSpan } from '../common';
import {
  AdditionalImagesContainer,
  ArrayImage,
  ClearImageButton,
  ImageSizeSpan,
  OneImageContainer,
  UploadBox,
} from './common';
import ImageCropper from '../../../../components/image-cropper';
import { useTranslation } from 'react-i18next';
import { imageSizeValidator } from '@/views/components/qrcode-background/common/utils';
import toast from 'react-hot-toast';
import { QRBGMode } from '@/views/components/qrcode-background/common/types';
import {
  UPLOADED_IMG_LIMIT_MO,
  UPLOADED_IMG_LIMIT_VC,
} from '@/views/components/qrcode-background/common/constants';

interface Props {
  mode: QRBGMode;
  additionalMobileBgs?: Array<any>;
  setAdditionalMobileBgs?: Dispatch<SetStateAction<Array<any>>>;
  additionalVideoCallBgs?: Array<any>;
  setAdditionalVideoCallBgs?: Dispatch<SetStateAction<Array<any>>>;
}

const AdditionalImages = (props: Props) => {
  const { t } = useTranslation();
  let fileInput = useRef(null);

  const { mode } = props;
  const isMobile = mode === 'mobile';

  type PathType = { fileName: string; filePath: string };
  const [pathsToRender, setPathsToRender] = useState<PathType[]>();
  const [imageCount, setImageCount] = useState(0);
  const [renderCount, setRenderCount] = useState(0);
  const [showCropper, setShowCropper] = useState('');

  useEffect(() => {
    setRenderCount(renderCount + 1);
    if (renderCount > 1) return;

    setPathsToRender(
      (props?.additionalMobileBgs || props?.additionalVideoCallBgs || []).map(x => {
        return {
          fileName: x,
          filePath: x,
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.additionalMobileBgs, props?.additionalVideoCallBgs]);

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          // do we want to alert or toast?
          alert(errMsg);
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => setShowCropper(reader.result as string);
        return;
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    // validate uploaded image size
    const error = await imageSizeValidator(croppedImageUrl, mode);
    if (error && error.length) {
      toast.error(error);
      return;
    }

    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = fileInput.current.files[0].name.split('.').pop();
    setShowCropper('');

    let file: File;
    let fileName: string;
    setImageCount(imageCount + 1);

    switch (mode) {
      case 'mobile':
        fileName = `qr-mobile/${imageCount}.${ext}`;
        file = new File([blob], fileName);
        props.setAdditionalMobileBgs([...props.additionalMobileBgs, file]);
        setPathsToRender([...pathsToRender, { fileName, filePath: croppedImageUrl }]);
        break;
      case 'videocall':
        fileName = `qr-videocall/${imageCount}.${ext}`;
        file = new File([blob], fileName);
        props.setAdditionalVideoCallBgs([...props.additionalVideoCallBgs, file]);
        setPathsToRender([...pathsToRender, { fileName, filePath: croppedImageUrl }]);
        break;
    }
  };

  const handleX = (file: PathType) => {
    const filterFn = (img: File | string) => {
      if (img instanceof File) return img.name !== file.fileName;
      else return img !== file.filePath;
    };

    switch (mode) {
      case 'mobile':
        setPathsToRender(pathsToRender.filter(image => image.fileName !== file.fileName));
        props.setAdditionalMobileBgs(imgs => imgs.filter(filterFn));
        break;
      case 'videocall':
        setPathsToRender(pathsToRender.filter(image => image.fileName !== file.fileName));
        props.setAdditionalVideoCallBgs(imgs => imgs.filter(filterFn));
        break;
    }
  };

  return (
    <>
      {showCropper && (
        <ImageCropper
          image={showCropper}
          onUpload={url => onUploadClick(url)}
          onClose={() => setShowCropper('')}
          noAspectRatio={true}
          fixedCrop={true}
          crop={{ width: 100, height: 100 }}
        />
      )}

      <Spacer size={30} />
      <DialogContainer>
        {isMobile ? (
          <SubsectionSpan bigMarginBottom>{t('qrBgTab.additionalMobileBgs')}</SubsectionSpan>
        ) : (
          <SubsectionSpan bigMarginBottom>{t('qrBgTab.additionalVideoCallBgs')}</SubsectionSpan>
        )}
        <>
          <UploadBox onClick={() => fileInput.current.click()}>
            <img src={CloudUpload} alt='upload' />
            <SubsectionSpan>{t('qrBgTab.uploadBg')}</SubsectionSpan>
          </UploadBox>
          {isMobile ? (
            <ImageSizeSpan>{t('qrBgTab.minBgSize', { x: UPLOADED_IMG_LIMIT_MO })}</ImageSizeSpan>
          ) : (
            <ImageSizeSpan>{t('qrBgTab.minBgSize', { x: UPLOADED_IMG_LIMIT_VC })}</ImageSizeSpan>
          )}
        </>
      </DialogContainer>

      <Spacer size={30} />

      <AdditionalImagesContainer>
        {pathsToRender?.map(file => (
          <OneImageContainer key={file.fileName}>
            <ArrayImage src={file.filePath} alt='banner' />
            <ClearImageButton onClick={() => handleX(file)}>
              <IoIosClose size={16} color='#4748EC' />
            </ClearImageButton>
          </OneImageContainer>
        ))}
      </AdditionalImagesContainer>

      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default AdditionalImages;

const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 45%;
`;
