import { HelpSidebarKey } from '@/shared/constants';
import { Box, BoxProps, Card } from '@mui/material';
import { HelpButton } from './HelpSidebar';
import { LtDefaultActionButtonsProps } from './LtDefaultActionButtons/types';
import LtActionButtonBar from './LtActionButtonBar';
import { useMemo } from 'react';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';

type Props = {
  children: React.ReactNode;
  maxWidth?: 's' | 'm' | 'l' | 'xl';
  helpSidebarConfigKey?: HelpSidebarKey | null;
  helpButtonBoxProps?: BoxProps;
  bottomActionBar?: LtDefaultActionButtonsProps;
  whiteCardWrapper?: boolean;
} & BoxProps;

const PageContainer: React.FC<Props> = ({
  children,
  maxWidth,
  helpSidebarConfigKey,
  helpButtonBoxProps,
  bottomActionBar,
  whiteCardWrapper,
  sx,
  ...rest
}) => {
  const { desktopView } = useDesktopView();
  const _sx = {
    ...(maxWidth === 's'
      ? {
          maxWidth: '60rem',
          margin: '0 auto',
        }
      : maxWidth === 'm'
      ? {
          maxWidth: '120rem',
          margin: '0 auto',
        }
      : maxWidth === 'l'
      ? {
          maxWidth: '180rem',
          margin: '0 auto',
        }
      : maxWidth === 'xl'
      ? {
          maxWidth: '240rem',
          margin: '0 auto',
        }
      : {}),
    ...sx,
  };

  const ContentWrapper = useMemo(
    () =>
      whiteCardWrapper && desktopView
        ? ({ children }: React.PropsWithChildren<{}>) => (
            <Card sx={{ display: 'flex', justifyContent: 'center', p: 2, borderRadius: '1rem' }}>
              {children}
            </Card>
          )
        : ({ children }: React.PropsWithChildren<{}>) => <Box>{children}</Box>,
    [desktopView, whiteCardWrapper],
  );

  return (
    <>
      <Box sx={_sx} px={{ xs: 2, md: 4 }} pt={{ xs: 2, md: 4.5 }} pb={4.5} {...rest}>
        {helpSidebarConfigKey && (
          <Box display='flex' justifyContent='flex-end' mt={-1.5} {...helpButtonBoxProps}>
            <HelpButton configKey={helpSidebarConfigKey} />
          </Box>
        )}
        <ContentWrapper>{children}</ContentWrapper>
      </Box>
      {bottomActionBar && (
        <LtActionButtonBar noCancelOnDesktop renderRelativeBox {...bottomActionBar} />
      )}
    </>
  );
};

export default PageContainer;

export type PageContainerProps = Props;
