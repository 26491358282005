import 'react-image-crop/dist/ReactCrop.css';
import React, { useCallback, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import { LtDialog } from '@/components';
import { Box, ThemeProvider } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

let imageRef;

interface Props {
  image: string;
  onUpload: (croppedImageUrl: string) => Promise<void>;
  onClose: () => void;
  crop?: Crop;
  noAspectRatio?: boolean;
  fixedCrop?: boolean;
  circularCrop?: boolean;
  style?: React.CSSProperties;
  cropStyle?: React.CSSProperties;
  isLocked?: boolean;
}

const ImageCropper = ({
  circularCrop,
  crop: cropOptions,
  noAspectRatio,
  onClose,
  fixedCrop,
  onUpload,
  image,
  cropStyle,
  isLocked,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState<Crop>(
    circularCrop
      ? { unit: '%', width: 100, x: 12.5, aspect: 1 / 1 }
      : {
          unit: cropOptions?.unit ?? '%',
          width: cropOptions?.width ?? 100,
          height: cropOptions?.height,
          x: noAspectRatio ? 0 : cropOptions?.x ?? 0,
          y: noAspectRatio ? 0 : cropOptions?.y ?? 0,
          aspect: noAspectRatio ? null : cropOptions?.aspect ?? 1 / 1,
        },
  );
  const [croppedImageUrl, setCroppedImageUrl] = useState('');

  const [loading, setLoading] = useState(false);

  const onCropChange = crop => {
    setCrop(crop);
  };

  const onImageLoaded = image => {
    imageRef = image;
  };

  const getCroppedImg = useCallback(
    (image, crop) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      if (fixedCrop) {
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
      } else {
        if (noAspectRatio) {
          canvas.width = crop.width * 4.5;
          canvas.height = crop.height * 4.5;
        } else {
          if (crop.aspect > 1) {
            canvas.width = 1980;
            canvas.height = 1980 / crop.aspect;
          } else if (crop.aspect < 1) {
            canvas.height = 1980;
            canvas.width = 1980 * crop.aspect;
          } else {
            canvas.height = canvas.width = 1980;
          }
        }
      }

      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        (crop.width - 2) * scaleX,
        (crop.height - 4) * scaleY,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob(
          blob => {
            if (!blob) {
              reject('Canvas is empty');
              return;
            }
            // @ts-ignore:next-line
            window.URL.revokeObjectURL(canvas.fileUrl);
            // @ts-ignore:next-line
            canvas.fileUrl = window.URL.createObjectURL(blob);
            // @ts-ignore:next-line
            resolve(canvas.fileUrl);
          },
          'image/png',
          0.75,
        );
      });
    },
    [fixedCrop, noAspectRatio],
  );

  const makeClientCrop = useCallback(
    async crop => {
      if (imageRef && crop.width && crop.height) {
        const croppedImageUrl: string = (await getCroppedImg(imageRef, crop)) as string;
        setCroppedImageUrl(croppedImageUrl);
      }
    },
    [getCroppedImg],
  );

  const handleUpload = async () => {
    if (croppedImageUrl.length > 0) {
      setLoading(true);
      await onUpload(croppedImageUrl);
      setLoading(false);
    }
  };

  const { theme } = useMuiTheme();

  return (
    <ThemeProvider theme={theme}>
      <LtDialog
        open
        onClose={onClose}
        onCancel={onClose}
        title={t('cropImage')}
        confirmAction={{
          ttext: 'upload',
          onClick: handleUpload,
        }}
        loading={loading}
        withActionDivider
      >
        <Box display='flex'>
          <ReactCrop
            style={{
              maxHeight: '400px',
              margin: '0 auto',
              ...cropStyle,
            }}
            imageStyle={{ maxHeight: '400px' }}
            src={image}
            crop={crop}
            onChange={onCropChange}
            onComplete={makeClientCrop}
            onImageLoaded={onImageLoaded}
            locked={isLocked}
            circularCrop={circularCrop}
          />
        </Box>
      </LtDialog>
    </ThemeProvider>
  );
};

export default ImageCropper;
