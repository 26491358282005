import { Account } from '../types/api';

export const getMimeTypeFromUrl = (url: string): string => {
  const split = url.split('.');
  if (split.length > 1) {
    switch (split[split.length - 1]) {
      case 'jpg':
      case 'jpeg':
        return 'image/JPEG';
      case 'png':
        return 'image/PNG';
      case 'svg':
        return 'image/svg+xml';
      default:
        return '';
    }
  }
  return '';
};

export const getMimeTypeFromBase64DataUrl = (dataUrl: string): string => {
  let parts = dataUrl.split(';');
  let mediaType = parts[0];
  let split = mediaType.split('/');
  if (split.length === 2) {
    switch (split[1]) {
      case 'jpg':
      case 'jpeg':
        return 'image/JPEG';
      case 'png':
        return 'image/PNG';
      case 'svg':
        return 'image/svg+xml';
      default:
        return '';
    }
  }
  return '';
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

// removes leading symbol(s) from string
export const removeLeading = (value: string, symbol: string) => {
  if (value.trim().startsWith(symbol)) return value.trim().substring(symbol.length);
  else return value.trim();
};

const getAddressLines = (
  elements: [string, string, string, string, string, string],
  delimiter: string,
): string => {
  const [addressee, addressLine1, addressLine2, postCode, city, country] = elements;

  const lines = [
    addressee,
    addressLine1,
    addressLine2,
    [postCode.trim(), city.trim()].filter(Boolean).join(' '),
    country,
  ];
  return lines
    .map(r => r.trim())
    .filter(Boolean)
    .join(delimiter);
};
export const getAddressFromAccount = (
  account: Partial<Account>,
  prefix: 1 | 2,
  delimiter = '\n',
): string => {
  let addressee = '',
    addressLine1 = '',
    addressLine2 = '',
    postCode = '',
    city = '',
    country = '';
  if (prefix === 1) {
    addressee = account.address1_addressee || '';
    addressLine1 = account.address1_addressLine1 || '';
    addressLine2 = account.address1_addressLine2 || '';
    postCode = account.address1_postCode || '';
    city = account.address1_city || '';
    country = account.address1_country || '';
  } else if (prefix === 2) {
    addressee = account.address2_addressee || '';
    addressLine1 = account.address2_addressLine1 || '';
    addressLine2 = account.address2_addressLine2 || '';
    postCode = account.address2_postCode || '';
    city = account.address2_city || '';
    country = account.address2_country || '';
  } else {
    return '';
  }

  return getAddressLines(
    [addressee, addressLine1, addressLine2, postCode, city, country],
    delimiter,
  );
};

export const getAddressFromEntity = (
  entity: Partial<
    Record<
      'addressee' | 'addressLine1' | 'addressLine2' | 'postCode' | 'city' | 'country',
      string | null
    >
  >,
  delimiter = '\n',
): string => {
  return getAddressLines(
    [
      entity.addressee || '',
      entity.addressLine1 || '',
      entity.addressLine2 || '',
      entity.postCode || '',
      entity.city || '',
      entity.country || '',
    ],
    delimiter,
  );
};

export const lexicalSortArray = (a: string, b: string) =>
  a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0;
export const lexicalSortObject =
  (attributeName: string) => (a: Record<string, any>, b: Record<string, any>) => {
    var textA = a[attributeName].toUpperCase();
    var textB = b[attributeName].toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };

// "Crème Brulée" -> "Creme Brulee"
export const removeDiacritics = (text: string) =>
  text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');

export const base64UrlEncode = (s: string) =>
  Buffer.from(s).toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
export const base64UrlDecode = (s: string) =>
  Buffer.from(s.replace(/-/g, '+').replace(/_/g, '/').replace(/\./g, '='), 'base64').toString();
