import { ProfileDesignConfig } from '../../../../../../shared/types/api';
import { Employee } from '../../../../../../shared/types/api/employee.type';

export const getProfileImage = (employee: Employee, profileDesignConfig: ProfileDesignConfig) =>
  employee.profileImageUrl || profileDesignConfig.defaultProfileImageUrl;

export const getPhone = (phone: string) => (phone ? `+${phone}` : '');

export const handleProfileClick = (username: string) =>
  window.open(`/${username}?lt2lt=false`, '_blank');
