import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../application/hooks';
import toast from 'react-hot-toast';
import { ApiFile, Link as ApiLink, ProfileDesignConfig, Theme } from '../../../../shared/types/api';

import { HeaderTextContainer, PaddedContainer, Spacer } from './common';

import { PageSubheader } from '../common';
import { Account } from '../../../../shared/types/api';
import SectionHeading from './section-heading';
import BoxForm from './box-form';
import ButtonColors from './button-colors';
import Images from './images';
import Buttons from './buttons';
import ContactsForm from './contactsForm';
import PolicyForm from './policyForm';
import ImageUpload from './ImageUpload';
import { getUploadFileThemeUrl } from '../../../../application/actions/md/profiles/files';
import Links from '../profiles/common/links';
import {
  FEATURE,
  THEME_CONFIG_KEYS,
  THEME_CONTACT_EXCHANGE_FLOWS,
  THEME_LINK_EDIT_MODES,
} from '../../../../shared/constants';
import withNav from '../../../../infrastructure/hoc/withNav';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import FeatureTeaser from '../teaser/featureTeaser';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import {
  deleteThemeConfig,
  fetchThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import ProfileDesignFiles from './ProfileDesignFiles';
import { updateFiles } from '@/infrastructure/apis/files';
import { saveCompanyDetailsLinksAPI } from '@/infrastructure/apis/edit-profile';
import { useDispatch } from 'react-redux';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { fetchUserData } from '@/application/actions/account';
import { SettingsContext } from './Settings/SettingsWrapper/context';
import { SettingsWrapper } from './Settings/SettingsWrapper';
import { DeleteSettingsSection } from './Settings/DeleteSettingsSection';
import AdditionalLogoUpload from './AdditionalLogoUpload';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { updateProfileDesign } from '@/infrastructure/apis/md/customization';

export interface ProfileDesignConfigLocal
  extends Omit<
    ProfileDesignConfig,
    | 'defaultBannerImageUrl'
    | 'defaultProfileImageUrl'
    | 'logoHeaderUrl'
    | 'bannerImgOptions'
    | 'logoImgOptions'
  > {
  defaultBannerImageUrl: string | File;
  defaultProfileImageUrl: string | File;
  logoHeaderUrl: string | File;
  bannerImgOptions: (string | File)[];
  logoImgOptions: (string | File)[];
}

type ProfileDesignState = Omit<ProfileDesignConfigLocal, 'bannerImgOptions' | 'logoImgOptions'>;

export type Image = {
  file: File | null;
  url: string;
  fileName?: string;
};

type LocalApiFile = ApiFile & { fileToUpload?: File };

const mapImageUrls = (urls: string[]) => {
  return urls.map(x => ({
    file: null,
    url: x,
    fileName: x,
  }));
};

const ProfileDesign = () => {
  const {
    selectedUnit,
    deleteButtonIsVisible,
    setSettingsHierarchyLevel,
    deleteClickHandler,
    setSettingsLoading,
    settingsHierarchyLevel,
  } = useContext(SettingsContext);
  const { isFeatureAllowed, isUnitAdmin } = useTierInfo();

  const isGlobalDataFeautureAllowed = isFeatureAllowed(FEATURE.GLOBAL_DATA);
  const { t } = useTranslation();
  const { getAccessTokenSilently, logout } = useAuth0();

  const { editRights } = useEditRights();
  const account: Account = useAppSelector(state => state.account);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const [profileDesign, setProfileDesign] = useState<ProfileDesignState>();
  const [bannerImgOptions, setBannerImgOptions] = useState<Image[]>([]);
  const [logoImgOptions, setLogoImgOptions] = useState<Image[]>([]);
  const selectedUnitId = selectedUnit?.id || null;

  const { refetch: refetchGlobalProfileDesign } = useProfileDesign();
  const { mutateProfileDesignForUnit, deleteProfileDesignForUnit } =
    useProfileDesignForUnits(selectedUnitId);

  const { setIsUnsaved, withUnsavedSetter } = useUnsavedStatusSetter();

  const [isSaving, setIsSaving] = useState(false);
  const themeLinks = useRef<(ApiLink & { newItem?: boolean })[]>([]);
  const setThemeLinks = useCallback(tlinks => {
    themeLinks.current = tlinks;
  }, []);
  const [themeLinksLoading, setThemeLinksLoading] = useState(
    isGlobalDataFeautureAllowed ? editRights.linkEditMode !== THEME_LINK_EDIT_MODES.OPTIONS : false,
  );
  const setThemeLinksFinishedLoading = useCallback(() => setThemeLinksLoading(false), []);

  const dispatch = useDispatch();

  const themeFiles = useRef<LocalApiFile[]>([]);
  const setThemeFiles = useCallback(tfiles => {
    themeFiles.current = tfiles;
  }, []);
  const [themeFilesLoading, setThemeFilesLoading] = useState(
    isGlobalDataFeautureAllowed ? true : false,
  );

  const setThemeFilesFinishedLoading = useCallback(() => setThemeFilesLoading(false), []);

  const fetchProfileDesign = useCallback(
    async (hideLoader?: boolean) => {
      if (!hideLoader) setSettingsLoading(true);
      try {
        const {
          value,
          meta: { hierarchyLevel },
        } = await fetchThemeConfig<ProfileDesignConfig>(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.PROFILE_DESIGN,
          fetchThemeLevel: true,
          unitId: selectedUnitId,
        });
        const {
          bannerImgOptions: _bannerImgOptions,
          logoImgOptions: _logoImgOptions,
          ..._profileDesign
        } = value;
        setProfileDesign(_profileDesign);
        setBannerImgOptions(mapImageUrls(_bannerImgOptions));
        setLogoImgOptions(mapImageUrls(_logoImgOptions));
        setSettingsHierarchyLevel(hierarchyLevel);
      } catch (error) {
        toast.error(t('error.general'));
      }
      setSettingsLoading(false);
    },
    [getAccessTokenSilently, t, selectedUnitId, setSettingsHierarchyLevel, setSettingsLoading],
  );

  useEffect(() => {
    fetchProfileDesign();
  }, [fetchProfileDesign]);

  const handleChange = (
    field: keyof ProfileDesignConfig,
    value: string | boolean | File | Array<string | boolean | File>,
  ) => {
    withUnsavedSetter(() => {});
    setProfileDesign(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const getLinksFromFiles = useCallback(
    async (files: LocalApiFile[]) => {
      return await Promise.all(
        files.map(async file => {
          if (file.link) {
            return file.link;
          } else if (file.id < 0 && file.fileToUpload) {
            return file.link
              ? file.link
              : await getUploadFileThemeUrl(
                  theme?.themeInternal.uniqueId,
                  file.fileToUpload,
                  account.id,
                  getAccessTokenSilently,
                );
          } else {
            return '';
          }
        }),
      );
    },
    [theme?.themeInternal.uniqueId, account.id, getAccessTokenSilently],
  );

  const handleSave = async () => {
    setIsSaving(true);
    toast.loading(t('Loading'), { id: 'profile-design-save-loader' });

    let ids = { id: account.id, uniqueId: theme?.themeInternal.uniqueId };

    const promises = [];
    if (!themeFilesLoading && isGlobalDataFeautureAllowed) {
      const links: string[] = await getLinksFromFiles(themeFiles.current);

      const filesForUpdate: ApiFile[] = themeFiles.current.map(
        ({ id, fileToUpload, ...rest }, index) => ({
          ...rest,
          ...(id < 1 ? {} : { id }), // template files ids is between 0 and 1, uploaded file ids is below 0. Keep ids only for already existing theme files
          link: links[index],
        }),
      );

      promises.push(updateFiles(getAccessTokenSilently, filesForUpdate, selectedUnitId));
    }

    if (
      isGlobalDataFeautureAllowed &&
      themeLinks.current &&
      editRights.linkEditMode !== THEME_LINK_EDIT_MODES.OPTIONS &&
      !themeLinksLoading
    ) {
      const links = (themeLinks.current || []).map(l => (l.newItem ? { ...l, id: undefined } : l));
      promises.push(saveCompanyDetailsLinksAPI(links, getAccessTokenSilently, selectedUnitId));
    }

    try {
      const payload = {
        ...profileDesign,
        bannerImgOptions: bannerImgOptions.map(x => (x.file ? x.file : x.url)),
        logoImgOptions: logoImgOptions.map(x => (x.file ? x.file : x.url)),
      };
      const profileDesignPromise = updateProfileDesign(
        ids,
        payload,
        getAccessTokenSilently,
        selectedUnitId,
      );

      const [profileDesignResponse] = await Promise.all([profileDesignPromise, ...promises]);
      setIsUnsaved(false);
      fetchProfileDesign();
      refetchGlobalProfileDesign();
      mutateProfileDesignForUnit(profileDesignResponse);
      dispatch(fetchUserData(getAccessTokenSilently, () => logout(), true));
      toast.success(t('changesSaved'), { id: 'profile-design-save-loader' });
    } catch {
      toast.error(t('unexpectedError'), { id: 'profile-design-save-loader' });
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      await deleteThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.PROFILE_DESIGN,
        unitId: selectedUnitId,
        withDeleteUnitConfig: true,
      });
      await fetchProfileDesign(true);
      deleteProfileDesignForUnit();
      toast.success(t('changesSaved'));
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  let sectionIdx = 1;
  return (
    <>
      {profileDesign && (
        <>
          <PaddedContainer>
            {deleteButtonIsVisible && (
              <DeleteSettingsSection
                onDelete={() => deleteClickHandler(handleDelete)}
                hierarchyLevel={settingsHierarchyLevel}
              />
            )}
            <HeaderTextContainer>
              <PageSubheader>
                {t(
                  `profileDesignInfo.${
                    isUnitAdmin
                      ? 'unitAdmin'
                      : selectedUnit
                      ? 'themeAdmin.unit'
                      : 'themeAdmin.global'
                  }`,
                )}
              </PageSubheader>
            </HeaderTextContainer>
            <SectionHeading
              headerText={sectionIdx++ + '. ' + t('boxForm')}
              tooltipText={t('md.profileDesign.boxFormTooltip')}
            />
            <BoxForm
              boxForm={profileDesign.boxStyle}
              setBoxForm={value => handleChange('boxStyle', value)}
            />
            <hr />
            <SectionHeading headerText={sectionIdx++ + '. ' + t('buttonColors')} />
            <ButtonColors
              saveColor={profileDesign.leadGenColor}
              filesColor={profileDesign.fileBoxColor}
              genericWebsiteColor={profileDesign.genericWebsiteColor}
              setSaveColor={value => handleChange('leadGenColor', value)}
              setFilesColor={value => handleChange('fileBoxColor', value)}
              setGenericWebsiteColor={value => handleChange('genericWebsiteColor', value)}
            />
            <hr />
            <SectionHeading headerText={sectionIdx++ + '. ' + t('images')} />
            <Images
              key={`images-${selectedUnitId || ''}`}
              bannerImage={profileDesign.defaultBannerImageUrl}
              profileImage={profileDesign.defaultProfileImageUrl}
              logoHeaderImage={profileDesign.logoHeaderUrl}
              setBannerImage={value => handleChange('defaultBannerImageUrl', value)}
              setProfileImage={value => handleChange('defaultProfileImageUrl', value)}
              setLogoHeaderImage={value => handleChange('logoHeaderUrl', value)}
            />
            <hr />
            <SectionHeading
              headerText={sectionIdx++ + '. ' + t('additionalCover')}
              tooltipText={t('md.profileDesign.uploadImageTooltip')}
              subHeader={t('*optional')}
            />
            <ImageUpload
              key={`cover-images-${selectedUnitId || ''}`}
              images={bannerImgOptions}
              onChange={setBannerImgOptions}
            />
            <SectionHeading
              headerText={sectionIdx++ + '. ' + t('md.profileDesign.additionalLogosTitle')}
              tooltipText={t('md.profileDesign.additionalLogosTooltip')}
              subHeader={t('*optional')}
            />
            {profileDesign.logoHeaderUrl ? (
              <>
                <Spacer size={15} />
                <div>{t('md.profileDesign.additionalLogosDefaultLogoIsSet')}</div>
                <Spacer size={15} />
                <hr />
              </>
            ) : (
              <AdditionalLogoUpload images={logoImgOptions} onChange={setLogoImgOptions} />
            )}
            <SectionHeading headerText={sectionIdx++ + '. ' + t('contactsForm')} />
            <ContactsForm
              leadGenFormShown={profileDesign.leadGenFormShown}
              setLeadGenFormShown={value => handleChange('leadGenFormShown', value)}
              leadGenFormAsPopup={
                profileDesign.contactExchangeFlow === THEME_CONTACT_EXCHANGE_FLOWS.POPUP
              }
              setLeadGenFormAsPopup={value =>
                handleChange(
                  'contactExchangeFlow',
                  value
                    ? THEME_CONTACT_EXCHANGE_FLOWS.POPUP
                    : THEME_CONTACT_EXCHANGE_FLOWS.BOTTOM_CARD_STATIC,
                )
              }
            />
            {editRights.linkEditMode !== THEME_LINK_EDIT_MODES.OPTIONS && (
              <>
                <hr />
                <SectionHeading
                  headerText={sectionIdx++ + '. ' + t('md.profileDesign.themeLinks.title')}
                  tooltipText={t('md.profileDesign.themeLinks.tooltip')}
                />
                {isGlobalDataFeautureAllowed ? (
                  <>
                    <Spacer size={25} />
                    <Links
                      unitId={selectedUnitId}
                      mode='themeLinks'
                      setThemeLinks={setThemeLinks}
                      themeLinksPosition={profileDesign.themeLinksPosition}
                      setThemeLinksPosition={value => handleChange('themeLinksPosition', value)}
                      loadingCompleted={setThemeLinksFinishedLoading}
                      genericWebsiteColor={profileDesign.genericWebsiteColor}
                    />
                    <Spacer size={50} />
                  </>
                ) : (
                  <FeatureTeaser text={t('upgradeTeaser.feature.themeLinks')} fullWidth />
                )}
              </>
            )}
            <hr />

            <SectionHeading
              headerText={sectionIdx++ + '. ' + t('md.profileDesign.themeFiles.title')}
              tooltipText={t('md.profileDesign.themeFiles.tooltip')}
            />
            {isGlobalDataFeautureAllowed ? (
              <>
                <Spacer size={25} />
                <ProfileDesignFiles
                  unitId={selectedUnitId}
                  setThemeFiles={setThemeFiles}
                  themeFilesPosition={profileDesign.themeFilesPosition}
                  setThemeFilesPosition={value => handleChange('themeFilesPosition', value)}
                  loadingCompleted={setThemeFilesFinishedLoading}
                />
              </>
            ) : (
              <FeatureTeaser text={t('upgradeTeaser.feature.themeLinks')} fullWidth />
            )}
            <hr />

            <SectionHeading
              headerText={sectionIdx++ + '. ' + t('footerPageHeader')}
              tooltipText={t('md.customizationFooter.mainTooltip')}
            />
            <PolicyForm
              privacyUrl={profileDesign.privacyUrl || ''}
              imprintUrl={profileDesign.imprintUrl || ''}
              showCopyright={profileDesign.showCopyright}
              legalName={profileDesign.legalName || ''}
              onChange={(field, value) => handleChange(field, value)}
            />
          </PaddedContainer>
          <Buttons
            onSave={handleSave}
            saveDisabled={themeLinksLoading || themeFilesLoading || isSaving}
            position='sticky'
          />
        </>
      )}
    </>
  );
};

export default withNav(
  () => {
    const { t } = useTranslation();
    return (
      <SettingsWrapper
        requiredFeature={FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN}
        teaserContent={{
          text: t('upgradeTeaser.feature.unitHierarchies.profileDesign'),
        }}
        settingKey={'profile-design'}
      >
        <ProfileDesign />
      </SettingsWrapper>
    );
  },
  {
    tTitle: 'profileDesign',
  },
  {},
);
