import { FontType } from '@/shared/types/api';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

// TODO: profile page optimizations

interface Props {
  font?: FontType;
}

export default function OverridesWrapper({
  font,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <OverrideStylesWrapper id='lt-font-wrapper' {...font}>
      {children}
    </OverrideStylesWrapper>
  );
}

const OverrideStylesWrapper = styled.div`
  ${(props: FontType) =>
    props?.name
      ? `
  &#lt-font-wrapper * {
   font-family: ${props?.name || 'Poppins, sans-serif'};
  }`
      : ''}
`;
