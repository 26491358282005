import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routePaths } from './infrastructure/constants';

const firstLevelRoutes = Object.values(routePaths)
  .map(x => (typeof x === 'object' ? Object.values(x) : x))
  .flat()
  .map(x => (typeof x === 'object' ? Object.values(x) : x))
  .flat()
  .filter(x => !x.substring(1).includes('/'));

//workaround to check if route is public profile
const isPublicProfilePage = (path: string) => {
  const splittedPath = path.split('/').filter(x => !!x);
  if (splittedPath.length > 1) {
    return false;
  }
  if (firstLevelRoutes.includes(path)) {
    return false;
  }
  return true;
};

const UserCentricsProvider = ({ children }) => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const userAlreadyInteracted = JSON.parse(localStorage.getItem('uc_user_interaction'));

  // needed so that the cookie banner won't be shown on the public profile page
  useEffect(() => {
    const handler = UC_UI => {
      UC_UI?.updateLanguage?.(i18n.language.substring(0, 2));
      if (userAlreadyInteracted) {
        console.log('Already Interacted');
      } else {
        if (!isPublicProfilePage(pathname)) {
          UC_UI.showFirstLayer();
        }
      }
    };

    if (!window.UC_UI || !window.UC_UI.isInitialized()) {
      window.addEventListener('UC_UI_INITIALIZED', () => {
        handler(window.UC_UI);
      });
    } else {
      handler(window.UC_UI);
    }
  }, [pathname, i18n.language, userAlreadyInteracted]);

  return <>{children}</>;
};

export default UserCentricsProvider;
