import { InputRestrictions, TextField } from '@/views/components/generic';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  niceName: string;
  setNiceName: Dispatch<SetStateAction<string>>;
}

const OrganisationName = (props: Props) => {
  const { niceName, setNiceName } = props;

  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };

  return (
    <div style={{ width: '50%', marginBottom: '5rem' }}>
      <TextField
        type='text'
        bgColor={{ normal: '#fff' }}
        name={'imprintUrl'}
        value={niceName}
        placeholder={'Name'}
        angles='normal'
        border={borderProps}
        onChange={e => setNiceName(e.target.value)}
        inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
        font={{ size: '18px', weight: '400' }}
        padding={{ left: '0' }}
        fullWidth
        isRequired
      />
    </div>
  );
};

export default OrganisationName;
