import { Box } from '@mui/material';

const styles = theme => ({
  width: '100%',
  height: '100%',
  maxWidth: '768px',
  margin: 'auto',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 0,
  position: 'fixed',
  zIndex: 1111,
  overflow: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.10)',
  },
});

export const FullScreenContainer = ({ children, nobg = false, noShadow = false, ...rest }) => {
  return (
    <Box
      className='scanner-page-container'
      sx={theme => ({ ...styles(theme), bgcolor: nobg ? 'none' : 'white' })}
      {...rest}
    >
      {children}
    </Box>
  );
};
