import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  apiResponse: {},
};

const editBioReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.EDITBIO.SAVE:
      return { ...state, isLoading: true };
    case dispatchTypes.EDITBIO.CLEARAPIRESPONSE:
      return { ...state, apiResponse: {} };
    case dispatchTypes.EDITBIO.SAVESUCCESS:
    case dispatchTypes.EDITBIO.SAVEERROR:
      return { ...state, isLoading: false, apiResponse: action.apiResponse };
    default:
      return state;
  }
};

export default editBioReducer;
