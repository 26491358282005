import config from '../../config/config';
import axios from 'axios';
import { routePaths } from '../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuth0TokenScopeString } from '@/util';

export const getResetPasswordUrlApi = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.post(
    config.API_BASE_URL + 'account/get-password-reset-url',
    {
      redirectTo: `${window.location.origin}/${routePaths.LOGIN}`,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
