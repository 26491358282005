import { getUnits } from '@/infrastructure/apis/md/units';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { Unit } from '@/shared/types/api/unit.type';
import { useAuth0 } from '@auth0/auth0-react';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, InputLabel, MenuItem, Chip } from '@mui/material';
import { FeatureSet } from '@/shared/types/api';

export const useUnitsForSettings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureAllowed, isUnitAdmin } = useTierInfo();
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [units, setUnits] = useState<Unit[]>([]);

  const isUnitsAllowed = isFeatureAllowed(FEATURE.UNITS);

  useEffect(() => {
    if (isUnitsAllowed) {
      getUnits(getAccessTokenSilently).then(res => {
        if (res.data.isSuccess) {
          setUnits(res.data.data.units);
          if (isUnitAdmin) setSelectedUnit(res.data.data.mainUnit || null);
        }
      });
    }
  }, [getAccessTokenSilently, isUnitAdmin, setSelectedUnit, isUnitsAllowed]);

  return { selectedUnit, setSelectedUnit, units };
};

type Props = {
  selectedUnit: Unit | null;
  onChange(unit: Unit | null): void;
  units: Unit[];
  requiredFeature: keyof FeatureSet;
} & ComponentProps<typeof FormControl>;

export const UnitSelector = (props: Props): JSX.Element => {
  const { selectedUnit, onChange, units, requiredFeature, ...formProps } = props;
  const { t } = useTranslation();
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();

  const value = selectedUnit?.id || (isThemeAdmin ? 'global' : '');
  return (
    <FormControl
      fullWidth
      sx={theme => ({ bgcolor: theme.palette.background.default })}
      {...formProps}
    >
      <InputLabel id='unit-select-label'>{t('unitsSelectLabel')}</InputLabel>
      <Select
        labelId='unit-select-label'
        value={value}
        label={t('unitsSelectLabel')}
        onChange={e =>
          onChange(units.find(u => u.id?.toString() === e.target.value?.toString()) || null)
        }
      >
        {isThemeAdmin && <MenuItem value='global'>{t('unitsGlobalSelect')}</MenuItem>}
        {units.map(unit => (
          <MenuItem value={unit.id} key={unit.id}>
            {unit.niceName}
            {isThemeAdmin && !isFeatureAllowed(requiredFeature) && (
              <Chip sx={{ ml: 1 }} color='info' size='small' label='Addon' />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
