import React from 'react';
import styled from 'styled-components';
import { TextField, TextFieldTypes, TextFieldPropsTypes } from '../generic/textField';

type IProps = TextFieldPropsTypes & {
  hidePlusIcon?: boolean;
};

const TelephoneBox = ({ hidePlusIcon, ...rest }: IProps) => {
  return (
    <>
      <Wrapper>
        {!hidePlusIcon && <Icon>+</Icon>}
        <TextField type={TextFieldTypes.PHONE} maxLength={25} {...rest} />
      </Wrapper>
    </>
  );
};

export default TelephoneBox;

const Wrapper = styled.div`
  position: relative;

  & input {
    padding-left: 4rem !important;
  }
`;

const Icon = styled.i`
  display: inline-block;
  position: absolute;
  z-index: 1;
  left: 45px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 18px;
`;
