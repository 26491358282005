import { Box, Typography } from '@mui/material';
import FeatureTeaser from '../../teaser/featureTeaser';
import { FieldsSet } from '../FieldsSet';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  defaultFields: { id: string; name: string }[];
  blockedFields: string[];
  onChange: (fieldId: string) => void;
  isEditable: boolean;
  showTeaser: boolean;
};

export const SubMenu = ({
  title,
  isEditable,
  showTeaser,
  blockedFields,
  defaultFields,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Box width='100%'>
      {showTeaser ? (
        <FeatureTeaser text={t('editRightsBlockedFieldsTeaser')} fullWidth />
      ) : (
        <Typography variant='body2' color='secondary'>
          {title}
        </Typography>
      )}
      <FieldsSet
        defaultFields={defaultFields}
        isEditable={isEditable}
        blockedFields={blockedFields}
        onChange={onChange}
        sx={{ width: '100%', mt: 2 }}
      />
    </Box>
  );
};
