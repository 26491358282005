import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/hooks';
import { clearApiResponse } from '../../../../../../../application/actions/md/profiles';
import { DeleteModeType } from '../../../../../../components/popups/delete-item-popup';

const usePopup = () => {
  const dispatch = useAppDispatch();

  const [isResponseUpdated, setResponseUpdate] = useState(false);
  const [showAddNewLink, setShowAddNewLink] = useState(false);
  const [showLinkTypePopup, setShowLinkTypePopup] = useState(null);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showExtendPopup, setShowExtendPopup] = useState(false);
  const [deleteMode, setDeleteMode] = useState<DeleteModeType>('');
  const selectedLinkRef = useRef(null);
  const apiResponse = useAppSelector(state => state.md.apiResponse);

  const showAddNewLinkPopup = () => setShowAddNewLink(true);

  const showCreateEditLinkPopup = item => {
    setResponseUpdate(false);
    selectedLinkRef.current = item;
    setShowAddNewLink(false);
    setShowCreatePopup(true);
  };

  const showExtendLinkPopup = item => {
    setShowExtendPopup(true);
    selectedLinkRef.current = item;
  };

  const onLinkTypeClick = linkType => {
    setShowLinkTypePopup(linkType);
    setShowAddNewLink(false);
  };

  const onCreateLinkBackClick = () => {
    setShowCreatePopup(false);
    setShowAddNewLink(true);
  };

  const onEditLinkTypeBackClick = () => {
    setShowLinkTypePopup(false);
    setShowAddNewLink(true);
  };

  const onDeleteLinkClick = () => {
    setShowCreatePopup(false);
    setDeleteMode('link');
    setShowDeletePopup(true);
  };

  // GENERIC CLOSE
  const onClosePopups = (): void => {
    setShowAddNewLink(false);
    setShowDeletePopup(false);
    setShowCreatePopup(false);
    setShowLinkTypePopup(false);
    setShowExtendPopup(false);
  };

  const checkForResponse = () => {
    if (apiResponse && apiResponse.isSuccess === true && isResponseUpdated === false) {
      setShowCreatePopup(false);
      setResponseUpdate(true);
      setShowDeletePopup(false);
      dispatch(clearApiResponse());
    }
  };

  return {
    showAddNewLink,
    showLinkTypePopup,
    showCreatePopup,
    showDeletePopup,
    showExtendPopup,
    deleteMode,
    selectedLinkRef,
    setShowLinkTypePopup,
    setShowCreatePopup,
    showAddNewLinkPopup,
    showCreateEditLinkPopup,
    showExtendLinkPopup,
    onCreateLinkBackClick,
    onLinkTypeClick,
    onEditLinkTypeBackClick,
    onDeleteLinkClick,
    onClosePopups,
    checkForResponse,
  };
};

export default usePopup;
