import { Dispatch, SetStateAction, useState } from 'react';
import { isFileValid } from '../../../../../../infrastructure/helper';
import cameraIcon from '../../../../../images/camera.svg';
import ImageCropper from '../../../../../components/image-cropper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import toast from 'react-hot-toast';

interface Props {
  profileImageUrl?: string;
  setProfileImageUrl?: Dispatch<SetStateAction<string>>;
  setProfileImageFile?: Dispatch<SetStateAction<File>>;
}

const ProfilePicture = (props: Props) => {
  let fileInput;

  const { t } = useTranslation();
  const [profileImage, setProfileImage] = useState('');

  const { profileImageUrl, setProfileImageUrl, setProfileImageFile } = props || undefined;

  const onImageClick = () => {
    fileInput.click();
  };

  const onImageSelection = async () => {
    const uploadedFile = fileInput.files[0];
    const extension = uploadedFile.name.split('.').pop();

    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        toast.error(errMsg);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);

      if (extension === 'gif') {
        setProfileImageFile(uploadedFile);
        reader.onload = () => setProfileImageUrl(reader.result as string);
      } else {
        reader.onload = () => setProfileImage(reader.result as string);
      }
    }
  };

  const onUploadClick = async croppedImageUrl => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    var file: File = new File([blob], fileInput.files[0].name);
    setProfileImage('');
    setProfileImageUrl(croppedImageUrl);
    setProfileImageFile(file);
  };

  return (
    <>
      {profileImage && (
        <ImageCropper
          image={profileImage}
          onUpload={url => onUploadClick(url)}
          onClose={() => setProfileImage('')}
          circularCrop
        />
      )}

      <ProfilePicWrapper>
        <ProfilePicCropper>
          <ProfilePicImage src={profileImageUrl} alt='Profile Pic' />
        </ProfilePicCropper>

        <EditProfilePicWrapper>
          <EditProfilePicImage src={cameraIcon} alt='Edit' onClick={() => onImageClick()} />
        </EditProfilePicWrapper>
      </ProfilePicWrapper>

      <input
        type='file'
        ref={input => {
          fileInput = input;
        }}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default ProfilePicture;

const ProfilePicWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const ProfilePicCropper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
`;

const ProfilePicImage = styled.img`
  width: 100%;
  height: auto;
`;

const EditProfilePicWrapper = styled.div`
  align-self: flex-end;
  background: #e7e7e7;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
  margin-left: -20px;
  z-index: 10;
`;

const EditProfilePicImage = styled.img`
  display: block;
  width: 25px;
  height: 25px;
  object-fit: contain;
  cursor: pointer;
`;

// TODO: ADD MEDIA QUERIES
