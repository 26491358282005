import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { AppBar, Divider, IconButton, Stack, Toolbar, Typography, alpha } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useProfileActions } from '@/infrastructure/hooks/Header/useProfileActions';
import { useAppSelector } from '@/application/hooks';
import { Account } from '@/shared/types/api';
import { Admin, Dropdown, UpgradeButton } from './components';
import { HeaderProps } from '@/infrastructure/hoc/types';
import { HeaderLogo } from './components/HeaderLogo';
import { useConsumeHeaderActions } from './useHeaderActions';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { CSS_VARS, routePaths } from '@/infrastructure/constants';
import { HelpDropdown } from './components/HelpDropdown';
import FreeNfcCardButton from './components/FreeNfcCardButton';

export const Header: React.FC<HeaderProps> = ({
  tTitle,
  hideTitleOnMobile,
  showLogoOnMobile,
  showBackButton,
  showShareButton,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t, InlineTranslation } = useAppTranslation();
  const account = useAppSelector<Account>(state => state.account);

  const { desktopView: isDesktop } = useDesktopView();

  const profileActions = useProfileActions();

  const { isMinEssentials, isTrial, isStarter } = useTierInfo();
  const showUpgradeButton =
    (isStarter || (isMinEssentials && isTrial)) &&
    (isDesktop || location.pathname === routePaths.SETTINGS.MORE);

  const showFreeNfcCardButton =
    (isStarter || isTrial) &&
    !isDesktop &&
    (location.pathname === routePaths.SETTINGS.MORE || location.pathname === routePaths.HOME);

  const title =
    typeof tTitle === 'function'
      ? t(tTitle())
      : tTitle
      ? t(tTitle)
      : `${t('homePage.hello') + ' ' + account.firstName}!`;

  const handleBackClick = () => {
    showBackButton.linkTo ? history.push(showBackButton.linkTo) : history.goBack();
  };
  const sidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  const { actions } = useConsumeHeaderActions();
  const actionsToRender = actions
    ?.filter(
      action =>
        (!action.mobileOnly || !isDesktop) && (!action.desktopOnly || isDesktop) && action.element,
    )
    .map(({ element }) => element);

  return (
    <AppBar
      position='fixed'
      sx={{
        borderRadius: 0,
        zIndex: 10,
        left: isDesktop
          ? sidebarCollapsed
            ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
            : CSS_VARS.LT_SIDEBAR_WIDTH_VAR
          : 0,
        right: 0,
        top: 0,
        width: 'auto',
      }}
    >
      <Toolbar
        sx={{
          height: isDesktop
            ? CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR
            : CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR,
        }}
      >
        {showBackButton && !(showBackButton.mobileOnly && isDesktop) && (
          <IconButton onClick={handleBackClick} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {(isDesktop || !hideTitleOnMobile) && <Typography variant='h1'>{title}</Typography>}

        {!isDesktop && showLogoOnMobile && (
          <HeaderLogo
            onClick={
              showLogoOnMobile.linkTo ? () => history.push(showLogoOnMobile.linkTo) : undefined
            }
            center={
              (!title || hideTitleOnMobile) &&
              !showUpgradeButton &&
              !showShareButton &&
              !Boolean(actionsToRender)
            }
          />
        )}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} />

        <Stack direction='row' spacing={1} alignItems='center'>
          {showUpgradeButton && <UpgradeButton isStarter={isStarter} />}
          {showFreeNfcCardButton && <FreeNfcCardButton />}
          {Boolean(actionsToRender) && actionsToRender}
          {isDesktop && (
            <>
              <InlineTranslation />
              <HelpDropdown />
              <Dropdown icon={<Admin />} actions={profileActions} />
            </>
          )}
        </Stack>
      </Toolbar>
      <Divider sx={theme => ({ borderColor: alpha(theme.palette.primary.main, 0.23) })} />
    </AppBar>
  );
};

export default Header;

export function BoxHeaderRelative() {
  const { desktopView: isDesktop } = useDesktopView();
  return (
    <Box
      sx={{
        height: isDesktop
          ? CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR
          : CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR,
      }}
    />
  );
}
