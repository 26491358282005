import { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { addCode } from '../../../application/actions/landing';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader, Popup } from '../../components/common';
import { Text } from '../../components/generic';

interface Props {
  code: string;
  onCloseClick: () => void;
}
const SuccessCodePopup = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { addCodeLoading, addCodeResponse } = useAppSelector(state => state.landing);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { code } = props;

  useEffect(() => {
    dispatch(addCode(code, getAccessTokenSilently));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {addCodeLoading && <Loader />}
      {!addCodeLoading && addCodeResponse.isSuccess && (
        <Popup onCloseClick={props.onCloseClick}>
          <AddCodeWrapper>
            <TextBlock>
              <Text
                text={
                  <>
                    <CongratsIcon role='img' aria-label='congrats-emoji'>
                      🎉
                    </CongratsIcon>{' '}
                    <br />
                    <br />
                    {t('addCodeSuccess')}
                  </>
                }
                variant='strong'
              />
            </TextBlock>
          </AddCodeWrapper>
        </Popup>
      )}
    </>
  );
};

export default SuccessCodePopup;

const AddCodeWrapper = styled.div``;

const TextBlock = styled.div`
  padding: 10px 0 30px 0;
`;

const CongratsIcon = styled.span`
  font-size: 4rem;
`;
