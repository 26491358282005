import React from 'react';
import styled from 'styled-components';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import advice from '../../../images/onboarding/advice.png';
import OnboardingHeading from '@/views/components/common/onboarding-heading';

interface Props {
  heading: string;
}

export const ScreenLastAdvice = (props: Props) => {
  const { t } = useAppTranslation();

  const text = [
    'onboarding.lastAdviceAboutNFC.pointOne',
    'onboarding.lastAdviceAboutNFC.pointTwo',
    'onboarding.lastAdviceAboutNFC.pointThree',
  ];

  return (
    <Wrapper>
      <OnboardingHeading pageHeading={props.heading} pageHeadingSecond={null} />
      <Div>
        <H4>{t('onboarding.importForNFC')}</H4>
        <ul>
          {text.map((item, idx) => (
            <li key={idx}>{t(item)}</li>
          ))}
        </ul>
      </Div>
      <ItalicText>
        <img src={advice} alt='' />
        {t('onboarding.advice')}
      </ItalicText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;
const Div = styled.div`
  background: #eff3fd;
  border-radius: 10px;
  padding: 30px;
  margin-top: 3rem;
  color: #181a5a;
  font-size: 18px;
  font-weight: 500;
  text-align: start;

  ul li {
    list-style: disc;
    list-style-position: inside;
    margin-left: 15px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 14px;
  }
`;

const H4 = styled.p`
  color: #181a5a;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const ItalicText = styled.div`
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 470px;

  margin: 100px 0;

  @media (max-width: 767px) {
    height: 87px;
    font-size: 15px;
  }
`;
