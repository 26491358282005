import React from 'react';
import styled from 'styled-components';

const SubHeading = (props: { text: string }): JSX.Element => {
  return (
    <Wrapper>
      <Text>{props.text}</Text>
    </Wrapper>
  );
};
export default SubHeading;

const Wrapper = styled.div`
  padding-bottom: 25px;
  @media (max-width: 360px) {
    padding-bottom: 13px;
  }
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
`;
