import React from 'react';
import styled from 'styled-components';

import { Text } from '.';

interface FooterProps {
  children: JSX.Element;
}

interface FooterTextProps {
  text: string;
}

interface FooterLinkProps {
  href: string;
  target?: string;
  children: JSX.Element;
}

export const Footer = (props: FooterProps) => {
  return <FooterContent>{props.children}</FooterContent>;
};

export const FooterText = (props: FooterTextProps) => {
  return <FooterTextContent text={props.text} />;
};

export const FooterLink = (props: FooterLinkProps) => {
  return (
    <FooterLinkContent href={props.href} target={props.target}>
      {props.children}
    </FooterLinkContent>
  );
};

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterTextContent = styled(Text)`
  line-height: 16px;
  color: #666666;
  text-align: center;
`;

const FooterLinkContent = styled.a`
  color: #02e0b1;
  text-decoration-line: underline;
  line-height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;
