import config from '../../../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../../../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { InputFormSaveProps } from '../../../../views/pages/md/profiles/common/inputs-form';
import { getAuth0TokenScopeString } from '@/util';
import { withSilentAccessToken } from '@/infrastructure/helper';

export interface UpdateEmployeeDataProps extends InputFormSaveProps {
  id: string;
  username: string;
  profileImageUrl: string;
  bannerImageUrl: string;
  logoHeaderUrl: string;
  defaultLinkId: number | undefined;
  links: [];
  files: [];
}

export interface AddEmployeeDataProps extends InputFormSaveProps {
  profileImageFile; //TODO: check type file it can be string
  bannerImageFile;
  bannerImageUrl;
  logoHeaderFile;
  id?: string;
  username?: string;
  links?;
  files?;
  unit?;
  authEmail?: string;
}

export interface toUpdateImagesType {
  profileImageFile: File;
  bannerImageFile: File;
  logoHeaderFile: File;
  bannerImageUrl: string;
  logoHeaderUrl: string | undefined;
}

export const _getEmployees = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  searchTerm: string,
  selectedColumn: string,
  unitId,
  page?: number,
  pageSize?: number,
  orderByParams?: { orderBy: string; sort: string },
  withoutNfc?: boolean,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.READ.THEME_PROFILES),
  });

  const response = await axios.get(config.API_BASE_URL + 'business/getEmployees', {
    params: {
      searchTerm,
      selectedColumn,
      unitId,
      page,
      pageSize,
      ...orderByParams,
      withoutNfc,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _addEmployee = async (
  data: AddEmployeeDataProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.CREATE.THEME_PROFILE),
  });

  const response = await axios.post(config.API_BASE_URL + 'business/addEmployee', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _updateEmployee = async (
  data: InputFormSaveProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(config.API_BASE_URL + 'business/updateEmployee', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _sendEmail = async (
  id: string,
  email: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = { id, email };

  const response = await axios.post(config.API_BASE_URL + 'business/sendEmail', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const checkCode = async (
  code: string,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.READ.CHECK_CODES),
  });

  const payload = { code };
  const response = await axios.post(config.API_BASE_URL + 'business/checkCode', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const updateImageUrl = async (
  accountId: string,
  imageUrl: string | readonly string[],
  type: 'banner' | 'profile' | 'logoHeader',
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}business/updateImageUrl`,
    {
      accountId,
      imageUrl,
      type,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const linkCodeToAccount = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  employeeId: string,
  code: string,
) => {
  return await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        `${config.API_BASE_URL}business/employees/${employeeId}/codes/${code}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
};
