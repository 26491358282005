import { updateAccount } from '../../infrastructure/apis/edit-bio';
import { dispatchTypes } from './index';
import { toast } from 'react-hot-toast';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { TFunction } from 'react-i18next';
import { Account } from '../../shared/types/api';

export const updateAccountDetails =
  (
    account: Account,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    t = null,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.EDITBIO.SAVE });
    try {
      const response = await updateAccount(account, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITBIO.SAVESUCCESS,
          apiResponse: result,
        });

        dispatch({
          type: dispatchTypes.COMMON.UPDATEBIODETAILS,
          account,
        });
        toast.success(t('EditBioSuccess'));
      } else {
        toast.error(t('error.editBio'));
        dispatch({
          type: dispatchTypes.EDITBIO.SAVEERROR,
          apiResponse: result,
        });
      }
    } catch (error) {
      toast.error(t('error.editBio'));
      dispatch({ type: dispatchTypes.EDITBIO.SAVEERROR, apiResponse: error });
    }
  };

export const clearApiResponse = () => async dispatch => {
  dispatch({ type: dispatchTypes.EDITBIO.CLEARAPIRESPONSE });
};

export const updateDefaultTemplate =
  (defaultAddressTemplate: number, t: TFunction) => async dispatch => {
    try {
      dispatch({
        type: dispatchTypes.COMMON.SETTHEMEDEFAULTADDRESSTEMPLATE,
        data: { defaultAddressTemplate },
      });
    } catch (error) {
      toast.error(t('error.defaultAddress'));
    }
  };
