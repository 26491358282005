import styled from 'styled-components';
import { invertHex } from '../../../../infrastructure/helper';
import { DESKTOP_BREAK_POINT } from '../../../../shared/constants';
import { CSS_VARS } from '@/infrastructure/constants';

export const WhitespaceContainer = styled.div`
  display: flex;
  flex-grow: 1;
  ${p => p.scroll && 'max-height: 100vh'};
  height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  min-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  max-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  ${props => props.isWhite && `background-color: white;`}
  ${props => props.isWhite && `position: relative;`}
`;

export const PreviewContainer = styled.div`
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #eff3fd;
`;

export const InteractionsContainer = styled.div`
  flex: 0.6;
  background-color: #ffffff;
  position: relative;
  overflow: auto;
  max-height: 72vh;
  transform: translateZ(0);
  @media (min-width: ${DESKTOP_BREAK_POINT}px) {
    max-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  } ;
`;

// GOOGLE: possible to create with onClick?
export const BackButton = styled.img`
  position: absolute;
  top: 5rem;
  left: 2.5rem;
  cursor: pointer;
`;

export const PaddedContainer = styled.div`
  padding: 4rem;
  padding-left: 8rem;
  width: 100%;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 80rem;

  /* gap property alternative */
  & > *:not(:last-child) {
    margin-bottom: 0.5vw;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;

  h4 {
    margin-right: 0.2vw;
  }
`;

export const SectionHeader = styled.h4`
  font-size: 1.2vw;
  font-weight: 700;
  color: #252525;
  display: inline-flex;
  align-items: center;
`;

export const SubsectionSpan = styled.span`
  color: #444444;
  font-size: 1.3rem;

  ${props => props.help && `cursor: help;`}

  ${props =>
    props.ellipsis &&
    `
  overflow: hidden;
  text-overflow: ellipsis;
  `}

  ${props =>
    props.marginBottom &&
    `
    margin-bottom: 0.5vw
  `}

  ${props =>
    props.bigMarginBottom &&
    `
  margin-bottom: 1.5vw
  `}

  ${props =>
    props.light &&
    `
    font-weight: 500;
  `}
`;

export const DescriptionHeader = styled.h4`
  font-size: 1.2vw;
  font-weight: 700;
  color: #000000;
`;

export const DescriptionP = styled.p`
  color: #666666;
  font-size: 0.9vw;
  font-weight: 500;
`;

const Button = styled.button`
  width: 45%;
  border-radius: 3rem;
  font-size: 1vw;
  font-weight: 500;
  padding: 1vw;
`;

export const PrimaryButton = styled(Button)`
  color: ${p =>
    p.bgColor ? invertHex(p.bgColor, true) : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  background: ${p =>
    p.bgColor ??
    `linear-gradient(
    90deg,
    ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} 0%,
    ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR} 100%
  )`};
  border: none;
`;

export const SecondaryButton = styled(Button)`
  color: #666666;
  background: transparent;
  border: 1px solid #bbbbc1;
`;

export const Spacer = styled.div`
  min-width: ${(props: { size: number }) => props.size}px;
  min-height: ${(props: { size: number }) => props.size}px;
`;

export const CustomHeightHolder = styled.div`
  display: block;
  min-height: ${(props: { height: number }) => props.height || '150px'};
`;

export const TogglesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80rem;
`;

export const ToggleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 600;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
