// unique error keys
export const ERROR_KEYS = {
  DISPOSABLE_EMAIL: 'disposable-email',
  DISPOSABLE_DOMAINS: 'disposable-domains',
  INVALID_EMAIL: 'invalid-email',
  INVALID_FIELDS_LENGTH: 'invalid-fields-length',
  INVALID_ADMINS_NUMBER: 'invalid-admins-number',
  INVALID_Unit_RULE: 'invalid-unit-rule',
  DUPLICATED_EMAILS: 'duplicated-emails',
  EXISTED_EMAILS: 'existed-emails',
  UNCHANGEABLE_SOCIAL_EMAIL: 'unchangeable-social-email',
  MISSING_PARAMS: 'missing-params',
  INVALID_UNIT: 'invalid-unit',
  NOT_EXISTED_ADDR_TEMPLATE: 'address-templates-not-exists',
  TRIAL_USERS_EXCEEDED: 'trial-users-exceeded',
  INVALID_CODE: 'invalid-code',
  AUTH0_JOB_ERROR: 'auth0-job-error',
  QR_ZIP_USERS_EXCEEDED: 'qr-zip-users-exceeded',
  TIMEOUT: 'timeout',
  S3_UPLOAD_ERROR: 's3-upload-error',
  LT2LT_INTERNAL_NOT_ALLOWED: 'lt2lt-not-allowed',
  TRANSLATIONS_UPLOAD_NO_DATA: 'translations-upload-no-data',
  TRANSLATIONS_UPLOAD_INVALID_FILE_TYPE: 'translations-upload-invalid-file-type',
  TRANSLATIONS_UPLOAD_READ_ERROR: 'translations-upload-failed-read',
  TRANSLATIONS_UPLOAD_INVALID_FILENAMES_OR_NO_PERMISSION:
    'translations-upload-invalid-filenames-or-no-permission',
  TRANSLATIONS_UPLOAD_FAILED_UNZIPPING: 'translations-upload-failed-unzipping',
  TRANSLATIONS_UPLOAD_CSV_PARSE_ERROR: 'translations-upload-csv-parse-error',
  TRANSLATIONS_UPLOAD_INVALID_KEYS: 'translations-upload-invalid-keys',
} as const;
