import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { IoIosClose } from 'react-icons/io';
import CloudUpload from '../../../../images/cloud-upload.svg';
import { isFileValid, isNotEmptyArray } from '../../../../../infrastructure/helper';

import { SubsectionSpan } from '../common';
import {
  ArrayImage,
  ClearImageButton,
  ImageSizeSpan,
  OneImageContainer,
  UploadBox,
} from './common';
import ImageCropper from '../../../../components/image-cropper';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { imageSizeValidator } from '@/views/components/qrcode-background/common/utils';
import {
  UPLOADED_IMG_LIMIT_MO,
  UPLOADED_IMG_LIMIT_VC,
} from '@/views/components/qrcode-background/common/constants';

interface Props {
  defaultMobileBg: string | File;
  setDefaultMobileBg: Dispatch<SetStateAction<File>>;
  defaultVideoCallBg: string | File;
  setDefaultVideoCallBg: Dispatch<SetStateAction<File>>;
}

const DefaultImages = (props: Props) => {
  const { t } = useTranslation();
  let fileInput = useRef(null);

  const { defaultMobileBg, defaultVideoCallBg, setDefaultMobileBg, setDefaultVideoCallBg } = props;

  type ImageType = 'mobile' | 'videocall';
  const [imageMode, setImageMode] = useState<ImageType | ''>('');
  const [showCropper, setShowCropper] = useState('');

  const [defaultMobileBgUrl, setDefaultMobileBgUrl] = useState(defaultMobileBg);
  const [defaultVideoCallBgUrl, setDefaultVideoCallBgUrl] = useState(defaultVideoCallBg);

  useEffect(() => {
    if (!(defaultMobileBg instanceof File)) setDefaultMobileBgUrl(defaultMobileBg);
  }, [defaultMobileBg]);

  useEffect(() => {
    if (!(defaultVideoCallBg instanceof File)) setDefaultVideoCallBgUrl(defaultVideoCallBg);
  }, [defaultVideoCallBg]);

  const onImageClick = (fileType: ImageType) => {
    setImageMode(fileType);
    fileInput.current.click();
  };

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => setShowCropper(reader.result as string);
        return;
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    // validate uploaded image size
    const error = await imageSizeValidator(croppedImageUrl, imageMode);

    if (error && error.length) {
      toast.error(error);
      return;
    }

    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = fileInput.current.files[0].name.split('.').pop();
    setShowCropper('');

    let file: File;

    switch (imageMode) {
      case 'mobile':
        file = new File([blob], `default-qr-mobile.${ext}`);
        setDefaultMobileBg(file);
        setDefaultMobileBgUrl(croppedImageUrl);
        break;
      case 'videocall':
        file = new File([blob], `default-qr-videocall.${ext}`);
        setDefaultVideoCallBg(file);
        setDefaultVideoCallBgUrl(croppedImageUrl);
        break;
    }
  };

  const handleX = (mode: ImageType) => {
    switch (mode) {
      case 'mobile':
        setDefaultMobileBg(null);
        setDefaultMobileBgUrl(null);
        break;
      case 'videocall':
        setDefaultVideoCallBg(null);
        setDefaultVideoCallBgUrl(null);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {showCropper && (
        <ImageCropper
          image={showCropper}
          onUpload={url => onUploadClick(url)}
          onClose={() => setShowCropper('')}
          noAspectRatio={true}
          fixedCrop={true}
          crop={{ width: 100, height: 100 }}
        />
      )}

      <ImagesContainer>
        <ImageContainer>
          <SubsectionSpan bigMarginBottom>{t('qrBgTab.defaultMobileBg')}</SubsectionSpan>
          {defaultMobileBgUrl ? (
            <OneImageContainer>
              <ArrayImage src={defaultMobileBgUrl} alt='defaultMobileQrImg' />
              <ClearImageButton onClick={() => handleX('mobile')}>
                <IoIosClose size={16} color='#4748EC' />
              </ClearImageButton>
            </OneImageContainer>
          ) : (
            <>
              <UploadBox onClick={() => onImageClick('mobile')}>
                <img src={CloudUpload} alt='upload' />
                <SubsectionSpan>{t('qrBgTab.uploadBg')}</SubsectionSpan>
              </UploadBox>
              <ImageSizeSpan>{t('qrBgTab.minBgSize', { x: UPLOADED_IMG_LIMIT_MO })}</ImageSizeSpan>
            </>
          )}
        </ImageContainer>
        <ImageContainer>
          <SubsectionSpan bigMarginBottom>{t('qrBgTab.defaultVideoCallBg')}</SubsectionSpan>
          {defaultVideoCallBgUrl ? (
            <OneImageContainer>
              <ArrayImage src={defaultVideoCallBgUrl} alt='defaultVideoCallQrImg' />
              <ClearImageButton onClick={() => handleX('videocall')}>
                <IoIosClose size={16} color='#4748EC' />
              </ClearImageButton>
            </OneImageContainer>
          ) : (
            <>
              <UploadBox onClick={() => onImageClick('videocall')}>
                <img src={CloudUpload} alt='upload' />
                <SubsectionSpan>{t('qrBgTab.uploadBg')}</SubsectionSpan>
              </UploadBox>
              <ImageSizeSpan>{t('qrBgTab.minBgSize', { x: UPLOADED_IMG_LIMIT_VC })}</ImageSizeSpan>
            </>
          )}
        </ImageContainer>
      </ImagesContainer>

      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default DefaultImages;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem 1rem 0rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 45%;

  &:not(:last-child) {
    margin-right: 1.5vw;
    margin-bottom: 1.5vw;
  }
`;
