import { BusinessCardScannerPage } from './BusinessCardScannerPage';
import { FeatureFlagGuard, FeatureSetGuard } from '@/components';
import teaserImage from '@/views/images/teaser/business-card-scanner.png';
import withNav from '@/infrastructure/hoc/withNav';
import { routePaths } from '@/infrastructure/constants';

const BusinessCardScanner = () => {
  return (
    <FeatureFlagGuard privateFlags={['businessCardScanner']}>
      <FeatureSetGuard
        feature='businessCardScanner'
        text='upgradeTeaser.feature.businessCardScanner'
        image={teaserImage}
      >
        <BusinessCardScannerPage />
      </FeatureSetGuard>
    </FeatureFlagGuard>
  );
};

export default withNav(
  BusinessCardScanner,
  {
    tTitle: 'businessCardScanner.title',
  },
  { activeScreen: routePaths.CONTACTS.SCANNER },
);
