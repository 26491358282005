import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const SelectedCountCounter = ({ total, selectedCount, onSelectAll, selectAllVisible }) => {
  const { t } = useTranslation();
  return (
    <Typography variant='h4'>
      <Box>
        {selectedCount === total
          ? t('leads.xAllSelected')
          : t('leads.xSelected', { count: selectedCount })}
      </Box>
      {selectAllVisible && (
        <StyledSelectAll onClick={onSelectAll}>
          {t('selectAllTexts.lead', { count: total })}
        </StyledSelectAll>
      )}
    </Typography>
  );
};

const StyledSelectAll = styled(Box)({
  fontStyle: 'italic',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});
