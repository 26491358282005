import { useState } from 'react';

import { routePaths } from '../../../infrastructure/constants';

import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useAuth0 } from '@auth0/auth0-react';
import withNav from '../../../infrastructure/hoc/withNav';

import { showCookieSidebar } from '@/util';
import { TextField, Typography } from '@mui/material';
import { LtDialog, PageContainer } from '@/components';
import toast from 'react-hot-toast';
import { deleteAccount } from '@/infrastructure/apis/account';
import Menu from './Menu';

const Settings = () => {
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const [deletePopup, showDeletePopup] = useState(false);
  const [languagePopup, showLanguagePopup] = useState(false);
  const [email, setEmail] = useState('');

  const { t, TranslationSelectDialog } = useAppTranslation();

  const onDeleteSelf = async () => {
    try {
      toast.loading(t('Loading'), { id: 'delete-toast' });
      await deleteAccount(getAccessTokenSilently, email);
      toast.success(t('deleteAccount.success'), { id: 'delete-toast' });
      setTimeout(() => {
        logout();
      }, 2000);
    } catch (error) {
      if (error?.response?.data?.error?.data?.reason === 'emailMismatch') {
        toast.error(t('deleteAccount.emailMismatch'), { id: 'delete-toast' });
      } else {
        toast.error(t('error.general'), { id: 'delete-toast' });
      }
    }
  };

  return (
    <PageContainer maxWidth='s'>
      <Menu
        mode='main'
        clickHandlers={{
          cookies: showCookieSidebar,
          'delete-self': () => showDeletePopup(true),
          'change-language': () => showLanguagePopup(true),
        }}
      />
      <LtDialog
        open={deletePopup}
        onClose={() => showDeletePopup(false)}
        onCancel={() => showDeletePopup(false)}
        title={t('deleteAccount.title')}
        size='sm'
        deleteAction={{
          onClick: onDeleteSelf,
          disabled: !email || email !== user.email,
        }}
        withActionDivider
      >
        <Typography variant='body2'>{t('deleteAccount.popupBody')}</Typography>
        <Typography variant='body2'>{t('deleteAccount.popupEmailLabel')}</Typography>
        <TextField
          fullWidth
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          sx={{ mt: 1 }}
        />
      </LtDialog>

      <TranslationSelectDialog show={languagePopup} setShow={showLanguagePopup} />
    </PageContainer>
  );
};

export default withNav(
  Settings,
  {
    tTitle: 'settings.main',
    showBackButton: {
      linkTo: null,
      mobileOnly: true,
    },
  },
  {
    activeScreen: routePaths.SETTINGS.MORE,
  },
);
