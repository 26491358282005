import { ThemeBoxStyle } from '@/shared/types/global';
import { Text } from '../../components/generic';
import Layout from '../../components/common/white-layout';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { useHistory } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

interface Props {
  bioText: string;
  boxStyle: ThemeBoxStyle;
}

const BioText = (props: Props) => {
  const { bioText, boxStyle } = props;
  const history = useHistory();
  const { t } = useAppTranslation();

  return (
    <Layout
      themeBoxStyle={boxStyle}
      actionButton={{
        text: t('editBio'),
        icon: <EditIcon />,
        onClick: () => history.push(routePaths.EDITBIO, { focus: 'bioText' }),
      }}
    >
      <Text text={bioText} color='#7a7a7a' lineHeight='2rem' multiline />
    </Layout>
  );
};

export default BioText;
