import { useHistory } from 'react-router-dom';

import { AppBar, Toolbar } from '@mui/material';

import { HeaderLogo } from './Header/components/HeaderLogo';
import { CSS_VARS } from '@/infrastructure/constants';

export const PublicHeader = ({ showLogo }) => {
  const history = useHistory();

  return (
    <AppBar
      position='sticky'
      sx={{
        borderRadius: 0,
        zIndex: 10,
      }}
    >
      <Toolbar sx={{ height: CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR }}>
        <HeaderLogo onClick={showLogo.linkTo ? () => history.push(showLogo.linkTo) : undefined} />
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
