import { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

import { Box, Card, CardActionArea, styled } from '@mui/material';
import { CameraswitchOutlined, FlashlightOnOutlined } from '@mui/icons-material';

const ActionButton = ({
  children,
  onClick,
  active,
}: {
  onClick: () => void;
  active?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Card variant='elevation' sx={active && { bgcolor: 'primary.main', color: 'white' }}>
      <CardActionArea onClick={onClick}>
        <Box
          height='4.8rem'
          width='4.8rem'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          {children}
        </Box>
      </CardActionArea>
    </Card>
  );
};

type Props = {
  onScan: (result: QrScanner.ScanResult) => void;
};

const QrCodeScanner = ({ onScan }: Props) => {
  let videoRef = useRef(null);
  const scanner = useRef<QrScanner | null>(null);

  const [cameras, setCameras] = useState<Array<{ id: string; label: string }>>([]);
  const [cameraIdx, setCameraIdx] = useState(0);
  const [hasFlash, setHasFlash] = useState(false);
  const [flashOn, setFlashOn] = useState(false);

  useEffect(() => {
    if (!videoRef.current || cameras.length === 0 || scanner.current) return;

    scanner.current = new QrScanner(
      videoRef.current,
      result => {
        onScan(result);
      },
      {
        returnDetailedScanResult: true,
        maxScansPerSecond: 2,
        preferredCamera: 'environment',
        highlightCodeOutline: true,
      },
    );

    scanner.current.setCamera(cameras[cameraIdx].id).then(async () => {
      const hasFlash = await scanner.current.hasFlash();
      setHasFlash(hasFlash);
    });
    scanner.current.start();
  }, [cameraIdx, cameras, onScan]);

  useEffect(() => {
    return () => {
      if (scanner.current) scanner.current.destroy();
    };
  }, []);

  useEffect(() => {
    QrScanner.listCameras(true).then(setCameras);
  }, []);

  const switchCamera = async () => {
    const nextIdx = (cameraIdx + 1) % cameras.length;
    setCameraIdx(nextIdx);
    await scanner.current?.turnFlashOff();
    await scanner.current?.setCamera(cameras[nextIdx].id);
    const hasFlash = await scanner.current.hasFlash();
    setHasFlash(hasFlash);
  };

  const handleFlashClick = async () => {
    try {
      const hasFlash = await scanner.current.hasFlash();
      if (!hasFlash) return;
      if (flashOn) {
        await scanner.current?.turnFlashOff();
      } else {
        await scanner.current?.turnFlashOn();
      }
      setFlashOn(!flashOn);
    } catch (error) {}
  };

  return (
    <>
      <ScannerVideo ref={videoRef} playsInline muted autoPlay />
      <Box
        sx={{ position: 'absolute', top: '1rem', right: '1rem', display: 'flex', gap: '1.6rem' }}
      >
        {hasFlash && (
          <ActionButton active={flashOn} onClick={() => handleFlashClick()}>
            <FlashlightOnOutlined />
          </ActionButton>
        )}
        {cameras.length > 1 && (
          <ActionButton onClick={switchCamera}>
            <CameraswitchOutlined />
          </ActionButton>
        )}
      </Box>
    </>
  );
};

export const ScannerVideo = styled('video')({
  width: '100%',
  '@media (max-width: 425px)': {
    height: '50vh',
    objectFit: 'cover',
  },
});

export default QrCodeScanner;
