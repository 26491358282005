import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { useTranslation } from 'react-i18next';
import MdAnalytics from './MdAnalytics';
import FeatureTeaser from '../teaser/featureTeaser';
import teaserImage from '../../../images/teaser/analytics-md.png';
import withNav from '@/infrastructure/hoc/withNav';

export const Page = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed } = useTierInfo();

  if (isFeatureAllowed(FEATURE.ANALYTICS_MD)) {
    return <MdAnalytics />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.mdAnalytics')} imageSrc={teaserImage} />;
  }
};

export default withNav(
  Page,
  {
    tTitle: 'mdAnalytics.sideBarLabel',
  },
  {},
);
