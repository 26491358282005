import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import mime from 'mime-types';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { flatten } from '@/shared/util/array';

const DEFAULT_MAX_SIZE = 1;

const BYTE_SIZE = 1000000;

type Props = {
  onChange: (acceptedFiles: File[]) => void;
  types: string[];
  maxSize?: number | ((mimetype: string) => number); //MB
  fullWidth?: boolean;
  description?: string;
};

const Dropzone = ({
  maxSize = DEFAULT_MAX_SIZE,
  onChange,
  types,
  fullWidth,
  description,
}: Props) => {
  const { t } = useTranslation();

  const handleSelect = (acceptedFiles: File[], rejections: FileRejection[]) => {
    if (rejections.length > 0) {
      const errors = Array.from(
        flatten(
          rejections.map(rejection =>
            rejection.errors.map((error: FileError & { meta: any }) => ({
              code: error.code,
              meta: error.meta,
            })),
          ),
        ),
      );

      const message = errors
        .map(({ code, meta }) =>
          t(`dropzone.errors.${code}`, {
            maxSize: meta?.maxSize,
            ext: meta?.ext,
            formats: types.map(x => `.${x}`).join(', '),
          }),
        )
        .join('\n');

      toast.error(message);
      return;
    }
    onChange(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleSelect,
    accept: types.reduce((acc, type) => {
      acc[mime.types[type]] = [type];
      return acc;
    }, {}),
    maxFiles: 1,
    // maxSize: maxSize * BYTE_SIZE,
    validator: (file): FileError & { meta: any } => {
      if (!types.some(t => mime.extension(file.type) === t)) return null; // don't show size error if file type rejected
      if (typeof maxSize === 'number' && file.size > maxSize * BYTE_SIZE) {
        return {
          code: 'file-too-large',
          message: 'File is too large',
          meta: { maxSize, ext: mime.extension(file.type) },
        };
      } else if (typeof maxSize === 'function' && file.size > maxSize(file.type) * BYTE_SIZE) {
        return {
          code: 'file-too-large',
          message: 'File is too large',
          meta: { maxSize: maxSize(file.type), ext: mime.extension(file.type) },
        };
      }
      return null;
    },
  });

  return (
    <Box>
      <Paper
        {...getRootProps()}
        sx={theme => ({
          py: 2,
          px: 4.5,
          borderStyle: 'dashed',
          m: 'auto',
          mb: 1,
          backgroundColor: isDragActive ? theme.palette.grey[200] : undefined,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          width: fullWidth ? '100%' : 'max-content',
        })}
        elevation={0}
        variant='outlined'
      >
        <div style={{ display: 'none' }}>
          <input {...getInputProps()} type='file' />
        </div>
        <IconButton
          sx={theme => ({
            backgroundColor: theme.palette.action.disabledBackground,
            mb: 1,
            '&:hover': {
              backgroundColor: theme.palette.action.disabledBackground,
            },
          })}
        >
          <UploadFile fontSize='medium' />
        </IconButton>
        <Typography sx={{ mb: 0.8 }} variant='body1'>
          {t('dropzone.title')}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {t('dropzone.subtitle', {
            formats: types
              .map(x => `.${x}`)
              .join(' / ')
              .toLowerCase(),
          })}
        </Typography>
      </Paper>
      {description && (
        <Typography color='secondary.main' variant='body2' sx={{ mb: 0.8 }} textAlign='center'>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default Dropzone;
