import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CrmConnectorType, CrmExportEvent, CrmExportEventStatus } from '@/shared/types/api';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';

export const ExportEventRenderer = ({
  event,
  crmConnectors,
}: {
  event: CrmExportEvent;
  crmConnectors: CrmConnectorType[];
}) => {
  const { t, activeLanguage } = useAppTranslation();
  const connector = crmConnectors.find(
    connector => connector.id === event.apideckConnection?.seviceId,
  );
  if (!connector) return null;

  const crm = connector.name;
  const exportDate = moment(event.createdOn).locale(activeLanguage).format('lll');

  const translationKey =
    event.status === CrmExportEventStatus.ERROR
      ? 'exportFailed'
      : event.status === CrmExportEventStatus.PENDING
      ? 'exportStarted'
      : 'exported';

  const tooltipText = t(`crmIntegration.${translationKey}`, { crm, datetime: exportDate });

  const statusIconStyles = {
    width: '1.75rem',
    height: '1.75rem',
    position: 'relative',
    right: '.5rem',
  };

  return (
    <Tooltip
      title={tooltipText}
      placement='top'
      PopperProps={{ sx: { whiteSpace: 'pre-wrap', textAlign: 'center' } }}
    >
      <Box position='relative' display='flex' alignItems='start'>
        <img
          style={{ width: '2.4rem', height: '2.4rem', padding: '.1rem' }}
          src={connector.iconUrl}
          alt={connector.name}
        />
        {event.status === CrmExportEventStatus.ERROR && (
          <ErrorIcon sx={statusIconStyles} color='error' />
        )}
        {event.status === CrmExportEventStatus.PENDING && (
          <PendingIcon sx={statusIconStyles} color='info' />
        )}
      </Box>
    </Tooltip>
  );
};
