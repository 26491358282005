import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import config from '../../../config/config';
import { FRESHWORKS } from '../../../infrastructure/constants';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import useEmbedScript from '../../../infrastructure/hooks/useEmbedScript';
import { Popup } from '../common';

interface Props {
  onClosed(): void;
  tTitle?: string;
}

export default function UpgradeRequestPopup(props: Props) {
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const activeLanguage = i18n.language.slice(0, 2);

  const {
    location: { pathname },
  } = useHistory();

  const informSales = useCallback(() => {
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + 'account/upgradeIntent',
        {
          pathname,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    );
  }, [getAccessTokenSilently, pathname]);

  useEmbedScript(
    FRESHWORKS?.[activeLanguage]?.contactFormUrl,
    ref,
    FRESHWORKS?.[activeLanguage]?.contactFormId,
    'anonymous',
    informSales,
  );

  return (
    <Popup
      headerText={t(props.tTitle || 'upgradeRequestPopup.title')}
      onCloseClick={props.onClosed}
    >
      <div ref={ref}></div>
    </Popup>
  );
}
