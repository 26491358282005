import { LeadAPI, LeadSortOption } from '@/shared/types/api';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  opened: boolean;
  onClose: () => void;
  options: LeadSortOption;
  onChange: (option: LeadSortOption) => void;
};

export const MobileSortDrawer = ({ opened, onClose, options, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <Drawer open={opened} onClose={onClose} anchor='right'>
      <Box p={2.4} display='flex' flexDirection='column' gap={2}>
        <Button
          sx={{ width: 'max-content', textTransform: 'none' }}
          size='small'
          variant='text'
          color='primary'
          startIcon={<Close />}
          onClick={onClose}
        >
          {t('close')}
        </Button>
        <FormControl>
          <FormLabel>{t('sortBy')}</FormLabel>
          <RadioGroup
            value={options.orderBy}
            onChange={({ target: { value } }) =>
              onChange({ ...options, orderBy: value as keyof LeadAPI })
            }
          >
            <FormControlLabel value='firstName' control={<Radio />} label={t('firstName')} />
            <FormControlLabel value='lastName' control={<Radio />} label={t('lastName')} />
            <FormControlLabel value='createdOn' control={<Radio />} label={t('leads.createDate')} />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>{t('sortOrder')}</FormLabel>
          <RadioGroup
            value={options.sort}
            onChange={({ target: { value } }) =>
              onChange({ ...options, sort: value as 'ASC' | 'DESC' })
            }
          >
            <FormControlLabel value='ASC' control={<Radio />} label={t('asc')} />
            <FormControlLabel value='DESC' control={<Radio />} label={t('desc')} />
          </RadioGroup>
        </FormControl>
      </Box>
    </Drawer>
  );
};
