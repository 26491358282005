import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  isLinkTypesLoading: false,
  links: [],
  showAddNewLinkBlock: false,
  selectedLink: {},
  apiResponse: {},
  fileUploadResponse: {},
  linkTypes: [],
};

const editProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.EDITPROFILE.HIDELOADER:
      return { ...state, isLoading: false };
    case dispatchTypes.EDITPROFILE.CLEARAPIRESPONSE:
      return { ...state, apiResponse: {} };
    case dispatchTypes.EDITPROFILE.DELETEFILE:
    case dispatchTypes.EDITPROFILE.SHOWLOADER:
      return { ...state, isLoading: true };
    case dispatchTypes.EDITPROFILE.SETLINKTYPESLOADING:
      return { ...state, isLinkTypesLoading: true };
    case dispatchTypes.EDITPROFILE.SAVELINKSUCCESS:
    case dispatchTypes.EDITPROFILE.SAVELINKERROR:
    case dispatchTypes.EDITPROFILE.REORDERLINKSSUCCESS:
    case dispatchTypes.EDITPROFILE.REORDERLINKSERROR:
    case dispatchTypes.EDITPROFILE.REORDERFILESSUCCESS:
    case dispatchTypes.EDITPROFILE.REORDERFILESERROR:
    case dispatchTypes.EDITPROFILE.DELETELINKSUCCESS:
    case dispatchTypes.EDITPROFILE.DELETELINKERROR:
    case dispatchTypes.EDITPROFILE.DELETEFILESUCCESS:
    case dispatchTypes.EDITPROFILE.DELETEFILEERROR:
    case dispatchTypes.EDITPROFILE.UPLOADFILESUCCESS:
    case dispatchTypes.EDITPROFILE.UPLOADFILEERROR:
    case dispatchTypes.EDITPROFILE.UPDATEIMGURLERROR:
    case dispatchTypes.EDITPROFILE.SETTHEMELINKTYPESERROR:
      return { ...state, isLoading: false, apiResponse: action.apiResponse };
    case dispatchTypes.EDITPROFILE.SETTHEMELINKTYPES:
      return { ...state, isLinkTypesLoading: false, linkTypes: action.themeLinkTypes };
    case dispatchTypes.EDITPROFILE.SAVEFILESUCCESS:
      return { ...state, File: action.File };

    case dispatchTypes.EDITPROFILE.SAVECOMPANYDETAILSLINK:
      return { ...state, links: action.links };
    default:
      return state;
  }
};

export default editProfileReducer;
