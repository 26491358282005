import { StaticProfileConfig } from '@/shared/types/api';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// TODO: profile page optimizations

interface Props {
  imprint?: StaticProfileConfig['footer']['imprint'];
  privacyPolicy?: StaticProfileConfig['footer']['privacyPolicy'];
  copyright?: string;
  showCookieSettingsButton?: boolean;
}

export default function Policies({
  imprint,
  privacyPolicy,
  copyright,
  showCookieSettingsButton,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      <PoliciesContainer>
        {imprint && (
          <LinkComponent href={imprint.url} target='_blank' rel='noopener noreferrer'>
            {imprint.label}
          </LinkComponent>
        )}

        {privacyPolicy && (
          <LinkComponent href={privacyPolicy.url} target='_blank' rel='noopener noreferrer'>
            {privacyPolicy.label}
          </LinkComponent>
        )}
        {showCookieSettingsButton && <CookieSettings />}
      </PoliciesContainer>
      {copyright && (
        <Copyright>
          {copyright} <br />
          {t('allRightsReservedPublic')}
        </Copyright>
      )}
    </>
  );
}

const PoliciesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const Copyright = styled.div`
  max-width: 80%;
  margin: auto;
  margin-bottom: 1rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
  color: #55595e;
  padding: 5px;
`;

const LinkComponent = styled.a`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #55595e;
  padding: 5px;
  cursor: pointer;
  &:focus-visible {
    outline: 2px solid;
  }
`;

const CookieSettings = () => {
  const { i18n } = useTranslation();
  return (
    <LinkComponent
      onClick={() => {
        window.UC_UI?.updateLanguage?.(i18n.language.substring(0, 2));
        window.UC_UI?.showSecondLayer?.();
      }}
    >
      <span style={{ marginLeft: '6px', fontSize: '1.4rem' }}>Cookies</span>
    </LinkComponent>
  );
};
