import { dispatchTypes } from '../../index';
import { updateImageUrl } from '../../../../infrastructure/apis/md/profiles';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import i18n from '../../../../config/i18n';

export const uploadImage =
  (
    flag: boolean = false,
    mode: 'add' | 'edit',
    id: string,
    username: string,
    file: File,
    type: 'banner' | 'profile' | 'logoHeader',
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    withToast: boolean = true,
  ) =>
  async dispatch => {
    const loaderId = `uploadImage${type}`;
    try {
      if (withToast) toast.loading(i18n.t('uploadingImage'), { id: loaderId });

      const url = await doS3UploadForBusiness(
        `${type}/${username}`,
        file,
        id,
        getAccessTokenSilently,
        type,
      );
      if (url) {
        const uploadResponse = await updateImageUrl(id, url, type, getAccessTokenSilently);
        if (uploadResponse.data.isSuccess) {
          if (mode === 'add')
            dispatch({
              type: dispatchTypes.ADDEMPLOYEE.UPLOADIMAGE,
              payload: { id, type, imgUrl: url },
            });

          if (mode === 'edit')
            dispatch({
              type: dispatchTypes.EDITEMPLOYEE.UPDATEIMAGE,
              payload: { id, type, imgUrl: url },
            });
          if (withToast) {
            if (type === 'banner') toast.success(i18n.t('bannerImageUpdated'), { id: loaderId });
            if (type === 'logoHeader') toast.success(i18n.t('logoImageUpdated'), { id: loaderId });
            if (type === 'profile') toast.success(i18n.t('profileImageUpdated'), { id: loaderId });
          }

          if (flag) {
            dispatch({
              type: dispatchTypes.COMMON.UPDATEFIELD,
              field: type !== 'logoHeader' ? `${type}ImageUrl` : `logoHeaderUrl`,
              value: url,
            });
            toast.error(i18n.t('error.uploadingImage'), { id: loaderId });
          }
        }
      }
    } catch (error) {
      dispatch({
        type: dispatchTypes.EDITEMPLOYEE.UPLOADFILEERROR,
        apiResponse: error,
      });
      toast.error(i18n.t('error.uploadingImage'), { id: loaderId });
    }
  };

export const updateImage =
  (
    flag: boolean = false,
    mode: 'add' | 'edit',
    id: string,
    imgUrl: string,
    type: 'banner' | 'logoHeader',
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    withToast: boolean = true,
  ) =>
  async dispatch => {
    const loaderId = `updateImage${type}`;
    try {
      if (withToast) toast.loading(i18n.t('updatingImage'), { id: loaderId });
      const uploadResponse = await updateImageUrl(id, imgUrl, type, getAccessTokenSilently);
      if (uploadResponse.data.isSuccess) {
        if (mode === 'add')
          dispatch({ type: dispatchTypes.ADDEMPLOYEE.UPLOADIMAGE, payload: { id, type, imgUrl } });
        if (mode === 'edit')
          dispatch({ type: dispatchTypes.EDITEMPLOYEE.UPDATEIMAGE, payload: { id, type, imgUrl } });
        if (withToast) {
          if (type === 'banner') toast.success(i18n.t('bannerImageUpdated'), { id: loaderId });
          if (type === 'logoHeader') toast.success(i18n.t('logoImageUpdated'), { id: loaderId });
        }
        if (flag) {
          dispatch({
            type: dispatchTypes.COMMON.UPDATEFIELD,
            field: type === 'banner' ? 'bannerImageUrl' : 'logoHeaderUrl',
            value: imgUrl,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: dispatchTypes.EDITEMPLOYEE.UPDATEIMGURLERROR,
        apiResponse: error,
      });
      toast.success(i18n.t('error.updatingImage'), { id: loaderId });
    }
  };
