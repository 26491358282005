import { Unit } from '@/shared/types/api/unit.type';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { useAppSelector } from '@/application/hooks';
import { ThemeInternal } from '@/shared/types/api';

type Props = {
  selectedUnitId: number | null;
  onChange(unitId: number | null): void;
  units: Unit[];
};

export const UnitSelector = ({
  selectedUnitId,
  onChange,
  units,
  ...formProps
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const themeInternal = useAppSelector<ThemeInternal>(state => state.account.theme.themeInternal);

  return (
    <FormControl fullWidth {...formProps} sx={{ width: '20rem' }} size='small'>
      <InputLabel id='unit-select-label'>{t('profilesSelectLabel')}</InputLabel>
      <Select
        labelId='unit-select-label'
        value={selectedUnitId || null}
        label={t('profilesSelectLabel')}
        onChange={e => onChange(e.target.value as number)}
      >
        <MenuItem value={-1}>{themeInternal.niceName}</MenuItem>
        {units.map(unit => (
          <MenuItem value={unit.id} key={unit.id}>
            {unit.niceName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
