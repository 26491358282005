import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { useTranslation } from 'react-i18next';
import teaserImage from '../../images/teaser/leadgen-individual.png';
import withNav from '@/infrastructure/hoc/withNav';
import MyLeads from './MyLeads';
import { routePaths } from '@/infrastructure/constants';
import FeatureTeaser from '../md/teaser/featureTeaser';
import UpgradeLeadgen from '../md/teaser/UpgradeLeadgen';

const Teaser = withNav(
  () => {
    const { t } = useTranslation();
    return (
      <FeatureTeaser text={t('upgradeTeaser.feature.leadgenIndividual')} imageSrc={teaserImage} />
    );
  },
  {
    tTitle: 'myContacts',
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
  },
);

const MyLeadsPage = withNav(
  MyLeads,
  {
    tTitle: 'myContacts',
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
    noHelpButton: true,
  },
);

export const Page = () => {
  const { isFeatureAllowed, isStarter } = useTierInfo();

  if (!isFeatureAllowed(FEATURE.LEAD_GEN_INDIVIDUAL) && isStarter) {
    // show upgrade page
    return <UpgradeLeadgen />;
  } else if (!isFeatureAllowed(FEATURE.LEAD_GEN_INDIVIDUAL)) {
    // show Feature teaser
    return <Teaser />;
  } else {
    return <MyLeadsPage />;
  }
};

export default Page;
