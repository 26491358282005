import React, { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AcademyCardRenderer } from './CardRenderer';
import Box from '@mui/system/Box';

const AcademyCardUI = React.lazy(() => import('./content'));

export default function AcademyCardWrapper(props: React.ComponentProps<typeof AcademyCardUI>) {
  return (
    <Suspense
      fallback={
        <AcademyCardRenderer handleClick={props.onUpgradeClick}>
          <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
            <CircularProgress />
          </Box>
        </AcademyCardRenderer>
      }
    >
      <AcademyCardUI {...props} />
    </Suspense>
  );
}
