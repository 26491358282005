import { routePaths } from '@/infrastructure/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { ContactsOutlined, EditOutlined, HomeOutlined, SendOutlined } from '@mui/icons-material';
import { FEATURE } from '@/shared/constants';
import { NavItem } from '@/infrastructure/hoc/types';

export const useProfileItems = (): NavItem[] => {
  const { isFeatureAllowed } = useTierInfo();

  return [
    {
      to: routePaths.HOME,
      icon: HomeOutlined,
      text: 'home',
    },
    {
      to: routePaths.EDITPROFILE,
      icon: EditOutlined,
      text: 'editProfile',
    },
    {
      to: routePaths.SHARE_PROFILE,
      icon: SendOutlined,
      text: 'shareProfile.text',
    },
    {
      to: routePaths.CONTACTS.ROOT,
      icon: ContactsOutlined,
      text: 'myContacts',
      badge: !isFeatureAllowed(FEATURE.LEAD_GEN_INDIVIDUAL) ? 'Teams' : undefined,
    },
  ];
};
