import { FontWeight } from '@/shared/types/api';
import { Box, SxProps } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
  content: string;
};

const MarkdownRenderer = ({ content }: Props) => {
  if (!content) return null;
  return (
    <Box sx={markDownContainer}>
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{ a: MarkdownLinkRenderer, p: MarkdownParagraphRenderer }}
      >
        {content}
      </Markdown>
    </Box>
  );
};

export default MarkdownRenderer;

function MarkdownLinkRenderer(props: JSX.IntrinsicElements['a']) {
  return (
    <a href={props.href} target='_blank' rel='noreferrer'>
      {props.children}
    </a>
  );
}

function MarkdownParagraphRenderer(props: JSX.IntrinsicElements['p']) {
  return <p style={{ marginBottom: '1rem' }}>{props.children}</p>;
}

const markDownContainer: SxProps = {
  color: 'text.primary',
  '*': {
    fontSize: '1.4rem',
  },
  h1: {
    fontWeight: FontWeight.Medium,
    fontSize: '2rem',
  },
  h2: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.8rem',
    marginBottom: '.5rem',
  },
  h3: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.6rem',
    marginBottom: '.5rem',
  },
  h4: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.4rem',
    marginBottom: '.5rem',
  },
  h5: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.2rem',
    marginBottom: '.5rem',
  },
  h6: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.0rem',
    marginBottom: '.5rem',
  },
  a: {
    color: 'primaryButton.main',
    textDecoration: 'underline',
  },
  ul: {
    marginTop: '1rem',
    paddingLeft: '1.5rem',
    li: {
      listStyle: 'disc',
      marginBottom: '1rem',
    },
  },
};
