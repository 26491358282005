import { BlockAttributeConfigurationManager } from 'easy-email-extensions';

import {
  createCustomBlock,
  IBlockData,
  components,
  BasicType,
  BlockManager,
  IPage,
  AdvancedType,
} from 'easy-email-core';
import { RightPanelTeaser } from './RightPanelTeaser';
const { Image } = components;

const merge = (target, source) => {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
        if (!target[key] || typeof target[key] !== 'object' || Array.isArray(target[key])) {
          target[key] = {};
        }
        merge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
};

export enum CustomType {
  GOOGLE_WALLET_ONLINE_DE = 'GOOGLE_WALLET_ONLINE_DE',
  GOOGLE_WALLET_ONLINE_EN = 'GOOGLE_WALLET_ONLINE_EN',
  GOOGLE_WALLET_OFFLINE_DE = 'GOOGLE_WALLET_OFFLINE_DE',
  GOOGLE_WALLET_OFFLINE_EN = 'GOOGLE_WALLET_OFFLINE_EN',
}

const googleWallet = (type: 'online' | 'offline', lang: 'de' | 'en') =>
  createCustomBlock({
    name: `Google Wallet (${lang.toUpperCase()}) - ${type}`,
    type: CustomType[`GOOGLE_WALLET_${type.toUpperCase()}_${lang.toUpperCase()}`],
    create(payload) {
      const defaultData: IBlockData = {
        type: CustomType[`GOOGLE_WALLET_${type.toUpperCase()}_${lang.toUpperCase()}`],
        data: {
          value: {},
        },
        attributes: {},
        children: [],
      };
      return merge(defaultData, payload);
    },
    validParentType: [BasicType.COLUMN, AdvancedType.COLUMN],
    render({
      data,
      context,
    }: {
      data: IBlockData;
      idx: string | null;
      mode: 'testing' | 'production';
      context?: IPage;
      dataSource?: { [key: string]: any };
    }) {
      return (
        <Image
          href={type === 'online' ? '$PASS_URL_ONLINE$' : '$PASS_URL_OFFLINE$'}
          width='215px'
          padding='8px'
          src={
            lang === 'de'
              ? 'https://lemontaps-dev.s3.eu-central-1.amazonaws.com/add-to-google-wallet-de.png'
              : 'https://lemontaps-dev.s3.eu-central-1.amazonaws.com/add-to-google-wallet-en.png'
          }
        />
      );
    },
  });

export const customizeBlocks = (hasFullAccess: boolean) => {
  const DefaultPageConfigPanel: React.FC<{ hideSubTitle: boolean }> =
    BlockAttributeConfigurationManager.get(BasicType.PAGE);

  BlockManager.registerBlocks({
    [CustomType.GOOGLE_WALLET_ONLINE_DE]: googleWallet('online', 'de'),
    [CustomType.GOOGLE_WALLET_ONLINE_EN]: googleWallet('online', 'en'),
    [CustomType.GOOGLE_WALLET_OFFLINE_DE]: googleWallet('offline', 'de'),
    [CustomType.GOOGLE_WALLET_OFFLINE_EN]: googleWallet('offline', 'en'),
  });

  if (hasFullAccess) {
    BlockAttributeConfigurationManager.add({
      [BasicType.PAGE]: () => <DefaultPageConfigPanel hideSubTitle />,
    });
  } else {
    const map = BlockAttributeConfigurationManager.getMap();
    BlockAttributeConfigurationManager.add(
      Object.fromEntries(Object.keys(map).map(key => [key, RightPanelTeaser])),
    );
  }
};
