import styled from 'styled-components';
import { BiError } from 'react-icons/bi';
import { Spacer } from '../../pages/md/customization/common';

import { TextField } from '../../components/generic';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import { ErrorInfo, useCallback, useEffect, useState } from 'react';
import { Button } from '../../components/common';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../../config/config';

import { invertHex, withSilentAccessToken } from '../../../infrastructure/helper';
import { useAppSelector } from '../../../application/hooks';
import { Toaster, toast } from 'react-hot-toast';
import { DEFAULT_COLOR, routePaths } from '../../../infrastructure/constants';
import { Account } from '@/shared/types/api';
import { useTranslation } from 'react-i18next';

interface Props {
  errorId: string;
  error: Error;
  errorInfo: ErrorInfo;
}

const DesignError = (props: Props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [moreFields, setMoreFields] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const account = useAppSelector<Account>(state => state.account);
  const MainError = props.error.toString();
  const ErrorStacktrace = props.errorInfo.componentStack;

  const SubmitError = useCallback(
    async (userDetails: boolean, textMessage: string) => {
      if (!props.error) return;
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.post(
            config.API_BASE_URL + 'monitor/error',
            {
              errorId: props.errorId,
              browser: (navigator?.userAgent || '').toString(),
              href: window.location.href,
              error: MainError || 'Error',
              errorStackTrace: ErrorStacktrace || 'Error stack not detected',
              accountId: account.id,
              message: (userDetails && textMessage) || undefined,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),
        [],
        () => {
          if (userDetails) toast.loading(t('Loading'), { id: 'error-toast' });
        },
      )
        .then(res => {
          if (userDetails) {
            if (res.data?.isSuccess) {
              toast.success(t('errorMessageSentSuccess'), { id: 'error-toast' });
            } else {
              toast.error(t('unexpectedError'), { id: 'error-toast' });
            }
          }
        })
        .catch(() => {
          if (userDetails) toast.error(t('unexpectedError'), { id: 'error-toast' });
        });
    },
    [ErrorStacktrace, MainError, account.id, getAccessTokenSilently, props.error, props.errorId, t],
  );
  useEffect(() => {
    SubmitError(false, '');
  }, [SubmitError]);

  return (
    <>
      <MainContainer>
        <Toaster />
        <Container>
          <TextWrapper>
            <BiError size={100} color={'#f4b82d'} />
            <Spacer size={20} />
            {t('Oops')}
          </TextWrapper>
          <Spacer size={25} />
          <SmallText>{t('anErrorHasOccured')}</SmallText>

          <Spacer size={15} />
          <Button
            onClick={() =>
              (window.location.href =
                window.location.origin +
                (window.location.pathname === routePaths.HOME
                  ? routePaths.EDITPROFILE
                  : routePaths.HOME))
            }
            text={t('goHome')}
            styles={{ backgroundColor: DEFAULT_COLOR, color: invertHex(DEFAULT_COLOR, true) }}
          />
          <Spacer size={20} />
          {!moreFields && (
            <MoreFields onClick={() => setMoreFields(!moreFields)}>
              <MdOutlineArrowDropDown size={25} />
              {t('more')}
            </MoreFields>
          )}
          {moreFields && (
            <>
              <FieldsContainer>
                <MoreFields onClick={() => setMoreFields(!moreFields)}>
                  <MdOutlineArrowDropUp size={25} />
                  {t('less')}
                </MoreFields>
                <MoreFields>
                  <Spacer size={15} />

                  <TextField
                    label={t('message')}
                    name='ToTechnicalTeam'
                    angles='normal'
                    multiline={true}
                    font={{ size: '18px', weight: '500' }}
                    onChange={e => setTextMessage(e.target.value)}
                  />
                </MoreFields>
              </FieldsContainer>

              <Button
                onClick={() => SubmitError(true, textMessage)}
                text={t('submitText')}
                styles={{ backgroundColor: DEFAULT_COLOR, color: invertHex(DEFAULT_COLOR, true) }}
              />
            </>
          )}
        </Container>
      </MainContainer>
    </>
  );
};

export default DesignError;

const Container = styled.div`
  display: flex;

  flex-direction: column;
  min-height: 100vh;
  max-width: min(90vw, 75rem);
  justify-content: center;
  align-items: center;
`;

const FieldsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: large;
  padding-top: 70px;
`;
const SmallText = styled.p`
  font-size: small;
  text-align: center;
`;
const MoreFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: small;
  cursor: pointer;
  color: #8f8f8f;
  width: min(90vw, 75rem);
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
`;
