import React, { SetStateAction } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { FiTrash } from 'react-icons/fi';
import { BsCheck2All } from 'react-icons/bs';
import { TFunction } from 'i18next';
import { Button, ButtonWrapper } from '../../pages/md/profiles/common/styles';

interface Props {
  codeExists: boolean;
  onScanTrigger: () => SetStateAction<boolean>;
  onResetCode: () => SetStateAction<boolean | string | number>;
  t: TFunction;
}

const ConnectBtn = (props: Props) => {
  const { codeExists, onScanTrigger, onResetCode, t, ...restProps } = props;
  return (
    <ButtonWrapper codeExists={codeExists} {...restProps}>
      {!codeExists ? (
        <Button onClick={onScanTrigger} codeExists={codeExists}>
          <BiPlusCircle />
          {t('codeRead.btnText')}
        </Button>
      ) : (
        <>
          <Button codeExists={codeExists}>
            <BsCheck2All />
            {t('codeRead.btnSuccessText')}
          </Button>
          <FiTrash onClick={onResetCode} />
        </>
      )}
    </ButtonWrapper>
  );
};

export default ConnectBtn;
