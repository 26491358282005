import { useMemo } from 'react';
import editIcon from '../../../../../../images/edit.svg';
import trashIcon from '../../../../../../images/trash.svg';
import styled from 'styled-components';
import { Link } from '../../../../../../../shared/types/api/link.type';
import { BoxWrapper, Name, ImagesBox } from '../../../../../edit-profile/sharedUIComponets';
import { useTranslation } from 'react-i18next';
import { NoEditIconWrapper } from '../../../../../edit-profile/link-box-themed';
import TooltipInfo from '../../../../../../components/generic/TooltipInfo';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';

interface Props {
  link: Link;
  onEditClick?: (link: Link) => void;
  onDeleteClick?: (link: Link) => void;
  hideOptions?: boolean;
  isThemeEntity?: boolean;
  blueBackground?: boolean;
}

const CompanyDetailsLinkBoxThemed = (props: Props): JSX.Element => {
  const isTemplated = useMemo(
    () => typeof props.link.linkTemplateId === 'number',
    [props.link?.linkTemplateId],
  );

  const { t } = useTranslation();
  let { link, hideOptions } = props;
  const displayName = link?.name;
  const { profileDesign } = useProfileDesign();
  return (
    <>
      <StyledBoxWrapper boxStyle={profileDesign.boxStyle} blueBackground={props.blueBackground}>
        <LinkIcon>
          <img
            src={
              // @ts-ignore:next-line
              link.iconColorUrl ||
              // @ts-ignore:next-line
              link.themeLinkType?.linkType?.iconColorUrl ||
              link?.linkType?.iconColorUrl
            }
            alt='link icon'
          />
        </LinkIcon>
        <StyledName>{displayName}</StyledName>
        {!hideOptions && (
          <StyledImagesBoxes>
            {isTemplated ? (
              <TooltipInfo onHover={true} text={t('explainTemplatedLink')}>
                <img
                  src={trashIcon}
                  alt=''
                  onClick={() => props.onDeleteClick && props.onDeleteClick(link)}
                />
              </TooltipInfo>
            ) : (
              <img
                src={editIcon}
                alt=''
                onClick={() => props.onEditClick && props.onEditClick(link)}
              />
            )}
          </StyledImagesBoxes>
        )}
        {props.isThemeEntity && (
          <StyledImagesBoxes>
            <TooltipInfo text={t('explainThemeLinkToAdmin')} onHover={true}>
              <NoEditIconWrapper>
                <img src={editIcon} alt='' />
              </NoEditIconWrapper>
            </TooltipInfo>
          </StyledImagesBoxes>
        )}
      </StyledBoxWrapper>
    </>
  );
};

export default CompanyDetailsLinkBoxThemed;

const StyledBoxWrapper = styled(BoxWrapper)`
  @media (max-width: 360px) {
    font-size: 11px;
  }
  ${props => props.blueBackground && 'background-color: #eff3fe'};
  ${props => props.bulkEdit && `margin-top: unset !important;`}
`;

const StyledName = styled(Name)`
  @media (max-width: 1024px) {
    width: 49%;
  }

  @media (max-width: 320px) {
    width: 40%;
  }

  @media (max-width: 280px) {
    width: 30%;
  }
`;

const LinkIcon = styled.div`
  width: 10%;
  max-width: 3rem;
  display: grid;
  place-items: center;

  & > img {
    width: 100%;
  }
`;

const StyledImagesBoxes = styled(ImagesBox)`
  & > img {
    @media (max-width: 1024px) {
      padding-right: 10px;
    }
  }
`;
