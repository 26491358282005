import {
  THEME_CONFIG_KEYS,
  THEME_FILE_EDIT_MODES,
  THEME_LINK_EDIT_MODES,
} from '@/shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { fetchThemeConfig } from '../apis/md/customization/jsonConfigs';
import { EditRightsConfig } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';

const initialValues = {
  detailsEditable: false,
  profileImageEditable: false,
  bannerImageEditable: false,
  linkEditMode: THEME_LINK_EDIT_MODES.DISALLOW,
  fileEditMode: THEME_FILE_EDIT_MODES.DISALLOW,
  allowOwnQrBgImage: false,
  rebuyCards: false,
  profileShare_uidParam: false,
};

const EditRightsContext = React.createContext<{
  editRights: EditRightsConfig;
  refetch(): void;
}>({
  editRights: { ...initialValues },
  refetch: () => {},
});

export const EditRightsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const domainVerified = useAppSelector(state => state.login.domainVerified);
  const { getAccessTokenSilently } = useAuth0();
  const [editRights, setEditRights] = React.useState<EditRightsConfig>({ ...initialValues });

  const fetchEditRights = React.useCallback(async () => {
    try {
      const { value: editRights } = await fetchThemeConfig(
        getAccessTokenSilently,
        THEME_CONFIG_KEYS.EDIT_RIGHTS,
      );
      setEditRights(editRights);
    } catch {
      //do nothing
    }
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    if (domainVerified) fetchEditRights();
  }, [fetchEditRights, domainVerified]);

  return (
    <EditRightsContext.Provider
      value={{
        editRights,
        refetch: fetchEditRights,
      }}
    >
      {children}
    </EditRightsContext.Provider>
  );
};

export const useEditRights = () => {
  const context = React.useContext(EditRightsContext);
  if (context === undefined) {
    throw new Error('useEditRights must be used within a EditRightsProvider');
  }
  return context;
};
