import { HierarchyLevel } from '@/shared/types/api';
import { Unit } from '@/shared/types/api/unit.type';
import { createContext } from 'react';
type SettingsContextProps = {
  selectedUnit: Unit | null;
  setSettingsHierarchyLevel: (level: HierarchyLevel) => void;
  settingsHierarchyLevel: HierarchyLevel | null;
  deleteButtonIsVisible: boolean;
  deleteClickHandler: (callback: () => void) => void;
  setSettingsLoading: (loading: boolean) => void;
  canEdit: boolean;
};

export const SettingsContext = createContext<SettingsContextProps>({
  selectedUnit: null,
  setSettingsHierarchyLevel: () => {},
  settingsHierarchyLevel: null,
  deleteButtonIsVisible: false,
  deleteClickHandler: () => {},
  setSettingsLoading: (loading: boolean) => {},
  canEdit: false,
});
