import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import EditEmployee from '../edit-employee';
import BulkEdit from '../bulk-edit';
import useEdit from './hooks/useEdit';
import useBulk from './hooks/useBulk';
import useSelection from './hooks/useSelection';
import TableUi, { TableUiHandle } from './components/table';
import { Employee } from '../../../../../shared/types/api/employee.type';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ApiFile, Link, ThemeInternal } from '../../../../../shared/types/api';
import { Loader, Popup } from '../../../../components/common';
import { _bulkDelete } from '../../../../../infrastructure/apis/md/profiles/bulk';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../../../../../application/hooks';
import { getEmployees } from '../../../../../application/actions/md/profiles';
import { Unit } from '../../../../../shared/types/api/unit.type';
import { getUnits } from '../../../../../infrastructure/apis/md/units';
import { FEATURE } from '../../../../../shared/constants';
import useTierInfo from '../../../../../infrastructure/hooks/useTierInfo';
import { ColumnDefinition } from './utils/constants';
import { getMainUnitSync } from '@/shared/util';
import usePagination from './hooks/usePagination';

export interface TableProps {
  employees: Employee[];
  tableRef: MutableRefObject<TableUiHandle>;
  SelectedObjects: {
    selectedLinksProps: {
      selectedLink: Link;
      setSelectedLink: Dispatch<SetStateAction<Link>>;
    };
    selectedFilesProps: {
      selectedFile: ApiFile;
      setSelectedFile: Dispatch<SetStateAction<ApiFile>>;
    };
  };
  refreshEmployees: () => void;
  orderBy?: string | null;
  sort?: 'asc' | 'desc' | null;
  onColumnClick?: (column: ColumnDefinition['key']) => void;
}

const Table = (props: TableProps) => {
  const { isFeatureAllowed } = useTierInfo();
  const paginationProps = usePagination();
  const { page, rowsPerPage } = paginationProps;
  const selectionProps = useSelection(props.employees, page, rowsPerPage);

  const { selectedIds, selectedUsernames, unselectAllEmployees, selectedEmployees } =
    selectionProps;
  const dispatch = useAppDispatch();
  const editProps = useEdit();
  const { editEmployee, employee, onBackClick } = editProps;
  const { user } = useAuth0();
  const bulkProps = useBulk();
  const { bulkEdit, bulkEditOff } = bulkProps;
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const bulkDelete = () => setShowDeletePopup(true);
  const [units, setUnits] = useState<Unit[]>([]);
  const themeAdmin = user['https://lemontaps.com/claim/roles'].includes('theme_admin');

  const { orderBy, sort, onColumnClick } = props;

  useEffect(() => {
    if (themeAdmin && isFeatureAllowed(FEATURE.UNITS)) {
      getUnits(getAccessTokenSilently)
        .then(res => {
          setUnits(res.data.data.units);
        })
        .catch(err => {
          toast.error(t('mdLeads.errorReadUnits'));
          setUnits([]);
        });
    }
  }, [getAccessTokenSilently, isFeatureAllowed, t, themeAdmin]);

  const themeInternal = useAppSelector<ThemeInternal>(state => state.account?.theme?.themeInternal);

  const performDelete = async () => {
    setShowDeletePopup(false);

    // prevent deleting main account
    if (themeInternal?.mainAccountId && selectedIds.includes(themeInternal?.mainAccountId)) {
      return toast.error(t('md.profiles.error.deleteMainAccount'));
    }

    setIsLoading(true);
    try {
      const response = await _bulkDelete(selectedIds, getAccessTokenSilently);
      if (response.data.isSuccess) {
        dispatch(
          getEmployees(
            getAccessTokenSilently,
            '',
            '',
            t,
            {
              withToast: true,
              onSuccess: () =>
                toast.success(t('successfullyUsersDeleted', { count: selectedIds.length })),
              onFail: () =>
                toast.success(t('successfullyUsersDeleted', { count: selectedIds.length })),
            },
            undefined,
            { orderBy, sort },
          ),
        );
        unselectAllEmployees();
      } else {
        toast.error(t('errorDeletingUsers', { count: selectedIds.length }));
      }
    } catch (error) {
      toast.error(t('errorDeletingUsers', { count: selectedIds.length }));
    }
    setIsLoading(false);
  };

  const combinedProps = {
    ...bulkProps,
    ...editProps,
    ...selectionProps,
    bulkDelete,
  };

  const unitIds = useMemo(() => {
    const unitIds = selectedEmployees?.map(e => getMainUnitSync(e)?.id || null) || [];
    return Array.from(new Set(unitIds));
  }, [selectedEmployees]);

  return (
    <>
      {bulkEdit && (
        <BulkEdit
          employeeIds={selectedIds}
          usernames={selectedUsernames}
          onBackClick={bulkEditOff}
          unitIds={unitIds}
        />
      )}

      {editEmployee && employee && <EditEmployee employee={employee} onBackClick={onBackClick} />}
      {isLoading ? (
        <Loader />
      ) : (
        <TableUi
          ref={props.tableRef}
          employees={props.employees}
          {...combinedProps}
          SelectedObjects={props.SelectedObjects}
          units={units}
          refreshEmployees={props.refreshEmployees}
          onColumnClick={onColumnClick}
          orderBy={orderBy}
          sort={sort}
          paginationProps={paginationProps}
        />
      )}
      {showDeletePopup && (
        <Popup
          onCloseClick={() => setShowDeletePopup(false)}
          headerText={t('deleteAccounts', { count: selectedIds.length })}
          btn={{
            type: 'reset',
            text: t('no'),
          }}
          secondBtn={{
            type: 'button',
            text: t('deleteIt'),
            onClick: performDelete,
          }}
          bodyText={t('deleteBulkMessageConfirmation', { count: selectedIds.length })}
        />
      )}
    </>
  );
};

export default Table;
