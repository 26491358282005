import { useEffect, useRef } from 'react';
import Back from '../back';
import OnboardingHeading from '../onboarding-heading';
import lemonTapsLogo from '@/views/images/lemontaps-logo.png';
import styled, { css } from 'styled-components';

import wallet from '@/views/images/wallet.png';
import lemon from '@/views/images/onboarding/image-3.png';

import { useTranslation } from 'react-i18next';
import { Spacer } from '@/views/pages/md/customization/common';
import { useAppSelector } from '@/application/hooks';
import { Theme } from '@/shared/types/api';

interface Props {
  children: JSX.Element;
  back: () => void;
  pageHeading: string;
  pageHeadingSecond: string;
  backPath?: boolean;
  preHeader?: JSX.Element;
  changeImage?: boolean;
  skip?: () => void;
  skipPath?: boolean;
  useColor?: boolean;
  screenNumber?: number;
}

const OnboardingContainer = (props: Props): JSX.Element => {
  const {
    children,
    back,
    pageHeading,
    pageHeadingSecond,
    backPath,
    skipPath,
    skip,
    useColor,
    screenNumber,
  } = props;
  const scrollRef = useRef<HTMLDivElement>(null);

  const theme = useAppSelector<Theme>(state => state.account.theme);

  useEffect(() => {
    if (scrollRef && scrollRef?.current) scrollRef.current.scrollTo({ top: 0 });
  }, [screenNumber]);

  const RenderHeader = () => (
    <Header>
      <HeaderMobile>{props.preHeader}</HeaderMobile>
      <RowDiv spaceBetween>
        <BackContainer visible={backPath}>
          <Back onClick={back} isButton />
        </BackContainer>
        <HeaderDesktop>{props.preHeader}</HeaderDesktop>
        <SkipButton visible={skipPath} onClick={skip}>
          {t('skip')}
        </SkipButton>
      </RowDiv>
      <Spacer size={24} />
    </Header>
  );

  const { t } = useTranslation();

  return (
    <>
      <OnboardingWrapper>
        <OnboardingImage
          plain={props.changeImage}
          color={useColor ? theme?.themeInternal?.sidebarColor : undefined}
          other={!props.changeImage}
        >
          {theme?.themeInternal?.logoWideUrl || theme?.themeInternal?.logoWideBrightUrl ? (
            !props.changeImage ? (
              <LogoContainer>
                <CustomLogo
                  src={theme.themeInternal.logoWideBrightUrl || theme.themeInternal.logoWideUrl}
                  invert={
                    !theme.themeInternal.logoWideBrightUrl && theme.themeInternal.logoWideInvert
                  }
                  alt='theme-logo'
                />
              </LogoContainer>
            ) : null
          ) : (
            <LogoImage src={lemonTapsLogo} alt='lemontaps-logo' />
          )}
          <ImagePhoneContainer>
            {props.changeImage && !useColor && (
              <RowDiv style={{ height: '100%', maxHeight: 'unset' }}>
                <WalletImage src={wallet} alt='wallet' />
              </RowDiv>
            )}
          </ImagePhoneContainer>
        </OnboardingImage>
        <OnboardingContent>
          <RenderHeader />
          <DisplayScreen ref={scrollRef}>
            <OnboardingHeading pageHeadingSecond={pageHeadingSecond} pageHeading={pageHeading} />
            <Spacer size={25} />
            {children}
          </DisplayScreen>
        </OnboardingContent>
      </OnboardingWrapper>
    </>
  );
};

export default OnboardingContainer;

const OnboardingWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const OnboardingImage = styled.div`
  ${props =>
    props.plain &&
    css`
      top: 0;
      left: 0;
      bottom: 0;
      width: 40%;
      position: relative;
      background: ${props.color
        ? props.color
        : `linear-gradient(
          194.98deg,
          rgba(0, 255, 231, 0.09) 27.09%,
          rgba(255, 255, 255, 0) 48.41%
        ),
        linear-gradient(0deg, #b9b9b9, #b9b9b9)`};
    `}

  ${props =>
    props.other &&
    css`
      display: flex;
      justify-content: center;
      align-items: baseline;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 40%;
      background: url(${lemon});
      background-size: 120vh;
      position: relative;
    `}

  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 385px) {
    display: none;
  }
`;

const OnboardingContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const SkipButton = styled.div`
  color: #747474;
  cursor: pointer;
  font-size: 16px;
  flex: 1;
  @media (max-width: 767px) {
    padding-top: 30px;
    text-align: right;
  }

  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const WalletImage = styled.img`
  width: 90%;
  max-width: 40rem;
  margin: auto;
  object-fit: contain;

  @media (max-width: 600px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  height: 90px;
`;

const CustomLogo = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  object-fit: contain;
  object-position: center;
  ${props => (props.invert ? 'filter: brightness(0) invert(1)' : '')};
`;

const LogoImage = styled.img`
  position: absolute;
  left: 15px;
  bottom: 20px;
  object-fit: contain;
  margin-left: 20px;

  @media (max-width: 1500px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const ImagePhoneContainer = styled.div`
  height: 100vh;
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  max-height: 75px;
`;

const DisplayScreen = styled.div`
  flex: 1;
  width: 100%;
  @media (max-width: 767px) {
    width: 100vw;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 20px 30px 0px 30px;
`;

const Header = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #ffffffff 0%, #ffffffff 90%, #ffffff00 100%);
  padding: 20px 10px 0px 10px;
`;

const HeaderDesktop = styled.div`
  flex: 2;
  display: 'grid';
  place-items: 'center';
  @media (max-width: 767px) {
    display: none;
  }
`;

const HeaderMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: grid;
    place-items: 'center';
    margin-bottom: -32px;
  }
`;

const BackContainer = styled.div`
  flex: 1;
  text-align: left;

  align-self: start;
  transform: scale(0.7);

  svg {
    width: 35px;
    height: 45px;

    path {
      font-size: larger;
      fill: #181a5a;
    }
  }

  visibility: ${props => (props.visible ? 'visibile' : 'hidden')};

  @media (max-width: 767px) {
    padding: 30px 0 0 0;
  }
`;
