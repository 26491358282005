export const requiredRule = (inputName: string) => {
  return createValidationRule({
    ruleName: 'required',
    errorMessage: `${inputName} required`,
    validateFunc: (inputValue) => inputValue.toString().length !== 0
  });
}

export const minLengthRule = (inputName: string, minCharacters: number) => {
  return createValidationRule({
    ruleName: 'minLength',
    errorMessage: `${inputName} should contain atleast ${minCharacters} characters`,
    validateFunc: inputValue => inputValue.toString().length >= minCharacters
  });
}
export const maxLengthRule = (inputName: string, maxCharacters: number) => {
  return createValidationRule({
    ruleName: 'minLength',
    errorMessage: `${inputName} cannot contain more than ${maxCharacters} characters`,
    validateFunc: inputValue => inputValue.toString().length <= maxCharacters,
  });
}

export const passwordMatchRule = () => {
  return createValidationRule({
    ruleName: 'passwordMatch',
    errorMessage: `passwords do not match`,
    validateFunc: (inputValue, formObj) => inputValue === formObj.password.value,
  });
}

export const isValidHttpUrl = (urlString: string): boolean => {
  let url: URL;
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

interface CreateValidationRulProps {
  ruleName: string;
  errorMessage: String;
  validateFunc: (input: string | number, formObj?: any) => boolean;
}

const createValidationRule = (props: CreateValidationRulProps) => {
  const { ruleName, errorMessage, validateFunc } = props;
  return {
    name: ruleName,
    message: errorMessage,
    validate: validateFunc,
  };
}
