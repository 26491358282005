import { FontType } from '@/shared/types/api';
import { useEffect } from 'react';

// TODO: profile page optimizations

export default function useFont(font: FontType | null) {
  useEffect(() => {
    if (font) {
      const link = document.createElement('link');
      link.href = font?.url;
      link.rel = 'stylesheet';
      document.body.appendChild(link);
    }
  }, [font]);
}
