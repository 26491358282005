import { TFunction } from 'i18next';
import { BulkFile } from '../../../../../../shared/types/api';

export const isFileExtendable = (file: BulkFile) => {
  return !(file['existsForAll'] || file['extendToAll']);
};

export const getFileCountString = (file: BulkFile, t: TFunction) => {
  return isFileExtendable(file)
    ? t('xProfilesHaveFile', { cnt: file['countToRender'] })
    : t('allProfilesHaveFile');
};
