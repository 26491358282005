import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../../../application/hooks';
import { Loader } from '../../../components/common';
import { Spacer } from '../../md/customization/common';
import axios from 'axios';
import config from '../../../../config/config';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { TextField } from '../../../components/generic/textField/TextField';
import { lightenHex, withSilentAccessToken } from '../../../../infrastructure/helper';
import { Account, AccountMeta } from '../../../../shared/types/api';
import { COMPANY_SIZES, POTENTIAL_USER_SIZES, USAGE_GOALS } from '../../../../shared/constants';
import { useDispatch } from 'react-redux';
import { dispatchTypes } from '../../../../application/actions';
import { ContinueButton } from './common';
import { getRedirectedRoute } from './constants';

const buttonsCategory = [
  'sales-manager',
  'field-representative',
  'hr',
  'procurement',
  'consultant',
  'c-level',
  'it',
  'freelancer',
  'marketing',
];
const buttonsReferral = [
  'google',
  'linkedin',
  'instagram',
  'referral',
  'event',
  'email',
  'friends',
];

export const Screen3 = () => {
  const { isLoading } = useAppSelector(state => state.onboarding);
  const account = useAppSelector<Account>(state => state.account);
  const theme = account.theme;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [isActiveCategory, setIsActiveCategory] = useState<string[]>([]);
  const [isActiveSocial, setIsActiveSocial] = useState<string[]>([]);
  const [isActiveUsageGoal, setIsActiveUsageGoal] = useState<string[]>([]);
  const { t, i18n } = useTranslation();
  const [categoryValue, setCategoryValue] = useState<string>(null);
  const [referralValue, setReferralValue] = useState<string>(null);
  const [companySize, setCompanySize] = useState<string>('');
  const [potentialUsers, setPotentialUsers] = useState<string>('');
  const [usageGoalValue, setUsageGoalValue] = useState<string>(null);

  const redirectHandler = () => {
    const redirectedRoute = getRedirectedRoute(account?.origin);
    history.push(redirectedRoute);
  };

  const onboardingCompletedHandler = async () => {
    setLoading(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios
        .post(
          config.API_BASE_URL + `onboarding/meta`,
          {
            onboardingCategory: [
              ...isActiveCategory.map(x => ({ key: x })),
              ...(categoryValue !== null
                ? [
                    {
                      key: 'other',
                      lang: i18n.language.substring(0, 2),
                      value: categoryValue.trim(),
                    },
                  ]
                : []),
            ],
            onboardingReferrer: [
              ...isActiveSocial.map(x => ({ key: x })),
              ...(referralValue !== null
                ? [
                    {
                      key: 'other',
                      lang: i18n.language.substring(0, 2),
                      value: referralValue.trim(),
                    },
                  ]
                : []),
            ],
            onboardingCompanySize: [{ key: companySize }],
            potentialUsers: [{ key: potentialUsers }],
            usageGoal: [
              ...isActiveUsageGoal.map(x => ({ key: x })),
              ...(usageGoalValue !== null
                ? [
                    {
                      key: 'other',
                      lang: i18n.language.substring(0, 2),
                      value: usageGoalValue.trim(),
                    },
                  ]
                : []),
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(res => {
          if (res.data?.data) {
            dispatch({
              type: dispatchTypes.COMMON.UPDATEFIELD,
              field: 'accountMeta',
              value: res.data.data,
            });
          }
          redirectHandler();
        })
        .catch(err => {
          toast.error(t('unexpectedError'));
          console.error(err);
        })
        .finally(() => setLoading(false)),
    );
  };
  const handleClick = text => {
    if (isActiveCategory.includes(text)) {
      setIsActiveCategory(isActiveCategory.filter(record => record !== text));
    } else {
      setIsActiveCategory([...isActiveCategory, text]);
    }
  };
  const socialClick = text => {
    if (isActiveSocial.includes(text)) {
      setIsActiveSocial(isActiveSocial.filter(record => record !== text));
    } else {
      setIsActiveSocial([...isActiveSocial, text]);
    }
  };
  const usageGoalClick = text => {
    if (isActiveUsageGoal.includes(text)) {
      setIsActiveUsageGoal(isActiveUsageGoal.filter(record => record !== text));
    } else {
      setIsActiveUsageGoal([...isActiveUsageGoal, text]);
    }
  };

  const accountMeta = useAppSelector<AccountMeta>(state => state.account?.accountMeta);
  useEffect(() => {
    if (accountMeta) {
      if (accountMeta.onboardingCategory) {
        setIsActiveCategory(
          (accountMeta.onboardingCategory || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key),
        );
        setCategoryValue(
          (accountMeta.onboardingCategory || []).find(cat => cat.key === 'other')?.value || null,
        );
      }
      if (accountMeta.onboardingReferrer) {
        setIsActiveSocial(
          (accountMeta.onboardingReferrer || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key),
        );
        setReferralValue(
          (accountMeta.onboardingReferrer || []).find(cat => cat.key === 'other')?.value || null,
        );
      }
      if (accountMeta.onboardingCompanySize) {
        setCompanySize(
          (accountMeta.onboardingCompanySize || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key)?.[0] || '',
        );
      }
      if (accountMeta.potentialUsers) {
        setPotentialUsers(
          (accountMeta.potentialUsers || [])
            .filter(cat => cat.key !== 'other')
            .map(cat => cat.key)?.[0] || '',
        );
      }
      if (accountMeta.usageGoal) {
        setIsActiveUsageGoal(
          (accountMeta.usageGoal || []).filter(cat => cat.key !== 'other').map(cat => cat.key),
        );
        setUsageGoalValue(
          (accountMeta.usageGoal || []).find(cat => cat.key === 'other')?.value || null,
        );
      }
    }
  }, [accountMeta]);

  // restrict potential users to maximum company size
  useEffect(() => {
    const idxCompanySize = COMPANY_SIZES.indexOf(companySize);
    const idxPotentialUsers = POTENTIAL_USER_SIZES.indexOf(potentialUsers);
    if (idxPotentialUsers > idxCompanySize) {
      setPotentialUsers('');
    }
  }, [companySize, potentialUsers]);

  // pre-fill when in dev
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setIsActiveCategory([buttonsCategory[0]]);
      setIsActiveSocial([buttonsReferral[0]]);
      setIsActiveUsageGoal([USAGE_GOALS[0]]);
      setCompanySize(COMPANY_SIZES[0]);
      setPotentialUsers(POTENTIAL_USER_SIZES[0]);
    }
  }, []);

  if (isLoading || loading) {
    return <Loader />;
  }

  const optionBgColor = lightenHex(theme?.themeInternal?.primaryColor, 20) || '#D5F8EF';

  const optionBorderColor = lightenHex(theme?.themeInternal?.primaryColor, 20) || '#e0e2da';

  return (
    <ParentContainer>
      <Wrapper>
        <Content>
          <SubTitle>{t('onboarding.selectCategory')}</SubTitle>
          <ButtonContainer>
            {buttonsCategory.map((button, index) => {
              return (
                <BoxFormOption
                  style={{
                    backgroundColor: isActiveCategory.includes(button) ? optionBgColor : null,
                    color: isActiveCategory.includes(button) ? 'white' : '',
                    border: `1px solid ${optionBorderColor}`,
                  }}
                  onClick={() => handleClick(button)}
                  key={index}
                >
                  <SubsectionSpan>{t(button)}</SubsectionSpan>
                </BoxFormOption>
              );
            })}
            <BoxFormOption
              style={{
                backgroundColor: categoryValue !== null ? optionBgColor : '',
                color: categoryValue !== null ? 'white' : '',
                border: `1px solid ${optionBorderColor}`,
              }}
              onClick={() => setCategoryValue(prev => (prev === null ? '' : null))}
            >
              <SubsectionSpan>{t('other')}</SubsectionSpan>
            </BoxFormOption>
          </ButtonContainer>
          {categoryValue !== null && (
            <OtherContainer>
              <TextField
                name='otherCategory'
                type='text'
                placeholder={t('onboarding.enterOtherCategory')}
                value={categoryValue}
                onChange={e => setCategoryValue(e.target.value)}
                padding={{ left: '0' }}
              />
            </OtherContainer>
          )}

          <SubTitle>{t('onboarding.howDidYouhear')}</SubTitle>
          <ButtonContainer>
            {buttonsReferral.map((button, index) => {
              return (
                <BoxFormOption
                  style={{
                    backgroundColor: isActiveSocial.includes(button) ? optionBgColor : '',
                    color: isActiveSocial.includes(button) ? 'white' : '',
                    border: `1px solid ${optionBorderColor}`,
                  }}
                  onClick={() => socialClick(button)}
                  key={index}
                >
                  <SubsectionSpan>{t(`onboardingReferrer.${button}`)}</SubsectionSpan>
                </BoxFormOption>
              );
            })}
            <BoxFormOption
              style={{
                backgroundColor: referralValue !== null ? optionBgColor : '',
                color: referralValue !== null ? 'white' : '',
                border: `1px solid ${optionBorderColor}`,
              }}
              onClick={() => setReferralValue(prev => (prev === null ? '' : null))}
            >
              <SubsectionSpan>{t('other')}</SubsectionSpan>
            </BoxFormOption>
          </ButtonContainer>
          {referralValue !== null && (
            <OtherContainer>
              <TextField
                name='otherCategory'
                type='text'
                placeholder={t('onboarding.EnterOther')}
                value={referralValue}
                onChange={e => setReferralValue(e.target.value)}
                padding={{ left: '0' }}
              />
            </OtherContainer>
          )}
          <SubTitle>{t('onboarding.whyLemontaps.title')}</SubTitle>
          <ButtonContainer>
            {USAGE_GOALS.map((button, index) => {
              return (
                <BoxFormOption
                  style={{
                    backgroundColor: isActiveUsageGoal.includes(button) ? optionBgColor : '',
                    color: isActiveUsageGoal.includes(button) ? 'white' : '',
                    border: `1px solid ${optionBorderColor}`,
                  }}
                  onClick={() => usageGoalClick(button)}
                  key={index}
                >
                  <SubsectionSpan>{t(`onboarding.whyLemontaps.${button}`)}</SubsectionSpan>
                </BoxFormOption>
              );
            })}
            <BoxFormOption
              style={{
                backgroundColor: usageGoalValue !== null ? optionBgColor : '',
                color: usageGoalValue !== null ? 'white' : '',
                border: `1px solid ${optionBorderColor}`,
              }}
              onClick={() => setUsageGoalValue(prev => (prev === null ? '' : null))}
            >
              <SubsectionSpan>{t('other')}</SubsectionSpan>
            </BoxFormOption>
          </ButtonContainer>
          {usageGoalValue !== null && (
            <OtherContainer>
              <TextField
                name='otherCategory'
                type='text'
                placeholder={t('onboarding.EnterOther')}
                value={usageGoalValue}
                onChange={e => setUsageGoalValue(e.target.value)}
                padding={{ left: '0' }}
              />
            </OtherContainer>
          )}
          <SubTitle>{t('onboarding.howMany')}</SubTitle>
          <ButtonContainer>
            {COMPANY_SIZES.map((size, index) => {
              return (
                <BoxFormOption
                  style={{
                    backgroundColor: companySize === size ? optionBgColor : '',
                    color: companySize === size ? '' : 'white',
                    border: `1px solid ${optionBorderColor}`,
                  }}
                  onClick={() => setCompanySize(size)}
                  key={index}
                >
                  <SubsectionSpan>{t(size)}</SubsectionSpan>
                </BoxFormOption>
              );
            })}
          </ButtonContainer>
          {companySize && (
            <Reveal>
              <SubTitle>{t('onboarding.howManyPotentialUsers')}</SubTitle>
              <ButtonContainer>
                {POTENTIAL_USER_SIZES.map((size, index) => {
                  const disabled = index > COMPANY_SIZES.indexOf(companySize);
                  if (disabled) return null;
                  return (
                    <BoxFormOption
                      style={{
                        opacity: disabled ? '0.5' : '1',
                        backgroundColor: potentialUsers === size ? optionBgColor : '',
                        color: potentialUsers === size ? '' : 'white',
                        border: `1px solid ${optionBorderColor}`,
                      }}
                      onClick={() => setPotentialUsers(size)}
                      key={index}
                    >
                      <SubsectionSpan>{t(size)}</SubsectionSpan>
                    </BoxFormOption>
                  );
                })}
              </ButtonContainer>
            </Reveal>
          )}
          <Spacer size={24} />
        </Content>
        <ContinueButton
          buttonTitle={t('onboarding.letsStart')}
          nextFunction={onboardingCompletedHandler}
          disabled={
            (!isActiveCategory.length && !categoryValue) ||
            (!isActiveSocial.length && !referralValue) ||
            (!isActiveUsageGoal.length && !usageGoalValue) ||
            companySize === '' ||
            potentialUsers === ''
          }
        />
      </Wrapper>
    </ParentContainer>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const ParentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70vw;
  align-self: center;
  @media (max-width: 767px) {
    width: 90vw;
  }
  @media (min-width: 1200px) {
    width: 50vw;
  }
`;
const Wrapper = styled.form`
  flex: 1;
  padding-top: 25px;
  align-content: center;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 0 48px;

  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2rem;
  text-align: left;
  margin-top: 4rem;

  @media (max-width: 767px) {
    font-size: 12px;
    text-align: start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const BoxFormOption = styled.div`
  padding: 10px 25px;
  transition: ease-in all 0.15s;
  cursor: pointer;
  border-radius: 30px;
  font-size: 14px;
  margin-bottom: 15px;
  margin-right: 15px;
`;

export const SubsectionSpan = styled.span`
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

const OtherContainer = styled.div`
  display: flex;
  max-width: 500px;
`;

const Reveal = styled.div`
  animation: lt-reveal 0.1s ease-out;
  @keyframes lt-reveal {
    0% {
      opacity: 0;
      height: 0%;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }
`;
