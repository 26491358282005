import React, { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '../../../application/hooks';
import Layout from '../../components/common/white-layout';
import FileBox from './fileBox';
import { ReactComponent as DragIndicatorIcon } from '../../images/dragIndicatorIcon.svg';
import { DropResult } from 'react-beautiful-dnd';
import {
  DEFAULT_BOX_STYLE,
  THEME_FILE_EDIT_MODES,
  THEME_LINKS_FILES_POSITIONS,
} from '../../../shared/constants/entities';
import { Account, ApiFile } from '../../../shared/types/api';
import { ReactComponent as AddIcon } from '../../images/add-icon.svg';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';

interface Props {
  onDragEndFiles: (result: DropResult) => void;
  onDeleteFileClick: (item: ApiFile) => void;
  onAddNewFileClick: () => void;
  getItemStyle: (isDragging: boolean, draggableStyle: any) => React.CSSProperties;
}

const FilesLayout = React.memo((props: Props) => {
  const { t } = useAppTranslation();

  const account = useAppSelector<Account>(state => state.account);
  const files = useAppSelector(state => {
    return state.account.files ? state.account.files.sort((a, b) => a.order - b.order) : [];
  });

  const { profileDesign } = useProfileDesign();
  const {
    editRights: { fileEditMode },
  } = useEditRights();

  const themeFiles = useMemo(
    () =>
      account.theme.files.map((item, index) => (
        <FileBox
          key={index}
          item={item}
          onDeleteClick={() => {}}
          theme={account.theme}
          hideOptions
          isThemeEntity
        />
      )),
    [account.theme],
  );

  if (fileEditMode === THEME_FILE_EDIT_MODES.DISALLOW) {
    return (
      <Layout headerText={t('myFiles')} themeBoxStyle={profileDesign.boxStyle || DEFAULT_BOX_STYLE}>
        {profileDesign.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeFiles}
        {files.map((item, index) => (
          <FileBox
            key={index}
            item={item}
            onDeleteClick={() => props.onDeleteFileClick(item)}
            theme={account.theme}
            hideOptions
          />
        ))}
        {profileDesign.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeFiles}
      </Layout>
    );
  } else {
    return (
      <Layout
        headerText={t('myFiles')}
        themeBoxStyle={profileDesign.boxStyle}
        actionButton={{
          text: t('add'),
          icon: <AddIcon />,
          onClick: props.onAddNewFileClick,
          customBtnClasses: 'lt-usetiful--file-add',
        }}
      >
        {profileDesign.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeFiles}
        <DragDropContext onDragEnd={props.onDragEndFiles}>
          <Droppable droppableId='files'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {files.map((item, index) => (
                  <Draggable
                    key={item.id.toString()}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={props.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <div
                          className='darg-icon'
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          {...provided.dragHandleProps}
                          tabIndex={undefined}
                        >
                          <DragIndicatorIcon />
                        </div>
                        <FileBox
                          key={index}
                          item={item}
                          onDeleteClick={() => props.onDeleteFileClick(item)}
                          theme={account.theme}
                          blueBackground={typeof item.fileTemplateId === 'number'}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {profileDesign.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeFiles}
      </Layout>
    );
  }
});

export default FilesLayout;
