import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { DEFAULT_AUTH_CONNECTION, THEME_CONFIG_KEYS } from '@/shared/constants';
import { Account } from '@/shared/types/api';
import {
  USER_MORE_SETTINGS_IDS,
  USER_SETTINGS_IDS,
  UserSettingsConfig,
} from '@/shared/types/global';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import SettingsItem from './components/SettingsItem';
import { translate } from './utils';
import {
  AccountCircleOutlined,
  AddCircleOutlineOutlined,
  AutoGraphOutlined,
  CookieOutlined,
  CreditCardOutlined,
  DeleteOutlineOutlined,
  HelpOutline,
  LockOutlined,
  MailOutlineOutlined,
  ShoppingCartOutlined,
  SupervisedUserCircleOutlined,
  TranslateOutlined,
} from '@mui/icons-material';
import { routePaths } from '@/infrastructure/constants';
import { getPrefilledGoogleFormLink } from '@/shared/business-logic/lib/google-forms';

type ClickHandlerIDS = 'cookies' | 'change-language' | 'delete-self' | 'help' | 'addToHomeScreen';
type Props = {
  mode: 'main' | 'more';
  clickHandlers?: {
    [k in ClickHandlerIDS]?: () => void;
  };
};
const Menu = (props: Props) => {
  const { mode, clickHandlers } = props;
  const { t, activeLanguage } = useAppTranslation();
  const { user } = useAuth0();
  const { desktopView } = useDesktopView();
  const { isStarter, isTrial, isMainAccount } = useTierInfo();
  const isMainMenu = mode === 'main';
  const IDS = isMainMenu ? USER_SETTINGS_IDS : USER_MORE_SETTINGS_IDS;
  const { config, loading } = usePrivateThemeConfig<UserSettingsConfig>(
    isMainMenu
      ? THEME_CONFIG_KEYS.USER_SETTINGS_CONFIG
      : THEME_CONFIG_KEYS.USER_MORE_SETTINGS_CONFIG,
  );
  useDeferredLoader(loading, 'settings-config-loader');

  const account = useAppSelector<Account>(state => state.account);
  const hideEmailChange = !(
    account?.theme?.themeInternal?.authConnection === DEFAULT_AUTH_CONNECTION ||
    (account.theme.themeInternal.ssoDomains?.length &&
      !account.theme.themeInternal.ssoDomains.includes(user.email?.toLowerCase().split('@')?.[1]))
  );

  const hideChangePassword = !(
    account?.theme?.themeInternal?.authConnection === DEFAULT_AUTH_CONNECTION ||
    (account.theme.themeInternal.ssoDomains?.length &&
      !account.theme.themeInternal.ssoDomains.includes(user.email?.toLowerCase().split('@')?.[1]))
  );

  const hideDeleteSelf = !(isStarter || (isTrial && isMainAccount));
  const hideNfcCardUI = account?.theme?.themeInternal?.hideNfcCardUI;

  const settingsRows = useMemo(
    () =>
      loading
        ? null
        : (!config || !config.items
            ? IDS.map(id => ({ id, labels: {} }))
            : config.override
            ? config.items
            : IDS.map(id => config.items.find(item => item.id === id) || { id, labels: {} })
          )
            .filter(({ id }) => !config?.hiddenItemIds?.includes(id))
            .map(setting => {
              switch (setting.id) {
                case 'change-email':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'settings.changeEmail')}
                      caption={user.email}
                      icon={<MailOutlineOutlined sx={{ color: 'primary.light' }} />}
                      link={routePaths.SETTINGS.AUTH}
                      hide={hideEmailChange}
                    />
                  );
                case 'change-password':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'settings.changePassword')}
                      icon={<LockOutlined sx={{ color: 'primary.light' }} />}
                      link={routePaths.CHANGEPASSWORD}
                      hide={hideChangePassword}
                    />
                  );
                case 'cookies':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'settings.openCookieSettings')}
                      icon={<CookieOutlined sx={{ color: 'primary.light' }} />}
                      onClick={clickHandlers?.['cookies']}
                    />
                  );
                case 'change-language':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'settings.changeLanguage')}
                      caption={activeLanguage?.startsWith('de') ? 'Deutsch' : 'English'}
                      icon={<TranslateOutlined sx={{ color: 'primary.light' }} />}
                      onClick={clickHandlers?.['change-language']}
                    />
                  );
                case 'delete-self':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'deleteAccount.title')}
                      icon={<DeleteOutlineOutlined sx={{ color: 'primary.light' }} />}
                      onClick={clickHandlers?.['delete-self']}
                      hide={hideDeleteSelf}
                    />
                  );
                case 'management-dashboard':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'moreSettings.teamMangement')}
                      caption={t('moreSettings.teamMangementCaption')}
                      icon={<SupervisedUserCircleOutlined sx={{ color: 'primary.light' }} />}
                      link={routePaths.MD.PROFILES}
                    />
                  );
                case 'nfc-cards':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'moreSettings.nfcCards')}
                      caption={t('moreSettings.nfcCardsCaption')}
                      icon={<CreditCardOutlined sx={{ color: 'primary.light' }} />}
                      link={routePaths.NFC_CARDS}
                    />
                  );
                case 'analytics':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'moreSettings.analytics')}
                      caption={t('moreSettings.analyticsCaption')}
                      icon={<AutoGraphOutlined sx={{ color: 'primary.light' }} />}
                      link={routePaths.ANALYTICS}
                      hide={hideNfcCardUI}
                    />
                  );
                case 'add-to-homescreen':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'moreSettings.addApp')}
                      caption={t('moreSettings.addAppCaption')}
                      icon={<AddCircleOutlineOutlined sx={{ color: 'primary.light' }} />}
                      onClick={clickHandlers?.['addToHomeScreen']}
                    />
                  );
                case 'help':
                  if (desktopView) return null; // dropdown cannot be opened on desktop currently
                  return (
                    <SettingsItem
                      text={t('settings.help')}
                      caption={t('settings.helpCaption')}
                      icon={<HelpOutline sx={{ color: 'primary.light' }} />}
                      onClick={clickHandlers?.['help']}
                    />
                  );
                case 'account-settings':
                  return (
                    <SettingsItem
                      text={translate(setting.labels, 'moreSettings.accountSettings')}
                      caption={t('moreSettings.accountSettingsCaption')}
                      icon={<AccountCircleOutlined sx={{ color: 'primary.light' }} />}
                      link={routePaths.SETTINGS.MAIN}
                    />
                  );
                case 'paper-card-order-link':
                  return (
                    'googleFormConfig' in setting && (
                      <SettingsItem
                        text={translate(setting.labels, 'moreSettings.printCard')}
                        caption={t('moreSettings.printCardCaption')}
                        icon={<ShoppingCartOutlined sx={{ color: 'primary.light' }} />}
                        external
                        link={getPrefilledGoogleFormLink(
                          setting.googleFormConfig,
                          account,
                          account.theme,
                        )}
                      />
                    )
                  );
                default:
                  return null;
              }
            }),
    [
      loading,
      config,
      IDS,
      user.email,
      hideEmailChange,
      hideChangePassword,
      clickHandlers,
      activeLanguage,
      hideDeleteSelf,
      t,
      hideNfcCardUI,
      desktopView,
      account,
    ],
  );
  return <>{settingsRows}</>;
};

export default Menu;
