import React from 'react';
import ReactDOM from 'react-dom';
import { IoIosCloseCircle } from 'react-icons/io';
import { Loader } from '@/views/components/common';
import useCloseByEscape from '@/infrastructure/hooks/useCloseByEscape';
import { ContentWrapper, IconContainer, VideoFrame, Wrapper } from './styles';

interface VideoDisplayProps {
  onCloseClick: () => void;
  details: { title: string; path: string };
}

export const VideoDisplay: React.FC<VideoDisplayProps> = ({ details, onCloseClick }) => {
  const [videoLoaded, setVideoLoaded] = React.useState(false);

  useCloseByEscape(onCloseClick);
  return ReactDOM.createPortal(
    <>
      <div className='overlay' onClick={onCloseClick}></div>
      <Wrapper>
        <IconContainer onClick={onCloseClick}>
          <IoIosCloseCircle size={24} color={'white'} />
        </IconContainer>
        <ContentWrapper>
          {!videoLoaded && <Loader />}
          <VideoFrame
            onLoad={() => setVideoLoaded(true)}
            title={details.title}
            src={details.path}
            frameborder='0'
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          ></VideoFrame>
        </ContentWrapper>
      </Wrapper>
    </>,
    document.getElementById('portal'),
  );
};
