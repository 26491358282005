import React, { useCallback, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';
import toast from 'react-hot-toast';
import config from '../../../../config/config';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import { Container } from '../../../components/generic';
import ScannerCamera from '../../../components/qrcode-scanner/scanner-camera';
import { BsCheckCircle } from 'react-icons/bs';
import axios from 'axios';
import useQuery from '../../../../infrastructure/hooks/useQuery';
import { SecondaryText, SuccessContainer, Title } from '../profiles/common/styles';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ThemeProvider } from '@mui/material';
import { PublicHeader } from '@/components';

const MD2Phone = () => {
  const { t, i18n } = useAppTranslation();
  const [scanResult, setScanResult] = useState<QrScanner.ScanResult>(null);
  const [newScan, setNewScan] = useState<boolean>(false);
  const [prevScan, setPrevScan] = useState<string>(null);
  const [nextTry, setNextTry] = useState<boolean>(true);
  const [codeIsChecking, setCodeIsChecking] = useState<boolean>(false);
  const [codeConnected, setCodeConnected] = useState<boolean>(false);

  const scanSetters = { setScanResult, setNewScan };
  const query = useQuery();

  const handleCode = useCallback(
    async (_code: string) => {
      if (_code.length === 10 && query.get('token')) {
        setCodeIsChecking(true);
        await axios
          .get(config.API_BASE_URL + `business/md2phone?token=${query.get('token')}&code=${_code}`)
          .then(res => {
            if (res.data.isSuccess) setCodeConnected(true);
          })
          .catch(error => {
            const errResponse = error.response.data?.error;
            toast.error(errResponse?.message || t('error.general'));
          });
        setCodeIsChecking(false);
      } else {
        toast.error(t('md2Phone.noCodeToken'));
      }
    },
    [query, t],
  );

  useEffect(() => {
    i18n.changeLanguage(query.get('lang')?.substring(0, 2) || 'en');
  }, [i18n, query]);

  useEffect(() => {
    if (scanResult?.data) {
      let qrString = scanResult.data;
      // domain matching
      let domain = config.REACT_APP_URL.split('://')[1];
      let codeToTest = '';
      if (qrString.includes(domain)) {
        let url = new URL(qrString);
        url.pathname.length > 1 && (codeToTest = url.pathname.split('/')[1]);
      }

      if (!codeIsChecking && newScan && (codeToTest !== prevScan || nextTry) && !codeConnected) {
        if (codeToTest.length > 0) {
          handleCode(codeToTest);
          setPrevScan(codeToTest);
        } else {
          toast.error(t('codeRead.missed'));
          setPrevScan('');
        }
        setTimeout(() => {
          setNextTry(true);
        }, 7000);
        setNewScan(false);
      }
      setNextTry(false);
    }
  }, [scanResult, t, prevScan, nextTry, newScan, codeIsChecking, handleCode, query, codeConnected]);

  const { theme } = useMuiTheme();
  return (
    <>
      <Container>
        <ThemeProvider theme={theme}>
          <PublicHeader showLogo={{ linkTo: config.REACT_APP_URL }} />
        </ThemeProvider>
        {codeConnected ? (
          <SuccessContainer>
            <BsCheckCircle size={60} color={'#01ce91'} style={{ marginBottom: '5rem' }} />
            <Title>{t('md2Phone.success')}</Title>
          </SuccessContainer>
        ) : (
          <>
            <div style={{ marginBottom: '1rem' }}>
              <Title>{t('md2Phone.title')}</Title>
              <SecondaryText>{t('md2Phone.subTitle')}</SecondaryText>
            </div>
            <ScannerCamera scanSetters={scanSetters} videoWidth='100%' allowUpload />
          </>
        )}
      </Container>
    </>
  );
};

export default MD2Phone;
