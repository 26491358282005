import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { Box, Card, CardProps, SxProps } from '@mui/material';
import { CSS_VARS } from '@/infrastructure/constants';
import { useAppSelector } from '@/application/hooks';
import { LtDefaultActionButtonsProps } from './LtDefaultActionButtons/types';
import LtDefaultActionButtons from './LtDefaultActionButtons';
import React, { useMemo, useRef } from 'react';

type Props = CardProps &
  LtDefaultActionButtonsProps & {
    noCancelOnDesktop?: boolean;
    sticky?: boolean;
    renderRelativeBox?: boolean; // render a box that takes up the space in relative positioning
  };

const LtActionButtonBar = ({ sx, sticky, renderRelativeBox, ...rest }: Props) => {
  const { desktopView } = useDesktopView();
  const sidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  const elemRef = useRef<HTMLDivElement>();

  const position: SxProps = useMemo(
    () =>
      sticky
        ? {
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'unset',
          }
        : {
            position: 'fixed',
            bottom: desktopView ? 0 : CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR,
            left: desktopView
              ? sidebarCollapsed
                ? CSS_VARS.LT_SIDEBAR_WIDTH_TOGGLED_VAR
                : CSS_VARS.LT_SIDEBAR_WIDTH_VAR
              : 0,
            right: 0,
          },
    [desktopView, sidebarCollapsed, sticky],
  );

  return (
    <>
      <Card
        ref={elemRef}
        sx={{
          p: 2,
          pb: desktopView ? undefined : 4,
          ...position,
          zIndex: 1,
          ...sx,
        }}
        {...rest}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: desktopView ? 'flex-end' : 'space-between',
            alignItems: 'strech',
            gap: 2,
            maxWidth: theme.spacing(240),
            margin: 'auto',
          })}
        >
          <LtDefaultActionButtons {...rest} />
        </Box>
      </Card>

      {!sticky && elemRef?.current && renderRelativeBox && (
        <Box height={elemRef.current.getBoundingClientRect().height} />
      )}
    </>
  );
};

export default LtActionButtonBar;
