import { ArrowDropDown, Download, FilePresent } from '@mui/icons-material';
import { Button } from '@mui/material';
import { CustomMenuList } from './CustomMenuList';
import { fetchDownloadLeads } from '@/infrastructure/apis/leadGen';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '@/application/hooks';
import { useTranslation } from 'react-i18next';

export const ExportButton = ({ selectedLeadIds }) => {
  const { getAccessTokenSilently } = useAuth0();
  const account = useAppSelector(state => state.account);
  const { t } = useTranslation();

  const handleCsvExport = () => {
    fetchDownloadLeads(account.firstName, 'csv', getAccessTokenSilently, {
      leadIds: selectedLeadIds,
    });
  };

  const handleExcelExport = () => {
    fetchDownloadLeads(account.firstName, 'xlsx', getAccessTokenSilently, {
      leadIds: selectedLeadIds,
    });
  };

  const exportActions = [
    { icon: <FilePresent />, label: `${t('export.title')} .csv`, onClick: handleCsvExport },
    { icon: <FilePresent />, label: `${t('export.title')} .xlsx`, onClick: handleExcelExport },
  ];

  return (
    <CustomMenuList
      items={exportActions}
      toggleElement={
        <Button
          startIcon={<Download />}
          endIcon={<ArrowDropDown />}
          variant='outlined'
          color='primary'
        >
          {t('export.title')}
        </Button>
      }
    />
  );
};
