import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { SubsectionWrapper, StyledLink } from '../../pages/md/profiles/common/styles';
import AutoComplete, { AutoCompleteValue } from './autoComplete';
import { ApiFile } from '../../../shared/types/api';
import LtIconButton from './lt-icon-button';
import { FEATURE, THEME_BOX_STYLES } from '../../../shared/constants';
import toast from 'react-hot-toast';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import config from '../../../config/config';
import { PERMISSIONS, routePaths } from '../../../infrastructure/constants';
import { UploadfileProps, UploadfileTemplateProps } from '../../pages/md/profiles/common/files';
import { ReactComponent as AddIcon } from '../../images/add-icon.svg';
import useTierInfo from '../../../infrastructure/hooks/useTierInfo';
import TeaserBlur from '../../pages/md/teaser/teaserBlur';
import { GoArrowUpRight } from 'react-icons/go';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  setSavedTemplateFiles: Dispatch<SetStateAction<ApiFile[]>>;
  savedTemplateFiles: ApiFile[];
  setShowIndividualFile: Dispatch<SetStateAction<boolean>>;
  employeeId?: string;
  scrollToBottom: () => void;
  totalFiles: ApiFile[];
  isCompanydataSettings?: boolean;
  handleCompanySettingsUpdate?: (props: {
    fileType: 'newFile' | 'templateFile';
    templateFileDetails?: UploadfileTemplateProps;
    newFileDetails?: UploadfileProps;
  }) => void;
  companyFilesList?: ApiFile[];
  userFiles?: Array<ApiFile>;
  setUserFiles?: Dispatch<SetStateAction<Array<ApiFile>>>;
}

const FileSelector = (props: Props) => {
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [initialTemplates, setInitialTemplates] = useState<ApiFile[]>([]);
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);

  const isDisabled = autoCompleteValues.length === 0;
  const noTemplates = isDisabled && initialTemplates.length === 0;

  if (document.getElementById('autocomplete-component')) {
    document.getElementById('autocomplete-component').style.borderStyle = 'none';
    document.getElementById('autocomplete-component').style.boxShadow = 'none';
    document.getElementById('autocomplete-component').style.borderColor = 'white';
    document.getElementById('autocomplete-component').style.fontSize = '20px';
    document.getElementById('autocomplete-component').style.height = '50px';
  }
  useEffect(() => {
    const unselectedFiles: AutoCompleteValue[] = [];
    initialTemplates.forEach((file, index) => {
      if (
        [...props.totalFiles, ...(props.companyFilesList || [])].filter(
          _file => _file.fileTemplateId === file.id,
        ).length === 0
      ) {
        unselectedFiles.push({
          id: file.id,
          description: file.fileName,
          index,
          otherData: { ...file },
        });
      }
    });
    setAutoCompleteValues(unselectedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.savedTemplateFiles, initialTemplates, props.totalFiles]);

  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `theme/file-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      )
        .then(res => {
          setInitialTemplates(res.data.data);
        })
        .catch(() => toast.error(t('unexpectedError')));
    }
  }, [getAccessTokenSilently, isFeatureAllowed, t]);

  const saveAutoValue = (selectedValue: AutoCompleteValue) => {
    if (selectedValue) {
      if (props.totalFiles.find(file => file.fileTemplateId === selectedValue.id)) return;
      if (props.employeeId) {
        const addedTemplateFile = {
          id: Math.floor(Math.random() * 1000),
          link: selectedValue.otherData.link,
          fileName: selectedValue.otherData.fileName,
          embed: false,
          pageCount: selectedValue.otherData.pageCount,
          canDisplayOnProfile: true,
          fileTemplateId: selectedValue.id,
          order: (props.userFiles?.length + 1) * 10,
        };

        props.setUserFiles([...props.userFiles, addedTemplateFile]);
      } else {
        props.setSavedTemplateFiles([selectedValue.otherData, ...props.savedTemplateFiles]);
        props.scrollToBottom();
        if (props.isCompanydataSettings) {
          props.handleCompanySettingsUpdate({
            fileType: 'templateFile',
            templateFileDetails: {
              id: selectedValue.otherData.id,
              url: selectedValue.otherData.link,
              fileName: selectedValue.otherData.fileName,
              pageCount: selectedValue.otherData.pageCount,
            },
          });
        }
      }
    }
  };

  return (
    <>
      <TeaserBlur condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} badge='Enterprise'>
        <AutoCompleteContainer>
          <AutoComplete
            options={autoCompleteValues}
            onSave={saveAutoValue}
            placeHolder={`${t(
              noTemplates ? 'filesTab.noTemplates' : 'filesTab.searchInputPlaceHolder',
            )}...`}
            disabled={isDisabled}
          />
        </AutoCompleteContainer>
        {noTemplates && isThemeAdmin && (
          <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.DATA_TEMPLATES}>
            {t('filesTab.createTemplate')}
            <GoArrowUpRight size={17} />
          </StyledLink>
        )}
      </TeaserBlur>
      {
        <SwitchIndividualAddressContainer>
          <SwitchAddressTitle>{t('filesTab.orAddIndividualFile')}</SwitchAddressTitle>
          <ButtonWrapper>
            <SubsectionWrapper>
              <LtIconButton
                text={t('addFile')}
                icon={<AddIcon />}
                onClick={() => props.setShowIndividualFile(true)}
                boxStyle={THEME_BOX_STYLES.ROUNDED}
                backgroundCss={muiTheme.palette.primaryButton.main}
              />
            </SubsectionWrapper>
          </ButtonWrapper>
        </SwitchIndividualAddressContainer>
      }
    </>
  );
};

export default FileSelector;

const AutoCompleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SwitchIndividualAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${props =>
    props.disabled &&
    `& svg {
      color: #535353;
      font-size: 2rem;
      margin-right: 0.7em;
      cursor: pointer;
    }`}
`;

const SwitchAddressTitle = styled.div`
  color: #666666;
  font-size: small;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 5px;
`;
