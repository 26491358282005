export const routePaths = {
  ROOT: '/',
  INDEXHTML: '/index.html',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CHANGEPASSWORD: '/change-password',
  MD: {
    ROOT: '/md',
    GUIDE: '/md/guide',
    PROFILES: '/md/profiles',
    UNITS: '/md/units',
    ANALYTICS: '/md/analytics',
    CONTACTS: '/md/contacts',
    NFCCARDS: '/md/nfc-cards',
    LINKCODE: '/md/linkcode/:code',
    LINKCODE_SUCCESS: '/md/linkcode/success',
    LINKCODE_ERROR: '/md/linkcode/error',
    INTEGRATIONS: '/md/integrations',
    CUSTOMISESETTINGS: {
      ROOT: '/md/settings',
      PROFILE_DESIGN: '/md/settings/profile-design',
      EDIT_RIGHTS: '/md/settings/edit-rights',
      COMPANY_DATA: '/md/settings/company-data',
      TRANSLATIONS: '/md/settings/translations',
      DATA_TEMPLATES: '/md/settings/data-templates',
      QR_BACKGROUNDS: '/md/settings/qr-backgrounds',
      CONTACT_FORM: '/md/settings/contact-form',
      EMAIL_TEMPLATES: '/md/settings/email-templates',
      UNITS: '/md/settings/units',
      EXPORT: '/md/settings/export',
      CRM_INTEGRATION: '/md/settings/crm-integration',
    },
    MOBILE: {
      MD2PHONE: '/md/mobile/md2phone',
    },
  },
  HOME: '/home',
  EDITPROFILE: '/edit-profile',
  EDITPROFILEWITHSETTINGS: '/edit-profile/:showHowToTap',
  EDITBIO: '/edit-bio',
  SHOWPROFILE: '/:username',
  ONBOARDING: '/onboarding',
  QR_CODE_BACKGROUND: '/qr-code-background',
  SHARE_LINK: '/share-link',
  ANALYTICS: '/analytics',
  NFC_CARDS: '/nfc-cards',
  LEADS: '/leads',
  UPGRADE_TEAMS: '/upgrade-teams',
  UPGRADE_LEADGEN: '/upgrade-leadgen',
  REFERRALS: '/referrals',
  ACTIVATION: {
    ACTIVATION: '/activation',
    CHANGEPASSWORD: '/activation/change-password',
    EMAILVALIDATION: '/activation/email-validation',
    VERIFYEMAIL: '/activation/verify-email',
  },
  SETTINGS: {
    MAIN: '/settings',
    MORE: '/more',
    AUTH: '/settings/auth',
  },
  LANDING: {
    CHECK: '/landing/check/:usernameorcode',
    LOGIN_REGISTER: '/landing/login-or-register',
  },
  REGISTRATION: {
    REGISTER: '/registration/register',
    SIGNUP: '/registration/signup',
  },
  BUSINESS: {
    ONBOARDING: '/business/onboarding/:token',
  },
  CONTACTS: {
    ROOT: '/contacts',
    ADD: '/contacts/add',
    EDIT: '/contacts/:leadId/edit',
    EXPORT: '/contacts/export',
    IMPORT: '/contacts/crm-how-to-import',
    SCANNER: '/contacts/scanner',
  },
  HOW_TO_TAP: {
    MAIN: '/how-to-tap',
    DETAILS: '/how-to-tap/:slug',
  },
  SHARE_PROFILE: '/share-profile',
  UTIL_ROUTES: {
    FLAG: '/util-lt/config/flag',
    UNFLAG: '/util-lt/config/unflag',
  },
  CRM_CALLBACK: '/crm/callback',
};

export const homepageLink = 'https://lemontaps.de';

export const helpLink = 'https://wa.me/4917668086624';

export const cardsShopLinkDe = 'https://lemontaps.de/collections/alle-nfc-visitenkarten';
export const cardsShopLinkEn = 'https://lemontaps.de/en/collections/all-nfc-business-cards';

export const bookCall = 'https://zcal.co/michellebaetge/10min';

export const requestMdDe = 'https://lemontaps.de/pages/digitale-visitenkarte-fur-unternehmen';
export const requestMdEn = 'https://lemontaps.de/en/pages/digital-business-cards-for-companys';

export const rebuyCardsDe =
  'https://docs.google.com/forms/d/e/1FAIpQLSdWG3vCx6Q6MgOHLf93qRo2PoknfGfuhSQ7Kkz_EKBkd0HSSQ/viewform';
export const rebuyCardsEn =
  'https://docs.google.com/forms/d/e/1FAIpQLSfLDjiIMA7r8JsDsQYFS6RCpVckhJwnW0dLxK_NueE5m8mRtA/viewform';

export const initialBuyCardsDe =
  'https://docs.google.com/forms/d/e/1FAIpQLSd-_3HOLGhEYovvJVwaw9Fq0t_0m9-Z2BCyaBSoajvtwd6h6g/viewform?usp=sf_link';
export const initialBuyCardsEn =
  'https://docs.google.com/forms/d/e/1FAIpQLSeUdELUzPIOSe1XBdP_YOObNv1h_NRHlgwS1FnbVYyTAg_1WQ/viewform?usp=sf_link';

export const buySampleCardsDe = 'https://lemontaps.de/collections/hidden-products';
export const buySampleCardsEn = 'https://lemontaps.de/en/collections/hidden-products';

export const requestOfferLinkDe = 'https://lemontaps.de/pages/angebot-anfragen';
export const requestOfferLinkEn = 'https://lemontaps.de/en/pages/request-offer';

export const contactSalesLinkDe = 'https://lemontaps.de/pages/vertrieb-kontaktieren';
export const contactSalesLinkEn = 'https://lemontaps.de/en/pages/vertrieb-kontaktieren';

export const upgradeProLinkDe = 'https://lemontaps.de/pages/teams-upgrade';
export const upgradeProLinkEn = 'https://lemontaps.de/en/pages/teams-upgrade';

export const FRESHWORKS = {
  de: {
    contactFormUrl:
      'https://lemontaps.myfreshworks.com/crm/sales/web_forms/c7571b479804f2a461652bd847e11e8fd3c43aa4d9b0b455e7cb0acfaacd0acb/form.js',
    contactFormId: 'fs_c7571b479804f2a461652bd847e11e8fd3c43aa4d9b0b455e7cb0acfaacd0acb',
  },
  en: {
    contactFormUrl:
      'https://lemontaps.myfreshworks.com/crm/sales/web_forms/3d7c1f6e9737e8628773c65a615af66f11e377326550b1473ec38fbcd6e73fc1/form.js',
    contactFormId: 'fs_3d7c1f6e9737e8628773c65a615af66f11e377326550b1473ec38fbcd6e73fc1',
  },
};

export const VIDEOS = {
  LEADS: {
    DE: {
      src: 'https://www.youtube.com/embed/kvMXmOdEtTs',
      title: 'Das Lemontaps Lead Gen Feature - Erfasse effizient Deine Leads!',
    },
    EN: {
      src: 'https://www.youtube.com/embed/8mkLpWK4A60',
      title: 'The Lemontaps Lead Gen Feature - Capture your leads efficiently!',
    },
  },
  MD: {
    DE: {
      src: 'https://www.youtube.com/embed/asrj1yHQuAQ',
      title: 'Lemontaps Firmenlösung Demo - Werde jetzt ein digitaler Vorreiter!',
    },
    EN: {
      src: 'https://www.youtube.com/embed/OesAWf0wHAY',
      title: 'Lemontaps Company Solution Demo - Become a digital pioneer now!',
    },
  },
};

export const PERMISSIONS = {
  READ: {
    THEME_PROFILES: 'read:theme-profiles',
    THEME_PROFILES_AUTH_DATA: 'read:theme-profiles-auth-data',
    CHECK_CODES: 'read:check-codes',
    THEME_GLOBAL: 'read:theme-global',
    THEME_UNIT: 'read:theme-unit',
  },
  WRITE: {
    THEME_PROFILES: 'write:theme-profiles',
    THEME_PROFILES_AUTH_DATA: 'write:theme-profiles-auth-data',
    THEME_GLOBAL: 'write:theme-global',
    THEME_UNIT: 'write:theme-unit',
  },
  CREATE: {
    THEME_PROFILE: 'create:theme-profile',
    THEME_UNIT: 'create:theme-unit',
  },
  DELETE: {
    THEME_PROFILES: 'delete:theme-profile',
    THEME_UNIT: 'delete:theme-unit',
  },
};

export const CLAIMS = {
  ID_TOKEN: {
    ROLES: 'https://lemontaps.com/claim/roles',
  },
};

export const LOCAL_STORAGE = {
  ltNoTrack: 'ltNoTrack', // 'true' | 'false'
  lt2lt: 'lt2lt', // 'true' | 'false'
  ltProfilesTableSort: 'ltProfilesTableSort', // Array<typeof COLUMNS_DEFAULT[number]['key']>
  ltContactsTableColumns: 'ltContactsTableColumns', // Array<typeof COLUMNS_DEFAULT[number]['key']>
  ltMdContactsTableColumns: 'ltMdContactsTableColumns', // Array<typeof COLUMNS_DEFAULT[number]['key']>
};

export const AVAILABLE_PERMISSION_LEVELS = (isUnitsEnabled: boolean) => [
  {
    name: 'plain',
    niceName: 'Employee',
    exclusiveGroups: [0],
    requiredGroups: [0],
    translateKey: 'employeeType.employee',
  },
  {
    name: 'theme_admin',
    niceName: 'Administrator',
    exclusiveGroups: [0],
    requiredGroups: [0],
    translateKey: 'employeeType.themeAdmin',
  },
  ...(isUnitsEnabled
    ? [
        {
          name: 'unit_admin',
          niceName: 'Unit-Admin',
          exclusiveGroups: [0],
          requiredGroups: [0],
          translateKey: 'employeeType.unitAdmin',
        },
      ]
    : []),
];

export const EMPLOYEE_STATUS = [
  { name: 'active', niceName: 'Active', translateKey: 'statusActive' },
  { name: 'invited', niceName: 'Invited', translateKey: 'statusInvited' },
  { name: 'uninvited', niceName: 'Uninvited', translateKey: 'statusUninvited' },
];

export const CSS_VARS = {
  LT_PRIMARY_BUTTON_COLOR_VAR: 'var(--lt-primary-button-color)',
  LT_PRIMARY_BUTTON_COLOR_NAME: '--lt-primary-button-color',
  LT_PRIMARY_BUTTON_COLOR_INVERTED_NAME: '--lt-primary-button-color-inverted',
  LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR: 'var(--lt-primary-button-color-inverted)',
  LT_DEFAULT_COLOR_VAR: 'var(--lt-default-color)',
  LT_DEFAULT_COLOR_NAME: '--lt-default-color',
  LT_DEFAULT_COLOR_INVERTED_NAME: '--lt-default-color-inverted',
  LT_DEFAULT_COLOR_INVERTED_VAR: 'var(--lt-default-color-inverted)',

  LT_BOTTOM_NAVBAR_HEIGHT_NAME: '--lt-bottom-navbar-height',
  LT_BOTTOM_NAVBAR_HEIGHT_VAR: 'var(--lt-bottom-navbar-height)',

  LT_MOBILE_HEADER_HEIGHT_NAME: '--lt-mobile-header-height',
  LT_MOBILE_HEADER_HEIGHT_VAR: 'var(--lt-mobile-header-height)',

  LT_DESKTOP_HEADER_HEIGHT_NAME: '--lt-desktop-header-height',
  LT_DESKTOP_HEADER_HEIGHT_VAR: 'var(--lt-desktop-header-height)',

  LT_SIDEBAR_WIDTH_NAME: '--lt-sidebar-width',
  LT_SIDEBAR_WIDTH_VAR: 'var(--lt-sidebar-width)',

  LT_SIDEBAR_WIDTH_TOGGLED_NAME: '--lt-sidebar-width-toggled',
  LT_SIDEBAR_WIDTH_TOGGLED_VAR: 'var(--lt-sidebar-width-toggled)',
} as const;

export const DEFAULT_COLOR = '#02e0b1' as const;
