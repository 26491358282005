import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { RequireAtMostOne, RequireOnlyOne } from '../../../shared/types/util';

interface PropsGeneric {
  text: string;
  link?: string;
  icon?: Icon;
  size?: string;
  weight?: string;
  bgColor?: string;
  width?: string;
  txtColor?: string;
  borderColor?: string;
  margin?: { left?: string; top?: string; right?: string; bottom?: string };
  padding?: { all?: string; left?: string; top?: string; right?: string; bottom?: string };
  alignSelf?: 'center' | 'left' | 'right';
  target?: string;
  external?: boolean;
  radius?: string;
  ready?: boolean;
  disabled?: boolean;
  onClick?: Function;
  style?: CSSProperties;
}
type Props = RequireOnlyOne<
  RequireAtMostOne<PropsGeneric, 'onClick' | 'external'>,
  'link' | 'onClick'
>;

interface IProps {
  size?: PropsGeneric['size'];
  weight?: PropsGeneric['weight'];
  icon?: Icon;
  bgColor?: PropsGeneric['bgColor'];
  borderColor?: PropsGeneric['borderColor'];
  margin?: PropsGeneric['margin'];
  padding?: PropsGeneric['padding'];
  alignSelf?: PropsGeneric['alignSelf'];
  txtColor?: PropsGeneric['txtColor'];
  width?: PropsGeneric['width'];
  radius?: PropsGeneric['radius'];
  disabled?: PropsGeneric['disabled'];
}

type Icon = {
  element?: React.ReactNode | string;
  left?: boolean;
  size?: string;
};

export const Button: React.FC<Props> = ({
  text,
  link,
  icon,
  external,
  radius,
  onClick,
  ready,
  ...rest
}) => {
  const targetProps = external
    ? { target: '_blank', rel: 'noopener noreferrer', href: link }
    : onClick
    ? { onClick }
    : { to: link ?? '#' };

  const ButtonContent = external
    ? ButtonContentAnchor
    : onClick
    ? ButtonContentButton
    : ButtonContentLink;

  return (
    <ButtonContent
      {...targetProps}
      {...rest}
      radius={radius}
      icon={icon}
      {...(ready && { disabled: !ready })}
    >
      {icon?.left && icon?.element && icon.element}
      {text}
      {!icon?.left && icon?.element && icon.element}
    </ButtonContent>
  );
};

const styling = css`
  font-size: ${(props: IProps) => props.size ?? '12px'};
  background-color: ${(props: IProps) => props.bgColor ?? '#4748EC'};
  color: ${(props: IProps) => props.txtColor ?? '#FFFFFF'};
  border: solid 1px ${(props: IProps) => props.borderColor ?? '#fff'};
  width: ${(props: IProps) => (props.width ? props.width : '75%')};
  padding: ${(props: IProps) => (props?.padding?.all ? props?.padding?.all : '1rem')};
  margin-top: 1rem;
  font-weight: ${(props: IProps) => props.weight ?? '400'};
  line-height: 16px;
  border-radius: ${(props: IProps) => (props.radius ? props.radius : '5px')};
  display: flex;
  justify-content: ${(props: IProps) => (props.icon?.element ? 'space-evenly' : 'center')};
  align-items: center;
  cursor: pointer;
  ${(props: IProps) => props?.alignSelf && `align-self: ${props.alignSelf} !important`};
  ${(props: IProps) => (props.disabled ? 'filter: grayscale(1);' : '')}
  ${(props: IProps) => props.margin?.left && `margin-left: ${props.margin.left}`};
  ${(props: IProps) => props.margin?.top && `margin-top: ${props.margin.top}`};
  ${(props: IProps) => props.margin?.right && `margin-right: ${props.margin.right}`};
  ${(props: IProps) => props.margin?.bottom && `margin-bottom: ${props.margin.bottom}`};
  ${(props: IProps) => props.padding?.left && `padding-left: ${props.padding.left}`};
  ${(props: IProps) => props.padding?.top && `padding-top: ${props.padding.top}`};
  ${(props: IProps) => props.padding?.right && `padding-right: ${props.padding.right}`};
  ${(props: IProps) => props.padding?.bottom && `padding-bottom: ${props.padding.bottom}`};

  & > svg {
    margin: 0 1rem;
    ${(props: IProps) => props.icon?.size && `font-size: ${props.icon?.size}`};
    ${(props: IProps) => props.icon?.element && props.txtColor && `color: ${props.txtColor}`};
  }
  @media (max-width: 767px) {
    font-size: 10px;
  }
  &:hover,
  &:visited {
    color: ${(props: IProps) => props.txtColor ?? '#FFFFFF'};
  }

  ${(props: IProps) =>
    props.disabled === true &&
    `
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
  `}

  &:focus-visible {
    outline: 1px solid ${(props: IProps) => props.bgColor};
    outline-offset: 2px;
  }
`;
const ButtonContentLink = styled(Link)`
  ${styling}
`;
const ButtonContentAnchor = styled.a`
  ${styling}
`;
const ButtonContentButton = styled.button`
  outline: none;
  border: none;
  ${styling}
`;
