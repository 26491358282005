type NestedArray<T> = Array<T | NestedArray<T>>;

export const flatten = <T>(arr: NestedArray<T>): T[] => {
  let allFlat = true;
  let out: NestedArray<T> = [];
  for (let el of arr) {
    if (Array.isArray(el)) {
      allFlat = false;
      out = [...out, ...el];
    } else {
      out.push(el);
    }
  }
  if (allFlat) return out as T[];
  else return flatten(out);
};

export function removeDuplicates<T>(
  arr: T[],
  compareFn: (a: T, b: T) => boolean = (a, b) => a === b,
): T[] {
  const result: T[] = [];

  let outerIdx = 0;
  let innerIdx = 0;
  for (const item of arr) {
    let isDuplicate = false;

    innerIdx = 0;
    for (const uniqueItem of arr) {
      if (outerIdx !== innerIdx) {
        if (compareFn(item, uniqueItem)) {
          isDuplicate = true;
          break;
        }
      }
      innerIdx++;
    }

    if (!isDuplicate || innerIdx > outerIdx) {
      result.push(item);
    }
    outerIdx++;
  }

  return result;
}
