import { TextField } from '@mui/material';
import { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

export const SearchField = ({ onSearch, ...rest }) => {
  const [value, setValue] = useState('');
  const timeoutRef = useRef(null);
  const { t } = useTranslation();

  const handleChange = value => {
    setValue(value);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onSearch(value);
    }, 500);
  };

  return (
    <TextField
      color='secondary'
      label={t('search')}
      placeholder={t('leads.searchPlaceholder')}
      value={value}
      onChange={({ target: { value } }) => handleChange(value)}
      InputProps={{ startAdornment: <SearchIcon sx={{ mr: 1 }} /> }}
      {...rest}
    />
  );
};
