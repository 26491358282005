import { dispatchTypes } from '../../index';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Link } from '../../../../shared/types/api';
import { saveCompanyDetailsLinksAPI } from '../../../../infrastructure/apis/edit-profile';

export const saveCompanyDetailsLinks =
  (
    links: Link[],
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    onSuccess: () => void,
    onError: () => void,
  ) =>
  async dispatch => {
    try {
      const uploadResponse = await saveCompanyDetailsLinksAPI(links, getAccessTokenSilently);
      if (uploadResponse.data.isSuccess) {
        dispatch({
          type: dispatchTypes.EDITPROFILE.SAVELINKSUCCESS,
          apiResponse: uploadResponse.data,
        });

        if (onSuccess) onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }
  };
