import { Account, AnalyticsData, LinkType, UploadedFileType } from '@/shared/types/api';
import { CLAIMS } from './constants';
import { createVcardFormattedString } from '../shared/util';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import config from '../config/config';
import { TFunction } from 'react-i18next';
import { IdToken } from '@auth0/auth0-react';
import toast, { DefaultToastOptions, Renderable, ValueOrFunction } from 'react-hot-toast';
import i18n from '../config/i18n';
import { getAuth0TokenScopeString } from '@/util';
import MUIButton from '@mui/material/Button';
import { v4 as uuid } from 'uuid';
import { LINK_TYPE_MODES } from '@/shared/constants';

export const validatePassword = (value: string): boolean => {
  //minimum of 8 characters
  //atleast one special characters and number
  const pattern = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{8,})');
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isAlphabetsWithSpecificChars = (value: string) => {
  var pattern = new RegExp('^[a-zA-Z öüäß.,-]*$');
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isOnlyAlphabets = (value: string): boolean => {
  var pattern = new RegExp('^[a-zA-Z ]*$');
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isOnlyNumbers = (value: string): boolean => {
  var pattern = new RegExp('^[0-9]*$');
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

// allows only numbers with at least one space in between or at the end
export const isOnlyNumbersAndSpaces = (value: string): boolean => {
  const pattern: RegExp = /^([0-9\s\-x]+)?$/;
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isOnlyNumbersAndSpacesAndDashes = (value: string): boolean => {
  const pattern: RegExp = /^([0-9\s\-x-]+)?$/;
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const toOnlyNumbersAndSpacesAndDashes = (value: string): string => {
  const pattern = /[^0-9\s\-x-]+/gm;
  return value.replace(pattern, '');
};

export const isAlphaNumeric = (value: string): boolean => {
  var pattern: RegExp = new RegExp('^[0-9a-zA-Z]*$');
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isAlphaNumericLowerCase = (value: string): boolean => {
  var pattern = new RegExp('^[0-9a-z]*$');
  if (pattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isNotEmptyArray = (arr: Array<any>) => {
  return arr && arr.length > 0;
};

export const toPhoneNumber = (txt: string): string => {
  //only numbers and spaces allowed
  return txt.replace(/[^0-9- ]/g, '');
};

const getFileType = (file: File): string => {
  let fileParts = file.name.split('.');
  return fileParts[fileParts.length - 1].toLowerCase();
};

const isFileSupported = (uploadedFile: File, fileType: UploadedFileType) => {
  let supportedTypes: string[] = [];
  if (fileType === 'imageWithoutSvg') {
    supportedTypes = ['jpg', 'jpeg', 'png', 'gif'];
  } else if (fileType === 'imageWithSvg') {
    supportedTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
  } else if (fileType === 'file') {
    supportedTypes = ['pdf', 'jpg', 'jpeg', 'png', 'mp4', 'gif', 'mov'];
  }

  let type = getFileType(uploadedFile);
  return supportedTypes.includes(type.toLowerCase());
};

const isFileSizeValid = (uploadedFile: File, fileType: UploadedFileType): boolean => {
  if (fileType === 'imageWithSvg' || fileType === 'imageWithoutSvg') {
    return uploadedFile.size > 20971520 ? false : true;
  } else if (fileType === 'file') {
    return uploadedFile.size > 41943040 ? false : true;
  }
  return false;
};

export const isFileValid = (
  uploadedFile: File,
  fileType: UploadedFileType,
  t: TFunction,
): string => {
  if (isFileSupported(uploadedFile, fileType)) {
    if (isFileSizeValid(uploadedFile, fileType)) {
      return '';
    } else {
      if (fileType === 'imageWithSvg' || fileType === 'imageWithoutSvg') {
        return t('maxUpload', { mb: 20 });
      }
      if (fileType === 'file') {
        return t('maxUpload', { mb: 40 });
      }
    }
  } else if (fileType === 'imageWithSvg' || fileType === 'imageWithoutSvg') {
    return t('uploadRestrictedTo');
  } else if (fileType === 'file') {
    return t('fileTypeNotSupported');
  }
};

export const getPdfPageCount = (pdfFile: File): Promise<number | null> => {
  return new Promise(resolve => {
    if (pdfFile.type !== 'application/pdf') {
      resolve(null);
    } else {
      const reader = new FileReader();
      reader.readAsBinaryString(pdfFile);
      reader.onloadend = function () {
        const matchResult: RegExpMatchArray = (reader.result as string).match(
          /\/Type[\s]*\/Page[^s]/g,
        );
        if (matchResult?.length) {
          const pageCount: number = matchResult.length;
          resolve(pageCount);
        } else {
          resolve(null);
        }
      };
    }
  });
};
export const hasRole = (idTokenClaims: IdToken, role: string[]): boolean => {
  return (
    idTokenClaims &&
    Array.isArray(idTokenClaims[CLAIMS.ID_TOKEN.ROLES]) &&
    idTokenClaims[CLAIMS.ID_TOKEN.ROLES].some(c => role.includes(c))
  );
};

export const getTimeStr = (date: Date): string => {
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

/**
 *
 * @param hex string - input colour
 * @param bw boolean - black/white mode
 * @param fallback string - fallback colour
 * @returns string coulour after inverting the input colour to it's equivalent inverted version
 * `bw` is true ? the returned value is either black or white
 */

export const invertHex = (hex: string, bw?: boolean, fallback = '#ffffff'): string => {
  if (!hex?.startsWith('#')) return fallback;
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return fallback;
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  let R = (255 - r).toString(16),
    G = (255 - g).toString(16),
    B = (255 - b).toString(16);
  return '#' + padZero(R) + padZero(G) + padZero(B);
};

export const lightenHex = (hex: string, percent: number = 0) => {
  if (!hex?.startsWith('#')) return '';
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  return percent > 0
    ? 'rgba(' + r + ',' + g + ',' + b + ',' + percent / 100 + ')'
    : 'rgb(' + r + ',' + g + ',' + b + ')';
};

function padZero(str: string, len?: number): string {
  len = len || 2;
  let zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

/**
 *
 * @param hex string - input colour
 * @returns boolean - whether the input hex is a dark/light colour
 */

export const isDarkHex = (hex: string): boolean => {
  if (!hex?.startsWith('#')) return false;
  if (hex?.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return false;
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 < 186;
};

/**
 *
 * @param hex string - hex colour
 * @param shift number - the amount of colour shifting to be applied
 * @returns rgb() string - the converted hex input after adding the amount of colour shifting
 */

export const hexVariant = (hex: string, shift: number): string => {
  if (!hex?.startsWith('#')) return;
  let { r, g, b } = hexToRgb(hex);
  let { h, s, v } = rgbToHsv(r, g, b);
  return hsvToRgb(h, s, v + shift);
};

/**
 *
 * @param hex string - hex colour
 * @returns {r,g,b} - converted input hex to rgb colour params
 */
const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  if (!hex?.startsWith('#')) return { r: 0, g: 0, b: 0 };
  if (hex?.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  return { r, g, b };
};

/**
 *
 * @param h
 * @param s
 * @param v
 * @returns string - converted rgb colour
 */

const hsvToRgb = (h: number, s: number, v: number): string => {
  let f = (n: number, k: number = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return 'rgb(' + f(5) + ',' + f(3) + ',' + f(1) + ')';
};

/**
 *
 * @param r
 * @param g
 * @param b
 * @returns {h,s,v} - converted hsv colour
 */
const rgbToHsv = (r: number, g: number, b: number): { h: number; s: number; v: number } => {
  let v = Math.max(r, g, b),
    c = v - Math.min(r, g, b);
  let h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);

  return { h: 60 * (h < 0 ? h + 6 : h), s: v && c / v, v };
};

export const setSessionName = (
  type: string,
  linkEntityId: number,
  fileEntityId: number,
  meta: object,
): string => {
  const username = window.location.pathname.split('/')[1];
  const linkOrFile = (linkEntityId || fileEntityId) ?? '';
  let metaKeys = [];
  if (meta) {
    for (const metaKey in meta) {
      metaKeys.push(metaKey);
    }
  }
  const metaName = meta ? metaKeys.map(key => `${key}-${meta[key]}`) : '';
  return type + '-' + linkOrFile + '-' + metaName + '-' + username;
};

export const setSession = (session: AnalyticsData) => {
  sessionStorage.setItem(
    setSessionName(session.type, session.linkEntityId, session.fileEntityId, session.meta),
    JSON.stringify(session),
  );
};

export const checkSameSession = (
  type: string,
  linkEntityId: number,
  fileEntityId: number,
  meta: object,
): boolean => {
  let session = sessionStorage.getItem(setSessionName(type, linkEntityId, fileEntityId, meta));
  if (session) {
    let sessionObj = JSON.parse(session);
    if (sessionObj?.meta) {
      return JSON.stringify(sessionObj.meta) === JSON.stringify(meta);
    }

    if (fileEntityId || linkEntityId || meta) {
      if (fileEntityId) {
        return sessionObj.fileEntityId === fileEntityId;
      } else if (linkEntityId) {
        return sessionObj.linkEntityId === linkEntityId;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const createVCard = (
  account: Account,
  profileUrl: string,
  lang: string,
  exclude: Parameters<typeof createVcardFormattedString>[0]['exclude'] = [],
): string => {
  const vCardString = createVcardFormattedString({
    account,
    lang: lang || 'en',
    profileImage: false,
    note: false,
    url: profileUrl,
    exclude,
  });

  return vCardString;
};

export async function withSilentAccessToken<T>(
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  fct: (token: string) => Promise<T>,
  additionalScopes: string[] = [],
  onTrigger: Function = null,
): Promise<T> {
  onTrigger?.();
  const scope = getAuth0TokenScopeString(...additionalScopes);
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope,
  });

  return fct(token);
}

// export function showLoadingToaster<T>(
//   promiseFct: (resolve: (data: T) => void, reject: (err?: any) => void) => void,
//   messages: { loading?: string; success: string; error: string },
// ): void {
//   toast.promise<T>(new Promise(promiseFct), {
//     loading: messages.loading ?? i18n.t('Loading') + '...',
//     success: messages.success,
//     error: messages.error,
//   });
// }

export function showLoadingToaster<T>(
  fct: () => Promise<T>,
  messages: {
    loading?: Renderable;
    success: ValueOrFunction<Renderable, T>;
    error: ValueOrFunction<Renderable, any>;
  },
  options?: DefaultToastOptions,
): void {
  toast.promise<T>(
    new Promise((res, rej) => {
      fct()
        .then(val => res(val))
        .catch(err => rej(err));
    }),
    {
      loading: messages.loading ?? i18n.t('Loading') + '...',
      success: messages.success,
      error: messages.error,
    },
    options,
  );
}
export function showManuallyDismissableLoadingToaster<T>(
  fct: () => Promise<T>,
  messages: {
    success: ValueOrFunction<Renderable, T>;
    error: ValueOrFunction<Renderable, any>;
  },
  options?: DefaultToastOptions,
): string {
  const toastId = uuid();
  toast.promise<T>(
    new Promise((res, rej) => {
      fct()
        .then(val => res(val))
        .catch(err => rej(err));
    }),
    {
      loading: i18n.t('Loading') + '...',
      success: res => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: '.5rem',
          }}
        >
          {messages.success instanceof Function ? messages.success(res) : messages.success}
          <MUIButton variant='text' color='success' onClick={() => toast.dismiss(toastId)}>
            {i18n.t('Dismiss')}
          </MUIButton>
        </div>
      ),
      error: err => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: '.5rem',
          }}
        >
          {messages.error instanceof Function ? messages.error(err) : messages.error}
          <MUIButton variant='text' color='error' onClick={() => toast.dismiss(toastId)}>
            {i18n.t('Dismiss')}
          </MUIButton>
        </div>
      ),
    },
    { ...(options || {}), id: toastId, duration: Infinity },
  );
  return toastId;
}

export const isEmptyObject = (obj: Object) => (Object.keys(obj).length === 0 ? true : false);

export const isEmptyObjectValues = (obj: Object) =>
  !Object.values(obj)?.some(value =>
    Array.isArray(value)
      ? value.some(v =>
          Object.values(v).every(val => (Array.isArray(val) ? val.some(Boolean) : Boolean(val))),
        )
      : typeof value === 'string'
      ? value?.length > 0
      : value !== null && value !== undefined,
  );

export const getFullLink = (link: string, linkType: LinkType) => {
  return linkType.mode === LINK_TYPE_MODES.LINK
    ? link.startsWith('https')
      ? link
      : 'https://' + link
    : linkType.modeMeta + link;
};
