import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { TFunction } from 'i18next';
import { Popup } from '../common';
import config from '../../../config/config';
import { AiOutlineArrowRight } from 'react-icons/ai';
import {
  Title,
  PopupWrapper,
  SecondaryText,
  SecondaryButton,
} from '../../pages/md/profiles/common/styles';
import { PERMISSIONS } from '../../../infrastructure/constants';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import axios from 'axios';
import QRCode from 'qrcode.react';
import ScannerCamera from './scanner-camera';
import { useAppSelector } from '@/application/hooks';
import { Theme } from '@/shared/types/api';

interface Props {
  codeIsChecking: boolean;
  onCloseClick: () => void;
  onHandleCode: (_code: string) => Promise<void>;
  t: TFunction;
  mobileScan?: {
    connectAccountId: string;
    domain: string;
    lang?: string;
  };
}

const ScannerPopup = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const [scanResult, setScanResult] = useState(null);
  const [prevScan, setPrevScan] = useState(null);
  const [newScan, setNewScan] = useState(false);
  const [nextTry, setNextTry] = useState(true);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const { t } = props;
  const [mobileToken, setMobileToken] = useState<string>();

  const scanSetters = { setScanResult, setNewScan };

  useEffect(() => {
    if (scanResult?.data) {
      let qrString = scanResult.data;
      // domain matching
      let ltDomain = config.REACT_APP_URL.split('://')[1];
      let themeDomain = theme?.domain.split('://')[1];
      let codeToTest = '';
      if (qrString.includes(ltDomain) || qrString.includes(themeDomain)) {
        let url = new URL(qrString);
        url.pathname.length > 1 && (codeToTest = url.pathname.split('/')[1]);
      }

      if (!props.codeIsChecking && newScan && (codeToTest !== prevScan || nextTry)) {
        if (codeToTest.length > 0) {
          props.onHandleCode(codeToTest);
          setPrevScan(codeToTest);
        } else {
          toast.error(t('codeRead.missed'));
          setPrevScan('');
        }
        setTimeout(() => {
          setNextTry(true);
        }, 7000);
        setNewScan(false);
      }
      setNextTry(false);
    }
  }, [scanResult, props, t, prevScan, nextTry, newScan, theme?.domain]);

  const handleMobileQR = () => {
    setShowMobilePopup(true);
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios
          .post(
            config.API_BASE_URL + `business/md2phone`,
            { connectAccountId: props?.mobileScan.connectAccountId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            const result = res.data as {
              isSuccess: boolean;
              data?: { token: string };
              message?: string;
            };
            if (result.isSuccess) {
              setMobileToken(result.data.token);
            }
          })
          .catch(e => {
            toast.error(t('error.general'));
          })
          .finally(() => toast.dismiss('mobile-scan-token')),
      [PERMISSIONS.WRITE.THEME_PROFILES],
      () => {
        toast.loading('', { id: 'mobile-scan-token' });
        setMobileToken(undefined);
      },
    );
  };

  return (
    <>
      <Popup onCloseClick={props.onCloseClick}>
        <PopupWrapper>
          {showMobilePopup ? (
            mobileToken && (
              <>
                <Title>{t('md2Phone.popupStep1')}</Title>
                <QRCode
                  value={`${props?.mobileScan?.domain}/md/mobile/md2phone?token=${
                    props.mobileScan?.lang
                      ? `${mobileToken}&lang=${props.mobileScan.lang}`
                      : mobileToken
                  }`}
                  renderAs='svg'
                  style={{ marginTop: '2rem', marginBottom: '2rem' }}
                />
                <Title>{t('md2Phone.popupStep2')}</Title>
              </>
            )
          ) : (
            <>
              <Title>{t('codeRead.title')}</Title>
              <ScannerCamera scanSetters={scanSetters} />
              {props?.mobileScan && (
                <SecondaryText>
                  {t('codeRead.problems')}
                  <SecondaryButton onClick={handleMobileQR}>
                    {t('codeRead.startNow')}
                    <AiOutlineArrowRight />
                  </SecondaryButton>
                </SecondaryText>
              )}
            </>
          )}
        </PopupWrapper>
      </Popup>
    </>
  );
};

export default ScannerPopup;
