import { Box, Fade, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';

export type MenuItemType = {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
};

type Props = {
  items: MenuItemType[];
  toggleElement: React.ReactNode;
};

export const CustomMenuList = ({ items, toggleElement }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleElementRef = useRef(null);

  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(toggleElementRef.current);
  };

  const handleClose = (e: React.MouseEvent) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box ref={toggleElementRef} onClick={handleClick}>
        {toggleElement}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              setAnchorEl(null);
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
