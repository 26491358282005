import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from './common';
import AddAddress from './add-address';
import AddressRecord from './addressRecord';
import DeletePopup from '../../../components/common/deletePopup';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '../../../../config/config';
import { CSS_VARS, PERMISSIONS } from '../../../../infrastructure/constants';
import toast from 'react-hot-toast';
import { Account } from '../../../../shared/types/api';
import { useAppSelector } from '../../../../application/hooks';
import { useDispatch } from 'react-redux';
import { dispatchTypes } from '../../../../application/actions';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import { FEATURE } from '../../../../shared/constants';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import FeatureTeaser from '../teaser/featureTeaser';
import teaserImage from '../../../images/teaser/data-templates.png';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';

export interface AddressFields {
  id?: number;
  label: string;
  addressee: string;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  city: string;
  country: string;
  isDefault: boolean;
}

const CustomDataAddresses = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'data-addresses-feature-loader-id');

  if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
    return <FeatureContent />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.dataTemplates')} imageSrc={teaserImage} />;
  }
};

export default CustomDataAddresses;

const FeatureContent = () => {
  const { t } = useTranslation();
  const [addAddress, setAddAddress] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<AddressFields>();
  const [addresses, setAddresses] = useState<Array<AddressFields>>([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<AddressFields>();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const account: Account = useAppSelector(state => state.account);
  const dispatch = useDispatch();

  useDeferredLoader(loading, 'data-templates-address-loading-id');

  const updateThemeDefaultAddressField = useCallback(
    (newDefaultId: number | null) => {
      dispatch({
        type: dispatchTypes.COMMON.UPDATEACCOUNT,
        account: {
          ...account,
          theme: {
            ...account.theme,
            themeInternal: {
              ...account.theme.themeInternal,
              defaultAddressTemplateId: newDefaultId,
            },
          },
        },
      });
    },
    [account, dispatch],
  );

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `theme/address-templates`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_GLOBAL],
      () => setLoading(true),
    )
      .then(res => {
        setAddresses(
          res.data.data.map(a => ({
            ...a,
            isDefault: a.id === account.theme?.themeInternal.defaultAddressTemplateId,
          })),
        );
        setLoading(false);
      })
      .catch(err => {
        toast.error(t('addressesTab.errorReadAddress'));
        setAddresses([]);
        setLoading(false);
      });
  }, [account?.theme?.themeInternal?.defaultAddressTemplateId, getAccessTokenSilently, t]);

  const addNewAddress = () => {
    setAddressToEdit(null);
    setAddAddress(true);
  };
  const editAddress = (address: AddressFields) => {
    setAddressToEdit({
      ...address,
      // isDefault: account.theme.defaultAddressTemplateId === address.id,
    });
    setAddAddress(true);
  };
  const deleteClick = (address: AddressFields) => {
    setItemToDelete(address);
    setShowDeletePopup(true);
  };

  const saveAddress = (address: AddressFields, isEdit: boolean) => {
    if (isEdit) {
      const former = addresses.find(a => a.id === address.id);
      if (former.isDefault !== address.isDefault) {
        if (address.isDefault) updateThemeDefaultAddressField(address.id);
        else if (former.isDefault) updateThemeDefaultAddressField(null);
      }
      setAddresses(addresses =>
        addresses.map(a =>
          a.id === address.id
            ? {
                ...address,
                isDefault: !!address.isDefault,
              }
            : {
                ...a,
                isDefault: address.isDefault ? false : a.isDefault,
              },
        ),
      );
    } else {
      setAddresses(addresses => [
        address,
        ...addresses.map(a => ({ ...a, isDefault: address.isDefault ? false : a.isDefault })),
      ]);
      if (address.isDefault) updateThemeDefaultAddressField(address.id);
    }
  };

  return (
    <>
      <Header>
        <HorizontalLine>
          {t('addressesTab.header')}
          <Spacer size={9} />
          <TooltipInfo text={t('addressesTab.headerTooltip')} placement='right' />
        </HorizontalLine>
        <AddButton onClick={addNewAddress}>
          <AiOutlinePlusCircle size={17} />
          <Spacer size={9} />
          {t('addressesTab.addAddresses')}
        </AddButton>

        {addAddress && (
          <AddAddress
            onBackClick={() => setAddAddress(false)}
            onSaveClick={saveAddress}
            editAddress={addressToEdit}
          />
        )}
      </Header>
      <AddressesContainer>
        {(!addresses || addresses.length === 0) && <Notice>{t('addressesTab.noAddresses')}</Notice>}
        {addresses.map(address => (
          <AddressRecord
            address={address}
            onEditClick={editAddress}
            onDeleteClick={deleteClick}
            key={address.id}
          />
        ))}
      </AddressesContainer>
      {showDeletePopup && (
        <DeletePopup
          onCancel={() => setShowDeletePopup(false)}
          label='Are you sure you want to delete address?'
          onDeleteClick={() => {
            setLoading(true);
            if (account.theme?.themeInternal.defaultAddressTemplateId === itemToDelete.id) {
              toast.error(t('addressesTab.cannotDeleteDefaultAddress'));
              setLoading(false);
              return;
            }

            withSilentAccessToken(
              getAccessTokenSilently,
              token =>
                axios.delete(config.API_BASE_URL + `theme/address-templates/${itemToDelete.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }),
              [PERMISSIONS.WRITE.THEME_GLOBAL],
            )
              .then(res => {
                setAddresses(addresses.filter(address => address.id !== itemToDelete.id));
                setLoading(false);
              })
              .catch(err => {
                toast.error(t('addressesTab.ErrorDeletingAddress'));
                setLoading(false);
              });

            setShowDeletePopup(false);
          }}
        />
      )}
    </>
  );
};

const Header = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HorizontalLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AddressesContainer = styled.div``;

const Spacer = styled.div`
  min-width: ${(props: { size: number }) => props.size}px;
`;
const AddButton = styled(PrimaryButton)`
  padding: 15px 25px;
  font-size: 1.2rem;
  max-width: 170px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
`;

const Notice = styled.div`
  margin: 10rem auto;
  text-align: center;
`;
