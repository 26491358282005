import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../../../../application/hooks';
import { Link } from '../../../../../../../shared/types/api';
import { BulkLinkProps } from '../../inputs-form';

export type CustomLinkType = Pick<
  Link,
  'id' | 'name' | 'link' | 'isCustomLink' | 'themeLinkOption' | 'linkType'
>;

interface Props {
  bulkLinkProps: BulkLinkProps;
  showLinkTypePopup: any;
  selectedLinkRef: any;
  onClosePopups: () => void;
  setCounterToForceRender: Function;
}

const useBulk = (props: Props) => {
  const commonLinks = useAppSelector(state => state.md.commonLinks);

  const [bulkOptionLinks, setBulkOptionLinks] = useState<Array<CustomLinkType>>([]);
  const [bulkCustomLinks, setBulkCustomLinks] = useState<Array<CustomLinkType>>([]);
  const [tempCommonLinks] = useState<Array<CustomLinkType>>([...commonLinks]);

  // BULK PROPS
  const { bulkLinkProps } = props;
  const { bulkEdit, bulkLinks, setBulkLinks, bulkDeletedLinks } = bulkLinkProps || {};
  // usePopup PROPS
  const { showLinkTypePopup, selectedLinkRef, onClosePopups } = props;

  useEffect(() => {
    if (bulkOptionLinks.length || bulkCustomLinks.length) {
      bulkLinkProps?.setBulkLinks([...tempCommonLinks, ...bulkOptionLinks, ...bulkCustomLinks]);
    } else {
      bulkLinkProps?.setBulkLinks(tempCommonLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCommonLinks, bulkOptionLinks, bulkCustomLinks, setBulkLinks]);

  const updateStateUponDeletion = async (optionsMode: boolean, idToDelete?: number, linkToUse?) => {
    const l = linkToUse || selectedLinkRef.current;
    let index: number, commonLinkIndex: number;

    if (optionsMode) {
      index = bulkLinks?.findIndex(link =>
        link.isCustomLink
          ? link.linkType.id === idToDelete
          : link.themeLinkOption.id === idToDelete,
      );
      if (index !== -1) {
        const [removed] = bulkLinks.splice(index, 1);
        if (removed['existsInDB']) bulkDeletedLinks.push(removed);
      }
      commonLinkIndex = tempCommonLinks?.findIndex(link =>
        link.isCustomLink
          ? link.linkType.id === idToDelete
          : link.themeLinkOption.id === idToDelete,
      );
      if (commonLinkIndex !== -1) tempCommonLinks.splice(commonLinkIndex, 1);
    } else {
      index = bulkLinks?.findIndex(i => i.id === l?.id && i.name === l?.name && i.link === l?.link);
      if (index !== -1) {
        const [removed] = bulkLinks.splice(index, 1);
        if (removed['existsInDB']) bulkDeletedLinks.push(removed);
      }

      commonLinkIndex = tempCommonLinks?.findIndex(
        i => i.id === l?.id && i.name === l?.name && i.link === l?.link,
      );
      if (commonLinkIndex !== -1) tempCommonLinks.splice(commonLinkIndex, 1);
    }
    props.setCounterToForceRender(prev => prev + 1);
  };

  const handleBulkCustomLinks = (linkItem: {
    linkTypeId: number;
    customLink: string;
    formerCustomLink: Link;
  }) => {
    const { linkTypeId, customLink, formerCustomLink } = linkItem;

    if (formerCustomLink) {
      let filtered = bulkCustomLinks.filter(link => link.id !== formerCustomLink.id);
      setBulkCustomLinks(filtered);
      updateStateUponDeletion(true, formerCustomLink.linkTypeId);
    }

    const duplicate = bulkLinks?.some(link => link.linkTypeId === linkTypeId);

    if (!duplicate && customLink) {
      let _customLink: CustomLinkType = {
        id: linkTypeId,
        name: `My ${showLinkTypePopup.name}`,
        link: customLink,
        isCustomLink: true,
        themeLinkOption: null,
        linkType: showLinkTypePopup,
      };

      setBulkCustomLinks(bulkCustomLinks.concat(_customLink));
    } else {
      // edit custom link
      let index = bulkLinks?.findIndex(l => l.isCustomLink && l.linkTypeId === linkTypeId);
      let updatedLink = bulkLinks?.find(l => l.isCustomLink && l.linkTypeId === linkTypeId);
      if (index !== -1) bulkLinks[index] = { ...updatedLink, link: customLink };
    }
  };

  const handleBulkOptionLinks = (linkItem: { newIds: number[]; deletedIds: number[] }) => {
    const { newIds, deletedIds } = linkItem;

    for (let i = 0; i < deletedIds?.length; i++) {
      updateStateUponDeletion(true, deletedIds[i]);
    }

    let deletedLinks = bulkOptionLinks?.filter(
      link => !link.isCustomLink && !deletedIds.includes(link.themeLinkOption.id),
    );

    let addedLinks = newIds?.map(newId => {
      let themeLinkOption = showLinkTypePopup.options.find(option => option.id === newId);
      themeLinkOption['linkType'] = showLinkTypePopup.themeLinkTypes[0];

      let _optionLink: CustomLinkType = {
        id: newId,
        name: themeLinkOption.name,
        link: themeLinkOption.link,
        isCustomLink: false,
        themeLinkOption,
        linkType: showLinkTypePopup,
      };

      return _optionLink;
    });

    setBulkOptionLinks(deletedLinks.concat(addedLinks));
  };

  const handleBulkEdit = (linkItem, isCreate: boolean) => {
    if (isCreate) {
      setBulkLinks(bulkLinks.concat(linkItem));
    } else {
      let index = bulkLinks?.findIndex(link => link.id === linkItem.id);
      if (index !== -1) bulkLinks[index] = linkItem;
    }
  };

  const handleBulkDelete = (link?) => {
    const l = link || selectedLinkRef.current;
    // DELETE FROM bulkLinks
    updateStateUponDeletion(false);
    // DELETE ALSO FROM bulkOptionLinks or bulkCustomLinks
    let optionIndex = bulkOptionLinks?.findIndex(i => i.id === l.id);
    let customIndex = bulkCustomLinks?.findIndex(i => i.id === l.id);
    if (optionIndex !== -1) bulkOptionLinks.splice(optionIndex, 1);
    if (customIndex !== -1) bulkCustomLinks.splice(customIndex, 1);

    onClosePopups();
  };

  const handleExtendLink = () => {
    let index = bulkLinks?.findIndex(l => l.id === selectedLinkRef.current.id);
    if (index !== -1) bulkLinks[index] = { ...selectedLinkRef.current, extendToAll: true };
    onClosePopups();
  };

  return {
    bulkEdit,
    bulkLinks,
    setBulkLinks,
    handleBulkEdit,
    handleBulkDelete,
    handleBulkOptionLinks,
    handleBulkCustomLinks,
    handleExtendLink,
  };
};

export default useBulk;
