import { useAppSelector } from '@/application/hooks';
import { doS3LeadUpload } from '@/infrastructure/apis/aws';
import { addLead, editLead } from '@/infrastructure/apis/leadGen';
import { LeadDataProps } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';

export const useLeadGen = () => {
  const account = useAppSelector(state => state.account);
  const { getAccessTokenSilently } = useAuth0();

  const getImageUrl = async (file?: File) => {
    return await doS3LeadUpload(
      `leads/${account.id}/${file?.name}`,
      file,
      account.id,
      getAccessTokenSilently,
    );
  };

  const createLeadHandler = async (
    leadData: Partial<LeadDataProps>,
    businessCardFile?: File,
    profileImageFile?: File,
  ) => {
    const [businessCardUrl, profileImageUrl] = await Promise.all([
      businessCardFile && getImageUrl(businessCardFile),
      profileImageFile && getImageUrl(profileImageFile),
    ]);

    const payload = {
      ...leadData,
      businessCardUrl: businessCardUrl || leadData.businessCardUrl,
      profileImageUrl: profileImageUrl || leadData.profileImageUrl,
    } as LeadDataProps;

    let { data: res } = await addLead(payload, account.id);
    if (!res.isSuccess) throw new Error('Error adding lead');
    return res.data;
  };

  const editLeadHandler = async (
    leadData: Partial<LeadDataProps>,
    businessCardFile?: File,
    profileImageFile?: File,
  ) => {
    const [businessCardUrl, profileImageUrl] = await Promise.all([
      businessCardFile && getImageUrl(businessCardFile),
      profileImageFile && getImageUrl(profileImageFile),
    ]);

    const payload = {
      ...leadData,
      businessCardUrl: businessCardUrl || leadData.businessCardUrl,
      profileImageUrl: profileImageUrl || leadData.profileImageUrl,
    } as LeadDataProps;

    let { data: res } = await editLead(payload, account.id, getAccessTokenSilently);
    if (!res.isSuccess) throw new Error('Error adding lead');
    return res.data;
  };

  return { createLeadHandler, editLeadHandler };
};
