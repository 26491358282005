import { useEffect, useMemo, useState } from 'react';
import { PageHeader, PageSubheader, WhitespaceContainer } from '../common';
import { useTranslation } from 'react-i18next';
import { DescriptionHeader, HeaderTextContainer, Spacer } from '../customization/common';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../../../config/config';
import { AnalyticsSummary } from '../../../../shared/types/api';
import { ANALYTICS_OPTIONS } from '../../../../shared/constants/global/analytics.constants';
import SummaryCard from './summaryCard';
import styled from 'styled-components';
import SummaryCardWithBackground from './summaryCardWithBackground';
import { DropDown } from '../../../components/generic/Dropdown';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import toast from 'react-hot-toast';
import useDeferredLoader from '../../../../infrastructure/hooks/useDeferredLoader';
import useDesktopView from '../../../../infrastructure/hooks/useDesktopView';
import { DESKTOP_BREAK_POINT } from '@/shared/constants';

const MdAnalytics = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { desktopView } = useDesktopView();
  const [data, setData] = useState<AnalyticsSummary>({
    raw: {
      contactsCollected: 0,
      contactsSaved: 0,
      fileClicksIndividual: [],
      fileClicksTotal: 0,
      linkClicksIndividual: [],
      linkClicksTotal: 0,
      profileEdits: 0,
      profileShares: 0,
    },
    savings: {
      cards: 0,
      co2: 0,
      energy: 0,
      flyers: 0,
      water: 0,
      wood: 0,
    },
  });
  const defaultOptionPeriod = useMemo(
    () =>
      ANALYTICS_OPTIONS.filter(option => option?.default)?.[0]?.value || ANALYTICS_OPTIONS[0].value,
    [],
  );
  const options = ANALYTICS_OPTIONS.map((option, idx) => ({
    id: idx,
    title: t(`analytics.options.${option.title}`),
    value: option.value,
  }));
  const [period, setPeriod] = useState(defaultOptionPeriod);

  useDeferredLoader(isLoading, 'md-analytics-toast');

  useEffect(() => {
    setIsLoading(true);
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + `data/theme-summary?period=${period}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
    )
      .then(res => {
        if (res.data?.success) {
          setData(res.data.data);
        }
      })
      .catch(err => {
        toast.error(t('error.general'));
      })
      .finally(() => setIsLoading(false));
    return () => {
      setIsLoading(false);
    };
  }, [getAccessTokenSilently, period, t]);

  return (
    <WhitespaceContainer>
      <Spacer size={20} />
      <HeaderTextContainer>
        {!desktopView && <PageHeader>{t('mdAnalytics.sideBarLabel')}</PageHeader>}
        <PageSubheader>{t('mdAnalytics.subHeader')}</PageSubheader>
      </HeaderTextContainer>
      <Spacer size={15} />
      <Header>
        <DescriptionHeader>{t('mdAnalytics.profileActivity')}</DescriptionHeader>
        <DropDown value={period} setValue={setPeriod} options={options} />
      </Header>

      <SummaryLine>
        <SummaryCard
          label={t('mdAnalytics.profileViews')}
          total={data?.raw?.profileShares}
          tooltipText={t('mdAnalytics.tooltip.profileView')}
        />
        <SummaryCard
          label={t('analytics.networking.contacts')}
          total={data?.raw?.contactsCollected}
          tooltipText={t('mdAnalytics.tooltip.contactsCollected')}
        />
        <SummaryCard
          label={t('analytics.networking.profileEdits')}
          total={data?.raw?.profileEdits}
        />
      </SummaryLine>
      <SummaryLine>
        <SummaryCard
          label={t('mdAnalytics.vCardsSaved')}
          total={data?.raw?.contactsSaved}
          tooltipText={t('mdAnalytics.tooltip.vCardsSaved')}
        />
        <SummaryCard
          label={t('analytics.clicks.links')}
          total={data?.raw?.linkClicksTotal}
          tooltipText={t('mdAnalytics.tooltip.totalLinksClicked')}
        />
        <SummaryCard
          label={t('analytics.clicks.files')}
          total={data?.raw?.fileClicksTotal}
          tooltipText={t('mdAnalytics.tooltip.totalFilesClicked')}
        />
      </SummaryLine>
      <Spacer size={15} />
      <DescriptionHeader>{t('mdAnalytics.profileSavings')}:</DescriptionHeader>
      <SummaryLine>
        <SummaryCardWithBackground
          label={t('analytics.saves.cards')}
          total={data?.savings?.cards}
        />
        <SummaryCardWithBackground
          label={t('analytics.saves.flyers')}
          total={data?.savings?.flyers}
        />
      </SummaryLine>
      <SummaryLine>
        <SummaryCard
          size='23%'
          center
          label={t('analytics.saves.wood')}
          total={Math.ceil(data?.savings?.wood)}
          unit='kg'
        />
        <SummaryCard
          size='23%'
          center
          label={t('analytics.saves.water')}
          total={Math.ceil(data?.savings?.water)}
          unit='l'
        />
        <SummaryCard
          size='23%'
          center
          label={t('analytics.saves.energy')}
          total={Math.ceil(data?.savings?.energy)}
          unit='kWh'
        />
        <SummaryCard
          size='23%'
          center
          label={t('analytics.saves.co2')}
          total={Math.ceil(data?.savings?.co2)}
          unit='kg'
        />
      </SummaryLine>
      <Spacer size={70} />
    </WhitespaceContainer>
  );
};

export default MdAnalytics;

const SummaryLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  text-align: center;
  @media (max-width: 662px) {
    justify-content: center;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  & > *:first-child {
    margin-right: 2rem;
  }
  @media (max-width: ${DESKTOP_BREAK_POINT}) {
    justify-content: space-between;
  }
  max-width: calc(90% + 50px);
`;
