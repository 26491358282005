type MuiColorSettings = {
  main: string;
  dark?: string;
  light?: string;
  contrastText?: string;
};

export type MuiPalette = {
  text?: {
    primary?: string;
    secondary?: string;
    disabled?: string;
  };
  sidebar?: MuiColorSettings;
  sidebarActive?: MuiColorSettings;
  primary?: MuiColorSettings;
  secondary?: MuiColorSettings;
  error?: MuiColorSettings;
  warning?: MuiColorSettings;
  info?: MuiColorSettings;
  success?: MuiColorSettings;
  primaryButton?: MuiColorSettings;
  action?: {
    active?: string;
    hover?: string;
    hoverOpacity?: number;
    selected?: string;
    selectedOpacity?: number;
    disabled?: string;
    disabledOpacity?: number;
    disabledBackground?: string;
    focus?: string;
    focusOpacity?: number;
    activatedOpacity?: number;
  };
  background?: {
    paper?: string;
    box?: string;
    video?: string;
    default?: string;
  };
  divider?: string;
};

export type MuiShape = {
  borderRadius?: number;
};

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Medium = 500,
  Bold = 700,
}

type MuiTypographySettings = {
  fontWeight: FontWeight;
  fontSize: string;
  letterSpacing: string;
};

export type MuiTypography = {
  fontFamily?: string;
  h1?: MuiTypographySettings;
  h2?: MuiTypographySettings;
  h3?: MuiTypographySettings;
  h4?: MuiTypographySettings;
  h5?: MuiTypographySettings;
  h6?: MuiTypographySettings;
  subtitle1?: MuiTypographySettings;
  subtitle2?: MuiTypographySettings;
  body1?: MuiTypographySettings;
  body2?: MuiTypographySettings;
  button?: MuiTypographySettings;
  caption?: MuiTypographySettings;
  overline?: MuiTypographySettings;
  // Custom settings
  pageTitle?: MuiTypographySettings;
  avatarLetter?: MuiTypographySettings;
  inputLabel?: MuiTypographySettings;
  helperText?: MuiTypographySettings;
  inputText?: MuiTypographySettings;
  tooltip?: MuiTypographySettings;
  cardHeader?: MuiTypographySettings;
};

export type MuiTheme = {
  typography?: MuiTypography;
  palette?: MuiPalette;
  shape?: MuiShape;
};
