import { useState } from 'react';
import { scanBusinessCard } from '@/infrastructure/apis/leadGen';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '@/application/hooks';
import { LoadingScreen } from './components/LoadingScreen';
import { CaptureScreen } from './components/CaptureScreen';
import { SelectionScreen } from './components/SelectionScreen';
import { getCropResults, transformScanResult, getResultsFromQrIfPossible } from './helpers';
import { EditScreen } from './components/EditScreen';
import { GDPRScreen } from './components/GDPRScreen';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { LEAD_ORIGINS, THEME_CONFIG_KEYS } from '@/shared/constants';
import { Box, styled } from '@mui/material';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { useLeadGen } from '../CreateEditLead/helpers';
import { FullScreenContainer } from './components/FullScreenContainer';
import { LeadDataProps } from '@/shared/types/api';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';

const DesktopOverlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  bgcolor: 'black',
  opacity: 0.5,
  zIndex: 100,
});

enum Step {
  CAPTURE = 'capture',
  LOADING = 'loading',
  SELECTION = 'selection',
  EDIT = 'edit',
  GDPR = 'gdpr',
}

export const BusinessCardScannerPage = () => {
  const [currentStep, setCurrentStep] = useState(Step.CAPTURE);
  const [businessCardData, setBusinessCardData] = useState({ file: null, dataUrl: '' });
  const businessCardDataUrl = businessCardData.dataUrl;

  const [scanResult, setScanResult] = useState<Partial<LeadDataProps>>({});
  const [leadData, setLeadData] = useState<Partial<LeadDataProps>>({});
  const [sendContactToEmail, setSendContactToEmail] = useState(false);
  const [selectedFields, setSelectedFields] = useState<Array<keyof LeadDataProps>>([]);
  const [saving, setSaving] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const account = useAppSelector(state => state.account);

  const flag_useFullScreenVideo = usePrivateFeatureFlag('businessCardScanner_useFullScreenVideo');

  const { createLeadHandler } = useLeadGen();

  const history = useHistory();

  const { t } = useTranslation();

  const { config: helpUrls } = usePrivateThemeConfig<Record<string, string>>(
    THEME_CONFIG_KEYS.HELP_RESOURCE_URLS,
  );
  const helpArticleUrl = helpUrls?.['business-card-scanner'];

  const { config: allowedFields } = usePrivateThemeConfig<(keyof LeadDataProps)[]>(
    THEME_CONFIG_KEYS.LEAD_DETAILS_KEYS,
  );
  const { config: gdprOptions } = usePrivateThemeConfig<string[]>(
    THEME_CONFIG_KEYS.GDPR_LEGAL_BASIS_OPTIONS,
  );

  const handleHelpClick = () => window.open(helpArticleUrl, '_blank');

  const handleFileCapture = async (file: File, dataUrl: string, isSelectedFile = false) => {
    setCurrentStep(Step.LOADING);
    const fd = new FormData();
    fd.append('image', file);

    try {
      let transformedData, boundingBox;
      const qrScanResult = await getResultsFromQrIfPossible(file); // try to read vcard qr code
      if (qrScanResult) {
        transformedData = qrScanResult;
      } else {
        const { data } = await scanBusinessCard(getAccessTokenSilently, fd, account.id);
        transformedData = transformScanResult(data['attributes']);
        boundingBox = data['boundingBox'];
      }

      const shouldCrop = isSelectedFile && boundingBox && !qrScanResult;

      setScanResult(transformedData);
      setSelectedFields(Object.keys(transformedData) as Array<keyof LeadDataProps>);
      setCurrentStep(Step.SELECTION);

      if (shouldCrop) {
        const { croppedFile, croppedDataUrl } = await getCropResults(dataUrl, boundingBox);
        setBusinessCardData({ file: croppedFile, dataUrl: croppedDataUrl });
      } else {
        setBusinessCardData({ file, dataUrl });
      }
    } catch (error) {
      toast.error(t('businessCardScanner.fileCaptureError'), { style: { zIndex: 1001 } });
      setCurrentStep(Step.CAPTURE);
    }
  };

  const handleSelectionFinish = () => {
    const data = Object.fromEntries(
      Object.entries(scanResult).filter(([key]) =>
        selectedFields.includes(key as keyof LeadDataProps),
      ),
    );

    setLeadData(data);
    setCurrentStep(Step.EDIT);
  };

  const handleEditFinish = () => {
    setCurrentStep(Step.GDPR);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const payload = {
        ...leadData,
        sendContactToEmail,
        origin: LEAD_ORIGINS.SCANNER,
      } as LeadDataProps;

      await createLeadHandler(payload, businessCardData.file);
      history.push('/contacts');
      toast.success(t('businessCardScanner.addContactSuccess'));
    } catch (error) {
      toast.error(t('businessCardScanner.addContactError'));
    }
    setSaving(false);
  };

  return (
    <>
      <FullScreenContainer> </FullScreenContainer>
      {currentStep === Step.CAPTURE && (
        <CaptureScreen
          onCapture={handleFileCapture}
          onHelpClick={helpArticleUrl ? handleHelpClick : undefined}
          useFullScreenVideo={flag_useFullScreenVideo}
        />
      )}
      {currentStep === Step.LOADING && (
        <LoadingScreen onCancel={() => setCurrentStep(Step.CAPTURE)} />
      )}
      {currentStep === Step.SELECTION && (
        <SelectionScreen
          businessCardUrl={businessCardDataUrl}
          onFinish={handleSelectionFinish}
          onBackClick={() => setCurrentStep(Step.CAPTURE)}
          onRetakeClick={() => setCurrentStep(Step.CAPTURE)}
          onToggle={newValues => setSelectedFields(newValues as Array<keyof LeadDataProps>)}
          selectedFields={selectedFields}
          scanResult={scanResult}
          allowedFields={allowedFields}
        />
      )}
      {currentStep === Step.EDIT && (
        <EditScreen
          sendContactToEmail={sendContactToEmail}
          setSendContactToEmail={setSendContactToEmail}
          businessCardUrl={businessCardDataUrl}
          onChange={values => setLeadData(values)}
          onFinish={handleEditFinish}
          onBackClick={() => setCurrentStep(Step.SELECTION)}
          onRetakeClick={() => setCurrentStep(Step.CAPTURE)}
          values={leadData}
          allowedFields={allowedFields}
        />
      )}
      {currentStep === Step.GDPR && (
        <GDPRScreen
          onBackClick={() => setCurrentStep(Step.EDIT)}
          onRetakeClick={() => setCurrentStep(Step.CAPTURE)}
          onFinish={handleSave}
          isSaving={saving}
          options={gdprOptions}
          selectedGdpr={leadData.gdprLegalBasis || ''}
          onChange={x => setLeadData({ ...leadData, gdprLegalBasis: x })}
          sendContactToEmail={sendContactToEmail}
        />
      )}
      <DesktopOverlay />
    </>
  );
};
