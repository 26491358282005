import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import closeIcon from '../../images/close.svg';
import { Overlay } from '../common/overlay';
import useCloseByEscape from '@/infrastructure/hooks/useCloseByEscape';

interface Props {
  profilePic: string;
  setProfilePic: Dispatch<SetStateAction<string>>;
}

const ImageModal = (props: Props): JSX.Element => {
  const onCloseHandler = () => props.setProfilePic(null);

  // close on keyboard escape
  useCloseByEscape(onCloseHandler);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if ((e.target as Element).classList.contains('overlay')) {
      props.setProfilePic(null);
    }
  };
  return (
    <>
      <Overlay onClick={handleClick} />
      <PopupWrapper>
        <PopupHeader>
          <img src={closeIcon} alt='Close' onClick={onCloseHandler} />
        </PopupHeader>
        <img src={props.profilePic} alt='Profile' />
      </PopupWrapper>
    </>
  );
};

export default ImageModal;

const PopupWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 25px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 11;
  width: 35%;
  max-height: 80vh;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > img {
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    border-radius: 20px;
    width: 70%;
  }
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3rem;

  img {
    height: 18px;
    cursor: pointer;
  }
`;
