import { useTranslation } from 'react-i18next';
import { DivisionTitle, RowDiv } from './styles';

import styled from 'styled-components';

import { Skeleton } from '@mui/lab';
import VideoComponent from './VideoComponent';

interface MainVideosContainerProps {
  videos: { title: string; duration: number; embedUrl: string }[];
  loading: boolean;
}

const MainVideosContainer = ({ videos, loading }: MainVideosContainerProps) => {
  const { t } = useTranslation();

  if (!loading && (!videos || videos.length === 0)) {
    return null;
  }

  return (
    <>
      <DivisionTitle>{t('mdguides.videoGuides.header')}</DivisionTitle>
      <RowDiv isWrap style={{ maxWidth: '120rem' }}>
        {loading
          ? Array.from({ length: 1 }, (_, i) => <StyledSkeleton variant='rounded' key={i} />)
          : videos.map((video, index) => (
              <VideoComponent
                video={video}
                index={index}
                duration={t('minute', { count: video.duration })}
              />
            ))}
      </RowDiv>
    </>
  );
};

export default MainVideosContainer;

const StyledSkeleton = styled(Skeleton)`
  width: 35rem;
  height: 14rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-radius: 15px;
`;
