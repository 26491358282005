import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import LtLoadingButton from '../LtLoadingButton';
import { LtDefaultActionButtonsProps } from './types';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { DeleteOutline, SaveOutlined } from '@mui/icons-material';

export default function LtDefaultActionButtons(props: LtDefaultActionButtonsProps) {
  const {
    cancelAction,
    onCancel,
    saveAction,
    onSave,
    confirmAction,
    onConfirm,
    deleteAction,
    onDelete,
    customActions,
    loading,
    disabled,
    noCancelOnDesktop,
  } = props;

  const { t } = useAppTranslation();

  const { desktopView: isDesktop } = useDesktopView();
  const responsiveProps = isDesktop
    ? { size: 'medium' as const, fullWidth: false }
    : { size: 'large' as const, fullWidth: true };

  if (!hasAnyAction(props)) return null;

  return (
    <>
      {hasCustomAction(props) &&
        customActions.map(action => (
          <LtLoadingButton
            variant='contained'
            color='primaryButton'
            {...responsiveProps}
            {...action}
          >
            {action.text || t(action.ttext)}
          </LtLoadingButton>
        ))}

      {hasCancel(props) && !(isDesktop && noCancelOnDesktop) && (
        <LtLoadingButton
          variant='outlined'
          color='primary'
          onClick={onCancel || cancelAction.onClick}
          {...responsiveProps}
          {...(cancelAction || {})}
        >
          {cancelAction?.text || t(cancelAction?.ttext ?? 'cancel')}
        </LtLoadingButton>
      )}
      {hasDelete(props) && (
        <LtLoadingButton
          onClick={onDelete || deleteAction.onClick}
          startIcon={<DeleteOutline />}
          loading={loading}
          disabled={disabled}
          color='error'
          variant='outlined'
          {...responsiveProps}
          {...(deleteAction || {})}
        >
          {deleteAction?.text || t(deleteAction?.ttext ?? 'delete')}
        </LtLoadingButton>
      )}
      {hasSave(props) && (
        <LtLoadingButton
          onClick={onSave || saveAction.onClick}
          startIcon={<SaveOutlined />}
          loading={loading}
          loadingPosition='start'
          disabled={disabled}
          {...responsiveProps}
          {...(saveAction || {})}
        >
          {saveAction?.text || t(saveAction?.ttext ?? 'save')}
        </LtLoadingButton>
      )}
      {hasConfirm(props) && (
        <LtLoadingButton
          onClick={onConfirm || confirmAction.onClick}
          loading={loading}
          disabled={disabled}
          {...responsiveProps}
          {...(confirmAction || {})}
        >
          {confirmAction?.text || t(confirmAction?.ttext ?? 'confirm')}
        </LtLoadingButton>
      )}
    </>
  );
}

export const hasCancel = (props: LtDefaultActionButtonsProps) =>
  !!(props.cancelAction || props.onCancel);
export const hasSave = (props: LtDefaultActionButtonsProps) => !!(props.saveAction || props.onSave);
export const hasConfirm = (props: LtDefaultActionButtonsProps) =>
  !!(props.confirmAction || props.onConfirm);
export const hasDelete = (props: LtDefaultActionButtonsProps) =>
  !!(props.deleteAction || props.onDelete);
export const hasCustomAction = (props: LtDefaultActionButtonsProps) =>
  props.customActions && props.customActions.length > 0;
export const hasAnyAction = (props: LtDefaultActionButtonsProps) =>
  hasCancel(props) ||
  hasSave(props) ||
  hasConfirm(props) ||
  hasDelete(props) ||
  hasCustomAction(props);
