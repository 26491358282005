import { PropsWithChildren } from 'react';
import Button from '@mui/material/Button';
import East from '@mui/icons-material/East';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CardRenderer } from '@/components/CardRenderer';

export const AcademyCardRenderer = (
  props: PropsWithChildren<{ title?: string; handleClick?: () => void }>,
) => {
  const { t } = useAppTranslation();
  return (
    <CardRenderer
      title={props.title}
      px={0.8}
      actions={
        props.handleClick
          ? [
              <Button
                endIcon={<East />}
                variant='text'
                sx={{ ml: 'auto' }}
                onClick={props.handleClick}
                disabled={!props.handleClick}
              >
                {t('homePage.toAcademy')}
              </Button>,
            ]
          : undefined
      }
    >
      {props.children}
    </CardRenderer>
  );
};
