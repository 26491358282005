import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Container, Text } from '../../components/generic';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '../../../application/hooks';
import { fetchDownloadLeads } from '../../../infrastructure/apis/leadGen';

import { CSS_VARS, routePaths } from '../../../infrastructure/constants';
import withNav from '../../../infrastructure/hoc/withNav';
import { Account } from '../../../shared/types/api';
import { invertHex, lightenHex } from '../../../infrastructure/helper';
import { DESKTOP_BREAK_POINT } from '../../../shared/constants';
import useDesktopView from '../../../infrastructure/hooks/useDesktopView';

const ExportLeads = () => {
  const { t } = useAppTranslation();
  const { firstName } = useAppSelector<Account>(state => state.account);
  const { desktopView } = useDesktopView();

  const { getAccessTokenSilently } = useAuth0();

  const downloadAllLeads = async (fileType: string) => {
    fetchDownloadLeads(firstName, fileType, getAccessTokenSilently);
  };

  return (
    <>
      <StyledContainer>
        <Content>
          {!desktopView && <Heading text={t('exportToCRM')} />}
          <Boxes>
            <Box bgColor={lightenHex('#4748EC', 20)}>
              <HeadingsWrapper>
                <Step>{t('step1')}</Step>
                <BoxTitle>{t('downloadFile')}</BoxTitle>
                <SubHeading>{t('chooseFileFormat')}</SubHeading>
              </HeadingsWrapper>
              <BtnsWrapper btnColor={'#4748EC'}>
                <Link to='#' onClick={() => downloadAllLeads('csv')}>
                  {t('downloadCSV')}
                </Link>
                <Link to='#' onClick={() => downloadAllLeads('xlsx')}>
                  {t('downloadXLSX')}
                </Link>
              </BtnsWrapper>
            </Box>
            <Box>
              <HeadingsWrapper>
                <Step>{t('step2')}</Step>
                <BoxTitle>{t('importToCRM')}</BoxTitle>
                <SubHeading>{t('importInstructions')}</SubHeading>
              </HeadingsWrapper>
              <BtnsWrapper btnColor={CSS_VARS.LT_DEFAULT_COLOR_VAR}>
                <Link to={routePaths.CONTACTS.IMPORT}>{t('learnMore')}</Link>
              </BtnsWrapper>
            </Box>
          </Boxes>
        </Content>
      </StyledContainer>
    </>
  );
};

export default withNav(
  ExportLeads,
  {
    tTitle: 'exportToCRM',
    showBackButton: {
      linkTo: routePaths.CONTACTS.ROOT,
    },
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
  },
);

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  box-shadow: none;
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 17px;
  box-sizing: content-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
  @media (min-width: ${DESKTOP_BREAK_POINT}px) {
    padding-top: 2rem;
  }
`;

const Heading = styled(Text)`
  font-weight: 600;
  color: #181a5a;
  font-size: 16px;
  margin-top: 5px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const Boxes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  background: ${props => props?.bgColor ?? '#ccf9ef'};
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const HeadingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
`;

const Step = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 33px;
  color: #91919d;
`;

const BoxTitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  color: #000000;
`;

const SubHeading = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
`;

const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  width: 100%;
  & a {
    margin-top: 2rem;
    background: ${props => (props?.btnColor ? props.btnColor : '#123123')};
    border-radius: 10px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: ${props => (props?.btnColor ? invertHex(props.btnColor, true) : 'white')};
    margin-right: 5px;
    padding: 1rem 2rem;
    @media (max-width: 425px) {
      font-size: 8px;
    }
  }
`;
