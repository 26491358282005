import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { Heading } from './Heading';
import { FiChevronRight } from 'react-icons/fi';
import useWindowSize from '../../../infrastructure/hooks/useWindowSize';
import { RequireOnlyOne } from '../../../shared/types/util';
import TooltipInfo from './TooltipInfo';

interface GenericProps {
  title?: {
    text?: string;
    color?: string;
    size?: string;
    middle?: boolean;
    align?: 'flex-start' | 'flex-end';
  };
  tooltip?: {
    text?: string;
  };
  link?: string;
  bgColor?: string;
  bgImage?: string;
  transparent?: boolean;
  noPadding?: boolean;
  customClasses?: string;
  fullWidth?: {
    reverse?: boolean; // if true -> right col wider than right
    content?: React.ReactNode;
    leftContent?: React.ReactNode;
    rightContent?: React.ReactNode;
  };
  halfWidth?:
    | {
        icon?: React.ReactNode;
        svgIcon?: boolean;
        imageIcon?: boolean;
        iconColor?: string;
        iconSize?: number;
        text?: string;
        textColor?: string;
        textMaxRatio?: number;
        flexHeight?: boolean;
      }
    | true;
  action?: () => void;
  style?: React.CSSProperties;
}
type Props = RequireOnlyOne<GenericProps, 'halfWidth' | 'fullWidth'>;

export const LTCard: React.FC<Props> = ({
  title,
  tooltip,
  link,
  halfWidth,
  fullWidth,
  bgColor,
  bgImage,
  transparent,
  noPadding,
  customClasses,
  action,
  style,
}) => {
  const halfWidthConfig = halfWidth instanceof Object && halfWidth;

  const boxEl = React.useRef(null);
  const [boxWidth, setBoxWidth] = React.useState(0);

  const history = useHistory();

  useEffect(() => {
    if (boxEl.current) {
      setBoxWidth(boxEl.current.clientWidth);
    }
  }, [boxWidth]);
  useWindowSize(useCallback(() => setBoxWidth(boxEl.current?.clientWidth), []));

  const fwContent = useMemo(
    () =>
      fullWidth ? (
        <>
          {title?.text ? (
            <RowWithMargin align={title?.align}>
              <Heading text={title?.text} size={title?.size ?? '21px'} color={title?.color} />
              {tooltip?.text && <TooltipInfo text={tooltip?.text} placement='right' arrow />}
            </RowWithMargin>
          ) : null}
          {fullWidth.reverse ? (
            <>
              {fullWidth.content ? (
                // <Row>
                <FWContent>{fullWidth.content}</FWContent>
              ) : (
                // </Row>
                <Row>
                  <ColThin>{fullWidth.leftContent}</ColThin>
                  <ColWide>{fullWidth.rightContent}</ColWide>
                </Row>
              )}
            </>
          ) : (
            <>
              {fullWidth.content ? (
                // <Row>
                <FWContent>{fullWidth.content}</FWContent>
              ) : (
                // </Row>
                <Row>
                  <ColWide>{fullWidth.leftContent}</ColWide>
                  <ColThin>{fullWidth.rightContent}</ColThin>
                </Row>
              )}
            </>
          )}
        </>
      ) : null,
    [fullWidth, title, tooltip?.text],
  );

  const hwContent = useMemo(
    () =>
      halfWidth ? (
        <HWContainer $boxWidth={boxWidth}>
          <HWIconWrapper $textColor={halfWidthConfig?.textColor}>
            <HWIcon
              $boxWidth={boxWidth}
              $iconColor={halfWidthConfig?.iconColor}
              $svgIcon={halfWidthConfig?.svgIcon ?? null}
              $imageIcon={halfWidthConfig?.imageIcon ?? null}
              $wFactor={halfWidthConfig?.iconSize || 1}
            >
              {halfWidthConfig?.icon ?? null}
            </HWIcon>
            <span>{halfWidthConfig?.text}</span>
          </HWIconWrapper>
          <HWHeading
            $color={title?.color}
            $boxWidth={boxWidth}
            $wFactor={1}
            $maxRatio={halfWidthConfig?.textMaxRatio}
            $middle={title?.middle}
          >
            {title?.text}
          </HWHeading>
        </HWContainer>
      ) : null,
    [halfWidth, title, halfWidthConfig, boxWidth],
  );

  return (
    <Box
      ref={boxEl}
      onClick={link ? () => history.push(link) : action && (() => action())}
      $boxFullWidth={fullWidth}
      $boxBgColor={bgColor}
      $boxBgImage={bgImage}
      $transparent={transparent}
      $boxWidth={boxWidth}
      $pointer={link || action}
      $noPadding={noPadding}
      $flexHeight={halfWidthConfig?.flexHeight}
      className={customClasses}
      style={style}
    >
      {(link || action) && (
        <ChevIcon
          to={link ?? '#'}
          $iconColor={title?.color}
          $boxWidth={boxWidth}
          $wFactor={halfWidth ? 1 : 0.5}
        >
          <FiChevronRight />
        </ChevIcon>
      )}
      {fullWidth ? fwContent : hwContent}
    </Box>
  );
};
interface BoxStyleProps {
  $boxFullWidth: Props['fullWidth'];
  $boxBgColor?: string;
  $boxBgImage?: string;
  $transparent?: boolean;
  $boxWidth?: number;
  $pointer?: boolean;
  $noPadding?: boolean;
  $flexHeight?: boolean;
}

interface IconStyleProps {
  $iconColor: string;
  $textColor: string;
  $boxWidth?: number;
  $svgIcon?: boolean;
  $imageIcon?: boolean;
  $wFactor: number;
}

export interface HeadingProps {
  $color?: string;
  $size?: string;
  $smallText?: boolean;
  $boxWidth: number;
  $wFactor: number;
  $maxRatio: number;
  $middle: boolean;
}

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  background: ${(props: BoxStyleProps) =>
    props.$boxBgImage ? `url(${props.$boxBgImage}) no-repeat center center` : null};
  background-size: ${(props: BoxStyleProps) => !props.$boxFullWidth && 'cover'};
  background-color: ${(props: BoxStyleProps) => props.$boxBgColor ?? '#EFF3FD'};
  ${(props: BoxStyleProps) =>
    props.$transparent &&
    `
    background-color: #ffffff;
    border: 2px solid #EDEAE9;
  `};
  height: ${(props: BoxStyleProps) =>
    !props.$boxFullWidth &&
    (!props.$flexHeight ? `${props.$boxWidth}px` : `${(props.$boxWidth * 2) / 3}px`)};
  border-radius: 15px;
  padding: ${(props: BoxStyleProps) => props.$boxFullWidth && '2rem'};
  padding: ${(props: BoxStyleProps) => props.$noPadding && '0'};
  margin-bottom: 2rem;
  width: ${(props: BoxStyleProps) => (props.$boxFullWidth ? '100%' : '48%')};
  ${(props: BoxStyleProps) => props.$pointer && `cursor: pointer`};
`;

export const ChevIcon = styled(Link)`
  position: absolute;
  top: ${(props: IconStyleProps) => (props.$wFactor * props.$boxWidth) / 22}px;
  right: ${(props: IconStyleProps) => (props.$wFactor * props.$boxWidth) / 32}px;
  display: grid;
  place-items: center;
  color: ${(props: IconStyleProps) => props.$iconColor ?? '#181a5a'};
  font-size: ${(props: IconStyleProps) => Math.min((props.$wFactor * props.$boxWidth) / 5, 33)}px;
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${(props: IconStyleProps) => props.$iconColor ?? '#181a5a'};
  }
  &:focus-visible {
    outline: 1px solid ${(props: IconStyleProps) => props.$iconColor ?? '#181a5a'};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const RowWithMargin = styled(Row)`
  margin-bottom: 13px;
  align-items: ${props => props.align ?? 'center'};
`;

const ColThin = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
`;

const ColWide = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
`;

const HWContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  & p {
    align-self: start;
  }
`;

const HWIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
    font-weight: 600;
    padding-left: 0.5rem;
    width: 50%;
    color: ${(props: IconStyleProps) => props.$textColor ?? '#181a5a'};
  }
`;

const HWIcon = styled.div`
  display: flex;
  font-size: ${(props: IconStyleProps) => (props.$wFactor * props.$boxWidth) / 2.25}px;
  color: ${(props: IconStyleProps) => props.$iconColor ?? '#181a5a'};
  align-items: center;
  justify-content: center;
  & svg {
    ${(props: IconStyleProps) =>
      props.$svgIcon &&
      `width: ${(props.$wFactor * props.$boxWidth) / 2.25}px; height: ${
        (props.$wFactor * props.$boxWidth) / 2.25
      }px`};
  }
  & img {
    ${(props: IconStyleProps) =>
      props.$imageIcon &&
      `width: ${(props.$wFactor * props.$boxWidth) / 1.25}px; max-height: ${
        (props.$wFactor * props.$boxWidth) / 2.25
      }px`};
  }
`;

const HWHeading = styled.h3`
  margin-top: 1rem;
  line-height: 1;
  width: ${(props: HeadingProps) => (props.$maxRatio ? `${props.$maxRatio}%` : 'fit-content')};
  font-weight: 600;
  color: ${(props: HeadingProps) => props.$color ?? '#181a5a'};
  font-size: ${(props: HeadingProps) => (props.$smallText ? 1.2 : 1.8)}rem;
`;

const FWContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
`;
