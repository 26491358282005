import React from 'react';
import { ApiFile } from '../../../../../../shared/types/api';
import styled from 'styled-components';
import { TableProps } from '..';
import { isNotEmptyArray } from '../../../../../../infrastructure/helper';
import TooltipInfo from '../../../../../components/generic/TooltipInfo';
import folderIcon from '../../../../../images/folder.svg';

const VISIBLE_FILES_COUNT = 8;

interface Props {
  files: ApiFile[];
  selectedFiles: TableProps['SelectedObjects']['selectedFilesProps'];
}

const FilesCell = ({ files, selectedFiles }: Props) => {
  if (!Array.isArray(files)) return null;
  const addFilter = (File: ApiFile) => {
    if (File.id === selectedFiles.selectedFile?.id) {
      selectedFiles.setSelectedFile(null);
    } else {
      selectedFiles.setSelectedFile(File);
    }
  };

  const visibleFiles = files.slice(0, VISIBLE_FILES_COUNT);
  return (
    <Container>
      {isNotEmptyArray(files) &&
        visibleFiles.map(File => (
          <TooltipInfo
            text={
              <a href={File.link} target='_blank' rel='noopener noreferrer'>
                {File.link}
              </a>
            }
            placement='bottom'
            onHover={true}
            key={File.id}
          >
            <FileContainer
              disabled={selectedFiles?.selectedFile && selectedFiles?.selectedFile?.id !== File.id}
              onClick={() => addFilter(File)}
            >
              <img src={folderIcon} alt='file-icon' style={{ width: '100%', height: '100%' }} />
            </FileContainer>
          </TooltipInfo>
        ))}
    </Container>
  );
};

export default FilesCell;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-template-rows: repeat(2, max-content);
  grid-auto-flow: column;
`;

const FileContainer = styled.div`
  padding: 0.2rem;
  background-color: rgb(233, 233, 233);
  ${props => props.disabled && 'opacity:0.3'};
  margin: 1px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
