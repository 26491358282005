import { useState } from 'react';
import { Employee } from '../../../../../../shared/types/api/employee.type';

const useEdit = () => {
  const [editEmployee, setEditEmployee] = useState(false);
  const [employee, setEmployee] = useState<Employee>();

  const handleEdit = (employee: Employee) => {
    setEditEmployee(true);
    setEmployee(employee);
  };

  const onBackClick = () => {
    setEditEmployee(false);
  };

  return { editEmployee, employee, handleEdit, onBackClick };
};

export default useEdit;
