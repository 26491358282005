import { useCallback, useMemo, useState } from 'react';
import useTierInfo from './useTierInfo';
import { useAppTranslation } from './useAppTranslation';
import { upgradeProLinkDe, upgradeProLinkEn } from '../constants';
import UpgradeRequestPopup from '@/views/components/popups/upgradeRequest';
import { usePublicFeatureFlag } from './useFeatureFlags';

export default function useUpgradeAction() {
  const { activeLanguage } = useAppTranslation();
  const { isTrial, isStarter, isMinEssentials } = useTierInfo();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');

  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const upgradeAction = useCallback(() => {
    if (!flag_hidePrices && (isStarter || (isMinEssentials && isTrial))) {
      window.open(activeLanguage.startsWith('de') ? upgradeProLinkDe : upgradeProLinkEn, '_blank');
    } else {
      setShowUpgradePopup(true);
    }
  }, [activeLanguage, flag_hidePrices, isMinEssentials, isStarter, isTrial]);

  const upgradeRequestPopup = useMemo(
    () =>
      showUpgradePopup ? <UpgradeRequestPopup onClosed={() => setShowUpgradePopup(false)} /> : null,
    [showUpgradePopup],
  );

  return { upgradeAction, upgradeRequestPopup, setShowUpgradePopup };
}
