import { Box, Button, Drawer, Typography } from '@mui/material';
import { Account, LeadAPI, MDLead } from '@/shared/types/api';
import { CloseOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { LeadForm } from '@/views/pages/lead-gen/CreateEditLead/LeadForm';
import { useThemeAccounts } from '../hooks';
import { addLead, editMdLead } from '@/infrastructure/apis/leadGen';
import { doS3LeadUpload } from '@/infrastructure/apis/aws';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  onClose: () => void;
  account: Account;
  onSave: () => void;
  onDelete: (leadId: number) => Promise<void>;
  opened: boolean;
  focusNotes?: boolean;
  lead?: LeadAPI | MDLead;
};

export const MDLeadFormDrawer = ({
  opened,
  onClose,
  account,
  onSave,
  onDelete,
  focusNotes,
  lead,
}: Props) => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const { openDialogIfUnsaved } = useUnsavedStatus();
  const { getAccessTokenSilently } = useAuth0();

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const handleClose = () => openDialogIfUnsaved(() => onClose());
  const { themeAccounts, isLoading: isThemeAccountsLoading } = useThemeAccounts();
  const [selectedThemeAccountId, setSelectedThemeAccountId] = useState<string>(null);

  const isEditLead = Boolean(lead);

  const leadFormRef = useRef(null);

  const getImageUrl = async (file?: File) => {
    return await doS3LeadUpload(
      `leads/${account.id}/${file?.name}`,
      file,
      account.id,
      getAccessTokenSilently,
    );
  };

  useEffect(() => {
    if (lead) {
      setSelectedThemeAccountId(lead?.account?.id);
    } else {
      setSelectedThemeAccountId(account?.id);
    }
  }, [lead, account]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const { leadData, businessCardFile, profileImageFile } = leadFormRef.current.getValues();
      console.log('leadData:', leadData);
      const [businessCardUrl, profileImageUrl] = await Promise.all([
        businessCardFile && getImageUrl(businessCardFile),
        profileImageFile && getImageUrl(profileImageFile),
      ]);
      const payload = {
        ...leadData,
        businessCardUrl: businessCardUrl || leadData.businessCardUrl,
        profileImageUrl: profileImageUrl || leadData.profileImageUrl,
      };
      if (isEditLead) {
        await editMdLead(getAccessTokenSilently, payload, account.id);
      } else {
        await addLead(payload, selectedThemeAccountId);
        onClose();
      }
      setIsUnsaved(false);
      onSave?.();
      leadFormRef.current.resetValues();
      toast.success(t('leadSaveSuccess'));
    } catch (error) {
      toast.error(t('mdLeads.errorSaving'));
      console.log(error);
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      anchor='right'
      open={opened}
      onClose={handleClose}
      PaperProps={{ sx: { width: '54rem' } }}
    >
      <Box p={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h3'>
          {isEditLead ? t('mdLeads.editFormTitle') : t('addContact')}
        </Typography>
        <Button variant='text' startIcon={<CloseOutlined />} onClick={handleClose}>
          {t('close')}
        </Button>
      </Box>
      <LeadForm
        isMd
        ref={leadFormRef}
        onDelete={onDelete}
        focusNotes={focusNotes}
        lead={lead}
        loading={isThemeAccountsLoading}
        themeAccounts={themeAccounts}
        selectedAccountId={selectedThemeAccountId}
        onAccountSelect={setSelectedThemeAccountId}
      />
      <Box flex={1} />
      <LtActionButtonBar
        saveAction={{ onClick: handleSave, loading: isSaving }}
        onCancel={handleClose}
        sticky
      />
    </Drawer>
  );
};
