import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../components/generic';
import { isValidHttpUrl } from '../../../../infrastructure/validations';
import { ProfileDesignConfig } from '@/shared/types/api';

type Props = {
  privacyUrl: string;
  imprintUrl: string;
  showCopyright?: boolean;
  legalName?: string;
  onChange: (field: keyof ProfileDesignConfig, value: string | boolean) => void;
};

const PolicyForm = (props: Props) => {
  const { t } = useTranslation();
  const { privacyUrl, imprintUrl, legalName, onChange } = props;
  const isURLValid = (value: string) => isValidHttpUrl(value) || value.trim() === '';
  // const RendershowCopyright = () => (
  //   <ShowCopyrightsContainer>
  //     <CopyRight>{t('addCopyRight')}</CopyRight>
  //     <AllRightsReserved>
  //       {t('allRightsReserved').replace('@year', new Date().getFullYear().toString())}
  //     </AllRightsReserved>
  //   </ShowCopyrightsContainer>
  // );
  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    transformFct?: (arg: string) => string,
  ) => {
    const { name } = e.target;
    let { value } = e.target;
    if (transformFct) {
      value = transformFct(value);
    }
    onChange(name as keyof ProfileDesignConfig, value);
  };

  useEffect(() => {
    if (!legalName) {
      onChange('showCopyright', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalName]);

  return (
    <PolicyFormContainer>
      <TextField
        label={t('linkToImprint')}
        name={'imprintUrl'}
        value={imprintUrl}
        angles='normal'
        type='text'
        border={borderProps}
        onChange={handleInputChange}
        font={{ size: '18px', weight: '400' }}
        fullWidth
        padding={{ left: '0' }}
        helperText={!isURLValid(imprintUrl) && 'This is not a valid webstite'}
        placeholder={'https://'}
      />
      <TextField
        label={t('linkToPrivacyPolicy')}
        name={'privacyUrl'}
        value={privacyUrl}
        angles='normal'
        type='text'
        border={borderProps}
        onChange={handleInputChange}
        font={{ size: '18px', weight: '400' }}
        fullWidth
        padding={{ left: '0' }}
        helperText={!isURLValid(privacyUrl) && 'This is not a valid webstite'}
        placeholder={'https://'}
      />

      {/* <TextField
        label={t('nameOfYourCompany')}
        name={'legalName'}
        value={policyType.legalName}
        angles='normal'
        type='text'
        border={borderProps}
        onChange={handleInputChange}
        font={{ size: '18px', weight: '400' }}
        fullWidth
        padding={{ left: '0' }}
      />
      <Checkbox
        key={'showCopyright'}
        name={'showCopyright'}
        value={policyType.showCopyright}
        title={() => <RendershowCopyright />}
        checked={true}
        onChange={() =>
          setPolicyType({ ...props.policyType, showCopyright: !policyType.showCopyright })
        }
        disabled={policyType.legalName === ''}
      /> */}
    </PolicyFormContainer>
  );
};

export default PolicyForm;

const PolicyFormContainer = styled.div`
  max-width: 60rem;
  margin-top: 20px;
`;

// const ShowCopyrightsContainer = styled.div`
//   padding-left: 20px;
// `;
// const CopyRight = styled.div`
//   color: #666666;
//   font-weight: 500;
//   line-height: 27px;
//   letter-spacing: 0em;
//   text-align: left;
// `;
// const AllRightsReserved = styled.div`
//   color: #a7a7a7;
//   font-style: italic;
//   font-weight: 400;
//   line-height: 27px;
//   letter-spacing: 0em;
//   text-align: left;
// `;
