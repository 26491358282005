import i18nMain from '@/config/i18n';
import { UDT_CATEGORIES } from '@/shared/business-logic/features/user-defined-translations/constants';
import { FEATURE } from '@/shared/constants';
import { EditRightsConfig } from '@/shared/types/api';

// the type here replaces the keys linkEditMode/fileEditMode with linksEditable/filesEditable
// ToDo: convert the entire link and files edit modes to be controlled by boolean edit rights as well
// i.e. get rid of linkEditMode=options
export const FIELDS: Record<
  | Exclude<keyof EditRightsConfig, 'linkEditMode' | 'fileEditMode'>
  | 'linksEditable'
  | 'filesEditable',
  { tooltip: string }
> = {
  detailsEditable: { tooltip: '' },
  profileImageEditable: { tooltip: '' },
  bannerImageEditable: { tooltip: '' },
  linksEditable: { tooltip: '' },
  filesEditable: { tooltip: '' },
  allowOwnQrBgImage: {
    tooltip: 'editRights.tooltips.allowOwnQrBgImage',
  },
  rebuyCards: { tooltip: 'editRights.tooltips.rebuyCards' },
  profileShare_uidParam: { tooltip: 'editRights.tooltips.profileShare_uidParam' },
} as const;

export const DEFAULT_BIO_FIELDS = [
  { id: 'title', name: i18nMain.t('title') },
  { id: 'firstName', name: i18nMain.t('firstName') },
  { id: 'lastName', name: i18nMain.t('lastName') },
  { id: 'position', name: i18nMain.t('position') },
  { id: 'role', name: i18nMain.t('role') },
  { id: 'division', name: i18nMain.t('division') },
  { id: 'company', name: i18nMain.t('company') },
  { id: 'workUrl', name: i18nMain.t('workUrl') },
  { id: 'bioText', name: i18nMain.t('bioText') },
  { id: 'mobilePhone', name: i18nMain.t('mobilePhone.text') },
  { id: 'workPhone', name: i18nMain.t('workPhone.text') },
  { id: 'homePhone', name: i18nMain.t('homePhone.text') },
  { id: 'hotlinePhone', name: i18nMain.t('hotlinePhone.text') },
  { id: 'assistantName', name: i18nMain.t('assistantName.text') },
  { id: 'assistantPhone', name: i18nMain.t('assistantPhone.text') },
  { id: 'assistantEmail', name: i18nMain.t('assistantEmail.text') },
  { id: 'assistantGeneric', name: i18nMain.t('assistantGeneric.text') },
  { id: 'workFax', name: i18nMain.t('workFax.text') },
  { id: 'email', name: i18nMain.t('email1') },
  { id: 'workEmail', name: i18nMain.t('workEmail') },
  { id: 'workEmail2', name: i18nMain.t('workEmail2') },
  { id: 'address1_addressee', name: i18nMain.t('address.addressee') },
  { id: 'address1_addressLine1', name: i18nMain.t('address.addressLine1') },
  { id: 'address1_addressLine2', name: i18nMain.t('address.addressLine2') },
  { id: 'address1_postCode', name: i18nMain.t('address.postCode') },
  { id: 'address1_city', name: i18nMain.t('address.city') },
  { id: 'address1_country', name: i18nMain.t('address.country') },
  { id: 'address1_label', name: i18nMain.t('address.label') },
  { id: 'address2_addressee', name: i18nMain.t('address.addressee') },
  { id: 'address2_addressLine1', name: i18nMain.t('address.addressLine1') },
  { id: 'address2_addressLine2', name: i18nMain.t('address.addressLine2') },
  { id: 'address2_postCode', name: i18nMain.t('address.postCode') },
  { id: 'address2_city', name: i18nMain.t('address.city') },
  { id: 'address2_country', name: i18nMain.t('address.country') },
  { id: 'address2_label', name: i18nMain.t('address.label') },
];

export const getUnitEditRightsConfig = (isFeatureAllowed: (key: string) => boolean) => {
  const allowedConfig = [
    {
      themeConfigKey: 'profile-design',
      tLabel: 'editRights.unitOverrideLabels.profileDesign.label',
      requiredFeature: FEATURE.UNIT_HIERARCHIES_PROFILE_DESIGN,
      tooltipTLabel: null,
      conditionalThemeConfigGroup: null,
    },
    {
      themeConfigKey: 'edit-rights',
      tLabel: 'editRights.unitOverrideLabels.editRights.label',
      requiredFeature: FEATURE.UNIT_HIERARCHIES_EDIT_RIGHTS,
      tooltipTLabel: null,
      conditionalThemeConfigGroup: null,
    },
    {
      themeConfigKey: 'user-defined-translations',
      tLabel: 'editRights.unitOverrideLabels.userDefinedTranslations.label',
      requiredFeature: FEATURE.USER_DEFINED_TRANSLATIONS,
      tooltipTLabel: null,
      conditionalThemeConfigGroup: {
        tHeading: 'editRights.unitOverrideLabels.userDefinedTranslations.subsettings.heading',
        tSubHeading: 'editRights.unitOverrideLabels.userDefinedTranslations.subsettings.subheading',
        tPrefix: 'editRights.unitOverrideLabels.userDefinedTranslations.subsettings.labels.',
        prefix: 'user-defined-translation',
        suffixes: UDT_CATEGORIES,
      },
    },
  ] as const;

  return allowedConfig.filter(conf => isFeatureAllowed(conf.requiredFeature));
};

export type SubField = { id: string; name: string };
export type SubFields = {
  [k in 'detailsEditable' | 'linksEditable']: SubField[];
};

export type EditRightsFields =
  | {
      [k in keyof typeof FIELDS]: boolean;
    }
  | null;
