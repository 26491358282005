import { useEffect } from 'react';
import useQuery from '../../../../../infrastructure/hooks/useQuery';

export default function UnsetFlag() {
  const query = useQuery();
  useEffect(() => {
    localStorage.removeItem(query.get('name'));
  });

  return <>Success</>;
}
