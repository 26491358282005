import { IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { CSSProperties } from 'react';

interface Props {
  onClick?: () => void;
  style?: CSSProperties;
}

const EditImageIcon = (props: Props): JSX.Element => {
  const { onClick, style } = props;
  return (
    <IconButton
      onClick={onClick || null}
      sx={theme => ({
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        boxShadow: '0px 1px 3px 0px #111356',
        ':hover': {
          backgroundColor: theme.palette.background.default,
        },
        ...style,
      })}
    >
      <EditOutlined sx={theme => ({ color: theme.palette.primary.main })} fontSize='large' />
    </IconButton>
  );
};

export default EditImageIcon;
