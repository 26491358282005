import { CustomBioTextBox as CustomBioTextBoxType } from '@/shared/types/api';
import { ThemeBoxStyle } from '@/shared/types/global';
import { Text } from '@/views/components/generic';
import Layout from '@/views/components/common/white-layout';

// TODO: profile page optimizations
// remove hard-coded values

interface Props {
  config: CustomBioTextBoxType;
  boxStyle: ThemeBoxStyle;
}

const CustomBioTextBox = (props: Props) => {
  const { config, boxStyle } = props;

  return (
    <Layout themeBoxStyle={boxStyle}>
      <Text text={config.text} color='#7a7a7a' lineHeight='2rem' multiline />
    </Layout>
  );
};

export default CustomBioTextBox;
