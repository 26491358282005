import { LOCAL_STORAGE } from '../../../../../../infrastructure/constants';

export type LOCAL_STORAGE_CACHE<T> = {
  version: number;
  data: T;
};
export interface ColumnDefinition {
  key: string;
  tkey: string;
  hidden?: boolean;
}

export const COLUMNS_DEFAULT = (withUnits: boolean, multiUnits: boolean): ColumnDefinition[] => [
  { key: 'status', tkey: 'status' },
  { key: 'namePrefix', tkey: 'namePrefix' },
  { key: 'academicDegree', tkey: 'academicDegree' },
  { key: 'academicDegree2', tkey: 'academicDegree2' },
  { key: 'firstName', tkey: 'firstName' },
  { key: 'lastName', tkey: 'lastName' },
  { key: 'authEmail', tkey: 'authEmail' },
  { key: 'createdOn', tkey: 'createdOn' },
  { key: 'editedAt', tkey: 'editedAt' },
  ...(withUnits
    ? multiUnits
      ? [
          { key: 'mainUnit', tkey: 'mainUnit' },
          { key: 'otherUnits', tkey: 'otherUnits' },
        ]
      : [{ key: 'unit', tkey: 'unit' }]
    : []),
  { key: 'position', tkey: 'position' },
  { key: 'role', tkey: 'role' },
  { key: 'division', tkey: 'division' },
  { key: 'company', tkey: 'company' },
  { key: 'costCenter', tkey: 'costCenter' },
  { key: 'email', tkey: 'email1' },
  { key: 'workEmail', tkey: 'workEmail', hidden: true },
  { key: 'workEmail2', tkey: 'workEmail2', hidden: true },
  { key: 'workUrl', tkey: 'workUrl' },
  { key: 'workPhone', tkey: 'workPhone.text' },
  { key: 'mobilePhone', tkey: 'mobilePhone.text', hidden: true },
  { key: 'homePhone', tkey: 'homePhone.text', hidden: true },
  { key: 'hotlinePhone', tkey: 'hotlinePhone.text', hidden: true },
  { key: 'assistantName', tkey: 'assistantName.text', hidden: true },
  { key: 'assistantPhone', tkey: 'assistantPhone.text', hidden: true },
  { key: 'assistantEmail', tkey: 'assistantEmail.text', hidden: true },
  { key: 'assistantGeneric', tkey: 'assistantGeneric.text', hidden: true },
  { key: 'workFax', tkey: 'workFax.text', hidden: true },
  { key: 'bioText', tkey: 'bioText' },
  { key: 'links', tkey: 'links' },
  { key: 'files', tkey: 'files' },
  { key: 'address1_addressee', tkey: 'address.addressee' },
  { key: 'address1_addressLine1', tkey: 'address.addressLine1' },
  { key: 'address1_addressLine2', tkey: 'address.addressLine2' },
  { key: 'address1_postCode', tkey: 'address.postCode' },
  { key: 'address1_city', tkey: 'address.city' },
  { key: 'address1_country', tkey: 'address.country' },
  { key: 'address1_label', tkey: 'address.label' },
  { key: 'address2_addressee', tkey: 'address.2.addressee', hidden: true },
  { key: 'address2_addressLine1', tkey: 'address.2.addressLine1', hidden: true },
  { key: 'address2_addressLine2', tkey: 'address.2.addressLine2', hidden: true },
  { key: 'address2_postCode', tkey: 'address.2.postCode', hidden: true },
  { key: 'address2_city', tkey: 'address.2.city', hidden: true },
  { key: 'address2_country', tkey: 'address.2.country', hidden: true },
  { key: 'address2_label', tkey: 'address.2.label', hidden: true },
];

export const NON_SORTABLE_COLUMNS = ['unit', 'mainUnit', 'otherUnits', 'links', 'files'];

const VERSION = 2 as const;
export const initColumnsDef = (
  withUnits: boolean,
  multiUnits: boolean,
): Array<ColumnDefinition> => {
  try {
    let saved: LOCAL_STORAGE_CACHE<ColumnDefinition[]> = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.ltProfilesTableSort),
    );
    if (saved.version !== VERSION) {
      // migration no version -> 1
      if (!('version' in saved)) {
        const data = saved as unknown as ColumnDefinition[];
        saved = {
          version: 1,
          data: data.map(def =>
            def.key === 'headline' ? { ...def, key: 'position' } : { ...def },
          ),
        };
        localStorage.setItem(LOCAL_STORAGE.ltProfilesTableSort, JSON.stringify(saved));
      }
      // migration version 1 -> 2
      if (saved.version === 1) {
        const data = saved as unknown as ColumnDefinition[];
        saved = {
          version: 2,
          data: data.map(def => {
            switch (def.key) {
              case 'address':
                return { ...def, key: 'address1_addressLine1', tkey: 'address.addressLine1' };
              case 'postCode':
                return { ...def, key: 'address1_postCode', tkey: 'address.postCode' };
              case 'city':
                return { ...def, key: 'address1_city', tkey: 'address.city' };
              case 'country':
                return { ...def, key: 'address1_country', tkey: 'address.country' };
              case 'addressLabel':
                return { ...def, key: 'address1_label', tkey: 'address.label' };
              case 'address2':
                return { ...def, key: 'address2_addressLine1', tkey: 'address.2.addressLine1' };
              case 'postCode2':
                return { ...def, key: 'address2_postCode', tkey: 'address.2.postCode' };
              case 'city2':
                return { ...def, key: 'address2_city', tkey: 'address.2.city' };
              case 'country2':
                return { ...def, key: 'address2_country', tkey: 'address.2.country' };
              case 'addressLabel2':
                return { ...def, key: 'address2_label', tkey: 'address.2.label' };
              default:
                return def;
            }
          }),
        };
        localStorage.setItem(LOCAL_STORAGE.ltProfilesTableSort, JSON.stringify(saved));
      }
    }
    if (
      !saved ||
      !('version' in saved) ||
      !('data' in saved) ||
      !Array.isArray(saved.data) ||
      saved.data.length < 1 ||
      saved.data.some(entry => !('key' in entry) || !('tkey' in entry))
    )
      throw new Error();
    else return syncColumns(saved.data, withUnits, multiUnits);
  } catch {
    return [...COLUMNS_DEFAULT(withUnits, multiUnits)];
  }
};

export const saveColumnsDef = (
  columns: Array<ColumnDefinition>,
  withUnits: boolean,
  multiUnits: boolean,
) =>
  localStorage.setItem(
    LOCAL_STORAGE.ltProfilesTableSort,
    JSON.stringify({ version: VERSION, data: syncColumns(columns, withUnits, multiUnits) }),
  );

// make sure columns are in sync with available ones
const syncColumns = (columns: Array<ColumnDefinition>, withUnits: boolean, multiUnits: boolean) => {
  return [
    ...columns.filter(col =>
      COLUMNS_DEFAULT(withUnits, multiUnits).some(defaultCol => defaultCol.key === col.key),
    ),
    ...COLUMNS_DEFAULT(withUnits, multiUnits)
      .filter(defaultCol => columns.every(col => col.key !== defaultCol.key))
      .map(col => ({ ...col, hidden: true })),
  ];
};

export const searchColumns = (withUnits: boolean) => [
  { name: 'firstName', value: 'firstName' },
  { name: 'lastName', value: 'lastName' },
  { name: 'authEmail', value: 'authEmail' },
  { name: 'status', value: 'status' },
  { name: 'permissionLevel', value: 'permissionLevel' },
  { name: 'position', value: 'position' },
  { name: 'role', value: 'role' },
  ...(withUnits ? [{ name: 'mdLeads.unit', value: 'unit' }] : []),
  { name: 'title', value: 'title' },
  { name: 'division', value: 'division' },
  { name: 'company', value: 'company' },
  { name: 'workUrl', value: 'workUrl' },
  { name: 'email1', value: 'email' },
  { name: 'workEmail', value: 'workEmail' },
  { name: 'workEmail2', value: 'workEmail2' },
  { name: 'mobilePhone.text', value: 'mobilePhone' },
  { name: 'workPhone.text', value: 'workPhone' },
  { name: 'homePhone.text', value: 'homePhone' },
  { name: 'hotlinePhone.text', value: 'hotlinePhone' },
  { name: 'assistantName.text', value: 'assistantName' },
  { name: 'assistantPhone.text', value: 'assistantPhone' },
  { name: 'assistantEmail.text', value: 'assistantEmail' },
  { name: 'assistantGeneric.text', value: 'assistantGeneric' },
  { name: 'workFax.text', value: 'workFax' },
  { name: 'address.addressee', value: 'address1_addressee' },
  { name: 'address.addressLine1', value: 'address1_addressLine1' },
  { name: 'address.addressLine2', value: 'address1_addressLine2' },
  { name: 'address.postCode', value: 'address1_postCode' },
  { name: 'address.city', value: 'address1_city' },
  { name: 'address.country', value: 'address1_country' },
  { name: 'address.label', value: 'address1_label' },
  { name: 'address.2.addressee', value: 'address2_addressee' },
  { name: 'address.2.addressLine1', value: 'address2_addressLine1' },
  { name: 'address.2.addressLine2', value: 'address2_addressLine2' },
  { name: 'address.2.postCode', value: 'address2_postCode' },
  { name: 'address.2.city', value: 'address2_city' },
  { name: 'address.2.country', value: 'address2_country' },
  { name: 'address.2.label', value: 'address2_label' },
  { name: 'links', value: 'links' },
  { name: 'files', value: 'files' },
];
