import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LINK_TYPE_MODES } from '../../../../../../../shared/constants';
import { removePrefix } from '../../../../../../../util';
import { Button } from '../../../../../../components/common';
import { ErrorMessage } from '../../../../../../components/common/error-message';
import { Popup } from '../../../../../../components/common/popup';
import { Text, TextField } from '../../../../../../components/generic';
import './edit-company-details-link.css';
import deletIcon from '../../../../../../images/trash.svg';
import { Link } from '../../../../../../../shared/types/api';
import SecondaryButton from '../../../../../../components/generic/SecondaryButton';
import styled from 'styled-components';

interface Props {
  item: Link;
  mode: string;
  onCloseClick: () => void;
  onDeleteClick: (link: Link) => void;
  onEditLink: (link: Link) => void;
}

const EditCompanyDetailsLink = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [linkName, setLinkName] = useState(props.item.name);
  const [linkText, setLinkText] = useState<string>(
    props.mode === LINK_TYPE_MODES.LINK
      ? removePrefix(props.item.link, 'https://')
      : props.item.link,
  );
  const [errorMsg, setErrorMsg] = useState('');

  const isUsername = props.mode === LINK_TYPE_MODES.USERNAME;
  const isPhone = props.mode === LINK_TYPE_MODES.PHONE;
  const backgroundCss =
    // @ts-ignore:next-line
    props.item.linkType?.backgroundCss ||
    // @ts-ignore:next-line
    props.item?.themeLinkType?.linkType?.backgroundCss ||
    // @ts-ignore:next-line
    props.item?.themeLinkType?.backgroundCss ||
    // @ts-ignore:next-line
    props.item?.backgroundCss;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const onTextBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'linkName') {
      if (errorMsg === t('nameLinkerror') && linkName.length > 1) setErrorMsg('');
    } else if (e.target.name === 'linkText') {
      if (
        (errorMsg === t('enterLinkerror') || errorMsg === t('enterUsernameerror')) &&
        linkText.length > 1
      )
        setErrorMsg('');
    }
  };

  const onSaveClick = () => {
    if (linkName.length <= 1) {
      setErrorMsg(t('nameLinkerror'));
    } else if (linkText.length <= 1) {
      setErrorMsg(t('enterMessage', { subject: props.mode }));
    } else {
      setErrorMsg('');
      props.onEditLink({ ...props.item, name: linkName, link: linkText });
      props.onCloseClick();
    }
  };
  return (
    <>
      {!showConfirmDelete && (
        <Popup
          headerText={t('editLink')}
          onBackClick={props.onCloseClick}
          onCloseClick={() => {
            props.onCloseClick();
            return null;
          }}
        >
          <div className='create-link-wrapper'>
            <div className='link-wrapper' style={{ background: backgroundCss }}>
              <img
                src={
                  props.item.linkType?.iconUrl ||
                  // @ts-ignore:next-line
                  props.item?.themeLinkType?.iconUrl ||
                  // @ts-ignore:next-line
                  props.item?.themeLinkType?.linkType?.iconUrl ||
                  // @ts-ignore:next-line
                  props.item?.iconUrl
                }
                // src={props.item.iconUrl || props.item.themeLinkType?.linkType?.iconUrl}
                alt='link icon'
              />
            </div>
            <div>
              {errorMsg && <ErrorMessage message={errorMsg} />}
              <TextField
                name='linkName'
                placeholder={t('nameLink')}
                value={linkName}
                onChange={e => setLinkName(e.target.value)}
                onBlur={e => onTextBlur(e)}
                maxLength={30}
                variant='outlined'
                fullWidth={true}
                angles='curved'
                bgColor={{ normal: '#fff', hover: '#fff' }}
                padding={{ left: 'auto' }}
                border={{ normal: '1px solid #ddd', focus: '1px solid #ddd' }}
                disabled={!!props.item.linkTemplateId}
              />
              <div
                className={`create-link-textbox-wrapper ${
                  isUsername ? LINK_TYPE_MODES.USERNAME : isPhone ? LINK_TYPE_MODES.PHONE : ''
                }`}
              >
                <TextField
                  name='linkText'
                  value={linkText}
                  placeholder={
                    isUsername ? t('enterUsername') : isPhone ? t('enterPhone') : t('enterLink')
                  }
                  onChange={e => setLinkText(removePrefix(e.target.value, 'https://'))}
                  onBlur={e => onTextBlur(e)}
                  maxLength={isUsername || isPhone ? 30 : null}
                  prefixText={isUsername ? '@' : isPhone ? '+' : null}
                  variant='outlined'
                  fullWidth={true}
                  angles='curved'
                  bgColor={{ normal: '#fff', hover: '#fff' }}
                  border={{ normal: '1px solid #ddd', focus: '1px solid #ddd' }}
                  disabled={!!props.item.linkTemplateId}
                />
              </div>
            </div>
            <div>
              {!props.item.linkTemplateId && (
                <Button text={t('save')} vMargin='3rem' onClick={() => onSaveClick()} />
              )}

              <a href='#!' className='delete-link' onClick={() => setShowConfirmDelete(true)}>
                <img src={deletIcon} alt='Delete' />
                {t('deleteLink')}
              </a>
            </div>
          </div>
        </Popup>
      )}
      {showConfirmDelete && (
        <Popup
          onCloseClick={() => {
            setShowConfirmDelete(false);
            return null;
          }}
        >
          <div className='delete-item-wrapper'>
            <div className='text-block'>
              <Text text={t('requestDelete')} variant='strong' />
              <Text text={t('deleteMessageLink')} variant='light' color='#7a7a7a' />
            </div>
            <ButtonBlock>
              <SecondaryButton text={t('cancel')} onClick={() => setShowConfirmDelete(false)} />
              <Button
                text={t('deleteIt')}
                onClick={() => {
                  props.onDeleteClick(props.item);
                  setShowConfirmDelete(false);
                  props.onCloseClick();
                }}
              />
            </ButtonBlock>
          </div>
        </Popup>
      )}
    </>
  );
};

export default EditCompanyDetailsLink;

const ButtonBlock = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    flex: 1;
  }
`;
