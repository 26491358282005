import styled from 'styled-components';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import Switch from 'react-switch';
import { SubsectionSpan } from '../common';
import CloudUpload from '@/views/images/cloud-upload.svg';
import { isFileValid, isNotEmptyArray } from '@/infrastructure/helper';
import { toast } from 'react-hot-toast';
import {
  CloseX,
  ImageOptionContainer,
  ImageOptionsContainer,
  PreviewContainer,
  UploadBox,
} from '../images';
import { DEFAULT_COLOR } from '@/infrastructure/constants';
import ImageCropper from '@/views/components/image-cropper';
import { useTranslation } from 'react-i18next';

interface Props {
  logoWide: string;
  setLogoWide: Dispatch<SetStateAction<string>>;
  logoWideBright: string;
  setLogoWideBright: Dispatch<SetStateAction<string>>;
  logoWideInvert: boolean;
  setLogoWideInvert: Dispatch<SetStateAction<boolean>>;
}

const Logos = (props: Props) => {
  const { t } = useTranslation();
  let fileInput = useRef(null);

  type ImageType = 'logoWide' | 'logoWideBright';
  const [imageType, setImageType] = useState<ImageType>();

  const { logoWide, logoWideBright, logoWideInvert } = props;
  const { setLogoWide, setLogoWideBright, setLogoWideInvert } = props;

  const [logoWideUrl, setLogoWideUrl] = useState(logoWide);
  const [logoWideBrightUrl, setLogoWideBrightUrl] = useState(logoWideBright);

  const [imageToCrop, setImageToCrop] = useState('');

  const onImageClick = (image: ImageType) => {
    setImageType(image);
    fileInput.current.click();
  };

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      const ext = uploadedFile.name.split('.').pop();

      // validate extension
      if (!['png'].includes(ext)) {
        toast.error(t('onlyExt') + 'png');
        return;
      }

      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);

        reader.onload = () => {
          setImageToCrop(reader.result as string);
        };
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = fileInput.current.files[0].name.split('.').pop();
    setImageToCrop('');

    let file;
    switch (imageType) {
      case 'logoWide':
        file = new File([blob], `logoWide.${ext}`);
        setLogoWide(file);
        setLogoWideUrl(croppedImageUrl);
        break;
      case 'logoWideBright':
        file = new File([blob], `logoWideBright.${ext}`);
        setLogoWideBright(file);
        setLogoWideBrightUrl(croppedImageUrl);
        break;
      default:
        console.warn('default case, cropper');
    }
  };

  const handleX = (type: ImageType) => {
    switch (type) {
      case 'logoWide':
        setLogoWide(null);
        setLogoWideUrl(null);
        break;
      case 'logoWideBright':
        setLogoWideBright(null);
        setLogoWideBrightUrl(null);
        break;
    }
  };

  return (
    <>
      {imageToCrop && (
        <ImageCropper
          image={imageToCrop}
          onUpload={url => onUploadClick(url)}
          onClose={() => setImageToCrop('')}
          noAspectRatio={true}
          crop={{ width: 100, height: 100 }}
        />
      )}
      <ImageOptionsContainer>
        <ImageOptionContainer>
          <SwitchWrapper>
            <SubsectionSpan>{t('organisation.lightBackground')}</SubsectionSpan>
          </SwitchWrapper>
          {logoWideUrl ? (
            <PreviewContainer>
              <img src={logoWideUrl} alt='logoWide' />
              <CloseX onClick={() => handleX('logoWide')}>&times;</CloseX>
            </PreviewContainer>
          ) : (
            <UploadBox onClick={() => onImageClick('logoWide')}>
              <img src={CloudUpload} alt='upload' />
              <SubsectionSpan>{t('uploadLogo')}</SubsectionSpan>
            </UploadBox>
          )}
          <ExtensionSpan>{t('organisation.onlyPng')}</ExtensionSpan>
        </ImageOptionContainer>
        <ImageOptionContainer>
          <SwitchWrapper>
            <Switch
              onChange={() => setLogoWideInvert(!logoWideInvert)}
              checked={!logoWideInvert}
              onColor={DEFAULT_COLOR}
              offColor='#C1CBDC'
            />
            <SubsectionSpan>{t('organisation.otherLightBackground')}</SubsectionSpan>
          </SwitchWrapper>
          {logoWideInvert && logoWideUrl ? (
            <DarkPreviewContainer>
              <img src={logoWideUrl} alt='logoWideInverted' />
            </DarkPreviewContainer>
          ) : logoWideBrightUrl ? (
            <>
              <DarkPreviewContainer>
                <img src={logoWideBrightUrl} alt='logoWideBright' />
                <CloseX onClick={() => handleX('logoWideBright')}>&times;</CloseX>
              </DarkPreviewContainer>
              <ExtensionSpan>{t('organisation.onlyPng')}</ExtensionSpan>
            </>
          ) : (
            <>
              <UploadBox onClick={() => onImageClick('logoWideBright')}>
                <img src={CloudUpload} alt='upload' />
                <SubsectionSpan>{t('uploadLogo')}</SubsectionSpan>
              </UploadBox>
              <ExtensionSpan>{t('organisation.onlyPng')}</ExtensionSpan>
            </>
          )}
        </ImageOptionContainer>
      </ImageOptionsContainer>

      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default Logos;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 3rem;

  cursor: default;

  & > div {
    transform: scale(0.7);
  }
`;

const ExtensionSpan = styled.span`
  color: #666666;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0.5rem;
`;

const DarkPreviewContainer = styled(PreviewContainer)`
  background: rgba(0, 0, 0, 0.2);

  img {
    filter: brightness(0) invert(1);
  }
`;
