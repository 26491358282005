import { useEffect, useState } from 'react';
import useQuery from '../../../../../infrastructure/hooks/useQuery';

export default function Flag() {
  const query = useQuery();
  const [success, setSuccess] = useState<null | boolean>(null);
  useEffect(() => {
    const name = query.get('name');
    const value = query.get('value');
    if (!name || !value) {
      setSuccess(false);
    } else {
      localStorage.setItem(name, value);
      setSuccess(true);
    }
  }, [query]);

  return success ? <>Success</> : success === false ? <>Error</> : <></>;
}
