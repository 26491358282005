import { useEffect, useRef } from 'react';
import { routePaths } from '../constants';
import { useAppDispatch } from '@/application/hooks';
import { setSidebarSettingsCollapsed } from '@/application/actions/ui';
import useTierInfo from './useTierInfo';

/**
 * set sidebar settings collapse to true whenever the app route inside md settings
 */

const useSidebarSettingsCollapse = () => {
  const dispatch = useAppDispatch();
  const { isUnitAdmin, isThemeAdmin, isStarter } = useTierInfo();
  const changed = useRef(false);

  useEffect(() => {
    if (
      window.location &&
      window.location?.pathname &&
      window.location.pathname.includes(routePaths.MD.CUSTOMISESETTINGS.ROOT) &&
      !changed.current &&
      !isStarter &&
      (isUnitAdmin || isThemeAdmin)
    ) {
      // set sidebar settings toggle to true
      dispatch(setSidebarSettingsCollapsed(true));
      changed.current = true;
    } else if (isStarter || (!isUnitAdmin && !isThemeAdmin)) {
      dispatch(setSidebarSettingsCollapsed(false));
    }
  }, [dispatch, isThemeAdmin, isUnitAdmin, isStarter]);
};

export default useSidebarSettingsCollapse;
