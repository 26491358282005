import { useEffect, useRef, useState } from 'react';

const getIndex = <T extends { id: number | string }>(arr: T[], item: T) =>
  arr.findIndex(x => x.id === item.id);

const useGenericSelection = <T extends { id: number | string }>(items: T[], curPageItems?: T[]) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [lastClickedIndex, setLastClickedIndex] = useState(0);

  const isShiftKeyDown = useRef(false);
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Shift') {
        isShiftKeyDown.current = true;
      }
    };

    const handleKeyUp = event => {
      if (event.key === 'Shift') {
        isShiftKeyDown.current = false;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // when filter is applied, make sure that only items are selected that are in the current list of items
  useEffect(() => {
    setSelectedItems(prev =>
      prev.filter(selectedItem => items.some(item => item.id === selectedItem.id)),
    );
  }, [items]);

  const isSelected = (item: T) => selectedItems.some(x => x.id === item.id);

  const selectAllOnPage = () => {
    setSelectedItems(prev => [...prev, ...curPageItems]);
  };

  const unselectAllOnPage = () => {
    setSelectedItems(prev => prev.filter(x => !curPageItems.some(y => y.id === x.id)));
  };

  const selectSingleItem = (item: T) => {
    const indexInSelectedArr = getIndex(selectedItems, item);
    const indexInItemsArr = getIndex(items, item);
    if (indexInSelectedArr === -1) {
      setSelectedItems(prev => [...prev, item]);
    } else {
      setSelectedItems(prev => prev.filter(x => x.id !== item.id));
    }
    setLastClickedIndex(indexInItemsArr === -1 ? 0 : indexInItemsArr);
  };

  const shiftSelectItems = (item: T) => {
    const currentlyClickedIndex = getIndex(curPageItems, item);

    const min = Math.min(lastClickedIndex, currentlyClickedIndex);
    const max = Math.max(lastClickedIndex + 1, currentlyClickedIndex + 1);
    const itemsInRange = curPageItems.slice(min, max);

    const isSelected = getIndex(selectedItems, item) !== -1;

    if (isSelected) {
      const newItems = selectedItems.filter(x => getIndex(itemsInRange, x) === -1);
      setSelectedItems([...newItems]);
    } else {
      const newItems = itemsInRange.filter(x => getIndex(selectedItems, x) === -1);
      setSelectedItems([...selectedItems, ...newItems]);
    }
    setLastClickedIndex(currentlyClickedIndex === -1 ? 0 : currentlyClickedIndex);
  };

  const selectItem = (item: T) => {
    if (isShiftKeyDown.current) {
      shiftSelectItems(item);
    } else {
      selectSingleItem(item);
    }
  };

  const selectAllItems = () => {
    setSelectedItems([...items]);
  };

  const unselectAllItems = () => {
    setSelectedItems([]);
  };

  const totalCount: number = items?.length;
  const selectedCount: number = selectedItems?.length;
  const isAnySelectedOnCurrentPage = selectedItems.some(x => curPageItems.some(y => y.id === x.id));

  return {
    selectedCount,
    totalCount,
    selectedItems,
    isSelected,
    selectItem,
    selectAllItems,
    unselectAllItems,
    isAnySelectedOnCurrentPage,
    selectAllOnPage,
    unselectAllOnPage,
    isAllSelected: totalCount > 0 && selectedCount === totalCount,
  };
};

export default useGenericSelection;

export const useGenericSelection_number = (items: { id: number }[]) => useGenericSelection(items);
export const useGenericSelection_string = (items: { id: string }[]) => useGenericSelection(items);
