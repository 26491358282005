import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';
import { AddCardOutlined } from '@mui/icons-material';
import { Theme, Typography } from '@mui/material';
import { Box, alpha } from '@mui/system';
import { useTranslation } from 'react-i18next';

export const NoCards = ({ actions }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktopMui();

  const { editRights } = useEditRights();
  return (
    <Box display='flex' alignItems='center' flexDirection='column' mt={isDesktop ? 15 : 5}>
      <Box
        sx={(theme: Theme) => ({
          border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
          bgcolor: alpha(theme.palette.primary.main, 0.04),
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: isDesktop ? '15rem' : '10rem',
          height: isDesktop ? '15rem' : '10rem',
        })}
      >
        <AddCardOutlined sx={{ fontSize: isDesktop ? '5rem' : '3rem', color: 'primary.main' }} />
      </Box>
      <Typography sx={{ mt: 5 }} variant={isDesktop ? 'h1' : 'h4'} textAlign='center'>
        {editRights.rebuyCards ? t('cards.noCardsViewWithShop') : t('cards.noCardsViewWithoutShop')}
      </Typography>
      <Box gap={2} display='flex' mt={5} width={isDesktop ? 'auto' : '100%'}>
        {actions}
      </Box>
    </Box>
  );
};
