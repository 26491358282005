import React, { useCallback, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Employee } from '../../../../shared/types/api/employee.type';
import styled from 'styled-components';
import { PrimaryButton } from '../customization/common';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../config/config';
import toast from 'react-hot-toast';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Loader, Popup } from '../../../components/common';
import ScannerPopup from '../../../components/qrcode-scanner/popup';
import { BsChevronDown } from 'react-icons/bs';
import moment from 'moment';
import TooltipInfo from '../../../components/generic/TooltipInfo';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

interface Props {
  employee: Employee;
  themeDomain: string;
  onBackClick?: () => void;
  onSaveSuccess?: Function;
  isAccountCards?: boolean;
}

const EmployeeCards = (props: Props) => {
  const { t } = useTranslation();
  const { theme: muiTheme } = useMuiTheme();
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [cards, setCards] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showCodeScannerPopup, setShowCodeScannerPopup] = useState(false);
  const [selectedCode, setSelectedCode] = useState('');
  const [codeIsChecking, setCodeIsChecking] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [codeKey, setCodeKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const fetchCards = useCallback(
    () =>
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(
            config.API_BASE_URL +
              (props.isAccountCards
                ? `account/codes`
                : `business/employees/${props.employee.id}/codes`),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),

        [PERMISSIONS.READ.THEME_PROFILES],
      )
        .then(res => {
          setIsLoadingCards(false);
          setCards(res.data.data);
        })
        .catch(() => toast.error(t('error.general'))),
    [getAccessTokenSilently, props.employee.id, props.isAccountCards, t],
  );
  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  const RenderCard = (props: { date: string | null; code: string }) => {
    return (
      <CardContainer>
        <CardRow padding spaceBetween>
          <Card>
            {t('cards.cardFrom')}{' '}
            {props.date ? (
              moment(props.date).format('lll')
            ) : (
              <TooltipInfo text={t('cards.nodate')} placement='top' icon={{ size: 14 }} arrow />
            )}
          </Card>
          <Remove
            onClick={() => {
              setSelectedCode(props.code);
              setShowDelete(true);
            }}
          >
            {t('cards.remove')}
          </Remove>
        </CardRow>
        {/* Unwanted for now */}
        {/* <CardRow>
          <Tooltip title={theme.domain + '/' + props.code} placement='left' enterDelay={500}>
            <Code>{props.code}</Code>
          </Tooltip>
        </CardRow> */}
      </CardContainer>
    );
  };
  const clearCode = useCallback(() => setCodeKey(prev => prev + 1), []);
  const handleCode = async (_code: string) => {
    if (_code.length === 10) {
      setCodeIsChecking(true);
      if (cards.find(card => card.code === _code)) {
        toast.error(t('cards.cardAlreadyConnected'));
        return;
      }
      const promise = withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.post(
            config.API_BASE_URL +
              (props.isAccountCards
                ? `account/addcode`
                : `business/employees/${props.employee.id}/codes/${_code}`),
            props.isAccountCards
              ? {
                  code: _code,
                }
              : {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ),
        [PERMISSIONS.WRITE.THEME_PROFILES],
      );
      toast.promise(
        promise,
        {
          loading: t('cards.uploadingCode'),
          success: res => {
            if (res.data.isSuccess) {
              setShowCodeScannerPopup(false);
              setCodeIsChecking(false);
              setCards([{ code: _code, date: res.data.data?.date || new Date() }, ...cards]);
            }
            clearCode();
            return t('cards.successUpload');
          },
          error: () => {
            return t('cards.errorUpload');
          },
        },
        {
          success: {
            duration: 4000,
          },
        },
      );
    }
  };
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const handleDeleteCode = () => {
    setShowDeleteLoader(true);
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.delete(
          config.API_BASE_URL +
            (props.isAccountCards
              ? `account/codes/${selectedCode}`
              : `business/employees/${props.employee.id}/codes/${selectedCode}`),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [PERMISSIONS.WRITE.THEME_PROFILES],
    )
      .then(res => {
        if (res.data.isSuccess) {
          setShowDelete(false);
          setCards(cards.filter(card => card.code !== selectedCode));
          toast.success(t('cards.successDelete'), { id: 'delete-code-toast' });
        }
      })
      .catch(() => toast.error(t('cards.errorDelete'), { id: 'delete-code-toast' }))
      .finally(() => setShowDeleteLoader(false));
  };
  return (
    <>
      {showDeleteLoader && <Loader />}
      <Contents style={{ marginBottom: '8rem' }}>
        <RowDiv padding={50} style={{ paddingBottom: '20px' }}>
          {t('cards.cardsHeader')}
          <Spacer size={9} />
          {!props.isAccountCards && (
            <TooltipInfo text={t('cards.headToolTip')} icon={{ size: 17 }} arrow />
          )}
        </RowDiv>
        <Contents>
          {isLoadingCards ? (
            <Card>{t('cards.loadingCards')}</Card>
          ) : cards.length === 0 ? (
            <Card>{t('cards.noCards')}</Card>
          ) : (
            cards.map(card => <RenderCard date={card.date} code={card.code} />)
          )}
        </Contents>
        <Spacer size={20} />
        <RowDiv isCenter padding='20'>
          <AddButton
            onClick={() => setShowCodeScannerPopup(true)}
            bgColor={muiTheme.palette.primaryButton.main}
          >
            <AiOutlinePlusCircle size={17} color='#FFFFFF' />
            <Spacer size={9} />
            {t('cards.connectANewCard')}
          </AddButton>
          <Spacer size={9} />
          {!props.isAccountCards && (
            <TooltipInfo text={t('cards.bottomToolTip')} icon={{ size: 17 }} arrow />
          )}
        </RowDiv>
        <>
          <EnterCodeText
            onClick={() => (showCodeInput ? setShowCodeInput(false) : setShowCodeInput(true))}
          >
            {t('codeRead.enterCodeText')}
            <StyledChevron flip={showCodeInput.toString()} />
          </EnterCodeText>
          {showCodeInput && (
            <>
              <ManualCodeContainer>
                <ReactCodeInput
                  type='text'
                  fields={10}
                  autoFocus={false}
                  key={codeKey}
                  onChange={e => handleCode(e)}
                  filterChars={[
                    '!',
                    '@',
                    '#',
                    '$',
                    '%',
                    '^',
                    '&',
                    '*',
                    '(',
                    ')',
                    '[',
                    ']',
                    '{',
                    '}',
                    '/',
                    '|',
                    '+',
                    '-',
                    '=',
                    ';',
                    ':',
                    ',',
                    "'",
                    '"',
                    '`',
                    ' ',
                  ]}
                  name='dashboard-code-input'
                  inputMode='verbatim'
                  inputStyle={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: '#4748ec',
                    textAlign: 'center',
                    height: 55,
                    padding: 0,
                    width: '8%',
                    borderRadius: 6,
                    marginLeft: 8,
                  }}
                />
              </ManualCodeContainer>
            </>
          )}
        </>
        {showDelete && (
          <Popup
            headerText={t('cards.codeDelete')}
            onCloseClick={() => setShowDelete(false)}
            btn={{
              type: 'reset',
              text: t('cancel'),
            }}
            secondBtn={{
              type: 'button',
              text: t('deleteIt'),
              onClick: handleDeleteCode,
            }}
            bodyText={t('cards.areYouSureDelete')}
          />
        )}
        {showCodeScannerPopup && (
          <ScannerPopup
            onCloseClick={() => {
              setShowCodeScannerPopup(false);
              fetchCards();
            }}
            onHandleCode={handleCode}
            codeIsChecking={codeIsChecking}
            t={t}
            mobileScan={
              !!props.isAccountCards
                ? undefined
                : {
                    connectAccountId: props.employee.id,
                    domain: props.themeDomain,
                    lang: props.employee?.preferredLang,
                  }
            }
          />
        )}
      </Contents>
    </>
  );
};
export default EmployeeCards;

const Spacer = styled.div`
  min-width: ${(props: { size: number }) => props.size}px;
  min-height: ${(props: { size: number }) => props.size}px;
`;
const AddButton = styled(PrimaryButton)`
  width: 80%;
  min-width: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;
const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.isCenter && ' justify-content: center'};
  padding-top: ${props => props.padding}px;
  padding-bottom: ${props => props.padding}px;
  align-items: center;
  ${props => props.spaceBetween && 'justify-content: space-between'};
`;

const CardContainer = styled.div`
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-style: solid;
  border-bottom-width: 0.1px;
  border-bottom-color: #b2b8c3;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Card = styled.div`
  color: #949799;
  display: flex;
  align-items: center;

  > span {
    height: 14px;
    margin-left: 0.5rem;
  }
`;

// const Code = styled.div`
//   font-family: monospace;
//   color: #949799;
//   font-size: 12px;
// `;

const Remove = styled.div`
  color: #4748ec;
  cursor: pointer;
`;

const Contents = styled.div`
  width: 90%;
  margin: auto;
`;

const EnterCodeText = styled.p`
  font-size: small;
  text-align: center;
  cursor: pointer;
`;
const StyledChevron = styled(BsChevronDown)`
  transform: rotate(${props => (props.flip === 'true' ? '180deg' : '0deg')})
    ${props => (props.flip === 'true' ? '' : 'translateY(50%)')};
  transition: transform 0.3s ease;
  position: relative;
  top: ${props => (props.flip === 'true' ? '4px' : '-4px')};
  left: 4px;
`;

const ManualCodeContainer = styled.div`
  align-self: center;

  justify-content: center;
  display: flex;
`;
