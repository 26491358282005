import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import QRCode from 'qrcode.react';
import toast from 'react-hot-toast';

import { getThemeQrBgImgs } from '../../../application/actions/account';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { routePaths } from '../../../infrastructure/constants';
import { createVCard } from '../../../infrastructure/helper';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useStateCallback } from '../../../infrastructure/hooks/useStateCallback';
import {
  DEFAULT_IMG_WIDTH_MO,
  DEFAULT_IMG_WIDTH_VC,
  POS_CONST_MO,
  POS_CONST_VC,
} from './common/constants';
import {
  HeaderWithIconWrapper,
  QRBtn,
  QRNavigator,
  ScreenBtn,
  ScreenNavigator,
  SecondaryHeader,
  SectionWrapper,
  StyledContainer,
} from './common/styles';
import QRCodeBgVideocall from './videocall';
import QRCodeBgMobile from './mobile';
import { Account } from '../../../shared/types/api';
import TooltipInfo from '../generic/TooltipInfo';
import {
  generateImageObj,
  imageSizeValidator,
  imgWithTimestamp,
  loadQrConfig,
} from './common/utils';
import { QRBGConfig } from '@/shared/types/global';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import ImageCropper from '../image-cropper';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { QRBGMode, QRCodeType, QRPositionsMo, QRPositionsVC } from './common/types';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { withKeyEventHandler } from '@/utils/helpers';
import { useLocation } from 'react-router-dom';
import { useUnitSelectorForUidParam } from '@/infrastructure/hooks/useUnitSelectorForUidParam';
import { generateProfileLink } from '@/shared/business-logic/features/profile/getter';

interface Props {
  useForPlainHeader?: boolean;
  detectDownload?: () => void;
}

const QRBG = (props: Props) => {
  const { state: routeState } = useLocation<{ screen: QRBGMode }>();

  let qrCodeEl = useRef(null);

  const { t, i18n } = useAppTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const account = useAppSelector<Account>(state => state.account);

  const domain = useMemo(() => account?.theme?.domain, [account?.theme?.domain]);

  const {
    editRights: { allowOwnQrBgImage },
  } = useEditRights();

  const [mode, setMode] = useState<'samples' | 'uploaded'>('samples');
  const [QRCodeImg, setQRCodeImg] = useStateCallback('');
  const [QRCodeImgSize, setQrCodeImgSize] = useStateCallback(0);
  const [QRConfig, setQRConfig] = useState<QRBGConfig>();
  const [QRCodeType, setQRCodeType] = useState<QRCodeType>('online');

  const [QRImgReady, setQRImgReady] = useState(false);

  const [configsLoading, setConfigsLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(false);

  // VC: stands for videocall & Mo: stands for mobile screens
  const [BGPreviewImgsVC, setBgPreviewImgsVC] = useState<string[]>([]);
  const [BGPreviewImgsMo, setBgPreviewImgsMo] = useState<string[]>([]);

  const [QRBgImgVC, setQRBgImgVC] = useState('');
  const [downloadReadyVC, setDownloadReadyVC] = useState(false);

  const [QRBgImgMo, setQRBgImgMo] = useState('');
  const [downloadReadyMo, setDownloadReadyMo] = useState(false);

  const [imgIndexMo, setImgIndexMo] = useState(0);
  const [imgIndexVC, setImgIndexVC] = useState(0);

  const [error, setError] = useState(null);
  const [displayScreen, setDisplayScreen] = useState<QRBGMode>(routeState?.screen || 'mobile');

  const [QRCodePositionMo, setQRCodePositionMo] = useState<QRPositionsMo>(undefined);
  const [QRCodePositionVC, setQRCodePositionVC] = useState<QRPositionsVC>(undefined);

  const [configPositionVC, setConfigPositionVC] = useState<boolean>();
  const [configPositionMo, setConfigPositionMo] = useState<boolean>();

  const [uncroppedImage, setUncroppedImage] = useState('');
  const [uploadedImageMo, setUploadedImageMo] = useState('');
  const [uploadedImageVC, setUploadedImageVC] = useState('');
  const [origImgCounter, setOrigImgCounter] = useState(1);

  const { isStarter } = useTierInfo();
  const { theme: muiTheme } = useMuiTheme();
  const primaryButtonColor = muiTheme.palette.primaryButton.main;

  const { uidForProfileLink } = useUnitSelectorForUidParam();

  const profileLink = generateProfileLink(account, {
    lt: { lt_medium: displayScreen === 'videocall' ? 'videobgqr' : 'mobilebgqr' },
    unit: uidForProfileLink,
  });

  const offlineUrl = useMemo(
    () =>
      createVCard(account, profileLink, i18n.language, [
        'homePhone',
        'hotlinePhone',
        'assistantPhone',
        'assistantEmail',
        'workFax',
        'workEmail',
        'workEmail2',
        'address2',
      ]),
    [account, profileLink, i18n.language],
  );

  useDeferredLoader(
    (configsLoading && imagesLoading) || (!downloadReadyMo && !downloadReadyVC && !uncroppedImage),
    'qr-background-loading-toast',
  );

  useEffect(() => {
    // load qr params theme-config
    setConfigsLoading(true);
    const fetchConfig = async () => await loadQrConfig(getAccessTokenSilently);
    fetchConfig()
      .then(config => setQRConfig(config))
      .catch(err => {
        setError(err);
      })
      .finally(() => setConfigsLoading(false));
  }, [getAccessTokenSilently, t]);

  useEffect(() => {
    // fetch bg images from the api
    setImagesLoading(true);
    dispatch(getThemeQrBgImgs(getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (account.theme.qrBg?.images && QRConfig) {
      // split the fetched images to separated Mo & VC Imgs
      let bgImgs = account.theme.qrBg.images;
      let imgsPreviewVC = bgImgs.videocall;
      let imgsPreviewMo = bgImgs.mobile;

      setBgPreviewImgsVC(
        imgsPreviewVC.default
          ? [imgsPreviewVC.default, ...imgsPreviewVC.additional]
          : imgsPreviewVC.additional,
      );
      setBgPreviewImgsMo(
        imgsPreviewMo.default
          ? [imgsPreviewMo.default, ...imgsPreviewMo.additional]
          : imgsPreviewMo.additional,
      );
    }
  }, [account.theme.qrBg?.images, QRConfig]);

  useEffect(() => {
    // set initial displayed image
    if (BGPreviewImgsMo.length > 0) {
      setQRBgImgMo(imgWithTimestamp(BGPreviewImgsMo[0]));
      setQRBgImgVC(imgWithTimestamp(BGPreviewImgsVC[0]));
    }
    setImagesLoading(false);
  }, [BGPreviewImgsMo, BGPreviewImgsVC]);

  useEffect(() => {
    if (error) {
      toast.error(t('generalQRImgError'));
      console.error(error);
    }
  }, [error, t]);

  const getQRPositions = useCallback(
    (
      displayScreen: 'videocall' | 'mobile',
      position: QRPositionsMo | QRPositionsVC,
      imgWidth: number,
      imgHeight: number,
      qrCodeWidth: number,
      QRConfig: QRBGConfig,
    ): { x: number; y: number } => {
      let x: number;
      let y: number;
      const fixedPosMo = {
        x: POS_CONST_MO.X * (imgWidth - qrCodeWidth),
        y_top: POS_CONST_MO.Y.TOP * (imgHeight - qrCodeWidth),
        y_middle: POS_CONST_MO.Y.MIDDLE * (imgHeight - qrCodeWidth),
        y_bottom: POS_CONST_MO.Y.BOTTOM * (imgHeight - qrCodeWidth),
      };
      const fixedPosVC = {
        y: POS_CONST_VC.Y * (imgHeight - qrCodeWidth),
        x_left: POS_CONST_VC.X.LEFT * (imgWidth - qrCodeWidth),
        x_middle: POS_CONST_VC.X.MIDDLE * (imgWidth - qrCodeWidth),
        x_right: POS_CONST_VC.X.RIGHT * (imgWidth - qrCodeWidth),
      };

      if (displayScreen === 'mobile') {
        const configX = QRConfig.mobile.x * (imgWidth - qrCodeWidth);
        const configY = QRConfig.mobile.y * (imgHeight - qrCodeWidth);
        x = !position && !QRConfig.fallback ? configX : fixedPosMo.x;
        // set positions
        if (position === 'bottom') {
          y = fixedPosMo.y_bottom;
        } else if (position === 'top') {
          y = fixedPosMo.y_top;
        } else if (position === 'middle') {
          y = fixedPosMo.y_middle;
        } else {
          y = configY;
        }

        if (isStarter) {
          // disable config position on starter plan
          setQRCodePositionMo('bottom');
          setConfigPositionMo(false);
        } else {
          // set to fixed positions if config positions equal to fixed ones
          if (
            QRConfig.fallback ||
            (POS_CONST_MO.X === QRConfig.mobile.x &&
              Object.values(POS_CONST_MO.Y).includes(QRConfig.mobile.y))
          ) {
            // fixed positions
            setConfigPositionMo(false);
            if (x === fixedPosMo.x && y === fixedPosMo.y_top) {
              QRCodePositionMo !== 'top' && setQRCodePositionMo('top');
            } else if (x === fixedPosMo.x && y === fixedPosMo.y_middle) {
              QRCodePositionMo !== 'middle' && setQRCodePositionMo('middle');
            } else if (x === fixedPosMo.x && y === fixedPosMo.y_bottom) {
              QRCodePositionMo !== 'bottom' && setQRCodePositionMo('bottom');
            } else {
              setQRCodePositionMo('bottom');
            }
          } else {
            // config position
            setConfigPositionMo(true);
          }
        }
      } else if (displayScreen === 'videocall') {
        const configX = QRConfig.videocall.x * (imgWidth - qrCodeWidth);
        const configY = QRConfig.videocall.y * (imgHeight - qrCodeWidth);
        y = !position && !QRConfig.fallback ? configY : fixedPosVC.y;
        if (position === 'right') {
          x = fixedPosVC.x_right;
        } else if (position === 'left') {
          x = fixedPosVC.x_left;
        } else if (position === 'middle') {
          x = fixedPosVC.x_middle;
        } else {
          x = configX;
        }

        if (isStarter) {
          setQRCodePositionVC('left');
          setConfigPositionVC(false);
        } else {
          if (
            QRConfig.fallback ||
            (POS_CONST_VC.Y === QRConfig.videocall.y &&
              Object.values(POS_CONST_VC.X).includes(QRConfig.videocall.x))
          ) {
            // fixed positions
            setConfigPositionVC(false);
            if (y === fixedPosVC.y && x === fixedPosVC.x_left) {
              QRCodePositionVC !== 'left' && setQRCodePositionVC('left');
            } else if (y === fixedPosVC.y && x === fixedPosVC.x_middle) {
              QRCodePositionVC !== 'middle' && setQRCodePositionVC('middle');
            } else if (y === fixedPosVC.y && x === fixedPosVC.x_right) {
              QRCodePositionVC !== 'right' && setQRCodePositionVC('right');
            } else {
              setQRCodePositionVC('left');
            }
          } else {
            // config position
            setConfigPositionVC(true);
          }
        }
      }

      return { x, y };
    },
    [QRCodePositionMo, QRCodePositionVC, isStarter],
  );

  const generateQrBgImage = useCallback(
    (bgImg: string, qrImg: string) => {
      setDownloadReadyVC(false);
      setDownloadReadyMo(false);

      let img = generateImageObj(bgImg);
      let imgWidth = 0;
      let imgHeight = 0;
      img.onload = () => {
        imgWidth = img.width;
        imgHeight = img.height;

        let qrCode = generateImageObj(qrImg);
        let qrCodeWidth = 0;

        let canvas = document.createElement('canvas');
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        let ctx = canvas.getContext('2d');

        // draw first image on created canvas element
        ctx.drawImage(img, 0, 0);

        qrCode.onload = () => {
          qrCodeWidth = qrCode.width;
          // draw second image on created canvas element
          if (displayScreen === 'videocall') {
            const { x, y } = getQRPositions(
              displayScreen,
              QRCodePositionVC,
              imgWidth,
              imgHeight,
              qrCodeWidth,
              QRConfig,
            );

            // draw qr over canvas
            ctx.drawImage(qrCode, x, y);
          } else if (displayScreen === 'mobile') {
            const { x, y } = getQRPositions(
              displayScreen,
              QRCodePositionMo,
              imgWidth,
              imgHeight,
              qrCodeWidth,
              QRConfig,
            );

            ctx.drawImage(qrCode, x, y);
          }
          let img = canvas.toDataURL('image/*');
          if (displayScreen === 'videocall') {
            setQRBgImgVC(img);
            setDownloadReadyVC(true);
          } else {
            setQRBgImgMo(img);
            setDownloadReadyMo(true);
          }
        };
      };
    },
    [QRCodePositionMo, QRCodePositionVC, QRConfig, displayScreen, getQRPositions],
  );

  const getQrCodeImg = useCallback(
    async (upload?: boolean, img?: string) => {
      let serialized = new XMLSerializer().serializeToString(await qrCodeEl.current.children[0]);
      const parser = new DOMParser();
      const newSvg = parser.parseFromString(serialized, 'image/svg+xml');
      const newSvgEl = newSvg.firstChild as SVGSVGElement;
      let qrCodeImgSrc =
        'data:image/svg+xml;base64,' + encodeURIComponent(window.btoa(newSvgEl.outerHTML));

      if (img && qrCodeImgSrc?.length > 0) {
        setQRCodeImg(qrCodeImgSrc, (qrCodeImg: string) => {
          generateQrBgImage(img, qrCodeImg);
          setQRImgReady(true);
        });
      }
    },
    [generateQrBgImage, setQRCodeImg],
  );

  const onImgClickHandler = async (e: React.ChangeEvent<HTMLInputElement> | number) => {
    let imgIdx = Number(e);
    let src: string;
    if (displayScreen === 'videocall') {
      setImgIndexVC(imgIdx);
      src = imgWithTimestamp(BGPreviewImgsVC[imgIdx]);
    } else if (displayScreen === 'mobile') {
      setImgIndexMo(imgIdx);
      src = imgWithTimestamp(BGPreviewImgsMo[imgIdx]);
    }
    if (mode === 'uploaded') {
      // regenerate code size dynamically for sample images after coming from custom uploading process
      let bgImg = generateImageObj(src);
      let factor = 0;
      let percentage = displayScreen === 'mobile' ? QRConfig.mobile.size : QRConfig.videocall.size;

      bgImg.onload = () => {
        factor = displayScreen === 'mobile' ? bgImg.height : bgImg.width;
        setQrCodeImgSize(factor * percentage, async (size: number) => {
          setMode('samples');
          await getQrCodeImg(false, src);
        });
      };
    } else {
      if (displayScreen === 'videocall') {
        generateQrBgImage(src, QRCodeImg);
      } else {
        generateQrBgImage(src, QRCodeImg);
      }
    }
  };

  const downloadImgHandler = (qrBgImgDownload: string, analyticQuery?: string) => {
    fetch(qrBgImgDownload, {
      method: 'GET',
      headers: {},
    })
      .then(response => {
        response.arrayBuffer().then(buffer => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          if (displayScreen === 'videocall') {
            link.href = url;
          } else if (displayScreen === 'mobile') {
            link.href = url;
          }
          link.setAttribute('download', `${account?.username}-QR-Background.png`);
          document.body.appendChild(link);
          link.click();
          if (analyticQuery) {
            fetch(`${domain}${routePaths.QR_CODE_BACKGROUND}?${analyticQuery}`);
          }
          toast.success(t('downloadImgSuccess'));
        });
        props.detectDownload && props.detectDownload();
      })
      .catch(err => {
        setError(err);
      });
  };

  const uploadImageClickHandler = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current.click();
  };

  const uploadImgHandler = async (target: HTMLInputElement) => {
    mode === 'samples' && setMode('uploaded');
    uploadedImageMo.length > 0 && setUploadedImageMo('');
    uploadedImageVC.length > 0 && setUploadedImageVC('');
    if (target.files && target.files.length > 0) {
      const acceptedFileTypes = ['image/jpg', 'image/png', 'image/jpeg'];
      const inputImg = target.files[0];
      if (!acceptedFileTypes.includes(inputImg.type)) {
        return toast.error(t('uploadImgError'));
      } else {
        const imgUrl = URL.createObjectURL(inputImg);
        setUncroppedImage(imgUrl);
      }
      return;
    }
  };

  const handleCroppedImage = async (croppedImage: string) => {
    // validate uploaded image size
    const error = await imageSizeValidator(
      croppedImage,
      displayScreen,
      displayScreen === 'mobile' ? () => setDownloadReadyMo(true) : () => setDownloadReadyVC(true),
    );
    if (error && error.length) {
      toast.error(error);
      return;
    }

    setUncroppedImage('');
    let percentage = displayScreen === 'mobile' ? QRConfig.mobile.size : QRConfig.videocall.size;
    let img = generateImageObj(croppedImage);
    img.onload = async () => {
      let uploadedImgWidth = img.width;
      // 1333 the mobile default image width
      // 1920 the videocall default image width
      let defaultImgWidth =
        displayScreen === 'mobile' ? DEFAULT_IMG_WIDTH_MO : DEFAULT_IMG_WIDTH_VC;
      if (uploadedImgWidth === defaultImgWidth)
        setOrigImgCounter(
          QRCodeImg > defaultImgWidth * percentage ? origImgCounter - 1 : origImgCounter + 1,
        );
      setQrCodeImgSize(
        uploadedImgWidth === defaultImgWidth
          ? defaultImgWidth * percentage + origImgCounter
          : uploadedImgWidth * percentage,
        async (size: number) => {
          await getQrCodeImg(true, croppedImage);
          displayScreen === 'mobile'
            ? setUploadedImageMo(croppedImage)
            : setUploadedImageVC(croppedImage);
          return toast.success(t('uploadImgSuccess'));
        },
      );
    };
  };

  const sliderImagesNumber = useMemo((): number => {
    if (window.innerWidth > 443) {
      if (allowOwnQrBgImage) {
        return displayScreen === 'mobile' ? 33.33 : 50;
      } else return displayScreen === 'mobile' ? 25 : 33.33;
    } else {
      if (allowOwnQrBgImage) {
        return displayScreen === 'mobile' ? 50 : 100;
      } else return displayScreen === 'mobile' ? 33.33 : 50;
    }
  }, [allowOwnQrBgImage, displayScreen]);

  // generate the qrcode image after render
  if (qrCodeEl.current) {
    if (displayScreen === 'videocall') {
      (async () => await getQrCodeImg(false, QRBgImgVC))();
    } else if (displayScreen === 'mobile') {
      (async () => await getQrCodeImg(false, QRBgImgMo))();
    }
  }

  // generate initial bg images
  useEffect(() => {
    if (mode === 'samples' && account.theme.qrBg?.images && BGPreviewImgsMo.length > 0) {
      if (displayScreen === 'videocall') {
        (async () => await getQrCodeImg(false, BGPreviewImgsVC[0]))();
      } else if (displayScreen === 'mobile') {
        (async () => await getQrCodeImg(false, BGPreviewImgsMo[0]))();
      }
    }
  }, [
    BGPreviewImgsMo,
    BGPreviewImgsVC,
    account.theme.qrBg?.images,
    displayScreen,
    getQrCodeImg,
    mode,
  ]);

  // reset to default values on screens toggle
  useEffect(() => {
    setImgIndexMo(0);
    setImgIndexVC(0);
    setMode('samples');
  }, [displayScreen]);

  // handle the change of qr position
  useEffect(() => {
    if (BGPreviewImgsMo && BGPreviewImgsMo.length > 0) {
      if (displayScreen === 'mobile') {
        if (mode === 'samples') {
          generateQrBgImage(imgWithTimestamp(BGPreviewImgsMo[imgIndexMo]), QRCodeImg);
        } else {
          generateQrBgImage(uploadedImageMo, QRCodeImg);
        }
      } else {
        if (mode === 'samples') {
          generateQrBgImage(imgWithTimestamp(BGPreviewImgsVC[imgIndexVC]), QRCodeImg);
        } else {
          generateQrBgImage(uploadedImageVC, QRCodeImg);
        }
      }
    }
  }, [
    BGPreviewImgsMo,
    BGPreviewImgsVC,
    QRCodeImg,
    QRCodePositionMo,
    QRCodePositionVC,
    displayScreen,
    generateQrBgImage,
    imgIndexMo,
    imgIndexVC,
    mode,
    uploadedImageMo,
    uploadedImageVC,
  ]);

  const QRTypeNavComponent = useMemo(
    () =>
      !props.useForPlainHeader && (
        <SectionWrapper>
          <HeaderWithIconWrapper className='lt-usetiful--qr-bg-type-choose'>
            <SecondaryHeader
              text={'1. ' + t('bgImageTitles.chooseQR')}
              style={{ marginRight: '1rem' }}
            />
            <TooltipInfo
              text={t('QRCodeTypes.tooltipText')}
              placement={window.innerWidth >= 600 ? 'right-start' : 'bottom'}
              arrow
              icon={{ color: muiTheme.palette.primary.main }}
            />
          </HeaderWithIconWrapper>
          <QRNavigator>
            <QRBtn
              onClick={() => setQRCodeType('online')}
              className={QRCodeType === 'online' ? 'active' : null}
              primaryColor={primaryButtonColor}
            >
              {t('QRCodeTypes.online')}
            </QRBtn>
            <QRBtn
              onClick={() => setQRCodeType('offline')}
              className={QRCodeType === 'offline' ? 'active' : null}
              primaryColor={primaryButtonColor}
            >
              {t('QRCodeTypes.offline')}
            </QRBtn>
          </QRNavigator>
        </SectionWrapper>
      ),
    [props.useForPlainHeader, t, muiTheme.palette.primary.main, QRCodeType, primaryButtonColor],
  );

  const QRPositionNavComponent = useMemo(
    () =>
      !props.useForPlainHeader && (
        <SectionWrapper>
          <SecondaryHeader text={'2. ' + t('bgImageTitles.choosePosition')} />
          <QRNavigator>
            {displayScreen === 'mobile' ? (
              <>
                {configPositionMo && (
                  <QRBtn
                    onClick={() => setQRCodePositionMo(undefined)}
                    className={!QRCodePositionMo ? 'active' : null}
                    primaryColor={primaryButtonColor}
                  >
                    {t('QRCodePositions.default')}
                  </QRBtn>
                )}
                <QRBtn
                  onClick={() => setQRCodePositionMo('top')}
                  className={QRCodePositionMo === 'top' ? 'active' : null}
                  primaryColor={primaryButtonColor}
                >
                  {t('QRCodePositions.top')}
                </QRBtn>
                <QRBtn
                  onClick={() => setQRCodePositionMo('middle')}
                  className={QRCodePositionMo === 'middle' ? 'active' : null}
                  primaryColor={primaryButtonColor}
                >
                  {t('QRCodePositions.middle')}
                </QRBtn>
                <QRBtn
                  onClick={() => setQRCodePositionMo('bottom')}
                  className={QRCodePositionMo === 'bottom' ? 'active' : null}
                  primaryColor={primaryButtonColor}
                >
                  {t('QRCodePositions.bottom')}
                </QRBtn>
              </>
            ) : (
              <>
                {configPositionVC && (
                  <QRBtn
                    onClick={() => setQRCodePositionVC(undefined)}
                    className={!QRCodePositionVC ? 'active' : null}
                    primaryColor={primaryButtonColor}
                  >
                    {t('QRCodePositions.default')}
                  </QRBtn>
                )}
                <QRBtn
                  onClick={() => setQRCodePositionVC('left')}
                  className={QRCodePositionVC === 'left' ? 'active' : null}
                  primaryColor={primaryButtonColor}
                >
                  {t('QRCodePositions.left')}
                </QRBtn>
                <QRBtn
                  onClick={() => setQRCodePositionVC('middle')}
                  className={QRCodePositionVC === 'middle' ? 'active' : null}
                  primaryColor={primaryButtonColor}
                >
                  {t('QRCodePositions.middle')}
                </QRBtn>
                <QRBtn
                  onClick={() => setQRCodePositionVC('right')}
                  className={QRCodePositionVC === 'right' ? 'active' : null}
                  primaryColor={primaryButtonColor}
                >
                  {t('QRCodePositions.right')}
                </QRBtn>
              </>
            )}
          </QRNavigator>
        </SectionWrapper>
      ),
    [
      props.useForPlainHeader,
      t,
      displayScreen,
      configPositionMo,
      QRCodePositionMo,
      primaryButtonColor,
      configPositionVC,
      QRCodePositionVC,
    ],
  );

  const statesVC = useMemo(
    () => ({
      BGPreviewImgsVC,
      QRBgImgVC,
      QRConfig,
      downloadReadyVC,
      windowWidth: window.innerWidth,
      allowOwnQrBgImage,
      sliderImagesNumber,
    }),
    [BGPreviewImgsVC, QRBgImgVC, QRConfig, allowOwnQrBgImage, downloadReadyVC, sliderImagesNumber],
  );

  const statesMo = useMemo(
    () => ({
      BGPreviewImgsMo,
      QRBgImgMo,
      QRConfig,
      downloadReadyMo,
      windowWidth: window.innerWidth,
      allowOwnQrBgImage,
      sliderImagesNumber,
    }),
    [BGPreviewImgsMo, QRBgImgMo, QRConfig, allowOwnQrBgImage, downloadReadyMo, sliderImagesNumber],
  );

  return (
    <>
      {uncroppedImage && (
        <ImageCropper
          image={uncroppedImage}
          onUpload={url => handleCroppedImage(url)}
          onClose={() => {
            setUncroppedImage('');
            setDownloadReadyMo(true);
            setDownloadReadyVC(true);
          }}
          noAspectRatio={true}
          fixedCrop={true}
          crop={{ width: 100, height: 100 }}
        />
      )}
      <StyledContainer>
        {!props.useForPlainHeader && (
          <ScreenNavigator>
            <ScreenBtn
              tabIndex={0}
              onClick={() => setDisplayScreen('mobile')}
              onKeyUp={withKeyEventHandler(() => setDisplayScreen('mobile'))}
              className={displayScreen === 'mobile' ? 'active mobile' : null}
              color={primaryButtonColor}
            >
              {t('mobileBG')}
            </ScreenBtn>
            <ScreenBtn
              tabIndex={0}
              onKeyUp={withKeyEventHandler(() => setDisplayScreen('videocall'))}
              onClick={() => setDisplayScreen('videocall')}
              className={displayScreen === 'videocall' ? 'active videocall' : null}
              color={primaryButtonColor}
            >
              {t('videocallBG')}
            </ScreenBtn>
          </ScreenNavigator>
        )}
        <div ref={qrCodeEl} style={{ display: 'none' }}>
          <QRCode
            value={QRCodeType === 'online' ? profileLink : offlineUrl}
            size={QRCodeImgSize}
            includeMargin={true}
            renderAs='svg'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            xmlSpace='preserve'
            crossOrigin=''
          />
        </div>
        {QRImgReady ? (
          displayScreen === 'videocall' ? (
            <QRCodeBgVideocall
              t={t}
              QRCodeNavComponent={QRTypeNavComponent}
              QRCodePositionComponent={QRPositionNavComponent}
              states={statesVC}
              getQrCodeImg={getQrCodeImg}
              handleOnImgClick={onImgClickHandler}
              handleUploadImage={uploadImageClickHandler}
              handleUpload={uploadImgHandler}
              handleDownloadImg={downloadImgHandler}
              setQrCodeImgSize={setQrCodeImgSize}
            />
          ) : (
            <QRCodeBgMobile
              t={t}
              QRCodeNavComponent={QRTypeNavComponent}
              QRCodePositionComponent={QRPositionNavComponent}
              states={statesMo}
              getQrCodeImg={getQrCodeImg}
              handleOnImgClick={onImgClickHandler}
              handleUploadImage={uploadImageClickHandler}
              handleUpload={uploadImgHandler}
              handleDownloadImg={downloadImgHandler}
              useForPlainHeader={props.useForPlainHeader}
              setQrCodeImgSize={setQrCodeImgSize}
            />
          )
        ) : null}
      </StyledContainer>
    </>
  );
};

export default QRBG;
