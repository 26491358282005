import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { routePaths } from '../../../../infrastructure/constants';
import withNav from '../../../../infrastructure/hoc/withNav';
import { ERROR_KEYS } from '../../../../shared/constants';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../config/config';
import toast from 'react-hot-toast';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import { APIError } from '../../../../shared/types/api';
import { validateEmail } from '../../../../shared/util';
import { PageContainer } from '@/components';
import { Alert, AlertTitle, Box, Card, TextField } from '@mui/material';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import LtLoadingButton from '@/components/LtLoadingButton';
import {
  canChangeEmailAddress,
  getAccountIdentityProviderByIdSync,
  getIdpFriendlyName,
} from '@/shared/business-logic/lib/account-identities';

const AuthEmail = () => {
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const { t } = useAppTranslation();
  const { desktopView } = useDesktopView();

  const currentEmail = user?.email;
  const [newEmail, setNewEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canChangeEmail = canChangeEmailAddress(user);

  const hasEmailChanged = useMemo(() => user?.email !== newEmail, [newEmail, user?.email]);

  const allowSubmit = useMemo(
    () => !isLoading && hasEmailChanged && !!newEmail && validateEmail(newEmail),
    [hasEmailChanged, isLoading, newEmail],
  );

  const onEmailSave = () => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.post(
          config.API_BASE_URL + `account/change-auth-email`,
          { email: newEmail },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      [],
      () => {
        setIsLoading(true);
      },
    )
      .then(res => {
        if (res.data?.isSuccess) {
          logout();
        } else {
          toast.error(t('error.general'));
        }
      })
      .catch((err: APIError) => {
        if (err?.response?.data?.error?.code === ERROR_KEYS.DISPOSABLE_EMAIL) {
          // disposable email (not allowed)
          toast.error(t('error.disposableEmail'));
        } else if (err?.response?.data?.error?.code === ERROR_KEYS.EXISTED_EMAILS) {
          // email already existed for another user
          toast.error(t('error.existedEmail'));
        } else if (err?.response?.data?.error?.code === ERROR_KEYS.INVALID_EMAIL) {
          // invalid email format
          toast.error(t('error.invalidEmail'));
        } else if (err?.response?.data?.error?.code === ERROR_KEYS.UNCHANGEABLE_SOCIAL_EMAIL) {
          // social email could not be changed
          toast.error(t('error.socialEmail'));
        } else {
          toast.error(t('error.general'));
        }
      })
      .finally(() => {
        toast.dismiss('change-email-toast');
        setIsLoading(false);
      });
  };

  const Wrapper = useCallback(
    (props: PropsWithChildren<{}>) =>
      desktopView ? (
        <Card sx={{ p: 2 }}>{props.children}</Card>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>{props.children}</Box>
      ),
    [desktopView],
  );

  return (
    <PageContainer maxWidth='s'>
      <Wrapper>
        <TextField
          value={currentEmail}
          fullWidth
          label={t('changeEmail.currentEmail')}
          sx={{ mb: 2 }}
          inputProps={{ style: { cursor: 'not-allowed' } }}
          disabled
        />
        {canChangeEmail ? (
          <>
            <TextField
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
              fullWidth
              label={t('changeEmail.newEmail')}
              sx={{ mb: 4 }}
              error={!hasEmailChanged}
              helperText={
                !!newEmail &&
                (!hasEmailChanged
                  ? t('changeEmail.error.unchanged')
                  : !validateEmail(newEmail)
                  ? t('changeEmail.error.invalidFormat')
                  : undefined)
              }
            />
            <LtLoadingButton
              presets='save'
              disabled={!allowSubmit}
              onClick={onEmailSave}
              loading={isLoading}
            />
          </>
        ) : (
          <Alert severity='warning' variant='outlined' sx={{ borderRadius: '1rem' }}>
            <AlertTitle>{t('changeEmail.notAllowed.title')}</AlertTitle>
            {t('changeEmail.notAllowed.text', {
              idp: getIdpFriendlyName(getAccountIdentityProviderByIdSync(user)),
            })}
          </Alert>
        )}
      </Wrapper>
    </PageContainer>
  );
};

export default withNav(
  AuthEmail,
  {
    tTitle: 'changeEmail.title',
    showBackButton: {
      linkTo: routePaths.SETTINGS.MAIN,
    },
  },
  {
    activeScreen: routePaths.SETTINGS.AUTH,
  },
);
