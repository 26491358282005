import { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  text: string | ReactElement;
  color?: string;
  size?: string | number;
  weight?: number | string;
  fontStyle?: string;
  lineHeight?: string;
  multiline?: boolean;
  textAlign?: string;
  variant?: 'strong' | 'light';
  style?: CSSProperties;
}

export const Text = ({ text, ...props }: Props) => {
  return <TextWrapper {...props}>{text}</TextWrapper>;
};

const TextWrapper = styled.div`
  word-wrap: break-word;
  color: ${(props: Props) => props.color || '#55595E'};
  font-size: ${(props: Props) => props.size || '1.4rem'};
  font-weight: ${(props: Props) => props.weight || 'normal'};
  font-style: ${(props: Props) => props.fontStyle || 'unset'};
  line-height: ${(props: Props) => `${props.lineHeight} !important` || '1'};
  ${(props: Props) => props.multiline && `white-space: pre-wrap`};
  ${(props: Props) => props.textAlign && `text-align: center`};

  ${(props: Props) =>
    props.variant === 'strong' &&
    `
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.7rem;

    @media (max-width: 320px) {
      font-size: 1.5rem;
    }
  `}

  ${(props: Props) =>
    props.variant === 'light' &&
    `
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.1rem;

    @media (max-width: 320px) {
      font-size: 1.1rem;
    }
  `}
`;
