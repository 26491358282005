// this popup will be shown when a user upgrades on mobile, and therefore is not able to access the md

import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeviceInfo from '@/infrastructure/hooks/useDeviceInfo';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { LtDialog } from '@/components';

interface Props {
  open?: boolean;
  onClose(): void;
}

export default function UpgradedOnMobilePopup({ onClose, open }: Props) {
  const { isMobile } = useDeviceInfo();
  const { isStarter } = useTierInfo();
  const { t } = useAppTranslation();

  if (isStarter || !isMobile) return null;

  return (
    <LtDialog open={open} title={t('upgradedOnMobile.title')} onClose={onClose}>
      <p>{t('upgradedOnMobile.text')}</p>
    </LtDialog>
  );
}
