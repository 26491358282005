import { Alert, AlertTitle } from '@mui/material';

type Props = {
  title: string;
  body: string | JSX.Element;
  onClose?: () => void;
};
const InfoCard = ({ title, body, onClose }: Props) => {
  return (
    <Alert
      sx={{ mb: 2 }}
      severity='info'
      variant='outlined'
      onClose={onClose ? onClose : undefined}
    >
      <AlertTitle>{title}</AlertTitle>
      {body}
    </Alert>
  );
};

export default InfoCard;
