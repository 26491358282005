import { useUnsavedStatus } from '@/utils/unsavedStatus';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

export const LayoutLayer: React.FC = ({ children }) => {
  const { isUnsaved } = useUnsavedStatus();

  const { t } = useTranslation();

  //Prevent tab close
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (isUnsaved) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUnsaved]);

  return (
    <>
      <Prompt when={isUnsaved} message={t('unsavedChangesText')} />
      {children}
    </>
  );
};
