import React from 'react';
import styled from 'styled-components';
import backIcon from '../../images/back-black.svg';

interface Props {
  onBackClick: () => void;
  headerText: string;
  contents?: JSX.Element;
}

const SettingsHeader = (props: Props) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton>
          <Image src={backIcon} alt='Back' onClick={() => props.onBackClick()} />
        </BackButton>
        {props.contents || (
          <Heading className='settings-header-text-wrapper'>{props.headerText}</Heading>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default SettingsHeader;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 10vh;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const Image = styled.img`
  cursor: pointer;
`;

const BackButton = styled.div`
  margin: auto 0;
`;

const Heading = styled.div`
  margin: auto;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
`;
