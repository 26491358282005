import i18n from '@/config/i18n';
import { ACCOUNT_FIELDS_MAX_LENGTH, ERROR_KEYS, TRIAL_MAX_USERS } from '@/shared/constants';
import { APIError } from '@/shared/types/api';
import { AccountFieldsErr } from '@/shared/types/global';

export const getImportError = (err: APIError['response']['data']['error']) => {
  let error: string;
  if (err.code === ERROR_KEYS.INVALID_FIELDS_LENGTH) {
    // fields length errors
    const errors = err.data?.errors as AccountFieldsErr[];
    errors.forEach(err => {
      error = (error ? error : '') + i18n.t('csvImport.errors.length.header', { user: err.user });
      error += getAccountLengthError(err);
    });
  } else if (err.code === ERROR_KEYS.INVALID_EMAIL) {
    // invalid emails
    const invalidEmails = err.data?.emails as string[];

    error = i18n.t('csvImport.errors.invalidEmails', {
      emails: invalidEmails.join('", "'),
    });
  } else if (err.code === ERROR_KEYS.DISPOSABLE_DOMAINS) {
    // disposable domains
    const disposableDomains = err.data?.domains as string[];

    error = i18n.t('csvImport.errors.disposableDomains', {
      domains: disposableDomains.join('", "'),
    });
  } else if (err.code === ERROR_KEYS.DUPLICATED_EMAILS) {
    // duplicated emails inputs
    const duplicatedEmails = err.data?.emails as string[];

    error = i18n.t('csvImport.errors.duplicatedEmails', {
      emails: duplicatedEmails.join('", "'),
    });
  } else if (err.code === ERROR_KEYS.EXISTED_EMAILS) {
    // already existed profiles
    const existedEmails = err.data?.emails as string[];

    error = i18n.t('csvImport.errors.existedEmails', {
      emails: existedEmails.join('", "'),
    });
  } else if (err.code === ERROR_KEYS.MISSING_PARAMS) {
    // missed params
    const row = err.data?.row as number;
    const requiredParams = err.data?.requiredParams as string[];

    error = i18n.t('csvImport.errors.missedParams', {
      params: requiredParams.join('", "'),
      row,
    });
  } else if (err.code === ERROR_KEYS.NOT_EXISTED_ADDR_TEMPLATE) {
    // invalid/not existed address_templates inputs
    const row = err.data?.row as number;

    error = i18n.t('csvImport.errors.invalidParam', {
      param: 'addressTemplateId',
      row,
    });
  } else if (err.code === ERROR_KEYS.INVALID_UNIT) {
    // invalid unit input
    const row = err.data?.row as number;

    error = i18n.t('csvImport.errors.invalidParam', {
      param: 'unit',
      row,
    });
  } else if (err.code === ERROR_KEYS.INVALID_CODE) {
    // invalid/not existed codes
    const invalidCodes = err.data?.codes as string[];
    error = i18n.t('csvImport.errors.invalidCodes', {
      codes: invalidCodes.join('", "'),
    });
  } else if (err.code === ERROR_KEYS.TRIAL_USERS_EXCEEDED) {
    // trial users exceeded
    error = i18n.t('csvImport.errors.trialUsersExceeded', {
      max: TRIAL_MAX_USERS,
    });
  } else if (err.code === ERROR_KEYS.AUTH0_JOB_ERROR) {
    // auth0 import job unexpected error
    error = i18n.t('error.general');
  } else {
    error = err.message ? err.message : i18n.t('error.general');
  }

  return error;
};

export const getAccountLengthError = (err: AccountFieldsErr): string => {
  let error: string = '';

  if (err.email || err.workEmail || err.workEmail2)
    error += i18n.t('csvImport.errors.length.fields', {
      fields: 'Emails',
      length: ACCOUNT_FIELDS_MAX_LENGTH.EMAIL,
    });
  if (err.firstName || err.lastName)
    error += i18n.t('csvImport.errors.length.fields', {
      fields: `${i18n.t('firstName')}, ${i18n.t('lastName')}`,
      length: ACCOUNT_FIELDS_MAX_LENGTH.FIRSTNAME,
    });
  if (err.id)
    error += i18n.t('csvImport.errors.length.field', {
      field: 'ID',
      length: ACCOUNT_FIELDS_MAX_LENGTH.ID,
    });
  if (err.username)
    error += i18n.t('csvImport.errors.length.field', {
      field: i18n.t('username'),
      length: ACCOUNT_FIELDS_MAX_LENGTH.TEXT,
    });

  if (err.position || err.role || err.division || err.company)
    error += i18n.t('csvImport.errors.length.fields', {
      fields: `${i18n.t('position')}, ${i18n.t('role')},${i18n.t('company')}, ${i18n.t(
        'division',
      )}`,
      length: ACCOUNT_FIELDS_MAX_LENGTH.TEXT,
    });
  if (
    err.address1_addressee ||
    err.address1_addressLine1 ||
    err.address1_addressLine2 ||
    err.address1_city ||
    err.address1_country ||
    err.address1_label ||
    err.address2_addressee ||
    err.address2_addressLine1 ||
    err.address2_addressLine2 ||
    err.address2_city ||
    err.address2_country ||
    err.address2_label
  )
    error += i18n.t('csvImport.errors.length.fields', {
      fields: `${i18n.t('address.label')}, ${i18n.t('address.addressLine1')},${i18n.t(
        'address.addressLine2',
      )}, ${i18n.t('address.city')}, ${i18n.t('address.country')}`,
      length: ACCOUNT_FIELDS_MAX_LENGTH.TEXT,
    });

  if (
    err.mobilePhone ||
    err.workPhone ||
    err.homePhone ||
    err.hotlinePhone ||
    err.assistantPhone ||
    err.workFax ||
    err.address1_postCode ||
    err.address2_postCode
  )
    error += i18n.t('csvImport.errors.length.fields', {
      fields: `${i18n.t('phone')}, Fax,${i18n.t('address.postCode')}`,
      length: ACCOUNT_FIELDS_MAX_LENGTH.NUMBER,
    });

  if (err.addressTemplate1Id || err.addressTemplate2Id)
    error += i18n.t('csvImport.errors.length.amount', {
      field: 'adderssTemplateId',
      amount: ACCOUNT_FIELDS_MAX_LENGTH.INTEGER,
    });
  if (err.workUrl)
    error += i18n.t('csvImport.errors.length.fields', {
      fields: 'URLs',
      length: ACCOUNT_FIELDS_MAX_LENGTH.URL,
    });

  return error;
};
