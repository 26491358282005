import TooltipInfo from '@/views/components/generic/TooltipInfo';
import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Switch,
  Typography,
} from '@mui/material';

type Props = {
  title: string;
  checked: boolean;
  onChange: () => void;
  tooltip?: string;
  subMenu?: React.ReactNode;
} & React.ComponentProps<typeof Box>;

const SwitchRow = ({ title, checked, onChange, tooltip, subMenu, ...boxProps }: Props) => {
  return (
    <Box {...boxProps}>
      {!!subMenu ? (
        <Card sx={{ mt: 1 }}>
          <Accordion square={true} sx={{ borderRadius: '.7rem', boxShadow: 'none' }}>
            <Box display={'flex'} alignItems={'center'} pr={2}>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='panel1-content'
                id='panel1-header'
                sx={{ width: '100%' }}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <Typography color='text.primary'>{title}</Typography>
                  {tooltip && (
                    <TooltipInfo text={tooltip} icon={{ color: 'text.primary' }} onHover />
                  )}
                </Box>
              </AccordionSummary>
              <Box display={'flex'} justifyContent={'end'}>
                <Switch
                  checked={checked}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            </Box>
            <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {subMenu}
            </AccordionDetails>
          </Accordion>
        </Card>
      ) : (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1.7,
            pt: 0.5,
            pb: 0.5,
            mt: 1,
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Typography color='text.primary'>{title}</Typography>
            {tooltip && <TooltipInfo text={tooltip} icon={{ color: 'text.primary' }} onHover />}
          </Box>
          <Box>
            <Switch
              checked={checked}
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default SwitchRow;
