import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useAppSelector } from '../../../application/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { routePaths } from '../../../infrastructure/constants';
import PageNotFound from '../page-not-found';
import StickyBanner from '../../components/common/sticky-banner';
import Layout from '../../components/common/white-layout';
import LinkBoxThemed from './LinkBoxTheme';
import FileBox from './FileBox';
import {
  Link,
  ApiFile,
  Account,
  ProfileDesignConfig,
  StaticProfileConfig,
} from '../../../shared/types/api';
import { PUBLIC_EVENTS } from '../../../shared/constants/global/analytics.constants';

import toast from 'react-hot-toast';

import { trackPublicEvent } from '../../../infrastructure/apis/analytics';
import withNav from '../../../infrastructure/hoc/withNav';
import axios from 'axios';
import config from '../../../config/config';
import { withSilentAccessToken } from '../../../infrastructure/helper';
import HideableCallout from './HideableCallout';
import { BsCheckCircle } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import {
  DEFAULT_BOX_STYLE,
  THEME_LINKS_FILES_POSITIONS,
  THEME_LINK_EDIT_MODES,
} from '../../../shared/constants';
import useQuery from '../../../infrastructure/hooks/useQuery';
import { fetchProfile as fetchProfile_api } from '@/infrastructure/apis/profile';
import StaticProfile from './components/StaticProfile';
import StaticProfileSkeleton from './components/StaticProfileSkeleton';
import MetaTags from './components/MetaTags';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { I18nextProvider } from 'react-i18next';
import i18nMain, {
  I18N_LOCAL_STORAGE_KEY,
  QUERY_KEY_LANG,
  getNewI18nInstance,
} from '@/config/i18n';
import { i18n } from 'i18next';
import PrivateAlert from './PrivateAlert';
import { base64UrlDecode } from '@/shared/util';

// TODO: profile page optimizations
// consolidate logic, universalize logic, make parts re-usable

interface TParams {
  username: string;
}

export default function UserProfile(props: RouteComponentProps<TParams>): JSX.Element {
  const usernameOrCode = props.match.params.username;

  const query = useQuery();
  const history = useHistory();

  const forceProfile = !!query.get('forceProfile');

  // i18n instance logic
  const [i18nProfile, setI18nProfile] = useState<null | i18n>(null);
  const t = useMemo(() => (i18nProfile?.t ? i18nProfile.t : (t: string) => t), [i18nProfile?.t]);

  // initiate language to fetch profile for
  const [activeLanguage, setActiveLanguage] = useState(
    query.get(QUERY_KEY_LANG)?.substring(0, 2) ||
      localStorage.getItem(I18N_LOCAL_STORAGE_KEY.PROFILE) ||
      navigator.language,
  );

  // used to initiate the i18n instance one the profile information has been fetched
  const initI18nProfile = useCallback(
    (profile: StaticProfileConfig) => {
      if (!i18nProfile) {
        setI18nProfile(
          getNewI18nInstance({
            fallbackLng: 'en',
            supportedLngs: profile.meta.supportedLangs,
            lng: profile.meta.lang,
            detection: {
              order: ['localStorage', 'navigator'],
              lookupLocalStorage: I18N_LOCAL_STORAGE_KEY.PROFILE,
            },
          }),
        );
      }
    },
    [i18nProfile],
  );

  // query params to forward to the server
  const paramsFromQuery = useMemo(
    () => ({
      uid: query.get('uid')
        ? parseInt(query.get('uid')) || parseInt(base64UrlDecode(query.get('uid'))) || undefined
        : undefined,
    }),
    [query],
  );

  const { isAuthenticated, isLoading: isAuth0Loading, getAccessTokenSilently } = useAuth0();

  // logged-in user
  const account = useAppSelector<Account>(state => state.account);
  const accountDataLoaded = useMemo(() => account && Object.keys(account).length > 0, [account]);

  // profile fetching
  const [profile, setProfile] = useState<StaticProfileConfig>(null);
  const cachedProfiles = useRef<Record<string, StaticProfileConfig>>({}); // if lang is switched back and forth multiple times
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileReloading, setProfileReloading] = useState(false);
  const fetchProfile = useCallback(
    (setLoaderFct = setProfileLoading) => {
      if (cachedProfiles.current[activeLanguage]) {
        setProfile(cachedProfiles.current[activeLanguage]);
        return;
      }

      setLoaderFct(true);
      fetchProfile_api(usernameOrCode, activeLanguage, paramsFromQuery)
        .then(res => {
          const { status, name, code, profile } = res;
          if (status === 200 && name === 'profile') {
            if (profile.forward && !forceProfile) {
              window.open(profile.forward, '_self');
            } else if (profile.meta.domain !== window.location.origin) {
              window.location.replace(
                `${profile.meta.domain}/${profile.meta.username}` + window.location.search,
              );
            } else {
              cachedProfiles.current[activeLanguage] = profile;
              setProfile(profile);
              initI18nProfile(profile);
              setLoaderFct(false);
            }
          } else if (status === 200 && name === 'code') {
            history.push(`/landing/check/${code}`);
          }
        })
        .catch(() => setLoaderFct(false));
    },
    [activeLanguage, forceProfile, history, initI18nProfile, paramsFromQuery, usernameOrCode],
  );
  useEffect(() => {
    if (!profile) {
      // don't refetch when forwarded due to code
      fetchProfile();
    }
  }, [fetchProfile, profile]);
  // do re-fetch on language change
  const prevLang = useRef<string>(activeLanguage);
  useEffect(() => {
    if (prevLang.current && activeLanguage !== prevLang.current) {
      prevLang.current = activeLanguage;
      fetchProfile(setProfileReloading);
    }
  }, [activeLanguage, fetchProfile]);

  useDeferredLoader(profileReloading, 'profile-reloader');

  // once profile is available, attach listener to lang changed event
  // to update the state "activeLanguage". Otherwise no re-render would be triggered
  useEffect(() => {
    if (i18nProfile) {
      const listener = (lang: string) => {
        setActiveLanguage(lang);
        if (query.get(QUERY_KEY_LANG)) {
          query.set(QUERY_KEY_LANG, lang);
          history.replace('/' + profile.meta.username + '?' + query.toString());
        }
      };
      i18nProfile.on('languageChanged', listener);
      return () => i18nProfile.off('languageChanged', listener);
    }
  }, [history, i18nProfile, profile?.meta.username, query]);

  // replace path with username
  useEffect(() => {
    if (profile && profile?.meta.username !== usernameOrCode) {
      history.replace('/' + profile.meta.username + window.location.search);
    }
  }, [history, profile, usernameOrCode]);

  const renderTryForFree = !isAuth0Loading && profile?.config.tryForFreeBanner && !isAuthenticated;

  // scroll to lead gen form logic
  const leadGenFormRef = useRef(null);
  const scrollToLeadForm = history.location.hash === '#leadform';
  useEffect(() => {
    if (scrollToLeadForm && leadGenFormRef.current) {
      // just give it half a second to render
      setTimeout(() => leadGenFormRef.current.scrollIntoView?.({ behavior: 'smooth' }), 500);
    }
  }, [scrollToLeadForm]);

  useEffect(() => window.scrollTo(0, 0), []);

  const utmParams = useMemo(() => {
    const out = {};
    for (const key of Array.from(query.keys())) {
      if (key.startsWith('utm_')) {
        out[key] = query.get(key);
      }
    }
    return out;
  }, [query]);

  const thArg = useMemo(
    () => ({
      username: account?.username,
      isAuthenticated,
      isAuth0Loading,
    }),
    [account?.username, isAuth0Loading, isAuthenticated],
  );
  useEffect(() => {
    if (profile) {
      trackPublicEvent(thArg, PUBLIC_EVENTS.PROFILE_VIEW, profile.meta.username, null, null, {
        utm: utmParams,
      });
    }
  }, [profile, thArg, utmParams]);

  // favicon
  useEffect(() => {
    if (profile?.config?.iconUrl) {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = profile.config.iconUrl;
    }
  }, [profile?.config?.iconUrl]);

  // lt2lt
  const lt2ltDisabled = useMemo(
    () => query.get('lt2lt') === 'false' || localStorage.getItem('lt2lt') === 'false',
    [query],
  );
  const lt2ltExecuted = useRef(false);
  useEffect(() => {
    if (
      lt2ltExecuted.current ||
      lt2ltDisabled ||
      !isAuthenticated ||
      !profile ||
      !accountDataLoaded ||
      profile.meta.username === account.username
    )
      return;

    lt2ltExecuted.current = true;

    const toastId = 'lt2lt-toast';
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + `lead-gen/accounts/${account.id}/connect`,
        {
          ltId: profile.meta.username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
      .then(res => {
        if (res?.data?.isSuccess) {
          trackPublicEvent(
            thArg,
            PUBLIC_EVENTS.CONTACT_LT2LT_SAVED,
            profile.meta.username,
            null,
            null,
            { username: account.username },
          );
          toast(
            thisToast => (
              <HideableCallout
                titleIcon={<BsCheckCircle />}
                title={t('publicProfile.autoConnect.heading', { name: profile.bio.firstName })}
                subtitle={t('publicProfile.autoConnect.subheading')}
                buttonText={t('publicProfile.autoConnect.button')}
                onButtonClick={() => {
                  toast.dismiss(thisToast.id);
                  setTimeout(
                    () =>
                      history.push(
                        routePaths.CONTACTS.EDIT.replace(':leadId', `${res.data?.data?.id}`),
                      ),
                    500,
                  );
                }}
                onHideClick={() => toast.dismiss(thisToast.id)}
              />
            ),
            {
              id: toastId,
              duration: 15000,
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                maxWidth: 'unset',
                marginTop: '4rem',
              },
            },
          );
        }
      })
      .catch(err => {
        if (err?.response?.data?.error?.code === 'exists-already') {
          toast(
            thisToast => (
              <HideableCallout
                titleIcon={<BsCheckCircle />}
                title={t('publicProfile.autoConnect.heading', { name: profile.bio.firstName })}
                subtitle={t('publicProfile.autoConnect.subheading')}
                buttonText={t('publicProfile.autoConnect.button')}
                onButtonClick={() => {
                  toast.dismiss(thisToast.id);
                  setTimeout(
                    () =>
                      history.push(
                        routePaths.CONTACTS.EDIT.replace(
                          ':leadId',
                          `${err.response.data.error?.data?.id}`,
                        ),
                      ),
                    500,
                  );
                }}
                onHideClick={() => toast.dismiss(thisToast.id)}
              />
            ),
            {
              id: toastId,
              duration: 15000,
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                maxWidth: 'unset',
                marginTop: '4rem',
              },
            },
          );
        }
        // do nothing (may be error, or lt2lt may be forbidden by themeInternal.lt2ltInternal)
      });
  }, [
    account,
    accountDataLoaded,
    getAccessTokenSilently,
    history,
    isAuthenticated,
    lt2ltDisabled,
    profile,
    t,
    thArg,
  ]);

  const forcedLeadValues = useMemo(() => ({ sendContactToEmail: true }), []);

  if (profileLoading) {
    return <StaticProfileSkeleton />;
  } else if (!profile || !i18nProfile) {
    return <PageNotFound />;
  }

  const isOwnProfile = isAuthenticated && account?.username === profile.meta.username;
  const content = (
    <>
      <I18nextProvider i18n={i18nProfile}>
        <MetaTags bio={profile.bio} />

        {profile.forward && isOwnProfile && (
          <PrivateAlert ttext='publicProfile.profileForced' tobj={{ url: profile.forward }} />
        )}

        <StaticProfile
          profile={profile}
          activeLanguage={activeLanguage}
          authAccount={!isAuth0Loading ? account : null}
          renderTryForFree={renderTryForFree}
          noCookieSettings={isAuthenticated}
          leadGenFormRef={leadGenFormRef}
          forcedLeadValues={forcedLeadValues}
        />
      </I18nextProvider>

      {isOwnProfile && (
        <StickyBanner bannerLink={routePaths.EDITPROFILE} tLabel={'editProfile'} cssFixed />
      )}
    </>
  );

  if (isAuthenticated && accountDataLoaded && account.username !== profile.meta.username) {
    const PageContentsWithNav = withNav(
      () => content,
      {
        tTitle: i18nMain.t('profileOf', {
          name: profile.bio.firstName + ' ' + profile.bio.lastName,
        }),
      },
      { activeScreen: routePaths.CONTACTS.ROOT, collapsed: true, noHelpButton: true },
    );
    return <PageContentsWithNav />;
  } else {
    return content;
  }
}

export const renderLinks = (
  profile: Account,
  linkTH,
  t,
  linkEditMode,
  profileDesign: ProfileDesignConfig,
  genericHeader = false,
) => {
  let links: Link[] = [];
  if (linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS && profile.links) {
    links = profile.links
      .filter(n => n.canDisplayOnProfile)
      .sort((a, b) => {
        // move order logic to server
        if (a.linkType.id === b.linkType.id) {
          if (a.isCustomLink && b.isCustomLink) return a.order - b.order;
          else if (a.isCustomLink && !b.isCustomLink) return 1;
          else if (!a.isCustomLink && b.isCustomLink) return -1;
          else return a.order - b.order;
        } else {
          let a_order = a.themeLinkOption?.themeLinkType?.order;
          let b_order = b.themeLinkOption?.themeLinkType?.order;
          if (!a_order) {
            a_order =
              profile.theme.themeLinkTypes?.find(val => val.linkType.id === a.linkType.id)?.order ||
              0;
          }
          if (!b_order) {
            b_order =
              profile.theme.themeLinkTypes?.find(val => val.linkType.id === b.linkType.id)?.order ||
              0;
          }
          return a_order - b_order;
        }
      });
  } else if (profile.links) {
    links = profile.links
      .filter(n => n.canDisplayOnProfile === true)
      .sort((a, b) => a.order - b.order);
  }
  if ((links && links.length > 0) || (profile.theme.links && profile.theme.links.length > 0)) {
    const themeLinks = profile.theme.links.map((item: Link, index: number) => (
      <LinkBoxThemed
        key={index}
        item={item}
        boxStyle={profileDesign.boxStyle}
        trackHandler={() => linkTH(item.id)}
        genericWebsiteColor={profileDesign.genericWebsiteColor}
      />
    ));
    return (
      <Layout
        headerText={genericHeader ? t('links') : t('myLinks')}
        themeBoxStyle={profileDesign.boxStyle || DEFAULT_BOX_STYLE}
      >
        {profileDesign.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeLinks}
        {links.map((item: Link, index: number) => (
          <LinkBoxThemed
            key={index}
            item={item}
            boxStyle={profileDesign.boxStyle}
            trackHandler={() => linkTH(item.id)}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
        ))}
        {profileDesign.themeLinksPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeLinks}
      </Layout>
    );
  } else {
    return null;
  }
};

export const renderFiles = (
  profile: Account,
  fileTH,
  t,
  profileDesign: ProfileDesignConfig,
  genericHeader = false,
) => {
  if (profile.files || profile.theme.files) {
    const themeFiles = profile.theme.files;
    const profileFiles = profile.files
      .filter(n => n.canDisplayOnProfile === true)
      .sort((a, b) => a.order - b.order);
    if (profileFiles.length > 0 || themeFiles.length > 0) {
      const allFiles =
        profileDesign.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.AFTER
          ? [...profileFiles, ...themeFiles]
          : [...themeFiles, ...profileFiles];
      return (
        <Layout
          headerText={genericHeader ? t('files') : t('myFiles')}
          themeBoxStyle={profileDesign.boxStyle || DEFAULT_BOX_STYLE}
        >
          {allFiles.map((item: ApiFile, index: number) => (
            <FileBox
              key={index}
              item={item}
              boxStyle={profileDesign.boxStyle}
              fileBoxColor={profileDesign.fileBoxColor}
              trackHandler={() => fileTH(item.id, item.pageCount)}
            />
          ))}
        </Layout>
      );
    }
  }
};
