import styled from 'styled-components';

export interface InputProps {
  label: string;
  type: string;
  name: string;
  handleChange: (e: HTMLInputElement) => void;
  errorMessage: string;
  isValid: boolean;
  value: any;
  iconType?: string;
  paddingLeftSize?: number;
  disabled?: boolean;
}

export const Input = (props: InputProps) => {
  return (
    <Wrapper>
      <InputField
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.label}
        autoComplete='off'
        paddingLeftSize={props.paddingLeftSize}
        disabled={props.disabled}
      />
      {props.errorMessage && !props.isValid && <ErrorWrapper>*</ErrorWrapper>}
    </Wrapper>
  );
};

const InputField = styled.input`
  padding-left: ${(props: { paddingLeftSize: number }) => props.paddingLeftSize || 50}px !important;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #777;
  background-color: #ffffff;
  outline: none;
  font-size: 1.1rem;
  box-sizing: border-box;
  margin: 0 0 8px 0;

  &[disabled] {
    background-color: rgba(59, 59, 59, 0.3);
    color: rgb(170, 170, 170);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ErrorWrapper = styled.span`
  position: relative;
  left: -20px;
  color: #e20f0f;
  font-size: 20px;
  top: 14px;
  box-sizing: border-box;
`;
