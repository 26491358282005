import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { Alert, AlertTitle, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const UpgradeAlert = ({ description }) => {
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();
  const { t } = useTranslation();

  const { isTrial } = useTierInfo();
  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');

  return (
    <>
      <Alert severity='info' variant='outlined' sx={{ mb: 4 }} dir='vertical'>
        <AlertTitle>{t('emailTemplates.upgradeAlertTitle')}</AlertTitle>
        {description}{' '}
        <Link sx={{ cursor: 'pointer' }} onClick={() => upgradeAction()}>
          {isTrial
            ? t('upgradeTeaser.enableForTrial')
            : flag_hidePrices
            ? t('contactSales')
            : t('upgradeNow')}
        </Link>
      </Alert>
      {upgradeRequestPopup}
    </>
  );
};
