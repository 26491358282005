import { useState } from 'react';
import { useAppDispatch } from '../../../application/hooks';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import { isFileValid } from '../../../infrastructure/helper';
import { updateImage, uploadImage } from '../../../application/actions/edit-profile';
import EditImageIcon from '../profile-details/EditImageIcon';
import BannerChooser from './banner-chooser';

import { useTranslation } from 'react-i18next';
import { trackPrivateEvent } from '../../../infrastructure/apis/analytics';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../shared/constants/global';
import { Popup } from '../common';
import ImageCropper from '../image-cropper';

interface Props {
  username: string;
  bannerImageUrl: string;
  canEdit: boolean;
  bannerImgOptions?: string[];
}

const Banner = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [image, setImage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showBannerChooser, setShowBannerChooser] = useState(false);

  const { username, bannerImageUrl, bannerImgOptions } = props;
  let fileInput: HTMLInputElement;

  const onImageClick = (fileType: string) => {
    if (fileType === 'banner' && bannerImgOptions && bannerImgOptions.length > 0) {
      setShowBannerChooser(true);
    } else {
      fileInput.click();
    }
  };

  const onImageSelection = async () => {
    const uploadedFile = fileInput.files[0];
    const extension = uploadedFile.name.split('.').pop();

    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        setErrorMsg(errMsg);
        return;
      }

      if (extension === 'gif') {
        // upload right away
        dispatch(
          uploadImage(username, uploadedFile, 'banner', getAccessTokenSilently, () => {
            trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
              type: PRIVATE_META_TYPES.BANNER,
            });
          }),
        );
      } else {
        // show cropper
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => setImage(reader.result as string);
      }
    }
  };

  const onBannerUrlSelected = async (newUrl: string) => {
    setShowBannerChooser(false);
    dispatch(
      updateImage(username, newUrl, 'banner', getAccessTokenSilently, () => {
        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
          type: PRIVATE_META_TYPES.BANNER,
        });
      }),
    );
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    var file = new File([blob], fileInput.files[0].name);
    setImage('');
    dispatch(
      uploadImage(username, file, 'banner', getAccessTokenSilently, () => {
        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
          type: PRIVATE_META_TYPES.BANNER,
        });
      }),
    );
  };

  return (
    <>
      {image && (
        <ImageCropper
          image={image}
          onUpload={url => onUploadClick(url)}
          onClose={() => setImage('')}
          crop={{ width: 100, x: 0, y: 0, aspect: 16 / 9 }}
        />
      )}

      {showBannerChooser && bannerImgOptions && (
        <BannerChooser
          onCancel={() => setShowBannerChooser(false)}
          options={bannerImgOptions}
          onSelected={onBannerUrlSelected}
          current={bannerImageUrl}
        />
      )}

      {errorMsg && <Popup bodyText={errorMsg} onCloseClick={() => setErrorMsg('')} isErrorPopup />}

      <BannerWrapper>
        <BannerContainer>
          <BannerBlock>
            <img src={bannerImageUrl} alt='Banner' />
          </BannerBlock>
          {props.canEdit && (
            <EditImageIcon
              style={{ top: '1rem', right: '1rem' }}
              onClick={() => onImageClick('banner')}
            />
          )}
        </BannerContainer>
        <input
          type='file'
          ref={input => {
            fileInput = input;
          }}
          onClick={event => {
            (event.target as HTMLInputElement).value = null;
          }}
          onChange={onImageSelection}
          style={{ display: 'none' }}
          accept='image/*'
        />
      </BannerWrapper>
    </>
  );
};

export default Banner;

const BannerWrapper = styled.div`
  position: relative;
`;

const BannerBlock = styled.div`
  & > img {
    width: 70vh;
    height: calc(70vh / 1.8);
    object-fit: cover;
    display: block;
    margin: auto;
    border-radius: 0px 0px 10px 10px;
  }

  @media (max-width: 768px) {
    & > img {
      width: 100%;
      height: 55.55555555vw;
      object-fit: cover;
      border-radius: 0px;
    }
  }
`;

const BannerContainer = styled.div`
  position: relative;
  width: 70vh;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    height: 55.55555555vw;
    object-fit: cover;
    border-radius: 0px;
  }
`;
