import { EditRightsProvider } from '@/infrastructure/hooks/useEditRights';
import { ConfirmationDialogProvider } from './confirmationDialog';
import { UnsavedStatusContextProvider } from './unsavedStatus';
import { ProfileDesignProvider } from '@/infrastructure/hooks/useProfileDesign';
import { ProfileDesignForUnitsProvider } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { InternalDesignProvider } from '@/config/theme/useMuiTheme';
import { HeaderActionsProvider } from '@/components/Header/useHeaderActions';

const providers = [
  UnsavedStatusContextProvider,
  ConfirmationDialogProvider,
  EditRightsProvider,
  ProfileDesignProvider,
  ProfileDesignForUnitsProvider,
  InternalDesignProvider,
  HeaderActionsProvider,
];

export const ContextProvider: React.FC = ({ children }) => {
  return providers.reduce((prev, Provider) => {
    return <Provider>{prev}</Provider>;
  }, children as JSX.Element);
};
