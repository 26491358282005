import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { Typography } from '@mui/material';
import { Box, SxProps, ThemeProvider } from '@mui/system';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  label?: string | null;
  embedUrl: string;
  sx?: SxProps;
  labelSx?: SxProps;
}

const isVideoFileUrl = (url: string) =>
  url.split('?')[0]?.split('#')[0]?.toLowerCase().endsWith('.mp4') ||
  url.split('?')[0]?.split('#')[0]?.toLowerCase().endsWith('.mov') ||
  url.split('?')[0]?.split('#')[0]?.toLowerCase().endsWith('.webm');
const isYoutubeUrl = (url: string) =>
  url.startsWith('https://youtube.com/watch?v=') ||
  url.startsWith('https://www.youtube.com/watch?v=') ||
  url.startsWith('https://www.youtube.com/embed/') ||
  url.startsWith('https://youtube.com/embed/') ||
  url.startsWith('https://youtu.be/') ||
  url.startsWith('https://www.youtu.be/');
const isVimeoUrl = (url: string) => url.includes('vimeo.com');

export const isEmbeddableUrl = (url: string) =>
  isVideoFileUrl(url) || isYoutubeUrl(url) || isVimeoUrl(url);

const youtubeUrlToEmbedUrl = (url: string) => {
  if (url.includes('youtube.com/watch?v='))
    return 'https://www.youtube.com/embed/' + url.split('?v=').at(-1).split('&').at(0);
  if (url.includes('youtu.be/'))
    return 'https://www.youtube.com/embed/' + url.split('youtu.be/').at(-1).split('?').at(0);
  return url;
};
const vimeoUrlToEmbedUrl = (url: string) => {
  const query = url.split('?')[1] || '';
  const matchEvent = /vimeo\.com\/event\/(\d+)\/(?:embed\/)?([a-zA-Z0-9]+)$/i.exec(url); // if event (livestream) url: from c -> https://vimeo.com/event/4193249/embed/bb633b6f40
  if (matchEvent !== null) {
    const videoId = matchEvent[1];
    const hash = matchEvent[2];
    return `https://vimeo.com/event/${videoId}/embed/${hash}?${query}`;
  }
  const matchVideo = /vimeo\.com\/.*?(\d+)/.exec(url); // extract video id
  if (matchVideo !== null) {
    const videoId = matchVideo[1];
    return `https://player.vimeo.com/video/${videoId}?${query}`;
  } else {
    return url;
  }
};

export default function LtVideo({ label, labelSx, embedUrl, sx }: Props) {
  const [wrapperBox, setWrapperBoxRef] = useState<HTMLDivElement>();
  const wrapperBoxWidth = wrapperBox?.getBoundingClientRect().width;

  const videoRef = useRef<HTMLVideoElement>();
  useEffect(() => {
    // to make sure thumbnails are loaded and displayed (mainly safari)
    if (videoRef.current) videoRef.current.load();
  }, []);

  const videoElement = useMemo(() => {
    const videoStyles: CSSProperties = {
      borderRadius: '8px',
      overflow: 'hidden',
    };
    if (isYoutubeUrl(embedUrl)) {
      const src = youtubeUrlToEmbedUrl(embedUrl);
      return (
        <iframe
          title={label}
          src={src}
          allowFullScreen
          style={{
            ...videoStyles,
            width: '100%',
            height: wrapperBox ? (wrapperBox.getBoundingClientRect().width * 9) / 16 : undefined,
          }}
        />
      );
    }
    if (isVimeoUrl(embedUrl)) {
      const src = vimeoUrlToEmbedUrl(embedUrl);
      return (
        <iframe
          title={label}
          src={src}
          // frameborder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          // allowfullscreen
          style={{
            ...videoStyles,
            width: '100%',
            height: wrapperBox ? (wrapperBoxWidth * 9) / 16 : undefined,
          }}
        />
      );
    }
    return (
      <video
        controls
        playsInline
        preload='metadata'
        ref={videoRef}
        style={{ ...videoStyles, maxWidth: '100%' }}
      >
        <source src={embedUrl} />
        Your browser does not support the video tag.
      </video>
    );
  }, [embedUrl, label, wrapperBox, wrapperBoxWidth]);

  const { theme } = useMuiTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, ...sx }}
        ref={setWrapperBoxRef}
      >
        {videoElement}
        {label && (
          <Typography variant='h4' textAlign='center' sx={labelSx}>
            {label}
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
}
