import { AccountOrigin, AccountStatus } from '../global';
import { ApiFile } from './file.type';
import { Link } from './link.type';
import { Unit } from './unit.type';
import { Theme } from './theme.type';
import { LeadSortOption } from './lead.type';

export interface Account {
  id: string;
  username?: string;
  firstName?: string | null;
  lastName?: string | null;
  theme?: Theme | null;
  email?: string | null;
  workEmail?: string | null;
  workEmail2?: string | null;
  workUrl?: string | null;
  bioText?: string | null;
  workPhone?: string | null;
  mobilePhone?: string | null;
  homePhone?: string | null;
  hotlinePhone?: string | null;
  assistantPhone?: string | null;
  assistantEmail?: string | null;
  assistantName?: string | null;
  assistantGeneric?: string | null;
  workFax?: string | null;
  files?: Array<ApiFile>;
  links?: Array<Link>;
  profileImageUrl?: string | null;
  position?: string | null;
  academicDegree?: string | null;
  academicDegree2?: string | null;
  role?: string | null;
  units?: Array<Unit> | null; ///Team and units are the same
  namePrefix?: string | null;
  division?: string | null;
  company?: string | null;
  logoHeaderUrl: string | null;
  address1_addressee?: string | null;
  address1_addressLine1?: string | null;
  address1_addressLine2?: string | null;
  address1_postCode?: string | null;
  address1_city?: string | null;
  address1_country?: string | null;
  address1_label?: string | null;
  address2_addressee?: string | null;
  address2_addressLine1?: string | null;
  address2_addressLine2?: string | null;
  address2_postCode?: string | null;
  address2_city?: string | null;
  address2_country?: string | null;
  address2_label?: string | null;
  defaultLinkId: number | null;
  preferredLang?: string | null;
  bannerImageUrl?: string | null;
  addressTemplate1Id?: number | null;
  isThemeAdmin?: Boolean;
  isUnitAdmin?: Boolean;
  addressTemplate2Id?: number | null;
  authRoles?: Array<AuthRole> | null;
  origin?: AccountOrigin | null;
  status: AccountStatus;
  actionPreferences?: ActionPreferences;
  accountMeta?: AccountMeta | null;
  attachedThemeId?: number | null;
  createdOn?: Date;
  accountToUnits?: AccountToUnits[] | null;
  authEmail?: string | null;
  costCenter?: string | null;
}

export interface AccountToUnits {
  id?: number;
  accountId: string;
  account?: Account;
  unitsId: number;
  unit?: Unit;
  isMain: boolean;
}

export interface AuthRole {
  id?: string;
  name: string;
  niceName: string;
}

export type ActionPreferences = {
  dismissedFreeTrialBannerAt?: number;
  linkCode_dontShowConfirmationDialog?: boolean;
  linkCode_Filters?: {
    selectedColumn: keyof Account;
    orderBy: keyof Account;
    sort?: 'asc' | 'desc';
    withoutNfc?: boolean;
  };
  myLeads_SortOptions?: LeadSortOption;
  mdLeads_options?: LeadSortOption;
  myCards_reconnectCardAlertVisible?: boolean;
};
export const ACTION_PREFERENCE_KEYS: Array<keyof ActionPreferences> = [
  'dismissedFreeTrialBannerAt',
];

export interface AccountMeta {
  onboardingCategory: Array<{ key: string; lang?: string; value?: string }>;
  onboardingReferrer: Array<{ key: string; lang?: string; value?: string }>;
  onboardingCompanySize: Array<{ key: string }>;
  potentialUsers: Array<{ key: string }>;
  usageGoal: Array<{ key: string; lang?: string; value?: string }>;
}

type Serializable<T> = {
  [K in keyof T as T[K] extends string | boolean | number ? K : never]: T[K];
};
type ExcludeForeignKeys<T> = {
  [K in keyof T as K extends `${string}Id` ? never : K]: T[K];
};
type SerializableAndExcludeForeignKeys<T> = Serializable<T> & ExcludeForeignKeys<T>;

type AccountExcludedDefaults = Pick<
  Account,
  | 'id'
  | 'username'
  | 'files'
  | 'links'
  | 'theme'
  | 'units'
  | 'authRoles'
  | 'origin'
  | 'status'
  | 'actionPreferences'
  | 'accountMeta'
  | 'createdOn'
>;

type AccountIncludedDefaults = Pick<Account, 'addressTemplate1Id' | 'addressTemplate2Id'>;

// export type AccountDefaultsConfig = Omit<ExcludeForeignKeys<Account> & Serializable<Account>, keyof AccountExcludedDefaults>;
export type AccountDefaultsConfig = Partial<
  Omit<
    {
      [K in keyof SerializableAndExcludeForeignKeys<Account>]: Account[K];
    },
    keyof AccountExcludedDefaults
  > &
    AccountIncludedDefaults
>;

// account onboarding types
export interface OnboardingDataProps1 {
  firstName: string;
  lastName: string;
}

export interface OnboardingDataProps2 extends OnboardingDataProps1 {
  position: string;
  role: string;
  title: string;
  company: string;
  workUrl: string;
  mobilePhone: string;
  workPhone: string;
  email: string;
  workEmail: string;
  division: string;
  address1_addressee: string;
  address1_addressLine1: string;
  address1_addressLine2: string;
  address1_postCode: string;
  address1_city: string;
  address1_country: string;
}
