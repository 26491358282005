import React, { useCallback, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routePaths } from '../../../../infrastructure/constants';
import { ReactComponent as LemontapsLogo } from '@/views/images/lemontaps-logo.svg';
import useWindowSize from '../../../../infrastructure/hooks/useWindowSize';

// TODO: profile page optimizations

interface Props {
  onHeightChange?(newHeight: number): void;
  username: string;
}

const TryForFreeBanner = (props: Props) => {
  const { t } = useTranslation();
  const signupWithParams = `${routePaths.REGISTRATION.SIGNUP}/?lt_ref=${props.username}&lt_medium=profilerefer`;

  const containerRef = useRef<HTMLDivElement>(null);

  const { onHeightChange } = props;

  useLayoutEffect(() => {
    if (containerRef.current) {
      onHeightChange?.(containerRef.current.clientHeight);
    }
  }, [containerRef, onHeightChange]);
  useWindowSize(
    useCallback(() => onHeightChange?.(containerRef?.current?.clientHeight), [onHeightChange]),
  );

  return (
    <BannerContainer ref={containerRef}>
      <LemontapsLogo />
      <Link target='_blank' to={signupWithParams}>
        {t('tryForFree')} &rarr;
      </Link>
    </BannerContainer>
  );
};

export default TryForFreeBanner;

const BannerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 2rem;
  margin: auto;
  width: 70vh;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  svg {
    width: 7.5vw;
  }

  a {
    color: #55595e;
    background: #ffffff;
    font-size: 0.85vw;
    font-weight: 500;
    padding: 1rem 2rem;
    border: 1px black solid;
    border-radius: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;

    svg {
      width: 22vw;
    }

    a {
      font-size: 2.5vw;
    }
  }
`;
