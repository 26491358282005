import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Tooltip } from '@mui/material';

type Props = {
  className?: string;
  label: string;
  options: Array<{
    text: string;
    onClick: () => void;
    tooltipText?: string;
    disabled?: boolean;
  }>;
  sx?: React.ComponentProps<typeof Button>['sx'];
  MenuProps?: React.ComponentProps<typeof MenuItem>;
} & React.ComponentProps<typeof Button>;

export default function LtMultiActionButton({
  className,
  label,
  options,
  sx,
  MenuProps,
  ...buttonProps
}: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box className={className} ref={anchorRef} sx={sx}>
        <Button endIcon={<ArrowDropDownIcon />} onClick={handleToggle} {...buttonProps}>
          {label}
        </Button>
      </Box>
      <Popper
        id='1'
        sx={{
          zIndex: 10,
        }}
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom-end'
        transition
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map(option => (
                    <MenuItem
                      key={option.text}
                      onClick={() => {
                        option.onClick();
                        setOpen(false);
                      }}
                      disabled={option.disabled}
                      {...MenuProps}
                    >
                      <Box
                        width='100%'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        gap={3}
                      >
                        {option.text}
                        {option.tooltipText && (
                          <Tooltip title={option.tooltipText} placement='right'>
                            <HelpOutlineIcon />
                          </Tooltip>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
