import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BsArrowRightShort } from 'react-icons/bs';

interface HCProps {
  titleIcon?: React.ReactElement;
  title: string;
  subtitle: string;
  buttonText: string;
  onButtonClick: () => void;
  onHideClick: () => void;
  hidden?: boolean;
}

const HideableCallout: React.FC<HCProps> = props => {
  const hidden = useMemo(() => !!props.hidden, [props.hidden]);

  if (!hidden) {
    return (
      <HCContainer>
        <div className='crossmark' onClick={props.onHideClick}></div>
        <h3>
          {props.titleIcon && <span className='pre-icon'>{props.titleIcon}</span>}
          {props.title}
        </h3>
        <p className='subtitle'>{props.subtitle}</p>
        <button className='ctaButton' onClick={props.onButtonClick}>
          {props.buttonText}
          <BsArrowRightShort className='arrow-right' />
        </button>
      </HCContainer>
    );
  } else {
    return null;
  }
};

const HCContainer = styled.div`
  position: relative;
  background-color: #eff3fd;
  border-radius: 10px;
  padding: 4rem 7.5%;
  width: 40rem;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossmark {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.25rem;
    height: 2.25rem;

    cursor: pointer;

    &::before,
    &::after {
      content: '';
      display: block;
      background-color: #181a5a;
      width: 15%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
    }
    &::before {
      transform: translateX(-50%) rotate(45deg);
    }
    &::after {
      transform: translateX(-50%) rotate(-45deg);
    }
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #181a5a;
    margin-bottom: 1rem;

    .pre-icon {
      display: grid;
      place-items: center;
      margin-right: 0.7rem;
      font-size: 1.25em;

      svg {
        stroke-width: 0.5px;
      }
    }
  }

  .subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    color: #8a8a8a;
  }

  .ctaButton {
    outline: none;
    border: none;
    background: #02e0b1;
    border-radius: 10px;
    padding: 1rem 2rem;
    color: white;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.4px;

    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-right {
      font-size: 1.7em;
    }
  }
`;

export default HideableCallout;
