import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/common';
import { routePaths } from '../../../infrastructure/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import { getResetPasswordUrlApi } from '../../../infrastructure/apis/reset-password';

const ChangePassword = (): JSX.Element => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    getResetPasswordUrlApi(getAccessTokenSilently)
      .then(res => setRedirectUrl(res.data.data.resetUrl))
      .catch(err => setError(err));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }, [redirectUrl]);

  if (!isAuthenticated) {
    return <Redirect exact to={routePaths.LOGIN} />;
  } else if (error) {
    return <span>Error.</span>;
  } else if (!redirectUrl) {
    return <Loader />;
  } else {
    return <Loader />;
  }
};
export default ChangePassword;
