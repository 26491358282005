import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { THEME_BOX_STYLES } from '../../../shared/constants';
import { CSS_VARS } from '@/infrastructure/constants';
import { ThemeBoxStyle } from '@/shared/types/global';

interface Props {
  icon: React.ReactElement;
  boxStyle: ThemeBoxStyle;
  onClick: () => void;
  text: string;
  customClass?: string;
  backgroundCss?: string;
  style?: CSSProperties;
}

const LtIconButton = (props: Props): JSX.Element => {
  return (
    <>
      <Wrapper
        className={props.customClass}
        boxStyle={props.boxStyle}
        backgroundCss={props.backgroundCss}
        onClick={props.onClick}
        style={props.style || undefined}
      >
        <AddNewSection>
          <IconWrapper>{props.icon}</IconWrapper>
          <WhiteText>{props.text}</WhiteText>
        </AddNewSection>
      </Wrapper>
    </>
  );
};

export default LtIconButton;

const Wrapper = styled.button`
  cursor: pointer;
  height: fit-content;
  background: ${props => props.backgroundCss ?? CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
  border: 1px solid #ffffff;
  box-sizing: border-box;
  padding: 8px;
  min-width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  display: flex;

  ${(props: { boxStyle: string }) =>
    props.boxStyle === THEME_BOX_STYLES.ANGULAR
      ? css`
          border-radius: 0;
        `
      : css`
          border-radius: 10px;
        `}

  @media (max-width: 320px) {
    min-width: 120px;
  }
  &:focus-visible {
    outline: 1px solid ${props => props.backgroundCss ?? CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR};
    outline-offset: 2px;
  }
`;

const AddNewSection = styled.div`
  color: ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  padding-right: 10px;
  display: grid;
  place-items: center;
`;

const WhiteText = styled.div``;
