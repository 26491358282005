import { getPluginElement, useBlock, useEditorContext } from 'easy-email-editor';

const hideElement = (element?: Element | HTMLElement) => {
  if (!element) return;
  (element as HTMLElement).style.display = 'none';
};

const allowedToolsClassNames = [
  'icon-back-parent',
  'icon-copy',
  'icon-delete',
  'icon-merge-tags',
  'icon-link',
  'icon-unlink',
];

export const useHideRichTextEditorToolbar = (hasFullAccess: boolean) => {
  const { initialized } = useEditorContext();
  const { focusBlock } = useBlock();
  const root = initialized && getPluginElement();

  if (hasFullAccess || !root || focusBlock?.type !== 'advanced_text') return;

  requestAnimationFrame(() => {
    const toolsContainer = root.querySelector('#Tools')?.firstChild as HTMLElement;
    if (!toolsContainer) return;
    const dividers = toolsContainer.querySelectorAll('.easy-email-extensions-divider');
    dividers.forEach(hideElement);
    const buttons = toolsContainer.querySelectorAll('.easy-email-extensions-emailToolItem');
    buttons.forEach(button => {
      const elem = button.firstChild as HTMLElement | undefined;
      if (!elem) return;
      if (!allowedToolsClassNames.some(x => elem.classList.contains(x))) {
        hideElement(button);
      }
    });
  });
};
