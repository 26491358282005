import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch } from '../../../../application/hooks';
import { getEmployees } from '../../../../application/actions/md/profiles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { AVAILABLE_PERMISSION_LEVELS, EMPLOYEE_STATUS } from '../../../../infrastructure/constants';
import { getUnits } from '../../../../infrastructure/apis/md/units';
import { Unit } from '../../../../shared/types/api/unit.type';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import { FEATURE } from '../../../../shared/constants';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { searchColumns } from './table/utils/constants';
import { Select } from '@mui/material';

const orderByMap = {
  language: 'preferredLang',
};

const getOrderByKey = orderBy => {
  if (orderByMap[orderBy]) {
    return orderByMap[orderBy];
  }
  return orderBy;
};

interface Props {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  selectedColumn: string;
  setSelectedColumn: Dispatch<SetStateAction<string>>;
  onSearchStart: Function;
  parentSearchCondition: string;
  setParentSearchCondition: Dispatch<SetStateAction<string>>;
  parentSelectedColumn?: string;
  onTextChange?: () => void;
  orderBy?: string | null;
  sort?: 'asc' | 'desc' | null;
}
type SelectOption = { name: string; niceName: string; tKey: string };

const Search = forwardRef(
  (
    props: Props,
    ref: React.MutableRefObject<{ refreshEmployees: (col: string, term: string) => void }>,
  ) => {
    const { isFeatureAllowed } = useTierInfo();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const {
      searchTerm,
      setSearchTerm,
      selectedColumn,
      setSelectedColumn,
      onSearchStart,
      orderBy,
      sort,
    } = props;
    const [initialRender, setInitialRender] = useState(true);
    const optionsColumns = ['unit', 'permissionLevel', 'status'];
    const [isOptionsColumn, setOptionsColumn] = useState(false);
    const [inputOptions, setInputOptions] = useState<Array<SelectOption>>([]);

    const [availableUnits, setAvailableUnits] = useState<Unit[]>([]);
    useEffect(() => {
      if (selectedColumn === 'unit') {
        getUnits(getAccessTokenSilently).then(res => {
          if (res.data.isSuccess) {
            setAvailableUnits(res.data.data.units);
            setInputOptions(
              res.data.data.units.map(x => {
                return {
                  name: x.niceName,
                  niceName: x.niceName,
                  tKey: x.niceName,
                };
              }),
            );
          }
        });
      }
    }, [getAccessTokenSilently, selectedColumn]);

    const textInputRef = useRef<HTMLInputElement>();
    const selectInputRef = useRef<string>('');

    const columns = useMemo(
      () => searchColumns(isFeatureAllowed(FEATURE.UNITS)),
      [isFeatureAllowed],
    );

    useEffect(() => {
      setSearchTerm(props.parentSearchCondition);
    }, [props.parentSearchCondition, setSearchTerm]);

    useImperativeHandle(ref, () => ({
      refreshEmployees() {
        dispatch(
          getEmployees(
            getAccessTokenSilently,
            isOptionsColumn ? selectInputRef.current : textInputRef.current.value,
            selectedColumn,
            t,
            { withToast: true },
            undefined,
            { orderBy, sort },
          ),
        );
      },
    }));

    useEffect(() => {
      if (initialRender) {
        setInitialRender(false);
        return;
      }
      const timer = setTimeout(() => {
        dispatch(
          getEmployees(
            getAccessTokenSilently,
            searchTerm,
            selectedColumn,
            t,
            { withToast: true },
            undefined,
            { orderBy: getOrderByKey(orderBy), sort },
          ),
        );
        onSearchStart();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, [
      dispatch,
      getAccessTokenSilently,
      initialRender,
      onSearchStart,
      searchTerm,
      selectedColumn,
      orderBy,
      sort,
      t,
    ]);

    useEffect(() => {
      const result: Array<{ name: string; value: string }> = columns.filter(
        item => item.value === props.parentSelectedColumn,
      );
      if (result.length > 0) {
        setSelectedColumn(result[0].value);
      }
    }, [columns, props.parentSelectedColumn, setSelectedColumn]);

    const setSearchValue = (value: string) => {
      dispatch(
        getEmployees(getAccessTokenSilently, encodeURIComponent(value || ''), selectedColumn, t, {
          withToast: true,
        }),
      );
      onSearchStart();
    };

    const filterByEmpty = () => {
      const tooltip = t('emptySearchMD.tooltip');
      const tag = t('emptySearchMD.tag');

      const hideFilter =
        isOptionsColumn ||
        ['firstName', 'lastName', 'email', 'links', 'files'].includes(selectedColumn);

      const onClick = () => (searchTerm === tag ? setSearchTerm('') : setSearchTerm(tag));

      return (
        !hideFilter && (
          <Tooltip title={tooltip} placement='top' arrow>
            <IconButton onClick={onClick} sx={{ position: 'absolute', right: '1%', zIndex: 1 }}>
              <LightbulbOutlinedIcon />
            </IconButton>
          </Tooltip>
        )
      );
    };

    const optionsDisabled = !inputOptions.length;

    return (
      <FormControl
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '60%',
          gap: 1,
        }}
      >
        {isOptionsColumn ? (
          <>
            <InputLabel id='search-select-label' disabled={optionsDisabled}>
              {optionsDisabled ? t('noSearchOptions') : t('selectFromList')}
            </InputLabel>
            <Select
              labelId='search-select-label'
              label={optionsDisabled ? t('noSearchOptions') : t('selectFromList')}
              onChange={(e: SelectChangeEvent) => {
                setSearchValue(e.target.value);
                selectInputRef.current = e.target.value || '';
              }}
              sx={{ minWidth: '60%' }}
              disabled={optionsDisabled}
              size='small'
            >
              {optionsDisabled
                ? null
                : inputOptions.map(({ name, niceName, tKey }) => {
                    return (
                      <MenuItem key={name} value={name} sx={{ fontSize: '1.4rem' }}>
                        {t(Boolean(tKey) ? tKey : niceName)}
                      </MenuItem>
                    );
                  })}
            </Select>
          </>
        ) : (
          <Box position={'relative'} display={'flex'} width={'60%'} alignItems={'center'}>
            {filterByEmpty()}
            <TextField
              ref={textInputRef}
              name='search'
              type='text'
              placeholder={t('searchMD')}
              size='small'
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
                props.onTextChange && props.onTextChange();
              }}
              sx={{
                width: '100%',
                position: 'relative',
              }}
              InputProps={{ style: { fontSize: '1.4rem' } }}
              InputLabelProps={{ style: { fontSize: '1.4rem' } }}
            />
          </Box>
        )}
        <Box>
          <Select
            name='columns'
            id='columns'
            value={selectedColumn}
            size='small'
            color='primary'
            sx={{ fontSize: '1.4rem' }}
            onChange={e => {
              setSelectedColumn(e.target.value);
              if (e.target.value === 'permissionLevel') {
                setSearchTerm('');
                setInputOptions(
                  AVAILABLE_PERMISSION_LEVELS(isFeatureAllowed(FEATURE.UNITS)).map(x => {
                    return {
                      name: x.name,
                      niceName: x.niceName,
                      tKey: x.translateKey,
                    };
                  }),
                );
              } else if (e.target.value === 'status') {
                setSearchTerm('');
                setInputOptions(
                  EMPLOYEE_STATUS.map(x => {
                    return {
                      name: x.name,
                      niceName: x.niceName,
                      tKey: x.translateKey,
                    };
                  }),
                );
              } else if (e.target.value === 'unit') {
                setSearchTerm('');
                setInputOptions(
                  availableUnits.map(x => {
                    return {
                      name: x.niceName,
                      niceName: x.niceName,
                      tKey: x.niceName,
                    };
                  }),
                );
              }
              setOptionsColumn(optionsColumns.filter(x => x === e.target.value).length > 0);
            }}
          >
            {columns.map(({ value, name }) => {
              return (
                <MenuItem key={value} value={value} sx={{ fontSize: '1.4rem' }}>
                  {t(name)}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </FormControl>
    );
  },
);

export default Search;
