import { useAppTranslation } from '../../../../../../infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '../../../../../../application/hooks';

import { SubsectionHeader } from '../../../common';

import AddNewLinkPopup from '../../../../../components/popups/add-new-link-popup';
import CreateEditLinkPopup from '../../../../../components/popups/create-edit-link-popup';
import EditLinkTypePopup from '../../../../../components/popups/edit-link-type-popup';
import DeletePopup from '../../../../../components/popups/delete-item-popup';

import useBulk, { CustomLinkType } from './hooks/useBulk';
import usePopup from './hooks/usePopup';
import LinksUi from './components/links';
import { Employee } from '../../../../../../shared/types/api/employee.type';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  THEME_LINKS_FILES_POSITIONS,
  THEME_LINK_EDIT_MODES,
} from '../../../../../../shared/constants';
import { BulkLinkProps } from '../inputs-form';
import ExtendPopup from './components/extend-popup';
import { RowDiv, Spacer } from '../../../customization/common';
import { getThemeLinkTypes } from '../../../../../../application/actions/edit-profile';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Account, Link, Theme } from '../../../../../../shared/types/api';
import { withSilentAccessToken } from '../../../../../../infrastructure/helper';
import axios from 'axios';
import config from '../../../../../../config/config';
import { PERMISSIONS } from '../../../../../../infrastructure/constants';
import LinksCompanyDetails from './components/linksCompanyDetails';
import { saveCompanyDetailsLinks } from '../../../../../../application/actions/md/profiles/links';
import toast from 'react-hot-toast';
import { Link as ApiLink } from '../../../../../../shared/types/api';
import { ThemeLinksFilesPosition } from '../../../../../../shared/types/global';
import { Checkbox } from '../../../../../components/generic';
import TooltipInfo from '../../../../../components/generic/TooltipInfo';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';

interface Props {
  unitId?: number;
  employee?: Employee;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
  bulkLinkProps?: BulkLinkProps;
  mode: 'single' | 'bulk' | 'themeLinks';
  setThemeLinks?(themeLinks: ApiLink[]): void;
  themeLinksPosition: ThemeLinksFilesPosition;
  setThemeLinksPosition?(position: ThemeLinksFilesPosition): void;
  loadingCompleted?: Function;
  genericWebsiteColor: string;
}
const Links = (props: Props) => {
  const { loadingCompleted, unitId } = props;
  const { t } = useAppTranslation();
  const { employee, bulkLinkProps, userLinksProps, mode } = props;
  const isThemeLinks = mode === 'themeLinks';
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const themeLinkTypes = useAppSelector(state => state.md.themeLinkTypes);
  const account = useAppSelector<Account>(state => state.account);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [counterToForceRender, setCounterToForceRender] = useState(0);
  const usePopupProps = usePopup();
  const {
    showAddNewLink,
    showLinkTypePopup,
    showCreatePopup,
    showDeletePopup,
    showExtendPopup,
    deleteMode,
    selectedLinkRef,
    showAddNewLinkPopup,
    showCreateEditLinkPopup,
    onCreateLinkBackClick,
    onLinkTypeClick,
    onEditLinkTypeBackClick,
    onDeleteLinkClick,
    onClosePopups,
    checkForResponse,
  } = usePopupProps;

  const passPopupPropsToBulkHook = { selectedLinkRef, showLinkTypePopup, onClosePopups };
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const useBulkProps = useBulk({
    ...passPopupPropsToBulkHook,
    bulkLinkProps,
    setCounterToForceRender,
  });
  const { handleExtendLink } = useBulkProps;

  const links = useMemo(
    () => userLinksProps?.userLinks?.sort((a, b) => a.order - b.order) || [],
    [userLinksProps?.userLinks],
  );

  const linksToLoop = bulkLinkProps?.bulkLinks || links;
  const linkProps = { employee, links, userLinksProps, account, themeLinkTypes, t };
  useEffect(() => {
    if (isThemeLinks) {
      dispatch(getThemeLinkTypes(getAccessTokenSilently));
    }
  }, [dispatch, getAccessTokenSilently, isThemeLinks]);

  const maxOrder = useMemo(
    () => links?.reduce((prev, cur) => (cur.order > prev ? cur.order : prev), -1) || 0,
    [links],
  );

  const [themeLinks, setThemeLinks] = useState<Link[]>([]);
  useEffect(() => {
    props.setThemeLinks && props.setThemeLinks(themeLinks);
  }, [props, themeLinks]);
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(`${config.API_BASE_URL}theme/global-links`, {
          params: { unitId, unitLevelOnly: isThemeLinks && !!unitId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
    )
      .then(res => {
        setThemeLinks(res.data.data);
        loadingCompleted?.();
      })
      .catch(err => console.log(err));
  }, [getAccessTokenSilently, loadingCompleted, unitId, isThemeLinks]);

  const saveLinks = async links => {
    if (isThemeLinks) {
      props.setThemeLinks(links);
      setIsUnsaved(true);
    } else {
      const toastId = 'saveLinksDatabase';
      toast.loading('Saving to database', { id: toastId });
      setThemeLinks(links);
      await dispatch(
        saveCompanyDetailsLinks(
          links.map(item => {
            // @ts-ignore:next-line
            if (item.newItem) {
              delete item.id;
              // @ts-ignore:next-line
              delete item.newItem;
            }
            return item;
          }),
          getAccessTokenSilently,
          () => {
            toast.success('Success', { id: toastId });
          },
          () => {
            toast.error('error', { id: toastId });
          },
        ),
      );
    }
  };
  const onEditLink = (linkItem: Link) => {
    const newLinks = themeLinks.map(link => {
      const newLink = { ...link };
      if (newLink.id === linkItem.id) {
        newLink.name = linkItem.name;
        newLink.link = linkItem.link;
      }
      return newLink;
    });
    setThemeLinks(newLinks);
    saveLinks(newLinks);
  };
  const onDeleteLink = (linkItem: Link) => {
    const newLinks = themeLinks.filter(link =>
      link?.id === 0
        ? link.name !== linkItem.name
        : link.link !== linkItem.link && link.name !== linkItem.name,
    );
    setThemeLinks(newLinks);
    saveLinks(newLinks);
  };
  const onSaveCompanydataSettings = async (linkItem: Link) => {
    const newLinks = [
      ...themeLinks,
      { ...linkItem, themeId: account.theme.id, order: themeLinks.length * 10 + 10, newItem: true },
    ];
    setThemeLinks(newLinks);
    saveLinks(newLinks);
  };

  const { editRights } = useEditRights();

  return (
    <>
      <div style={{ display: 'none' }}>{counterToForceRender}</div>

      {!isThemeLinks && checkForResponse()}

      {showAddNewLink && (
        <AddNewLinkPopup
          newOrder={maxOrder + 10}
          onCloseClick={onClosePopups}
          onItemClick={item => showCreateEditLinkPopup(item)}
          onLinkTypeClick={linkType => onLinkTypeClick(linkType)}
          loading={false}
          linkTypes={isThemeLinks ? theme.themeLinkTypes : themeLinkTypes}
          firstWebsite={editRights.linkEditMode === THEME_LINK_EDIT_MODES.OPTIONS}
          isCompanydataSettings={isThemeLinks}
          genericWebsiteColor={props.genericWebsiteColor}
        />
      )}

      {showCreatePopup && (
        <CreateEditLinkPopup
          employeeId={employee?.id}
          onCloseClick={onClosePopups}
          onBackClick={() => onCreateLinkBackClick()}
          onDeleteClick={() => onDeleteLinkClick()}
          item={selectedLinkRef.current}
          mode={selectedLinkRef.current.mode || selectedLinkRef.current.linkType.mode}
          {...useBulkProps}
          isCompanydataSettings={isThemeLinks}
          // I SUSPECT THIS WILL FAIL
          onSaveCompanydataSettings={(linkItem: Link) =>
            onSaveCompanydataSettings({
              id: Math.floor(Math.random() * 1000),
              name: linkItem.name,
              link: linkItem.link,
              linkType: linkItem.linkType,
              linkTypeId: linkItem.linkTypeId,
            })
          }
          genericWebsiteColor={props.genericWebsiteColor}
          {...userLinksProps}
        />
      )}

      {showLinkTypePopup && (
        <EditLinkTypePopup
          employeeId={employee?.id}
          onCloseClick={onClosePopups}
          onBackClick={() => onEditLinkTypeBackClick()}
          linkType={showLinkTypePopup}
          formerIds={linksToLoop.filter(l => !!l.themeLinkOption).map(l => l.themeLinkOption.id)}
          formerCustomLink={linksToLoop.find(
            l => l.isCustomLink && l.linkType && l.linkType.id === showLinkTypePopup.id,
          )}
          {...useBulkProps}
          {...userLinksProps}
        />
      )}

      {showDeletePopup && (
        <DeletePopup
          employeeId={employee.id}
          onCloseClick={onClosePopups}
          item={selectedLinkRef.current}
          mode={deleteMode}
          {...userLinksProps}
        />
      )}
      {showExtendPopup && (
        <ExtendPopup
          onCloseClick={onClosePopups}
          onSaveClick={handleExtendLink}
          {...bulkLinkProps}
        />
      )}
      {!isThemeLinks && (
        <SubsectionHeader>
          Links
          <TooltipInfo
            text={t('md.profileDesign.linksTooltip')}
            placement='right'
            icon={{ size: 12 }}
            arrow
          />
        </SubsectionHeader>
      )}
      {isThemeLinks ? (
        <>
          {props.themeLinksPosition && props.setThemeLinksPosition && (
            <>
              <RowDiv>{t('md.profileDesign.themeLinksPosition.question')}</RowDiv>
              <div>
                {Object.values(THEME_LINKS_FILES_POSITIONS).map(pos => (
                  <div>
                    <Checkbox
                      key={pos}
                      name={pos}
                      title={t('md.profileDesign.themeLinksPosition.' + pos)}
                      forcedCheckState={props.themeLinksPosition === pos}
                      onChange={() => props.setThemeLinksPosition(pos)}
                    />
                  </div>
                ))}
              </div>
              <Spacer size={32} />
            </>
          )}
          <RowDiv>{t('md.profileDesign.themeLinks.question')}:</RowDiv>
          <Spacer size={12} />
          <LinksCompanyDetails
            links={themeLinks}
            onSaveCompanydataSettings={onSaveCompanydataSettings}
            onDeleteLink={onDeleteLink}
            onEditLink={onEditLink}
            onDragEnd={saveLinks}
            showAddNewLinkPopup={showAddNewLinkPopup}
          />
        </>
      ) : (
        <LinksUi
          unitId={unitId}
          themeLinksPosition={props.themeLinksPosition}
          maxOrder={maxOrder}
          bulkProps={useBulkProps}
          popupProps={usePopupProps}
          showAddNewLinkPopup={showAddNewLinkPopup}
          onDeleteLinkClick={link => {
            selectedLinkRef.current = link;
            onDeleteLinkClick();
          }}
          {...linkProps}
        />
      )}
    </>
  );
};

export default Links;
