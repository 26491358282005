import { useState, useEffect } from 'react';
import LTIconButton from '../../../components/common/lt-icon-button';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { HeaderTextContainer, PageSubheader } from '../common';
import { WhitespaceContainer, PageHeader } from '../common';
import { FEATURE, THEME_BOX_STYLES } from '../../../../shared/constants';
import { toast } from 'react-hot-toast';
import Searchbar from './searchbar';
import { Button, Loader, Popup } from '../../../components/common';
import { TextField } from '../../../components/generic';
import UnitsTable from './table';
import { unitsTableColums } from '../contacts/tablecolumns.constant';
import { withSilentAccessToken } from '../../../../infrastructure/helper';
import axios from 'axios';
import { PERMISSIONS } from '../../../../infrastructure/constants';
import config from '../../../../config/config';
import { deleteUnits, getUnits } from '../../../../infrastructure/apis/md/units';
import DeletePopup from '../../../components/common/deletePopup';
import { Unit } from '../../../../shared/types/api/unit.type';
import { ReactComponent as AddIcon } from '../../../images/add-icon.svg';
import useDeferredLoader from '../../../../infrastructure/hooks/useDeferredLoader';
import withNav from '../../../../infrastructure/hoc/withNav';
import { Spacer } from '../customization/common';
import useDesktopView from '../../../../infrastructure/hooks/useDesktopView';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import FeatureTeaser from '../teaser/featureTeaser';
import teaserImage from '../../../images/teaser/units.png';

const MdUnits = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  if (loading) {
    return <Loader />;
  }

  if (isFeatureAllowed(FEATURE.UNITS)) {
    return <FeaturePage />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.units')} imageSrc={teaserImage} />;
  }
};

export default withNav(
  MdUnits,
  {
    tTitle: 'units',
  },
  {},
);

const FeaturePage = () => {
  const { desktopView } = useDesktopView();
  const { t } = useTranslation();
  const [units, setUnits] = useState<Unit[]>([]);
  const [createEditUnit, setCreateEditUnit] = useState<Unit>();
  const { getAccessTokenSilently } = useAuth0();
  const [showAddPopUp, setShowAddPopUp] = useState(false);
  const [searchCondition, setSearchCondition] = useState('');
  const [selectedUnits, setSelectedUnits] = useState<Unit[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<Unit[]>([]);
  const [isAdd, setIsAdd] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [blockingLoader, setBlockingLoader] = useState(false);

  const [loadingUnits, setLoadingUnits] = useState(true);
  useDeferredLoader(loadingUnits, 'units-loading-toast');
  useEffect(() => {
    getUnits(getAccessTokenSilently)
      .then(res => {
        setUnits(res.data.data.units);
        setFilteredUnits(res.data.data.units);
        setLoadingUnits(false);
      })
      .catch(err => {
        toast.error(t('mdLeads.errorReadUnits'), { id: 'units-loading-toast' });
        setUnits([]);
      });
  }, [getAccessTokenSilently, t]);

  useEffect(() => {
    setFilteredUnits(
      searchCondition === ''
        ? units
        : units.filter(unit =>
            unit.niceName.toLocaleLowerCase().includes(searchCondition.toLocaleLowerCase()),
          ),
    );
  }, [getAccessTokenSilently, searchCondition, t, units]);

  const onSaveClick = async () => {
    setBlockingLoader(true);
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        isAdd
          ? axios.post(
              config.API_BASE_URL + `theme/units`,
              {
                niceName: createEditUnit.niceName,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
          : axios.patch(
              config.API_BASE_URL + `theme/units/${createEditUnit.id}`,
              {
                niceName: createEditUnit.niceName,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ),
      [!isAdd ? PERMISSIONS.WRITE.THEME_UNIT : PERMISSIONS.CREATE.THEME_UNIT],
    )
      .then(res => {
        if (res.data.isSuccess) {
          setShowAddPopUp(false);
          setUnits(res.data.data);
          toast.success(t('saved'), { id: 'savingUnits' });
        }
      })
      .catch(error =>
        toast.error(
          error.response.status === 409 ? t('mdLeads.unitNameExists') : t('mdLeads.errorReadUnits'),
          { id: 'savingUnits' },
        ),
      )
      .finally(() => setBlockingLoader(false));
  };
  const deleteSelected = () => {
    setBlockingLoader(true);
    deleteUnits(
      getAccessTokenSilently,
      selectedUnits.map(unit => unit.id),
    )
      .then(res => {
        setUnits(res.data.data);
        setShowDeletePopup(false);
        setSelectedUnits([]);
        toast.success(t('linkTab.successDelete'), { id: 'deletingUnits' });
      })
      .catch(err => {
        if (err?.response?.data?.error?.code === 'not-empty') {
          toast.error(t('mdLeads.unitsNotEmpty'), { id: 'deletingUnits' });
        } else {
          toast.error(t('mdLeads.errorDeleteUnits', { id: 'deletingUnits' }));
        }
      })
      .finally(() => setBlockingLoader(false));
  };

  return (
    <>
      {blockingLoader && <Loader />}
      <WhitespaceContainer scroll>
        <Spacer size={20} />
        <HeaderTextContainer>
          {!desktopView && <PageHeader>{t('units')}</PageHeader>}
          <PageSubheader>{t('unitsText')}</PageSubheader>
        </HeaderTextContainer>
        <InteractionsContainer>
          <Searchbar setParentSearchCondition={setSearchCondition} />

          <LTIconButton
            text={t('addUnit')}
            icon={<AddIcon />}
            onClick={() => {
              setCreateEditUnit(null);
              setIsAdd(true);
              setShowAddPopUp(true);
            }}
            customClass='add-new-profile-btn'
            boxStyle={THEME_BOX_STYLES.ROUNDED}
          />
        </InteractionsContainer>
        {showAddPopUp && (
          <Wrapper>
            <Popup
              headerText={isAdd ? t('addUnit') : t('editUnit')}
              onCloseClick={() => setShowAddPopUp(false)}
            >
              <PopUpContainer>
                <TextField
                  name='Unit'
                  placeholder={t('Name*')}
                  value={createEditUnit?.niceName || ''}
                  onChange={e => setCreateEditUnit({ ...createEditUnit, niceName: e.target.value })}
                  variant='outlined'
                  fullWidth={true}
                  padding={{ left: 'auto' }}
                  border={{ normal: '1px solid #5C5C5C', focus: '1px solid #5C5C5C' }}
                />
                <ButtonContainer>
                  <Button
                    text={!isAdd ? t('save') : t('createUnit')}
                    vMargin='3rem'
                    onClick={onSaveClick}
                  />
                </ButtonContainer>
              </PopUpContainer>
            </Popup>
          </Wrapper>
        )}

        {units.length === 0 && !loadingUnits ? (
          <TextWrapper>{t('noUnitData')}</TextWrapper>
        ) : (
          <>
            <UnitsTable
              columns={unitsTableColums}
              units={filteredUnits}
              handleEdit={(unit: Unit) => {
                setCreateEditUnit(unit);
                setIsAdd(false);
                setShowAddPopUp(true);
              }}
              setSelectedUnits={setSelectedUnits}
              selectedUnits={selectedUnits}
              numUnits={units.length}
              deleteSelected={() => setShowDeletePopup(true)}
            />
          </>
        )}
        {showDeletePopup && (
          <DeletePopup
            onCancel={() => setShowDeletePopup(false)}
            label={t('mdLeads.deleteUnits', { count: selectedUnits.length })}
            onDeleteClick={deleteSelected}
          />
        )}
      </WhitespaceContainer>
    </>
  );
};

const InteractionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  margin-bottom: 1%;
`;
const PopUpContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 29px;
  row-gap: 3vh;

  text-align: center;
  margin: auto;
  place-items: center;
`;
const ButtonContainer = styled.div`
  width: 215px;
`;
const Wrapper = styled.div`
  align-items: center;
`;
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* ?????????????? */
  /* height: 150vh; */
  /* margin-bottom: 25%; */
  margin-top: 25px;
  font-size: large;
`;
