import { Box } from '@mui/material';
import { FaBrush, FaRegEdit } from 'react-icons/fa';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdContacts } from 'react-icons/md';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import {
  BlueSection,
  Center,
  Heading,
  StyledBenefitsSectionContainer,
  StyledIconWrapper,
  Subtext,
} from '../styled';

interface BenefitProps {
  icon: (props: any) => JSX.Element;
  title: string;
  subtitle: string;
}
const Benefit = ({ icon, title, subtitle }: BenefitProps) => {
  const Icon = icon;
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={'2rem'}>
        <StyledIconWrapper>
          <Icon color={'#4446F0'} size={24} />
        </StyledIconWrapper>
      </Box>
      <div>
        <Heading $small style={{ marginBottom: '.5rem' }}>
          {title}
        </Heading>
        <Subtext>{subtitle}</Subtext>
      </div>
    </Box>
  );
};

const BenefitsSection = () => {
  const { t } = useAppTranslation();
  return (
    <BlueSection>
      <Center>
        <Heading>{t('teaser.importantFunction')}</Heading>
      </Center>

      <StyledBenefitsSectionContainer>
        <Benefit
          icon={HiOutlineUserGroup}
          title={t('teaser.centralUser')}
          subtitle={t('teaser.easyData')}
        />
        <Benefit
          icon={FaBrush}
          title={t('teaser.corporateIdentity')}
          subtitle={t('teaser.designDigitalProfile')}
        />
        <Benefit
          icon={FaRegEdit}
          title={t('teaser.individualProcessing')}
          subtitle={t('teaser.access')}
        />
        <Benefit
          icon={MdContacts}
          title={t('teaser.leader')}
          subtitle={t('teaser.efficientContact')}
        />
      </StyledBenefitsSectionContainer>
    </BlueSection>
  );
};

export default BenefitsSection;
