import { getFullName } from '@/shared/business-logic/features/profile/getter';
import { LeadAPI, ThemeAccount } from '@/shared/types/api';
import {
  Autocomplete,
  Avatar,
  CardHeader,
  ListItemButton,
  TextField,
  createFilterOptions,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const VISIBLE_ITEMS = 5;
const ITEM_SIZE = 72;

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: ThemeAccount) => getFullName(option),
});

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  const listProps = dataSet[0];
  const option = dataSet[1];

  return (
    <li {...listProps} style={inlineStyle}>
      <ListItemButton sx={{ p: 0 }}>
        <CardHeader
          avatar={<Avatar src={option.profileImageUrl} />}
          title={getFullName(option)}
          subheader={option.email}
        />
      </ListItemButton>
    </li>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    const itemCount = itemData.length;

    const getChildSize = () => {
      return ITEM_SIZE;
    };

    const getHeight = () => {
      if (itemCount > VISIBLE_ITEMS) {
        return VISIBLE_ITEMS * ITEM_SIZE;
      }
      return itemCount * ITEM_SIZE;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={() => getChildSize()}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

type Props = {
  lead: LeadAPI;
  themeAccounts: ThemeAccount[];
  selectedAccountId: string;
  onSelect: (accountId: string) => void;
};

export const AccountsSelector = ({ lead, themeAccounts, selectedAccountId, onSelect }: Props) => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      disabled={Boolean(lead)}
      multiple={false}
      options={themeAccounts}
      disableClearable
      onChange={(_event, newValue: ThemeAccount) => {
        if (!newValue) return;
        onSelect(newValue.id);
      }}
      value={
        selectedAccountId
          ? getFullName(themeAccounts.find(x => x.id === selectedAccountId)) || ''
          : lead
          ? t('mdLeads.accountDeleted')
          : ''
      }
      filterOptions={filterOptions}
      renderInput={params => <TextField {...params} label={t('mdLeads.metBy')} />}
      renderOption={(props, option, state) => [props, option] as React.ReactNode}
      ListboxComponent={ListboxComponent}
    />
  );
};
