import styled from 'styled-components';

import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import NewerIphoneGif from '../../../images/onboarding/newerIphones.gif';
import OlderIphoneGif from '../../../images/onboarding/olderIphones.gif';
import AndroidsGif from '../../../images/onboarding/androids.gif';
import ScanQrGif from '../../../images/onboarding/scan.gif';
import { ScreenGif } from './ScreenGif';
import { ScreenLastAdvice } from './ScreenLastAdvice';
import { ContinueButton } from './common';

interface Props {
  next: () => void;
  buttonTitle: string;
}

export const Screen3Card = (props: Props) => {
  const { t } = useAppTranslation();

  return (
    <>
      <Wrapper>
        <ScreenGif
          heading={null}
          subheading={null}
          gif={{ url: ScanQrGif, text: null, underText: t('onboarding.viaQR') }}
          secondGif={{
            text: null,
            url: NewerIphoneGif,
            underText: t('onboarding.viaNFC'),
          }}
        />
        <ScreenGif
          heading={t('TapNewIphoneHeading')}
          subheading={t('TapNewIphoneDetailSubHeading')}
          gif={{ url: NewerIphoneGif, text: t('onboarding.slideVeryTop'), underText: null }}
        />
        <ScreenGif
          heading={t('TapOlderIphoneHeading')}
          subheading={t('TapNewIphoneDetailSubHeading')}
          gif={{ url: OlderIphoneGif, text: t('onboarding.mustPressNFCbutton'), underText: null }}
        />
        <ScreenGif
          heading={t('TapAndroidHeading')}
          subheading={null}
          gif={{
            url: AndroidsGif,
            text: [t('onboarding.androidGifText1'), t('onboarding.androidGifText2')],
            underText: null,
          }}
        />
        <ScreenLastAdvice heading={t('onboarding.lastAdvice')} />
      </Wrapper>
      <ContinueButton buttonTitle={props.buttonTitle} nextFunction={props.next} />
    </>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  gap: 31px;
`;
