import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const ColumnSelector = ({ value, onChange }: Props) => {
  const { isFeatureAllowed } = useTierInfo();

  const columns = searchColumns(isFeatureAllowed(FEATURE.UNITS));
  const { t } = useTranslation();
  return (
    <FormControl size='small'>
      <InputLabel>{t('linkCode.searchBy')}</InputLabel>
      <Select
        value={value}
        label={t('linkCode.searchBy')}
        onChange={({ target: { value } }) => onChange(value)}
      >
        {columns.map(({ name, value }) => (
          <MenuItem value={value}>{t(name)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const searchColumns = (withUnits: boolean) => [
  { name: 'firstName', value: 'firstName' },
  { name: 'lastName', value: 'lastName' },
  { name: 'authEmail', value: 'authEmail' },
  { name: 'position', value: 'position' },
  { name: 'role', value: 'role' },
  ...(withUnits ? [{ name: 'mdLeads.unit', value: 'unit' }] : []),
  { name: 'title', value: 'title' },
  { name: 'division', value: 'division' },
  { name: 'company', value: 'company' },
  { name: 'workUrl', value: 'workUrl' },
  { name: 'email1', value: 'email' },
  { name: 'workEmail', value: 'workEmail' },
  { name: 'workEmail2', value: 'workEmail2' },
  { name: 'mobilePhone.text', value: 'mobilePhone' },
  { name: 'workPhone.text', value: 'workPhone' },
  { name: 'homePhone.text', value: 'homePhone' },
  { name: 'hotlinePhone.text', value: 'hotlinePhone' },
  { name: 'assistantName.text', value: 'assistantName' },
  { name: 'assistantPhone.text', value: 'assistantPhone' },
  { name: 'assistantEmail.text', value: 'assistantEmail' },
  { name: 'assistantGeneric.text', value: 'assistantGeneric' },
  { name: 'workFax.text', value: 'workFax' },
  { name: 'address.addressee', value: 'address1_addressee' },
  { name: 'address.addressLine1', value: 'address1_addressLine1' },
  { name: 'address.addressLine2', value: 'address1_addressLine2' },
  { name: 'address.postCode', value: 'address1_postCode' },
  { name: 'address.city', value: 'address1_city' },
  { name: 'address.country', value: 'address1_country' },
  { name: 'address.label', value: 'address1_label' },
  { name: 'address.2.addressee', value: 'address2_addressee' },
  { name: 'address.2.addressLine1', value: 'address2_addressLine1' },
  { name: 'address.2.addressLine2', value: 'address2_addressLine2' },
  { name: 'address.2.postCode', value: 'address2_postCode' },
  { name: 'address.2.city', value: 'address2_city' },
  { name: 'address.2.country', value: 'address2_country' },
  { name: 'address.2.label', value: 'address2_label' },
  { name: 'links', value: 'links' },
  { name: 'files', value: 'files' },
];
