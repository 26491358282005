export const UserPlaceholderIcon = () => (
  <svg width='66' height='66' viewBox='0 0 66 66' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='33' cy='33' r='33' fill='#F0F3FC' />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M24.5439 41.1789C25.6174 37.2404 29.219 34.3438 33.4992 34.3438C37.7795 34.3438 41.3811 37.2404 42.4546 41.1789C42.7017 42.0854 42.4238 42.9356 41.8729 43.534C41.3334 44.12 40.5352 44.4688 39.6867 44.4688H27.3118C26.4633 44.4688 25.6651 44.12 25.1256 43.534C24.5747 42.9356 24.2969 42.0854 24.5439 41.1789ZM33.4992 36.0312C29.9994 36.0312 27.0505 38.3996 26.1721 41.6226C26.0924 41.915 26.17 42.177 26.3671 42.391C26.5755 42.6174 26.9176 42.7812 27.3118 42.7812H39.6867C40.0809 42.7812 40.423 42.6174 40.6314 42.391C40.8285 42.177 40.9061 41.915 40.8265 41.6227L40.8264 41.6226C39.948 38.3996 36.9992 36.0312 33.4992 36.0312Z'
      fill='#495474'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M33.5 24.7812C31.7913 24.7812 30.4062 26.1664 30.4062 27.875C30.4062 29.5836 31.7913 30.9687 33.5 30.9687C35.2086 30.9687 36.5937 29.5836 36.5937 27.875C36.5937 26.1664 35.2086 24.7812 33.5 24.7812ZM28.7188 27.875C28.7188 25.2344 30.8593 23.0938 33.5 23.0938C36.1406 23.0938 38.2812 25.2344 38.2812 27.875C38.2812 30.5156 36.1406 32.6562 33.5 32.6562C30.8593 32.6562 28.7188 30.5156 28.7188 27.875Z'
      fill='#495474'
    />
  </svg>
);
