import { IconType } from 'react-icons';
import { BsCheck2 } from 'react-icons/bs';
import {
  Center,
  CenterFlexRow,
  Heading,
  Section,
  StyledTiersContainer,
  TierActionButton,
  TierActionSubtext,
  TierContainer,
  TierDescriptionBullet,
  TierDescriptionTitle,
  TierHighlight,
  TierIconText,
  TierLabel,
  TierLabelSublabel,
  TierShortDescrContainer,
  TierTitle,
} from '../styled';
import { CSSProperties, useMemo } from 'react';

import { IoPersonOutline } from 'react-icons/io5';
import { BsChatText } from 'react-icons/bs';

import { HiOutlineUserGroup } from 'react-icons/hi';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { TRIAL_LENGTH_DAYS } from '@/shared/constants';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';

interface TierProps {
  highlight?: string;
  title: string;
  subtitle: {
    icon: IconType;
    text: string;
  };
  shortDescr: string;
  label: JSX.Element;
  sublabel?: string;
  actionButton: {
    text: string;
    action?: () => void;
    subtext?: string;
    subaction?: () => void;
  };
  description: {
    title: string;
    bullets: string[];
  };
}

const Tier = ({
  highlight,
  title,
  subtitle,
  shortDescr,
  label,
  sublabel,
  actionButton,
  description,
  style,
}: TierProps & { style?: CSSProperties }) => {
  const Icon = subtitle.icon;
  return (
    <TierContainer highlight={highlight}>
      {highlight && <TierHighlight>{highlight}</TierHighlight>}

      <TierTitle>{title}</TierTitle>
      <CenterFlexRow>
        <Icon size={12} color='#707070' />
        <TierIconText>{subtitle.text}</TierIconText>
      </CenterFlexRow>

      <TierShortDescrContainer>{shortDescr}</TierShortDescrContainer>

      <TierLabel>
        <div>{label}</div>
        <TierLabelSublabel>{sublabel}</TierLabelSublabel>
      </TierLabel>

      <TierActionButton
        onClick={actionButton.action ?? undefined}
        isClickable={!!actionButton.action}
        hasSubtext={!!actionButton.subtext}
      >
        {actionButton.text}
      </TierActionButton>

      {actionButton.subtext && (
        <TierActionSubtext onClick={actionButton.subaction} isClickable={!!actionButton.subaction}>
          {actionButton.subtext}
        </TierActionSubtext>
      )}

      <TierDescriptionTitle>{description.title}</TierDescriptionTitle>
      {description.bullets.map(bullet => (
        <TierDescriptionBullet key={bullet}>
          <BsCheck2 size={16} color={'#181A5A'} /> {bullet}
        </TierDescriptionBullet>
      ))}
    </TierContainer>
  );
};

const TiersSection = ({
  canStartTrial,
  onShowUpgradePopup,
}: {
  canStartTrial: boolean;
  onShowUpgradePopup: Function;
}) => {
  const { t } = useAppTranslation();
  const { upgradeAction, upgradeRequestPopup, setShowUpgradePopup } = useUpgradeAction();
  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  const TIERS_CONFIG = useMemo<TierProps[]>(
    () => [
      {
        title: 'Lemontaps Starter',
        subtitle: {
          icon: IoPersonOutline,
          text: t('teaser.individualUser'),
        },
        shortDescr: t('teaser.starterShortDescr'),
        label: (
          <div style={{ height: '6rem', color: '#181A5A', fontWeight: 500, fontSize: '4.5rem' }}>
            0€
          </div>
        ),
        sublabel: t('teaser.noPayment'),
        actionButton: {
          text: t('teaser.currentPlan'),
        },
        description: {
          title: t('teaser.basicFeature'),
          bullets: t('teaser.starterBullets', { returnObjects: true }),
        },
      },
      {
        highlight: t('teaser.popular'),
        title: 'Lemontaps Teams',
        subtitle: {
          icon: HiOutlineUserGroup,
          text: t('teaser.groupUsers'),
        },
        shortDescr: t('teaser.teamsShortDescr'),
        label: flag_hidePrices ? (
          <div style={{ height: '6rem' }}>
            <BsChatText style={{ height: '6rem' }} size={45} color='#181A5A' />
          </div>
        ) : (
          <div style={{ height: '6rem', color: '#181A5A', fontWeight: 500, fontSize: '4.5rem' }}>
            1,25€
          </div>
        ),
        sublabel: flag_hidePrices ? t('teaser.graduatedprices') : t('teaser.peruserpermonth'),
        actionButton: {
          text: !canStartTrial
            ? flag_hidePrices
              ? t('contactSales')
              : t('upgradeNow')
            : t('teaser.costless', { days: TRIAL_LENGTH_DAYS }),
          subtext: !canStartTrial
            ? undefined
            : flag_hidePrices
            ? t('teaser.salesSubtext')
            : t('teaser.upgradeSubtext'),
          subaction: !canStartTrial ? undefined : upgradeAction,
          action: !canStartTrial ? upgradeAction : () => onShowUpgradePopup(),
        },
        description: {
          title: t('teaser.allStarterFeatures'),
          bullets: t('teaser.proBullets', { returnObjects: true }),
        },
      },
      {
        title: 'Lemontaps Enterprise',
        subtitle: {
          icon: HiOutlineUserGroup,
          text: t('teaser.hunderdsgroupUsers'),
        },
        shortDescr: t('teaser.enterpriseShortDescr'),
        label: (
          <div style={{ height: '6rem' }}>
            <BsChatText style={{ height: '6rem' }} size={45} color='#181A5A' />
          </div>
        ),
        sublabel: flag_hidePrices ? t('teaser.graduatedprices') : t('teaser.askUs'),
        actionButton: {
          text: t('contactSales'),
          action: () => setShowUpgradePopup(true),
        },
        description: {
          title: t('teaser.allProFeatures'),
          bullets: t('teaser.enterpriseBullets', { returnObjects: true }),
        },
      },
    ],
    [t, flag_hidePrices, canStartTrial, upgradeAction, onShowUpgradePopup, setShowUpgradePopup],
  );
  return (
    <Section>
      {upgradeRequestPopup}

      {/* tiers section */}
      <Center>
        <Heading>{t('teaser.packageComparison')}</Heading>
      </Center>

      <StyledTiersContainer>
        {TIERS_CONFIG.map(config => (
          <Tier {...config} />
        ))}
      </StyledTiersContainer>
    </Section>
  );
};

export default TiersSection;
