import SingleQuestion from './SingleQuestion';
import { RowDiv } from './styles';

const QuestionsContainer = ({ questions }) => {
  return (
    <RowDiv isWrap>
      {questions.map(question => (
        <SingleQuestion
          image={question.image}
          title={question.title}
          link={question.link}
          linkLabel={question.linkLabel}
          action={question.action}
        />
      ))}
    </RowDiv>
  );
};

export default QuestionsContainer;
