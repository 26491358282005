import { Box, Button, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ErrorLinkPage = () => {
  const codeToConnect = useLocation<{ codeToConnect: string }>().state?.codeToConnect;
  const { t } = useTranslation();

  if (!codeToConnect) {
    return <Redirect to='/' />;
  }
  return (
    <Box
      m='auto'
      p={2}
      justifyContent='center'
      height='100vh'
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <HighlightOffIcon sx={{ width: '5rem', height: '5rem', color: 'warning.dark' }} />
      <Typography variant='h3' sx={{ mt: 3, textAlign: 'center' }}>
        {t('linkCode.errorLinkTitle')}
      </Typography>
      <Link to={{ pathname: `/md/linkcode/${codeToConnect}` }}>
        <Button variant='text' color='primary' sx={{ mt: 2 }}>
          {t('linkCode.tryAgain')}
        </Button>
      </Link>
    </Box>
  );
};
