import styled from 'styled-components';
import { CSSProperties } from 'react';
import { DESKTOP_BREAK_POINT } from '../../../../shared/constants';
import { CSS_VARS } from '@/infrastructure/constants';

type Props = {
  children: JSX.Element | JSX.Element[];
  maxWidth?: string;
  style?: CSSProperties;
};

const ViewsContainer = (props: Props) => {
  return (
    <Wrapper style={props.style}>
      <MaxWidthContainer $maxWidth={props.maxWidth}>{props.children}</MaxWidthContainer>
    </Wrapper>
  );
};

export default ViewsContainer;

const Wrapper = styled.div`
  position: sticky;
  z-index: 1;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  width: 100%;

  min-height: calc(
    100vh - ${CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR} - ${CSS_VARS.LT_BOTTOM_NAVBAR_HEIGHT_VAR}
  );
  @media (min-width: ${DESKTOP_BREAK_POINT}px) {
    min-height: calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR});
  } ;
`;

const MaxWidthContainer = styled.div`
  margin: auto;
  max-width: ${props => props.$maxWidth ?? '100rem'};
`;
