import React, { PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';
import websiteIcon from '@/views/images/textbox-icons/website.svg';
import phoneIcon from '@/views/images/textbox-icons/phone.svg';
import mailIcon from '@/views/images/textbox-icons/mail.svg';
import locationIcon from '@/views/images/textbox-icons/location.svg';
import downIcon from '@/views/images/down.svg';
import userIcon from '@/views/images/textbox-icons/user.svg';
import { ReactComponent as EditIcon } from '@/views/images/edit-icon.svg';
import { ReactComponent as DownloadIcon } from '@/views/images/download.svg';
import { ThemeBoxStyle } from '@/shared/types/global';
import Layout from '@/views/components/common/white-layout';
import { RequireAtMostOne } from '@/shared/types/util';
import { ContactDetailsBox as ContactDetailsBoxType } from '@/shared/types/api';
import { IconButton } from '@mui/material';

// TODO: profile page optimizations

type Props = RequireAtMostOne<
  {
    config: ContactDetailsBoxType;
    boxStyle: ThemeBoxStyle;
    onSaveContact?: () => void;
    editConfig?: {
      editText: string;
      onEditClick(): void;
    };
  },
  'onSaveContact' | 'editConfig' // can either show edit or save button
>;

export default function ContactDetailsBox(props: Props) {
  const { config, editConfig, onSaveContact, boxStyle } = props;

  const [expandView, setExpandView] = useState(!config.foldInitially);

  const iconMap = {
    websiteIcon,
    phoneIcon,
    userIcon,
    mailIcon,
    locationIcon,
  };

  if (config.rows.length === 0 && !editConfig) return null;

  return (
    <Layout
      headerText={config.label}
      themeBoxStyle={boxStyle}
      actionButton={
        editConfig
          ? {
              text: editConfig.editText,
              icon: <EditIcon />,
              customBtnClasses: 'lt-usetiful--bio-edit',
              onClick: editConfig.onEditClick,
            }
          : config.contactSaveButton && onSaveContact
          ? {
              text: config.contactSaveButton.text,
              icon: <DownloadIcon />,
              onClick: onSaveContact,
              backgroundCss: config.contactSaveButton.backgroundCss,
            }
          : undefined
      }
    >
      <ContactWrapper withToggle={config.rows.length > config.foldAfter}>
        {config.rows
          .slice(0, expandView ? config.rows.length : config.foldAfter)
          .map((row, idx) => {
            const Wrapper = row.href
              ? (props: PropsWithChildren<{}>) => (
                  <ContactLink href={row.href} target='_blank' rel='noopener noreferrer'>
                    {props.children}
                  </ContactLink>
                )
              : (props: PropsWithChildren<{}>) => <>{props.children}</>;
            return (
              <Wrapper>
                <ContactRow>
                  <img
                    style={{
                      transform:
                        row.icon.name === 'websiteIcon' ? 'rotate(90deg) scale(0.95)' : undefined,
                      visibility: !iconMap[row.icon.name] ? 'hidden' : undefined,
                    }}
                    src={iconMap[row.icon.name]}
                    alt={`${row.icon.name} icon`}
                  />
                  <ContactContent>
                    <ContactValue>
                      {row.lines.map(line => (
                        <>
                          {line.href ? (
                            <ContactLink href={line.href} target='_blank' rel='noopener noreferrer'>
                              {line.text}
                            </ContactLink>
                          ) : (
                            line.text
                          )}
                          <br />
                        </>
                      ))}
                    </ContactValue>
                    {row.label && <ContactLabel> {row.label}</ContactLabel>}
                  </ContactContent>
                </ContactRow>
              </Wrapper>
            );
          })}
      </ContactWrapper>
      {config.rows.length > config.foldAfter && (
        <ArrowIconWrapper>
          <IconButton
            sx={{ width: '3.5rem', height: '3.5rem' }}
            onClick={() => setExpandView(!expandView)}
          >
            <ArrowIcon src={downIcon} alt='toggle' expandView={expandView} />
          </IconButton>
        </ArrowIconWrapper>
      )}
    </Layout>
  );
}

const ContactWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  ${(props: { withToggle: boolean }) =>
    props.withToggle &&
    css`
      padding-right: 5rem;
    `}
`;

export const ArrowIconWrapper = styled.div`
  position: relative;
  height: 18px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: -8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ArrowIcon = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  ${(props: { expandView }) =>
    props.expandView &&
    css`
      transform: rotate(180deg);
    `}
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ::first-child {
    margin-top: 0;
  }
  & > img {
    margin: 0 1.5rem 0 0.5rem;
    width: 18px;
  }
`;

const ContactContent = styled.div`
  line-height: 21px;
  padding: 8px 0;
  flex: 1;
`;

const ContactValue = styled.div`
  color: #55595e;
  font-size: 14px;
  font-weight: 500;
`;

const ContactLabel = styled.div`
  color: #ababab;
  font-size: 12px;
  margin-top: -5px;
`;

const ContactLink = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
  & > * {
    &:active {
      border-radius: 100px;
      background-image: linear-gradient(to bottom, #eee, #ddd);
    }
  }

  &:focus-visible {
    outline: 2px solid;
  }
  display: block;
`;
