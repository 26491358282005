import config from '../../../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../../../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { InputFormSaveProps } from '../../../../views/pages/md/profiles/common/inputs-form';
import { ApiFile, BulkFile, Link } from '../../../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';

export const _bulkUpdateInfo = async (
  ids: readonly string[],
  toUpdateAccounts: InputFormSaveProps,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = Object.assign({ employeeIds: ids }, toUpdateAccounts);
  const response = await axios.post(config.API_BASE_URL + 'business/bulkUpdateEmployees', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkUpdateLinks = async (
  ids: readonly string[],
  toUpdateLinks: Link[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = Object.assign({ ids }, { toUpdateLinks });
  const response = await axios.post(config.API_BASE_URL + 'business/bulkUpdateLinks', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkDeleteLinks = async (
  ids: readonly string[],
  toDeleteLinks: Link[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = Object.assign({ ids }, { toDeleteLinks });
  const response = await axios.post(config.API_BASE_URL + 'business/bulkDeleteLinks', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkDeleteFiles = async (
  ids: readonly string[],
  toDeleteFiles: ApiFile[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = Object.assign({ ids }, { toDeleteFiles });
  const response = await axios.post(config.API_BASE_URL + 'business/bulkDeleteFiles', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkExtendFiles = async (
  ids: readonly string[],
  file: BulkFile,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const payload = { ids, file };
  const response = await axios.post(config.API_BASE_URL + 'business/bulkExtendFiles', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _bulkSendEmail = async (
  ids: readonly string[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  let payload = { ids };

  const response = await axios.post(config.API_BASE_URL + 'business/bulkSendEmail', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const _getCommonLinks = async (ids, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.READ.THEME_PROFILES),
  });

  const payload = { ids };
  const response = await axios.post(`${config.API_BASE_URL}link/commons`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const _getCommonFiles = async (ids, usernames, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.READ.THEME_PROFILES),
  });

  const payload = { ids, usernames };
  const response = await axios.post(`${config.API_BASE_URL}file/commons`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const _bulkDelete = async (
  ids: readonly string[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.DELETE.THEME_PROFILES),
  });

  let payload = { ids };

  const response = await axios.post(config.API_BASE_URL + 'business/bulkDeleteEmployees', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
