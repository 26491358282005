import { ArrowForwardOutlined } from '@mui/icons-material';
import { Box, Card, CardActionArea, Typography, alpha } from '@mui/material';
import { useHistory } from 'react-router-dom';

const SettingsItem = ({
  text,
  icon,
  caption,
  link,
  onClick,
  hide,
  external,
}: {
  text: string;
  icon: React.ReactNode;
  caption?: string;
  link?: string;
  onClick?: () => void;
  hide?: boolean;
  external?: boolean;
}) => {
  const history = useHistory();
  if (hide) return null;
  return (
    <Card
      onClick={onClick ?? (() => (external ? window.open(link, '_blank') : history.push(link)))}
      sx={{ mt: 2 }}
    >
      <CardActionArea
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display='flex' alignItems='center'>
          <Box
            sx={theme => ({
              bgcolor: alpha(theme.palette.primary.main, 0.7),
              mr: 1,
              display: 'flex',
              width: '40',
              height: '40',
              p: 1,
              borderRadius: '50%',
            })}
          >
            {icon}
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant='body2'>{text}</Typography>
            {caption && (
              <Typography variant='caption' color='text.secondary'>
                {caption}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display='flex'>
          <ArrowForwardOutlined />
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default SettingsItem;
