import React from 'react';
import styled from 'styled-components';
import { RequireOnlyOne } from '../../../shared/types/util';

type IProps = RequireOnlyOne<
  {
    textColor?: string;
    backgroundColor?: string;
    backgroundImage?: {
      src: string;
      styles?: React.CSSProperties;
    };
    style?: React.CSSProperties;
  },
  'backgroundColor' | 'backgroundImage'
>;

export const Section: React.FC<IProps> = ({ children, ...props }) => {
  if (props.backgroundColor) {
    return (
      <Wrapper
        style={{
          color: props.textColor,
          backgroundColor: props.backgroundColor,
          ...props?.style,
        }}
      >
        {children}
      </Wrapper>
    );
  } else if (props.backgroundImage) {
    return (
      <Wrapper
        style={{
          color: props.textColor,
          ...props?.style,
        }}
        bgImage={props.backgroundImage}
      >
        <BGImage src={props.backgroundImage.src} style={props.backgroundImage.styles} />
        {children}
      </Wrapper>
    );
  } else {
    return null;
  }
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  ${props =>
    props.bgImage &&
    `
    * {
      z-index: 5;
    }
  `}
`;

const BGImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
