import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import styled from 'styled-components';
import { TextField } from '../../../components/generic';
import { LinkFields } from './customDataLinks';
import { PrimaryButton, Spacer } from './common';
import { getThemeLinkTypes } from '../../../../application/actions/edit-profile';
import AutoComplete, { AutoCompleteValue } from '../../../components/common/autoComplete';
import { LinkType } from '../../../../shared/types/api';

interface Props {
  onBackClick: () => void;
  onSaveClick: (link: LinkFields, isEdit?: boolean, callBack?: () => void) => void;
  editLink?: LinkFields;
  isAddDisabled?: boolean;
}

const AddLinkComponent = (props: Props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const themeLinkTypes: LinkType[] = useAppSelector(state => state.editProfile.linkTypes);
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (themeLinkTypes.length === 0) dispatch(getThemeLinkTypes(getAccessTokenSilently));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);
  if (document.getElementById('autocomplete-component')) {
    document.getElementById('autocomplete-component').style.borderStyle = 'none';
    document.getElementById('autocomplete-component').style.boxShadow = 'none';
    document.getElementById('autocomplete-component').style.borderColor = 'white';
    document.getElementById('autocomplete-component').style.fontSize = '20px';
    document.getElementById('autocomplete-component').style.height = '50px';
  }
  useEffect(() => {
    setAutoCompleteValues(
      themeLinkTypes?.map((theme, index) => {
        return {
          id: theme.id,
          description: theme.name,
          index,
          // @ts-ignore
          order: theme?.themeLinkTypes?.[0]?.order,
          otherData: theme,
        };
      }),
    );
  }, [themeLinkTypes]);
  const initialState = {
    name: '',
    link: '',
    type: 0,
    color: '',
    isDefault: false,
    iconUrl: '',
    mode: '',
  };
  const saveLink = () => {
    setIsValidating(true);
    if (link.name === '' || link.link === '' || link.type === 0) {
      return;
    }
    props.onSaveClick(link, false, () => {
      setLink({ ...initialState, type: link.type });
      setIsValidating(false);
    });
  };

  const [link, setLink] = useState<LinkFields>(initialState);

  const borderProps = {
    normal: '1px solid rgb(221, 221, 221)',
    focus: '1px solid #181a5a',
  };

  const saveAutoValue = (item: AutoCompleteValue) => {
    setLink({
      ...link,
      type: item?.otherData.themeLinkTypes[0].id || 0,
      iconUrl: item?.otherData.iconUrl,
      color: item?.otherData.backgroundCss,
      mode: item?.otherData.mode,
    });
  };

  return (
    <Container>
      <AutoCompleted>
        <AutoComplete
          options={autoCompleteValues}
          onSave={saveAutoValue}
          placeHolder={t('linkTab.linkType')}
          style={{ minWidth: '25%' }}
        />
      </AutoCompleted>
      <Spacer size={25} />
      <StyledText
        label={t('linkTab.name')}
        name={'linkTab'}
        value={link.name}
        angles='normal'
        type='text'
        border={borderProps}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLink({ ...link, name: e.target.value })
        }
        font={{ size: '18px', weight: '400' }}
        padding={{ left: '0' }}
        helperText={isValidating && link.name === '' && 'Link name required'}
        error={isValidating && link.name === ''}
        placeholder={link?.mode === 'link' ? t('linkTab.placeHolderLink') : ''}
      />
      <Spacer size={25} />
      <StyledText
        label={
          link?.mode === 'username'
            ? t('username')
            : link?.mode === 'phone'
            ? t('linkTab.linkPhone')
            : t('linkTab.linkUrl')
        }
        name={'linkTab'}
        value={link.link}
        angles='normal'
        type='text'
        border={borderProps}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLink({ ...link, link: e.target.value })
        }
        font={{ size: '18px', weight: '400' }}
        padding={{ left: '0' }}
        placeholder={
          link?.mode === 'username'
            ? ''
            : link?.mode === 'phone'
            ? t('linkTab.placeHolderPhone')
            : t('linkTab.placeHolderUrl')
        }
        prefixText={link?.mode === 'phone' ? '+' : link?.mode === 'username' ? '@' : undefined}
        helperText={isValidating && link.link === '' && 'Link name required'}
        error={isValidating && link.link === ''}
      />
      <Spacer size={25} />
      <AddButton
        disabled={link.name === '' || link.link === '' || link.type === 0 || props.isAddDisabled}
        onClick={saveLink}
      >
        {t('save')}
      </AddButton>
    </Container>
  );
};

export default AddLinkComponent;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 1800px) {
    width: 70%;
    border-radius: 0%;
  }
`;
const AutoCompleted = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: green;
  flex-wrap: wrap;
  margin-top: -9px;
`;

const StyledText = styled(TextField)`
  width: 25%;
  min-width: 200px;
`;

const AddButton = styled(PrimaryButton)`
  padding: 15px 0px;
  font-size: 1.2rem;
  width: 10%;
  min-width: 150px;
  @media (max-width: 550) {
    margin-top: 15px;
  }
  ${props => props.disabled && 'background: #dddddd'};
`;
