import { RequireAtMostOne } from '@/shared/types/util';
import { Card, Typography, styled } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ComponentProps, useCallback, useMemo } from 'react';
import LtLoadingButton from './LtLoadingButton';
import LtMultiActionButton from './LtMultiActionButton';

type Props = RequireAtMostOne<
  {
    image?: {
      src: string;
      alt: string;
    };
    title: string;
    body: string;
    loading?: boolean;
    disabled?: boolean;
    singleAction: {
      text: string;
      onClick: () => void;
    };
    multipleActions: {
      text: string;
      actions: ComponentProps<typeof LtMultiActionButton>['options'];
    };
  },
  'singleAction' | 'multipleActions'
>;

export default function LtActionCard({
  image,
  title,
  body,
  loading,
  disabled,
  singleAction,
  multipleActions,
}: Props) {
  const ActionButton = useCallback(
    ({ className }: { className?: string }) => {
      if (singleAction) {
        return (
          <LtLoadingButton
            className={className}
            loading={loading}
            disabled={disabled}
            onClick={singleAction.onClick}
          >
            {singleAction.text}{' '}
          </LtLoadingButton>
        );
      } else if (multipleActions) {
        return (
          <LtMultiActionButton
            className={className}
            label={multipleActions.text}
            options={multipleActions.actions}
          />
        );
      } else {
        return null;
      }
    },
    [disabled, loading, multipleActions, singleAction],
  );
  const StyledActionButton = useMemo(
    () => styled(ActionButton)({ alignSelf: 'flex-end' }),
    [ActionButton],
  );

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      {image && (
        <Box
          component='img'
          src={image.src}
          alt={image.alt}
          padding={2}
          maxHeight='28rem'
          sx={{ background: 'rgba(17, 19, 86, 0.04)', objectFit: 'contain' }}
        />
      )}
      <Stack padding={2}>
        <Typography variant='h3'>{title}</Typography>
        <Typography variant='body1' mt={1} mb={2}>
          {body}
        </Typography>
        <StyledActionButton />
      </Stack>
    </Card>
  );
}
