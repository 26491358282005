import { invertHex } from '@/infrastructure/helper';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { ClicksEntity } from '@/shared/types/api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Card, CardContent, Collapse, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import folderIcon from '../../../images/folder.svg';
import TooltipInfo from '@/views/components/generic/TooltipInfo';

const WithTooltipWrapper = ({
  tooltip,
  children,
}: {
  tooltip: string;
  children: JSX.Element;
}): JSX.Element =>
  tooltip && tooltip.length ? (
    <TooltipInfo text={tooltip} onHover>
      {children}
    </TooltipInfo>
  ) : (
    children
  );

const ClicksCard = ({
  mode,
  open,
  onClick,
  entities,
  totalClicks,
  oldClicks,
}: {
  mode: 'links' | 'files';
  open: boolean;
  onClick: () => void;
  entities: ClicksEntity[];
  totalClicks: number;
  oldClicks?: number;
}) => {
  const { t } = useAppTranslation();
  const linksMode = mode === 'links';

  return (
    <Card sx={{ flexBasis: '100%' }}>
      <CardContent
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ':hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Typography variant='h4'>
          {t(`analytics.clicks.${linksMode ? 'links' : 'files'}`)}
        </Typography>
        <Stack direction='row' alignItems='center' gap={1}>
          <Typography variant='h4'>{totalClicks}</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Stack>
      </CardContent>
      <Divider sx={{ visibility: open ? 'visible' : 'hidden' }} />

      <Collapse in={open} timeout='auto' unmountOnExit>
        {!!entities?.length &&
          entities.map((ent, entIdx) => (
            <>
              <CardContent>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    width='95%'
                    onClick={() => window.open(ent.fullUrl, '_blank')}
                    sx={{ cursor: 'pointer' }}
                  >
                    <WithTooltipWrapper tooltip={linksMode ? ent.fullUrl : null}>
                      <Card
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '1rem',
                          marginRight: '1rem',
                          borderRadius: '50%',
                          background: ent.backgroundCss,
                          overflow: 'visible',
                        }}
                      >
                        <img
                          color='#fff'
                          src={linksMode ? ent.iconColorUrl : folderIcon}
                          alt='link-icon'
                          width='25'
                          height='25'
                          style={{
                            color: '#fff',
                            fill: '#fff',
                            filter: `brightness(0) ${
                              invertHex(ent.backgroundCss, true).toLocaleLowerCase() === '#ffffff'
                                ? 'invert(100)'
                                : ''
                            }`,
                          }}
                        />
                      </Card>
                    </WithTooltipWrapper>
                    <Typography
                      variant='body2'
                      sx={{
                        width: '100%',
                        display: 'inline-block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {ent.name}
                    </Typography>
                  </Box>
                  <Typography variant='body2'>{ent.clicks}</Typography>
                </Box>
              </CardContent>
              {entIdx < entities.length - 1 && <Divider />}
            </>
          ))}
        {oldClicks > 0 && (
          <>
            <Divider />
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='body2'>
                {t(`analytics.clicks.old${linksMode ? 'Link' : 'File'}Clicks`)}
              </Typography>
              <Typography variant='body2'>
                {t('analytics.clicks.unit', { count: oldClicks })}
              </Typography>
            </CardContent>
          </>
        )}
      </Collapse>
    </Card>
  );
};

export default ClicksCard;
