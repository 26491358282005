import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { MdOutlineFlipCameraIos, MdUploadFile } from 'react-icons/md';

import { RowDiv, ScannerVideo, SmallText } from '../../pages/md/profiles/common/styles';
import { Spacer } from '../../pages/md/customization/common';
import { isFileValid } from '@/infrastructure/helper';
import toast from 'react-hot-toast';
import { Button, Separator } from '@/views/components/generic';
import FlexWrapper from '@/views/components/generic/FlexWrapper';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

type Props = {
  scanSetters: {
    setScanResult: Dispatch<SetStateAction<QrScanner.ScanResult>>;
    setNewScan: Dispatch<SetStateAction<boolean>>;
  };
  videoWidth?: string;
  allowUpload?: boolean;
};

const ScannerCamera = (props: Props) => {
  const { t } = useAppTranslation();
  let videoRef = useRef(null);
  const scanner = useRef<QrScanner | null>(null);
  const imageInput = useRef(null);

  const { scanSetters, allowUpload } = props;
  const { setScanResult, setNewScan } = scanSetters;
  const [cameras, setCameras] = useState<Array<{ id: string; label: string }>>([]);
  const [cameraIdx, setCameraIdx] = useState(0);

  useEffect(() => {
    if (!videoRef.current || cameras.length === 0 || scanner.current) return;

    scanner.current = new QrScanner(
      videoRef.current,
      result => {
        setScanResult(result);
        setNewScan(true);
      },
      {
        returnDetailedScanResult: true,
        maxScansPerSecond: 2,
        preferredCamera: 'user',
        highlightCodeOutline: true,
      },
    );

    scanner.current.setCamera(cameras[cameraIdx].id);
    scanner.current.start();
  }, [cameraIdx, cameras, setNewScan, setScanResult]);

  useEffect(() => {
    return () => {
      if (scanner.current) scanner.current.destroy();
    };
  }, []);

  useEffect(() => {
    QrScanner.listCameras(true).then(setCameras);
  }, []);

  const switchCamera = () => {
    const nextIdx = (cameraIdx + 1) % cameras.length;
    setCameraIdx(nextIdx);
    scanner.current?.setCamera(cameras[nextIdx].id);
  };

  const handleScanImage = () => {
    const uploadedFile = imageInput.current?.files[0];
    const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
    if (errMsg) {
      toast.error(errMsg);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      const scanResult = reader.result as string;
      QrScanner.scanImage(scanResult)
        .then(result => {
          setScanResult({ data: result } as QrScanner.ScanResult);
          setNewScan(true);
        })
        .catch(error => console.error(error || 'No QR code found.'));
    };
  };

  return (
    <>
      <ScannerVideo ref={videoRef} playsInline muted autoplay videoWidth={props?.videoWidth} />
      {cameras.length > 1 && (
        <>
          <Spacer size={9} />
          <SmallText>{cameras[cameraIdx].label}</SmallText>
          <Spacer size={6} />
          <RowDiv onClick={switchCamera}>
            <MdOutlineFlipCameraIos color={'grey'} size={25} />
            <Spacer size={9} />
            <SmallText>{t('codeRead.switchCamera')}</SmallText>
          </RowDiv>
        </>
      )}
      {allowUpload && (
        <>
          <Separator text={t('or')} />
          <FlexWrapper style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Button
              text={t('codeRead.uploadImage')}
              size='1.8rem'
              onClick={() => imageInput.current.click()}
              icon={{ element: <MdUploadFile />, left: true, size: '2.3rem' }}
              width='fit-content'
              txtColor='#fff'
              bgColor='#02E0B0'
              margin={{ left: '1rem' }}
              padding={{ left: '4rem', right: '4rem', top: '1.6rem', bottom: '1.6rem' }}
              radius='10px'
            />
            <input
              type='file'
              onChange={handleScanImage}
              style={{ display: 'none' }}
              ref={imageInput}
              accept='image/*'
              onClick={e => {
                setNewScan(false);
                (e.target as HTMLInputElement).value = null;
              }}
            />
          </FlexWrapper>
        </>
      )}
    </>
  );
};

export default ScannerCamera;
