import { customLightPalette, defaultPalette } from './palette';
import CloseIcon from '@mui/icons-material/Close';

export const defaultComponents = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        avatarLetter: 'div',
        inputLabel: 'label',
        helperText: 'div',
        inputText: 'div',
        tooltip: 'span',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      color: 'primaryButton' as const,
      variant: 'contained' as const,
      focusRipple: true,
    },
    styleOverrides: {
      containedError: {
        backgroundColor: defaultPalette.error.dark,
      },
      text: {
        color: defaultPalette.text.primary,
      },
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      focusRipple: true,
    },
  },
  MuiListItemButton: {
    defaultProps: {
      focusRipple: true,
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      color: 'primaryButton' as const,
      variant: 'contained' as const,
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      color: 'primaryButton' as const,
      variant: 'contained' as const,
    },
  },
  MuiCard: {
    defaultProps: {
      variant: 'outlined' as const,
    },
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h4' as const,
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: 12,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: customLightPalette.anchor,
        '&:hover': {
          color: customLightPalette.anchor,
        },
        '&:visited': {
          color: customLightPalette.anchor,
        },
      },
    },
  },
  MuiAlert: {
    components: {
      CloseIcon: CloseIcon,
    },
    styleOverrides: {
      outlinedInfo: {
        borderColor: defaultPalette.info.main,
        //todo :: override the default icon color to our design info.main color
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: {
        backgroundColor: defaultPalette.primary.main,
        color: defaultPalette.primary.contrastText,
        fontSize: '1.4rem',
      },
      arrow: {
        color: defaultPalette.primary.main,
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
      color: 'inherit' as const,
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      shrink: {
        transform: 'translate(14px,-9px) scale(0.857)',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        fontSize: '1.86rem',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: 'white',
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary' as const,
    },
  },
};
