import { dispatchTypes } from '../../index';
import {
  _bulkSendEmail,
  _bulkUpdateInfo,
  _bulkUpdateLinks,
  _bulkDeleteLinks,
  _bulkDeleteFiles,
  _bulkExtendFiles,
} from '../../../../infrastructure/apis/md/profiles/bulk';
import { saveFile } from '../../../../infrastructure/apis/md/profiles/files';
import { _getCommonLinks, _getCommonFiles } from '../../../../infrastructure/apis/md/profiles/bulk';
import { updateImageUrl } from '../../../../infrastructure/apis/md/profiles';
import { doS3UploadForBusiness } from '../../../../infrastructure/apis/aws';
import toast from 'react-hot-toast';
import {
  ACCOUNT_STATUSES,
  INVITE_EMAILS_MAX_NUM,
  PRIVATE_EVENTS,
  PRIVATE_META_TYPES,
} from '../../../../shared/constants';
import { TFunction } from 'i18next';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { InputFormSaveProps } from '../../../../views/pages/md/profiles/common/inputs-form';
import { Link, ApiFile, BulkFile } from '../../../../shared/types/api';
import i18n from '../../../../config/i18n';
import { trackPrivateEvent } from '../../../../infrastructure/apis/analytics';

export const getCommonLinks = (ids, getAccessTokenSilently) => async dispatch => {
  try {
    let { data } = await _getCommonLinks(ids, getAccessTokenSilently);

    if (data.length) {
      dispatch({ type: dispatchTypes.BUSINESS.BULK.SET_COMMON_LINKS, commonLinks: data });
    } else {
      dispatch({ type: dispatchTypes.BUSINESS.BULK.SET_COMMON_LINKS, commonLinks: [] });
    }
  } catch (error) {
    dispatch({ type: dispatchTypes.BUSINESS.BULK.SET_COMMON_LINKS, commonLinks: [] });
  }
};

export const getCommonFiles = (ids, usernames, getAccessTokenSilently) => async dispatch => {
  try {
    let { data } = await _getCommonFiles(ids, usernames, getAccessTokenSilently);

    if (data.length) {
      dispatch({ type: dispatchTypes.BUSINESS.BULK.SET_COMMON_FILES, commonFiles: data });
    } else {
      dispatch({ type: dispatchTypes.BUSINESS.BULK.SET_COMMON_FILES, commonFiles: [] });
    }
  } catch (error) {
    dispatch({ type: dispatchTypes.BUSINESS.BULK.SET_COMMON_FILES, commonFiles: [] });
  }
};

export const bulkSendEmail =
  (
    idsToUpdate: readonly string[],
    t: TFunction,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    if (idsToUpdate.length > 100) {
      toast.loading(t('LoadingLong'), { id: 'toastid' });
    } else {
      toast.loading(t('Loading'), { id: 'toastid' });
    }
    try {
      let response = await _bulkSendEmail(idsToUpdate, getAccessTokenSilently);

      const result = response.data;
      if (result.isSuccess) {
        if (idsToUpdate.length <= INVITE_EMAILS_MAX_NUM) {
          for (const id of idsToUpdate) {
            dispatch({
              type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
              id,
              field: 'status',
              value: ACCOUNT_STATUSES.INVITED,
            });
          }
          dispatch({ type: dispatchTypes.BUSINESS.BULK.INVITE_SUCCESS });
          toast.success(t('bulkInvitedSync'), { id: 'toastid' });
        } else {
          toast.success(t('bulkInvitedAsync'), { id: 'toastid' });
        }
      } else {
        dispatch({ type: dispatchTypes.BUSINESS.BULK.INVITE_ERROR });
        toast.error(t('error.general'), { id: 'toastid' });
      }
    } catch (error) {
      console.error(error);
      toast.error(t('error.general'), { id: 'toastid' });
    }
  };

export const bulkUploadImage =
  (
    ids: readonly string[],
    usernames: readonly string[],
    file: File,
    type: 'banner' | 'logoHeader',
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      const username = usernames[index];
      try {
        const url = await doS3UploadForBusiness(
          `${type}/${username}`,
          file,
          id,
          getAccessTokenSilently,
          type,
        );

        if (url) {
          await updateImageUrl(id, url, type, getAccessTokenSilently)
            .then(res => {
              if (res.data.isSuccess) {
                dispatch({
                  type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
                  id,
                  field: type === 'banner' ? 'bannerImageUrl' : 'logoHeaderUrl',
                  value: url,
                });
              }
            })
            .catch(error =>
              dispatch({
                type: dispatchTypes.EDITEMPLOYEE.UPLOADFILEERROR,
                apiResponse: error,
              }),
            );
        }
      } catch (error) {
        dispatch({
          type: dispatchTypes.EDITEMPLOYEE.UPLOADFILEERROR,
          apiResponse: error,
        });
        toast.error(i18n.t('error.uploadingImage'));
      }
    }

    // track bulk profiles banner update
    type === 'banner' &&
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.BANNER,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
  };

export const bulkUpdateImage =
  (
    ids: readonly string[],
    imgUrl: readonly string[],
    type: 'banner' | 'logoHeader',
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    for (const id of ids) {
      try {
        await updateImageUrl(id, imgUrl, type, getAccessTokenSilently)
          .then(res => {
            if (res.data.isSuccess) {
              dispatch({
                type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
                id,
                field: `${type}ImageUrl`,
                value: imgUrl,
              });
            }
          })
          .catch(error =>
            dispatch({
              type: dispatchTypes.EDITEMPLOYEE.UPDATEIMGURLERROR,
              apiResponse: error,
            }),
          );
      } catch (error) {
        dispatch({
          type: dispatchTypes.EDITEMPLOYEE.UPDATEIMGURLERROR,
          apiResponse: error,
        });
        toast.error(i18n.t('error.uploadingImage'));
      }
    }

    // track bulk profiles banner update
    type === 'banner' &&
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.BANNER,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
  };

export const bulkUpdateInfo =
  (
    ids: readonly string[],
    toUpdateInfo: InputFormSaveProps,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    let response = await _bulkUpdateInfo(ids, toUpdateInfo, getAccessTokenSilently);
    const { isSuccess } = response.data;
    if (isSuccess) {
      for (const id of ids) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELDS,
          id,
          fields: Object.keys(toUpdateInfo),
          values: Object.values(toUpdateInfo),
        });
      }

      // track bulk profiles bio update
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.BIO,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
    }
    return isSuccess;
  };

export const bulkUpdateLinks =
  (
    ids: readonly string[],
    toUpdateLinks: Array<Link>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    let response = await _bulkUpdateLinks(ids, toUpdateLinks, getAccessTokenSilently);
    const { isSuccess, data } = response.data;
    if (isSuccess) {
      for (const { id, links } of data) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
          id,
          field: 'links',
          value: links,
        });
      }
      // track bulk profiles link update/add
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.LINK_ADD,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
    }
    return isSuccess;
  };

export const bulkDeleteLinks =
  (
    ids: readonly string[],
    toDeleteLinks: Array<Link>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    let response = await _bulkDeleteLinks(ids, toDeleteLinks, getAccessTokenSilently);
    const { isSuccess, data } = response.data;
    if (isSuccess) {
      for (const { id, links } of data) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
          id,
          field: 'links',
          value: links,
        });
      }
      // track bulk profiles link delete
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.LINK_DELETE,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
    }
    return isSuccess;
  };

export const bulkUpdateFiles =
  (
    ids: readonly string[],
    usernames: readonly string[],
    toUpdateFiles: Array<BulkFile>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    for (const file of toUpdateFiles) {
      if (file.uploadedFile instanceof File) {
        try {
          for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            const username = usernames[index];
            const { uploadedFile, fileName, pageCount, link } = file;
            const url = link
              ? link
              : await doS3UploadForBusiness(
                  `file/${username}`,
                  uploadedFile,
                  id,
                  getAccessTokenSilently,
                );

            if (url) {
              const uploadResponse = await saveFile(
                id,
                fileName,
                url,
                pageCount,
                getAccessTokenSilently,
              );

              if (uploadResponse.data.isSuccess) {
                const { data: files } = uploadResponse.data;
                dispatch({
                  type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
                  id,
                  field: 'files',
                  value: files,
                });
              }
            }
          }

          // track bulk profiles file add
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
            {
              type: PRIVATE_META_TYPES.FILE_ADD,
              editedAccountIds: [...ids],
            },
            null,
            null,
            true,
          );
        } catch (err) {
          toast.error(i18n.t('error.uploadFile'));
        }
      } else if (file.extendToAll) {
        const response = await _bulkExtendFiles(ids, file, getAccessTokenSilently);
        const { isSuccess, data } = response.data;
        if (isSuccess) {
          for (const { id, files } of data) {
            dispatch({
              type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
              id,
              field: 'files',
              value: files,
            });
          }

          // track bulk profiles file add
          trackPrivateEvent(
            getAccessTokenSilently,
            PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
            {
              type: PRIVATE_META_TYPES.FILE_ADD,
              editedAccountIds: [...ids],
            },
            null,
            null,
            true,
          );
        }
      }
    }
  };

export const bulkDeleteFiles =
  (
    ids: readonly string[],
    toDeleteFiles: Array<BulkFile>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    let response = await _bulkDeleteFiles(ids, toDeleteFiles, getAccessTokenSilently);
    const { isSuccess, data } = response.data;
    if (isSuccess) {
      for (const { id, files } of data) {
        dispatch({
          type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
          id,
          field: 'files',
          value: files,
        });
      }
      // track bulk profiles file delete
      trackPrivateEvent(
        getAccessTokenSilently,
        PRIVATE_EVENTS.PROFILE_EDIT_MD_BULK,
        {
          type: PRIVATE_META_TYPES.FILE_DELETE,
          editedAccountIds: [...ids],
        },
        null,
        null,
        true,
      );
    }
    return isSuccess;
  };

export const bulkUpdateFileTemplates =
  (
    ids: readonly string[],
    toAddFiles: Array<ApiFile>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) =>
  async dispatch => {
    for (let index = 0; index < ids.length; index++) {
      const employeeId = ids[index];
      for (const { fileName, pageCount, link, id } of toAddFiles) {
        try {
          const uploadResponse = await saveFile(
            employeeId,
            fileName,
            link,
            pageCount,
            getAccessTokenSilently,
            id,
          );
          if (uploadResponse.data.isSuccess) {
            const { data: files } = uploadResponse.data;
            dispatch({
              type: dispatchTypes.BUSINESS.COMMON.UPDATEFIELD,
              id,
              field: 'files',
              value: files,
            });
          }
        } catch (err) {
          console.error(err);
          toast.error(t('error.general'));
        }
      }
    }
  };

export const bulkUpdateCombined =
  (
    ids: readonly string[],
    usernames: readonly string[],
    toUpdateBanner: {
      bannerImageFile: File;
      bannerImageUrl: readonly string[];
    },
    toUpdateLogo: {
      logoHeaderFile: File;
    },
    toUpdateInfo: InputFormSaveProps,
    toUpdateLinks: Array<Link>,
    toDeleteLinks: Array<Link>,
    toUpdateFiles: Array<BulkFile>,
    toDeleteFiles: Array<BulkFile>,
    toUpdateTemplateFiles: Array<ApiFile>,
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
    // t: TFunction,
    hideSuccessToast?: boolean,
  ) =>
  async dispatch => {
    dispatch({ type: dispatchTypes.BUSINESS.SHOWLOADER });

    const { bannerImageFile, bannerImageUrl } = toUpdateBanner;
    const { logoHeaderFile } = toUpdateLogo;

    let updateInfo,
      updateLinks,
      deleteLinks,
      updateBanners,
      updateFiles,
      deleteFiles,
      updateTemplateFiles,
      updateLogos;

    updateInfo =
      updateLinks =
      deleteLinks =
      updateBanners =
      updateFiles =
      deleteFiles =
      updateTemplateFiles =
      updateLogos =
        true;

    if (!(bannerImageFile || bannerImageUrl)) updateBanners = false;
    if (!logoHeaderFile) updateLogos = false;
    if (Object.keys(toUpdateInfo).length === 0) updateInfo = false;
    if (!toUpdateLinks.length) updateLinks = false;
    if (!toDeleteLinks.length) deleteLinks = false;
    if (!toUpdateFiles.length) updateFiles = false;
    if (!toDeleteFiles.length) deleteFiles = false;
    if (!toUpdateTemplateFiles.length) updateTemplateFiles = false;

    try {
      // Will update this logic later, to make it consume less requests
      if (updateBanners) {
        if (bannerImageFile)
          await dispatch(
            bulkUploadImage(ids, usernames, bannerImageFile, 'banner', getAccessTokenSilently),
          );

        if (bannerImageUrl && !bannerImageFile)
          await dispatch(bulkUpdateImage(ids, bannerImageUrl, 'banner', getAccessTokenSilently));
      }

      if (updateLogos) {
        if (logoHeaderFile)
          await dispatch(
            bulkUploadImage(ids, usernames, logoHeaderFile, 'logoHeader', getAccessTokenSilently),
          );
      }

      let isInfoUpdated =
        updateInfo && (await dispatch(bulkUpdateInfo(ids, toUpdateInfo, getAccessTokenSilently)));

      let isLinksDeleted =
        deleteLinks &&
        (await dispatch(bulkDeleteLinks(ids, toDeleteLinks, getAccessTokenSilently)));

      let isLinksUpdated =
        updateLinks &&
        (await dispatch(bulkUpdateLinks(ids, toUpdateLinks, getAccessTokenSilently)));

      deleteFiles && (await dispatch(bulkDeleteFiles(ids, toDeleteFiles, getAccessTokenSilently)));

      if (updateFiles)
        await dispatch(bulkUpdateFiles(ids, usernames, toUpdateFiles, getAccessTokenSilently));

      if (updateTemplateFiles)
        await dispatch(bulkUpdateFileTemplates(ids, toUpdateTemplateFiles, getAccessTokenSilently));

      // Failed updating info
      if (updateInfo && !isInfoUpdated) {
        dispatch({
          type: dispatchTypes.BUSINESS.BULK.UPDATE_ERROR,
          apiResponse: { isSuccess: false },
        });
        toast.error(i18n.t('error.updateAccounts'));
      }
      // Failed updating links
      else if (updateLinks && !isLinksUpdated) {
        dispatch({
          type: dispatchTypes.BUSINESS.BULK.UPDATE_ERROR,
          apiResponse: { isSuccess: false },
        });
        toast.error(i18n.t('error.updateAccounts'));
      } else if (deleteLinks && !isLinksDeleted) {
        dispatch({
          type: dispatchTypes.BUSINESS.BULK.UPDATE_ERROR,
          apiResponse: { isSuccess: false },
        });
        toast.error(i18n.t('error.updateAccounts'));
      } else {
        dispatch({
          type: dispatchTypes.BUSINESS.BULK.UPDATE_SUCCESS,
          apiResponse: { isSuccess: true },
        });
        if (!hideSuccessToast) toast.success(i18n.t('bulkUpdated'));
      }
    } catch (error) {
      toast.error(i18n.t('error.general'));
    }
  };
