import styled from 'styled-components';
import SectionHeading from '../section-heading';
import { useState } from 'react';
import OrganisationName from './name';
import Logos from './logos';
import Colors from './colors';
import Buttons from '../buttons';
import { useAppDispatch, useAppSelector } from '@/application/hooks';
import { Account, ThemeInternal } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import toast from 'react-hot-toast';
import { updateThemeInternal } from '@/application/actions/md/customization';
import { useAuth0 } from '@auth0/auth0-react';
import { UpdateInternalDesignProps } from '@/infrastructure/apis/md/customization';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { Temporary } from '@/application/reducers/temporary';
import withNav from '@/infrastructure/hoc/withNav';
import ViewsContainer from '@/views/components/common/containers/ViewsContainer';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useTranslation } from 'react-i18next';
import FeatureTeaser from '../../teaser/featureTeaser';

const Organisation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureAllowed } = useTierInfo();
  const isInternalDesignAllowed = isFeatureAllowed('internalDesign');
  const account: Account = useAppSelector(state => state.account);
  const themeInternal = useAppSelector<ThemeInternal>(state => state.account.theme.themeInternal);
  const temporary = useAppSelector<Temporary>(state => state.temporary);

  const isLoading = useAppSelector(state => state.md.isLoading);

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();

  const { logoWideUrl, logoWideBrightUrl } = themeInternal;

  const [niceName, setNiceName] = useState(themeInternal.niceName);
  const nameProps = { niceName, setNiceName: withUnsavedSetter(setNiceName) };

  const primaryColor = temporary?.tempPrimaryButtonColor || themeInternal?.primaryColor;
  const sidebarColor = temporary?.tempSidebarColor || themeInternal?.sidebarColor;
  const sidebarColorText = temporary?.tempSidebarColorText || themeInternal?.sidebarColorText;
  const sidebarColorActive = temporary?.tempSidebarColorActive || themeInternal?.sidebarColorActive;
  const sidebarColorActiveText =
    temporary?.tempSidebarColorActiveText || themeInternal?.sidebarColorActiveText;
  const chatColor = temporary?.tempChatColor || themeInternal?.chatColor;

  useDeferredLoader(isLoading, 'organisations-initial-loader-id');

  const colorProps = {
    primaryColor,
    sidebarColor,
    sidebarColorText,
    sidebarColorActive,
    sidebarColorActiveText,
    chatColor,
  };

  const [logoWide, setLogoWide] = useState(logoWideUrl);
  const [logoWideBright, setLogoWideBright] = useState(logoWideBrightUrl);
  const [logoWideInvert, setLogoWideInvert] = useState(themeInternal.logoWideInvert);

  const logoProps = {
    logoWide,
    logoWideBright,
    logoWideInvert,
    primaryColor,
    setLogoWide: withUnsavedSetter(setLogoWide),
    setLogoWideBright: withUnsavedSetter(setLogoWideBright),
    setLogoWideInvert: withUnsavedSetter(setLogoWideInvert),
  };

  const handleSave = () => {
    if (!niceName) return toast.error(t('organisation.nameRequired'));

    const isNameChanged = niceName !== themeInternal.niceName;

    const ids = { id: account.id, uniqueId: themeInternal.uniqueId };
    const flags = { isNameChanged, isInternalDesignAllowed };

    // map to field names
    const internalDesign: UpdateInternalDesignProps = {
      logoWideInvert,
      logoWideUrl: logoWide,
      logoWideBrightUrl: logoWideBright,
      primaryColor,
      sidebarColor: temporary?.tempSidebarColor || themeInternal?.sidebarColor,
      sidebarColorText: temporary?.tempSidebarColorText || themeInternal?.sidebarColorText,
      sidebarColorActive: temporary?.tempSidebarColorActive || themeInternal?.sidebarColorActive,
      sidebarColorActiveText:
        temporary?.tempSidebarColorActiveText || themeInternal?.sidebarColorActiveText,
      chatColor: temporary?.tempChatColor || themeInternal?.chatColor,
    };

    toast.loading(t('Loading'), { id: 'organisations-loader-id' });
    dispatch(
      updateThemeInternal(
        ids,
        flags,
        niceName,
        internalDesign,
        getAccessTokenSilently,
        (message?: string) => {
          message
            ? toast.success(t(message), { id: 'organisations-loader-id' })
            : toast.success(t('changesSaved'), { id: 'organisations-loader-id' });
          setIsUnsaved(false);
        },
        (message?: string) =>
          message
            ? toast.error(t(message), { id: 'organisations-loader-id' })
            : toast.error(t('error.general'), { id: 'organisations-loader-id' }),
      ),
    );
  };

  let sectionIdx = 1;
  return (
    <ViewsContainer>
      <SectionHeading
        headerText={sectionIdx++ + '. ' + t('organisation.name')}
        tooltipText={t('organisation.nameTooltip')}
      />
      <OrganisationName {...nameProps} />

      <SectionHeading
        headerText={sectionIdx++ + '. ' + t('organisation.internalDesign')}
        tooltipText={t('organisation.internalDesignTooltip')}
      />
      {!isInternalDesignAllowed && (
        <FeatureTeaser text={t('upgradeTeaser.feature.internalDesign')} />
      )}
      <InternalDesignContainer>
        <InternalDesignOverlay hide={isInternalDesignAllowed} />
        <Logos {...logoProps} />
        <Colors {...colorProps} />
      </InternalDesignContainer>
      <CustomHeightElement />
      <Buttons onSave={handleSave} position='sticky' primaryColor={primaryColor} />
    </ViewsContainer>
  );
};

export default withNav(
  Organisation,
  {
    tTitle: 'organisationPageHeader',
  },
  {},
);

const InternalDesignContainer = styled.div`
  position: relative;
`;

const InternalDesignOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;

  display: ${props => props.hide && 'none'};
`;

const CustomHeightElement = styled.div`
  display: block;
  min-height: 35vh;
`;
