import styled from 'styled-components';
import { invertHex, lightenHex } from '@/infrastructure/helper';
import { CSS_VARS } from '@/infrastructure/constants';

export const WhitespaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  padding-bottom: 1rem;
  /* width: 85vw; */
  /* height: 100vh; */

  /* gap property alternative */
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderInteractions = styled.div`
  display: inherit;
  align-items: center;
  position: absolute;
  top: 2vw;
  right: 2vw;
  display: flex;
  flex-direction: row;
`;

export const PageHeader = styled.h3`
  color: #252525;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const PageSubheader = styled.p`
  color: #666666;
  font-size: 1.4rem;
  font-weight: 500;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 80rem;

  /* gap property alternative */
  & > *:not(:last-child) {
    margin-bottom: 0.5vw;
  }
`;

// MERGE styles/index.tsx

export const SettingsContainer = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  background: #fff;
  z-index: 10;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  box-sizing: border-box;
  animation: animationDropLeft 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const HeaderName = styled.h5`
  color: #252525;
  font-weight: 600;
  padding-left: 10px;
`;

export const SubsectionHeader = styled.h6`
  font-size: small;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 5px;
  display: flex;
  align-items: center;
`;

export const SubsectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Lt2ltProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  .left {
    flex: 1;

    img {
      width: 8rem;
      height: 8rem;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .right {
    flex: 3;
    span {
      display: block;
      font-size: 1.4rem;
      &.name {
        font-size: 1.8rem;
        font-weight: 600;
        color: #181a5a;
      }
      &.position {
      }
      &.company {
        font-style: italic;
      }
    }
  }
`;

export const InteractionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${props => props.marginTop ?? '25px'};
`;

export const RowContactProfileImage = styled.div`
  position: relative;
  cursor: ${props => (props.pointer ? 'pointer' : 'auto')};
  background-image: url(${props => props.image});
  border-radius: 15px;
  height: ${p => (p.screen === 'sidebar' ? '7rem' : '4rem')};
  min-width: ${p => (p.screen === 'sidebar' ? '10rem' : '7rem')};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-size: ${props => props.sizeCover};
  background-position: center;
  background-repeat: no-repeat;
  ${p => (p.withBackground ? `background-color: #eff3fd;` : '')}
  ${p =>
    p.circle
      ? `
  background-size: contain;
  clip-path: circle(2.5rem at 50% 50%);
`
      : ''}
`;

export const RowEditIcon = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  cursor: pointer;
  transform: translateX(-1%);
  /** TODO:: the best practice here is to pass the global css var directly to lightenHex helper but the helper is not reading it properly like other helpers  */
  /** for now we are passing the global css var as a color prop to pass it then to the helper */
  background: ${props => lightenHex(props.color, 30)};
`;

export const RowProfileImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 5rem;
  max-height: 5rem;

  > img {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
  }

  img.open-profile {
    cursor: pointer;
  }
`;

export const LTIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 5rem;
  transform: translateY(-3rem);
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.5) 0, transparent 100%);
  img {
    width: 2rem;
    height: 2rem;
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 20%;
  border-radius: 50%;
  border: 1px solid #181a5a;
  color: #181a5a;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`;
export const ProfileInfoWrapper = styled.div`
  min-width: 50%;
  margin-left: 1.5rem;
`;

export const ProfileName = styled.h5`
  font-size: ${p => (p.screen === 'sidebar' ? '1.7rem' : '1.6rem')};
  font-weight: 500;
`;
export const ProfileCompany = styled.h6`
  word-wrap: break-word;
`;

export const Form = styled.form``;

export const CaptureImage = styled.img`
  height: 22px;
  width: 22px;
  align-self: center;
`;

export const CameraCaptureWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #dadada;
  display: flex;
  justify-content: center;
  align-self: end;
  left: -30px;
  bottom: -10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
`;

export const CapturedImage = styled.img`
  width: 186px;
  height: 114px;
  border-radius: 7px;
  object-fit: contain;
`;

export const CameraWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px dashed #c1cbdc;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding: 20px;
  align-self: center;
  margin: 14px;

  cursor: pointer;
  // as long as not enabled
  /* pointer-events: none;
  opacity: 0.4; */
`;

export const CameraIconWrapper = styled.img`
  height: 16px;
`;

export const CameraBox = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-left: 5px;
  color: #4748ec;
`;

export const ActionBtn = styled.a`
  background-color: ${p => (p.transparent ? 'transparent' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_VAR)};
  padding: 0.5rem 2rem;
  color: ${p => (p.warning ? 'red' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR)};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: small;
  border-radius: 5px;
  &:hover,
  &:active,
  &:focus {
    color: ${p => (p.warning ? 'red' : CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_VAR)};
  }
`;

export const BodyWrapper = styled.div`
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
`;

export const DeleteItem = styled.div`
  cursor: pointer;
  text-align: center;
  margin: 7rem auto;
  color: red;
`;
type RowWrapperProps = {
  bgColor?: string;
  direction?: 'column' | 'row';
};

export const RowWrapper = styled.div`
  background-color: ${(props: RowWrapperProps) => props.bgColor ?? '#f6f8ff'};
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  z-index: 10;
  flex-direction: ${(props: RowWrapperProps) => props.direction ?? 'row'};
  justify-content: space-evenly;
  align-items: ${(props: RowWrapperProps) => (props.direction === 'column' ? 'stretch' : 'center')};
`;

export const InputsFormWrapper = styled.div`
  border-radius: 10px;
  position: absolute;
  width: 100% !important;
  padding: 0 10px;
  top: unset !important;
  left: 50%;
  transform: translateX(-50%);
`;

export const AddressBlock = styled.div`
  display: flex;
  background: #ffffff;
  margin-top: 20px;
  padding: 10px 20px 20px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 30px;

  & > div:first-child {
    width: 15%;
    margin: auto;
  }

  & > div:last-child {
    width: 85%;
  }
`;

// POSTCODE | CITY
export const AddressRowContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
`;

export const BtnB = styled.input`
  background: ${p => (p.disabled ? 'gray' : p.color)};
  color: ${p => invertHex(p.color, true)};
  transition: 1s ease-in-out;
  height: 50px;
  box-sizing: border-box;
  border: none;
  border-radius: 30px;
  width: 40%;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

export const BtnB2 = styled.input`
  background: transparent;
  border-radius: 30px;
  height: 50px;
  box-sizing: border-box;
  width: 40%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid #bbbbc1;
  color: #666666;
`;

export const Spacer = styled.div`
  min-width: ${(props: { size: number }) => props.size}px;
  min-height: ${(props: { size: number }) => props.size}px;
`;
