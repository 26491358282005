import { LtDialog } from '@/components';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { getProfileImageUrl } from '@/shared/business-logic/features/profile/getter';
import { Employee } from '@/shared/types/api/employee.type';
import { getMainUnitSync } from '@/shared/util';
import {
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: (id: string, dontShowAgain?: boolean) => void;
  employee: Employee;
  isSaving: boolean;
  showConfirmationDialog?: boolean;
};

export const SingleContactCard = ({
  onClick,
  employee,
  showConfirmationDialog,
  isSaving,
}: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dontShowAgainIsChecked, setDontShowAgainIsChecked] = useState(false);

  const mainUnit = getMainUnitSync(employee);
  const { profileDesign } = useProfileDesignForUnits(mainUnit?.id || null);
  const profileImageUrl = getProfileImageUrl(employee, profileDesign);

  const { t } = useTranslation();

  const fullName = `${employee.firstName || ''} ${employee.lastName || ''}`;
  const initials = fullName
    .split(' ')
    .filter(Boolean)
    .map(name => name[0])
    .join('')
    .toUpperCase();

  const handleCardClick = () => {
    if (showConfirmationDialog) {
      setDialogOpened(true);
    } else {
      onClick(employee.id);
    }
  };

  const subtitle = [employee.position, employee.company, employee.email].filter(Boolean).join('\n');
  return (
    <>
      <Card variant='elevation'>
        <CardActionArea onClick={handleCardClick}>
          <CardHeader
            avatar={<Avatar src={profileImageUrl}>{initials}</Avatar>}
            title={fullName}
            titleTypographyProps={{ lineHeight: 2 }}
            subheader={subtitle}
            subheaderTypographyProps={{ lineHeight: 2, whiteSpace: 'pre-line' }}
          />
        </CardActionArea>
      </Card>
      <LtDialog
        onClose={() => setDialogOpened(false)}
        open={dialogOpened}
        title={t('linkCode.connectToNfc')}
        onCancel={() => setDialogOpened(false)}
        onConfirm={() => onClick(employee.id, dontShowAgainIsChecked)}
        loading={isSaving}
        withActionDivider
      >
        <Typography variant='body1'>{t('linkCode.confirmLinkText')}</Typography>
        <FormControlLabel
          onChange={() => setDontShowAgainIsChecked(!dontShowAgainIsChecked)}
          control={<Checkbox checked={dontShowAgainIsChecked} />}
          label={t('linkCode.dontShowAgain')}
        />
      </LtDialog>
    </>
  );
};
