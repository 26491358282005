import { Dispatch, SetStateAction, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import { addEmployee } from '../../../../application/actions/md/profiles';
import SettingsHeader from '../../settings/header';
import { SettingsContainer, HeaderWrapper, HeaderName, InputsFormWrapper } from '../common';
import { Loader } from '../../../components/common';
import toast from 'react-hot-toast';
import InputsForm, { InputFormSaveProps } from './common/inputs-form';
import ProfilePicture from './common/images/profile-picture';
import Banner from './common/images/banner';
import LogoHeader from './common/images/logo-header';
import { Theme } from '../../../../shared/types/api';
import { UnitSelector } from './unitSelector';
import { Unit } from '../../../../shared/types/api/unit.type';
import { Overlay } from '../../../components/common/overlay';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import { FEATURE } from '../../../../shared/constants';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { getFullName } from '@/shared/business-logic/features/profile/getter';

interface Props {
  onBackClick: () => void;
  setAddEmployee: Dispatch<SetStateAction<any>>;
  showLicensesWarning: () => void;
}

const AddEmployee = (props: Props) => {
  const { isFeatureAllowed } = useTierInfo();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const { setIsUnsaved } = useUnsavedStatusSetter();

  const { employees } = useAppSelector(state => state.md);
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const isLoading = useAppSelector(state => state.md.isLoading);

  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const { profileDesign } = useProfileDesignForUnits(selectedUnit?.id || null);

  const [namePrefix, setNamePrefix] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const profileImageProps = {
    profileImageUrl: profileImageUrl || profileDesign.defaultProfileImageUrl,
    setProfileImageUrl,
    setProfileImageFile,
  };

  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const bannerImageProps = {
    bannerImgOptions: profileDesign.bannerImgOptions,
    bannerImageUrl: bannerImageUrl || profileDesign.defaultBannerImageUrl,
    setBannerImageUrl,
    setBannerImageFile,
  };

  const [logoHeaderFile, setLogoHeaderFile] = useState(null);
  const [logoHeaderUrl, setLogoHeaderUrl] = useState(null);
  const logoHeaderProps = {
    logoHeaderUrl: logoHeaderUrl || profileDesign.logoHeaderUrl,
    setLogoHeaderUrl,
    setLogoHeaderFile,
  };
  const onSuccess = () => {
    toast.success(t('createdSuccessMD'));
    setIsUnsaved(false);
    props.onBackClick();
  };

  const onFailed = msg => toast.error(msg);

  const handleSave = async (inputs: InputFormSaveProps) => {
    let images = {
      profileImageFile,
      bannerImageFile,
      bannerImageUrl,
      logoHeaderFile,
    };
    let createdAccount = {
      ...inputs,
      ...images,
      unit: selectedUnit,
    };
    if (employees.length + 1 > (theme?.themeInternal.amountLicences || 0)) {
      props.setAddEmployee({ ...createdAccount, unit: selectedUnit });
      props.showLicensesWarning();
    } else {
      dispatch(
        addEmployee(
          { ...createdAccount, unit: selectedUnit },
          getAccessTokenSilently,
          () => onSuccess(),
          (msg: string) => onFailed(t(msg)),
        ),
      );
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Overlay />
      <SettingsContainer>
        <SettingsHeader headerText={t('createProfile')} onBackClick={props.onBackClick} />
        <InputsFormWrapper>
          <HeaderWrapper>
            <ProfilePicture {...profileImageProps} />
            <HeaderName> {getFullName({ namePrefix, firstName, lastName })}</HeaderName>
          </HeaderWrapper>
          <hr />
          <Banner {...bannerImageProps} />
          <hr />
          <LogoHeader notEditable={!!profileDesign.logoHeaderUrl} {...logoHeaderProps} />
          <hr />
          {isFeatureAllowed(FEATURE.UNITS) && (
            <UnitSelector onChangeUnit={(unit: Unit) => setSelectedUnit(unit)} />
          )}
          <InputsForm
            namePrefix={namePrefix}
            setNamePrefix={setNamePrefix}
            firstName={firstName}
            lastName={lastName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            onBackClick={props.onBackClick}
            handleSave={handleSave}
          />
        </InputsFormWrapper>
      </SettingsContainer>
    </>
  );
};

export default AddEmployee;
