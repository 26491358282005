import { Box, Divider, Typography } from '@mui/material';
import { isAddress1Field } from '../utils';
import useBlockedFields from '@/infrastructure/hooks/useBlockedFields';
import { Account } from '@/shared/types/api';
import { ChangeEvent } from 'react';
import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import BioTextField from './BioTextField';

const AddressWrapper = ({ children }) => {
  return (
    <Box display={'flex'} width={'100%'} flexWrap={'wrap'} gap={1} justifyContent={'center'}>
      {children}
    </Box>
  );
};
const AddressRenderer = ({
  addressFields,
  bioForm,
  onInputChange,
}: {
  addressFields: Array<keyof Account>;
  bioForm: Account;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { disableFields, checkDisabledField } = useBlockedFields();
  const { t } = useAppTranslation();

  const account = useAppSelector<Account>(state => state.account);

  const isDisabled = (field: keyof Account) =>
    checkDisabledField(field, [
      disableFields,
      ...[
        field.startsWith('address1')
          ? !!account?.addressTemplate1Id
          : field.startsWith('address2')
          ? !!account?.addressTemplate2Id
          : null,
      ],
    ]);

  return (
    <>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant='h3' color='text.secondary' mb={2}>
        {isAddress1Field(addressFields[0]) ? t('address1') : t('address2')}
      </Typography>
      <AddressWrapper>
        {addressFields.map(field => (
          <BioTextField
            field={field}
            value={bioForm[field]}
            disabled={isDisabled(field)}
            onChange={onInputChange}
          />
        ))}
      </AddressWrapper>
    </>
  );
};

export default AddressRenderer;
