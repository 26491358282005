export const editorLocales = {
  en: {
    'Full width on mobile': 'Full width on mobile',
    undo: 'undo',
    redo: 'redo',
    'Font family': 'Font family',
    'Import font': 'Import font',
    'Points to a hosted css file': 'Points to a hosted css file',
    attributes: 'attributes',
    'Email Setting': 'Email Setting',
    Subject: 'Subject',
    SubTitle: 'SubTitle',
    Width: 'Width',
    Breakpoint: 'Breakpoint',
    'Allows you to control on which breakpoint the layout should go desktop/mobile.':
      'Allows you to control on which breakpoint the layout should go desktop/mobile.',
    'Theme Setting': 'Theme Setting',
    'Font weight': 'Font weight',
    'Text color': 'Text color',
    Background: 'Background',
    'Content background': 'Content background',
    'User style': 'User style',
    Padding: 'Padding',
    left: 'left',
    center: 'center',
    right: 'right',
    Align: 'Align',
    'Text align': 'Text align',
    'No matching components': 'No matching components',
    'Font size': 'Font size',
    'Insert before': 'Insert before',
    'Insert after': 'Insert after',
    'Drag here': 'Drag here',
    Configuration: 'Configuration',
    'Source code': 'Source code',
    Block: 'Block',
    Layer: 'Layer',
    'Invalid content': 'Invalid content',
    'Json source': 'Json source',
    'MJML source': 'MJML source',
    'Drag to': 'Drag to',
    'Move up': 'Move up',
    'Move down': 'Move down',
    Copy: 'Copy',
    Delete: 'Delete',
    'Background color': 'Background color',
    'No selectionRange': 'No selectionRange',
    'Not commonAncestorContainer': 'Not commonAncestorContainer',
    'Align left': 'Align left',
    'Align center': 'Align center',
    'Align right': 'Align right',
    Orderlist: 'Orderlist',
    Unorderlist: 'Unorderlist',
    Line: 'Line',
    'Remove format': 'Remove format',
    Underline: 'Underline',
    Unlink: 'Unlink',
    'Merge tag': 'Insert Variable',
    Paragraph: 'Paragraph',
    Italic: 'Italic',
    Link: 'Link',
    Apply: 'Apply',
    'https://www.example.com': 'https://www.example.com',
    Target: 'Target',
    blank: 'blank',
    self: 'self',
    off: 'off',
    on: 'on',
    Bold: 'Bold',
    'Select parent block': 'Select parent block',
    'Add to collection': 'Add to collection',
    Strikethrough: 'Strikethrough',
    'Uploading...': 'Uploading...',
    'Upload failed': 'Upload failed',
    Preview: 'Preview',
    Remove: 'Remove',
    Name: 'Name',
    Href: 'URL',
    Item: 'Item',
    'Picker...': 'Picker...',
    Tab: 'Tab',
    Title: 'Title',
    'Title required!': 'Title required!',
    Description: 'Description',
    Thumbnail: 'Thumbnail',
    'Thumbnail required!': 'Thumbnail required!',
    Layout: 'Layout',
    Content: 'Content',
    '2 columns': '2 columns',
    '3 columns': '3 columns',
    '4 columns': '4 columns',
    'Default value': 'Default value',
    'If a personalized text value isn\\"t available, then a default value is shown.':
      'If a personalized text value isn\\"t available, then a default value is shown.',
    Save: 'Save',
    Text: 'Text',
    'This block allows you to display text in your email':
      'This block allows you to display text in your email',
    Image: 'Image',
    "Displays a responsive image in your email. It is similar to the HTML '&lt;img/&gt;' tag. Note that if no width is provided, the image will use the parent column width.":
      "Displays a responsive image in your email. It is similar to the HTML '&lt;img/&gt;' tag. Note that if no width is provided, the image will use the parent column width.",
    Button: 'Button',
    'Displays a customizable button.': 'Displays a customizable button.',
    Hero: 'Hero',
    "This block displays a hero image. It behaves like an 'section' with a single 'column'.":
      "This block displays a hero image. It behaves like an 'section' with a single 'column'.",
    Navbar: 'Navbar',
    'Displays a menu for navigation with an optional hamburger mode for mobile devices.':
      'Displays a menu for navigation with an optional hamburger mode for mobile devices.',
    Spacer: 'Spacer',
    'Displays a blank space.': 'Displays a blank space.',
    Divider: 'Divider',
    'Displays a horizontal divider that can be customized like a HTML border.':
      'Displays a horizontal divider that can be customized like a HTML border.',
    Accordion: 'Accordion',
    'Accordion is an interactive component to stack content in tabs, so the information is collapsed and only the titles are visible. Readers can interact by clicking on the tabs to reveal the content, providing a great experience on mobile devices where space is scarce.':
      'Accordion is an interactive component to stack content in tabs, so the information is collapsed and only the titles are visible. Readers can interact by clicking on the tabs to reveal the content, providing a great experience on mobile devices where space is scarce.',
    Carousel: 'Carousel',
    "This block displays a gallery of images or 'carousel'. Readers can interact by hovering and clicking on thumbnails depending on the email client they use.":
      "This block displays a gallery of images or 'carousel'. Readers can interact by hovering and clicking on thumbnails depending on the email client they use.",
    Social: 'Social',
    'Displays calls-to-action for various social networks with their associated logo.':
      'Displays calls-to-action for various social networks with their associated logo.',
    Wrapper: 'Wrapper',
    'Wrapper enables to wrap multiple sections together. It"s especially useful to achieve nested layouts with shared border or background images across sections.':
      'Wrapper enables to wrap multiple sections together. It"s especially useful to achieve nested layouts with shared border or background images across sections.',
    Section: 'Section',
    'Sections are intended to be used as rows within your email. They will be used to structure the layout.':
      'Sections are intended to be used as rows within your email. They will be used to structure the layout.',
    'Sections cannot nest in sections. Columns can nest in sections; all content must be in a column.':
      'Sections cannot nest in sections. Columns can nest in sections; all content must be in a column.',
    Group: 'Group',
    'Group allows you to prevent columns from stacking on mobile. To do so, wrap the columns inside a group block, so they"ll stay side by side on mobile.':
      'Group allows you to prevent columns from stacking on mobile. To do so, wrap the columns inside a group block, so they"ll stay side by side on mobile.',
    Column: 'Column',
    'Columns enable you to horizontally organize the content within\n                your sections. They must be located under "Section" block in order\n                to be considered by the engine. To be responsive, columns are\n                expressed in terms of percentage.':
      'Columns enable you to horizontally organize the content within\n                your sections. They must be located under "Section" block in order\n                to be considered by the engine. To be responsive, columns are\n                expressed in terms of percentage.',
    'Every single column has to contain something because they are\n                responsive containers, and will be vertically stacked on a mobile\n                view.':
      'Every single column has to contain something because they are\n                responsive containers, and will be vertically stacked on a mobile\n                view.',
    'Navbar links': 'Navbar links',
    Links: 'Links',
    Extra: 'Extra',
    Color: 'Color',
    'Font size (px)': 'Font size (px)',
    Url: 'Url',
    _blank: '_blank',
    _self: '_self',
    'Line height': 'Line height',
    Dimension: 'Dimension',
    True: 'True',
    False: 'False',
    Border: 'Border',
    'Background border radius': 'Background border radius',
    Left: 'Left',
    Right: 'Right',
    top: 'top',
    middle: 'middle',
    bottom: 'bottom',
    Setting: 'Setting',
    'Icon width': 'Icon width',
    'Icon height': 'Icon height',
    'Unwrapped icon': 'Unwrapped icon',
    'The image suffix should be .jpg, jpeg, png, gif, etc. Otherwise, the picture may not be displayed normally.':
      'The image suffix should be .jpg, jpeg, png, gif, etc. Otherwise, the picture may not be displayed normally.',
    'Wrapped icon': 'Wrapped icon',
    'Icon position': 'Icon position',
    'Icon align': 'Icon align',
    hidden: 'hidden',
    visible: 'visible',
    'Thumbnail width': 'Thumbnail width',
    Thumbnails: 'Thumbnails',
    Images: 'Images',
    Icon: 'Icon',
    'Left icon': 'Left icon',
    'Right icon': 'Right icon',
    'Hovered border': 'Hovered border',
    'Selected Border': 'Selected Border',
    'Border of the thumbnails': 'Border of the thumbnails',
    'Border radius of the thumbnails': 'Border radius of the thumbnails',
    src: 'src',
    title: 'title',
    alt: 'alt',
    'class name': 'class name',
    vertical: 'vertical',
    horizontal: 'horizontal',
    Mode: 'Mode',
    Typography: 'Typography',
    'Social item': 'Social item',
    'Border radius': 'Border radius',
    'Icon padding': 'Icon padding',
    'Text padding': 'Text padding',
    'Inner padding': 'Inner padding',
    'Button color': 'Button color',
    Edit: 'Edit',
    Decoration: 'Decoration',
    'Html mode': 'Html mode',
    'Fluid height': 'Fluid height',
    'Fixed height': 'Fixed height',
    'Background width': 'Background width',
    'Background height': 'Background height',
    'Background position': 'Background position',
    Html: 'Html',
    'Editor Loading...': 'Editor Loading...',
    'Please select': 'Please select',
    'Column inside a group must have a width in percentage, not in pixel':
      'Column inside a group must have a width in percentage, not in pixel',
    None: 'None',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    capitalize: 'capitalize',
    'Text transform': 'Text transform',
    Overline: 'Overline',
    'Line through': 'Line through',
    Blink: 'Blink',
    Inherit: 'Inherit',
    'Text decoration': 'Text decoration',
    ltr: 'ltr',
    rtl: 'rtl',
    Direction: 'Direction',
    Iteration: 'Iteration',
    'Data source': 'Data source',
    'Item name': 'Item name',
    Limit: 'Limit',
    'Mock quantity': 'Mock quantity',
    Normal: 'Normal',
    'Font style': 'Font style',
    'Container background color': 'Container background color',
    'Top (px)': 'Top (px)',
    'Left (px)': 'Left (px)',
    'Bottom (px)': 'Bottom (px)',
    'Right (px)': 'Right (px)',
    'Class name': 'Class name',
    'No repeat': 'No repeat',
    Repeat: 'Repeat',
    'Repeat X': 'Repeat X',
    'Repeat Y': 'Repeat Y',
    'Background image': 'Background image',
    'Background repeat': 'Background repeat',
    'Background size': 'Background size',
    Dashed: 'Dashed',
    Dotted: 'Dotted',
    Solid: 'Solid',
    double: 'double',
    ridge: 'ridge',
    groove: 'groove',
    inset: 'inset',
    outset: 'outset',
    Style: 'Style',
    'Vertical align': 'Vertical align',
    Height: 'Height',
    Center: 'Center',
    Margin: 'Margin',
    Top: 'Top',
    Bottom: 'Bottom',
    Opacity: 'Opacity',
    'At least one condition': 'At least one condition',
    Condition: 'Condition',
    Symbol: 'Symbol',
    And: 'And',
    Or: 'Or',
    'Variable path': 'Variable path',
    Operator: 'Operator',
    'Letter spacing': 'Letter spacing',
    'email-block': 'email-block',
    Page: 'Page',
    Template: 'Template',
    'Accordion element': 'Accordion element',
    'Accordion title': 'Accordion title',
    Table: 'Table',
    'Make it easy for everyone to compose emails!': 'Make it easy for everyone to compose emails!',
    'Accordion text': 'Accordion text',
    Raw: 'Raw',
  },
  de: {
    'Full width on mobile': 'Volle Breite auf Mobilgeräten',
    undo: 'Rückgängig',
    redo: 'Wiederherstellen',
    'Font family': 'Schriftart',
    'Import font': 'Schrift importieren',
    'Points to a hosted css file': 'Verweist auf eine gehostete CSS-Datei',
    attributes: 'Attribute',
    'Email Setting': 'E-Mail-Einstellung',
    Subject: 'Betreff',
    SubTitle: 'Untertitel',
    Width: 'Breite',
    Breakpoint: 'Breakpoint',
    'Allows you to control on which breakpoint the layout should go desktop/mobile.':
      'Ermöglicht es zu steuern, bei welcher Breite das Layout auf Desktop/Mobil umschalten soll.',
    'Theme Setting': 'Theme-Einstellungen',
    'Font weight': 'Schriftstärke',
    'Text color': 'Schriftfarbe',
    Background: 'Hintergrund',
    'Content background': 'Inhaltshintergrund',
    'User style': 'Benutzerstil',
    Padding: 'Padding',
    left: 'links',
    center: 'zentriert',
    right: 'rechts',
    Align: 'Ausrichten',
    'Text align': 'Textausrichtung',
    'No matching components': 'Keine übereinstimmenden Komponenten',
    'Font size': 'Schriftgröße',
    'Insert before': 'Davor Einfügen',
    'Insert after': 'Danach Einfügen',
    'Drag here': 'Hierher Draggen',
    Configuration: 'Konfiguration',
    'Source code': 'Quellcode',
    Block: 'Blöcke',
    Layer: 'Ebenen',
    'Invalid content': 'Ungültiger Inhalt',
    'Json source': 'JSON-Quelle',
    'MJML source': 'MJML-Quelle',
    'Drag to': 'Dragge zu',
    'Move up': 'Nach oben bewegen',
    'Move down': 'Nach unten bewegen',
    Copy: 'Kopieren',
    Delete: 'Löschen',
    'Background color': 'Hintergrundfarbe',
    'No selectionRange': 'Kein Auswahlbereich',
    'Not commonAncestorContainer': 'Kein commonAncestorContainer',
    'Align left': 'Links ausrichten',
    'Align center': 'Zentriert ausrichten',
    'Align right': 'Rechts ausrichten',
    Orderlist: 'Sortierte Liste',
    Unorderlist: 'Unsortierte Liste',
    Line: 'Linie',
    'Remove format': 'Format entfernen',
    Underline: 'Unterstreichen',
    Unlink: 'Verknüpfung entfernen',
    'Merge tag': 'Variable einfügen',
    Paragraph: 'Absatz',
    Italic: 'Kursiv',
    Link: 'Link',
    Apply: 'Anwenden',
    'https://www.example.com': 'https://www.example.com',
    Target: 'Neuer Tab',
    blank: 'Ja',
    self: 'Nein',
    off: 'aus',
    on: 'ein',
    Bold: 'Fett',
    'Select parent block': 'Elternblock auswählen',
    'Add to collection': 'Zur Sammlung hinzufügen',
    Strikethrough: 'Durchgestrichen',
    'Uploading...': 'Lade hoch...',
    'Upload failed': 'Upload fehlgeschlagen',
    Preview: 'Vorschau',
    Remove: 'Entfernen',
    Name: 'Name',
    Href: 'URL',
    Item: 'Element',
    'Picker...': 'Picker...',
    Tab: 'Tab',
    Title: 'Titel',
    'Title required!': 'Titel erforderlich!',
    Description: 'Beschreibung',
    Thumbnail: 'Thumbnail',
    'Thumbnail required!': 'Thumbnail erforderlich!',
    Layout: 'Layout',
    Content: 'Inhalt',
    '2 columns': '2 Spalten',
    '3 columns': '3 Spalten',
    '4 columns': '4 Spalten',
    'Default value': 'Standardwert',
    'If a personalized text value isn"t available, then a default value is shown.':
      'Wenn kein personalisierter Textwert verfügbar ist, wird ein Standardwert angezeigt.',
    Save: 'Speichern',
    Text: 'Text',
    'This block allows you to display text in your email':
      'Dieser Block ermöglicht es Dir, Text in der E-Mail anzuzeigen.',
    Image: 'Bild',
    "Displays a responsive image in your email. It is similar to the HTML '<img/>' tag. Note that if no width is provided, the image will use the parent column width.":
      'Zeigt ein responsives Bild in Deiner E-Mail an. Es ähnelt dem HTML "<img/>"-Tag. Beachte, dass das Bild die Breite des Elternelements verwendet, wenn keine Breite angegeben ist.',
    Button: 'Button',
    'Displays a customizable button.': 'Zeigt einen anpassbaren Button an.',
    Hero: 'Hero',
    "This block displays a hero image. It behaves like an 'section' with a single 'column'.":
      "Dieser Block zeigt ein Hero-Bild an. Es verhält sich wie ein 'Abschnitt' mit einer einzelnen 'Spalte'.",
    Navbar: 'Navigationsleiste',
    'Displays a menu for navigation with an optional hamburger mode for mobile devices.':
      'Zeigt ein Menü zur Navigation mit einem optionalen Hamburger-Modus für Mobilgeräte an.',
    Spacer: 'Abstandshalter',
    'Displays a blank space.': 'Zeigt einen leeren Platzhalter an.',
    Divider: 'Teiler',
    'Displays a horizontal divider that can be customized like a HTML border.':
      'Zeigt einen horizontalen Teiler an, der wie ein HTML-Border angepasst werden kann.',
    Accordion: 'Akkordeon',
    'Accordion is an interactive component to stack content in tabs, so the information is collapsed and only the titles are visible. Readers can interact by clicking on the tabs to reveal the content, providing a great experience on mobile devices where space is scarce.':
      'Ein Akkordion ist ein interaktives Komponente zum Stapeln von Inhalten in Tabs, so dass die Informationen zusammengeklappt sind und nur die Titel sichtbar sind. Leser können durch Klicken auf die Tabs interagieren, um den Inhalt anzuzeigen, was auf mobilen Geräten mit begrenztem Platzangebot eine großartige Erfahrung bietet.',
    Carousel: 'Karussell',
    "This block displays a gallery of images or 'carousel'. Readers can interact by hovering and clicking on thumbnails depending on the email client they use.":
      "Dieser Block zeigt eine Galerie von Bildern oder 'Carousel' an. Leser können durch Schweben und Klicken auf Thumbnails je nach E-Mail-Client interagieren.",
    Social: 'Soziale Medien',
    'Displays calls-to-action for various social networks with their associated logo.':
      'Zeigt Aufforderungen zur Interaktion mit verschiedenen sozialen Netzwerken und deren zugehörige Logos an.',
    Wrapper: 'Wrapper',
    'Wrapper enables to wrap multiple sections together. It"s especially useful to achieve nested layouts with shared border or background images across sections.':
      'Wrapper ermöglicht das Zusammenfassen mehrerer Abschnitte. Es ist besonders nützlich, um verschachtelte Layouts mit gemeinsamen Rahmen oder Hintergrundbildern über Abschnitte hinweg zu erreichen.',
    Section: 'Abschnitt',
    'Sections are intended to be used as rows within your email. They will be used to structure the layout.':
      'Abschnitte sind dafür vorgesehen, als Zeilen in Ihrer E-Mail verwendet zu werden. Sie werden zur Strukturierung des Layouts verwendet.',
    'Sections cannot nest in sections. Columns can nest in sections; all content must be in a column.':
      'Abschnitte können nicht in Abschnitten verschachtelt werden. Spalten können in Abschnitten verschachtelt werden, der gesamte Inhalt muss in einer Spalte sein.',
    Group: 'Gruppe',
    'Group allows you to prevent columns from stacking on mobile. To do so, wrap the columns inside a group block, so they"ll stay side by side on mobile.':
      'Gruppe ermöglicht es Ihnen, das Stapeln von Spalten auf Mobilgeräten zu verhindern. Um dies zu erreichen, wickeln Sie die Spalten innerhalb eines Gruppenblocks ein, damit sie auf Mobilgeräten nebeneinander bleiben.',
    Column: 'Spalte',
    'Columns enable you to horizontally organize the content within\n your sections. They must be located under "Section" block in order\n to be considered by the engine. To be responsive, columns are\n expressed in terms of percentage.':
      'Spalten ermöglichen es Ihnen, den Inhalt innerhalb Ihrer Abschnitte horizontal zu organisieren. Sie müssen sich unter dem "Abschnitt"-Block befinden, um vom System berücksichtigt zu werden. Um ansprechbar zu sein, werden Spalten in Prozent ausgedrückt.',
    'Every single column has to contain something because they are\n responsive containers, and will be vertically stacked on a mobile\n view.':
      'Jede einzelne Spalte muss etwas enthalten, da sie ansprechende Container sind und auf einer mobilen Ansicht vertikal gestapelt werden.',
    'Navbar links': 'Navigationsleisten-Links',
    Links: 'Links',
    Extra: 'Extra',
    Color: 'Farbe',
    'Font size (px)': 'Schriftgröße (px)',
    Url: 'URL',
    _blank: '_blank',
    _self: '_self',
    'Line height': 'Zeilenhöhe',
    Dimension: 'Abmessungen',
    True: 'Wahr',
    False: 'Falsch',
    Border: 'Rahmen',
    'Background border radius': 'Hintergrund-Rahmenradius',
    Left: 'Links',
    Right: 'Rechts',
    top: 'Oben',
    middle: 'Mitte',
    bottom: 'Unten',
    Setting: 'Einstellungen',
    'Icon width': 'Symbolbreite',
    'Icon height': 'Symbolhöhe',
    'Unwrapped icon': 'Entpacktes Symbol',
    'The image suffix should be .jpg, jpeg, png, gif, etc. Otherwise, the picture may not be displayed normally.':
      'Die Bildendung sollte .jpg, .jpeg, .png, .gif usw. sein. Andernfalls kann das Bild möglicherweise nicht normal angezeigt werden.',
    'Wrapped icon': 'Eingepacktes Symbol',
    'Icon position': 'Symbolposition',
    'Icon align': 'Symbolausrichtung',
    hidden: 'versteckt',
    visible: 'sichtbar',
    'Thumbnail width': 'Thumbnail Breite',
    Thumbnails: 'Thumbnails',
    Images: 'Bilder',
    Icon: 'Symbol',
    'Left icon': 'Linkes Symbol',
    'Right icon': 'Rechtes Symbol',
    'Hovered border': 'Rand bei Mouse-Hover',
    'Selected Border': 'Ausgewählter Rand',
    'Border of the thumbnails': 'Rand der Thumbnails',
    'Border radius of the thumbnails': 'Randeckenradius der Thumbnails',
    src: 'src',
    title: 'Titel',
    alt: 'alt',
    'class name': 'Klassenname',
    vertical: 'vertikal',
    horizontal: 'horizontal',
    Mode: 'Modus',
    Typography: 'Typografie',
    'Social item': 'Social Elemente',
    'Border radius': 'Randeckenradius',
    'Icon padding': 'Symbolabstand',
    'Text padding': 'Textabstand',
    'Inner padding': 'Innerer Abstand',
    'Button color': 'Buttonfarbe',
    Edit: 'Bearbeiten',
    Decoration: 'Dekoration',
    'Html mode': 'HTML-Modus',
    'Fluid height': 'Dynamische Höhe',
    'Fixed height': 'Feste Höhe',
    'Background width': 'Hintergrundbreite',
    'Background height': 'Hintergrundhöhe',
    'Background position': 'Hintergrundposition',
    Html: 'HTML',
    'Editor Loading...': 'Editor wird geladen...',
    'Please select': 'Bitte wählen',
    'Column inside a group must have a width in percentage, not in pixel':
      'Eine Spalte innerhalb einer Gruppe muss eine Breite in Prozent haben, nicht in Pixel',
    None: 'Keine',
    uppercase: 'Großbuchstaben',
    lowercase: 'Kleinbuchstaben',
    capitalize: 'Wortanfang groß',
    'Text transform': 'Textumwandlung',
    Overline: 'Überstrichen',
    'Line through': 'Durchgestrichen',
    Blink: 'Blinkend',
    Inherit: 'Vererbt',
    'Text decoration': 'Textdekoration',
    ltr: 'ltr',
    rtl: 'rtl',
    Direction: 'Richtung',
    Iteration: 'Iteration',
    'Data source': 'Datenquelle',
    'Item name': 'Elementname',
    Limit: 'Limit',
    'Mock quantity': 'Musteranzahl',
    Normal: 'Normal',
    'Font style': 'Schriftstil',
    'Container background color': 'Hintergrundfarbe des Containers',
    'Top (px)': 'Oben (px)',
    'Left (px)': 'Links (px)',
    'Bottom (px)': 'Unten (px)',
    'Right (px)': 'Rechts (px)',
    'Class name': 'Klassenname',
    'No repeat': 'Nicht wiederholen',
    Repeat: 'Wiederholen',
    'Repeat X': 'Horizontal wiederholen',
    'Repeat Y': 'Vertikal wiederholen',
    'Background image': 'Hintergrundbild',
    'Background repeat': 'Hintergrund wiederholen',
    'Background size': 'Hintergrundgröße',
    Dashed: 'Gestrichelt',
    Dotted: 'Gepunktet',
    Solid: 'Durchgezogen',
    double: 'Doppelt',
    ridge: 'Erhöht',
    groove: 'Vertieft',
    inset: 'Inset',
    outset: 'Outset',
    Style: 'Stil',
    'Vertical align': 'Vertikale Ausrichtung',
    Height: 'Höhe',
    Center: 'Zentriert',
    Margin: 'Abstand',
    Top: 'Oben',
    Bottom: 'Unten',
    Opacity: 'Deckkraft',
    'At least one condition': 'Mindestens eine Bedingung',
    Condition: 'Bedingung',
    Symbol: 'Symbol',
    And: 'Und',
    Or: 'Oder',
    'Variable path': 'Variabler Pfad',
    Operator: 'Operator',
    'Letter spacing': 'Buchstabenabstand',
    'email-block': 'E-Mail-Block',
    Page: 'Seite',
    Template: 'Vorlage',
    'Accordion element': 'Akkordeonelement',
    'Accordion title': 'Akkordeontitel',
    Table: 'Tabelle',
    'Make it easy for everyone to compose emails!':
      'Machen Sie es allen leicht, E-Mails zu verfassen!',
    'Accordion text': 'Akkordeontext',
    Raw: 'Rohe',
  },
};
