import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  isEmptyObject,
  toOnlyNumbersAndSpacesAndDashes,
} from '../../../../../infrastructure/helper';
import { TextField, TextFieldTypes, InputRestrictions } from '../../../../components/generic';
import TelephoneBox from '../../../../components/common/telephone-box';
import locationIcon from '../../../../images/textbox-icons/location.svg';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import styled from 'styled-components';
import { AddressBlock, AddressRowContainer, BtnB, BtnB2, SubsectionHeader } from '../../common';

import Links from './links';
import CodeBox from '../../../../components/qrcode-scanner/codebox';
import Files from './files';
import { Employee } from '../../../../../shared/types/api/employee.type';
import {
  Link,
  ApiFile,
  BulkFile,
  Account,
  AccountDefaultsConfig,
} from '../../../../../shared/types/api';
import LocationSelector from './locationSelector';
import { AddressFields } from '../../customization/customDataAddresses';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { Spacer } from '../../customization/common';
import { MdOutlineLanguage } from 'react-icons/md';
import { CustomLinkType } from './links/hooks/useBulk';
import { AddressTemplate } from '../../../../../shared/types/api/address-template';
import { BulkLink } from './links/components/links';
import { checkAccountFieldsLength, validateEmail } from '../../../../../shared/util';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { getAccountLengthError } from '../import-error-handler';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { AccountImportData } from '@/shared/types/global';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

export type InputFormSaveProps = AccountImportData;

const availableLanguages = [
  { value: 'en', tName: 'english' },
  { value: 'de', tName: 'german' },
] as const;

export interface BulkLinkProps {
  numSelected?: number;
  bulkEdit?: boolean;
  bulkLinks?: Array<BulkLink>;
  setBulkLinks?: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
  bulkDeletedLinks?: Array<Link>;
  setBulkDeletedLinks?: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
}

export interface BulkFileProps {
  numSelected: number;
  bulkEdit?: boolean;
  bulkFiles: Array<BulkFile>;
  setBulkFiles: Dispatch<SetStateAction<Array<BulkFile>>>;
  bulkDeletedFiles: Array<BulkFile>;
  setBulkDeletedFiles: Dispatch<SetStateAction<Array<BulkFile>>>;
  bulkTemplateFiles: Array<ApiFile>;
  setBulkTemplateFiles: Dispatch<SetStateAction<Array<ApiFile>>>;
}

interface Props {
  namePrefix?: string;
  firstName?: string;
  lastName?: string;
  setNamePrefix?: Dispatch<SetStateAction<string>>;
  setFirstName?: Dispatch<SetStateAction<string>>;
  setLastName?: Dispatch<SetStateAction<string>>;
  onBackClick: () => void;
  handleSave: (props: InputFormSaveProps) => void;
  employee?: Employee;
  bulkEdit?: boolean;
  bulkLinkProps?: BulkLinkProps;
  bulkFileProps?: BulkFileProps;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
  userFilesProps?: {
    userFiles: Array<ApiFile>;
    setUserFiles: Dispatch<SetStateAction<Array<ApiFile>>>;
  };
  employeeMainUnitId?: number;
}

const InputsForm = (props: Props) => {
  const { t } = useTranslation();
  const { theme: muiTheme } = useMuiTheme();
  const { getAccessTokenSilently } = useAuth0();
  const {
    onBackClick,
    handleSave,
    employee,
    bulkEdit,
    bulkLinkProps,
    bulkFileProps,
    userLinksProps,
    userFilesProps,
    employeeMainUnitId,
  } = props;

  const { profileDesign } = useProfileDesign();

  const { activeLanguage } = useAppTranslation();
  const [defaults, setDefaults] = useState<AccountDefaultsConfig>(undefined);

  const addingEmployee = !bulkEdit && !employee;

  const [firstName, setFirstName] = useState(employee?.firstName || '');
  const [lastName, setLastName] = useState(employee?.lastName || '');

  const [namePrefix, setNamePrefix] = useState(employee?.namePrefix || '');
  const [position, setPosition] = useState(employee?.position || '');
  const [academicDegree, setAcademicDegree] = useState(employee?.academicDegree || '');
  const [academicDegree2, setAcademicDegree2] = useState(employee?.academicDegree2 || '');
  const [role, setRole] = useState(employee?.role || '');
  const [division, setDivision] = useState(employee?.division || '');
  const [company, setCompany] = useState(employee?.company || '');
  const [costCenter, setCostCenter] = useState(employee?.costCenter || '');
  const [mobilePhone, setMobilePhone] = useState(employee?.mobilePhone || '');
  const [workPhone, setWorkPhone] = useState(employee?.workPhone || '');
  const [homePhone, setHomePhone] = useState(employee?.homePhone || '');
  const [hotlinePhone, setHotlinePhone] = useState(employee?.hotlinePhone || '');
  const [assistantPhone, setAssistantPhone] = useState(employee?.assistantPhone || '');
  const [assistantEmail, setAssistantEmail] = useState(employee?.assistantEmail || '');
  const [assistantName, setAssistantName] = useState(employee?.assistantName || '');
  const [assistantGeneric, setAssistantGeneric] = useState(employee?.assistantGeneric || '');
  const [workFax, setWorkFax] = useState(employee?.workFax || '');
  const [email, setEmail] = useState(employee?.email || '');
  const [workEmail, setWorkEmail] = useState(employee?.workEmail || '');
  const [workEmail2, setWorkEmail2] = useState(employee?.workEmail2 || '');
  const [workUrl, setWorkUrl] = useState(employee?.workUrl || '');
  const [bioText, setBioText] = useState(employee?.bioText || '');
  const [address1_addressee, setAddress1_addressee] = useState(employee?.address1_addressee || '');
  const [address1_addressLine1, setAddress1_addressLine1] = useState(
    employee?.address1_addressLine1 || '',
  );
  const [address1_addressLine2, setAddress1_addressLine2] = useState(
    employee?.address1_addressLine2 || '',
  );
  const [address1_postCode, setAddress1_postCode] = useState(employee?.address1_postCode || '');
  const [address1_city, setAddress1_city] = useState(employee?.address1_city || '');
  const [address1_country, setAddress1_country] = useState(employee?.address1_country || '');
  const [address1_label, setAddress1_label] = useState(employee?.address1_label || '');
  const [address2_addressee, setAddress2_addressee] = useState(employee?.address2_addressee || '');
  const [address2_addressLine1, setAddress2_addressLine1] = useState(
    employee?.address2_addressLine1 || '',
  );
  const [address2_addressLine2, setAddress2_addressLine2] = useState(
    employee?.address2_addressLine2 || '',
  );
  const [address2_postCode, setAddress2_postCode] = useState(employee?.address2_postCode || '');
  const [address2_city, setAddress2_city] = useState(employee?.address2_city || '');
  const [address2_country, setAddress2_country] = useState(employee?.address2_country || '');
  const [address2_label, setAddress2_label] = useState(employee?.address2_label || '');
  const [preferredLang, setPreferredLang] = useState(employee?.preferredLang || activeLanguage);
  const [templateAddresses, setTemplateAddresses] = useState<AddressTemplate[]>([]);
  const [code, setCode] = useState('');
  const [codeExists, setCodeExists] = useState(false);

  const { withUnsavedSetter } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const individualAddressAvailable = useMemo(
    () =>
      address1_addressee ||
      address1_addressLine1 ||
      address1_addressLine2 ||
      address1_postCode ||
      address1_city ||
      address1_country ||
      address1_label,
    [
      address1_addressLine1,
      address1_addressLine2,
      address1_addressee,
      address1_city,
      address1_country,
      address1_label,
      address1_postCode,
    ],
  );
  const individualAddress2Available = useMemo(
    () =>
      address2_addressee ||
      address2_addressLine1 ||
      address2_addressLine2 ||
      address2_postCode ||
      address2_city ||
      address2_country ||
      address2_label,
    [
      address2_addressLine1,
      address2_addressLine2,
      address2_addressee,
      address2_city,
      address2_country,
      address2_label,
      address2_postCode,
    ],
  );

  const [sendInvite, setSendInvite] = useState(true);
  const [tooltipMsg, setTooltipMsg] = useState('');
  const [addressBlur, setAddressBlur] = useState(false);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [showIndividualAddress, setShowIndividualAddress] = useState(false);

  useEffect(() => {
    // load account defaults from theme-config
    if (addingEmployee) {
      fetchThemeConfig(getAccessTokenSilently, THEME_CONFIG_KEYS.ACCOUNT_DEFAULTS)
        .then(({ value: response }) => {
          setDefaults(response);
        })
        .catch(err => {
          toast.error(t('error.general'));
          console.error(err);
        });
    }
  }, [addingEmployee, getAccessTokenSilently, t]);

  useEffect(() => {
    defaults?.firstName && setFirstName(defaults.firstName);
    defaults?.lastName && setLastName(defaults.lastName);
    defaults?.namePrefix && setNamePrefix(defaults.namePrefix);
    defaults?.position && setPosition(defaults.position);
    defaults?.academicDegree && setAcademicDegree(defaults.academicDegree);
    defaults?.academicDegree2 && setAcademicDegree2(defaults.academicDegree2);
    defaults?.division && setDivision(defaults.division);
    defaults?.company && setCompany(defaults.company);
    defaults?.costCenter && setCostCenter(defaults.costCenter);
    defaults?.mobilePhone && setMobilePhone(defaults.mobilePhone);
    defaults?.workPhone && setWorkPhone(defaults.workPhone);
    defaults?.homePhone && setHomePhone(defaults.homePhone);
    defaults?.hotlinePhone && setHotlinePhone(defaults.hotlinePhone);
    defaults?.assistantPhone && setAssistantPhone(defaults.assistantPhone);
    defaults?.assistantEmail && setAssistantEmail(defaults.assistantEmail);
    defaults?.assistantName && setAssistantName(defaults.assistantName);
    defaults?.assistantGeneric && setAssistantGeneric(defaults.assistantGeneric);
    defaults?.workFax && setWorkFax(defaults.workFax);
    defaults?.email && setEmail(defaults.email);
    defaults?.workEmail && setWorkEmail(defaults.workEmail);
    defaults?.workEmail2 && setWorkEmail2(defaults.workEmail2);
    defaults?.workUrl && setWorkUrl(defaults.workUrl);
    defaults?.bioText && setBioText(defaults.bioText);
    defaults?.address1_addressee && setAddress1_addressee(defaults.address1_addressee);
    defaults?.address1_addressLine1 && setAddress1_addressLine1(defaults.address1_addressLine1);
    defaults?.address1_addressLine2 && setAddress1_addressLine2(defaults.address1_addressLine2);
    defaults?.address1_postCode && setAddress1_postCode(defaults.address1_postCode);
    defaults?.address1_city && setAddress1_city(defaults.address1_city);
    defaults?.address1_country && setAddress1_country(defaults.address1_country);
    defaults?.address1_label && setAddress1_label(defaults.address1_label);
    defaults?.address2_addressee && setAddress2_addressee(defaults.address2_addressee);
    defaults?.address2_addressLine1 && setAddress2_addressLine1(defaults.address2_addressLine1);
    defaults?.address2_addressLine2 && setAddress2_addressLine2(defaults.address2_addressLine2);
    defaults?.address2_postCode && setAddress2_postCode(defaults.address2_postCode);
    defaults?.address2_city && setAddress2_city(defaults.address2_city);
    defaults?.address2_country && setAddress2_country(defaults.address2_country);
    defaults?.address2_label && setAddress2_label(defaults.address2_label);
    defaults?.preferredLang && setPreferredLang(defaults.preferredLang);
  }, [defaults]);

  useEffect(() => {
    const addressTemplateBuilder: AddressTemplate[] = [];
    if (employee?.addressTemplate1Id != null) {
      addressTemplateBuilder.push({
        label: address1_label,
        addressee: address1_addressee,
        addressLine1: address1_addressLine1,
        addressLine2: address1_addressLine2,
        postCode: address1_postCode,
        city: address1_city,
        country: address1_country,
        id: employee?.addressTemplate1Id,
        themeId: 0,
      });
      setAddress1_addressee('');
      setAddress1_addressLine1('');
      setAddress1_addressLine2('');
      setAddress1_postCode('');
      setAddress1_city('');
      setAddress1_country('');
      setAddress1_label('');
    }
    if (employee?.addressTemplate2Id != null) {
      addressTemplateBuilder.push({
        label: address2_label,
        addressee: address2_addressee,
        addressLine1: address2_addressLine1,
        addressLine2: address2_addressLine2,
        postCode: address2_postCode,
        city: address2_city,
        country: address2_country,
        id: employee?.addressTemplate2Id,
        themeId: 0,
      });
      setAddress2_addressee('');
      setAddress2_addressLine1('');
      setAddress2_addressLine2('');
      setAddress2_postCode('');
      setAddress2_city('');
      setAddress2_country('');
      setAddress2_label('');
    }
    setTemplateAddresses(addressTemplateBuilder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee?.id]);

  useEffect(() => {
    addingEmployee ? setTooltipMsg(t('emailTooltipCreate')) : setTooltipMsg(t('emailTooltipEdit'));
  }, [addingEmployee, t]);

  const onSaveClick = () => {
    let _address1: AddressFields | null;
    let _address2: AddressFields | null;

    if (templateAddresses.length > 1) {
      _address1 = {
        label: templateAddresses[0]?.label,
        addressee: templateAddresses[0]?.addressee,
        addressLine1: templateAddresses[0]?.addressLine1,
        addressLine2: templateAddresses[0]?.addressLine2,
        postCode: templateAddresses[0]?.postCode,
        city: templateAddresses[0]?.city,
        country: templateAddresses[0]?.country,
        isDefault: false,
      };
      _address2 = {
        label: templateAddresses[1]?.label,
        addressee: templateAddresses[1]?.addressee,
        addressLine1: templateAddresses[1]?.addressLine1,
        addressLine2: templateAddresses[1]?.addressLine2,
        postCode: templateAddresses[1]?.postCode,
        city: templateAddresses[1]?.city,
        country: templateAddresses[1]?.country,
        isDefault: false,
      };
    } else {
      if (templateAddresses.length === 1) {
        _address1 = {
          label: templateAddresses[0]?.label,
          addressee: templateAddresses[0]?.addressee,
          addressLine1: templateAddresses[0]?.addressLine1,
          addressLine2: templateAddresses[0]?.addressLine2,
          postCode: templateAddresses[0]?.postCode,
          city: templateAddresses[0]?.city,
          country: templateAddresses[0]?.country,
          isDefault: false,
        };
        if (
          (!!address1_addressee ||
            !!address1_addressLine1 ||
            !!address1_addressLine2 ||
            !!address1_postCode ||
            !!address1_city ||
            !!address1_country ||
            !!address1_label) &&
          !address2_addressee &&
          !address2_addressLine1 &&
          !address2_addressLine2 &&
          !address2_postCode &&
          !address2_city &&
          !address2_country &&
          !address2_label
        ) {
          _address2 = {
            addressee: address1_addressee,
            addressLine1: address1_addressLine1,
            addressLine2: address1_addressLine2,
            postCode: address1_postCode,
            city: address1_city,
            country: address1_country,
            label: address1_label,
            isDefault: false,
          };
        }
      }
    }

    let inputs: InputFormSaveProps = {
      ...(!bulkEdit && { firstName }),
      ...(!bulkEdit && { lastName }),
      position,
      academicDegree,
      academicDegree2,
      role,
      namePrefix: bulkEdit ? namePrefix : props.namePrefix,
      division,
      company,
      mobilePhone,
      workPhone,
      homePhone,
      hotlinePhone,
      assistantPhone,
      assistantName,
      assistantEmail,
      assistantGeneric,
      workFax,
      ...(addingEmployee && { sendInvite }),
      ...(!bulkEdit && { email }),
      workEmail,
      workEmail2,
      workUrl,
      bioText,
      address1_addressee: _address1?.addressee || address1_addressee,
      address1_addressLine1: _address1?.addressLine1 || address1_addressLine1,
      address1_addressLine2: _address1?.addressLine2 || address1_addressLine2,
      address1_postCode: _address1?.postCode || address1_postCode,
      address1_city: _address1?.city || address1_city,
      address1_country: _address1?.country || address1_country,
      address1_label: _address1?.label || address1_label,
      address2_addressee: _address2?.addressee || address2_addressee,
      address2_addressLine1: _address2?.addressLine1 || address2_addressLine1,
      address2_addressLine2: _address2?.addressLine2 || address2_addressLine2,
      address2_postCode: _address2?.postCode || address2_postCode,
      address2_city: _address2?.city || address2_city,
      address2_country: _address2?.country || address2_country,
      address2_label: _address2?.label || address2_label,
      ...(addingEmployee && codeExists && { code }),
      preferredLang: preferredLang || null,
      addressTemplate1Id: templateAddresses.length > 0 ? templateAddresses[0].id : null,
      addressTemplate2Id: templateAddresses.length > 1 ? templateAddresses[1].id : null,
      costCenter,
    };
    if (isFormValid(inputs)) handleSave(inputs);
  };

  const isFormValid = (inputs: Partial<Account>) => {
    if (bulkEdit) return true;

    if (!(inputs?.firstName?.length > 0) || !(inputs?.lastName?.length > 0)) {
      toast.error(t('errorMDFullName'));
      return false;
    }

    if (
      (addingEmployee && inputs?.email?.length === 0) ||
      (email?.length && email.length > 0 && !validateEmail(email))
    ) {
      toast.error(t('errorMDMail'));
      return false;
    }

    // check for account fields length rule
    const lengthError = checkAccountFieldsLength(inputs);
    if (!isEmptyObject(lengthError)) {
      const errorText = getAccountLengthError(lengthError);
      toast.error(errorText);
      return false;
    }

    return true;
  };

  const deleteAddress = (addressType: 1 | 2) => {
    if (addressType === 1) {
      setAddress1_addressee('');
      setAddress1_addressLine1('');
      setAddress1_addressLine2('');
      setAddress1_postCode('');
      setAddress1_city('');
      setAddress1_country('');
      setAddress1_label('');
    }
    if (addressType === 2) {
      setAddress2_addressee('');
      setAddress2_addressLine1('');
      setAddress2_addressLine2('');
      setAddress2_postCode('');
      setAddress2_city('');
      setAddress2_country('');
      setAddress2_label('');
    }
    setShowIndividualAddress(false);
  };

  const textfieldProps = {
    fullWidth: true,
    angles: 'curved',
    variant: 'outlined',
    font: { weight: '400' },
    margin: { all: '0.5rem 0' },
    bgColor: { normal: '#fff' },
    border: { normal: '1px solid #ddd' },
    boxShadow: 'inset 0 1px 2px rgb(0 0 0 / 10%)',
  } as const;

  const countIndividualAddresses = () => {
    let count = 0;
    if (
      address1_addressee +
        address1_addressLine1 +
        address1_addressLine2 +
        address1_postCode +
        address1_city +
        address1_country +
        address1_label !==
      ''
    )
      count += 1;
    if (
      address2_addressee +
        address2_addressLine1 +
        address2_addressLine2 +
        address2_postCode +
        address2_city +
        address2_country +
        address2_label !==
      ''
    )
      count += 1;
    return count;
  };

  return (
    <>
      <SubsectionHeader>{t('contactInfo')}</SubsectionHeader>
      <TextField
        name='academicDegree'
        type={TextFieldTypes.USER}
        placeholder={t('academicDegree')}
        value={academicDegree}
        onChange={withUnsavedSetter(e => setAcademicDegree(e.target.value))}
        maxLength={200}
        {...textfieldProps}
      />
      <TextField
        name='academicDegree2'
        type={TextFieldTypes.USER}
        placeholder={t('academicDegree2')}
        value={academicDegree2}
        onChange={withUnsavedSetter(e => setAcademicDegree2(e.target.value))}
        maxLength={200}
        {...textfieldProps}
      />
      <TextField
        name='namePrefix'
        type={TextFieldTypes.USER}
        placeholder={t('namePrefix')}
        value={bulkEdit ? namePrefix : props?.namePrefix}
        onChange={withUnsavedSetter(e =>
          (bulkEdit ? setNamePrefix : props.setNamePrefix)(e.target.value),
        )}
        maxLength={200}
        {...textfieldProps}
      />
      <StyledRow>
        <TextField
          name='firstname'
          type={TextFieldTypes.USER}
          placeholder={t('firstName')}
          value={firstName}
          onChange={withUnsavedSetter(e => setFirstName(e.target.value))}
          maxLength={70}
          isRequired
          inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
          disabled={bulkEdit}
          {...textfieldProps}
        />
        <TextField
          name='lastname'
          type={TextFieldTypes.USER}
          placeholder={t('lastName')}
          value={lastName}
          onChange={withUnsavedSetter(e => setLastName(e.target.value))}
          maxLength={70}
          isRequired
          inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
          disabled={bulkEdit}
          {...textfieldProps}
        />
      </StyledRow>
      <TextField
        name='bio'
        type={TextFieldTypes.BIO}
        placeholder={t('position')}
        value={position}
        onChange={withUnsavedSetter(e => setPosition(e.target.value))}
        maxLength={200}
        multiline={true}
        {...textfieldProps}
      />

      <TextField
        name='role'
        type={TextFieldTypes.BIO}
        placeholder={t('role')}
        value={role}
        onChange={withUnsavedSetter(e => setRole(e.target.value))}
        maxLength={200}
        multiline={true}
        {...textfieldProps}
      />

      <TextField
        name='division'
        type={TextFieldTypes.BIO}
        placeholder={t('division')}
        value={division}
        onChange={withUnsavedSetter(e => setDivision(e.target.value))}
        maxLength={200}
        multiline={true}
        {...textfieldProps}
      />
      <TextField
        name='company'
        type={TextFieldTypes.COMPANY}
        placeholder={t('company')}
        value={company}
        multiline={true}
        onChange={withUnsavedSetter(e => setCompany(e.target.value))}
        maxLength={70}
        {...textfieldProps}
      />
      <TextField
        name='workUrl'
        type={TextFieldTypes.COMPANY}
        placeholder={t('workUrl')}
        value={workUrl}
        onChange={withUnsavedSetter(e => setWorkUrl(e.target.value))}
        {...textfieldProps}
      />
      <TextField
        name='bioText'
        type={TextFieldTypes.BIOTEXT}
        placeholder={t('bioText')}
        value={bioText}
        onChange={withUnsavedSetter(e => setBioText(e.target.value))}
        maxLength={200}
        multiline={true}
        {...textfieldProps}
      />
      <TextField
        name='email'
        type={TextFieldTypes.EMAIL}
        placeholder={t('email1')}
        value={email}
        onChange={withUnsavedSetter(e => setEmail(e.target.value))}
        tooltip={tooltipMsg}
        disabled={bulkEdit}
        isRequired={addingEmployee}
        {...textfieldProps}
      />
      {addingEmployee && (
        <SendEmailToggle>
          <SendEmailToggleSpan>{t('invitationMD')}</SendEmailToggleSpan>
          <label>
            <Switch
              onChange={() => setSendInvite(!sendInvite)}
              checked={sendInvite}
              onColor={'#02E0B1'}
              offColor='#C1CBDC'
            />
          </label>
        </SendEmailToggle>
      )}
      <TextField
        name='workEmail'
        type={TextFieldTypes.EMAIL}
        placeholder={t('workEmail')}
        value={workEmail}
        onChange={withUnsavedSetter(e => setWorkEmail(e.target.value))}
        {...textfieldProps}
      />
      <TextField
        name='workEmail2'
        type={TextFieldTypes.EMAIL}
        placeholder={t('email3')}
        value={workEmail2}
        onChange={withUnsavedSetter(e => setWorkEmail2(e.target.value))}
        {...textfieldProps}
      />
      <TelephoneBox
        name='mobilePhone'
        placeholder={t('mobilePhone.text')}
        value={mobilePhone}
        onChange={withUnsavedSetter(e =>
          setMobilePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
        )}
        {...textfieldProps}
      />
      <TelephoneBox
        name='workPhone'
        placeholder={t('workPhone.text')}
        value={workPhone}
        onChange={withUnsavedSetter(e =>
          setWorkPhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
        )}
        {...textfieldProps}
      />
      <TelephoneBox
        name='homePhone'
        placeholder={t('homePhone.text')}
        value={homePhone}
        onChange={withUnsavedSetter(e =>
          setHomePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
        )}
        {...textfieldProps}
      />
      <TelephoneBox
        name='hotlinePhone'
        placeholder={t('hotlinePhone.text')}
        value={hotlinePhone}
        onChange={withUnsavedSetter(e =>
          setHotlinePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
        )}
        {...textfieldProps}
      />
      <TextField
        type={TextFieldTypes.USER}
        name='assistantName'
        placeholder={t('assistantName.text')}
        value={assistantName}
        onChange={withUnsavedSetter(e => setAssistantName(e.target.value))}
        {...textfieldProps}
      />
      <TelephoneBox
        name='assistantPhone'
        placeholder={t('assistantPhone.text')}
        value={assistantPhone}
        onChange={withUnsavedSetter(e =>
          setAssistantPhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
        )}
        {...textfieldProps}
      />
      <TextField
        name='assistantEmail'
        type={TextFieldTypes.EMAIL}
        placeholder={t('assistantEmail.text')}
        value={assistantEmail}
        onChange={withUnsavedSetter(e => setAssistantEmail(e.target.value))}
        {...textfieldProps}
      />
      <TextField
        type={TextFieldTypes.BIOTEXT}
        name='assistantGeneric'
        placeholder={t('assistantGeneric.text')}
        value={assistantGeneric}
        onChange={withUnsavedSetter(e => setAssistantGeneric(e.target.value))}
        {...textfieldProps}
      />
      <TelephoneBox
        name='workFax'
        placeholder={t('workFax.text')}
        value={workFax}
        onChange={withUnsavedSetter(e =>
          setWorkFax(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
        )}
        {...textfieldProps}
      />
      <TextField
        name='costCenter'
        type={TextFieldTypes.COMPANY}
        placeholder={t('costCenter')}
        value={costCenter}
        onChange={withUnsavedSetter(e => setCostCenter(e.target.value))}
        maxLength={70}
        {...textfieldProps}
      />
      <LocationSelector
        setTemplateAddresses={setTemplateAddresses}
        templateAddresses={templateAddresses}
        setShowIndividualAddress={setShowIndividualAddress}
        individualAddressesCount={countIndividualAddresses()}
        employeeAddresses={[employee?.addressTemplate1Id, employee?.addressTemplate2Id]}
        isAddingEmployee={addingEmployee}
        isBulkEdit={bulkEdit}
      />

      {(showIndividualAddress || addressBlur || individualAddressAvailable) && (
        <RowDiv>
          <AddressBlock>
            <div>
              <img src={locationIcon} alt='Location' />
            </div>
            <div>
              <AddressText
                type='text'
                name='address1_label'
                placeholder={t('address.label')}
                value={address1_label}
                autoComplete='off'
                onBlur={() => setAddressBlur(false)}
                onFocus={() => setAddressBlur(true)}
                onChange={withUnsavedSetter(e => {
                  setAddress1_label(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressText
                type='text'
                name='address1_addressee'
                placeholder={t('address.addressee')}
                value={address1_addressee}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress1_addressee(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressText
                type='text'
                name='address1_addressLine1'
                placeholder={t('address.addressLine1')}
                value={address1_addressLine1}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress1_addressLine1(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressText
                type='text'
                name='address1_addressLine2'
                placeholder={t('address.addressLine2')}
                value={address1_addressLine2}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress1_addressLine2(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressRowContainer>
                <AddressText
                  type='text'
                  name='address1_postCode'
                  placeholder={t('address.postCode')}
                  value={address1_postCode}
                  autoComplete='off'
                  onChange={withUnsavedSetter(e => {
                    setAddress1_postCode(e.target.value);
                    setShowIndividualAddress(false);
                  })}
                />
                <AddressText
                  type='text'
                  name='address1_city'
                  placeholder={t('address.city')}
                  value={address1_city}
                  autoComplete='off'
                  onChange={withUnsavedSetter(e => {
                    setAddress1_city(e.target.value);
                    setShowIndividualAddress(false);
                  })}
                />
              </AddressRowContainer>
              <AddressText
                type='text'
                name='address1_country'
                placeholder={t('address.country')}
                value={address1_country}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress1_country(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
            </div>
          </AddressBlock>
          <Spacer size={15} />
          <IconContainer>
            <MdOutlineDeleteOutline size={28} color='#5C5C5C' onClick={() => deleteAddress(1)} />
          </IconContainer>
        </RowDiv>
      )}
      {((showIndividualAddress && individualAddressAvailable) ||
        address2Blur ||
        individualAddress2Available) && (
        <RowDiv>
          <AddressBlock>
            <div>
              <img src={locationIcon} alt='Location' />
            </div>
            <div>
              <AddressText
                type='text'
                name='address2_label'
                placeholder={t('address.label')}
                value={address2_label}
                autoComplete='off'
                onBlur={() => setAddress2Blur(false)}
                onFocus={() => setAddress2Blur(true)}
                onChange={withUnsavedSetter(e => {
                  setAddress2_label(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressText
                type='text'
                name='address2_addressee'
                placeholder={t('address.addressee')}
                value={address2_addressee}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress2_addressee(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressText
                type='text'
                name='address2_addressLine1'
                placeholder={t('address.addressLine1')}
                value={address2_addressLine1}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress2_addressLine1(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressText
                type='text'
                name='address2_addressLine2'
                placeholder={t('address.addressLine2')}
                value={address2_addressLine2}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress2_addressLine2(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
              <AddressRowContainer>
                <AddressText
                  type='text'
                  name='address2_postCode'
                  placeholder={t('address.postCode')}
                  value={address2_postCode}
                  autoComplete='off'
                  onChange={withUnsavedSetter(e => {
                    setAddress2_postCode(e.target.value);
                    setShowIndividualAddress(false);
                  })}
                />
                <AddressText
                  type='text'
                  name='address2_city'
                  placeholder={t('address.city')}
                  value={address2_city}
                  autoComplete='off'
                  onChange={withUnsavedSetter(e => {
                    setAddress2_city(e.target.value);
                    setShowIndividualAddress(false);
                  })}
                />
              </AddressRowContainer>
              <AddressText
                type='text'
                name='address2_country'
                placeholder={t('address.country')}
                value={address2_country}
                autoComplete='off'
                onChange={withUnsavedSetter(e => {
                  setAddress2_country(e.target.value);
                  setShowIndividualAddress(false);
                })}
              />
            </div>
          </AddressBlock>
          <Spacer size={15} />
          <IconContainer>
            <MdOutlineDeleteOutline size={28} color='#5C5C5C' onClick={() => deleteAddress(2)} />
          </IconContainer>
        </RowDiv>
      )}
      <hr />
      <SubsectionHeader>{t('accountInfo')}</SubsectionHeader>
      <SubSubSectionHeader>{t('language')}</SubSubSectionHeader>
      <SelectContainer>
        <Select
          name='languages'
          id='languages'
          value={preferredLang}
          onChange={withUnsavedSetter(e => setPreferredLang(e.target.value))}
        >
          {availableLanguages.map(({ value, tName }) => {
            return (
              <option key={value} value={value}>
                {t(tName)}
              </option>
            );
          })}
        </Select>
        <GlobIconContainer>
          <MdOutlineLanguage size={24} color='#7F858E' />
        </GlobIconContainer>
      </SelectContainer>
      <Spacer size={15} />
      {bulkEdit && !addingEmployee && (
        <>
          <hr />
          <Links
            themeLinksPosition={profileDesign.themeLinksPosition}
            mode='bulk'
            bulkLinkProps={bulkLinkProps}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
          <hr />
          <Files
            themeFilesPosition={profileDesign.themeFilesPosition}
            bulkFileProps={bulkFileProps}
          />
        </>
      )}
      {!bulkEdit && !addingEmployee && (
        <>
          <hr />
          <Links
            unitId={employeeMainUnitId}
            themeLinksPosition={profileDesign.themeLinksPosition}
            mode='single'
            employee={employee}
            userLinksProps={userLinksProps}
            genericWebsiteColor={profileDesign.genericWebsiteColor}
          />
          <hr />
          <Files
            unitId={employeeMainUnitId}
            themeFilesPosition={profileDesign.themeFilesPosition}
            employee={employee}
            userFilesProps={userFilesProps}
          />
        </>
      )}
      {addingEmployee && (
        <>
          <hr />
          <CodeBox
            code={code}
            setCode={setCode}
            codeExists={codeExists}
            setCodeExists={setCodeExists}
          />
        </>
      )}
      <ButtonWrapper>
        <BtnB
          type='button'
          value={t<string>('save')}
          onClick={onSaveClick}
          color={muiTheme.palette.primaryButton.main}
        />
        <BtnB2
          type='button'
          value={t<string>('cancel')}
          onClick={() => openDialogIfUnsaved(onBackClick)}
        />
      </ButtonWrapper>
    </>
  );
};

export default InputsForm;

const StyledRow = styled.div`
  display: flex;
  div ~ div {
    margin-left: 1rem !important;
  }
`;
const SendEmailToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 75%;
  margin-top: 10px;
`;
const SendEmailToggleSpan = styled.span`
  color: #a9a9a9;
  font-size: 0.75em;
`;
const ButtonWrapper = styled.div`
  background-color: #f6f8ff;
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transform: scale(1.1);
`;

const AddressText = styled.input`
  outline: none !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #7a7a7a !important;
  box-shadow: none !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
`;
const Select = styled.select`
  margin-bottom: 0;
  text-align: left;
  padding: 0 0.5rem;
  padding-left: 5rem;
  appearance: none;
  cursor: pointer;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    padding-right: 1.5rem;
  }
`;
const SelectContainer = styled.div`
  position: relative;
  border-radius: 30px;
  &::after {
    content: '>';
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 2rem;
    top: 1.5rem;
    position: absolute;
    pointer-events: none;
  }
`;

const SubSubSectionHeader = styled.div`
  font-size: 1.3rem;
  padding-left: 10px;
  padding-bottom: 10px;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const GlobIconContainer = styled.div`
  position: absolute;
  top: 1.3rem;
  left: 2rem;
`;
