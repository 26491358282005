import { useAppSelector } from '@/application/hooks';
import { NavItem } from '@/infrastructure/hoc/types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Divider, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CollapsableList } from './CollapsableList';

type Props = {
  title?: string;
  items: NavItem[];
  collapsable?: boolean;
};

export const SingleListSet = ({ title = '', items, collapsable = true }: Props) => {
  const [opened, setOpened] = useState(true);

  const sidebarCollapsed = useAppSelector(state => state.ui.sidebarCollapsed);

  const headerVisible = collapsable && !sidebarCollapsed;

  useEffect(() => {
    if (sidebarCollapsed) setOpened(true);
  }, [sidebarCollapsed]);

  return (
    <Box mb={'0.4rem'}>
      {collapsable && <Divider sx={{ borderColor: 'inherit', opacity: '0.15', mb: '0.4rem' }} />}
      {headerVisible && (
        <ListItemButton
          sx={{ height: '4rem', justifyContent: 'space-between', ':hover': { bgcolor: 'initial' } }}
          onClick={() => setOpened(!opened)}
        >
          <Typography variant='body1' color='inherit'>
            {title}
          </Typography>
          {opened ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <CollapsableList items={items} sidebarCollapsed={sidebarCollapsed} opened={opened} />
    </Box>
  );
};
