import { useEffect } from 'react';
import { useAppDispatch } from '@/application/hooks';
import { dispatchTypes } from '@/application/actions';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from './ColorPicker';
import styled from 'styled-components';
import { defaultPalette } from '@/config/theme/palette';
import { ThemeProvider } from '@mui/system';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { Button } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';

interface Props {
  primaryColor: string;
  sidebarColor: string;
  sidebarColorText: string;
  sidebarColorActive: string;
  sidebarColorActiveText: string;
  chatColor: string;
}

const Colors = (props: Props) => {
  const { t } = useTranslation();
  const {
    primaryColor,
    sidebarColor,
    sidebarColorText,
    sidebarColorActive,
    sidebarColorActiveText,
    chatColor,
  } = props;

  const dispatch = useAppDispatch();

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();

  useEffect(() => {
    return function cleanup() {
      setIsUnsaved(false);
      dispatch({
        type: dispatchTypes.TEMP.THEME.RESETCOLORS,
      });
    };
  }, [dispatch, setIsUnsaved]);

  const setTempColor = payload => {
    dispatch({
      type: dispatchTypes.TEMP.THEME.SETCOLOR,
      payload,
    });
  };

  const resetColors = () => {
    dispatch({
      type: dispatchTypes.TEMP.THEME.SETCOLOR,
      payload: {
        tempPrimaryButtonColor: defaultPalette.primaryButton.main,
        tempChatColor: defaultPalette.primaryButton.main,
        tempSidebarColor: defaultPalette.sidebar.main,
        tempSidebarColorText: defaultPalette.sidebar.contrastText,
        tempSidebarColorActive: defaultPalette.sidebarActive.main,
        tempSidebarColorActiveText: defaultPalette.sidebarActive.contrastText,
      },
    });
  };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <>
      <GridContainer>
        <ColorPicker
          title={t('organisation.primaryBtnColor')}
          value={primaryColor}
          onChange={withUnsavedSetter(color => setTempColor({ tempPrimaryButtonColor: color }))}
        />
        <ColorPicker
          title={t('organisation.helpBtnColor')}
          value={chatColor}
          onChange={withUnsavedSetter(color => setTempColor({ tempChatColor: color }))}
        />
        <ColorPicker
          title={t('organisation.sidebarBtnColor')}
          value={sidebarColor}
          onChange={withUnsavedSetter(color => setTempColor({ tempSidebarColor: color }))}
        />
        <ColorPicker
          title={t('organisation.sidebarTextBtnColor')}
          value={sidebarColorText}
          onChange={withUnsavedSetter(color => setTempColor({ tempSidebarColorText: color }))}
        />
        <ColorPicker
          title={t('organisation.sidebarActiveBtnColor')}
          value={sidebarColorActive}
          onChange={withUnsavedSetter(color => setTempColor({ tempSidebarColorActive: color }))}
        />
        <ColorPicker
          title={t('organisation.sidebarActiveTextBtnColor')}
          value={sidebarColorActiveText}
          onChange={withUnsavedSetter(color => setTempColor({ tempSidebarColorActiveText: color }))}
        />
      </GridContainer>
      <ThemeProvider theme={muiTheme}>
        <Button
          startIcon={<RestoreIcon />}
          variant='outlined'
          color='primary'
          onClick={withUnsavedSetter(() => resetColors())}
        >
          {t('organisation.resetColors')}
        </Button>
      </ThemeProvider>
    </>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 4rem;
`;

export default Colors;
