import { routePaths } from '@/infrastructure/constants';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { ArrowCircleUp } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

export const UpgradeButton = ({ isStarter }) => {
  const { t } = useAppTranslation();
  const history = useHistory();
  const location = useLocation();
  const { desktopView } = useDesktopView();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const handleClick = () => {
    if (!flag_hidePrices && isStarter && location.pathname !== routePaths.UPGRADE_TEAMS) {
      history.push(routePaths.UPGRADE_TEAMS);
    } else {
      upgradeAction();
    }
  };

  return (
    <>
      <Button
        startIcon={<ArrowCircleUp />}
        onClick={handleClick}
        sx={theme => ({ mx: theme.spacing(2), whiteSpace: 'nowrap' })}
      >
        {flag_hidePrices ? (desktopView ? t('contactSales') : t('upgrade')) : t('upgradeNow')}
      </Button>
      {upgradeRequestPopup}
    </>
  );
};
