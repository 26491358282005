import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { ActionPreferences } from '../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';
import { withSilentAccessToken } from '../helper';
import { PERMISSIONS } from '../constants';

export const checkDomain = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + `account/checkDomain`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const getOwnAccount = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.get(config.API_BASE_URL + 'account', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchThemeQrBgImgs = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.get(`${config.API_BASE_URL}account/qr-code/bg-imgs`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const postActionPreference = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  partialPrefs: Partial<ActionPreferences>,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}account/action-preferences`,
    {
      actionPreferences: partialPrefs,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const getWalletPass = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  qrType: 'online' | 'offline',
  wallet: 'apple' | 'google',
  uidForProfileLink?: number,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.get(
    config.API_BASE_URL +
      `account/pass/${wallet}?qrType=${qrType}` +
      (uidForProfileLink ? `&uid=${uidForProfileLink}` : ''),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // for `apple` wallet, we get a buffer response
      // for `google` wallet, we get a url string response
      ...(wallet === 'apple' && { responseType: 'blob' }),
    },
  );
};

export const deleteAccount = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  email,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.post(
    config.API_BASE_URL + `account/delete`,
    { authEmail: email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getAccountCodes = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + 'account/codes', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),

    [PERMISSIONS.READ.THEME_PROFILES],
  );
  return data;
};

export const deleteAccountCode = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  code: string,
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(config.API_BASE_URL + `account/codes/${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
  if (!data.isSuccess) {
    throw new Error('Failed to delete account code');
  }
};

export const connectCode = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  code: string,
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + 'account/addcode',
        { code },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );

  if (!data.isSuccess) {
    throw new Error('Failed to connect code');
  }
  return data;
};
