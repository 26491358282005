import { SIDEBAR_COLLAPSE_BREAKPOINT } from '../../shared/constants';
import { dispatchTypes } from '../actions';

let initialState = {
  sidebarCollapsed: window.innerWidth < SIDEBAR_COLLAPSE_BREAKPOINT,
  sidebarMyProfileCollapsed: false,
  sidebarAdminManagementCollapsed: false,
  sidebarSettingsCollapsed: false,
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.UI.SET_SIDEBAR_COLLAPSED:
      return { ...state, sidebarCollapsed: action.sidebarCollapsed };
    case dispatchTypes.UI.SET_SIDEBAR_MY_PROFILE_COLLAPSED:
      return { ...state, sidebarMyProfileCollapsed: action.collapsed };
    case dispatchTypes.UI.SET_SIDEBAR_ADMIN_MANAGEMENT_COLLAPSED:
      return { ...state, sidebarAdminManagementCollapsed: action.collapsed };
    case dispatchTypes.UI.SET_SIDEBAR_SETTINGS_COLLAPSED:
      return { ...state, sidebarSettingsCollapsed: action.collapsed };
    default:
      return state;
  }
};

export default verificationReducer;
