import styled from 'styled-components';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FileBox from '../../../../edit-profile/fileBox';
import AddNewFilePopup from '../../../../../components/popups/add-new-file-popup';
import DeletePopup from '../../../../../components/popups/delete-item-popup';
import { Employee } from '../../../../../../shared/types/api/employee.type';
import { Account, ApiFile, BulkFile } from '../../../../../../shared/types/api';
import FileSelector from '../../../../../components/common/FileSelector';
import { RowDiv, Spacer } from '../../../customization/common';
import { useAppSelector } from '../../../../../../application/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../../../../../../infrastructure/helper';
import axios from 'axios';
import { PERMISSIONS } from '../../../../../../infrastructure/constants';
import config from '../../../../../../config/config';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ReactComponent as DragIndicatorIcon } from '../../../../../images/dragIndicatorIcon.svg';
import { getItemStyle } from '../../../../edit-profile';
import { ThemeLinksFilesPosition } from '../../../../../../shared/types/global';
import { THEME_LINKS_FILES_POSITIONS } from '../../../../../../shared/constants';
import { Checkbox } from '../../../../../components/generic';
import { SubsectionHeader } from '../styles';
import { getFileCountString } from './helpers';
import ExtendPopup from './extend-popup';
import { BulkFileProps } from '../inputs-form';
import TooltipInfo from '../../../../../components/generic/TooltipInfo';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useTranslation } from 'react-i18next';

export interface UploadfileProps {
  id?: number;
  uploadedFile: File;
  fileName: string;
  pageCount: number;
  embed: boolean;
  link?: string;
}

export interface UploadfileTemplateProps {
  id: number;
  url: string;
  fileName: string;
  pageCount: number;
}

interface Props {
  employee?: Employee;
  bulkFileProps?: BulkFileProps;
  isCompanydataSettings?: boolean;
  setThemeFiles?(themeFiles: (ApiFile & { fileToUpload?: File })[]): void;
  themeFilesPosition: ThemeLinksFilesPosition;
  setThemeFilesPosition?(position: ThemeLinksFilesPosition): void;
  userFilesProps?: {
    userFiles: Array<ApiFile>;
    setUserFiles: Dispatch<SetStateAction<Array<ApiFile>>>;
  };
  loadingCompleted?: Function;
  unitId?: number;
}
const reorder = <T extends ApiFile>(
  list: Array<T>,
  startIndex: number,
  endIndex: number,
): Array<any> => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((elements, idx) => ({
    ...elements,
    order: idx * 10,
  }));
};

const Files = (props: Props) => {
  const { t } = useTranslation();
  const {
    employee,
    isCompanydataSettings,
    setThemeFiles,
    bulkFileProps,
    userFilesProps,
    loadingCompleted,
    unitId,
  } = props;

  const { numSelected, bulkEdit, bulkFiles, setBulkFiles, setBulkTemplateFiles } =
    bulkFileProps || {};

  const [loading, setLoading] = useState(true);
  const account = useAppSelector<Account>(state => state.account);

  const [savedTemplateFiles, setSavedTemplateFiles] = useState<
    (ApiFile & { fileToUpload?: File })[]
  >([]);
  const [companyFilesList, setCompanyFilesList] = useState<ApiFile[]>([]);
  const files = useMemo(
    () => (isCompanydataSettings ? [] : userFilesProps?.userFiles || bulkFiles || []),
    [userFilesProps?.userFiles, isCompanydataSettings, bulkFiles],
  );

  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<ApiFile>();

  const [showExtendPopup, setShowExtendPopup] = useState(false);
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const EndFilesRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();

  const totalFiles = useMemo((): ApiFile[] => {
    let _totalFiles = [];
    if (Array.isArray(files)) _totalFiles = [...files];
    return [..._totalFiles, ...savedTemplateFiles];
  }, [files, savedTemplateFiles]);

  useEffect(() => {
    if (bulkEdit) {
      setBulkTemplateFiles(savedTemplateFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedTemplateFiles]);

  const scrollToBottom = () => {
    EndFilesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'theme/global-files', {
          params: { unitId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
    )
      .then(res => {
        if (isCompanydataSettings) {
          setSavedTemplateFiles(res.data.data);
        } else {
          if (employee || bulkEdit) setCompanyFilesList(res.data.data);
        }
        setThemeFiles?.(res.data.data);
        loadingCompleted?.();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    bulkEdit,
    employee,
    getAccessTokenSilently,
    isCompanydataSettings,
    loadingCompleted,
    setThemeFiles,
    unitId,
  ]);

  const onDeleteFileClick = useCallback(
    file => {
      if (bulkEdit) {
        if (file.existsInDB) {
          // consent delete, file exists
          setSelectedFile(file);
          setShowDeleteFile(true);
        } else if (!file.link) {
          // shallow added, normal file
          const filtered = bulkFiles?.filter(f => f.id !== file.id);
          setBulkFiles([...filtered]);
        } else {
          // shallow added, template file
          setSavedTemplateFiles(savedTemplateFiles?.filter(f => f.id !== file.id));
        }
        return;
      }
      setSelectedFile(file);
      setShowDeleteFile(true);
    },
    [bulkEdit, bulkFiles, savedTemplateFiles, setBulkFiles],
  );

  const onExtendClick = (item: BulkFile) => {
    setSelectedFile(item);
    setShowExtendPopup(true);
  };

  const handleExtendFile = () => {
    let index = bulkFiles?.findIndex(l => l.id === selectedFile.id);
    // @ts-ignore-next-line
    if (index !== -1) bulkFiles[index] = { ...selectedFile, extendToAll: true };
    setBulkFiles([...bulkFiles]);
    setShowExtendPopup(false);
  };

  const [showIndividualFile, setShowIndividualFile] = useState(false);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const newItems = reorder(totalFiles, source.index, destination.index);

    if (isCompanydataSettings) {
      setSavedTemplateFiles(newItems);
      props.setThemeFiles?.(newItems);
      setIsUnsaved(true);
    } else if (bulkEdit) {
      // should never be called
      return;
    } else {
      userFilesProps?.setUserFiles(newItems);
    }
  };

  const handleCompanySettingsUpdate = async (config: {
    fileType: 'newFile' | 'templateFile';
    templateFileDetails?: UploadfileTemplateProps;
    newFileDetails?: UploadfileProps;
  }) => {
    const templateFiles: (ApiFile & { fileToUpload?: File })[] = [
      ...savedTemplateFiles,
      {
        id: config.fileType === 'templateFile' ? Math.random() : -Math.random(),
        link: config.fileType === 'newFile' ? '' : config.templateFileDetails.url,
        fileName:
          config.fileType === 'newFile'
            ? config.newFileDetails.fileName
            : config.templateFileDetails.fileName,
        embed: config.fileType === 'newFile' ? config.newFileDetails.embed : false,
        canDisplayOnProfile: true,
        order: savedTemplateFiles.length,
        pageCount:
          config.fileType === 'newFile'
            ? config.newFileDetails.pageCount
            : config.templateFileDetails.pageCount,
        fileTemplateId: config.fileType === 'newFile' ? null : config.templateFileDetails.id,
        fileToUpload: config.fileType === 'newFile' && config.newFileDetails?.uploadedFile,
      },
    ];
    setSavedTemplateFiles(templateFiles);
    props.setThemeFiles?.(templateFiles);
    setIsUnsaved(true);
  };

  const handleCompanySettingsFileDelete = (fileId: number) => {
    const templateFiles = savedTemplateFiles.filter(file => file.id !== fileId);
    setSavedTemplateFiles(templateFiles);
    props.setThemeFiles?.(templateFiles);
    setIsUnsaved(true);
  };

  const themeFiles = useMemo(
    () =>
      companyFilesList.map((item, index) => (
        <FileBox
          key={index}
          // @ts-ignore:next-line
          item={item}
          theme={account.theme}
          hideOptions
          isThemeEntity
          userIsAdmin
          // @ts-ignore:next-line
          onDeleteClick={() => onDeleteFileClick(item)}
        />
      )),
    [account.theme, companyFilesList, onDeleteFileClick],
  );

  return (
    <>
      {showIndividualFile && (
        <AddNewFilePopup
          isCompanydataSettings={isCompanydataSettings}
          handleCompanySettingsUpdate={isCompanydataSettings && handleCompanySettingsUpdate}
          employee={employee}
          onCloseClick={() => setShowIndividualFile(false)}
          {...bulkFileProps}
          {...userFilesProps}
        />
      )}
      {showDeleteFile && selectedFile && (
        <DeletePopup
          isCompanydataSettings={isCompanydataSettings}
          handleCompanySettingsFileDelete={isCompanydataSettings && handleCompanySettingsFileDelete}
          employeeId={employee?.id}
          onCloseClick={() => setShowDeleteFile(false)}
          item={selectedFile}
          mode='file'
          {...bulkFileProps}
          {...userFilesProps}
        />
      )}
      {showExtendPopup && (
        <ExtendPopup
          onCloseClick={() => setShowExtendPopup(false)}
          onSaveClick={handleExtendFile}
          numSelected={numSelected}
        />
      )}
      {props.themeFilesPosition && props.setThemeFilesPosition && (
        <>
          <RowDiv>{t('md.profileDesign.themeFilesPosition.question')}</RowDiv>
          <div>
            {Object.values(THEME_LINKS_FILES_POSITIONS).map(pos => (
              <div>
                <Checkbox
                  key={pos}
                  name={pos}
                  title={t('md.profileDesign.themeFilesPosition.' + pos)}
                  forcedCheckState={props.themeFilesPosition === pos}
                  onChange={() => props.setThemeFilesPosition(pos)}
                />
              </div>
            ))}
          </div>
          <Spacer size={32} />
        </>
      )}

      {props.isCompanydataSettings ? (
        <>
          <RowDiv>{t('md.profileDesign.themeFiles.question')}:</RowDiv>
          <Spacer size={12} />
        </>
      ) : (
        <SubsectionHeader>
          {t('files')}{' '}
          <TooltipInfo
            text={t('md.profileDesign.filesTooltip')}
            placement='right'
            icon={{ size: 12 }}
            arrow
          />
        </SubsectionHeader>
      )}

      <FileSelector
        setSavedTemplateFiles={setSavedTemplateFiles}
        savedTemplateFiles={savedTemplateFiles}
        setShowIndividualFile={setShowIndividualFile}
        employeeId={employee?.id}
        scrollToBottom={scrollToBottom}
        totalFiles={totalFiles}
        isCompanydataSettings={isCompanydataSettings}
        handleCompanySettingsUpdate={isCompanydataSettings && handleCompanySettingsUpdate}
        companyFilesList={companyFilesList}
        {...userFilesProps}
      />
      {loading && <p>{t('Loading')}..</p>}
      {props.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.BEFORE && themeFiles}
      {bulkEdit ? (
        totalFiles.map((file: BulkFile, index: number) => (
          <>
            {(file.existsInDB || file.existsForAll) && (
              <CommonFileSpan>{getFileCountString(file, t)}</CommonFileSpan>
            )}
            <FileBox
              employeeId={employee?.id}
              key={index}
              // @ts-ignore:next-line
              item={file}
              // @ts-ignore:next-line
              onDeleteClick={() => onDeleteFileClick(file)}
              onExtendClick={() => onExtendClick(file)}
              bulkEdit
              theme={account.theme}
              blueBackground={typeof file.fileTemplateId === 'number'}
              userIsAdmin
            />
          </>
        ))
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='files'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {totalFiles.map((item, index) => (
                  <Draggable
                    key={item.id?.toString()}
                    draggableId={item.id?.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div
                          className='darg-icon'
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          {...provided.dragHandleProps}
                        >
                          <DragIndicatorIcon />
                        </div>
                        <FileBox
                          employeeId={employee?.id}
                          key={index}
                          // @ts-ignore:next-line
                          item={item}
                          // @ts-ignore:next-line
                          onDeleteClick={() => onDeleteFileClick(item)}
                          bulkEdit
                          theme={account.theme}
                          blueBackground={typeof item.fileTemplateId === 'number'}
                          userIsAdmin
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {props.themeFilesPosition === THEME_LINKS_FILES_POSITIONS.AFTER && themeFiles}
      <Spacer ref={EndFilesRef} size={50} />
    </>
  );
};

export default Files;

const CommonFileSpan = styled.span`
  font-size: 1rem;
`;
