import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  openDialog: (onConfirm: () => void, title?: string) => void;
}

const ConfirmationDialogContext = React.createContext<ConfirmationDialogProps>({
  openDialog: () => {},
});

export const ConfirmationDialogProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const openDialog = useCallback((onConfirm: () => void, title?: string) => {
    const promptMessage = title || t('unsavedChangesText');
    if (window.confirm(promptMessage)) {
      onConfirm();
    }
  }, [t]);

  return (
    <ConfirmationDialogContext.Provider
      value={{
        openDialog,
      }}
    >
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const context = React.useContext(ConfirmationDialogContext);
  if (context === undefined) {
    throw new Error('useConfirmationDialog must be used within a ConfirmationDialogProvider');
  }
  return context;
};
