import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';

export const SuccessLinkPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      m='auto'
      p={2}
      justifyContent='center'
      height='100vh'
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <CheckCircleOutlineIcon sx={{ width: '5rem', height: '5rem' }} color='success' />
      <Typography variant='h3' sx={{ mt: 3, textAlign: 'center' }}>
        {t('linkCode.successLinkTitle')}
      </Typography>
      <Typography variant='h3' sx={{ mt: 2, textAlign: 'center' }}>
        {t('linkCode.successLinkSubTitle')}
      </Typography>
    </Box>
  );
};
