import config from '../../../../config/config';
import axios from 'axios';
import { PERMISSIONS } from '../../../constants';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Link } from '../../../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';

export interface SavelinkItemProps {
  id: number;
  name: string;
  link: string;
  order: number;
  linkTypeId: number;
  linkTemplateId?: number;
}

export const reorderLinksAPI = async (
  id: string,
  links: Link[],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(PERMISSIONS.WRITE.THEME_PROFILES),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}business/links`,
    {
      id,
      links,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response;
};
