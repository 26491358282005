import React from 'react';
import styled from 'styled-components';
import OnboardingHeading from '@/views/components/common/onboarding-heading';

interface Props {
  heading: string;
  subheading: string;
  gif: { url: string; text: string[] | string; underText?: string };
  secondGif?: { url: string; text: string; underText?: string };
}

export const ScreenGif = (props: Props) => {
  return (
    <Wrapper>
      <OnboardingHeading pageHeading={props.heading} pageHeadingSecond={props.subheading} />
      <GifsWrapper doubleGifs={props?.secondGif?.url !== undefined}>
        <GifWrapper>
          <GifText>
            {Array.isArray(props.gif.text) ? (
              <ol>
                {props.gif.text.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ol>
            ) : (
              props.gif.text
            )}
          </GifText>
          <Gif src={props.gif.url} />
          <GifText>{props.gif?.underText && props.gif.underText}</GifText>
        </GifWrapper>
        {props.secondGif?.url && (
          <GifWrapper>
            <GifText>{props.secondGif.text}</GifText>
            <Gif src={props.secondGif.url} />
            <GifText>{props.secondGif?.underText && props.secondGif.underText}</GifText>
          </GifWrapper>
        )}
      </GifsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  gap: 31px;
`;

const GifWrapper = styled.div`
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 390px;
  width: 560px;
  border: 6px solid #eff3fd;
  border-radius: 21px;

  @media (max-width: 767px) {
    border: none;
    width: 302px;
  }
`;

const Gif = styled.img`
  width: auto;
  max-height: 291px;
  border-radius: 21px;

  @media (max-width: 767px) {
    border: none;
    width: 240px;
  }
`;

const GifText = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: start;
  color: #181a5a;
  margin: 15px 40px;

  ol {
    list-style: auto;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const GifsWrapper = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;

  ${props =>
    props?.doubleGifs &&
    `
    @media (max-width: 1200px) {
    width: 300px;
  }
  `}
  @media (max-width: 767px) {
    width: 302px;
  }
`;
