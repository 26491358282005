import { useCallback, useEffect, useRef, useState } from 'react';
import AddNewFilePopup from '@/views/components/popups/add-new-file-popup';
import { Account, ApiFile } from '@/shared/types/api';
import FileSelector from '@/views/components/common/FileSelector';
import { useAppSelector } from '@/application/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ReactComponent as DragIndicatorIcon } from '@/views/images/dragIndicatorIcon.svg';
import { ThemeLinksFilesPosition } from '@/shared/types/global';
import { THEME_LINKS_FILES_POSITIONS } from '@/shared/constants';
import { Checkbox } from '@/views/components/generic';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { RowDiv, Spacer } from './common';
import FileBox from '../../edit-profile/fileBox';
import { getItemStyle } from '../../edit-profile';
import { getFiles } from '@/infrastructure/apis/files';
import { useTranslation } from 'react-i18next';
import { LtDialog } from '@/components';
import { Typography } from '@mui/material';

export interface UploadfileProps {
  id?: number;
  uploadedFile: File;
  fileName: string;
  embed: boolean;
  pageCount: number;
  link?: string;
}

export interface UploadfileTemplateProps {
  id: number;
  url: string;
  fileName: string;
  pageCount: number;
}

interface Props {
  setThemeFiles?(themeFiles: (ApiFile & { fileToUpload?: File })[]): void;
  themeFilesPosition: ThemeLinksFilesPosition;
  setThemeFilesPosition?(position: ThemeLinksFilesPosition): void;
  loadingCompleted?: Function;
  unitId?: number | null;
}
const reorder = <T extends ApiFile>(
  list: Array<T>,
  startIndex: number,
  endIndex: number,
): Array<any> => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((elements, idx) => ({
    ...elements,
    order: idx * 10,
  }));
};

const ProfileDesignFiles = (props: Props) => {
  const { t } = useTranslation();
  const { setThemeFiles, loadingCompleted, unitId } = props;

  const [loading, setLoading] = useState(true);
  const account = useAppSelector<Account>(state => state.account);

  const [savedTemplateFiles, setSavedTemplateFiles] = useState<
    (ApiFile & { fileToUpload?: File })[]
  >([]);

  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<ApiFile>();

  const { setIsUnsaved } = useUnsavedStatusSetter();

  const EndFilesRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();

  const totalFiles = savedTemplateFiles;

  const scrollToBottom = () => {
    EndFilesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true);
      try {
        const {
          data: { data: _files },
        } = await getFiles(getAccessTokenSilently, { unitId, unitLevelOnly: !!unitId });
        setSavedTemplateFiles(_files);
        setThemeFiles(_files);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      loadingCompleted?.();
    };
    fetchFiles();
  }, [getAccessTokenSilently, loadingCompleted, setThemeFiles, unitId]);

  const onDeleteFileClick = useCallback(file => {
    setSelectedFile(file);
    setShowDeleteFile(true);
  }, []);

  const [showIndividualFile, setShowIndividualFile] = useState(false);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const newItems = reorder(totalFiles, source.index, destination.index);

    setSavedTemplateFiles(newItems);
    props.setThemeFiles?.(newItems);
    setIsUnsaved(true);
  };

  const handleCompanySettingsUpdate = async (config: {
    fileType: 'newFile' | 'templateFile';
    templateFileDetails?: UploadfileTemplateProps;
    newFileDetails?: UploadfileProps;
  }) => {
    const templateFiles: (ApiFile & { fileToUpload?: File })[] = [
      ...savedTemplateFiles,
      {
        id: config.fileType === 'templateFile' ? Math.random() : -Math.random(),
        link:
          config.fileType === 'newFile'
            ? config.newFileDetails.link || ''
            : config.templateFileDetails.url,
        fileName:
          config.fileType === 'newFile'
            ? config.newFileDetails.fileName
            : config.templateFileDetails.fileName,
        embed: config.fileType === 'newFile' ? config.newFileDetails.embed : false,
        canDisplayOnProfile: true,
        order: savedTemplateFiles.length,
        pageCount:
          config.fileType === 'newFile'
            ? config.newFileDetails.pageCount
            : config.templateFileDetails.pageCount,
        fileTemplateId: config.fileType === 'newFile' ? null : config.templateFileDetails.id,
        fileToUpload: config.fileType === 'newFile' && config.newFileDetails?.uploadedFile,
      },
    ];
    setSavedTemplateFiles(templateFiles);
    props.setThemeFiles?.(templateFiles);
    setIsUnsaved(true);
  };

  const handleCompanySettingsFileDelete = (fileId: number) => {
    const templateFiles = savedTemplateFiles.filter(file => file.id !== fileId);
    setSavedTemplateFiles(templateFiles);
    props.setThemeFiles?.(templateFiles);
    setShowDeleteFile(false);
    setIsUnsaved(true);
  };

  return (
    <>
      {showIndividualFile && (
        <AddNewFilePopup
          isCompanydataSettings
          handleCompanySettingsUpdate={handleCompanySettingsUpdate}
          onCloseClick={() => setShowIndividualFile(false)}
        />
      )}
      <LtDialog
        open={Boolean(showDeleteFile && selectedFile)}
        onClose={() => setShowDeleteFile(false)}
        onCancel={() => setShowDeleteFile(false)}
        title={t('genericDelete')}
        size='sm'
        onDelete={() => handleCompanySettingsFileDelete(selectedFile.id)}
        withActionDivider
      >
        <Typography>{t('deleteFile')}</Typography>
      </LtDialog>
      {props.themeFilesPosition && props.setThemeFilesPosition && (
        <>
          <RowDiv>{t('md.profileDesign.themeFilesPosition.question')}</RowDiv>
          <div>
            {Object.values(THEME_LINKS_FILES_POSITIONS).map(pos => (
              <div key={pos}>
                <Checkbox
                  name={pos}
                  title={t('md.profileDesign.themeFilesPosition.' + pos)}
                  forcedCheckState={props.themeFilesPosition === pos}
                  onChange={() => props.setThemeFilesPosition(pos)}
                />
              </div>
            ))}
          </div>
          <Spacer size={32} />
        </>
      )}

      <RowDiv>{t('md.profileDesign.themeFiles.question')}:</RowDiv>
      <Spacer size={12} />
      <FileSelector
        setSavedTemplateFiles={setSavedTemplateFiles}
        savedTemplateFiles={savedTemplateFiles}
        setShowIndividualFile={setShowIndividualFile}
        scrollToBottom={scrollToBottom}
        totalFiles={savedTemplateFiles}
        isCompanydataSettings
        handleCompanySettingsUpdate={handleCompanySettingsUpdate}
      />
      {loading && <p>{t('Loading')}..</p>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='files'>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {savedTemplateFiles.map((item, index) => (
                <Draggable
                  key={item.id?.toString()}
                  draggableId={item.id?.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div
                        className='darg-icon'
                        style={{
                          marginTop: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        {...provided.dragHandleProps}
                      >
                        <DragIndicatorIcon />
                      </div>
                      <FileBox
                        key={index}
                        // @ts-ignore:next-line
                        item={item}
                        // @ts-ignore:next-line
                        onDeleteClick={() => onDeleteFileClick(item)}
                        bulkEdit
                        theme={account.theme}
                        blueBackground={typeof item.fileTemplateId === 'number'}
                        userIsAdmin
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Spacer ref={EndFilesRef} size={50} />
    </>
  );
};

export default ProfileDesignFiles;
