import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';

import { Container } from '../../../components/generic';
import { routePaths } from '../../../../infrastructure/constants';
import { Heading, SubHeading, TapBoxes, BodyWrapper } from './Common';
import { tapInfo } from './Constant';
import withNav from '../../../../infrastructure/hoc/withNav';
import useDesktopView from '../../../../infrastructure/hooks/useDesktopView';
import { Spacer } from '../../md/customization/common';

const HowToTap = () => {
  const { desktopView } = useDesktopView();
  const { t } = useAppTranslation();

  return (
    <Container fillHeight>
      <Spacer size={16} />
      <BodyWrapper>
        {!desktopView && <Heading value={t('HowToTap')} />}
        <SubHeading value={t('HowToTapDescription')} />
        <TapBoxes items={tapInfo} />
      </BodyWrapper>
    </Container>
  );
};

export default withNav(
  HowToTap,
  {
    tTitle: 'HowToTap',
    showBackButton: {
      linkTo: routePaths.NFC_CARDS,
    },
  },
  {},
);
