import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../../../application/hooks';
import { getUnits } from '../../../../infrastructure/apis/md/units';
import { Unit } from '../../../../shared/types/api/unit.type';
import { SubsectionHeader } from '../common';
import { Spacer } from '../customization/common';

interface Props {
  onChangeUnit: (unit: Unit) => void;
}

export const UnitSelector = (props: Props) => {
  const [units, setUnits] = useState<Array<Unit>>([]);
  const { user } = useAuth0();
  const { t } = useTranslation();
  const themeAdmin = user['https://lemontaps.com/claim/roles'].includes('theme_admin');
  const { getAccessTokenSilently } = useAuth0();
  const account = useAppSelector(state => state.account);
  useEffect(() => {
    if (!themeAdmin) {
      setUnits(account.units);
      props.onChangeUnit(account.units[0]);
      return;
    }
    getUnits(getAccessTokenSilently)
      .then(res => {
        toast.dismiss('loadingUnits');
        setUnits(res.data.data.units);
      })
      .catch(err => {
        toast.error(t('mdLeads.errorReadUnits'));
        setUnits([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getList = themeAdmin
    ? [{ value: 'no-unit', niceName: 'mdLeads.noUnit', id: 0 }, ...units]
    : units;
  return (
    <>
      <SubsectionHeader>{t('mdLeads.unit')}</SubsectionHeader>
      <SelectContainer>
        <Select
          name='unit'
          id='unit'
          onChange={e => {
            props.onChangeUnit(units.find(x => x?.niceName === e.target.value));
          }}
        >
          {getList.map((unit: Unit) => {
            return (
              <option key={unit.niceName} value={unit.niceName}>
                {unit.id === 0 ? t(unit.niceName) : unit.niceName}
              </option>
            );
          })}
        </Select>
      </SelectContainer>
      <Spacer size={15} />
      <hr />
    </>
  );
};

const SelectContainer = styled.div`
  position: relative;
  border: 1px solid #bbbbc1;
  border-radius: 30px;
`;

const Select = styled.select`
  margin-right: 5%;
  padding-left: 5%;
  padding-right: 4%;
  margin-bottom: 0;
`;
