import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getContactFormTranslationKeyByField } from '@/shared/util';
import { toast } from 'react-hot-toast';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Grid } from '@mui/material';
import { PageHeader } from '../common';
import { Spacer } from './common';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type Fields = {
  required: string[];
  optional: string[];
  notShown: string[];
};

const titleMapT = {
  required: 'contactFormEdit.required',
  optional: 'contactFormEdit.optional',
  notShown: 'contactFormEdit.notShown',
};

interface Props {
  fields: Fields;
  onSortEnd: (fields: Fields) => void;
  title: string;
}

const ContactFieldsSorter = ({ fields, title, onSortEnd }: Props) => {
  const handleDragEnd = (result: any) => {
    if (!result.source || !result.destination) {
      // dragged from/to wrong pane
      toast(t('contactFormEdit.infoDraggedToWrongPane'), {
        icon: <AiFillInfoCircle size={16} color='#F8E363' />,
      });
      return;
    }
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const sourceArr = fields[result.source.droppableId as keyof typeof fields].slice();
    const sourceIndex = result.source.index;
    const [removed] = sourceArr.splice(sourceIndex, 1);

    const destinationArr =
      result.source.droppableId === result.destination.droppableId
        ? sourceArr
        : fields[result.destination.droppableId as keyof typeof fields].slice();
    const destinationIndex = result.destination.index;
    destinationArr.splice(destinationIndex, 0, removed);

    onSortEnd({
      ...fields,
      [result.source.droppableId]: sourceArr,
      [result.destination.droppableId]: destinationArr,
    });
  };

  const { t } = useTranslation();

  return (
    <Grid container spacing={2} mt={2}>
      <DragDropContext onDragEnd={handleDragEnd}>
        {Object.keys(fields).map((key, index) => {
          const currentFields = fields[key];
          return (
            <Grid item xs={4}>
              <PageHeader>{t(titleMapT[key as keyof typeof titleMapT])}</PageHeader>
              <Spacer size={9} />
              <Droppable droppableId={key}>
                {(provided, snapshot) => (
                  <StyledListContainer ref={provided.innerRef} {...provided.droppableProps}>
                    {currentFields.map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided, snapshot) => (
                          <StyledListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              wordWrap: 'word-break',
                              hyphens: 'auto',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <DragIndicatorIcon
                              sx={{ width: '2.5rem', height: '2.5rem', mr: '1rem' }}
                            />
                            {t(getContactFormTranslationKeyByField(item))}
                          </StyledListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </StyledListContainer>
                )}
              </Droppable>
            </Grid>
          );
        })}
      </DragDropContext>
    </Grid>
  );
};

export default ContactFieldsSorter;

const StyledListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem 2rem 1rem 0;
  margin: 0 0 16px 0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: grab;
  border: 1px solid #f5f5f5;
  &:hover {
    background-color: #f5f5f5;
  }
`;
