import { routePaths } from '@/infrastructure/constants';
import { ChevronRight } from '@mui/icons-material';
import { Divider, ListItemButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const AdminManagementButton = () => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <>
      <Divider sx={{ borderColor: 'inherit', opacity: '0.15', mb: '0.4rem' }} />

      <ListItemButton
        onClick={() => history.push(routePaths.UPGRADE_TEAMS)}
        sx={{
          height: '4rem',
          justifyContent: 'space-between',
          ':hover': { bgcolor: 'initial' },
        }}
      >
        <Typography variant='body1' color='inherit'>
          {t('testBusinessSolution')}
        </Typography>
        <ChevronRight />
      </ListItemButton>
    </>
  );
};
