import React, { HTMLInputTypeAttribute, useState } from 'react';
import styled from 'styled-components';
import { TextField as TextFieldMU, InputAdornment } from '@mui/material';
import {
  isAlphabetsWithSpecificChars,
  isAlphaNumeric,
  isAlphaNumericLowerCase,
  isOnlyNumbers,
  isOnlyNumbersAndSpaces,
  isOnlyNumbersAndSpacesAndDashes,
} from '@/infrastructure/helper';
import { TextFieldTypes, InputRestrictions } from './constants';
import { TextFieldTypesType, InputRestrictionsType } from './constants.types';
import { ObjVal } from '@/shared/types/util';

import UserIcon from '@/views/images/textbox-icons/user.svg';
import SearchIcon from '@/views/images/textbox-icons/search.png';
import EmailIcon from '@/views/images/textbox-icons/mail.svg';
import BioIcon from '@/views/images/textbox-icons/bio.svg';
import BioTextIcon from '@/views/images/textbox-icons/bio-text.svg';
import CompanyIcon from '@/views/images/textbox-icons/company.svg';
import PhoneIcon from '@/views/images/textbox-icons/phone.png';
import CodeIcon from '@/views/images/textbox-icons/lock.png';

import InstagramIcon from '@/views/images/textbox-icons/instagram.png';
import FacebookIcon from '@/views/images/textbox-icons/facebook.png';
import TwitterIcon from '@/views/images/textbox-icons/twitter.png';
import LinkedInIcon from '@/views/images/textbox-icons/linkedin.png';
import TiktokIcon from '@/views/images/textbox-icons/tiktok.png';
import SnapchatIcon from '@/views/images/textbox-icons/snapchat.png';
import SpotifyIcon from '@/views/images/textbox-icons/spotify.png';
import SoundCloudIcon from '@/views/images/textbox-icons/soundcloud.png';
import YouTubeIcon from '@/views/images/textbox-icons/youtube.png';
import PayPalIcon from '@/views/images/textbox-icons/paypal.png';
import AppleMusicIcon from '@/views/images/textbox-icons/applemusic.png';
import WebsiteIcon from '@/views/images/textbox-icons/website.png';

import TooltipInfo from '../TooltipInfo';
import { validateEmail } from '@/shared/util';
import { useMuiTheme } from '@/config/theme/useMuiTheme';

export interface Props {
  error?: boolean;
  helperText?: string;
  name: string;
  placeholder?: string;
  label?: string;
  labelShrink?: boolean;
  value?: string;
  type?: ObjVal<TextFieldTypesType>;
  inputRestrictions?: ObjVal<InputRestrictionsType>;
  inputType?: HTMLInputTypeAttribute;
  pattern?: string | undefined;
  inputmode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  isRequired?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  tooltip?: string;
  multiline?: boolean;
  rows?: number;
  customClassName?: string;
  autoFocus?: boolean;
  maxLength?: number;
  disabled?: boolean;
  prefixText?: '+' | '@';
  font?: { size?: string; color?: string; weight?: string };
  bgColor?: { normal?: string; hover?: string };
  border?: { normal?: string; focus?: string; bottom?: boolean };
  angles?: 'normal' | 'curved';
  boxShadow?: string;
  margin?: { all?: string };
  padding?: { all?: string; top?: string; left?: string };
  width?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

interface IProps {
  $prefixText?: Props['prefixText'];
  $font?: Props['font'];
  $bgColor?: Props['bgColor'];
  $border?: Props['border'];
  $angles?: Props['angles'];
  $boxShadow?: Props['boxShadow'];
  $fieldType?: Props['type'];
  $label?: Props['label'];
  $margin?: Props['margin'];
  $padding?: Props['padding'];
  $width?: Props['width'];
}

interface ToolTipProps {
  tooltip?: string;
  showTooltip?: boolean;
  children: JSX.Element;
}
const FieldWrapper = (props: ToolTipProps) => {
  return props.tooltip ? (
    <TooltipInfo
      text={props.tooltip}
      onHover={true}
      placement='left'
      arrow
      style={{ marginTop: '2rem !important' }}
    >
      {props.children}
    </TooltipInfo>
  ) : (
    <>{props.children}</>
  );
};

export const TextField: React.FC<Props> = ({
  error,
  helperText,
  type,
  inputType = 'text',
  inputRestrictions,
  angles = 'normal',
  placeholder,
  label,
  labelShrink = true,
  isRequired = false,
  fullWidth,
  variant = 'filled',
  tooltip,
  prefixText,
  bgColor,
  font,
  border,
  margin,
  padding,
  width,
  boxShadow,
  multiline = false,
  rows,
  customClassName,
  autoFocus,
  maxLength,
  disabled,
  onChange,
  onBlur,
  onFocus,
  inputRef,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnBlur = e => {
    if (isRequired) {
      const value = e.target.value;
      let isValid = value.length > 0;

      if (type === TextFieldTypes.EMAIL && isValid) isValid = validateEmail(value);

      setIsValid(isValid);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = e => {
    let isValid = true;
    if (inputRestrictions) {
      if (inputRestrictions === InputRestrictions.ONLY_ALPHABETS)
        isValid = isAlphabetsWithSpecificChars(e.target.value);
      else if (inputRestrictions === InputRestrictions.ONLY_NUMBERS)
        isValid = isOnlyNumbers(e.target.value);
      else if (inputRestrictions === InputRestrictions.ONLY_NUMBERS_AND_SPACES)
        isValid = isOnlyNumbersAndSpaces(e.target.value);
      else if (inputRestrictions === InputRestrictions.ONLY_NUMBERS_AND_SPACES_AND_DASHES)
        isValid = isOnlyNumbersAndSpacesAndDashes(e.target.value);
      else if (inputRestrictions === InputRestrictions.ALPHA_NUMERIC)
        isValid = isAlphaNumeric(e.target.value);
      else if (inputRestrictions === InputRestrictions.ALPHA_NUMERIC_LOWERCASE)
        isValid = isAlphaNumericLowerCase(e.target.value);
    }

    if (isValid === true) {
      if (onChange) onChange(e);
    }
  };

  const getClassName = () => {
    let className = type ? type.toString() : '';
    className += ' ' + customClassName;
    if (!isValid) className += ' input-error';

    return className;
  };

  const textFieldType = type === TextFieldTypes.PASSWORD ? 'password' : inputType;

  const defFont: Props['font'] = { size: '1.2rem', color: '#181a5a', weight: '400' };
  const defBgColor: Props['bgColor'] = { normal: 'rgba(239, 243, 253, 0.7)', hover: '#fff' };
  const defMargin: Props['margin'] = { all: '2rem 0 !important' };

  const { theme: muiTheme } = useMuiTheme();

  return (
    <FieldWrapper tooltip={tooltip} showTooltip={showTooltip}>
      <TextInput
        inputProps={{
          className: 'custom-input',
        }}
        error={error}
        helperText={helperText}
        variant={variant}
        type={textFieldType}
        placeholder={placeholder}
        label={label}
        InputLabelProps={{
          shrink: labelShrink,
        }}
        multiline={multiline}
        rows={rows || (multiline ? 3 : 1)}
        onFocus={onFocus}
        fullWidth={fullWidth}
        {...rest}
        autoComplete='off'
        className={getClassName()}
        onChange={e => handleOnChange(e)}
        onBlur={e => handleOnBlur(e)}
        onKeyDown={tooltip ? () => setShowTooltip(false) : null}
        onMouseEnter={tooltip ? () => setShowTooltip(true) : null}
        onMouseLeave={tooltip ? () => setShowTooltip(false) : null}
        autoFocus={autoFocus ? true : false}
        maxLength={maxLength ?? null}
        disabled={disabled}
        inputRef={inputRef}
        $prefixText={!!prefixText}
        $font={{ ...defFont, ...font }}
        $bgColor={{ ...defBgColor, ...bgColor }}
        $border={border}
        $angles={angles}
        $boxShadow={boxShadow}
        $margin={{ ...defMargin, ...margin }}
        $padding={padding}
        $width={width}
        $fieldType={type}
        InputProps={{
          ...(type && {
            startAdornment: (
              <InputAdornment position='start'>
                <i className={type.toString()}></i>
              </InputAdornment>
            ),
          }),
          ...(isRequired && {
            endAdornment: isRequired && (
              <RequiredIconWrapper color={muiTheme.palette.primary.main}>*</RequiredIconWrapper>
            ),
          }),
          ...(prefixText && {
            startAdornment: (
              <Prefix position='start' style={{ marginTop: !label && -2 }}>
                {prefixText}
              </Prefix>
            ),
          }),
        }}
      />
    </FieldWrapper>
  );
};
const TextInput = styled(TextFieldMU)`
  font-family: inherit;
  box-sizing: border-box;
  margin: ${(props: IProps) => props.$margin.all};
  flex-grow: 100;
  background-color: ${props => !props.disabled && props.$bgColor.normal};
  border: ${(props: IProps) => props.$border?.normal ?? null};
  border-radius: ${(props: IProps) =>
    props.$angles === 'normal' ? '5px !important' : '3rem !important'};
  box-shadow: ${(props: IProps) => props?.$boxShadow ?? null};
  ${(props: IProps) => props.$width && `width: ${props.$width}`}

  input,
  textarea {
    padding-left: ${(props: IProps) =>
      !props?.$padding?.left
        ? !props?.$prefixText
          ? props?.$fieldType && '4rem !important'
          : '0 !important'
        : props.$padding.left};
    margin-bottom: unset !important;
    border: unset !important;
    border-radius: unset !important;
    color: ${(props: IProps) => props.$font.color};
    font-style: normal;
    font-weight: ${(props: IProps) => props.$font.weight};
    font-size: ${(props: IProps) => props.$font.size + ' !important'};
    ${(props: Props) => props.label && `padding-top: 25px`};
    padding-top: ${(props: IProps) => props?.$padding?.top ?? null};
    box-shadow: unset !important;
    line-height: normal;
    & ::placeholder {
      font-family: inherit;
    }

    & :focus,
    & :hover,
    & :visited {
      outline: none !important;
    }
    white-space: normal !important;

    &[disabled] {
      color: rgb(170, 170, 170);
      -webkit-text-fill-color: rgb(170, 170, 170);
    }
  }
  outline: none !important;
  white-space: nowrap !important;
  fieldset {
    border: unset !important;
  }
  .MuiInputBase-multiline {
    padding-top: 1rem;
  }
  :focus-within {
    border: ${(props: IProps) => props.$border?.focus ?? null};
  }
  & :hover:not(i):not(span) {
    border-radius: ${(props: IProps) =>
      props.$angles === 'normal' ? '5px !important' : '3rem !important'};
    box-shadow: ${(props: IProps) => props?.$boxShadow ?? null};

    /* What's the use of this? */
    /* background-color: ${(props: IProps) => props.$bgColor.hover}; */
  }

  & :focus {
    border-radius: ${(props: IProps) =>
      props.$angles === 'normal' ? '5px !important' : '3rem !important'};
    outline: none !important;
    background-color: transparent !important;
  }
  div {
    &:before {
      border-bottom: ${(props: IProps) =>
        props?.$border?.bottom ? '1px solid #D9D9D9' : 'unset !important'};
    }
    padding-right: unset;
    margin-left: unset;
    background-color: unset;
  }
  label {
    font-size: 1.5rem;
    color: #181a5a;
    opacity: 0.45; // TODO this was done via "how it looks" (the birthday field in the lead gen form is rendered different)
  }
  .input-error {
    border: 2px solid #e02020 !important;
    box-shadow: none !important;
  }

  .form-input {
    position: relative;
  }

  /* VARIANT OUTLINED TAKES OUT DISABLED CLASS */
  ${props =>
    props.disabled &&
    props.variant === 'outlined' &&
    `
    background-color: rgba(0,0,0,0.12);
  `}

  i.user {
    background: url(${UserIcon}) no-repeat;
    width: 24px;
    height: 24px;
    top: 15px;
    left: 15px;
  }

  i.search {
    background: url(${SearchIcon}) no-repeat;
    width: 20px;
    height: 20px;
    top: 18px;
    left: 18px;
  }

  i.email {
    background: url(${EmailIcon}) no-repeat;
    width: 20px;
    height: 16px;
    top: 19px;
    left: 18px;
  }

  i.bio {
    background: url(${BioIcon}) no-repeat;
    width: 20px;
    height: 20px;
    top: 16px;
    left: 18px;
  }

  i.bio-text {
    background: url(${BioTextIcon}) no-repeat;
    transform: scale(1.2);
    width: 20px;
    height: 20px;
    top: 20px;
    left: 19px;
  }

  i.company {
    background: url(${CompanyIcon}) no-repeat;
    width: 20px;
    height: 20px;
    top: 16px;
    left: 18px;
  }

  i.phone {
    background: url(${PhoneIcon}) no-repeat;
    width: 21px;
    height: 20px;
    top: 17px;
    left: 17px;
  }

  i.code {
    background: url(${CodeIcon}) no-repeat;
    width: 16px;
    height: 20px;
    top: 15px;
    left: 18px;
    position: absolute;
    z-index: 1;
  }

  i.user,
  i.email,
  i.instagram,
  i.facebook,
  i.twitter,
  i.linkedin,
  i.snapchat,
  i.spotify,
  i.facebook,
  i.soundcloud,
  i.paypal,
  i.applemusic,
  i.tiktok,
  i.twitter,
  i.youtube,
  i.website,
  i.bio,
  i.bio-text,
  i.company,
  i.phone,
  i.search {
    display: inline-block;
    position: absolute;
    z-index: 1;
  }

  i.instagram,
  i.linkedin {
    width: 24px;
    height: 24px;
    top: 15px;
    left: 17px;
  }

  i.tiktok,
  i.twitter,
  i.website {
    width: 20px;
    height: 20px;
    top: 16px;
    left: 18px;
  }

  i.spotify,
  i.facebook,
  i.soundcloud,
  i.paypal,
  i.applemusic,
  i.youtube,
  i.snapchat {
    width: 22px;
    height: 22px;
    top: 16px;
    left: 18px;
  }

  i.instagram {
    background: url(${InstagramIcon}) no-repeat;
    color: #e1306c;
  }

  i.facebook {
    background: url(${FacebookIcon}) no-repeat;
    color: #1877f2;
  }

  i.twitter {
    background: url(${TwitterIcon}) no-repeat;
    color: #1da1f2;
  }

  i.linkedin {
    background: url(${LinkedInIcon}) no-repeat;
    color: #0077b5;
  }

  i.tiktok {
    background: url(${TiktokIcon}) no-repeat;
    color: #fe2c55;
  }

  i.snapchat {
    background: url(${SnapchatIcon}) no-repeat;
    color: #fffc00;
  }

  i.spotify {
    background: url(${SpotifyIcon}) no-repeat;
    color: #1db954;
  }

  i.soundcloud {
    background: url(${SoundCloudIcon}) no-repeat;
    color: #ff8800;
  }

  i.youtube {
    background: url(${YouTubeIcon}) no-repeat;
    color: #ff0000;
  }

  i.paypal {
    background: url(${PayPalIcon}) no-repeat;
    color: #003087;
  }

  i.applemusic {
    background: url(${AppleMusicIcon}) no-repeat;
    color: #f44e48;
  }

  i.website {
    background: url(${WebsiteIcon}) no-repeat;
    color: #203376;
  }

  i.required-label {
    position: absolute;
    right: 2rem;
    top: 17px;
    font-size: 18px;
    color: red;
  }
  .MuiTooltip-tooltip {
    font-family: inherit;
    font-size: 0.7em !important;
    text-align: center;
    white-space: pre-line;
    background: linear-gradient(90deg, #02e0b1 0%, #01efcb 100%);
    background: #181a5a !important;
  }

  .MuiTooltip-arrow {
    color: rgb(0, 194, 124) !important;
    color: #181a5a !important;
  }
`;

const RequiredIconWrapper = styled.span`
  font-size: 20px;
  color: ${p => p.color};
  padding-right: 2rem;
  height: 1rem;
`;

const Prefix = styled(InputAdornment)`
  background: rgba(239, 243, 253, 0.7);
  p {
    font-size: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 2rem 0 !important;
  div {
    margin: unset !important;
    flex: 1;
  }
  div ~ div {
    margin-left: 1rem !important;
  }
`;
