import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { EmailTemplatesEnum, FEATURE, THEME_CONFIG_KEYS } from '@/shared/constants';
import { HierarchyLevel, UnitOverrideRightsKey, UnitOverrideSettings } from '@/shared/types/api';
import {
  deleteThemeConfig,
  fetchThemeConfig,
  writeThemeConfig,
} from '@/infrastructure/apis/md/customization/jsonConfigs';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { SettingsContext } from '../Settings/SettingsWrapper/context';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { Box, Divider, Typography } from '@mui/material';
import SwitchRow from './SwitchRow';
import { getUnitEditRightsConfig } from './constants';
import LtActionButtonBar from '@/components/LtActionButtonBar';
const GROUPED = {
  EMAIL_TEMPLATES: 'email-templates',
  EDIT_RIGHTS: 'edit-rights',
} as const;

type UnitOverrideSettingsGrouped = UnitOverrideSettings & {
  [GROUPED.EDIT_RIGHTS]: boolean;
  [GROUPED.EMAIL_TEMPLATES]: boolean;
};

export const UnitEditRightsPage = () => {
  const {
    selectedUnit,
    setSettingsHierarchyLevel,
    deleteButtonIsVisible,
    deleteClickHandler,
    setSettingsLoading,
    settingsHierarchyLevel,
    canEdit,
  } = useContext(SettingsContext);
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();

  const isUnitsFeatureAllowed = isFeatureAllowed(FEATURE.UNITS);

  const selectedUnitId = selectedUnit?.id || null;
  const [unitOverrideSettings, setUnitOverrideSettings] =
    useState<UnitOverrideSettingsGrouped | null>(null);

  const canEditUnitEditRights = isUnitsFeatureAllowed && isThemeAdmin;

  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();
  const flag_userDefinedTranslations = usePrivateFeatureFlag('userDefinedTranslations');

  const unitEditRightsConfig = getUnitEditRightsConfig(isFeatureAllowed).filter(
    conf => conf.themeConfigKey !== 'user-defined-translations' || flag_userDefinedTranslations,
  );

  const conditionalThemeConfigSubEditRights = unitEditRightsConfig
    .filter(c => !!c.conditionalThemeConfigGroup)
    .filter(c => isFeatureAllowed(c.requiredFeature) && unitOverrideSettings?.[c.themeConfigKey])
    .map(c => c.conditionalThemeConfigGroup);

  const handleChange = (key: UnitOverrideRightsKey) => {
    withUnsavedSetter(value => setUnitOverrideSettings(value));
    setUnitOverrideSettings({ ...unitOverrideSettings, [key]: !unitOverrideSettings[key] });
  };

  const fetchData = useCallback(
    async (hideLoader?: boolean) => {
      if (!hideLoader) setSettingsLoading(true);
      try {
        const {
          value: unitOverrideSettings,
          meta: { hierarchyLevel },
        } = await fetchThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.OVERRIDE_SETTINGS,
          fetchThemeLevel: true,
          unitId: selectedUnitId,
        });
        setUnitOverrideSettings(groupConfigKeys(unitOverrideSettings));
        setSettingsHierarchyLevel(hierarchyLevel);
      } catch (error) {
        toast.error(t('error.general'));
      }
      setSettingsLoading(false);
    },
    [getAccessTokenSilently, selectedUnitId, setSettingsHierarchyLevel, setSettingsLoading, t],
  );

  useEffect(() => {
    if (canEditUnitEditRights) {
      fetchData();
    }
  }, [canEditUnitEditRights, fetchData]);

  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      await writeThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.OVERRIDE_SETTINGS,
        value: ungroupConfigKeys(unitOverrideSettings),
        unitId: selectedUnitId,
      });

      toast.success(t('changesSaved'));
      fetchData(true);
      setIsUnsaved(false);
    } catch (error) {
      toast.error(t('error.general'), { id: 'edit-rights-save' });
      return;
    }
    setSaving(false);
  };

  const handleDelete = async () => {
    try {
      await deleteThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.OVERRIDE_SETTINGS,
        unitId: selectedUnitId,
        withDeleteUnitConfig: true,
      });
      await fetchData(true);
      toast.success(t('changesSaved'));
    } catch (error) {
      toast.error(t('error.general'));
    }
  };

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ mt: 4 }}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
          <Typography variant='h2'>{t('editRights.unitHeading')}</Typography>
        </Box>
        {unitEditRightsConfig.map((conf, index) => (
          <SwitchRow
            title={index + 1 + '. ' + t(conf.tLabel)}
            checked={!!unitOverrideSettings?.[conf.themeConfigKey]}
            onChange={() => withUnsavedSetter(handleChange(conf.themeConfigKey))}
            key={index}
          />
        ))}
      </Box>
      {conditionalThemeConfigSubEditRights.map(subEditRightsConfig => (
        <>
          <Divider sx={{ mt: 2 }} />
          <Box display={'flex'} flexDirection={'column'} mt={2}>
            <Typography variant='h3' mb={2}>
              {t(subEditRightsConfig.tHeading)}
            </Typography>
            <Typography variant='body2' color='text.primary' mb={1}>
              {t(subEditRightsConfig.tSubHeading)}
            </Typography>
            {subEditRightsConfig.suffixes.map(suffix => {
              const themeConfigKey = `${subEditRightsConfig.prefix}-${suffix}` as THEME_CONFIG_KEYS;
              return (
                <SwitchRow
                  title={t(subEditRightsConfig.tPrefix + suffix)}
                  checked={!!unitOverrideSettings?.[themeConfigKey]}
                  onChange={() => handleChange(themeConfigKey)}
                />
              );
            })}
          </Box>
        </>
      ))}
      {canEdit && (
        <LtActionButtonBar
          saveAction={{
            loading: saving,
            onClick: handleSave,
          }}
          deleteAction={
            deleteButtonIsVisible && {
              onClick: () => deleteClickHandler(handleDelete),
              text: t(
                settingsHierarchyLevel === HierarchyLevel.Unit ? 'deleteSettings' : 'resetSettings',
              ),
            }
          }
        />
      )}
    </>
  );
};

const groupConfigKeys = (overrideSettings: UnitOverrideSettings): UnitOverrideSettingsGrouped => {
  const {
    [THEME_CONFIG_KEYS.EDIT_RIGHTS]: _editRights,
    [THEME_CONFIG_KEYS.BLOCKED_BIO_FIELDS]: _blockedBioFields,
    [THEME_CONFIG_KEYS.BLOCKED_LINK_TYPES]: _blockedLinkTypes,
    [EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE]: _welcomeEmailTemplate,
    [EmailTemplatesEnum.CONTACT_RECEIVED_EMAIL_TEMPLATE]: _contactReceivedEmailTemplate,
    [EmailTemplatesEnum.CONTACT_SHARE_EMAIL_TEMPLATE]: _contactShareEmailTemplate,
    ...restConfig
  } = overrideSettings;

  return {
    ...restConfig,
    [GROUPED.EMAIL_TEMPLATES]:
      _welcomeEmailTemplate && _contactReceivedEmailTemplate && _contactShareEmailTemplate,
    [GROUPED.EDIT_RIGHTS]: _editRights && _blockedBioFields && _blockedLinkTypes,
  };
};

const ungroupConfigKeys = (
  groupdOverrideSettings: UnitOverrideSettingsGrouped,
): UnitOverrideSettings => {
  const {
    [GROUPED.EDIT_RIGHTS]: _editRights,
    [GROUPED.EMAIL_TEMPLATES]: _emailTemplates,
    ...restConfig
  } = groupdOverrideSettings;

  return {
    ...restConfig,
    [THEME_CONFIG_KEYS.EDIT_RIGHTS]: _editRights,
    [THEME_CONFIG_KEYS.BLOCKED_BIO_FIELDS]: _editRights,
    [THEME_CONFIG_KEYS.BLOCKED_LINK_TYPES]: _editRights,
    [EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE]: _emailTemplates,
    [EmailTemplatesEnum.CONTACT_RECEIVED_EMAIL_TEMPLATE]: _emailTemplates,
    [EmailTemplatesEnum.CONTACT_SHARE_EMAIL_TEMPLATE]: _emailTemplates,
  };
};
