//@ts-nocheck
import { dispatchTypes } from '../actions';

const accountReducer = (state = {}, action) => {
  let account = action.account;
  switch (action.type) {
    case dispatchTypes.COMMON.UPDATEACCOUNT:
      return { ...state, ...account };
    case dispatchTypes.COMMON.UPDATEFIELD:
      return {
        ...state,
        ...{
          ...state.account,
          [action.field]: action.value,
        },
      };
    case dispatchTypes.COMMON.UPDATEBIODETAILS:
      return { ...state, ...{ ...action.account } };
    case dispatchTypes.BUSINESS.THEME_INTERNAL.UPDATE_FIELDS:
      return {
        ...state,
        theme: {
          ...state.theme,
          themeInternal: {
            ...state.theme.themeInternal,
            ...action.payload,
          },
        },
      };
    case dispatchTypes.QRBG.THEMEBGIMGSINIT:
      return {
        ...state,
        theme: {
          ...state.theme,
          qrBg: {
            ...state.theme.qrBg,
            loading: true,
          },
        },
      };
    case dispatchTypes.QRBG.SETTHEMEBGIMGSSUCCESS:
      return {
        ...state,
        theme: {
          ...state.theme,
          qrBg: {
            ...state.theme.qrBg,
            loading: false,
            images: action.themeQRBGImgs,
          },
        },
      };
    case dispatchTypes.QRBG.SETTHEMEBGIMGSERROR:
      return {
        ...state,
        theme: {
          ...state.theme,
          qrBg: {
            ...state.theme.qrBg,
            loading: false,
            error: action.apiResponse,
          },
        },
      };
    case dispatchTypes.COMMON.SETTHEMEDEFAULTADDRESSTEMPLATE:
      return {
        ...state,
        theme: {
          ...state.theme,
          themeInternal: {
            ...state.theme.themeInternal,
            defaultAddressTemplateId: action.data.defaultAddressTemplate,
          },
        },
      };
    default:
      return state;
  }
};

export default accountReducer;
