import styled from 'styled-components';
import { Link } from '../../../../../../../shared/types/api';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DragIndicatorIcon } from '../../../../../../images/dragIndicatorIcon.svg';
import useReorder from '../hooks/useReorder';
import { Dispatch, SetStateAction } from 'react';
import { CustomLinkType } from '../hooks/useBulk';

interface Props {
  links: Link[];
  renderLinks: (links: Link[]) => JSX.Element[];
  setUserLinks?: Dispatch<SetStateAction<Array<Link | CustomLinkType>>>;
  onDragEnd?: (links: Link[]) => void;
}

const DnD = (props: Props) => {
  const { links, setUserLinks, renderLinks, onDragEnd } = props;
  const { orderedLinks, onDragEndLinks } = useReorder(links, setUserLinks, onDragEnd);
  const renderedLinks = renderLinks(orderedLinks);

  return (
    <DragDropContext onDragEnd={onDragEndLinks}>
      <Droppable
        droppableId='links'
        renderClone={(provided, _, rubric) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            {renderedLinks.find(key => key.key === renderedLinks[rubric.source.index].key)}
          </div>
        )}
      >
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {renderedLinks?.map((link: JSX.Element, index: number) => (
              <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                {(provided, _) => (
                  <DraggableContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragHandle {...provided.dragHandleProps}>
                      <DragIndicatorIcon />
                    </DragHandle>
                    {link}
                  </DraggableContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnD;

const DraggableContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-bottom: 1rem;
  }
`;

const DragHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
