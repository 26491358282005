import React from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

// TODO: profile page optimizations
// adapt resemblence

const StaticProfileSkeletonPage = () => {
  return (
    <SkeletonWrapper>
      <BannerSkeleton animation='wave' variant='rectangular' />
      <HeaderSkeleton>
        <CardHeader
          avatar={<Skeleton animation='wave' variant='circular' width={105} height={105} />}
        />
        <HeaderContentSkeleton>
          <React.Fragment>
            <Skeleton variant='text' width={200} height={20} />
            <Skeleton variant='text' width={130} height={15} />
            <Skeleton variant='text' width={100} height={15} />

            <HeaderButtonSkeleton variant='rectangular' width={150} height={32} />
          </React.Fragment>
        </HeaderContentSkeleton>
      </HeaderSkeleton>
      <ContentSkeleton>
        <CardContent>
          <React.Fragment>
            {/* <p>My Contact Details</p> */}

            <Skeleton width={150} height={20} />
            <Skeleton height={12} />
            <Skeleton height={12} />
            <Skeleton height={12} />
            <Skeleton height={12} />
          </React.Fragment>
        </CardContent>
      </ContentSkeleton>
      <ContentSkeleton>
        <CardContent>
          <React.Fragment>
            {/* <p>My Links</p> */}
            <Skeleton width={150} height={20} />
            <LinkSkeleton variant='rectangular' height={35} />
            <LinkSkeleton variant='rectangular' height={35} />
          </React.Fragment>
        </CardContent>
      </ContentSkeleton>
    </SkeletonWrapper>
  );
};

export default StaticProfileSkeletonPage;

const SkeletonWrapper = styled.div`
  /* OVERRIDES */
  .MuiCardHeader-root {
    position: relative !important;
    top: -70px !important;
  }

  .MuiCardHeader-avatar span {
    border: 5px solid #fff !important;
    background: lightgray;
    left: 10px !important;
  }

  .MuiCardContent-root {
    padding: 20px !important;
  }
`;

const BannerSkeleton = styled(Skeleton)`
  margin: 0 auto;
  max-width: 527px;
  max-height: 293px;
  height: 293px !important;
  z-index: 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const HeaderSkeleton = styled(Card)`
  margin: 0 auto;
  max-width: 501px;
  height: 180px !important;
  max-height: 180px;
  position: relative;
  background: #ffffff;
  z-index: 10;
  top: -10rem;
  border-radius: 10px !important;
  overflow: visible !important;
`;

const HeaderButtonSkeleton = styled(Skeleton)`
  position: absolute !important;
  border-radius: 10px;
  top: -35px !important;
  right: 20px !important;
  margin: 0;
`;

const HeaderContentSkeleton = styled(CardContent)`
  margin: 0 auto;
  max-width: 501px;
  position: relative !important;
  top: -80px !important;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  border-radius: 10px !important;
  min-height: 110px !important;
`;

const ContentSkeleton = styled(Card)`
  margin: 0 auto;
  max-width: 501px;
  position: relative !important;
  top: -100px !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  border-radius: 10px !important;
  min-height: 110px !important;
`;

const LinkSkeleton = styled(Skeleton)`
  border-radius: 30px;
  margin: 5px 0px !important;
`;
