import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '../../../shared/types/api';
import { themeCss } from '../../../util';
import { getFullLink, invertHex } from '@/infrastructure/helper';
import { ThemeBoxStyle } from '@/shared/types/global';

interface Props {
  item: Link;
  boxStyle: ThemeBoxStyle;
  trackHandler: () => void;
  genericWebsiteColor: string;
}

const LinkBoxThemed: React.FC<Props> = ({ item, boxStyle, trackHandler, genericWebsiteColor }) => {
  const displayName = item.name;
  const bgCss = item.linkType.backgroundCss || genericWebsiteColor || '#152570';
  const txtColor = invertHex(bgCss, true);
  return (
    <a
      href={getFullLink(item.link, item.linkType)}
      target='_blank'
      rel='noopener noreferrer'
      onClick={trackHandler}
    >
      <SocialMediaWrapper theme={themeCss(boxStyle)} backgroundCss={bgCss} $txtColor={txtColor}>
        <Image src={item.linkType.iconUrl} alt='' $txtColor={txtColor} />
        <Text>{displayName}</Text>
      </SocialMediaWrapper>
    </a>
  );
};

export default LinkBoxThemed;

const SocialMediaWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  color: ${props => props.$txtColor};
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  height: fit-content;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  background: ${(props: { backgroundCss: string }) => props.backgroundCss};
  ${(props: { theme: string }) =>
    props.theme === 'theme-rounded'
      ? css`
          border-radius: 30px;
        `
      : css`
          border-radius: 0;
        `}
`;

const Image = styled.img`
  position: absolute;
  left: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  filter: brightness(0) ${props => props.$txtColor.toLowerCase() === '#ffffff' && 'invert(1)'};
`;

const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  color: inherit;
  text-overflow: clip;
  overflow-wrap: anywhere;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
