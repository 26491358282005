import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { updateAccountDetails } from '../../../application/actions/edit-bio';
import { Loader } from '../../components/common';
import { routePaths } from '../../../infrastructure/constants';
import BioForm from './components/EditBioForm';
import { trackPrivateEvent } from '../../../infrastructure/apis/analytics';

import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import withNav from '../../../infrastructure/hoc/withNav';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { PRIVATE_EVENTS, PRIVATE_META_TYPES } from '../../../shared/constants/global';
import { Account } from '../../../shared/types/api';
import useBlockedFields from '../../../infrastructure/hooks/useBlockedFields';
import { checkAccountFieldsLength } from '@/shared/util';
import { getAccountLengthError } from '../md/profiles/import-error-handler';
import { isEmptyObject } from '@/infrastructure/helper';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { PageContainer } from '@/components';
import { LtDefaultActionButtonsProps } from '@/components/LtDefaultActionButtons/types';

interface Props {}

const EditBio = (props: Props, ref): JSX.Element => {
  const account = useAppSelector<Account>(state => state.account);
  const {
    editRights: { detailsEditable },
  } = useEditRights();

  const isLoading = useAppSelector(state => state.editBio.isLoading);

  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { state: routerState } = useLocation<{ focus?: string }>();
  useEffect(() => {
    if (!routerState?.focus) window.scrollTo(0, 0);
  }, [routerState?.focus]);

  const [bioForm, setBioForm] = useState<Account>(account);
  const { t } = useAppTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const { blockedFields, disableFields, checkDisabledField } = useBlockedFields();
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    transformFct?: (arg: string) => string,
  ) => {
    const { name } = e.target;
    let { value } = e.target;
    if (transformFct) {
      value = transformFct(value);
    }
    setBioForm({
      ...bioForm,
      [name]: value,
    });
    setIsUnsaved(true);
  };

  const actions = useMemo<LtDefaultActionButtonsProps>(
    () => ({
      onSave: () => {
        // check for valid account fields length
        const lengthError = checkAccountFieldsLength(bioForm);
        if (bioForm.firstName.length < 1 || bioForm.lastName.length < 1) {
          toast.error(t('fillRequired'));
        } else if (!isEmptyObject(lengthError)) {
          const errorText = getAccountLengthError(lengthError);
          toast.error(errorText);
        } else {
          if (!blockedFields) return;
          const updatedAccount = { id: account.id, ...bioForm };
          dispatch(updateAccountDetails(updatedAccount, getAccessTokenSilently, t));
          trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
            type: PRIVATE_META_TYPES.BIO,
          });
          setIsUnsaved(false);
        }
      },
      onCancel: () => push(routePaths.EDITPROFILE),
    }),
    [account.id, bioForm, blockedFields, dispatch, getAccessTokenSilently, push, setIsUnsaved, t],
  );

  if (isLoading) return <Loader />;

  if (!detailsEditable) {
    return <Redirect to={routePaths.EDITPROFILE} />;
  }

  return (
    <PageContainer maxWidth='s' bottomActionBar={actions} whiteCardWrapper>
      <BioForm
        handleInputChange={handleInputChange}
        bioForm={bioForm}
        disableFields={disableFields}
        checkDisabledField={checkDisabledField}
        focusOnMount={routerState?.focus}
      />
    </PageContainer>
  );
};

export default withNav(
  EditBio,
  {
    showBackButton: {
      linkTo: routePaths.EDITPROFILE,
    },
    tTitle: 'editDetails',
  },
  {
    activeScreen: routePaths.EDITPROFILE,
  },
);
